import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { CircularProgress } from 'material-ui';
import styled from 'styled-components';
import DocumentEditorToolBar from '../DocumentEditorToolBar';
import DocumentNameIndicator from '../DocumentNameIndicator';
import { getPages } from '../../utils/documents';

class DocumentsPageLoader extends Component {
  state = {
    currentPageIndex: 0,
  }

  componentWillReceiveProps = (nextProps) => {
    const { currentPageIndex } = nextProps;
    if (!isEqual(this.props.currentPageIndex, currentPageIndex)
    && !isEqual(this.state.currentPageIndex, currentPageIndex)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPageIndex });
    }
  }

  onClickNextPage = (cb) => {
    const { documents, updateCurrentPage } = this.props;
    const pages = getPages(documents);
    const { currentPageIndex } = this.state;
    if (currentPageIndex === pages.length - 1) return;

    this.setState({ currentPageIndex: currentPageIndex + 1 });
    updateCurrentPage(currentPageIndex + 1);
    cb();
  }

  onClickPreviousPage = (cb) => {
    const { updateCurrentPage } = this.props;
    const { currentPageIndex } = this.state;
    if (currentPageIndex <= 0) {
      return 0;
    }
    this.setState({
      currentPageIndex: currentPageIndex - 1,
    });
    updateCurrentPage(currentPageIndex - 1);
    cb();
    return 0;
  }

  onZoomIn = () => {
    const {
      scaleRatio, setScaleRatio, imageWidth, documentDimensions,
    } = this.props;
    if (documentDimensions.MAX_IMAGE_WIDTH > imageWidth + 50) {
      setScaleRatio(scaleRatio, imageWidth + 50);
    }
  }

  onZoomOut = () => {
    const {
      scaleRatio, setScaleRatio, imageWidth, documentDimensions,
    } = this.props;
    if (documentDimensions.MIN_IMAGE_WIDTH < imageWidth - 50) {
      setScaleRatio(scaleRatio, imageWidth - 50);
    }
  }

  onClickGo = (goToPage) => {
    const { updateCurrentPage } = this.props;
    const page = parseInt(goToPage, 10);
    this.setState({ currentPageIndex: page - 1 });
    updateCurrentPage(page - 1);
  }

  renderToolbar = (currentPageIndex, pages) => (
    <StyledToolbar>
      <DocumentEditorToolBar
        currentPage={currentPageIndex + 1}
        totalPages={pages.length}
        onClickNext={this.onClickNextPage}
        onClickPrev={this.onClickPreviousPage}
        onChangePage={this.onClickGo}
        onClickZoomIn={this.onZoomIn}
        onClickZoomOut={this.onZoomOut}
      />
    </StyledToolbar>)


  render() {
    const {
      documents, loading, renderPageLoader,
    } = this.props;
    if (loading) {
      return <Container><CircularProgress size={60} thickness={7} /></Container>;
    }
    const pages = getPages(documents);
    const { currentPageIndex } = this.state;
    const currentPage = pages[currentPageIndex];
    if (pages.length === 0 || !currentPage) {
      return <Container><div>No pages</div></Container>;
    }
    return (
      <Container>
        {this.renderToolbar(currentPageIndex, pages)}
        <StyledDocumentName>
          <DocumentNameIndicator currentPage={currentPage} documents={documents} />
        </StyledDocumentName>
        {renderPageLoader(currentPage, currentPageIndex)}
      </Container>
    );
  }
}

DocumentsPageLoader.propTypes = {
  documents: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  renderPageLoader: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func,
  scaleRatio: PropTypes.number.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentPageIndex: PropTypes.number,
  imageWidth: PropTypes.number.isRequired,
  setScaleRatio: PropTypes.func.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

DocumentsPageLoader.defaultProps = {
  loading: false,
  updateCurrentPage: () => {},
  currentPageIndex: 0,
};


const Container = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
`;

const StyledToolbar = styled.div`
  position: fixed;
  z-index: 10;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  display: flex;
  background-color: transparent !important;
  @media (max-width: 768px) {
    width: 100%;
  } 
`;

const StyledDocumentName = styled.div`
  position: fixed;
  z-index: 10;
  justify-content: center;
  align-items: center;
  bottom: 4rem;
  display: flex;
  background-color: transparent !important;
`;

export default DocumentsPageLoader;
