import { isEmpty } from 'lodash';
import Types from '../constants/actionTypes';

const {
  SET_FIELD,
  ADD_RECIPIENT,
  SET_RECIPIENTS,
  REMOVE_RECIPIENT,
  ADD_DOCUMENTS,
  REMOVE_DOCUMENT,
  UPDATE_MESSAGE_FIELD,
  TOGGLE_USE_EXISTING_TEMPLATE_MODAL,
  SET_TEMPLATE,
  RESET,
  UPDATE_RECIPIENT,
  MATCH_ROLES_RECIPIENTS,
  TOGGLE_BRAND_SELECT,
  SET_MODE,
} = Types.sendDocumentScreen;

const INITIAL_STATE = {
  completeInOrder: true,
  disableCompleteInOrder: false,
  newRecipient: '',
  recipients: [{ email: '' }],
  cc: '',
  documents: [],
  passwordProtect: false,
  password: '',
  passwordConfirm: '',
  setReminder: false,
  reminderFrequencyType: 'byTimeFrame',
  reminderFrequency: 'DAILY',
  reminderMessage: '',
  reminderSendToMe: false,
  reminderRecipients: [],
  reminderDate: '',
  subject: {
    value: '',
    touched: false,
  },
  message: '',
  displayModal: false,
  documentMode: 'document',
  templateId: null,
  isSelectedBrandEnabled: false,
  shouldValidate: false,
  brandUuid: undefined,
  disableSorting: false,
  signatureRequest: '',
  templatesList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FIELD: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case ADD_RECIPIENT: {
      return {
        ...state,
        recipients: [...state.recipients, { email: '' }],
      };
    }

    case TOGGLE_BRAND_SELECT: {
      return {
        ...state,
        isSelectedBrandEnabled: !state.isSelectedBrandEnabled,
      };
    }

    case REMOVE_RECIPIENT: {
      const { index } = action;
      return {
        ...state,
        recipients: state.recipients.filter((v, ind) => ind !== index),
      };
    }

    case SET_RECIPIENTS: {
      const { recipients } = action;
      return {
        ...state,
        recipients: [...recipients.map(recipient => ({
          email: recipient.email ? recipient.email : '',
          role: recipient.role ? recipient.role : undefined,
          fields: [],
        }))],
      };
    }

    case ADD_DOCUMENTS: {
      const { documents } = action;

      return {
        ...state,
        documentMode: 'document',
        documents: [...documents.filter(document => !isEmpty(document))],
      };
    }

    case REMOVE_DOCUMENT: {
      const documents = state.documents.filter(d => d.uuid !== action.document.uuid);
      return {
        ...state,
        documents,
      };
    }

    case UPDATE_MESSAGE_FIELD: {
      const { payload } = action;
      return {
        ...state,
        message: payload,
      };
    }

    case TOGGLE_USE_EXISTING_TEMPLATE_MODAL: {
      const displayModal = action.display;

      return { ...state, displayModal };
    }

    case SET_TEMPLATE: {
      const { templateId } = action;
      return {
        ...state,
        templateId,
        documentMode: 'template',
      };
    }

    case SET_MODE: {
      const { documentMode } = action;
      return {
        ...state,
        documentMode,
      };
    }

    case RESET: {
      return {
        ...INITIAL_STATE,
        recipients: [{ email: '' }],
      };
    }

    case UPDATE_RECIPIENT: {
      const { payload: { index, newValue } } = action;

      const currentRecipients = state.recipients;

      currentRecipients[index].email = newValue ? newValue.trim() : '';

      return {
        ...state,
        recipients: currentRecipients,
      };
    }

    case MATCH_ROLES_RECIPIENTS: {
      // the state has recipients combine those recipients with roles
      // the state recipients are empty then create the recipients based on template recipients
      const templateRoles = action.recipients;
      const { recipients } = state;
      let subject = [];
      let compare = [];
      if (recipients.length >= templateRoles.length) {
        subject = recipients;
        compare = templateRoles;
      } else {
        subject = templateRoles;
        compare = recipients;
      }

      const newRecipients = subject.map((v, index) => {
        const email = compare[index] && compare[index].email ? compare[index].email : v.email;
        const role = compare[index] && compare[index].role ? compare[index].role : v.role;
        return {
          email: email || '',
          role: role || undefined,
          fields: [],
        };
      });

      return {
        ...state,
        recipients: newRecipients,
      };
    }

    default:
      return state;
  }
};
