import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, withFormik } from 'formik';
import { TextField, FlatButton, RaisedButton } from 'material-ui';
import * as Yup from 'yup';

const ChangeemailSchema = Yup.object().shape({
  name: Yup.string().ensure()
    .required('Required'),
  email: Yup.string().email('Invalid Email Address').ensure()
    .required('Required'),
});

class TransferOrganizationForm extends Component {
  setValue = (field, value) => {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  }

  render = () => {
    const {
      values, errors, touched, onSend, onCancel, isValid,
    } = this.props;

    return (
      <StyledForm>
        <TextField
          value={values.name}
          onChange={e => this.setValue('name', e.target.value)}
          errorText={touched.name && errors.name}
          floatingLabelText="New Owner's Full Name"
          fullWidth
          style={{ marginBottom: '0rem' }}
        />
        <TextField
          value={values.email}
          onChange={e => this.setValue('email', e.target.value)}
          errorText={touched.email && errors.email}
          floatingLabelText="New Owner's Email"
          fullWidth
        />
        <ActionsContainer>
          <FlatButton primary onClick={onCancel}>
            Cancel
          </FlatButton>
          <RaisedButton
            primary
            buttonStyle={{ color: 'white' }}
            onClick={() => onSend({ name: values.name, recipient: values.email })}
            disabled={!isValid}
          >
            Send
          </RaisedButton>
        </ActionsContainer>
      </StyledForm>
    );
  }
}

TransferOrganizationForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSend: PropTypes.func,
  onCancel: PropTypes.func,
};

TransferOrganizationForm.defaultProps = {
  onSend: () => {},
  onCancel: () => {},
};

const StyledForm = styled(Form)`
  width: 100%;
  & > div {
    margin-bottom: 1rem;
  }
`;

const ActionsContainer = styled.div`
  width: 98%;
  margin-top: 3rem;
  text-align: right;
  & > div {
    margin-left: 1rem;
  }
`;

export default withFormik({
  validationSchema: ChangeemailSchema,
  enableReinitialize: true,
})(TransferOrganizationForm);
