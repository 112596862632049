import { includes } from 'lodash';
import Types from '../constants/actionTypes';

const { SET_MENU, SET_COLLAPSE } = Types.sidenav;

export const SetActiveByLink = itemLink => (dispatch, getState) => {
  const { sections } = getState().sidenav;
  sections.forEach((section) => {
    const { items } = section;
    items.forEach((item) => {
      const { link } = item;
      const childItems = item.items;

      if (includes(itemLink, link)) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      if (childItems) {
        childItems.forEach((childItem) => {
          const childLink = childItem.link;
          if (childLink === itemLink) {
            childItem.isActive = true;
            item.isActive = true;
          } else {
            childItem.isActive = false;
          }
        });
      }
    });
  });

  dispatch({
    type: SET_MENU,
    sections,
  });
};

export const setCollapseSidenav = collapse => (dispatch) => {
  dispatch({
    type: SET_COLLAPSE,
    collapse,
  });
};
