import gql from 'graphql-tag';

export const FETCH_PLANS = gql`
  query Plans {
    plans {
      nodes {
        uuid
        amount
        name: nickname
        stripePlan: name
        planType
        organizationsLimit
      }
    }
  }
`;

export default {
  FETCH_PLANS,
};
