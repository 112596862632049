const loader = {
  SHOW_LOADER: 'loader/SHOW_LOADER',
  HIDE_LOADER: 'loader/HIDE_LOADER',
};

const auth = {
  SET_LOADING: 'auth/SET_LOADING',
  LOGIN: 'auth/LOGIN',
  LOGOUT: 'auth/LOGOUT',
  ERROR: 'auth/ERROR',
  RESET: 'auth/RESET',
  SET_USER_ORGANIZATIONS: 'auth/SET_USER_ORGANIZATIONS',
  SET_CURRENT_USER: 'auth/SET_CURRENT_USER',
  SET_TOKEN_EXPIRED: 'auth/SET_TOKEN_EXPIRED',
};

const document = {
  FETCH_PAGE: 'document/FETCH_PAGE',
  SET_CURRENT_PAGE: 'document/SET_CURRENT_PAGE',
  DOCUMENT_LOADED: 'document/DOCUMENT_LOADED',
  PAGE_LOADED: 'document/PAGE_LOADED',
  SHOW_ERROR: 'document/SHOW_ERROR',
  SET_LOADING: 'document/SET_LOADING',
  IS_SIGNING: 'document/IS_SIGNING',
  DOCUMENT_UPDATE: 'document/DOCUMENT_UPDATE',
};

const templateScreen = {
  SET_LOADING: 'templateScreen/SET_LOADING',
  SET_FIELD: 'templateScreen/SET_FIELD',
  ADD_DOCUMENTS: 'templateScreen/ADD_DOCUMENTS',
  REMOVE_DOCUMENT: 'templateScreen/REMOVE_DOCUMENT',
  ADD_ROLE: 'templateScreen/ADD_ROLES_DOCUMENTS',
  UPDATE_ROLES_DOCUMENTS: 'templateScreen/UPDATE_ROLES_DOCUMENTS',
  DELETE_ROLES_DOCUMENTS: 'templateScreen/DELETE_ROLES_DOCUMENTS',
  SAVE_IN_PROGRESS: 'templateScreen/SAVE_IN_PROGRESS',
  RESET_SCREEN: 'templateScreen/RESET_SCREEN',
  SET_BRANDS: 'templateScreen/SET_BRANDS',
  SET_ROLES: 'templateScreen/SET_ROLES',
};

const templateEditorScreen = {
  SET_LOADING: 'templateEditorScreen/SET_LOADING',
  SET_TEMPLATE: 'templateEditorScreen/SET_TEMPLATE',

};


const documentEditorScreen = {
  SET_LOADING: 'documentEditorScreen/SET_LOADING',
  SET_SIGNATURE_REQUEST: 'documentEditorScreen/SET_SIGNATURE_REQUEST',
};

const editor = {
  RESET: 'editor/RESET',
  SET_FIELD: 'editor/SET_FIELD',
  SET_LOADING: 'editor/SET_LOADING',
  SETTING_SCALE_RATIO: 'editor/SETTING_SCALE_RATIO',
  SET_SCALE_RATIO: 'editor/SET_SCALE_RATIO',
  SET_FOCUSED_PAGE_FIELD: 'editor/SET_FOCUSED_PAGE_FIELD',
  ADD_PAGE_FIELD: 'editor/ADD_PAGE_FIELD',
  UPDATE_PAGE_FIELD: 'editor/UPDATE_PAGE_FIELD',
  TOGGLE_DRAGGING: 'editor/TOGGLE_DRAGGING',
  REMOVE_PAGE_FIELD: 'editor/REMOVE_PAGE_FIELD',
};

const sendDocumentScreen = {
  SET_FIELD: 'sendDocumentScreen/SET_FIELD',
  ADD_RECIPIENT: 'sendDocumentScreen/ADD_RECIPIENT',
  SET_RECIPIENTS: 'sendDocumentScreen/SET_RECIPIENTS',
  REMOVE_RECIPIENT: 'sendDocumentScreen/REMOVE_RECIPIENT',
  ADD_DOCUMENTS: 'sendDocumentScreen/ADD_DOCUMENTS',
  REMOVE_DOCUMENT: 'sendDocumentScreen/REMOVE_DOCUMENT',
  UPDATE_MESSAGE_FIELD: 'sendDocumentScreen/UPDATE_MESSAGE_FIELD',
  TOGGLE_USE_EXISTING_TEMPLATE_MODAL: 'sendDocumentScreen/TOGGLE_USE_EXISTING_TEMPLATE_MODAL',
  CREATE_DOCUMENT: 'sendDocumentScreen/CREATE_DOCUMENT',
  TOGGLE_ADD_FIELDS_LOADER: 'sendDocumentScreen/TOGGLE_ADD_FIELDS_LOADER',
  SET_TEMPLATE: 'sendDocumentScreen/SET_TEMPLATE',
  RESET: 'sendDocumentScreen/RESET',
  UPDATE_RECIPIENT: 'sendDocumentScreen/UPDATE_RECIPIENT',
  MATCH_ROLES_RECIPIENTS: 'sendDocumentScreen/MATCH_ROLES_RECIPIENTS',
  TOGGLE_BRAND_SELECT: 'sendDocumentScreen/TOGGLE_BRAND_SELECT',
  SET_MODE: 'sendDocumentScreen/SET_MODE',
};

const filesManager = {
  SET_ADD_FILES_DIALOG: 'filesManager/SET_ADD_FILES_DIALOG',
  SET_LOADING: 'filesManager/SET_LOADING',
  SET_UPLOADING: 'filesManager/SET_UPLOADING',
  SET_UPLOAD_PROGRESS: 'filesManager/SET_UPLOAD_PROGRESS',
  SET_DOCUMENTS: 'filesManager/SET_DOCUMENTS',
  SET_DOCUMENTS_TMP: 'filesManager/SET_DOCUMENTS_TMP',
  CLEAR_DOCUMENTS: 'filesManager/CLEAR_DOCUMENTS',
  SET_SELECTED_FILES: 'filesManager/SET_SELECTED_FILES',
  GET_DOCUMENTS: 'documentManager/GET_DOCUMENTS',
  GET_LIST_TEMPLATES: 'documentManager/GET_LIST_TEMPLATES',
  SET_TEMPLATE: 'documentManager/SET_TEMPLATE',
  SET_PREVIOUS_DOCUMENT: 'filesManager/SET_PREVIOUS_DOCUMENT',
  SET_WORK_IN_PROGRESS: 'filesManager/SET_WORK_IN_PROGRESS',
  RESET: 'filesManager/RESET',
};

const fields = {
  FETCH_FIELDS: 'fields/FETCH_FIELDS',
  SET_INPUT_TYPES: 'fields/SET_INPUT_TYPES',
  SET_FIELDS: 'fields/SET_FIELDS',
  UPDATE_FIELD: 'fields/UPDATE_FIELD',
  CREATE_FIELD: 'fields/CREATE_FIELD',
  DELETE_FIELD: 'fields/DELETE_FIELD',
};

const documents = {
  GET_DOCUMENT: 'documents/GET_DOCUMENT',
  DOWNLOAD_DOCUMENT: 'documents/DOWNLOAD_DOCUMENT',
};

const documentManager = {
  SET_LOADING: 'documentManager/SET_LOADING',
  TEMPLATES_LOADED: 'documentManager/TEMPLATES_LOADED',
  DOCUMENTS_LOADED: 'documentManager/DOCUMENTS_LOADED',
  SET_SELECTED_DOCUMENT: 'documentManager/SET_SELECTED_DOCUMENT',
  OPEN_SIDE_OPTIONS_MENU: 'documentManager/OPEN_SIDE_OPTIONS_MENU',
  CLOSE_SIDE_OPTIONS_MENU: 'documentManager/CLOSE_SIDE_OPTIONS_MENU',
  SHARE_DOCUMENT: 'documentManager/SHARE_DOCUMENT',
  CLEAR_SHARE_DOCUMENT: 'documentManager/CLEAR_SHARE_DOCUMENT',
  SET_SELECTED_TEMPLATE: 'documentManager/SET_SELECTED_TEMPLATE',
  TOGGLE_SEND_TEMPLATE_MODAL: 'documentManager/TOGGLE_SEND_TEMPLATE_MODAL',
  SET_ARCHIVED_DOCUMENTS: 'documentManager/SET_ARCHIVED_DOCUMENTS',
  SET_DOCUMENTS_WAITING_FOR_ME: 'documentManager/SET_DOCUMENTS_WAITING_FOR_ME',
  UPDATE_NOTES: 'documentManager/UPDATE_NOTES',
};

const password = {
  SEND_EMAIL_FORGOT_PASSWORD: 'password/SEND_EMAIL_FORGOT_PASSWORD',
  PASSWORD_RECOVERY: 'password/PASSWORD_RECOVERY',
};

const uploadAndArchiveScreen = {
  SET_DOCUMENT: 'uploadAndArchive/SET_DOCUMENT',
  SAVING_ARCHIVE: 'uploadAndArchive/SAVING_ARCHIVE',
  SAVE_ARCHIVE: 'uploadAndArchive/SAVE_ARCHIVE',
  RESET_SCREEN: 'uploadAndArchive/RESET_SCREEN',
  SET_SAVED: 'uploadAndArchive/SET_SAVED',
  SENDING_DOCUMENT: 'uploadAndArchive/SENDING_DOCUMENT',
  SET_SEND_DOCUMENT_MODAL: 'uploadAndArchive/SET_SEND_DOCUMENT_MODAL',
  SET_SAVING_PERCENTAGE: 'uploadAndArchive/SET_SAVING_PERCENTAGE',
};

const sidenav = {
  SET_MENU: 'sidebar/SET_MENU',
  SET_SECTION_ITEM_VISIBLE: 'sidebar/SET_SECTION_ITEM_VISIBLE',
  SET_COLLAPSE: 'sidebar/SET_COLLAPSE',
};

const signs = {
  CREATE_FIELD: 'signs/CREATE_FIELD',
  REMOVE_FIELD: 'signs/REMOVE_FIELD',
  UPDATE_FIELD: 'signs/UPDATE_FIELD',
  DRAG_FIELDS: 'signs/DRAG_FIELDS',
  CLEAR_FIELDS: 'signs/CLEAR_FIELDS',
  TEMPLATE_PAYLOAD: 'signs/TEMPLATE_PAYLOAD',
  SEND_DOCUMENT_MODAL: 'signs/SEND_DOCUMENT_MODAL',
  SENDING: 'signs/SENDING',
  LOADING_TEMPLATE: 'signs/LOADING_TEMPLATE',
  SET_DOCUMENT: 'signs/SET_DOCUMENT',
  SETTING_SCALE_RATIO: 'signs/SETTING_SCALE_RATIO',
  SET_SCALE_RATIO: 'signs/SET_SCALE_RATIO',
  RESET: 'signs/RESET',
  SET_MESSAGE: 'signs/SET_MESSAGE',
  SET_PAGE_NUMBER: 'signs/SET_PAGE_NUMBER',
};

const organization = {
  SAVING: 'organization/SAVING',
  DELETE: 'organizations/DELETE',
  UPDATE: 'organizations/UPDATE',
  ADD: 'organizations/ADD',
  SET_CAN_CHANGE: 'organization/SET_CAN_CHANGE',
  RESET: 'organization/RESET',
  SET_FIELD: 'organization/SET_FIELD',
};

const organizationsScreen = {
  OPEN_INVITE_NEW_USER: 'organizationsScreen/OPEN_INVITE_NEW_USER',
  CLOSE_INVITE_NEW_USER: 'organizationsScreen/CLOSE_INVITE_NEW_USER',
  TOGGLE_EDIT_ORGANIZATION: 'organizationScreen/TOGGLE_EDIT_ORGANIZATION',
  TOGGLE_EDIT_IMAGE: 'organizationScreen/TOGGLE_EDIT_IMAGE',
  SET_CURRENT_ORGANIZATION_IMAGE: 'organizationScreen/SET_CURRENT_ORGANIZATION_IMAGE',
  IS_VALID_INVITATION_EMAIL: 'organizationScreen/IS_VALID_INVITATION_EMAIL',
};

const userProfileScreen = {
  UPDATING_PROFILE: 'userProfileScreen/UPDATING_PROFILE',
  PROFILE_UPDATED: 'userProfileScreen/PROFILE_UPDATED',
  SAVING_IDENTITY: 'userProfileScreen/SAVING_IDENTITY',
  LOADING_PROFILE: 'userProfileScreen/LOADING_PROFILE',
  TOGGLE_ORGANIZATION_MODAL: 'userProfileScreen/TOGGLE_ORGANIZATION_MODAL',
  SET_CURRENT_USER: 'userProfileScreen/SET_CURRENT_USER',
};

const organizations = {
  GET_ORGANIZATIONS_LIST: 'organizations/GET_ORGANIZATIONS_LIST',
  SET_CURRENT_ORGANIZATION: 'organizations/SET_CURRENT_ORGANIZATION',
};

const apiAndKeysSection = {
  TOGGLE_MODAL: 'apiAndKeysSection/TOGGLE_MODAL',
  GENERATING_API_KEY: 'apiAndKeysSection/GENERATING_API_KEY',
  LOADING: 'apiAndKeysSection/LOADING',
  APPS_LOADED: 'apiAndKeysSection/APPS_LOADED',
  API_KEY_ADDED: 'apiAndKeysSection/API_KEY_ADDED',
  API_KEY_REMOVED: 'apiAndKeysSection/API_KEY_REMOVED',
  API_KEY_REGENERATED: 'apiAndKeysSection/API_KEY_REGENERATED',
  SET_CURRENT_API_KEY: 'apiAndKeysSection/SET_CURRENT_API_KEY',
  UPDATE_API_KEY: 'apiAndKeysSection/UPDATE_API_KEY',
};

const confirmDialog = {
  SHOW_CONFIRM: 'confirmDialog/SHOW_CONFIRM',
  HIDE_CONFIRM: 'confirmDialog/HIDE_CONFIRM',
  SET_RESULT: 'confirmDialog/SET_RESULT',
};

const brandsSection = {
  GET_BRANDS_LIST: 'brandsSection/GET_BRANDS_LIST',
  TOGGLE_MODAL: 'brandsSection/TOGGLE_MODAL',
  SET_CURRENT: 'brandsSection/SET_CURRENT',
  SAVING: 'brandsSection/SAVING',
  ADD: 'brandsSection/ADD',
  UPDATE: 'brandsSection/UPDATE',
  DELETE: 'brandsSection/DELETE',
  SET_DEFAULT: 'brandsSection/SET_DEFAULT',
};

const signDocumentScreen = {
  LOADING: 'signDocumentScreen/LOADING',
  SET_FIELDS: 'signDocumentScreen/SET_FIELDS',
  SET_FIELD: 'signDocumentScreen/SET_FIELD',
  SETTING_SCALE_RATIO: 'signDocumentScreen/SETTING_SCALE_RATIO',
  SET_SCALE_RATIO: 'signDocumentScreen/SET_SCALE_RATIO',
  SET_USER_SIGNATURE: 'signDocumentScreen/SET_USER_SIGNATURE',
};


const signedDocumentScreen = {
  ERROR: 'signedDocumentScreen/ERROR',
  LOADING: 'signedDocumentScreen/LOADING',
  DOCUMENT_LOADED: 'signedDocumentScreen/DOCUMENT_LOADED',
  DOWNLOADING: 'signedDocumentScreen/DOWNLOADING',
};

const analyticsScreen = {
  TOGGLE_SCHEDULE_MODAL: 'analyticsScreen/SHOW_SCHEDULE_MODAL',
  TOGGLE_SEND_REPORT_MODAL: 'analyticsScreen/TOGGLE_SEND_REPORT_MODAL',
  SENDING_REPORT: 'analyticsScreen/SENDING_REPORT',
  SET_REPORT_VARIABLES: 'analyticsScreen/SET_REPORT_VARIABLES',
  SET_STOP_REPORT_SUCCESSFULLY_MODAL: 'analyticsScreen/SET_STOP_REPORT_SUCCESSFULLY_MODAL',
};

const platformManager = {
  SET_LOADING: 'platformManagerScreen/SET_LOADING',
  FETCH_DASHBOARD_DATA: 'platformManagerScreen/FETCH_DASHBOARD_DATA',
};

const signatureRequest = {
  SET_WAITING_ME: 'signatureRequest/SET_WAITING_FOR_ME',
  SET_WAITING_SIGNATURE: 'signatureRequest/SET_WAITING_FOR_SIGNATURE',
};

const activityLog = {
  SET_ACTIVITY_LOG: 'activityLog/SET_ACTIVITY_LOG',
  SET_LOADING: 'activityLog/SET_LOADING',
};

const subscriptions = {
  SET_PLANS: 'subscriptions/SET_PLANS',
  SET_CURRENT_SUBSCRIPTION: 'subscriptions/SET_CURRENT_SUBSCRIPTION',
  SET_SELECTED_SUBSCRIPTION: 'subscriptions/SET_SELECTED_SUBSCRIPTION',
  SET_OPEN_ITEMS_SELECTION_MODAL: 'subscriptions/SET_OPEN_ITEMS_SELECTION_MODAL',
  SET_OPEN_PAYMENT_MODAL: 'subscriptions/SET_OPEN_PAYMENT_MODAL',
  SET_OPEN_UPDATE_MODAL: 'subscriptions/SET_OPEN_UPDATE_MODAL',
  SET_OPEN_ERROR_CREDIT_CARD_MODAL: 'subscriptions/SET_OPEN_ERROR_CREDIT_CARD_MODAL',
  SET_UPGRADE_MODAL: 'subscriptions/SET_UPGRADE_MODAL',
  SET_SUBSCRIPTION_FIELD: 'subscriptions/SET_SUBSCRIPTION_FIELD',
  SET_VISIBLE_PLANS: 'subscriptions/SET_VISIBLE_PLANS',
  RESET: 'subscriptions/RESET',
};

const documentDimensions = {
  SET_IMAGE_WIDTH: 'documentDimensions/SET_IMAGE_WIDTH',
};

export default {
  loader,
  auth,
  templateScreen,
  templateEditorScreen,
  sendDocumentScreen,
  filesManager,
  document,
  fields,
  documents,
  documentManager,
  password,
  uploadAndArchiveScreen,
  sidenav,
  signs,
  organization,
  organizationsScreen,
  userProfileScreen,
  organizations,
  apiAndKeysSection,
  confirmDialog,
  brandsSection,
  signedDocumentScreen,
  signDocumentScreen,
  analyticsScreen,
  platformManager,
  signatureRequest,
  activityLog,
  editor,
  documentEditorScreen,
  subscriptions,
  documentDimensions,
};
