/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import BulletLegend from './BulletLegend';
import ChartSummaryWidget from './ChartSummaryWidget';
import { colors } from '../../utils';

function getData(props) {
  return [
    { name: 'Delivered', value: props.delivered, color: colors.blue },
    { name: 'Sent', value: props.sent, color: colors.purple },
    { name: 'Completed', value: props.completed, color: colors.green },
    { name: 'Declined', value: props.declined, color: colors.red },
    { name: 'Voided', value: props.voided, color: colors.yellow },
  ];
}

function getTotal(props) {
  return getData(props).reduce((accumulated, entry) => accumulated + entry.value, 0);
}

const DocumentsStatusChart = props => (
  <React.Fragment>
    <Container>
      <ChartSummaryWidget
        width="45%"
        height="100%"
        largeText={getTotal(props)}
        smallText="Total"
      />
      <ResponsiveContainer width="50%">
        <PieChart>
          <Pie
            data={getData(props)}
            dataKey="value"
            innerRadius="78%"
            outerRadius="90%"
            paddingAngle={0}
            fill={colors.gray}
          >
            {getData(props).map(entry => (
              <Cell key={entry.color} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <div
        className="mr-5 pt-4 d-flex justify-content-center align-items-center"
        style={{ width: '50%' }}
      >
        <div className="">
          {getData(props).map(entry => (
            <BulletLegend
              key={entry.color}
              color={entry.color}
              label={entry.name}
            />
          ))}
        </div>
      </div>
    </Container>
  </React.Fragment>
);

DocumentsStatusChart.propTypes = {
  sent: PropTypes.number,
  delivered: PropTypes.number,
  completed: PropTypes.number,
  declined: PropTypes.number,
  voided: PropTypes.number,
};

DocumentsStatusChart.defaultProps = {
  sent: 0,
  delivered: 0,
  completed: 0,
  declined: 0,
  voided: 0,
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 300px;
  display: inherit;
`;

export default DocumentsStatusChart;
