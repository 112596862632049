import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import graphqlClient from '../../../config/graphql';
import organizationActions from '../../../actions/organization';
import { setCurrentUser } from '../../../actions/auth';
import { DECLINE_INVITE } from './queries';
import { FETCH_CURRENT_USER } from '../../../screens/UserProfileScreen/queries';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    componentDidMount= () => {
      // eslint-disable-next-line react/prop-types
      const { organizationTransferToken, setOrganizationsValue } = this.props;
      if (!organizationTransferToken) {
        setOrganizationsValue('openDeclineOrganizationTransferModal', false);
      }
    }

    declineInvite = async (declineReason) => {
      try {
        // eslint-disable-next-line
        const { setOrganizationsValue, organizationTransferToken, selectedInvite, setCurrentAuthUser, user } = this.props;
        const variables = organizationTransferToken ? { inviteToken: organizationTransferToken } :
          // eslint-disable-next-line react/prop-types
          { inviteUuid: selectedInvite.uuid };
        await graphqlClient().mutate({
          mutation: DECLINE_INVITE,
          variables: {
            ...variables,
            declineReason,
          },
        });
        toast.success('Your invite was successfuly declined.');

        const {
          data: { currentUser },
        } = await graphqlClient().query({
          query: FETCH_CURRENT_USER,
          fetchPolicy: 'network-only',
        });
        setCurrentAuthUser({ ...user, ...currentUser });

        setOrganizationsValue('openDeclineOrganizationTransferModal', false);
        setOrganizationsValue('openDeclineOrganizationTransferSuccessModal', true);
      } catch (e) {
        toast.error(e.message);
        throw (e);
      }
    };

    handleBack = () => {
      // eslint-disable-next-line react/prop-types
      const { setOrganizationsValue } = this.props;
      setOrganizationsValue('openDeclineOrganizationTransferModal', false);
      setOrganizationsValue('openOrganizationTransferModal', true);
    }

    render() {
      // eslint-disable-next-line
      const { organizationTransferToken, openDeclineOrganizationTransferModal, selectedInvite } = this.props;
      return (<Component
        // eslint-disable-next-line
        open={(organizationTransferToken || selectedInvite.uuid) && openDeclineOrganizationTransferModal}
        onBack={this.handleBack}
        onSend={this.declineInvite}
        {...this.props}
      />);
    }
  };


const mapStateToProps = ({ organizationInvites, auth }) => ({
  openDeclineOrganizationTransferModal: organizationInvites.openDeclineOrganizationTransferModal,
  organizationTransferToken: organizationInvites.organizationTransferToken,
  selectedInvite: organizationInvites.selectedInvite,
  user: auth.user,
});

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, {
    setOrganizationsValue: organizationActions.setOrganizationsValue,
    setCurrentAuthUser: setCurrentUser,
  })(renderWrappedComponent(WrappedComponent));

export default connect;
