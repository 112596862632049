import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  document: {},
  downloading: false,
  error: '',
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.signedDocumentScreen.DOCUMENT_LOADED: {
      const { document } = action;

      return { ...state, document };
    }

    case Types.signedDocumentScreen.ERROR: {
      const { error } = action;

      return { ...state, error };
    }

    case Types.signedDocumentScreen.LOADING: {
      const { loading } = action;

      return { ...state, loading };
    }

    case Types.signedDocumentScreen.DOWNLOADING: {
      const { downloading } = action;

      return { ...state, downloading };
    }

    default:
      return state;
  }
};
