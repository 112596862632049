import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import introspectionQueryResultData from './fragmentTypes.json';
import authUtil from '../utils/auth';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const getPath = t => `${process.env.REACT_APP_API_ROOT_URL}/api/v1/graphql${t ? `?t=${t}` : ''}`;

const uri = (organization, t) => (organization
  ? `https://${organization}.${getPath(t).replace('https://', '')}`
  : getPath(t));

const httpLink = (organization, t) => createUploadLink({
  uri: uri(organization, t),
});

const authLink = setContext((_, { headers }) =>
  // return the headers to the context so httpLink can read them
  ({
    headers: {
      ...headers,
      'access-token': authUtil.getHeaders().accessToken,
      client: authUtil.getHeaders().client,
      uid: authUtil.getHeaders().uid,
    },
  }));

const client = (organization, t = null) => new ApolloClient({
  link: authLink.concat(httpLink(organization || '', t)),
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;
