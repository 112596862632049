import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';
import { Card } from 'material-ui';
import { connect } from 'react-redux';
import { isEmpty, isEqual, isNil } from 'lodash';
import { toast } from 'react-toastify';

import ArchivedDocumentsTable from '../../components/documentManager/ArchivedDocumentsTable';
import CompletedDocumentsTable from '../../components/documentManager/CompletedDocumentsTable';
import DocumentManagerOptionsMenu from '../../components/DocumentManagerOptionsMenu';
import FullLoadingIndicator from '../../components/commons/FullLoadingIndicator';
import Messages from '../../constants/toastMessages';
import OutForSignatureTableBody from '../../components/documentManager/OutForSignatureTableBody';
import SearchInput from '../../components/SearchInput';
import SendTemplateModal from '../../components/modals/SendTemplateModal';
import TabBar from '../../components/TabBar';
import TemplatesTableBody from '../../components/documentManager/TemplatesTableBody';
import WaitingForMeTableBody from '../../components/documentManager/WaitingForMeTableBody';
import brandsSectionActions from '../../actions/brandsSection';
import documentManagerParser from '../../utils/documentManagerParser';
import documentsManagerScreenActions from '../../actions/documentManagerScreen';
import editorActions from '../../actions/editor';
import { PageHeader, PageContent } from '../../components/pages';
import { animations, spacing } from './../../utils';
import { didOrganizationChanged } from '../../utils/userOrganizations';

const { appHeaderSize, docPreviewPanel } = spacing;

class DocumentsManagerScreen extends Component {
  state = {
    activeTab: 'out_for_signature',
    panelTab: null,
    searchTerm: '',
    loading: false,
  };

  async componentDidMount() {
    const { location, fetchBrands, resetEditor } = this.props;
    const { uuid, tab, panel_tab: panelTab } = queryString.parse(location.search);
    const selectedTab = this.tabs.find(t => t.value === tab);
    resetEditor();
    if (selectedTab) {
      const documents = await this.switchTab(selectedTab.value);
      this.openPanelTab(documents, uuid, tab, panelTab);
    }
    fetchBrands();
  }

  async componentWillReceiveProps(nextProps) {
    if (didOrganizationChanged(this.props, nextProps)) {
      const aux = this.state.activeTab;
      await this.switchTab(null);
      this.switchTab(aux);
      const { closeOptionsMenu } = this.props;
      closeOptionsMenu();
    }
  }

  async componentWillUnmount() {
    const { resetEditor } = this.props;
    resetEditor();
  }

  openPanelTab = async (documents, uuid, tab, panelTab) => {
    const { setSelectedDocument } = this.props;
    let parsedDocument;
    if (!isEmpty(documents)) {
      const selectedDocument = documents.find(document => document.uuid === uuid);
      if (selectedDocument) {
        parsedDocument = this.parseDocument(selectedDocument, tab);
      }
    }
    if (parsedDocument) {
      await setSelectedDocument(parsedDocument);
      if (panelTab) {
        // eslint-disable-next-line
        this.setState({
          panelTab,
        });
      }
    }
  };

  parseDocument = (selectedDocument, tab) => {
    let parsedDocument;
    if (!selectedDocument || isEmpty(selectedDocument)) {
      return null;
    }
    switch (tab) {
      case 'out_for_signature': {
        [parsedDocument] = documentManagerParser.parseOutForSignatureDocuments([selectedDocument]);
        break;
      }
      case 'waiting_for_me': {
        [parsedDocument] = documentManagerParser.parseWaitingForMeDocuments([selectedDocument]);
        break;
      }
      case 'completed': {
        [parsedDocument] = documentManagerParser.parseCompletedDocuments([selectedDocument]);
        break;
      }
      case 'template_library': {
        [parsedDocument] = documentManagerParser.parseTemplates([selectedDocument]);
        break;
      }
      case 'archived': {
        [parsedDocument] = documentManagerParser.parseArchivedDocuments([selectedDocument]);
        break;
      }
      default:
        parsedDocument = null;
        break;
    }
    return parsedDocument;
  };

  tabs = [
    { label: 'Out for signature', value: 'out_for_signature' },
    { label: 'Waiting for me', value: 'waiting_for_me' },
    { label: 'Completed', value: 'completed' },
    { label: 'Template Library', value: 'template_library' },
    { label: 'Stored', value: 'archived' },
  ];

  async switchTab(activeTab) {
    const {
      documentManagerScreen: { loading: actionsLoading },
      closeOptionsMenu,
    } = this.props;
    const { loading } = this.state;
    if (actionsLoading || loading) {
      return Promise.resolve([]);
    }
    try {
      closeOptionsMenu();
      this.setState({ activeTab, searchTerm: '' });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  filterDocumentsBySearchTerm = (documents) => {
    const { searchTerm } = this.state;
    return documents
      .filter(document => (
        document.name && document.name.toLowerCase().includes(searchTerm.toLowerCase())),
      );
  };

  filterDocumentsByOrganization = (documents) => {
    const { organization } = this.props;

    return documents.filter(document =>
      (organization
        ? document.organization && isEqual(document.organization.uuid, organization.uuid)
        : isNil(document.organization)));
  };

  updateLoading = (loading) => {
    this.setState({ loading });
  };

  handleShare = async (emails, message) => {
    const {
      shareDocument,
      documentManagerScreen: { selectedDocument },
    } = this.props;
    const { uuid, type, name } = selectedDocument;
    try {
      this.updateLoading(true);
      await shareDocument(uuid, type, emails, message, name);
      toast.success(Messages.documents.share.success);
      this.updateLoading(false);
    } catch (e) {
      toast.error(Messages.documents.share.error);
      this.updateLoading(false);
    }
  };

  renderTableBody = () => {
    const { activeTab, searchTerm } = this.state;

    const tabs = {
      out_for_signature: () => <OutForSignatureTableBody searchTerm={searchTerm} />,
      waiting_for_me: () => <WaitingForMeTableBody searchTerm={searchTerm} />,
      completed: () => <CompletedDocumentsTable searchTerm={searchTerm} />,
      template_library: () => <TemplatesTableBody searchTerm={searchTerm} />,
      archived: () => <ArchivedDocumentsTable searchTerm={searchTerm} />,
    };

    return (tabs[activeTab] || tabs.out_for_signature)();
  }

  render = () => {
    const { loading } = this.state;
    const {
      documentManagerScreen: { sideOptionsOpen, selectedDocument, selectedDocType },
      closeOptionsMenu,
    } = this.props;

    return (
      <div>
        <SendTemplateModal />
        <Container activeOptionMenu={sideOptionsOpen}>
          <PageHeader title="Document Manager" />
          <PageContent>
            <TabBar
              tabs={this.tabs}
              tabChanged={activeTab => this.switchTab(activeTab)}
              defaultTab={this.state.activeTab}
              fullWidth
            />
            <SearchInput
              style={{ margin: '2rem 0rem 0rem' }}
              searchTermChanged={term => this.setState({ searchTerm: term })}
            />
            <Card style={{ marginTop: '2rem' }}>
              {this.renderTableBody()}
            </Card>
          </PageContent>
        </Container>

        {sideOptionsOpen && (
          <SidePanelPosition className="position-fixed d-flex h-100">
            <SidePanelContainer className="align-self-stretch">
              <DocumentManagerOptionsMenu
                selectedDocument={selectedDocument}
                tab={this.state.panelTab}
                selectedDocType={selectedDocType}
                onShare={this.handleShare}
                onClose={closeOptionsMenu}
              />
            </SidePanelContainer>
          </SidePanelPosition>
        )}
        <FullLoadingIndicator open={loading} />
      </div>
    );
  }
}

const mapDispatchToProps = () => {
  const {
    closeOptionsMenu,
    shareDocument,
    sendReminder,
    setSelectedDocument,
  } = documentsManagerScreenActions;

  const { fetchBrands } = brandsSectionActions;
  const { resetEditor } = editorActions;

  return {
    closeOptionsMenu,
    shareDocument,
    sendReminder,
    setSelectedDocument,
    fetchBrands,
    resetEditor,
  };
};

DocumentsManagerScreen.propTypes = {
  documentManagerScreen: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  closeOptionsMenu: PropTypes.func.isRequired,
  shareDocument: PropTypes.func.isRequired,
  setSelectedDocument: PropTypes.func.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  resetEditor: PropTypes.func.isRequired,
};

const Container = styled.div`
  margin-top: 0.6rem;
  margin-left: 2rem;
  padding-right: ${p => (p.activeOptionMenu ? docPreviewPanel : 0)}px;
  animation: ${animations.fadeIn} 0.3s ease-in;
`;

const SidePanelPosition = styled.div`
  top: 0;
  right: 0;
`;

const SidePanelContainer = styled.div`
  margin-top: calc(${appHeaderSize} + 2px);
  width: ${docPreviewPanel}px;
`;

const mapStateToProps = ({
  organizations, documentManagerScreen, form,
}) => ({
  documentManagerScreen,
  form,
  organization: organizations.currentOrganization,
  organizations,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(DocumentsManagerScreen);
