import gql from 'graphql-tag';
import Types from '../constants/actionTypes';
import graphqlClient from '../config/graphql';

export const FETCH_DOCUMENT_DIMENSIONS = gql`
  query DocumentDefaultPageWidth {
    documentDefaultPageWidth
  }
`;


export const fetchDocumentDimensions = () => async (dispatch) => {
  try {
    const { data: { documentDefaultPageWidth: { page_width } } } = await graphqlClient().query({
      query: FETCH_DOCUMENT_DIMENSIONS,
      fetchPolicy: 'network-only',
    });
    dispatch({ type: Types.documentDimensions.SET_IMAGE_WIDTH, width: page_width });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default {
  fetchDocumentDimensions,
};
