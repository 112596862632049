import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RaisedButton, Dialog } from 'material-ui';

class VoidMessageModal extends Component {
  renderContent = () => {
    const { onClose } = this.props;
    return (
      <Wrapper>
        <Title>Sorry, this document was voided!</Title>
        <Description>
          You are unable to access this document because it was voided by the sender.
        </Description>
        <ButtonWrapper>
          <RaisedButton
            label="Close"
            primary
            onClick={onClose}
            labelStyle={{ fontFamily: 'DIN-Medium' }}
          />
        </ButtonWrapper>
      </Wrapper>
    );
  }

  render = () => (
    <Dialog
      open
      modal
      contentStyle={{ width: '550px' }}
    >
      {this.renderContent()}
    </Dialog>
  );
}

VoidMessageModal.propTypes = {
  onClose: PropTypes.func,
};

VoidMessageModal.defaultProps = {
  onClose: () => {},
};

const Wrapper = styled.div`
  color: black;
  padding: 30px 0 60px 0;
`;

const Title = styled.div`
  font-size: 20px;
`;

const Description = styled.div`
  margin: 40px 0;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
`;

export default VoidMessageModal;
