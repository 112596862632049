import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing } from '../../utils';

const PageContent = ({ children }) => (
  <Container>
    {children}
  </Container>
);

PageContent.propTypes = {
  children: PropTypes.any.isRequired,
};

const Container = styled.div`
  flex: 1;
  position: relative;
  margin-bottom: ${spacing.appHeaderSize}
`;

export default PageContent;
