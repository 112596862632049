import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  displayModal: false,
  currentBrand: null,
  saving: false,
  brands: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.brandsSection.GET_BRANDS_LIST: {
      const { brands } = action;
      return { ...state, brands };
    }

    case Types.brandsSection.TOGGLE_MODAL: {
      const { displayModal } = action;
      const { currentBrand } = state;

      return {
        ...state,
        displayModal,
        currentBrand: displayModal ? currentBrand : null,
      };
    }

    case Types.brandsSection.SET_CURRENT: {
      const { brandUuid } = action;
      const { brands } = state;

      return {
        ...state,
        currentBrand: brands.find(v => v.uuid === brandUuid),
      };
    }

    case Types.brandsSection.ADD: {
      const { brand } = action;
      const brands = [...state.brands, brand];
      return { ...state, brands };
    }

    case Types.brandsSection.UPDATE: {
      const { brand } = action;
      const brands = state.brands.map((v) => {
        if (v.uuid === brand.uuid) {
          return brand;
        }
        return v;
      });

      return { ...state, brands };
    }

    case Types.brandsSection.SAVING: {
      const { saving } = action;

      return { ...state, saving };
    }

    case Types.brandsSection.SET_DEFAULT: {
      const { id } = action;
      const brands = state.brands.map(v => ({ ...v, default: v.id === id }));

      return { ...state, brands };
    }

    case Types.brandsSection.DELETE: {
      const { id } = action;
      const brands = state.brands.filter(v => v.id !== id);

      return { ...state, brands };
    }

    default:
      return state;
  }
};
