import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from './../../utils';

class TextSignature extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.object,
    type: PropTypes.string,
  }

  static defaultProps = {
    type: 'signature',
    value: {
      text: '',
      width: 0,
      height: 0,
    },
  }

  componentDidMount = () => this.textInput.focus()

  handleChange = (e) => {
    this.invisibleRef.innerHTML = e.target.value;

    this.props.onChange({
      text: e.target.value,
      width: this.invisibleRef.clientWidth,
      height: this.invisibleRef.clientHeight,
    });
  }

  invisibleRef = null;


  invisibleRefStyles = {
    width: 'auto',
    height: 'auto',
    fontFamily: "'Dancing Script', cursive",
    fontSize: '45px',
    visibility: 'hidden',
    position: 'absolute',
  };

  render() {
    const { placeholder, type, value } = this.props;
    return (
      <TextSignatureWrapper>
        <div
          ref={(ref) => { this.invisibleRef = ref; }}
          style={this.invisibleRefStyles}
        />
        <TextInput
          {...this.props}
          innerRef={(ref) => { this.textInput = ref; }}
          type="text"
          value={value.text}
          onChange={this.handleChange}
          placeholder={placeholder}
          maxLength={type === 'initials' ? 4 : 30}
        />
      </TextSignatureWrapper>
    );
  }
}

const TextSignatureWrapper = styled.div`
  width: 500px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  @media (max-width: 823px) {
    width: 100%;
  }
`;

const TextInput = styled.input`
  width: 100%;
  font-family: 'Dancing Script', cursive;
  font-size: 45px;
  padding: 0 15px;
  border: none;
  border-bottom: 2px solid ${colors.blue}
  text-align: center;
  &:focus, &:active{
    border: none;
    border-bottom: 2px solid ${colors.blue}
    outline: none;
  }
`;

export default TextSignature;
