import { toast } from 'react-toastify';

import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import passwordServices from '../services/password';
import { history } from '../config';

const { SEND_EMAIL_FORGOT_PASSWORD, PASSWORD_RECOVERY } = Types.password;

export const forgotPassword = values => async (dispatch) => {
  try {
    const { origin } = window.location;

    const data = {
      ...values,
      redirect_url: `${origin}/recovery-password`,
    };

    const response = await passwordServices.forgot(data);

    dispatch({
      type: SEND_EMAIL_FORGOT_PASSWORD,
      payload: response.data,
    });

    history.push('/login');

    toast.success(`${response.data.message}`);
  } catch (error) {
    toast.error(Messages.password.forgot.error);
  }
};

export const recoveryPassword = headers => async (dispatch, getState) => {
  try {
    const { values } = getState().form.recoveryPassword;

    localStorage.setItem('accessToken', headers.token);
    localStorage.setItem('uid', headers.uid);
    localStorage.setItem('client', headers.client);

    const response = await passwordServices.recovery(values);

    dispatch({
      type: PASSWORD_RECOVERY,
      payload: response.data,
    });

    history.push('/login');

    toast.success(Messages.password.recovery.success);
  } catch (error) {
    toast.error(Messages.password.recovery.error);
  }
};

