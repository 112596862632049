import { toast } from 'react-toastify';
import { submit } from 'redux-form';
import Messages from '../constants/toastMessages';
import Types from '../constants/actionTypes';
import documentService from './../services/document';
import uploadAndArchiveParser from '../utils/parsers/uploadAndArchiveParser';
import graphqlClient from '../config/graphql';
import { CREATE_NOTE } from '../components/documentManager/DocumentNotesSection/mutations';

const createNote = async (currentOrganization, entityUuid, text) => {
  try {
    await graphqlClient(currentOrganization &&
      currentOrganization.subdomain).mutate({
      mutation: CREATE_NOTE,
      variables: { entityUuid, entityType: 'ARCHIVED_DOCUMENT', text },
    });
  } catch (e) {
    toast.error(Messages.documentManager.notes.update.error);
    throw e;
  }
};

export const setArchiveDocument = document => ({
  type: Types.uploadAndArchiveScreen.SET_DOCUMENT,
  document,
});

export const setSavingPercentage = savingPercentage => ({
  type: Types.uploadAndArchiveScreen.SET_SAVING_PERCENTAGE,
  savingPercentage,
});

export const saveArchiveDocument = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: Types.uploadAndArchiveScreen.SAVING_ARCHIVE,
      savingArchive: true,
    });
    const { currentOrganization } = getState().organizations;
    const { document } = getState().uploadAndArchiveScreen;
    const formValues = getState().form.uploadAndArchive.values;
    const formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      formData.append(`archived_document[${key}]`, formValues[key]);
    });
    formData.append('archived_document[file]', document);
    formData.append('archived_document[file_name]', formValues.document_name);
    formData.append('archived_document[signed_date]', new Date());

    if (currentOrganization && currentOrganization.uuid) {
      formData.append('archived_document[organization_uuid]', currentOrganization.uuid);
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch(setSavingPercentage(percentCompleted));
      },
    };

    const { data: response } = await documentService.createArchivedDocument(formData, config);

    // update the state with the updated data
    response.signed_date = new Date(response.signed_date);

    if (formValues.notes) {
      await createNote(currentOrganization, response.uuid, formValues.notes);
    }

    const updatedDocument = {
      ...document,
      ...response,
    };

    dispatch({
      type: Types.uploadAndArchiveScreen.SET_DOCUMENT,
      document: updatedDocument,
    });

    dispatch({
      type: Types.uploadAndArchiveScreen.SET_SAVED,
      payload: true,
    });

    dispatch({
      type: Types.uploadAndArchiveScreen.SAVING_ARCHIVE,
      savingArchive: false,
    });
    return Promise.resolve(document);
  } catch (err) {
    dispatch({
      type: Types.uploadAndArchiveScreen.SAVING_ARCHIVE,
      savingArchive: false,
    });
    return Promise.reject(err);
  }
};

export const resetUploadAndArchiveScreen = () => ({
  type: Types.uploadAndArchiveScreen.RESET_SCREEN,
});

export const setSendDocumentModal = status => ({
  type: Types.uploadAndArchiveScreen.SET_SEND_DOCUMENT_MODAL,
  status,
});

export const sendArchivedDocument = payload => async (dispatch, getState) => {
  try {
    dispatch({
      type: Types.uploadAndArchiveScreen.SENDING_DOCUMENT,
      sending: true,
    });

    const { uuid } = getState().uploadAndArchiveScreen.document;

    await documentService.sendArchivedDocument(
      uuid,
      uploadAndArchiveParser.fromFormToSendDocumentRequest.parseForm(payload),
    );

    toast.success(Messages.documents.send.success);

    dispatch(setSendDocumentModal(false));
  } catch (error) {
    toast.error(Messages.documents.send.error);
  } finally {
    dispatch({
      type: Types.uploadAndArchiveScreen.SENDING_DOCUMENT,
      sending: false,
    });
  }
};

export const submitSendArchived = () => (dispatch) => {
  dispatch(submit('sendDocumentForm'));
};

export default {
  setArchiveDocument,
  saveArchiveDocument,
  resetUploadAndArchiveScreen,
  setSendDocumentModal,
  sendArchivedDocument,
  submitSendArchived,
  setSavingPercentage,
};
