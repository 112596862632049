import { isEmpty } from 'lodash';

const getHistoryByEventName = (history, eventName) => {
  let value = null;
  if (!history || isEmpty(history)) {
    return value;
  }
  history.forEach((event) => {
    const keys = Object.keys(event);
    keys.forEach((key) => {
      if (key === eventName) {
        value = event[eventName];
      }
    });
  });
  return value;
};

export default documents => documents.map((d) => {
  const history = (d.activityLogs.nodes || []).map(log => ({ [log.event]: log.eventDate }));
  const recipients = (d.recipients || {}).nodes || [];

  return {
    ...d,
    type: 'signed',
    recipients,
    previewUrl: d.document.previewImage.url,
    fileUrl: d.signedPdf.url,
    name: d.fileName,
    sender: d.user.email,
    from: d.user.email,
    to: recipients.map(r => r.email).join(', '),
    history,
    date: getHistoryByEventName(history, 'created') || getHistoryByEventName(history, 'create'),
    status: 'Completed',
  };
});
