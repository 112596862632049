import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';
import documentService from '../../services/document';
import DocumentPage from './DocumentPage';

class DocumentPageLoader extends Component {
  state = {
    imageUrl: null,
  };

  componentDidMount = async () => {
    const { documentUUID, id } = this.props.page;
    this.loadPageData(documentUUID, id);
  };

  componentDidUpdate = (prevProps) => {
    const {
      page, page: { id: nextId, documentUUID }, currentEditorPage, setCurrentEditorPage,
    } = this.props;
    const { page: { id } } = prevProps;
    if (!isEqual(page, currentEditorPage)) {
      setCurrentEditorPage(page);
    }
    if (nextId !== id) {
      this.loadPageData(documentUUID, nextId);
    }
  }
  getPageFields = () => {
    const { pageFields, page } = this.props;
    if (pageFields && pageFields.length > 0) {
      return pageFields
        // eslint-disable-next-line
        .filter(pageField => pageField.pageId === page.id && !pageField['_destroy']);
    }
    return [];
  }

  loadPageData = async (documentUUID, id) => {
    const { data: pageData } = await documentService.getPage(documentUUID, id);
    this.setState({ imageUrl: pageData.image_url });
  }


  render() {
    const {
      page,
      focusedPageField,
      canDragFields,
      onAddPageField,
      onChangePageField,
      onClickPageField,
      onClickFieldsLayer,
      onResizeHandlePress,
      onRemovePageField,
      setScaleRatio,
      scaleRatio,
      recipients,
      imageWidth,
      openFieldPageOptionModal,
      setEditorFocusedPageField,
      documentDimensions,
    } = this.props;
    return (
      <React.Fragment>
        {
          this.state.imageUrl ?
            <VisibilitySensor resizeCheck scrollCheck partialVisibility="top">
              {
                ({ isVisible }) =>
                  (<DocumentPage
                    documentDimensions={documentDimensions}
                    imageURL={this.state.imageUrl}
                    pageFields={this.getPageFields()}
                    recipients={recipients}
                    focusedPageField={focusedPageField}
                    canDragFields={canDragFields}
                    onFieldAdd={field =>
                      onAddPageField(field, page.documentUUID, page.id, scaleRatio)}
                    onFieldChange={field =>
                      onChangePageField(field, page.id, scaleRatio)}
                    onFieldClick={field => onClickPageField(field)}
                    onClickFieldsLayer={onClickFieldsLayer}
                    onResizeHandlePress={canDrag =>
                      onResizeHandlePress(canDrag)}
                    onFieldResize={field =>
                      onChangePageField(field, page.id, scaleRatio)}
                    onRemoveField={field => onRemovePageField(field)}
                    setScaleRatio={setScaleRatio}
                    scaleRatio={scaleRatio}
                    imageWidth={imageWidth}
                    setEditorFocusedPageField={setEditorFocusedPageField}
                    setFieldOptionModalOpen={openFieldPageOptionModal}
                    isVisible={isVisible}
                  />)
              }
            </VisibilitySensor>
            : <span>Loading File</span>
        }
      </React.Fragment>
    );
  }
}

DocumentPageLoader.propTypes = {
  page: PropTypes.object.isRequired,
  pageFields: PropTypes.array.isRequired,
  recipients: PropTypes.array.isRequired,
  focusedPageField: PropTypes.object.isRequired,
  canDragFields: PropTypes.bool.isRequired,
  onAddPageField: PropTypes.func.isRequired,
  onChangePageField: PropTypes.func.isRequired,
  onClickPageField: PropTypes.func.isRequired,
  onClickFieldsLayer: PropTypes.func.isRequired,
  onResizeHandlePress: PropTypes.func.isRequired,
  onRemovePageField: PropTypes.func.isRequired,
  setScaleRatio: PropTypes.func.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  openFieldPageOptionModal: PropTypes.func.isRequired,
  setEditorFocusedPageField: PropTypes.func.isRequired,
  setCurrentEditorPage: PropTypes.func.isRequired,
  currentEditorPage: PropTypes.object.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

export default DocumentPageLoader;
