/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';

import OrganizationTransferModal from './OrganizationTransferModal';
import graphqlClient from '../../../config/graphql';
import organizationActions from '../../../actions/organization';
import authActions, { fetchCurrentUser } from '../../../actions/auth';
import { setOpenUpdateModal, setSubscriptionFieldValue } from '../../../actions/subscriptions';
import { FETCH_INVITE_INFO, ACCEPT_INVITE } from './queries';
import { parsePlan } from '../../../utils/parsers/subscriptionParser';

const connect = Component => (props) => {
  const {
    user, setCurrentOrganization, currentOrganization,
    setOrganizationsValue, openOrganizationTransferModal,
    organizationTransferToken, setCurrentUser, selectedInvite,
    setUserOrganizations,
  } = props;
  const { email } = user;
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});

  const fetchInviteInformation = async () => {
    try {
      if (selectedInvite.uuid) {
        const { organization } = selectedInvite;
        setValues({
          ...organization,
          address: organization.address && organization.address.split('/-/').join(', '),
          members: organization.users.nodes.length,
          documentsCompleted: organization.completedDocumentsCount,
          invitePlan: parsePlan(organization.plan),
        });
      } else {
        const { data: { invite } } = await graphqlClient().query({
          query: FETCH_INVITE_INFO,
          fetchPolicy: 'network-only',
          variables: { token: organizationTransferToken },
        });
        const { organization } = invite;

        setValues({
          ...organization,
          address: organization.address.split('/-/').join(', '),
          members: organization.users.nodes.length,
          documentsCompleted: organization.completedDocumentsCount,
          invitePlan: parsePlan(organization.plan),
        });

        if (email !== invite.email) {
          setOrganizationsValue('openOrganizationTransferModal', false);
        }
      }
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  };

  const acceptInvite = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line
      const variables = organizationTransferToken ? { inviteToken: organizationTransferToken } : { inviteUuid: selectedInvite.uuid };
      await graphqlClient().mutate({
        mutation: ACCEPT_INVITE,
        variables,
      });
      const currentUser = await fetchCurrentUser();
       // eslint-disable-next-line react/prop-types
      await setCurrentUser({ ...user, ...currentUser });
       if (selectedInvite.uuid) {
        // eslint-disable-next-line react/prop-types
        const newOrg = selectedInvite.organization;
        await setUserOrganizations([...user.organizations,
          // eslint-disable-next-line react/prop-types
          { history: [], ...newOrg, users: [
            ...newOrg.users.nodes, 
            { email: user.email,
               role: 'owner',
               uuid: user.uuid, 
               full_name: `${user.first_name} ${user.last_name}` 
              }] }]);
      }
      await setCurrentOrganization(currentOrganization);

      setOrganizationsValue('openOrganizationTransferModal', false);
      setOrganizationsValue('openOrganizationTransferSuccessModal', true);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };

  const loadInformation = async () => {
    setLoading(true);
    await fetchInviteInformation();
    setLoading(false);
  };

  const handleDecline = () => {
    setOrganizationsValue('openOrganizationTransferModal', false);
    setOrganizationsValue('openDeclineOrganizationTransferModal', true);
  };

  useEffect(() => {
    if (organizationTransferToken || selectedInvite.uuid) loadInformation();
  }, [organizationTransferToken]);

  return (
    <Component
      {...props}
      loading={loading}
      // eslint-disable-next-line react/prop-types
      open={(organizationTransferToken || selectedInvite.uuid) && openOrganizationTransferModal}
      values={values}
      onAccept={acceptInvite}
      onClose={() => setOrganizationsValue('openOrganizationTransferModal', false)}
      onDecline={handleDecline}
    />
  );
};

export default RConnect(({ auth, organizationInvites, organizations }) => ({
  user: auth.user,
  openOrganizationTransferModal: organizationInvites.openOrganizationTransferModal,
  organizationTransferToken: organizationInvites.organizationTransferToken,
  selectedInvite: organizationInvites.selectedInvite,
  currentOrganization: organizations.currentOrganization,
}), {
  setCurrentOrganization: organizationActions.setCurrentOrganization,
  setOrganizationsValue: organizationActions.setOrganizationsValue,
  setCurrentUser: authActions.setCurrentUser,
  setUserOrganizations: authActions.setUserOrganizations,
  setOpenUpdateModal,
  setSubscriptionFieldValue,
})(connect(OrganizationTransferModal));
