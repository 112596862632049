import gql from 'graphql-tag';

export const SEND_INVITES = gql`
  mutation SendInvites(
    $recipients: [InviteRecipient!]!,
  ) {
    sendInvites(
      recipients: $recipients,
    ) {
      invites {
        nodes {
          email
          uuid
        }
      }
    }
  }
`;


export default { SEND_INVITES };
