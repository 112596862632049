import gql from 'graphql-tag';

export const DECLINE_SIGNATURE_REQUEST = gql`
  mutation DeclineSignatureRequest(
    $signatureRequestUuid: ID!
    $declineReason: String!
  ) {
    declineSignatureRequest(
      uuid: $signatureRequestUuid
      declineReason: $declineReason
    ) {
        signatureRequest{
          uuid
        }
      }
    }
`;

export const DELEGATE_SIGNATURE_REQUEST = gql`
  mutation DelegateSignatureRequest(
    $signatureRequestUuid: ID!
    $email: String!
    $comment: String!
  ) {
    delegateSignatureRequest(
      uuid: $signatureRequestUuid
      email: $email
      comment: $comment
    ) {
        signatureRequest{
          uuid
        }
      }
    }
`;

export default {
  DECLINE_SIGNATURE_REQUEST,
  DELEGATE_SIGNATURE_REQUEST,
};
