import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton, TextField } from 'material-ui';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { colors } from '../../utils';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().ensure()
    .required('Required'),
});

const ForgotPasswordForm = ({
  handleSubmit, values, errors, touched,
  setFieldValue, setFieldTouched, onCancel, style,
}) => {
  const setValue = (field, value) => {
    setFieldTouched(field, true, false);
    setFieldValue(field, value);
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={style}
    >
      <TextField
        name="email"
        className="mb-2"
        fullWidth
        floatingLabelText="Email address"
        autoComplete="Email"
        required
        value={values.email}
        errorText={touched.email && errors.email}
        onChange={e => setValue('email', e.target.value)}
      />
      <ButtonsSection className="d-flex justify-content-between">
        <CancelButton
          onClick={onCancel}
        >
            CANCEL
        </CancelButton>
        <RaisedButton
          label="SUBMIT"
          primary
          buttonStyle={{ width: '8rem' }}
          onClick={handleSubmit}
        />
      </ButtonsSection>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  style: PropTypes.object,
  values: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  setFieldValue: PropTypes.any.isRequired,
  setFieldTouched: PropTypes.any.isRequired,
};

ForgotPasswordForm.defaultProps = {
  style: undefined,
  onCancel: () => {},
};

const ButtonsSection = styled.div`
  padding-top: 1rem;
  width: 100%;
`;

export const SmallButtonContainer = styled.div`
  width: 3.375rem;
`;

const CancelButton = styled.div`
  border: 1px solid ${colors.blue};
  border-radius: 2px;
  color: ${colors.blue};
  font-size: 0.9rem;
  padding: 0.2rem 2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${colors.blue};
    color: white;
  }
`;

export default withFormik({
  validationSchema: ForgotPasswordSchema,
  enableReinitialize: true,
  mapPropsToValues: ({
    email,
    password,
  }) => ({
    email,
    password,
  }),
  handleSubmit: (values, { props }) => {
    props.handleSubmit(values);
  },
})(ForgotPasswordForm);

