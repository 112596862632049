import React from 'react';

const inputStyle = {
  width: '100%',
  height: '100%',
};

const Accept = () => (
  <button type="button" style={inputStyle}>Accept</button>
);

export default Accept;
