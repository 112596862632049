import React from 'react';
import CanvasDraw from 'react-canvas-draw';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Close from 'material-ui/svg-icons/navigation/close';
import trimCanvas from 'trim-canvas';
import { toast } from 'react-toastify';
import TextSignature from './TextSignature';
import { colors } from './../../utils';
import BluePixel from './../../assets/images/bluePixel.jpg';
import SignatureUpload from './SignatureUpload';
import Modal from '../Modal';

import draw from './../../assets/images/signing_icons/draw.png';
import keyboard from './../../assets/images/signing_icons/keyboard.png';
import upload from './../../assets/images/signing_icons/upload.png';
import drawSelected from './../../assets/images/signing_icons/drawSelected.png';
import keyboardSelected from './../../assets/images/signing_icons/keyboardSelected.png';
import uploadSelected from './../../assets/images/signing_icons/uploadSelected.png';
import { getBase64ImageMimeType } from '../../utils/file';
import {
  scaleValueWithConstant,
} from '../../utils/scaling';


const required = value => (value ? undefined : 'Required');

class Signature extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      applyEnabled: false,
      signatureImage: null,
      signatureMode: 'draw',
      printName: '',
      plainSignature: {
        text: '',
        width: 0,
        height: 0,
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.clearCanvas = this.clearCanvas.bind(this);
  }


  getPlaceholder = () => {
    const { drawType } = this.props;
    const placeholder = {
      signature: 'Type your Signature',
      initials: 'Type your Initials',
    };
    return placeholder[drawType] || '';
  }

  canvas = null;
  signatureImage = null;

  openModal() { this.setState({ modalOpen: true }); }

  closeModal() { this.setState({ modalOpen: false, applyEnabled: false }); }

  doSave(fieldName, image) {
    const { printName } = this.state;
    if (this.props.beforeSavePromise) {
      this.props.beforeSavePromise(fieldName, image)
        .then(() => {
          // success callback
          this.props.onSaved(image, printName);
          this.closeModal();
        }, () => {
          // error callback
        });
    } else {
      this.props.onSaved(image, printName);
      this.closeModal();
    }
  }

  saveImage() {
    switch (this.state.signatureMode) {
      case 'text': {
        // handle image creation from text signature
        const canvas = document.createElement('canvas');
        canvas.width = this.state.plainSignature.width + 20;
        canvas.height = this.state.plainSignature.height;

        const context = canvas.getContext('2d');

        context.font = '45px Dancing Script';
        context.fillStyle = 'black';
        context.textBaseline = 'middle';

        context.fillText(this.state.plainSignature.text, 10, (canvas.height / 2) - 5);

        const signature = canvas.toDataURL();

        if (signature !== 'data:,') this.doSave(this.props.fieldName, signature);
        break;
      }
      case 'draw': {
        // get the canvas reference
        // trim the whitespace
        // convert the image to base 64
        // send the result via a prop
        this.doSave(this.props.fieldName, trimCanvas(this.canvas.canvas).toDataURL());
        break;
      }
      case 'upload': {
        const mimeType = getBase64ImageMimeType(this.signatureImage).toLowerCase();
        if (mimeType === 'png' || mimeType === 'jpeg' || mimeType === 'jpg') {
          this.doSave(this.props.fieldName, this.signatureImage);
        } else {
          toast.error('The file is not an image. Please try with a PNG, JPG or JPEG file');
        }
        break;
      }
      default:
        break;
    }
  }

  clearCanvas() {
    this.canvas.clear();
    this.setState({ applyEnabled: false });
  }

  updateTextSignature = (signature) => {
    this.setState({
      plainSignature: signature,
    });
  }

  handleClick = () => {
    const { onClick } = this.props;
    if (!onClick) {
      this.openModal();
      return;
    }
    const shouldOpenModal = onClick();
    if (shouldOpenModal) {
      this.openModal();
    }
  }

  isDesktop = () => {
    if (window.innerWidth > window.innerHeight) {
      return window.innerHeight > 768;
    }
    if (window.innerHeight > window.innerWidth) {
      return window.innerWidth > 768;
    }
    return false;
  }

  renderSignatureHandler(mode) {
    const { drawType } = this.props;
    switch (mode) {
      case 'text':
        return (
          <CanvasWrapper>
            <ClearCanvasIcon
              color={colors.textLight}
              style={{ zIndex: 1 }}
            />
            <TextSignature
              onChange={this.updateTextSignature}
              placeholder={this.getPlaceholder()}
              value={this.state.plainSignature}
              style={{
                position: 'relative',
                bottom: '2rem',
                width: this.isDesktop() ? '500' : window.innerWidth - 50,
              }}
              type={drawType}
            />
            <ClearTextContainer>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => this.setState({
                  plainSignature: {
                    text: '',
                    width: 0,
                    height: 0,
                  },
                })}
                style={{ cursor: 'pointer' }}
              >Clear
              </span>
            </ClearTextContainer>
          </CanvasWrapper>
        );
      case 'draw':
        return (
          <CanvasWrapper>
            <ClearCanvasIcon
              color={colors.textLight}
            />

            <DrawContainer
              onClick={() => { this.setState({ applyEnabled: true }); }}
              onTouchStart={() => { this.setState({ applyEnabled: true }); }}
            >
              <CanvasDraw
                canvasWidth={this.isDesktop() ? '500' : window.innerWidth - 50}
                canvasHeight="150"
                brushSize="2"
                ref={(ref) => {
                this.canvas = ref;
              }}
                style={{
                background: `url(${BluePixel}) repeat-x center center`,
              }}
              />
            </DrawContainer>
            <ClearTextContainer>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span
                onClick={this.clearCanvas}
                style={{ cursor: 'pointer' }}
              >Clear
              </span>
            </ClearTextContainer>
          </CanvasWrapper>
        );
      case 'upload':
        return (
          <UploadWrapper>
            <SignatureUpload
              onFilesLoaded={(base64Signature) => {
                  this.setState({ signatureImage: base64Signature });
                  this.signatureImage = base64Signature;
              }}
              onClear={() => {
                  this.setState({ signatureImage: null });
              }}
            />
          </UploadWrapper>
        );
      default:
        return null;
    }
  }

  renderModalContent() {
    const { drawType } = this.props;
    return (
      <React.Fragment>
        <ModalHeader>
          <DrawChoice selected={this.state.signatureMode === 'text'} onClick={() => { this.setState({ signatureMode: 'text' }); }}>
            <TabSwitch
              src={this.state.signatureMode === 'text' ? keyboardSelected : keyboard}
              alt="Type"
            />
          </DrawChoice>
          <DrawChoice selected={this.state.signatureMode === 'draw'} onClick={() => { this.setState({ signatureMode: 'draw', applyEnabled: false }); }}>
            <TabSwitch src={this.state.signatureMode === 'draw' ? drawSelected : draw} alt="Draw" />
          </DrawChoice>
          <DrawChoice style={{ display: this.isDesktop() ? 'block' : 'none' }} selected={this.state.signatureMode === 'upload'} onClick={() => { this.setState({ signatureMode: 'upload' }); }}>
            <TabSwitch src={this.state.signatureMode === 'upload' ? uploadSelected : upload} alt="Upload" />
          </DrawChoice>
          {/* <DrawChoice onClick={() => { this.setState({ signatureMode: 'upload' }); }}>
            <TabSwitch src={mobileDraw} alt="Mobile" />
          </DrawChoice> */}
        </ModalHeader>
        <div>
          {this.state.textSignature}
          {this.renderSignatureHandler(this.state.signatureMode)}
        </div>
        <ContainerPrintName>
          <TextInput
            value={this.state.printName}
            onChange={e => this.setState({ printName: e.target.value })}
            maxLength={drawType === 'initials' ? 4 : 30}
            placeholder={this.getPlaceholder()}
          />
          <div style={{ fontSize: '0.7rem', color: colors.defaultImageGray, marginTop: '0.6rem' }}>
            Print Name
          </div>
        </ContainerPrintName>
      </React.Fragment>
    );
  }

  render() {
    const { fieldValue, width, height } = this.props;
    const {
      signatureMode, applyEnabled, plainSignature, signatureImage,
    } = this.state;
    return (
      <Container style={{ width, height }}>
        <SignatureWrapper>
          <Modal
            title=""
            open={this.state.modalOpen}
            modal={false}
            content={this.renderModalContent()}
            primaryLabel={this.props.modalSaving ? 'Saving...' : 'Apply'}
            secondaryLabel="Cancel"
            onPrimaryClick={this.saveImage}
            onSecondaryClick={this.closeModal}
            contentStyle={{
              width: this.isDesktop() ? '548px' : '100%',
              height: this.isDesktop() ? '475px' : '100%',
              maxWidth: this.isDesktop() ? '548' : '100%',
              transform: 'none',
            }}
            primaryDisabled={this.props.modalSaving
            || (!applyEnabled && signatureMode === 'draw')
            || (!plainSignature.text && signatureMode === 'text')
            || (!signatureImage && signatureMode === 'upload')}

          />
          <SignaturePreview
            width={this.props.width}
            height={this.props.height}
            style={this.props.style}
            onClick={this.handleClick}
            bgcolor={this.props.bgcolor}
          >
            {fieldValue && (<SignatureImage src={fieldValue} />)}
            {!fieldValue && this.props.placeholder ?
              (<Placeholder scaleRatio={this.props.scaleRatio}>
                {this.props.placeholder
              }</Placeholder>) : null}
          </SignaturePreview>
          <input name={this.props.fieldName} type="hidden" value={fieldValue} validate={this.props.isRequired ? [required] : null} />
        </SignatureWrapper>

        {this.props.editable && <CloseContainer onClick={this.props.onRemove}>x</CloseContainer>}
      </Container>
    );
  }
}

Signature.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  fieldName: PropTypes.string.isRequired,
  drawType: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  isRequired: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  modalSaving: PropTypes.bool,
  beforeSavePromise: PropTypes.func,
  scaleRatio: PropTypes.number,
  bgcolor: PropTypes.string,
  onRemove: PropTypes.func,
  editable: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Signature.defaultProps = {
  isRequired: false,
  style: {},
  placeholder: null,
  modalSaving: false,
  beforeSavePromise: null,
  bgcolor: 'rgba(255, 255, 0, 0.3)',
  fieldValue: undefined,
  onRemove: () => {},
  editable: false,
  scaleRatio: 1,
};

const DrawContainer = styled.div``;

const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  & > div > div {
  border: dotted 1px rgb(84, 188, 234) !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  }
`;

const CloseContainer = styled.div`
  text-align: right;
  height: 5px;
  right: 0.8rem;
  cursor: pointer;
  position: relative;
  top: -4px;
  font-size: 0.8rem;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
  @media (orientation: landscape) and (max-width: 768px) {
    margin-top: 4em;
  }
`;

const TabSwitch = styled.img`
  cursor: pointer;
`;

const SignaturePreview = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${props => props.bgcolor};
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const Placeholder = styled.span`
  font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth, imageWidth)}px;
  color: #236BA0;
  font-weight: 500;
`;

const SignatureWrapper = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;
const SignatureImage = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: top;
`;

const DrawChoice = styled.div`
  background-color: ${props => props.selected && '#e0e0e0'};
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 42px;
  margin: 1rem 3px;
`;

const ClearCanvasIcon = styled(Close)`
  position: absolute;
  top: 55px;
  left: 3px;
`;

const UploadWrapper = styled.div`
  width: 500px;
`;

const ClearTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  color: ${colors.blue};

  & span {
    position: relative;
    bottom: 4rem;
  }
`;

const TextInput = styled.input`
  width: 12rem;
  font-size: 0.8rem;
  padding: 0.3rem 0.8rem;
  color: black;
  border: 1px solid #A7A7A7;
  border-radius: 4px;
  text-align: left;
  &:focus, &:active{
    border: 2px solid ${colors.blue};
    outline: none;
  }
`;

const ContainerPrintName = styled.div`
  position: relative;
  bottom: 1rem;
  @media (orientation: landscape) and (max-width: 1024px) {
    display: none;
  } 
`;

export default Signature;
