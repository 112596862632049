import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from './../utils';

const SectionLoader = () => (
  <Loader>
    <Circle>●</Circle>
    <Circle>●</Circle>
    <Circle>●</Circle>
    <Circle>●</Circle>
    <Circle>●</Circle>
    <Circle>●</Circle>
  </Loader>
);

const sweep = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(200px);
  }
`;

const Loader = styled.div`
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  width: 100%;
  color: ${colors.blue};
  text-align: center;
  
  label:nth-child(6) {
   animation: ${sweep} 3s infinite ease-in-out;
  }

  label:nth-child(5) {
    animation: ${sweep} 3s 100ms infinite ease-in-out;
  }

  label:nth-child(4) {
    animation: ${sweep} 3s 200ms infinite ease-in-out;
  }

  label:nth-child(3) {
    animation: ${sweep} 3s 300ms infinite ease-in-out;
  }

  label:nth-child(2) {
    animation: ${sweep} 3s 400ms infinite ease-in-out;
  }

  label:nth-child(1) {
    animation: ${sweep} 3s 500ms infinite ease-in-out;
  }
`;

const Circle = styled.label`
  font-size: 2.5rem;
  opacity: 0;
  display: inline-block;
`;

export default SectionLoader;
