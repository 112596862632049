import { isEmpty } from 'lodash';
import { getMessage } from './messagesGQL';


const parseResponse = (activityLogs = []) => {
  if (activityLogs && activityLogs.length > 0) {
    return activityLogs.map(activityLog => ({
      id: activityLog.id,
      description: getMessage(activityLog),
      date: new Date(activityLog.eventDate),
    }));
  }
  return [];
};

const sortResponse = (activityLogs) => {
  if (activityLogs && activityLogs.length > 0) {
    return activityLogs.sort((a, b) => b.date - a.date);
  }
  return [];
};

const filterActivityLogs = (activityLogs) => {
  if (activityLogs && activityLogs.length > 0) {
    return activityLogs.filter(activityLog => !isEmpty(activityLog.entity));
  }
  return [];
};

const prepareResponse = activityLogs => parseResponse(filterActivityLogs(activityLogs));

export default {
  getLogMessage: getMessage,
  parseResponse,
  sortResponse,
  prepareResponse,
};
