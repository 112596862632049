import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  message: '',
  confirmOpen: false,
  callback: null,
  buttonLabel: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.confirmDialog.SHOW_CONFIRM: {
      const { message, callback, buttonLabel } = action.payload;
      return {
        ...state,
        message,
        callback,
        confirmOpen: true,
        buttonLabel,
      };
    }
    case Types.confirmDialog.HIDE_CONFIRM: {
      return {
        ...INITIAL_STATE,
      };
    }
    case Types.confirmDialog.SET_RESULT: {
      const { result } = action;
      if (state.callback) {
        state.callback(result);
      }

      return {
        ...state,
        confirmOpen: false,
        callback: null,
      };
    }
    default:
      return state;
  }
};
