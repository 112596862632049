import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { change } from 'redux-form';

import editorActions from '../../actions/editor';
import fieldsActions from '../../actions/fields';
import graphqlClient from '../../config/graphql';
import organizationActions from '../../actions/organization';
import templateEditorScreenActions from '../../actions/templateEditorScreen';
import { fetchDocumentDimensions } from '../../actions/documentDimensions';
import { UPDATE_TEMPLATE_FIELDS } from './queries';
import { mapTemplatePageFields } from './mapping';

const renderWrappedComponent = Component => (props) => {
  React.useEffect(() => {
    // eslint-disable-next-line react/prop-types
    props.fetchDocumentDimensions();
  }, []);
  const updateTemplateFields = async () => {
    // eslint-disable-next-line react/prop-types
    const { templateEditorScreen, pageFields, documents } = props;

    await graphqlClient().mutate({
      variables: {
        templateUuid: templateEditorScreen.template.uuid,
        pageFields: mapTemplatePageFields(pageFields, documents),
      },
      mutation: UPDATE_TEMPLATE_FIELDS,
    });
  };

  return (
    <Component
      {...props}
      updateTemplateFields={updateTemplateFields}
    />
  );
};

const mapStateToProps = ({
  editor, templateEditorScreen, fields, documentDimensions,
}) => ({
  pageFields: editor.pageFields,
  documents: templateEditorScreen.template && templateEditorScreen.template.documents,
  templateEditorScreen,
  standardFields: fields.standardFields,
  customFields: fields.customFields,
  documentDimensions,
  editor,
});

const mapDispatchToProps = () => {
  const { fetchFields } = fieldsActions;
  const { fetchTemplate } = templateEditorScreenActions;
  const {
    resetEditor,
    loadEditorDataFromTemplate,
    setEditorField,
    setEditorFocusedPageField,
    addEditorPageField,
    updateEditorPageField,
    toggleEditorDragging,
    removePageFieldFromEditorPage,
    setEditorScaleRatio,
    setCurrentEditorPage,
    updateEditorDocumentPageFields,
  } = editorActions;
  const {
    enableOrganizationChange,
    disableOrganizationChange,
  } = organizationActions;
  return {
    fetchFields,
    fetchTemplate,
    resetEditor,
    setCurrentEditorPage,
    loadEditorDataFromTemplate,
    setEditorField,
    setEditorFocusedPageField,
    addEditorPageField,
    updateEditorPageField,
    toggleEditorDragging,
    removePageFieldFromEditorPage,
    setEditorScaleRatio,
    updateEditorDocumentPageFields,
    changeCustomFormFieldValue: (name, value) => change('customFieldForm', name, value),
    enableOrganizationChange,
    disableOrganizationChange,
    fetchDocumentDimensions,
  };
};

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
