import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import OrganizationForm from '../forms/OrganizationForm/OrganizationForm';
import Actions from '../../actions';

class AddNewOrganizationModal extends Component {
  handleCancel = () => {
    this.props.toggleUserProfileOrganizationModal(false);
  };

  render = () => {
    const {
      userProfileScreen: { organizationModalVisible, savingOrganization },
    } = this.props;

    return (
      <Dialog
        open={organizationModalVisible}
        title="Add Organization"
        autoScrollBodyContent
      >
        <OrganizationForm
          onCancel={this.handleCancel}
          loading={savingOrganization}
        />
      </Dialog>
    );
  }
}

AddNewOrganizationModal.propTypes = {
  toggleUserProfileOrganizationModal: PropTypes.func.isRequired,
  userProfileScreen: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  ...state,
});

export default connect(mapStateToProps, Actions)(AddNewOrganizationModal);
