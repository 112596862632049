import { identity } from 'lodash';

import Types from '../constants/actionTypes';

const {
  SET_IMAGE_WIDTH,
} = Types.documentDimensions;

const INITIAL_STATE = {
  IMAGE_WIDTH: 850,
  MAX_IMAGE_WIDTH: 1100,
  MIN_IMAGE_WIDTH: 650,
};

const states = {
  [SET_IMAGE_WIDTH]: (s, { width }) => ({
    ...s,
    IMAGE_WIDTH: width,
    MAX_IMAGE_WIDTH: width + 250,
    MIN_IMAGE_WIDTH: width - 200,
  }),
};

export default (state = INITIAL_STATE, action) => (states[action.type] || identity)(state, action);
