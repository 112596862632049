import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from '../..';

class VoidRequestModal extends Component {
  handleVoidClick = () => {
    const { onVoidClick, onCancel } = this.props;
    onVoidClick();
    onCancel();
  }

  renderContent = () => (
    <Wrapper>
      <Title>Void Signature Request</Title>
      <Description>
        Are you sure you want to void this signature request? Your
        document will be archived for later use.
      </Description>
    </Wrapper>
  );

  render = () => {
    const { onCancel } = this.props;
    return (
      <Modal
        open
        title=""
        content={this.renderContent()}
        onPrimaryClick={this.handleVoidClick}
        onSecondaryClick={onCancel}
        primaryLabel="Void"
        contentStyle={{ maxWidth: '550px' }}
      />
    );
  }
}

VoidRequestModal.propTypes = {
  onVoidClick: PropTypes.func,
  onCancel: PropTypes.func,
};

VoidRequestModal.defaultProps = {
  onVoidClick: () => {},
  onCancel: () => {},
};

const Wrapper = styled.div`
  color: black;
  font-weight: 500;
`;

const Title = styled.div`
  font-size: 24px;
  font-family: DIN-Medium;
`;

const Description = styled.div`
  margin: 40px 0;
  font-size: 20px;
  font-weight: 500;
  font-family: DIN-Medium;
`;

export default VoidRequestModal;
