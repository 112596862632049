import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RaisedButton, Dialog } from 'material-ui';
import connect from './connect';
import LogoImage from '../../../assets/images/logo.svg';
import SuccessCheck from '../../SuccessCheck';

const OrganizationInviteSuccessModal = ({ onClose, open }) => {
  const renderContent = () => (
    <Wrapper>
      <LogoContainer>
        <Logo src={LogoImage} />
      </LogoContainer>
      <TitleContainer>
        <Title>Success!</Title>
        <Description>
          You&apos;re now an organization member!
        </Description>
      </TitleContainer>
      <ImageContainer>
        <SuccessCheck style={{ width: '10rem', height: '10rem', margin: 0 }} />
      </ImageContainer>
      <ButtonsSection>
        <RaisedButton
          label="Close"
          onClick={onClose}
          buttonStyle={{ width: '12rem' }}
          primary
        />
      </ButtonsSection>
    </Wrapper>
  );

  return (
    <Dialog
      open={open}
      autoScrollBodyContent
      modal
    >
      {renderContent()}
    </Dialog>
  );
};

OrganizationInviteSuccessModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

OrganizationInviteSuccessModal.defaultProps = {
  onClose: () => {},
};

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
padding: 0 40px;
`;

const TitleContainer = styled.div`
  text-align: center;
  font-family: DIN-Medium;
`;

const Title = styled.div`
  font-size: 25px;
`;

const Description = styled.div`
  font-size: 20px;
  margin-top: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0;
`;

const ButtonsSection = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 230px;
  height: auto;
  margin-bottom: 0.375rem;
  align-self: center;
`;

export default connect(OrganizationInviteSuccessModal);
