import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlatButton, RaisedButton, TextField } from 'material-ui';
import connect from './connect';
import { animations } from '../../../utils';
import Modal from '../../Modal';

const DeclineOrganizationInviteModal = ({
  onSend, onBack, open,
}) => {
  const [message, setMessage] = useState('');

  const renderModalContent = () => (
    <Box>
      <Title>Decline organization invitation</Title>
      <Description>
        Please explain the reason for declining organization invitation.
        <div>This will be sent to the existing owner.</div>
      </Description>
      <TextField
        hintText="Your message"
        floatingLabelText="Message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        style={{ width: '450px' }}
      />
      <Actions>
        <FlatButton
          label="back"
          style={{ borderWidth: '1px', borderStyle: 'solid' }}
          onClick={onBack}
          primary
        />
        <RaisedButton
          label="send"
          disabled={!message}
          onClick={() => onSend(message)}
          primary
        />
      </Actions>
    </Box>
  );
  return (
    <Modal
      open={open || false}
      modal={false}
      size="lg"
      content={renderModalContent()}
      primaryStyle={{ display: 'none' }}
      secondaryStyle={{ display: 'none' }}
      titleStyle={{ padding: '50px 0px 50px 24px', fontFamily: 'DIN-Medium' }}
    />
  );
};

DeclineOrganizationInviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSend: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

const Box = styled.div`
  width: 100%;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

const Title = styled.div`
  font-size: 22px;
  font-family: DIN-Medium;
  margin-top: 40px;
  margin-bottom: 1rem;
`;

const Description = styled.div`
  font-size: 20px;
  font-family: DIN-Medium;
  margin: 10px 0;
`;

const Actions = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
`;

export default connect(DeclineOrganizationInviteModal);
