import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton, FlatButton } from 'material-ui';

import SubscriptionCard from '../../../components/SubscriptionCard';
import { Modal } from '../..';

const ModalContent = ({ selectedSubscription: sp }) => (
  <div
    className="d-flex justify-content-between"
    style={{
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: 'DIN-Medium',
      fontWeight: 500,
      marginTop: '3rem',
      marginBottom: '2.5rem',
    }}
  >
    <div style={{ width: 270 }}>
      <div style={{ fontSize: 24, marginBottom: '3rem' }}>
        Are you sure you want to change your plan?
      </div>

      <div style={{ fontSize: 18 }}>
        If you select this plan you will lose some features from your current plan.
        {sp.planType === 'FREE'
          ? ' You will lose all your current templates and organization members, this change is irreversible. '
          : ' You may need to select content to save in the next screen. '}
        <br />
        <br />
        Ready to change?
      </div>
    </div>

    <SubscriptionCard
      {...sp}
      readOnly
      style={{ margin: '0 3rem', height: 340 }}
    />
  </div>
);

ModalContent.propTypes = {
  selectedSubscription: PropTypes.object.isRequired,
};

const ModalButtons = ({ onConfirm, onCancel }) => (
  <Buttons>
    <div style={{ display: 'inline-block', marginRight: '1rem', width: 122 }}>
      <FlatButton
        primary
        label="Cancel"
        className="cancel-button"
        onClick={onCancel}
      />
    </div>
    <RaisedButton
      primary
      label="Next"
      className="confirm-button"
      onClick={onConfirm}
    />
  </Buttons>
);

ModalButtons.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const SubscriptionConfirmation = ({ isOpen, ...props }) => (
  <Modal
    open={isOpen}
    contentStyle={{ width: 640 }}
    content={ModalContent(props)}
    actions={ModalButtons(props)}
  />
);

SubscriptionConfirmation.propTypes = { isOpen: PropTypes.bool };
SubscriptionConfirmation.defaultProps = { isOpen: false };

const Buttons = styled.div`
  margin-bottom: 1rem;
  padding-right: 1rem;
  .cancel-button {
    color: #03a9f4 !important;
    width: 100%;
  }
  .confirm-button {
    width: 122px;
    & button {
      background-color: #03a9f4 !important;
    }
  }
`;

export {
  SubscriptionConfirmation as default,
  SubscriptionConfirmation,
};
