import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton } from 'material-ui';
import parser from '../utils/parserQueryString';
import SectionLoader from '../components/SectionLoader';
import Actions from '../actions';
import logo from '../assets/images/logo.svg';
import { colors } from '../utils';

class SignedDocumentScreen extends Component {
  componentDidMount = () => {
    this.params = parser(this.props.location.search, { ignoreQueryPrefix: true });

    this.props.fetchSignedDocument(
      this.params.t,
      this.params['access-token'],
      this.params.client,
      this.params.uid,
    );
  };

  params = null;

  handleDownload = () => {
    const { downloadSignedDocument, signedDocumentScreen: { document } } = this.props;

    downloadSignedDocument(document.signed_pdf_url);
  };

  renderPageBody = () => {
    const { error, document } = this.props.signedDocumentScreen;

    return (error
      ? <ErrorMessage>{error}</ErrorMessage>
      : <PDFContainer type="application/pdf" data={`${document.signed_pdf_url}#toolbar=0&navpanes=0`} />
    );
  }

  render() {
    const { error, loading, downloading } = this.props.signedDocumentScreen;
    const downloadLabel = downloading ? 'Downloading...' : false;
    const loadingLabel = loading ? 'Loading...' : false;

    return (
      <PageContainer>
        <PageHeader>
          <Header />
          <ButtonContainer>
            <RaisedButton
              primary
              label={downloadLabel || loadingLabel || 'Download'}
              onClick={this.handleDownload}
              disabled={!!error || loading || downloading}
            />
          </ButtonContainer>
        </PageHeader>
        <PageBody>
          {loading
            ? <SectionLoader />
            : this.renderPageBody()
          }
        </PageBody>
      </PageContainer>
    );
  }
}

SignedDocumentScreen.propTypes = {
  location: PropTypes.object.isRequired,
  fetchSignedDocument: PropTypes.func.isRequired,
  downloadSignedDocument: PropTypes.func.isRequired,
  signedDocumentScreen: PropTypes.object.isRequired,
};

const PageContainer = styled.div`
  padding-top: 5rem;
`;

const PageHeader = styled.header`
  padding: 1rem 0px;
  margin-bottom: 10px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
`;

const Header = styled.div`
  font-size: 1.3rem;
  height: 3rem;
  width: 16rem;
  background-image: url(${logo});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  margin-right: 1rem;
  margin-top: 0.375rem;
`;

const PageBody = styled.div`
  min-width: 200px;
  max-width: 60%;
  border-radius: 7px;
  background-color: ${colors.white};
  padding: 15px;
  text-align: center;
  margin: 2rem auto;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
`;

const PDFContainer = styled.object`
  min-width: 200px;
  width: 80%;
  height: 100vh;
  margin: 2rem auto;
`;

const ErrorMessage = styled.p`
  margin: 2rem auto;
  font-size: 1.5rem;
  color: ${colors.danger};
`;

const mapStateToProps = ({ signedDocumentScreen }) => ({ signedDocumentScreen });

export default connect(mapStateToProps, Actions)(SignedDocumentScreen);
