import Types from '../constants/actionTypes';

export const showConfirmDialog = (message, callback, buttonLabel) => ({
  type: Types.confirmDialog.SHOW_CONFIRM,
  payload: {
    message,
    callback,
    buttonLabel,
  },
});

export const confirmDialogReturn = result => (dispatch) => {
  dispatch({
    type: Types.confirmDialog.SET_RESULT,
    result,
  });
};

export default {
  showConfirmDialog,
  confirmDialogReturn,
};
