import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { ErrorUpdateCreditCardForm } from '../../forms';
import stripeApiKey from '../../../constants/stripeKey';
import { Modal } from '../..';

export const ErrorUpdateCreditCardModal = ({ onUpdate, onCancel, open }) => (
  <StyledModal
    open={open}
    modal
    isScrollable
    contentStyle={{ width: '1250px' }}
    autoScrollBodyContent
    content={
      <StripeProvider apiKey={stripeApiKey}>
        <Elements>
          <ErrorUpdateCreditCardForm
            onUpdate={onUpdate}
            onCancel={onCancel}
          />
        </Elements>
      </StripeProvider>}
    actions={<div />}
  />
);

const StyledModal = styled(Modal)`
  & > div > div {
    max-width: 980px !important;
  }
  & > div > div > div {
    padding: 3rem 0rem 0rem;
  }
  & h3 {
    text-align: center;
  }
`;


ErrorUpdateCreditCardModal.propTypes = {
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
};

ErrorUpdateCreditCardModal.defaultProps = {
  onUpdate: () => {},
  onCancel: () => {},
  open: false,
};

export default ErrorUpdateCreditCardModal;
