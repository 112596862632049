import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../utils/colors';


const DraggableItemDivider = (props) => {
  const { isHover } = props;
  return (<Divider hover={isHover} />);
};

DraggableItemDivider.propTypes = {
  isHover: PropTypes.bool.isRequired,
};

const Divider = styled.div`
  display: ${({ hover }) => (hover ? 'block' : 'none')};
  width: 100%;
  height: 0.25rem;
  background-color: ${({ hover }) => (hover ? colors.blue : 'transparent')};
`;

export default DraggableItemDivider;
