import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import {
  scaleValues,
  scaleValue,
  scaleValueWithConstant,
} from '../../../utils/scaling';

const Button = ({
  field, scaleRatio, imageWidth, onClick, onResizeStart, onResizeStop, recipients,
  // eslint-disable-next-line no-unused-vars
  mode, isRequired, width, height, onSaved, onChange,
}) => {
  const currentRecipient = recipients && recipients.find(r => r.uuid === field.recipientUuid);
  const renderSignButtonComponent = () => (
    <FieldContainer
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      width={width > scaleValue(50, scaleRatio) ? width : scaleValue(50, scaleRatio)}
      height={height > scaleValue(30, scaleRatio) ? height : scaleValue(30, scaleRatio)}
    >
      <ButtonContent
        sign
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
      >
        {isRequired &&
        <RequiredMark scaleRatio={scaleRatio} imageWidth={imageWidth} >*</RequiredMark>}
        {field.value ? field.value : field.field.name}
      </ButtonContent>
    </FieldContainer>
  );

  return mode === 'sign' ? renderSignButtonComponent() : (
    <ResizableBox
      width={field.width > scaleValue(50, scaleRatio) ? field.width : scaleValue(50, scaleRatio)}
      height={field.height > scaleValue(30, scaleRatio) ? field.height : scaleValue(30, scaleRatio)}
      minConstraints={scaleValues([50, 30], scaleRatio)}
      maxConstraints={scaleValues([300, 70], scaleRatio)}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      onClick={onClick}
    >
      <ButtonContent scaleRatio={scaleRatio} imageWidth={imageWidth}>
        <IndicatorContainer recipientColor={currentRecipient && currentRecipient.color}>
          <div className="indicator" />
        </IndicatorContainer>
        {field.required &&
        <RequiredMark scaleRatio={scaleRatio} imageWidth={imageWidth} >*</RequiredMark>}
        {field.value ? field.value : field.name}
      </ButtonContent>
    </ResizableBox>
  );
};

Button.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onResizeStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  recipients: PropTypes.array,
  mode: PropTypes.string,
  isRequired: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onSaved: PropTypes.func,
  onChange: PropTypes.func,
};

Button.defaultProps = {
  onClick: () => {},
  onResizeStop: () => {},
  onResizeStart: () => {},
  recipients: [],
  mode: 'edit',
  isRequired: false,
  width: 0,
  height: 0,
  onSaved: () => {},
  onChange: () => {},
};

const FieldContainer = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
   & div {
     background-color: #B3E1F5;
     border: 1px solid #3190C0;
     display: flex;
     padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
     align-items: flex-start;
     ::placeholder {  
       font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
       color: #236BA0;
       font-weight: 500;
    }
   }
`;

const RequiredMark = styled.span`
  && {
    margin: 0 ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(3, scaleRatio, imageWidth)}px;;
    color: red;
    font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(10, scaleRatio, imageWidth)}px;
    line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(14, scaleRatio, imageWidth)}px;
  }
`;

const ButtonContent = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  margin: 0 auto;
  height: 100%;
  justify-content: center;
  cursor: ${props => props.sign && 'pointer'};
  align-items: center;
  color: #236BA0;
  border: 1px solid #6BB5D5;
  line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(16, scaleRatio, imageWidth)}px;
  font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(16, scaleRatio, imageWidth)}px;
`;


const IndicatorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%
  position: absolute;
  top: 8px;
  left: 8px;
  height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;

  & > .indicator {
    border-radius: 50%;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    background-color: ${props => props.recipientColor};
  }

`;

export default Button;
