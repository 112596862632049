import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { getFormValues } from 'redux-form';
import * as ApiKeysActions from '../../../actions/apiAndKeys';
import * as ConfirmDialogActions from '../../../actions/confirmDialog';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render = () => (<Component {...this.props} />)
  };

const mapDispatchToProps = () => {
  const {
    loadApiKeys,
    toggleApiAndKeysModal,
    editApiKey,
    createApiKey,
    regenerateApiKey,
    setCurrentApiKey,
    deleteApiKey,
  } = ApiKeysActions;
  const {
    showConfirmDialog,
  } = ConfirmDialogActions;
  return {
    loadApiKeys,
    toggleApiAndKeysModal,
    editApiKey,
    createApiKey,
    regenerateApiKey,
    showConfirmDialog,
    setCurrentApiKey,
    deleteApiKey,
  };
};

function mapStateToProps({ apiAndKeysSection, organizations, form }) {
  return {
    form,
    apiAndKeysSection,
    currentOrganization: organizations.currentOrganization,
    formValues: getFormValues('addApiKeyForm'),
  };
}

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
