import React from 'react';
import { connect as ReduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import SendReportForm from './SendReportForm';
import * as AnalyticsScreenActions from '../../../actions/analyticsScreen';
import graphqlClient from '../../../config/graphql';
import { SEND_REPORT } from './queries';
import Messages from '../../../constants/toastMessages';

const connect = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const onCancel = () => props.toggleSendReportModal(false);
  const onSend = async ({ recipients, sendToMe = false }) => {
    // eslint-disable-next-line react/prop-types
    const { subdomain, reportVariables } = props;
    try {
      await graphqlClient(subdomain).mutate({
        mutation: SEND_REPORT,
        variables: {
          ...reportVariables,
          recipients: recipients.split(',').map(e => e.trim()),
          sendToMe,
        },
      });
      toast.success(Messages.analytics.sendReport.success);
      onCancel();
    } catch (e) {
      toast.error(e.message);
      onCancel();
    }
  };

  return (
    <Component
      {...props}
      onCancel={onCancel}
      onSend={onSend}
    />
  );
};

const mapStateToProps = ({ auth, organizations, analyticsScreen }) => ({
  user: auth.user,
  subdomain: organizations.currentOrganization.subdomain,
  reportVariables: analyticsScreen.reportVariables,
});

const mapDispatchToProps = () => {
  const { toggleSendReportModal } = AnalyticsScreenActions;
  return {
    toggleSendReportModal,
  };
};

export default ReduxConnect(
  mapStateToProps, mapDispatchToProps())(connect(SendReportForm));
