import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { change } from 'redux-form';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import fieldsActions from '../../actions/fields';
import documentEditorScreenActions from '../../actions/documentEditorScreen';
import { fetchDocumentDimensions } from '../../actions/documentDimensions';
import editorActions from '../../actions/editor';
import organizationActions from '../../actions/organization';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    componentDidMount() {
      // eslint-disable-next-line react/prop-types
      const { sendDocumentScreen: { documents }, history, fetchDocDimensions } = this.props;
      fetchDocDimensions();
      if (isEmpty(documents)) {
        // eslint-disable-next-line react/prop-types
        history.push('/send-document');
      }
    }

    savePageFields = async () => {
      // eslint-disable-next-line react/prop-types
      const { history, editor: { recipients, pageFields } } = this.props;
      const unrequiredRecipients = recipients
        .filter(r => !pageFields.some(p => p.recipientUuid === r.uuid));

      unrequiredRecipients.forEach((r) => {
        if (pageFields.length) {
          toast.error(`Signature field missing for Recipient (${r.email})`);
        }
      });

      if (!unrequiredRecipients.length || !pageFields.length) {
        history.push('/send-document');
      }
    };

    render() {
      // eslint-disable-next-line react/prop-types
      const props = {
        ...this.props,
      };
      return (
        <Component
          {...props}
          updateSignatureRequestFields={this.updateSignatureRequestFields}
          savePageFields={this.savePageFields}
        />);
    }
  };

const mapStateToProps = ({
  editor, documentEditorScreen, fields, sendDocumentScreen, documentDimensions,
}) => (
  {
    pageFields: editor.pageFields,
    documents: documentEditorScreen.signatureRequest &&
      documentEditorScreen.signatureRequest.documents,
    documentEditorScreen,
    sendDocumentScreen,
    standardFields: fields.standardFields,
    customFields: fields.customFields,
    documentDimensions,
    editor,

  }
);

const mapDispatchToProps = () => {
  const { fetchFields } = fieldsActions;
  const { fetchDocumentEditorSignatureRequest } = documentEditorScreenActions;
  const {
    resetEditor, loadEditorDataFromSignatureRequest,
    setEditorFocusedPageField,
    addEditorPageField,
    updateEditorPageField,
    toggleEditorDragging,
    removePageFieldFromEditorPage,
    setEditorScaleRatio,
    updateEditorDocumentPageFields,
    setCurrentEditorPage,
    setEditorField,
  } = editorActions;
  const {
    enableOrganizationChange,
    disableOrganizationChange,
  } = organizationActions;
  return {
    fetchFields,
    resetEditor,
    loadEditorDataFromSignatureRequest,
    fetchDocumentEditorSignatureRequest,
    setEditorFocusedPageField,
    addEditorPageField,
    setEditorField,
    setCurrentEditorPage,
    updateEditorPageField,
    toggleEditorDragging,
    removePageFieldFromEditorPage,
    setEditorScaleRatio,
    updateEditorDocumentPageFields,
    changeCustomFormFieldValue: (name, value) => change('customFieldForm', name, value),
    enableOrganizationChange,
    disableOrganizationChange,
    fetchDocDimensions: fetchDocumentDimensions,
  };
};


const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));


export default connect;
