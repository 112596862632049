import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FlatButton from 'material-ui/FlatButton';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { getInitialFieldDimensions } from '../../constants/fsFieldsInitialsDimensions';
import { DragItemTypes } from '../../constants';
import connect from './connect';

// DnD Spec
const dragSourceSpec = {
  beginDrag(props) {
    return { type: props.type, isNew: true };
  },
};

// DnD Collector
function collect(cnt, monitor) {
  return {
    connectDragSource: cnt.dragSource(),
    connectDragPreview: cnt.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}


class PageFieldOption extends React.Component {
    static propTypes = {
      style: PropTypes.object.isRequired,
      icon: PropTypes.any.isRequired,
      signs: PropTypes.any.isRequired,
      label: PropTypes.string,
      type: PropTypes.string.isRequired,
      standardFields: PropTypes.array.isRequired,
      createTemplateField: PropTypes.func.isRequired,
      connectDragSource: PropTypes.func.isRequired,
      connectDragPreview: PropTypes.func.isRequired,
    };

      static defaultProps = {
        label: '',
      };

      componentDidMount() {
        const { connectDragPreview } = this.props;
        connectDragPreview(getEmptyImage(), {
          captureDraggingState: true,
        });
      }

      handleAddField = () => {
        const {
          signs: { pageNumber }, createTemplateField, type, standardFields,
        } = this.props;


        const gefFieldIdByName = name => standardFields.find(f => f.name === name).id;

        const fieldIds = {
          input: gefFieldIdByName('Text'),
          signature: gefFieldIdByName('Signature'),
          initials: gefFieldIdByName('Initial'),
          date_picker: gefFieldIdByName('Date Signed'),
          cross: gefFieldIdByName('Cross'),
          check: gefFieldIdByName('Check'),
          dot: gefFieldIdByName('Dot'),
          circle: gefFieldIdByName('Circle'),
          line: gefFieldIdByName('Line'),
        };

        const field = getInitialFieldDimensions({
          type,
          field_id: fieldIds[type],
          pageNumber,
        });

        createTemplateField({
          ...field,
          x: 10,
          y: 10,
        });
      }

      render() {
        const {
          style, icon, label,
        } = this.props;
        return this.props.connectDragSource((
          <div>
            <FlatButton
              style={style}
              onClick={this.handleAddField}
            >
              <Wrapper>
                <Image src={icon} />
                {label && <ButtonLabel>{label}</ButtonLabel>}
              </Wrapper>
            </FlatButton>
          </div>
        ));
      }
}

const Image = styled.img`
  width: 28px;
  height: 28px;
  position: relative;
  object-fit: none;
`;

const ButtonLabel = styled.span`
  width: 100%;
  display: inline;
  text-align: center;
  margin-left: 0.5rem;
  color: gray;
  font-size: 14px;
  line-height: 14px;
  margin-top: 1px;  
  white-space: nowrap;
`;

const Wrapper = styled.div`
  padding: 5px 10px;
`;

export default DragSource(
  DragItemTypes.FIELDS_PANEL_OPTION,
  dragSourceSpec,
  collect,
)(connect(PageFieldOption));
