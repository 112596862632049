import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  fields: [],
  template: {},
  drag: true,
  displayModal: false,
  sending: false,
  documents: null,
  loading: false,
  scaleRatio: 1,
  imageWidth: 0,
  settingScaleRatio: false,
  pageNumber: 0,
  message: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.signs.CREATE_FIELD: {
      const { payload } = action;
      const id = state.fields.length + 1;
      return {
        ...state,
        fields: [
          ...state.fields,
          { id, ...payload },
        ],
      };
    }

    case Types.signs.REMOVE_FIELD: {
      const { payload } = action;
      return {
        ...state,
        fields: state.fields.filter(field => field.id !== payload.id && field),
      };
    }

    case Types.signs.UPDATE_FIELD: {
      const { payload } = action;
      return {
        ...state,
        fields: state.fields.map(field => (field.id === payload.id ? payload : field)),
      };
    }

    case Types.signs.DRAG_FIELDS: {
      const { payload } = action;
      return {
        ...state,
        drag: payload,
      };
    }

    case Types.signs.TEMPLATE_PAYLOAD: {
      const { payload } = action;
      return { ...state, template: payload };
    }

    case Types.signs.SENDING:
      return { ...state, sending: action.payload };

    case Types.signs.SEND_DOCUMENT_MODAL: {
      const displayModal = action.display;

      return { ...state, displayModal };
    }

    case Types.signs.LOADING_TEMPLATE: {
      const { loading } = action;
      return {
        ...state,
        loading,
      };
    }

    case Types.signs.SET_DOCUMENT: {
      const { documents } = action;
      return {
        ...state,
        documents,
      };
    }

    case Types.signs.SET_MESSAGE: {
      const { message } = action;
      return {
        ...state,
        message,
      };
    }

    case Types.signs.SET_PAGE_NUMBER: {
      const { pageNumber } = action;
      return {
        ...state,
        pageNumber,
      };
    }

    case Types.editor.SETTING_SCALE_RATIO: {
      const { settingScaleRatio } = action;

      return {
        ...state,
        settingScaleRatio,
      };
    }

    case Types.signs.SET_SCALE_RATIO: {
      const { scaleRatio, settingScaleRatio, imageWidth } = action;
      if (!settingScaleRatio) {
        return {
          ...state,
          scaleRatio,
          imageWidth,
        };
      }
      return {
        ...state,
      };
    }

    case Types.signs.CLEAR_FIELDS: {
      return {
        ...state,
        fields: [],
      };
    }
    case Types.signs.RESET: {
      return {
        ...INITIAL_STATE,
        message: state.message,
      };
    }

    default:
      return state;
  }
};

