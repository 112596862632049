import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

// eslint-disable-next-line react/prop-types
const Text = ({ field, value }) => (
  <Wrapper>
    <Label>{field.charAt(0).toUpperCase() + field.slice(1)}:</Label>
    <Value>{value}</Value>
  </Wrapper>
);

export const DocumentInputsSection = (props) => {
  const {
    name, from, to, date, createdBy, status, partyName, creator,
  } = props.documentManagerScreen.selectedDocument;
  const nameText = (name || '').slice(0, 25).concat(name.length > 25 ? '...' : '');

  return (
    <SectionContainer>
      {name && <Text field="name" value={nameText} />}
      {from && <Text field="from" value={from} />}
      {to && <Text field="to" value={to} />}
      {creator && <Text field="creator" value={creator} />}
      {partyName && <Text field="Party Name" value={partyName} />}
      {date && <Text field="date" value={moment(date).local().format('YYYY-MM-DD h:mm A')} />}
      {createdBy && <Text field="Created By" value={createdBy} />}
      {status && <Text field="status" value={status.toUpperCase()} />}
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  margin: 15px 0 20px 20px;
  min-height: 250px;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Label = styled.div`
  margin-right: 12px;
  font-family: 'Roboto', sans-serif;
`;

const Value = styled.div`
  color: #A1A4AA;
  font-family: 'Roboto', sans-serif;
`;

DocumentInputsSection.propTypes = {
  documentManagerScreen: PropTypes.object.isRequired,
};

const mapStateToProps = ({ documentManagerScreen, organizations, auth }) => ({
  documentManagerScreen,
  organizations,
  user: auth.user,
});

export default connect(mapStateToProps, null)(DocumentInputsSection);
