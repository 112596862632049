import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress, FlatButton, RaisedButton } from 'material-ui';

import SubscriptionPlans from '../../SubscriptionPlans';
import { Modal } from '../..';

const LoadingSpinner = () => (
  <div
    style={{ minWidth: 590, minHeight: 240 }}
    className="d-flex align-items-center justify-content-center"
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

const ModalContent = ({
  onClickSubscription, onClickContactUs, onClickCancel, plans, currentPlan,
  selectedSubscription, visiblePlans,
}) => (
  <>
    <SubscriptionPlans
      onClickSubscription={subscription => (subscription.contactUsAction
        ? onClickContactUs()
        : onClickSubscription(subscription))}
      currentPlan={currentPlan}
      selectedSubscription={selectedSubscription}
      plans={plans}
      visiblePlans={visiblePlans}
    />
    <ButtonsSection>
      <div className="contact">
        <span style={{ fontFamily: 'DIN-Medium' }}>Need help choosing a plan?</span>
        <FlatButton
          label="Contact us!"
          primary
          onClick={onClickContactUs}
          labelStyle={{ padding: 0, textTransform: 'none' }}
        />
      </div>
      <div className="signUp">
        <RaisedButton
          label="Not now"
          onClick={onClickCancel}
          primary
        />
      </div>
    </ButtonsSection>
  </>
);

ModalContent.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onClickContactUs: PropTypes.func.isRequired,
  onClickSubscription: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
  currentPlan: PropTypes.object.isRequired,
  selectedSubscription: PropTypes.object.isRequired,
  visiblePlans: PropTypes.string.isRequired,
};

const UpdatePlanModal = props => (
  <StyledModal
    open={props.open}
    title={props.title}
    content={props.loading ? LoadingSpinner() : ModalContent(props)}
    actions={<div />}
  />
);

UpdatePlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

UpdatePlanModal.defaultProps = {
  loading: false,
};

const StyledModal = styled(Modal)`

padding-top: 0px !important;
  & > div {
    overflow: scroll;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div > div {
    max-width: 1000px !important;
    transform: none !important;
  }
  & > div > div > div {
    padding: 3rem 0rem 0rem;
  }
  & h3 {
    text-align: center;
  }
  & > div > div >div > div{
    max-height: none !important;
  }
`;

const ButtonsSection = styled.div`
  margin-top: 3rem;
  width: 100%;
  flex: 1;
  display: flex;

  & > .contact {
    display: inline-block;
    width: 70%;
  }

  & > .signUp {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export { UpdatePlanModal as default, UpdatePlanModal };
