import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import connect from './connect';
import TransferOrganizationForm from '../../forms/TransferOrganizationForm';
import { Modal } from '../..';

const TransferOrganizationModal = props => (
  <StyledModal
    open={props.isOpen}
    title="Transfer Organization Ownership"
    content={
      <TransferOrganizationForm
        onSend={val => props.onClickSend(props.organizationSubdomain, val)}
        onCancel={props.onClickCancel}
        userEmail={props.userEmail}
      />}
    actions
  />
);

TransferOrganizationModal.propTypes = {
  onClickSend: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  organizationSubdomain: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

TransferOrganizationModal.defaultProps = {
  userEmail: '',
  organizationSubdomain: '',
};

const StyledModal = styled(Modal)`
  & > div > div > div {
    padding-top: 1.5rem;
    & > div {
      font-family: DIN-Medium;

    }
  }
  & h3 {
    font-family: DIN-Medium;
  }
`;

export default connect(TransferOrganizationModal);
