import gql from 'graphql-tag';

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation SendResetPasswordEmail(
    $uuid: ID!,
    ){
      sendResetPasswordEmail(
        uuid: $uuid,
      ){
        success
      }
  }
`;

export default {
  SEND_RESET_PASSWORD_EMAIL,
};
