import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';

import Actions from '../actions';
import PlatformOrganizationsList from '../components/PlatformOrganizationsList';
import PlatformSubscriptionPlans from '../components/PlatformSubscriptionPlans';
import PlatformUsersList from '../components/PlatformUsersList';
import history from '../config/history';
import { PageHeader, PageContent } from '../components/pages';
import { colors, animations } from './../utils';

export const PlatformManagerScreen = ({
  organizations, auth,
  fetchOrganizations, searchOrganizations,
}) => {
  const [currentTab, setCurrentTab] = useState('Organizations');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isSuperAdmin = get(auth, 'user.roles', []).some(u => u.name === 'super_admin');

    if (!isSuperAdmin) {
      history.replace('/dashboard');
    }
  }, []);

  const handleSearchOrganizations = (term) => {
    const func = term ? searchOrganizations : fetchOrganizations;
    setLoading(true);
    func(term)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleChangeTabs = (_, newTab) => {
    if (currentTab === newTab) return;
    setCurrentTab(newTab);
  };

  const tabs = [{
    label: 'Organizations',
    render: () => (
      <PlatformOrganizationsList
        data={organizations.list || []}
        onSearch={handleSearchOrganizations}
        loading={loading}
      />
    ),
  }, {
    label: 'Users',
    render: () => <PlatformUsersList />,
  }, {
    label: 'Subscriptions Plans',
    render: () => <PlatformSubscriptionPlans />,
  }];

  const renderTabs = (
    <div className="col-12 my-4 p-0">
      <Paper square>
        <Tabs
          style={{ color: colors.blue }}
          value={currentTab}
          onChange={handleChangeTabs}
          TabIndicatorProps={{ style: { backgroundColor: colors.blue } }}
        >
          {tabs.map(({ label }) => (
            <Tab key={label} label={label} value={label} />
          ))}
        </Tabs>
      </Paper>
    </div>
  );

  const tab = tabs.find(t => t.label === currentTab) || tabs[0];

  return (
    <Container>
      <PageHeader title="Platform Management" />
      <PageContent>
        {renderTabs}
        {tab.render()}
      </PageContent>
    </Container>
  );
};

PlatformManagerScreen.propTypes = {
  auth: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  searchOrganizations: PropTypes.func.isRequired,
};

const Container = styled.div`
  width: 100%;
  margin-top: 0.6rem;
  padding-left: 2rem;
  animation: ${animations.fadeIn} 0.3s ease-in;
`;

export default connect(
  ({ auth, organizations }) => ({ auth, organizations }),
  Actions,
)(PlatformManagerScreen);
