import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

const inputStyle = {
  backgroundColor: 'rgba(255, 255, 0, 0.3)',
  border: 'none',
  height: '100%',
  width: '100%',
};

const required = value => (value ? undefined : 'Required');

const ChoiceInput = ({
  isDisabled, type, fieldName, isRequired,
}) => (
  <Field
    disabled={isDisabled}
    component="input"
    type={type}
    name={fieldName}
    style={inputStyle}
    validate={isRequired ? [required] : null}
  />
);

ChoiceInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
};

ChoiceInput.defaultProps = {
  isRequired: false,
  type: 'text',
  isDisabled: false,
};

export default ChoiceInput;

