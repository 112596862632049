import React from 'react';
import { toast } from 'react-toastify';
import { connect as reduxConnect } from 'react-redux';

import filesManagerActions from '../../actions/filesManager';
import sendDocumentScreenActions from '../../actions/sendDocumentScreen';
import confirmDialogActions from '../../actions/confirmDialog';
import brandsSectionActions from '../../actions/brandsSection';
import organizationActions from '../../actions/organization';
import subscriptionsActions from '../../actions/subscriptions';
import editorActions from '../../actions/editor';
import documentEditorScreenActions from '../../actions/documentEditorScreen';
import graphqlClient from '../../config/graphql';
import { UPDATE_SIGNATURE_REQUEST_FIELDS, FETCH_TEMPLATES } from './queries';
import { mapDocumentPageFields } from './mapping';

const connect = Component => (props) => {
  const {
    /* eslint-disable react/prop-types */
    pageFields, updateCurrentUser,
    currentOrganization, setSendDocumentField,
    /* eslint-enable */
  } = props;

  const updateSignatureRequestFields = async (signatureRequestData) => {
    const auxPageFields = mapDocumentPageFields(pageFields, signatureRequestData);
    try {
      const {
        data: { upsertSignatureRequestPageFields: { signatureRequest } },
      } = await graphqlClient().mutate({
        variables: {
          signatureRequestUuid: signatureRequestData.uuid,
          pageFields: auxPageFields,
        },
        mutation: UPDATE_SIGNATURE_REQUEST_FIELDS,
      });
      updateCurrentUser();
      return (signatureRequest);
    } catch (e) {
      toast.error(e.message);
      return Promise.reject(e);
    }
  };

  const fetchTemplates = async () => {
    try {
      const {
        data: { templates: { nodes } },
      } = await graphqlClient((currentOrganization || {}).subdomain).query({
        query: FETCH_TEMPLATES,
        fetchPolicy: 'network-only',
      }).catch((e) => {
        toast.error(e.message);
      });

      setSendDocumentField('templatesList', nodes);
      return { templates: nodes };
    } catch (e) {
      return { templates: [] };
    }
  };

  return (
    <Component
      {...props}
      getTemplateList={fetchTemplates}
      updateSignatureRequestFields={updateSignatureRequestFields}
    />
  );
};

const mapStateToProps = ({
  brandsSection, sendDocumentScreen, documentEditorScreen,
  organizations, filesManager, editor, auth,
}) => ({
  brandsSection,
  sendDocumentScreen,
  organizations,
  filesManager,
  editor,
  documents: documentEditorScreen.signatureRequest &&
  documentEditorScreen.signatureRequest.documents,
  documentEditorScreen,
  pageFields: editor.pageFields,
  auth,
});

const mapDispatchToProps = () => {
  const { setAddFilesDialog, clearDocuments } = filesManagerActions;
  const {
    setSendDocumentField,
    addSendDocumentRecipient,
    setSendDocumentRecipients,
    removeSendDocumentRecipient,
    addSendDocumentDocuments,
    removeSendDocumentDocument,
    updateMessageDocument,
    toggleUseExistingTemplateModal,
    resetSendDocument,
    createSignatureRequest,
    addFields,
    changeRecipientValue,
    toggleBrandSelect,
    setMode,
    updateCurrentUser,
  } = sendDocumentScreenActions;
  const { resetEditor, setEditorField } = editorActions;
  const { showConfirmDialog } = confirmDialogActions;
  const { fetchBrands } = brandsSectionActions;
  const { enableOrganizationChange } = organizationActions;
  const { setUpgradeModal } = subscriptionsActions;
  const {
    setDocumentEditorSignatureRequest,
    fetchDocumentEditorSignatureRequest,
  } = documentEditorScreenActions;

  return {
    setAddFilesDialog,
    setSendDocumentField,
    addSendDocumentRecipient,
    setSendDocumentRecipients,
    removeSendDocumentRecipient,
    addSendDocumentDocuments,
    removeSendDocumentDocument,
    updateMessageDocument,
    toggleUseExistingTemplateModal,
    clearDocuments,
    resetSendDocument,
    createSignatureRequest,
    addFields,
    changeRecipientValue,
    showConfirmDialog,
    fetchBrands,
    toggleBrandSelect,
    enableOrganizationChange,
    resetEditor,
    setMode,
    setUpgradeModal,
    setEditorField,
    updateCurrentUser,
    setDocumentEditorSignatureRequest,
    fetchDocumentEditorSignatureRequest,
  };
};

export default WrappedComponent => reduxConnect(
  mapStateToProps,
  mapDispatchToProps(),
)(connect(WrappedComponent));
