import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  title: '',
  description: '',
  documents: [],
  documentsLimit: 10,
  roles: [{ role: '' }],
  rolesToWipe: [],
  brands: [],
  selectedBrand: null,
  isSelectedBrandEnabled: false,
  completeInOrder: true,
  loading: false,
  shouldValidate: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.templateScreen.SET_FIELD: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case Types.templateScreen.ADD_DOCUMENTS: {
      const documents = [...state.documents, ...action.documents];
      return {
        ...state,
        documents,
      };
    }

    case Types.templateScreen.REMOVE_DOCUMENT: {
      const documents = state.documents.filter(d => d.uuid !== action.document.uuid);
      return {
        ...state,
        documents,
      };
    }

    case Types.templateScreen.ADD_ROLE: {
      const { roles } = state;
      return {
        ...state,
        roles: [...roles, { role: '' }],
      };
    }

    case Types.templateScreen.SET_ROLES: {
      const { roles } = action;
      return {
        ...state,
        roles: [...roles],
      };
    }

    case Types.templateScreen.UPDATE_ROLES_DOCUMENTS: {
      const { i, role } = action.payload;
      const roles =
        state.roles.map((r, index) => i === index ? ({ ...r, role }) : r); // eslint-disable-line
      return {
        ...state,
        roles,
      };
    }

    case Types.templateScreen.DELETE_ROLES_DOCUMENTS: {
      const { index } = action;
      const { roles, rolesToWipe } = state;

      if (roles[index].uuid) {
        // the role exists and was previously loaded
        // move the role to the rolesToWipe array
        rolesToWipe.push({
          ...roles[index],
          _destroy: true,
          signing_order: true,
        });
      }

      roles.splice(index, 1);

      return {
        ...state,
        roles,
        rolesToWipe,
      };
    }
    case Types.templateScreen.RESET_SCREEN: {
      return {
        ...INITIAL_STATE,
        roles: [{ role: '' }],
        rolesToWipe: [],
        documents: [],
      };
    }

    case Types.templateScreen.SET_LOADING: {
      const { loading } = action;

      return {
        ...state,
        loading,
      };
    }

    case Types.brandsSection.GET_BRANDS_LIST: {
      const { brands } = action;
      return {
        ...state,
        brands: [...brands],
      };
    }


    default:
      return state;
  }
};
