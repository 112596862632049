import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { isEmpty } from 'lodash';
import { formValueSelector } from 'redux-form';
import { setSignsMessageValue } from '../../../actions/signs';
import brandsActions from '../../../actions/brandsSection';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render = () => (<Component {...this.props} />)
  };

const selector = formValueSelector('sendDocumentForm');

const mapStateToProps = (state, props) => {
  const {
    auth: { user },
    brandsSection: { brands },
    signs,
    form,
  } = state;
  const { selectedDocument } = props;

  const fileName = isEmpty(selectedDocument)
    ? ''
    : selectedDocument.file_name;

  return {
    initialValues: {
      email: user.email,
      subject: `${user.firstName} ${user.lastName} has sent you a document ${fileName}.`,
      message: signs.message || '',
    },
    brands,
    useBrand: selector(state, 'use_brand'),
    brandUuid: selector(state, 'brandUuid'),
    formValues: form.sendDocumentForm && form.sendDocumentForm.values,
  };
};

const mapDispatchToProps = () => {
  const {
    fetchBrands,
  } = brandsActions;

  return {
    fetchBrands,
    setMessageInitialValue: setSignsMessageValue,
  };
};

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
