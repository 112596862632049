import React, { Component } from 'react';
import moment from 'moment';
import { connect as ReduxConnect } from 'react-redux';
import _ from 'lodash';

import EnvelopeDetailTable from './EnvelopeDetailTable';
import graphqlClient from '../../../config/graphql';
import { ENVELOPES_DETAIL_REPORT } from './queries';
import { didOrganizationChanged } from '../../../utils/userOrganizations';
import { setReportVariables } from '../../../actions/analyticsScreen';

const timeFormat = time => ({
  style: { color: 'rgba(123,123,123,0.87)', fontSize: 12 },
  children: time.format('HH:mm A Z'),
});

const parseNode = ({
  lastActivity, sentOn, completedOn, ...details
}) => ({
  ...details,
  ..._({ lastActivity, sentOn, completedOn })
    .mapValues(d => moment((d || '').split(' on ')[1]))
    .mapValues(d => (d.isValid() ? [d.format('MM/DD/YYYY'), timeFormat(d)] : []))
    .value(),
});

const renderWrappedComponent = WrappedComponent =>
  class extends Component {
    state = {
      data: [],
      loading: false,
    }

    componentDidMount = () => {
      const startDate = moment().subtract(1, 'months').toISOString();
      const endDate = moment().toISOString();
      this.fetchEnvelopesDetailReport({ startDate, endDate });
    }

    componentDidUpdate = (prevProps) => {
      if (didOrganizationChanged(prevProps, this.props)) {
        const startDate = moment().subtract(1, 'months').toISOString();
        const endDate = moment().toISOString();
        this.fetchEnvelopesDetailReport({ startDate, endDate });
      }
    }

    fetchEnvelopesDetailReport = async (variables) => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization, setCurrentReportVariables } = this.props;
      await setCurrentReportVariables({ ...variables, reportType: 'ENVELOPES_DETAIL_REPORT' });
      this.setState({ loading: true });
      try {
        const { data: { envelopesDetailReport: { nodes } } } = await graphqlClient(
          currentOrganization && currentOrganization.subdomain,
        ).query({
          query: ENVELOPES_DETAIL_REPORT,
          variables,
        });
        this.setState({ data: nodes.map(parseNode) });
        this.setState({ loading: false });
      } catch (e) {
        this.setState({ loading: false });
        throw e;
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onFilterChange={this.fetchEnvelopesDetailReport}
        />
      );
    }
  };

const mapStateToProps = ({ organizations }) => ({
  organizations,
  currentOrganization: organizations.currentOrganization,
});

const mapDispatchToProps = () => ({
  setCurrentReportVariables: setReportVariables,
});

const connect = WrappedComponent =>
  ReduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect(EnvelopeDetailTable);
