import { isEmpty, startCase } from 'lodash';

export const getStatus = (history) => {
  if (!history || isEmpty(history)) {
    return null;
  }
  const keys = Object.keys(history[0]);
  return startCase(keys[0]);
};

export const getHistoryByEventName = (history, eventName) => {
  let value = null;
  if (!history || isEmpty(history)) {
    return value;
  }
  history.forEach((event) => {
    const keys = Object.keys(event);
    keys.forEach((key) => {
      if (key === eventName) {
        value = event[eventName];
      }
    });
  });
  return value;
};

export const parseSignatureRequest = (signatureRequest) => {
  const {
    subject,
    recipients,
    activityLogs,
    notes,
    uuid,
    user,
    previewImage,
    reminders,
  } = signatureRequest;

  const history = activityLogs.nodes.map(log => ({ [log.event]: log.eventDate }));

  return {
    history,
    name: subject,
    date: getHistoryByEventName(history, 'created') || getHistoryByEventName(history, 'create'),
    subject,
    notes: notes || '',
    uuid,
    from: user.email,
    to: ((recipients || {}).nodes || []).map(r => r.email).join(', '),
    previewUrl: (previewImage || {}).url || null,
    recipients: (recipients || {}).nodes || [],
    reminders: (reminders || {}).nodes || [],
    status: getStatus(history),
  };
};

export default {
  parseSignatureRequest,
  getHistoryByEventName,
};
