import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../Modal';

const contentStyle = {
  fontWeight: 500,
  fontSize: 22,
  color: 'black',
  margin: '1rem 1.2rem 3rem',
};

export const EnterpriseUpgradeModal = ({ open, onCancel, onConfirm }) => (
  <Modal
    title=""
    primaryLabel="contact us"
    secondaryLabel="cancel"
    onPrimaryClick={onConfirm}
    onSecondaryClick={onCancel}
    contentStyle={{ width: '680px' }}
    open={open}
    content={(
      <div style={contentStyle}>
        In order to accept the ownership of this organization,
        you need to upgrade your subscription to an Enterprise plan.
        For this please get in touch with us and let us know your needs.
      </div>
    )}
  />
);

EnterpriseUpgradeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default EnterpriseUpgradeModal;
