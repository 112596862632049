import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ChangePasswordForm from '../../forms/ChangePasswordForm';
import { Modal } from '../..';

const ChangePasswordModal = props => (
  <StyledModal
    open={props.isOpen}
    title="Change Password"
    content={
      <ChangePasswordForm
        onSave={props.onClickSend}
        onCancel={props.onClickCancel}
      />}
    actions
  />
);

ChangePasswordModal.propTypes = {
  onClickSend: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

const StyledModal = styled(Modal)`
  & > div > div {
    max-width: 640px !important;
  }

  & h3 {
    font-family: DIN-Medium;
    font-size: 24px;
  }

  & > div > div > div {
    padding: 2rem 0rem 0rem;
  }
`;

export default connect(mapStateToProps, null)(ChangePasswordModal);
