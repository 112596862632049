import api from './api';
import { checkOrganization } from '../utils';

const documentService = {
  get: id => api.get(`/documents/${id}`),
  getOriginal: id => api.get(`/documents/${id}/original`),
  create: (subdomain, document, config) => api.post(subdomain ? `${process.env.REACT_APP_API_URL.replace('//', `//${subdomain}.`)}/documents`
    : '/documents', document, config),
  update: (id, document) => api.put(`/documents/${id}`, { document }),
  delete: (id, organizationUuid) => api.delete(`/documents/${id}${checkOrganization(organizationUuid)}`),
  send: (documentId, payload) => api.post(`/documents/${documentId}/email`, payload),
  getPage: (uuid, pageId) => api.get(`/documents/${uuid}/pages/${pageId}`),
  getPages: uuid => api.get(`/documents/${uuid}/pages`),

  getSigned: organizationUuid => api.get(`/signature_requests/signed${checkOrganization(organizationUuid)}`),
  invite: (uuid, payload) => api.post(`/signature_requests/${uuid}/invite`, payload),

  createArchivedDocument: (payload, config) => api.post('/archived_documents', payload, config),
  saveArchivedDocument: (uuid, payload) => api.put(`/archived_documents/${uuid}`, payload),
  sendArchivedDocument: (uuid, payload) => api.post(`/archived_documents/${uuid}/email`, payload),

  sendSignedDocument: (uuid, payload) => api.post(`/signed_documents/${uuid}/email`, payload),
  updateSignedDocument: (uuid, payload) => api.put(`/signed_documents/${uuid}`, { signed_document: payload }),
};

export default documentService;
