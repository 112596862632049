import Types from '../constants/actionTypes';
import signatureRequestService from '../services/signatureRequest';


export const setDocumentEditorLoading = (action, loading) => ({
  type: Types.documentEditorScreen.SET_LOADING,
  loading,
  action,
});

export const setDocumentEditorSignatureRequest = signatureRequest => ({
  type: Types.documentEditorScreen.SET_SIGNATURE_REQUEST,
  signatureRequest,
});

export const fetchDocumentEditorSignatureRequest = uuid => async (dispatch) => {
  try {
    dispatch(setDocumentEditorLoading('fetchDocumentEditorSignatureRequest', true));
    const { data: signatureRequest } = await signatureRequestService.getSignatureRequest(uuid);
    dispatch(setDocumentEditorSignatureRequest(signatureRequest));
    dispatch(setDocumentEditorLoading('fetchDocumentEditorSignatureRequest', false));
    return Promise.resolve(signatureRequest);
  } catch (e) {
    dispatch(setDocumentEditorLoading('fetchDocumentEditorSignatureRequest', false));
    return Promise.reject(e);
  }
};

export default {
  setDocumentEditorLoading,
  setDocumentEditorSignatureRequest,
  fetchDocumentEditorSignatureRequest,
};
