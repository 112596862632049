import React from 'react';
import PropTypes from 'prop-types';

import EmptyListNotification from '../../EmptyListNotification';
import SortableTable from '../../SortableTable';
import history from '../../../config/history';
import { dateFormats } from '../../../utils';

const TemplatesTableBody = (props) => {
  const {
    documents: propDocs, selectedDocument, setSelectedDocument, loading,
    handleOnChangePage, currentPageInfo,
  } = props;
  const columns = [{
    id: 'name', label: 'Name', field: 'name', type: 'text',
  }, {
    id: 'creator', label: 'Creator', field: 'sender', type: 'text',
  }, {
    id: 'lastEdited', label: 'Last Edited', field: 'lastEdited', type: 'date',
  }, {
    id: 'dateCreated', label: 'Date Created', field: 'dateCreated', type: 'date',
  }];
  const options = [
    { label: 'Edit', action: uuid => history.push(`/documents-manager/templates/${uuid}`) },
  ];

  if (!loading && propDocs.length === 0) {
    return (
      <EmptyListNotification
        message="Nothing to see here! You do not have any templates."
        link="/documents-manager/templates/new"
        buttonLabel="Create Template"
      />
    );
  }

  const data = (propDocs || []).map(d => ({
    uuid: d.uuid,
    name: d.name,
    subject: d.subject,
    sender: d.sender,
    dateCreated: d.date && dateFormats.fullMonthDate(d.date),
    lastEdited: d.lastEdited && dateFormats.fullMonthDate(d.lastEdited),
    document: d,
  }));

  return (
    <SortableTable
      {...currentPageInfo}
      onChangePage={handleOnChangePage}
      data={data}
      isSelectedRow={row => (row.document || {}).uuid === (selectedDocument || {}).uuid}
      onClickRow={row => setSelectedDocument(row.document, 'template')}
      columns={columns}
      options={options}
      loading={loading}
    />
  );
};

TemplatesTableBody.propTypes = {
  documents: PropTypes.array.isRequired,
  selectedDocument: PropTypes.object.isRequired,
  setSelectedDocument: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  handleOnChangePage: PropTypes.func.isRequired,
  currentPageInfo: PropTypes.object.isRequired,
};

TemplatesTableBody.defaultProps = {
  loading: false,
};

export default TemplatesTableBody;
