import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import qs from 'query-string';
import Messages from '../../constants/toastMessages';
import DocumentsEditor from '../../components/editor/DocumentsEditor';
import connect from './connect';
import { getErrorMessage } from '../../utils/error';

class TemplateEditorScreen extends Component {
  state = {
    isEditing: this.props.match.params.action === 'edit',
    saving: false,
  };

  async componentDidMount() {
    const {
      fetchFields,
      fetchTemplate,
      resetEditor,
      loadEditorDataFromTemplate,
      match: { params },
      disableOrganizationChange,
    } = this.props;

    resetEditor();
    disableOrganizationChange();
    await fetchFields();

    if (params.id) {
      try {
        const template = await fetchTemplate(params.id);
        loadEditorDataFromTemplate(template);
      } catch (e) {
        // do nothing
      }
    }
  }

  componentWillUnmount() {
    const { enableOrganizationChange } = this.props;
    enableOrganizationChange();
  }

  saveTemplate = async () => {
    const {
      templateEditorScreen: { template },
      history,
      location,
      updateTemplateFields,
    } = this.props;

    const {
      redirect,
    } = qs.parse(location.search, { ignoreQueryPrefix: true });
    try {
      this.setState({ saving: true });
      await updateTemplateFields();
      if (!redirect) {
        toast.success(Messages.templates.update.success);
        history.push(`/documents-manager?uuid=${template.uuid}&tab=template_library`);
      } else {
        history.goBack();
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const {
      standardFields,
      customFields,
      templateEditorScreen: { loading },
      editor: {
        documents,
        loading: editorLoading,
        pageFields,
        scaleRatio,
        focusedPageField,
        canDragFields,
        imageWidth,
        recipients,
        currentEditorPage,
      },
      setEditorFocusedPageField,
      setCurrentEditorPage,
      addEditorPageField,
      updateEditorPageField,
      toggleEditorDragging,
      removePageFieldFromEditorPage,
      setEditorScaleRatio,
      changeCustomFormFieldValue,
      documentDimensions,
    } = this.props;
    const { saving } = this.state;

    return (
      <DocumentsEditor
        documentDimensions={documentDimensions}
        title={this.state.isEditing ? 'Edit Template' : 'Create New Template'}
        onSave={this.saveTemplate}
        currentEditorPage={currentEditorPage}
        setCurrentEditorPage={setCurrentEditorPage}
        saveDisabled={saving}
        documents={documents}
        standardFields={standardFields}
        customFields={customFields}
        loading={loading.fetchTemplate || editorLoading.loadEditorDataFromTemplate}
        setEditorFocusedPageField={setEditorFocusedPageField}
        pageFields={pageFields}
        recipients={recipients}
        openFieldPageOptionModal={() => {}}
        onResizeHandlePress={toggleEditorDragging}
        onRemovePageField={removePageFieldFromEditorPage}
        onClickPageField={async (fPageField) => {
          await setTimeout(() => {
            setEditorFocusedPageField(fPageField);
          }, 100);
        }}
        onChangePageField={updateEditorPageField}
        onAddPageField={addEditorPageField}
        focusedPageField={focusedPageField}
        canDragFields={canDragFields}
        imageWidth={imageWidth}
        scaleRatio={scaleRatio}
        setScaleRatio={setEditorScaleRatio}
        changeCustomFormFieldValue={changeCustomFormFieldValue}
        onClickFieldsLayer={() => { setEditorFocusedPageField(null); }}
      />
    );
  }
}

TemplateEditorScreen.propTypes = {
  templateEditorScreen: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  fetchFields: PropTypes.func.isRequired,
  standardFields: PropTypes.any.isRequired,
  customFields: PropTypes.any.isRequired,
  match: PropTypes.object.isRequired,
  fetchTemplate: PropTypes.func.isRequired,
  setEditorFocusedPageField: PropTypes.func.isRequired,
  addEditorPageField: PropTypes.func.isRequired,
  updateEditorPageField: PropTypes.func.isRequired,
  toggleEditorDragging: PropTypes.func.isRequired,
  removePageFieldFromEditorPage: PropTypes.func.isRequired,
  setEditorScaleRatio: PropTypes.func.isRequired,
  changeCustomFormFieldValue: PropTypes.func.isRequired,
  updateTemplateFields: PropTypes.func.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
  disableOrganizationChange: PropTypes.func.isRequired,
  loadEditorDataFromTemplate: PropTypes.func.isRequired,
  setCurrentEditorPage: PropTypes.func.isRequired,
  resetEditor: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  documentDimensions: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
};

export default connect(TemplateEditorScreen);
