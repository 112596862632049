import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Checkbox, TextField, FlatButton, RaisedButton } from 'material-ui';
import * as Yup from 'yup';
import styled from 'styled-components';

const SendReportSchema = Yup.object().shape({
  recipients: Yup.string().matches(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
    'You have entered an invalid email address, please check before sending',
  ).ensure().required('Required'),
});

const SendReportForm = ({
  user, setFieldValue, setFieldTouched, values, errors, touched,
  onCancel, onSend, isValid,
}) => {
  const setValue = (field, value) => {
    setFieldTouched(field, true, false);
    setFieldValue(field, value);
  };
  return (
    <form >
      <TextField
        name="recipients"
        fullWidth
        floatingLabelText="Recipients"
        required
        value={values.recipients}
        errorText={touched.recipients && errors.recipients}
        onChange={e => setValue('recipients', e.target.value)}
      />
      <Checkbox
        style={{ marginTop: '30px', marginBottom: '20px' }}
        checked={values.sendToMe}
        onCheck={(e, selected) => setValue('sendToMe', selected)}
        label={`CC me (${user.email})`}
      />
      <ButtonSection>
        <FlatButton
          label="Cancel"
          onClick={onCancel}
          primary
          style={{ width: '100px', marginRight: '20px' }}
        />
        <RaisedButton
          label="Send"
          onClick={() => onSend(values)}
          primary
          disabled={!isValid}
          style={{ width: '120px' }}
        />
      </ButtonSection>
    </form>
  );
};

const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

SendReportForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onSend: PropTypes.func,
  onCancel: PropTypes.func,
};

SendReportForm.defaultProps = {
  onSend: () => {},
  onCancel: () => {},
};

export default withFormik({
  validationSchema: SendReportSchema,
  enableReinitialize: true,
})(SendReportForm);
