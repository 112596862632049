import React, { Component } from 'react';
import { isEmpty, isEqual, get } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import SectionLoader from '../../components/SectionLoader';
import logo from '../../assets/images/logo.svg';
import parser from '../../utils/parserQueryString';
import SignatureForm from '../../components/forms/SignatureForm';
import connect from './connect';
import documentService from '../../services/signDocument';
import Messages from '../../constants/toastMessages';
import SignToolBar from '../../components/SignToolBar';
import DesignateModal from '../../components/modals/DesignateModal';
import DeclineModal from '../../components/modals/DeclineModal';
import SignatureSentModal from '../../components/modals/SignatureSentModal';
import VoidMessageModal from '../../components/modals/VoidMessageModal';
import PasswordProtectModal from '../../components/modals/PasswordProtectModal';
import DeclinedMessageModal from '../../components/modals/DeclinedMessageModal';
import UploadingDocumentModal from '../../components/modals/UploadingDocumentModal';
import {
  EVENTS,
  sendMessageToParent,
} from '../../utils/postMessages';

class SignatureScreen extends Component {
  state = {
    signatureRequest: null,
    params: null,
    loading: false,
    error: null,
    isActionInProgress: false,
    brand: null,
    openDeclineModal: false,
    openDesignateModal: false,
    openSignatureSentModal: false,
    openVoidMessageModal: false,
    openPasswordProtectModal: false,
    openDeclinedMessageModal: false,
    alreadySigned: false,
    verifyAction: () => {},
  };

  componentDidMount = async () => {
    const { location, history } = this.props;
    const params = parser(location.search);
    this.setParams(params);
    if (params.delegate && params.delegate === 'true') {
      this.toggleStateValue('openDesignateModal');
    }
    try {
      this.setLoading(true);
      const { data: signatureRequest } = await documentService.fetchSignatureRequest(params);
      const { state } = signatureRequest;
      if (!this.isAuthorized(signatureRequest)) {
        history.push('/login');
      }


      if (!isEmpty(signatureRequest.signed_documents)) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ alreadySigned: true });
        this.setError(Messages.signatureRequest.fetch.error);
        this.setLoading(false);
      } else {
        this.initializeFields(signatureRequest);
        this.setBrand(signatureRequest.brand);
        this.setSignatureRequest(signatureRequest);
        this.setLoading(false);

        if (state === 'voided') this.toggleStateValue('openVoidMessageModal');
        if (state === 'declined') this.toggleStateValue('openDeclinedMessageModal');
      }
    } catch (e) {
      this.setError(Messages.signatureRequest.fetch.error);
      this.setLoading(false);
    }
  };

  setBrand = (brand) => {
    this.setState({ brand });
  };

  setError = (error, callback) => {
    this.setState({ error }, callback);
  };

  setIsActionInProgress(isActionInProgress, callback) {
    this.setState({ isActionInProgress }, callback);
  }

  setLoading = (loading, callback) => {
    this.setState({ loading }, callback);
  };

  setParams = (params, callback) => {
    this.setState({ params }, callback);
  };

  setSignatureRequest = (signatureRequest, callback) => {
    if (signatureRequest && signatureRequest.context_info && signatureRequest.context_info) {
      this.setState(
        {
          signatureRequest: this.filterPageFields(
            signatureRequest,
            signatureRequest.context_info.recipient_uuid,
          ),
        },
        callback,
      );
    }
  };

  getDocuments = ({ documents, pages }) => {
    documents.forEach((document) => {
      document.pages = document.page_ids.map(pageId => pages.find(page => page.id === pageId));
    });
    return documents;
  };

  toggleStateValue = (value) => {
    const { state } = this;
    state[value] = !state[value];
    this.setState({ state });
  }

  initializeFields = (signatureRequest) => {
    const documents = this.getDocuments(signatureRequest);
    const { setSignDocumentFields, signDocumentScreen } = this.props;
    let documentFields = [];
    // eslint-disable-next-line array-callback-return
    documents.map((d) => { documentFields = [...documentFields, ...d.page_fields]; });
    if (!isEqual(signDocumentScreen.fields, documentFields)) {
      setSignDocumentFields(documentFields);
    }
  }

  handleComplete = async () => {
    const { history } = this.props;
    this.toggleStateValue('openSignatureSentModal');
    await this.state.verifyAction();
    history.push('/dashboard');
  }

  handleOnCancelPasswordProtect = () => {
    const { history } = this.props;
    history.push('/dashboard');
  }

  handleOnEnterPasswordProtect = () => {
    // Endpoint required here to ensure the password is correct
    this.toggleStateValue('openPasswordProtectModal');
  }

  /**
   * Triggers the saving of the signature form
   */
  handleSubmit = async () => {
    try {
      this.setIsActionInProgress(true);
      const { params } = this.state;
      const { saveDocumentSign } = this.props;
      const { action } = await saveDocumentSign(params);

      // Send message to parent iFrame if it exists
      sendMessageToParent(EVENTS.SIGNING_SUCCESS, { action });

      this.setState({ verifyAction: action }, () =>
        this.toggleStateValue('openSignatureSentModal'));
      this.setIsActionInProgress(false);
    } catch (e) {
      this.setIsActionInProgress(false);
      if (e.response.data.details) toast.error(e.response.data.details[0]);
      else toast.error(Messages.documents.sign.error);


      // Send message to parent iFrame if it exists
      sendMessageToParent(EVENTS.SIGNING_ERROR, { error: e.toString() });
    }
  };

  isAuthorized = signatureRequest =>
    !!(signatureRequest.context_info && signatureRequest.context_info.recipient_uuid);

  /**
   * Removes the page fields that are not available for the current recipient
   * @param sigRequest signature request
   * @param recipientUuid id of the recipient that is going to sign
   * @returns Object signature request updated
   */
  filterPageFields = (sigRequest, recipientUuid) => {
    const signatureRequest = { ...sigRequest };
    if (signatureRequest && !isEmpty(signatureRequest.documents)) {
      signatureRequest.documents.forEach((document) => {
        if (!isEmpty(document.page_fields)) {
          document.page_fields = document
            .page_fields
            .filter(pageField => pageField.recipient_uuid === recipientUuid);
        }
      });
    }
    if (signatureRequest && !isEmpty(signatureRequest.pages)) {
      signatureRequest.pages.forEach((page) => {
        if (!isEmpty(page.page_fields)) {
          page.page_fields = page
            .page_fields
            .filter(pageField => pageField.recipient_uuid === recipientUuid);
        }
      });
    }
    return {
      ...signatureRequest,
    };
  };

  pageHeader = null;

  renderForm = (height) => {
    const { error, signatureRequest, isActionInProgress } = this.state;
    if (error || !signatureRequest) {
      return (
        <FormWrapper height={height}>
          <Box>{error}</Box>
        </FormWrapper>
      );
    }

    return (
      <FormWrapper height={height}>
        <SignatureForm
          documentDimensions={this.props.documentDimensions}
          brand={this.state.brand}
          documents={this.getDocuments(signatureRequest)}
          currentRecipient={signatureRequest && signatureRequest.context_info.recipient_uuid}
          onSubmit={this.handleSubmit}
          signing={isActionInProgress}
        />
      </FormWrapper>
    );
  };

  renderSavingModal = () => (
    <UploadingDocumentModal
      isOpen={this.state.isActionInProgress}
      message="Signing Document"
    />
  );

  render() {
    const {
      loading, signatureRequest, openDeclineModal, openPasswordProtectModal,
      openDesignateModal, openSignatureSentModal, openVoidMessageModal,
      openDeclinedMessageModal,
    } = this.state;
    const {
      signDocumentScreen: { fields }, history,
      declineSignatureRequest, delegateSignatureRequest,
    } = this.props;

    const getRequiredFields = () => {
      if (!signatureRequest) return 0;
      let requiredFields = fields.filter(f => f.page_field_detail.required &&
        signatureRequest.context_info.recipient_uuid === f.recipient_uuid &&
        !f.field.name.includes('Date Signed') && !f.field.name.includes('Participation Stamp'));
      const auxRadioButtonsGroups = [];
      requiredFields = requiredFields.filter((f) => {
        if (f.metadata && f.metadata.input_type === 'radio') {
          if (!auxRadioButtonsGroups.includes(f.metadata.radio_button_group_id)) {
            auxRadioButtonsGroups.push(f.metadata.radio_button_group_id);
            return true;
          }
          return false;
        }
        return true;
      });
      requiredFields = requiredFields.filter(f => f.value === '');
      return requiredFields.length;
    };

    return (
      <div>
        <SignToolBar
          innerRef={(e) => {
              this.pageHeader = e;
            }}
          logo={logo}
          disabledActions={!!this.state.error}
          title={signatureRequest && get(signatureRequest, 'documents[0].file_name', [])}
          fieldsRequired={getRequiredFields()}
          actions={[
              { label: 'Someone else should sign', action: () => this.toggleStateValue('openDesignateModal') },
              { label: 'I will not e-sign', action: () => this.toggleStateValue('openDeclineModal') },
            ]}
        />
        <PageContainer
          blurDocument={openPasswordProtectModal}
        >
          {this.renderSavingModal()}
          {loading ? (
            <SectionLoader />
          ) : (
            this.renderForm(this.pageHeader ? this.pageHeader.clientHeight : 0)
          )}
        </PageContainer>
        <DeclineModal
          isOpen={openDeclineModal}
          onClickCancel={() => this.toggleStateValue('openDeclineModal')}
          onClickDecline={message => declineSignatureRequest(signatureRequest.uuid, message)}
        />
        <DesignateModal
          isOpen={openDesignateModal}
          onClickSend={(email, comment) =>
            delegateSignatureRequest(signatureRequest.uuid, email, comment)}
          onClickCancel={() => this.toggleStateValue('openDesignateModal')}
        />
        <SignatureSentModal
          open={openSignatureSentModal}
          onClose={this.handleComplete}
        />
        {openVoidMessageModal && (
          <VoidMessageModal
            onClose={() => {
              this.toggleStateValue('openVoidMessageModal');
              history.push('/dashboard');
              }}
          />
        )}
        {openPasswordProtectModal && (
          <PasswordProtectModal
            onClose={() => this.handleOnCancelPasswordProtect()}
            onEnter={password => this.handleOnEnterPasswordProtect(password)}
          />
        )}
        {openDeclinedMessageModal && (
          <DeclinedMessageModal
            onClose={() => {
              this.toggleStateValue('openDeclinedMessageModal');
              history.push('/dashboard');
              }}
          />
        )}
      </div>
    );
  }
}

SignatureScreen.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  signDocumentScreen: PropTypes.object.isRequired,
  saveDocumentSign: PropTypes.func.isRequired,
  setSignDocumentFields: PropTypes.func.isRequired,
  declineSignatureRequest: PropTypes.func.isRequired,
  delegateSignatureRequest: PropTypes.func.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

const FormWrapper = styled.div`
  width: 960px;
  margin: 0 auto;
  margin-top: ${props => props.height + 16}px;
  @media (max-width: 960px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const PageContainer = styled.div`
  padding-top: 10rem;
  @media (max-width: 768px) {
    padding-top: 6rem;
  }
  filter: ${props => props.blurDocument && 'blur(0.5rem)'}
`;

const Box = styled.div`
  min-width: 200px;
  font-size: 24px;
  border-radius: 7px;
  background-color: #fff;
  padding: 15px;
  text-align: center;
  margin: 0 auto;
  margin-top: 2em;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
`;

export default connect(SignatureScreen);
