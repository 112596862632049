import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Paper } from 'material-ui';
import { colors } from '../utils';

const Box = props => (
  <div className={props.className}>
    <Paper>
      {props.withHeader &&
        <BoxHeader style={props.boxHeaderStyle} className={`${props.boxHeaderClass} d-flex align-items-center p-3`} >
          {props.title}
        </BoxHeader>}
      <BoxContent style={props.boxContentStyle} className={`${props.boxContentClass}`}>
        {props.children}
      </BoxContent>
    </Paper>
  </div>
);

Box.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  boxContentStyle: PropTypes.any,
  boxContentClass: PropTypes.string,
  boxHeaderStyle: PropTypes.any,
  boxHeaderClass: PropTypes.string,
  className: PropTypes.string,
  withHeader: PropTypes.bool,
};

Box.defaultProps = {
  boxContentStyle: {},
  boxContentClass: '',
  boxHeaderStyle: {},
  boxHeaderClass: '',
  className: '',
  withHeader: true,
};

const BoxHeader = styled.div`
  width: 100%;
  height: 3rem;
  background-color: ${colors.gray};
  font-size: 1.2rem;
  overflow: hidden;
`;

const BoxContent = styled.div`
  width: '100%';
  overflow: hidden;
`;

export default Box;
