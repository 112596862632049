import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RaisedButton, FlatButton } from 'material-ui';
import { Formik } from 'formik';
import { colors } from '../../utils';
import ReportTable from '../../components/ReportTable';
import SearchInput from '../../components/SearchInput';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../../components/pages';
import SendReportModal from '../../components/modals/SendReportModal/connect';
import { isRoleAdmin, isRoleOwner } from '../../utils/role';
import graphqlClient from '../../config/graphql';
import { DOWNLOAD_REPORT } from '../../components/analytics/EnvelopeDetailTable/queries';
import ScheduleReportForm, { FormSchema } from '../../components/forms/ScheduleReportForm';
import Modal from '../../components/Modal';
import { getFileAndDownload } from '../../utils/file';

const columns = [{
  id: 'user-name', label: 'Name', field: 'senderName',
}, {
  id: 'user-email', label: 'Email', field: 'email',
}, {
  id: 'envlps-sent', label: 'Envelopes Sent', field: 'envelopesSent',
}, {
  id: 'envlps-completed', label: 'Envelopes Completed', field: 'envelopesCompleted',
}];

export const UserActivityReportScreen = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentReportType, setCurrentReportType] = useState(null);

  // eslint-disable-next-line react/prop-types
  const { auth: { user }, onSchedule } = props;

  useEffect(() => {
    const { currentOrganization, history } = props;
    const userInfo = currentOrganization && currentOrganization.users.find(o =>
      o.uuid === user.uuid);
    if (!userInfo || (!isRoleAdmin(userInfo.role) && !isRoleOwner(userInfo.role))) {
      history.push('/dashboard');
    }
  });

  const downloadReport = async () => {
    // eslint-disable-next-line react/prop-types
    const { currentOrganization, reportVariables } = props;
    try {
      const { data: { downloadReport: { downloadUrl } } } = await graphqlClient(
        currentOrganization && currentOrganization.subdomain,
      ).mutate({
        mutation: DOWNLOAD_REPORT,
        variables: reportVariables,
      });
      getFileAndDownload(downloadUrl, 'user-activity-report.csv');
    } catch (e) {
      throw e;
    }
  };

  const getActions = () => [
    () => (
      <FlatButton
        primary
        label="Send Report"
        // eslint-disable-next-line react/prop-types
        onClick={() => props.setOpenReportModal(true)}
      />
    ),
    () => (
      <RaisedButton
        primary
        label="Download Report"
        onClick={downloadReport}
        buttonStyle={{ backgroundColor: colors.darkBlue }}
      />
    ),
  ];

  const renderFormContent = form => (
    <ScheduleReportForm
      {...form}
      email={user.email}
      onChange={(value, path) => {
        form.setFieldTouched(path, !!value);
        form.setFieldValue(path, value);
      }}
    />
  );

  const renderModal = form => (
    <StyledModal
      title="Schedule Report"
      primaryLabel="Schedule"
      open={!!currentReportType}
      content={renderFormContent(form)}
      primaryDisabled={!form.isValid}
      onPrimaryClick={() => {
        onSchedule({ ...form.values, reportType: currentReportType });
        setCurrentReportType(null);
      }}
      onSecondaryClick={() => setCurrentReportType(null)}
    />
  );

  return (
    <PageContainer>
      <PageHeader
        title="Analytics: User Activity Report"
      />
      <Stack>
        <SubHeader>Totals users and their envelope count</SubHeader>
        <ScheduleWrapper>
          <FlatButton
            primary
            label="Schedule"
            style={{ border: `1px solid ${colors.blue}`, marginLeft: '30px' }}
            onClick={() => setCurrentReportType('USER_ACTIVITY_REPORT')}
          />
        </ScheduleWrapper>
      </Stack>
      <PageContent>
        <SearchInput
          style={{ marginBottom: '30px' }}
          searchTermChanged={setSearchTerm}
        />
        <ReportTable
          {...props}
          columns={columns}
          searchTerm={searchTerm}
        />
        <Formik
          key={new Date().getTime()}
          validationSchema={FormSchema}
          render={renderModal}
        />
      </PageContent>
      <PageFooter
        customActions={getActions()}
        // eslint-disable-next-line react/prop-types
        onBackPress={() => props.history.push('/analytics?tab=Reports')}
      />
      <SendReportModal />
    </PageContainer>
  );
};

const SubHeader = styled.div`
  font-family: DIN-Medium;
  opacity: 0.54;
  display: flex;
  align-items: center;
`;

const Stack = styled.div`
  display: flex;
  margin: -20px 0 25px;
`;
const ScheduleWrapper = styled.div`
  display: flex; 
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  & > div > div {
    width: 640px !important;
    & > div {
      min-height: 500px;
      height: 625px;
      display: flex;
      flex-direction: column;
      & > h3 {
        margin-top: 30px !important;
      }
      & > :nth-child(2n) {
        flex: 1 1 0;
      }
    }
  }
`;

export default UserActivityReportScreen;
