import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, reduxForm, Field, change } from 'redux-form';
import { TextField, Checkbox } from 'redux-form-material-ui';
import styled from 'styled-components';
import { validateEmails } from '../../../utils';
import SelectBrandField from '../../../components/SelectBrandField';
import connect from './connect';

const validate = (values, props) => {
  const errors = {};

  const { validateAll, isFormValid } = props;
  if (!values.recipients) {
    errors.recipients = 'This field is required';
  } else if (!validateEmails(values.recipients)) {
    errors.recipients = 'You have entered an invalid email address, please check before sending';
  }

  if (validateAll) {
    if (!values.cc_self) {
      errors.cc_self = 'This field is required';
    }
    if (!values.use_brand) {
      errors.use_brand = 'This field is required';
    } else if (!values.brand) {
      errors.brand = 'This field is required';
    }
  }

  if (!values.subject) {
    errors.subject = 'This field is required';
  }

  if (!values.message) {
    errors.message = 'This field is required';
  }

  isFormValid(isEmpty(errors));

  return errors;
};

class SendDocumentForm extends Component {
  componentDidMount() {
    const { fetchBrands } = this.props;
    fetchBrands();
  }

  handleSubmit = () => {
    const {
      initialValues: { email },
      onSubmit,
      setMessageInitialValue,
      formValues,
    } = this.props;

    const payload = {
      ...formValues,
    };

    if (formValues.cc_self) {
      payload.cc = email;
    }

    setMessageInitialValue('');
    onSubmit(payload);
  };

  render = () => {
    const {
      initialValues, brands, useBrand, brandUuid, dispatch,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Field
          placeholder="Enter email addresses separated by comma"
          fullWidth
          component={TextField}
          name="recipients"
          floatingLabelText="Recipients"
          floatingLabelFixed
        />
        <div className="mt-3">
          <CCWrapper>
            <Field className="mt-3" component={Checkbox} name="cc_self" label={`CC me (${initialValues.email})`} />
          </CCWrapper>
        </div>
        <Field fullWidth component={TextField} name="subject" floatingLabelText="Subject" />
        <Field fullWidth component={TextField} name="message" floatingLabelText="Message" />
        {brands && !isEmpty(brands) && (
          <div className="d-flex">
            <Field
              name="use_brand"
              component={Checkbox}
              style={{ width: 'auto', marginTop: '16px' }}
            />
            <SelectBrandField
              currentBrand={brandUuid}
              brands={brands}
              enabled={useBrand}
              showError={useBrand && !brandUuid}
              onSelectBrand={value => dispatch(change('sendDocumentForm', 'brandUuid', value))}
              style={{ marginTop: '15px' }}
            />
          </div>
        )}
      </Form>
    );
  }
}


SendDocumentForm.propTypes = {
  initialValues: PropTypes.any.isRequired,
  formValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  setMessageInitialValue: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired,
  brandUuid: PropTypes.number,
  useBrand: PropTypes.bool,
};

SendDocumentForm.defaultProps = {
  useBrand: false,
  brandUuid: 0,
};

const CCWrapper = styled.div`
  margin: 20px 10px;
`;


export default connect(reduxForm({
  form: 'sendDocumentForm',
  enableReinitialize: true,
  validate,
})(SendDocumentForm));
