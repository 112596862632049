import gql from 'graphql-tag';

export const FETCH_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      cardInfo {
        cardBrand
        cardExpirationMonth
        cardExpirationYear
        cardLastDigts
      }
      city
      defaultInitials
      defaultSignature
      email
      firstName
      uuid
      lastName
      phone
      provider
      state
      streetAddress
      zipCode
      signInCount
      subscription{
        billingCycleStart
        billingCycleEnd
        plan {
          active
          amount
          apiAccess
          currency
          interval
          name
          nickname
          organizationMembersLimit
          organizationsLimit
          planType
          templatesLimit
          uuid
        }
      }
      planUsage {
        noApiDocumentsInUse
        apiDocumentsInUse
        documentsLimit
        archivedDocumentsInUse
        archivedDocumentsLimit
        organizationUsersInUse
        organizationUsersLimit
        organizationsInUse
        organizationsLimit
        templatesInUse
        templatesLimit
      }
      pendingInvites{
        nodes{
          email
          createdAt
          inviteType
          recipientName
          uuid
          organization{
            uuid
            users{
              nodes{
                uuid
                role
                email
                firstName
                lastName
              }
            }
            name
            businessName
            address
            subdomain
            completedDocumentsCount
            plan{
              amount
              currency
              interval
              name
              nickname
              planType
              uuid
            }
          }
        }
      }
      pendingPlanChangeRequestExists
      paymentIssue
      downgradeAt
    }
  }
`;

export const FETCH_PLAN_USAGE = gql`
  query getUserPlanUsage {
    currentUser {
      planUsage {
        apiDocumentsInUse
        noApiDocumentsInUse
        archivedDocumentsInUse
        documentsLimit
        archivedDocumentsLimit
        organizationUsersInUse
        organizationUsersLimit
        organizationsInUse
        organizationsLimit
        templatesInUse
        templatesLimit
      }
    }
  }
`;

export const LEAVE_ORGANIZATION = gql`
  mutation leaveOrganization($reason: String!) {
    leaveOrganization(reason: $reason) {
      success
    }
  }
`;

export default {
  FETCH_CURRENT_USER,
  FETCH_PLAN_USAGE,
  LEAVE_ORGANIZATION,
};
