import { toast } from 'react-toastify';
import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import templateService from '../services/template';


export const setTemplateEditorLoading = (loading, action) => ({
  type: Types.templateEditorScreen.SET_LOADING,
  loading,
  action,
});

export const setTemplateEditorTemplate = template => ({
  type: Types.templateEditorScreen.SET_TEMPLATE,
  template,
});


export const fetchTemplate = uuid => async (dispatch, getState) => {
  try {
    dispatch(setTemplateEditorLoading(true, 'fetchTemplate'));
    const { currentOrganization } = getState().organizations;
    const { data } = await templateService.get(uuid, (currentOrganization || {}).uuid);
    dispatch(setTemplateEditorTemplate(data));
    dispatch(setTemplateEditorLoading(false, 'fetchTemplate'));

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateTemplate = (uuid, pageTemplate) => async (dispatch, getState) => {
  try {
    const userTemplates = await templateService.list();
    const orgUuid = userTemplates.data.some(t => t.uuid === uuid)
      ? undefined
      : (getState().organizations.currentOrganization || {}).uuid;
    const { data } = await templateService.update(uuid, pageTemplate, orgUuid);
    toast.success(Messages.templates.update.success);
    return Promise.resolve(data);
  } catch (error) {
    toast.error(Messages.templates.update.error);
    return Promise.reject(error);
  }
};

export default {
  setTemplateEditorLoading,
  setTemplateEditorTemplate,
  fetchTemplate,
  updateTemplate,
};
