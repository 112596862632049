import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ArrowRightIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import IconButton from 'material-ui/IconButton';

const Expander = ({ title, content }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Container>
      <ExpansionPanel expanded={expanded} onChange={(e, val) => setExpanded(val)}>
        <ExpansionPanelSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <IconButton className="icon">
            {expanded ? <ArrowDownIcon fill="#767676" /> : <ArrowRightIcon fill="#767676" />}
          </IconButton>
          {title}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {content}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Container>
  );
};


Expander.propTypes = {
  content: PropTypes.any,
  title: PropTypes.string,
};

Expander.defaultProps = {
  content: '',
  title: '',
};

const Container = styled.div`
 & .icon {
   padding: 0rem !important;
   height: 1rem !important;
   margin-right: 2rem !important;
 }
`;


export default Expander;
