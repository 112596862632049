const getUserOrganizations = (user, organizations) => organizations
  .filter(org => org.users.find(u => u.email === user.email));

export const didOrganizationChanged = (prevProps, nextProps) => {
  if (!nextProps || !prevProps) {
    return false;
  }
  const { organizations: { currentOrganization } } = nextProps;
  const { organizations: { currentOrganization: prevOrganization } } = prevProps;
  return !!((!prevOrganization && currentOrganization) ||
    (prevOrganization && prevOrganization.uuid
      && currentOrganization && currentOrganization.uuid
      && currentOrganization.uuid !== prevOrganization.uuid) ||
    (!currentOrganization && prevOrganization));
};


export default getUserOrganizations;
