import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { createTemplateField } from '../../actions/signs';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render() {
      // eslint-disable-next-line react/prop-types
      const { open } = this.props;
      return (<Component {...this.props} open={open} />);
    }
  };

function mapStateToProps({ signs }) {
  return {
    signs,
  };
}

function mapDispatchToProps() {
  return {
    createTemplateField,
  };
}

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));


export default connect;
