import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { RaisedButton, FlatButton } from 'material-ui';
import connect from './connect';
import { history } from '../../config';
import SendDocumentModal from '../../components/modals/SendDocumentModal';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../../components/pages';
import SectionLoader from '../../components/SectionLoader';
import { colors } from '../../utils';

class DocSignedScreen extends React.Component {
  componentDidMount = () => {
    const {
      filesManager: { documents },
      restoreFromPrevious,
      disableOrganizationChange,
    } = this.props;
    disableOrganizationChange();
    if (isEmpty(documents)) {
      restoreFromPrevious();
    }
  };

  componentWillUnmount() {
    this.props.enableOrganizationChange();
  }

  handleUploadAnother = () => {
    const {
      filesManager: { documents },
      setPreviousDocuments,
    } = this.props;

    if (!isEmpty(documents)) {
      setPreviousDocuments();
    }

    history.push('/fill-sign');
  };

  handleSend = (values) => {
    const { sendDocument, match: { params } } = this.props;
    sendDocument(params.id, values);
  }

  render() {
    const {
      filesManager,
      template,
      displayModal,
      sending,
      submitSendDocument,
      toggleSendDocumentModal,
      loader,
      message,
    } = this.props;
    const documents = isEmpty(filesManager.documents)
      ? filesManager.previousDocuments
      : filesManager.documents;

    // In case page is reloaded
    if (loader.active) {
      return (
        <PageContainer>
          <PageContent>
            <SectionLoader />
          </PageContent>
        </PageContainer>
      );
    }

    if (!documents[0]) {
      this.props.history.push('/fill-sign');
      return null;
    }

    const fileName = documents[0].file_name;
    const imagePreview = documents[0].document_preview_url;
    const urlPDFFile = template.signedPdf.url;

    return (
      <PageContainer>
        <PageHeader title="Fill & Sign" />
        <PageContent>
          <Container>
            <DocumentPreview>
              <Preview src={imagePreview} />
              <DocumentName>{fileName}</DocumentName>
            </DocumentPreview>
            <RightSection>
              <Congratulations>Congratulations!</Congratulations>
              <Description>You’ve successfully signed [{fileName}]</Description>
              <div style={{ marginBottom: '20px' }}>
                <RaisedButton
                  label="SEND A COPY"
                  onClick={() => toggleSendDocumentModal(true)}
                  style={{ marginRight: '20px' }}
                  primary
                />
                <FlatButton
                  label="DOWNLOAD A COPY"
                  target="_blank"
                  href={urlPDFFile}
                  style={{ marginRight: '20px' }}
                  primary
                  download
                />
                <FlatButton
                  label="Fill & Sign another document"
                  onClick={this.handleUploadAnother}
                  primary
                />
              </div>
            </RightSection>
          </Container>
        </PageContent>
        <PageFooter
          customActions={[
          () => (
            <FlatButton
              labelStyle={{ color: colors.blue }}
              label="Document Manager"
              onClick={() => history.push('/documents-manager')}
            />
          ),
        ]}
        />
        <SendDocumentModal
          isOpen={displayModal}
          handleModal={submitSendDocument}
          handleCancel={() => toggleSendDocumentModal(false)}
          handleSubmit={values => this.handleSend(values)}
          sending={sending}
          selectedDocument={{ ...documents[0], message }}
        />
      </PageContainer>
    );
  }
}

DocSignedScreen.propTypes = {
  toggleSendDocumentModal: PropTypes.func.isRequired,
  setPreviousDocuments: PropTypes.func.isRequired,
  restoreFromPrevious: PropTypes.func.isRequired,
  submitSendDocument: PropTypes.func.isRequired,
  sendDocument: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loader: PropTypes.object.isRequired,
  filesManager: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  displayModal: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  disableOrganizationChange: PropTypes.func.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
};


const Container = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const DocumentPreview = styled.div`
  width: 175px;
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Preview = styled.img`
  height: 170px;
  width: 132px;
`;

const DocumentName = styled.div`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  width: 100%;
`;

const RightSection = styled.div`
  margin-left: 6rem;
`;

const Congratulations = styled.div`
  font-size: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  margin: 15px 0 40px;
`;

export default connect(DocSignedScreen);
