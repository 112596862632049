import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AutoComplete } from 'material-ui';
import history from '../../../config/history';
import { Modal } from '../..';

export class RemoveMemberModal extends Component {
  state = {
    selectedUser: {},
  }

  userDataSource = () => {
    const { currentOrganization, currentUser } = this.props;
    const { users } = currentOrganization;
    return users.filter(u => u.uuid !== currentUser.uuid).map(user => ({
      ...user,
      text: user.full_name,
    }));
  }

  handleRemove = () => {
    const { onRemove, onCancel, currentUser: { uuid: userUuid } } = this.props;
    const { selectedUser: { uuid: transferToUserUuid } } = this.state;
    onRemove({ userUuid, transferToUserUuid }, () => {
      onCancel();
      history.push('/organizations');
    });
  }

  handleNewRequest = selectedUser => this.setState({ selectedUser })

  handleFilter = (searchText, key) => key.toLowerCase().includes(searchText.toLowerCase());

  renderContent = () => (
    <Wrapper>
      <Title>Remove Member from Organization</Title>
      <Description>
        Are you sure you want to remove this member? You will
        need to choose a new member to transfer their file ownership.
      </Description>
      <AutoComplete
        floatingLabelText="Member"
        hintText="Search by name"
        filter={this.handleFilter}
        onNewRequest={this.handleNewRequest}
        dataSource={this.userDataSource()}
        fullWidth
      />
    </Wrapper>
  );

  render = () => {
    const { onCancel } = this.props;
    const { selectedUser } = this.state;
    return (
      <Modal
        open
        title=""
        content={this.renderContent()}
        onPrimaryClick={this.handleRemove}
        onSecondaryClick={onCancel}
        primaryLabel="Remove"
        contentStyle={{ width: '600px' }}
        primaryDisabled={!selectedUser || !selectedUser.uuid}
      />
    );
  }
}

RemoveMemberModal.propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
};

RemoveMemberModal.defaultProps = {
  onRemove: () => {},
  onCancel: () => {},
};

const Wrapper = styled.div`
  color: black;
  font-family: DIN-Medium;
`;

const Title = styled.div`
  font-size: 23px;
`;

const Description = styled.div`
  margin: 20px 0;
`;

export default RemoveMemberModal;
