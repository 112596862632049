import React from 'react';
import PropTypes from 'prop-types';

import InviteNewUserForm from '../../forms/InviteNewUserForm';
import Modal from '../../Modal';

export const InviteNewUser = ({
  closeInviteNewUser, organizationsScreen: { showInviteNewUser, isValid },
  submitInviteForm, inviteNewUser,
}) => (
  <Modal
    title="Invite New User"
    primaryLabel="Send"
    primaryDisabled={!isValid}
    onPrimaryClick={submitInviteForm}
    onSecondaryClick={closeInviteNewUser}
    open={showInviteNewUser}
    content={<InviteNewUserForm onSubmit={inviteNewUser} />}
  />
);

InviteNewUser.propTypes = {
  submitInviteForm: PropTypes.func.isRequired,
  organizationsScreen: PropTypes.object.isRequired,
  closeInviteNewUser: PropTypes.func.isRequired,
  inviteNewUser: PropTypes.func.isRequired,
};

export default InviteNewUser;
