import React from 'react';
import { map, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from 'material-ui/Paper';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import EditIcon from 'material-ui/svg-icons/image/edit';
import AddIcon from 'material-ui/svg-icons/content/add';
import RecipientSelectField from '../../RecipientSelectField';
import connect from './connect';
import Expander from '../../Expander';
import FieldsPanelOption from '../FieldsPanelOption';
import { colors } from '../../../utils';

class FieldsPanel extends React.Component {
  state = {
    selectedTab: 'STANDARD_FIELDS',
  };

  handleOnTabPress = (tab) => {
    this.props.onTabPress(tab);
    this.setState({ selectedTab: tab.id });
  }

  renderSpecialFieldOptions = () => (
    <div style={{ width: '100%' }}>
      <Expander title="Data Label" content="Content here" />
      <Expander title="Tooltip" content="Content here" />
      <Expander title="Location" content="Content here" />
      <Expander title="Sender Permissions" content="Content here" />
    </div>
  );

  renderPanelOptions = () => {
    const { currentTab } = this.props;
    let fieldsCounter = 0;
    currentTab.groups.forEach((group) => {
      fieldsCounter += group.fields.length;
    });

    if (!currentTab) return null;
    return (
      <PanelOptions height={fieldsCounter * 50}>
        {this.renderSubHeader()}
        {this.renderFieldsGroups(currentTab)}
      </PanelOptions>
    );
  }

  renderPanelTabs = () => map(this.props.tabs, tab => (
    <PanelTab
      style={{ backgroundColor: `${tab.id === this.state.selectedTab ? 'white' : 'transparent'}` }}
      active="true"
      key={tab.id}
      onClick={() => this.handleOnTabPress(tab)}
    >
      {!tab.specialIcon
        ? <tab.icon />
        : <CustomFieldIconContainer icon={tab.specialIcon} />}
    </PanelTab>
  ));

  renderSubHeader = () => {
    const { currentTab, onClickEdit } = this.props;
    if (!currentTab.showAddIcon) {
      return <Subheader>{currentTab.name}</Subheader>;
    }

    return (
      <Subheader>
        <SubheaderContent>
          <div>{currentTab.name}</div>
          <IconButton style={{ bottom: '0.7rem' }} onClick={() => onClickEdit()}>
            <AddIcon color={colors.darkGray} />
          </IconButton>
        </SubheaderContent>
      </Subheader>
    );
  }

  renderFieldsGroups = ({ groups }) => (
    groups.map((group, index) => (
      <PanelOptionsSection key={Math.random().toString()}>
        {this.renderGroupFields(group.fields)}
        {index !== groups.length - 1 && <Divider className="mx-3" />}
      </PanelOptionsSection>
    ))
  );

  renderGroupFields = (fields) => {
    const { addEditorPageField, currentEditorPage, onClickEdit } = this.props;

    return fields
      .filter(field => field.id !== undefined).map(field => (
        <PanelOption key={Math.random().toString()}>
          <FieldsPanelOption
            id={field.id}
            field={field}
            addEditorPageField={addEditorPageField}
            currentEditorPage={currentEditorPage}
            key={Math.random().toString()}
            Icon={field.icon}
            specialIcon={field.specialIcon}
            label={field.panelName || field.name}
            fullWidthHoover={!field.editable}
          />
          {field.editable && (
            <IconButton onClick={() => onClickEdit(field)}>
              <EditIcon color={colors.darkGray} />
            </IconButton>
          )}
        </PanelOption>
      ));
  }

  renderRecipientContainer = () => {
    const { recipients, currentRecipient, setCurrentRecipient } = this.props;
    return (
      <RecipientContainer>
        <Title>Role</Title>
        <RecipientSelectField
          fullWidth
          enabled
          recipients={recipients}
          value={currentRecipient.uuid}
          onChange={setCurrentRecipient}
        />
      </RecipientContainer>
    );
  }

  render() {
    const {
      currentTab,
      onSave,
      resetEditorPageFields,
      validateSpecialFields,
      showBack,
      sendDocumentScreen,
    } = this.props;
    let fieldsCounter = 0;
    currentTab.groups.forEach((group) => {
      fieldsCounter += group.fields.length;
    });

    const renderTemplateOptions = () => (
      <>
        <ActionsContainer>
          <RaisedButton
            primary
            className="save-button"
            label="Save"
            disabled={!validateSpecialFields()}
            onClick={onSave}
          />
          <FlatButton
            primary
            label="Reset Fields"
            onClick={resetEditorPageFields}
          />
        </ActionsContainer>
      </>
    );

    const renderSignatureRequestOptions = () => (
      <>
        <ActionsContainer>
          <TopActionsContainer>
            <RaisedButton
              primary
              className="back-button"
              label="Back"
              disabled={!validateSpecialFields()}
              onClick={() => {
                resetEditorPageFields();
                onSave(true);
                }
              }
            />
            <RaisedButton
              primary
              className="save-button"
              label="Save"
              disabled={!validateSpecialFields() || showBack}
              onClick={() => onSave()}
            />
          </TopActionsContainer>
          <FlatButton
            primary
            label="Reset Fields"
            onClick={resetEditorPageFields}
          />
        </ActionsContainer>
      </>
    );

    return (
      <Container height={fieldsCounter * 50} className="d-flex flex-column">
        {this.renderRecipientContainer()}
        <div className="d-flex panel">
          <PanelTabs className="d-flex flex-column">
            {this.renderPanelTabs()}
          </PanelTabs>
          {this.renderPanelOptions()}
        </div>
        {isEmpty(sendDocumentScreen.documents) ?
          renderTemplateOptions() : renderSignatureRequestOptions()}
      </Container>
    );
  }
}

FieldsPanel.propTypes = {
  tabs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  currentTab: PropTypes.object.isRequired,
  onTabPress: PropTypes.func,
  onClickEdit: PropTypes.func,
  recipients: PropTypes.any.isRequired,
  onSave: PropTypes.func,
  currentRecipient: PropTypes.any.isRequired,
  setCurrentRecipient: PropTypes.func.isRequired,
  resetEditorPageFields: PropTypes.func.isRequired,
  currentEditorPage: PropTypes.object.isRequired,
  sendDocumentScreen: PropTypes.object.isRequired,
  showBack: PropTypes.bool.isRequired,
  validateSpecialFields: PropTypes.func.isRequired,
  addEditorPageField: PropTypes.func.isRequired,
};

FieldsPanel.defaultProps = {
  onTabPress: () => { },
  onClickEdit: () => { },
  onSave: () => { },
};

const Container = styled(Paper)`
  position: fixed;
  right: 0;
  width: ${props => (props.collapsed ? '48px' : '360px')};
  height: 100%;
  z-index: 10;
  min-height: 40vh;
  bottom: 0;
  top: 0;

  & .panel{
    border-top: 1px solid #ECEFF1;
    height: 100%;
    overflow: hidden;
  }
`;

const PanelTabs = styled.div`
  flex: 1;
  min-width: 48px;
  max-width: 48px;
  height: 100%;
  background-color: ${colors.gray};
  & svg{
    color: gray !important;
  }
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const RecipientContainer = styled.div`
  margin: 1rem 2rem 0rem;
`;

const PanelTab = styled(IconButton)`
  width: 48px;
  height: 48px;
  padding: 0.75rem;

  background: ${props => (props.active ? 'white' : 'transparent')};
`;

const TopActionsContainer = styled.div`
  width: 100%;
  display: flex;
  & > div {
    width: 47%;
    margin: 0 1.5%;
  }
`;

const ActionsContainer = styled.div`
  width: 100%;
  border-top: 1px solid #ECEFF1;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  & > div {
    margin: 0.5rem 0rem;
  }

  & .save-button button {
    background-color: ${colors.darkBlue} !important;
    &:disabled {
      background-color: ${colors.gray} !important;
    }
  }

  & .back-button button {
    background-color: white !important;
    border: 1px solid ${colors.blue} !important;
    box-shadow: 3px 3px 3px 3px white !important;
    & * {
      color: ${colors.blue} !important;
    }

    &:hover {
      background-color: ${colors.blue} !important;
      border: 1px solid ${colors.blue} !important;
      box-shadow: 3px 3px 3px 3px white !important;
      & * {
        color: white !important;
      }
    }
  }
`;

const CustomFieldIconContainer = styled.div`
  height: 20px;
  width: 100%;
  background-image: url(${props => props.icon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;


const PanelOptions = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const PanelOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PanelOptionsSection = styled.div`
  width: 100%;
`;

const SubheaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default connect(FieldsPanel);
