
/**
 * Gets the original size of an image. Returns a Promise
 * @param {string} imageURL
 * @returns
 */
function getImageSizeAsync(imageURL) {
  return new Promise((resolve, reject) => {
    const imgElem = new Image();
    imgElem.src = imageURL;

    imgElem.onload = function onImageLoad() {
      const { src, width, height } = imgElem;
      if (src && width && height) {
        resolve({ src, width, height });
      } else {
        reject(new Error('An error occurred getting image size', { src, width, height }));
      }
    };
  });
}

export default {
  getImageSizeAsync,
};
