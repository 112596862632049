import React from 'react';
import Helmet from 'react-helmet';

export default () => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Identity Mark CMS</title>
    <link rel="canonical" href="http://mysite.com/example" />
  </Helmet>
);
