import gql from 'graphql-tag';

export const FETCH_INVITE_INFO = gql`
  query getInvite($token: String!) {
    invite(token: $token) {
      email
      inviteType
      organization {
        address
        businessName
        completedDocumentsCount
        name
        plan {
          amount
          apiAccess
          currency
          interval
          name
          nickname
          planType
          uuid
        }
        users {
          nodes{
            uuid
            email
            firstName
            lastName
          }
        }
        uuid
      }
      recipientName
      upgradeToPlan {
        amount
        apiAccess
        currency
        interval
        name
        nickname
        planType
        uuid
      }
      uuid
    }
  }
`;

export default {
  FETCH_INVITE_INFO,
};
