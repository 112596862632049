import React from 'react';
import { connect as ReduxConnect } from 'react-redux';
import SendReportModal from './SendReportModal';

const connect = Component => props => (
  <Component
    {...props}
  />
);

const mapStateToProps = ({ analyticsScreen }) => ({ ...analyticsScreen });

export default ReduxConnect(
  mapStateToProps, null)(connect(SendReportModal));
