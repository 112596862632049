import api from './api';

const resolveWithUuidToId = r => Promise.resolve({
  ...r,
  data: { ...r.data, id: r.data.uuid },
});

export default {
  update: (uuid, payload) => api
    .put(`/users/${uuid}`, payload)
    .then(resolveWithUuidToId),
  resetPassword: payload => api
    .put('/auth/password', payload)
    .then(resolveWithUuidToId),
};
