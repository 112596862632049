import gql from 'graphql-tag';

export const FETCH_TEMPLATES = gql`
  query getTemplates {
    templates{
      nodes{
        activityLogs{
          nodes{
            event
            eventDate
            id
          }
        }
        brand {
          id: uuid
        }
        recipients{
          nodes {
            email
            uuid
            signingOrder
          }
        }
        defaultMessage
        completeInAnyOrder
        description
        name
        uuid
        previewImage {
          url
        }
        pageFields{
          nodes{
            id
            recipient {
              email
              signingOrder
              uuid
            }
            metadata
            field {
              id
              inputType
            }
            pageFieldDetail{
              height
              width
              id
              readOnly
              required
              xCoord
              yCoord
            }
            page{
              id
              document{
                fileName
                uuid
                previewImage {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default {
  FETCH_TEMPLATES,
};
