import { isEmpty } from 'lodash';

export function mapTemplatePageFields(pageFields) {
  return pageFields.filter(p => !p._destroy).map((pageField) => {
    const withMetadata = pageField.field_type === 'checkbox' || pageField.field_type === 'select'
        || pageField.field_type === 'radio';

    const metadata = {
      options: pageField.options,
      selectedOption: pageField.options ?
        pageField.options.indexOf(pageField.selected_option) : undefined,
      checked: pageField.checked,
      radioButtonGroupId: pageField.radio_button_group_id ?
        pageField.radio_button_group_id.toString() : undefined,
    };

    // eslint-disable-next-line array-callback-return
    Object.keys(metadata).map((key) => {
      if (metadata[key] === undefined) {
        delete metadata[key];
      }
    });

    const data = {
      fieldId: pageField.fieldType,
      pageId: pageField.pageId,
      recipientUuid: pageField.recipientUuid,
      pageFieldDetail: {
        xCoord: pageField.x,
        yCoord: pageField.y,
        height: pageField.height,
        width: pageField.width,
        required: pageField.required,
      },
    };
    return withMetadata && !isEmpty(metadata) ?
      ({ ...data, metadata }) : data;
  });
}

export default {
  mapTemplatePageFields,
};
