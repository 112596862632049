import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuItem } from 'material-ui';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DragItemTypes } from '../../constants';
import { requiredFields } from '../../config/editorFields';

// DnD Spec
const dragSourceSpec = {
  beginDrag(props) {
    return { id: props.id, isNew: true };
  },
};

// DnD Collector
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

class FieldsPanelOption extends React.Component {
  static propTypes = {
    Icon: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    addEditorPageField: PropTypes.func.isRequired,
    currentEditorPage: PropTypes.object.isRequired,
    isDragging: PropTypes.bool.isRequired,
    field: PropTypes.object.isRequired,
    specialIcon: PropTypes.bool,
    fullWidthHoover: PropTypes.bool,
  }

  static defaultProps = {
    fullWidthHoover: true,
    specialIcon: false,
  }

  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  handleAddField = () => {
    const { field, addEditorPageField, currentEditorPage } = this.props;
    addEditorPageField({
      id: (new Date()).getTime(),
      fieldType: field.id,
      x: 10,
      y: 10,
      width: 200,
      height: 20,
      isNew: true,
      required: requiredFields.includes(field.id),
    },
    currentEditorPage.documentUUID,
    currentEditorPage.id,
    );
  }

  render() {
    const { Icon, fullWidthHoover, specialIcon } = this.props;
    return this.props.connectDragSource((
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onClick={this.handleAddField}
        style={{
          position: 'relative',
          zIndex: 1,
          fontFamily: 'DIN-Medium',
          width: `${fullWidthHoover ? '100' : '80'}%`,
          }}
      >
        <MenuItem
          // eslint-disable-next-line jsx-a11y/alt-text
          leftIcon={specialIcon ? <img src={Icon} style={{ objectFit: 'none' }} /> : <Icon />}
          primaryText={this.props.label}
          disabled={this.props.isDragging}
        />
        <ClickableOverlay />
      </div>
    ));
  }
}

const ClickableOverlay = styled.div`
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 100;
  cursor: grab;
  transition: all 0.15s linear;

  &:hover {
    background: rgba(0,0,0,0.1);
  }
`;

export default DragSource(
  DragItemTypes.FIELDS_PANEL_OPTION,
  dragSourceSpec,
  collect,
)(FieldsPanelOption);
