import Types from '../constants/actionTypes';
import graphqlClient from '../config/graphql';
import subscriptions from '../constants/subscriptionsPlan';
import { FETCH_PLANS } from '../utils/queries/subscriptions';
import { mergeTypeWithInterval } from '../utils/parsers/subscriptionParser';

export const fetchSubscriptionPlans = async () => {
  try {
    const {
      data: { plans: { nodes } },
    } = await graphqlClient().query({
      query: FETCH_PLANS,
      fetchPolicy: 'network-only',
    });
    return nodes;
  } catch (e) {
    return [];
  }
};

export const fetchPlans = () => async (dispatch) => {
  const subscriptionPlans = await fetchSubscriptionPlans();
  const plansByType = subscriptionPlans.reduce(mergeTypeWithInterval, {});
  const orgLimitsByPlan = {};
  // eslint-disable-next-line array-callback-return
  subscriptionPlans.map((p) => { orgLimitsByPlan[p.planType] = p.organizationsLimit; });
  const plans = subscriptions.map((s) => {
    const plansByInterval = plansByType[s.planType] || {};
    const planInfo = plansByInterval[s.interval];
    const uuidAndAmount = planInfo ? {
      uuid: planInfo.uuid,
      amount: planInfo.amount,
    } : {};
    return {
      ...s, ...uuidAndAmount, plansByInterval, organizationsLimit: orgLimitsByPlan[s.planType],
    };
  });

  dispatch({ type: Types.subscriptions.SET_PLANS, plans });
  return plans;
};

export const setSelectedSubscription = selectedSubscription => dispatch => dispatch({
  type: Types.subscriptions.SET_SELECTED_SUBSCRIPTION,
  selectedSubscription,
});

export const setOpenItemsSelectionModal = openItemsSelectionModal => dispatch => dispatch({
  type: Types.subscriptions.SET_OPEN_ITEMS_SELECTION_MODAL,
  openItemsSelectionModal,
});

export const setOpenPaymentModal = openPaymentModal => dispatch => dispatch({
  type: Types.subscriptions.SET_OPEN_PAYMENT_MODAL,
  openPaymentModal,
});

export const setSubscriptionFieldValue = (field, value) => dispatch => dispatch({
  type: Types.subscriptions.SET_SUBSCRIPTION_FIELD,
  field,
  value,
});

export const setApiPlanModal = apiUpdate => async (dispatch) => {
  dispatch({
    type: Types.subscriptions.SET_VISIBLE_PLANS,
    apiUpdate,
  });
};

export const setOpenUpdateModal = (openUpdateModal, visiblePlans = 'all') => (dispatch) => {
  dispatch(setApiPlanModal(visiblePlans));
  dispatch({
    type: Types.subscriptions.SET_OPEN_UPDATE_MODAL,
    openUpdateModal,
  });
  if (!openUpdateModal) {
    dispatch(setSubscriptionFieldValue('newOrganizationUpgradeRequired', false));
  }
};

export const setUpgradeModal = (open, personalAccount = false) => (dispatch) => {
  dispatch({
    type: Types.subscriptions.SET_UPGRADE_MODAL,
    openUpgradeModal: open,
    personalAccountAction: personalAccount,
  });
  if (!open) {
    dispatch(setSubscriptionFieldValue('newOrganizationUpgradeRequired', false));
  }
};

export const setOpenErrorCreditCardModal = open => (dispatch) => {
  dispatch({
    type: Types.subscriptions.SET_OPEN_ERROR_CREDIT_CARD_MODAL,
    open,
  });
};

export default {
  fetchPlans,
  setSelectedSubscription,
  setOpenItemsSelectionModal,
  setOpenUpdateModal,
  setUpgradeModal,
  setSubscriptionFieldValue,
  setOpenErrorCreditCardModal,
  setApiPlanModal,
};
