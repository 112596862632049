import React, { useEffect, useState } from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchDocumentDimensions } from '../../actions/documentDimensions';
import graphqlClient from '../../config/graphql';
import organizationsActions from '../../actions/organization';
import subscriptionsActions from '../../actions/subscriptions';
import userProfileActions from '../../actions/userProfileScreen';
import authActions from '../../actions/auth';
import { FETCH_CURRENT_USER, LEAVE_ORGANIZATION } from './queries';
import UserProfileScreen from './UserProfileScreen';

export const connect = Component => (props) => {
  const {
    /* eslint-disable react/prop-types */
    fetchOrganizations, fetchPlans, plans, setCurrentUser, user,
    setUserOrganizations, setOpenUpdateModal, setCurrentAuthUser,
    /* eslint-enable */
  } = props;
  const { organizations, email, subscription } = user;

  React.useEffect(() => {
    // eslint-disable-next-line react/prop-types
    props.fetchDocumentDimensions();
  }, []);

  const [loading, setLoading] = useState(false);

  const fetchCurrentUser = async () => {
    try {
      const {
        data: { currentUser },
      } = await graphqlClient().query({
        query: FETCH_CURRENT_USER,
        fetchPolicy: 'network-only',
      });
      setCurrentAuthUser({ ...user, currentUser });
      setCurrentUser(currentUser);
    } catch (e) {
      throw e;
    }
  };

  const leaveOrganization = async (org) => {
    setLoading(true);
    try {
      await graphqlClient(org.subdomain).mutate({
        mutation: LEAVE_ORGANIZATION,
        variables: { reason: 'Leaving organization' },
      });
      setUserOrganizations(organizations.filter(o => o.uuid !== org.uuid));
      toast.success('You left the organization successfuly');
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const bootstrap = async () => {
    setLoading(true);
    await fetchOrganizations();
    await fetchCurrentUser();
    if (plans.length === 0) {
      await fetchPlans();
    }
    setLoading(false);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <Component
      {...props}
      subscription={subscription}
      onChangePlan={() => setOpenUpdateModal(true)}
      onContactUs={() => {
        window.location.href = `${process.env.REACT_APP_FRONT_PAGE_URL}/contact-us.html?email=${email}`;
      }}
      leaveOrganization={leaveOrganization}
      loading={loading}
    />
  );
};

export default RConnect(({
  auth: { user },
  organizations: { currentOrganization },
  subscriptions,
  userProfileScreen,
  documentDimensions,
}) => ({
  ...subscriptions,
  user,
  userRole: (user.roles || [])[0],
  organizations: user.organizations || [],
  currentOrganization,
  userProfileScreen,
  currentUser: userProfileScreen.currentUser,
  documentDimensions,
}), {
  ...userProfileActions,
  ...subscriptionsActions,
  setUserOrganizations: authActions.setUserOrganizations,
  setCurrentAuthUser: authActions.setCurrentUser,
  fetchOrganizations: organizationsActions.fetchOrganizations,
  deleteOrganization: organizationsActions.deleteOrganization,
  fetchDocumentDimensions,
})(connect(UserProfileScreen));
