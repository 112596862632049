import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import {
  scaleValueWithConstant,
} from '../../../utils/scaling';

const RadioButton = ({
  field, scaleRatio, imageWidth, onClick, recipients,
  mode, width, height, onChange, onSaved,
}) => {
  const currentRecipient = recipients && recipients.find(r => r.uuid === field.recipientUuid);
  const renderSignRadioButtonComponent = () => (
    <FieldContainer
      onClick={() => {
      onSaved(true);
      onChange(true);
    }}
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
    >
      <StyledRadioButton
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
        width={width}
        height={height}
        checked={field.value}
      >
        {!field.value ? <div className="circle" /> : (
          <div className="circle">
            <div className="selected-circle" />
          </div>
        )}
      </StyledRadioButton>
    </FieldContainer>
  );
  return mode === 'sign' ? renderSignRadioButtonComponent() : (
    <ResizableBox
      width={field.width}
      height={field.height}
      minConstraints={[field.width, field.height]}
      maxConstraints={[field.width, field.height]}
      className="react-resizable-handle-hidden"
      onClick={onClick}
    >

      <div style={{ display: 'flex' }}>
        <IndicatorContainer
          recipientColor={currentRecipient && currentRecipient.color}
          scaleRatio={scaleRatio}
          imageWidth={imageWidth}
          width={field.width}
          height={field.height}
        >
          <div className="indicator" />
        </IndicatorContainer>
        <div style={{ width: '100%', height: '100%' }}>
          <StyledRadioButton
            scaleRatio={scaleRatio}
            imageWidth={imageWidth}
            width={field.width}
            height={field.height}
            checked={field.checked}
          >
            {!field.checked ? <div className="circle" /> : (
              <div className="circle">
                <div className="selected-circle" />
              </div>
          )}
          </StyledRadioButton>
        </div>
      </div>
    </ResizableBox>
  );
};

RadioButton.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  onSaved: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

RadioButton.defaultProps = {
  mode: 'edit',
  width: 0,
  height: 0,
  onChange: () => {},
  onSaved: () => {},
};


const FieldContainer = styled.div`
   & input {
     opacity: 0.9 !important;
     background-color: #B3E1F5;
     border: 1px solid #3190C0;
     display: flex;
     padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
     align-items: flex-start;
     ::placeholder {  
       font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
       color: #236BA0;
       font-weight: 500;
    }
   }
`;

const StyledRadioButton = styled.div`
  background-color: #B1E1F6;
  height: ${({ imageWidth, height }) => scaleValueWithConstant(height, 1, imageWidth)}px !important;
  width: ${({ imageWidth, height }) => scaleValueWithConstant(height, 1, imageWidth)}px !important;
  padding: 0.1rem;
  position:absolute;
  z-index: 1;

  & > .circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid ${props => (props.checked ? '#236BA0' : '#3698C5')};

      & > .selected-circle {
        width: 70%;
        margin: 15%;
        height: 70%;
        border-radius: 50%;
        background-color: #236BA0;
    }
  }
`;


const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
  height: 100%;
  right: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(14, scaleRatio, imageWidth)}px;
  background-color: #B1E1F6;

  & > .indicator {
    border-radius: 50%;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    background-color: ${props => props.recipientColor};
    position: relative;
    z-index: 2;
  }
`;
export default RadioButton;
