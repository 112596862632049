export const getOrganizationUuid = (state) => {
  const { currentOrganization } = state.organizations;
  const organizationUuid = (currentOrganization && currentOrganization.uuid) ?
    currentOrganization.uuid : undefined;
  return organizationUuid;
};

export default {
  getOrganizationUuid,
};
