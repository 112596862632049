import gql from 'graphql-tag';

export const UPDATE_CARD = gql`
  mutation UpdateCard( $cardToken: String!) {
    updateCard(cardToken: $cardToken) {
      cardInfo {
        cardBrand
        cardExpirationMonth
        cardExpirationYear
        cardLastDigts
      }
    }
  }
`;

export default {
  UPDATE_CARD,
};
