const freeFeatures = [
  '3 Documents',
  '1 Sender',
  '0 Templates',
  'Audit Trail',
  'Data Validation',
];

const proFeatures = [
  'Unlimited Documents',
  '1 Sender',
  '1 Template',
  'Audit Trail',
  'Data Validation',
];

const bussinessFeatures = [
  'Unlimited Documents',
  '5 Senders',
  '5 Templates',
  'Audit Trail',
  'Data Validation',
  'Organization / Team Access',
];

const membershipEnterpriceFeatures = [
  'All business features +',
  'Unlimited Templates',
  'Audit Trail',
  'Data Validation',
  'White Labeling',
  'Multi Organization / Teams',
  'Advanced Signing Tools',
  'User Reporting',
  'Advanced Admin Tools',
];

const bronzeFeatures = [
  '50 API Signature Requests',
  '5 Templates',
  'Data Validation',
];

const silverFeatures = [
  '150 API Signature Requests',
  '15 Templates',
  'Data Validation',
  'OAuth',
  'Embedded Signing',
  'Embedded Requesting',
];

const goldFeatures = [
  '450 API Signature Requests',
  'Unlimited Templates',
  'Data Validation',
  'OAuth',
  'Embedded Signing',
  'Embedded Requesting',
  'Advanced Signing Tools',
];

const enterpriceFeatures = [
  'API Signature Requests HIGH VOLUME',
  'Unlimited Templates',
  'Data Validation',
  'OAuth',
  'White Labeling',
  'Embedded Signing',
  'Embedded Requesting',
  'Embedded Templates',
  'Advanced Signing Tools',
  'Multiple Languages',
  'Multiple Domains',
];

export default [{
  id: 1,
  title: 'Basic',
  features: freeFeatures,
  headerText: 'FREE',
  actionLabel: 'Choose Plan',
  planType: 'FREE',
  category: 'membership',
}, {
  id: 2,
  title: 'Pro',
  features: proFeatures,
  actionLabel: 'Choose Plan',
  planType: 'NO_API_PRO',
  category: 'membership',
}, {
  id: 3,
  title: 'Business',
  features: bussinessFeatures,
  actionLabel: 'Choose Plan',
  planType: 'NO_API_BUSINESS',
  category: 'membership',
}, {
  id: 4,
  title: 'Enterprise',
  features: membershipEnterpriceFeatures,
  headerText: 'Looking for more?',
  actionLabel: 'Contact Us',
  contactUsAction: true,
  planType: 'NO_API_ENTERPRISE',
  category: 'membership',
}, {
  id: 5,
  title: 'Bronze',
  features: bronzeFeatures,
  actionLabel: 'Choose Plan',
  planType: 'API_BRONZE',
  category: 'api',
}, {
  id: 6,
  title: 'Silver',
  features: silverFeatures,
  actionLabel: 'Choose Plan',
  planType: 'API_SILVER',
  category: 'api',
}, {
  id: 7,
  title: 'Gold',
  features: goldFeatures,
  actionLabel: 'Choose Plan',
  planType: 'API_GOLD',
  category: 'api',
}, {
  id: 8,
  title: 'Enterprise',
  features: enterpriceFeatures,
  headerText: 'Looking for more?',
  actionLabel: 'Contact Us',
  contactUsAction: true,
  planType: 'API_ENTERPRISE',
  category: 'api',
}].map(s => ({ ...s, interval: 'MONTH' }));
