import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton, Paper } from 'material-ui';
import { animations } from '../../utils';
import LogoImage from '../../assets/images/logo.svg';
import SuccessCheck from '../../components/SuccessCheck';

const imageStyle = {
  width: '9rem',
  height: '9rem',
};

const SuccessBaseScreen = ({
  title, description, buttonLabel, onButtonClick,
}) => (
  <Container>
    <Box zDepth={3}>
      <LogoContainer>
        <Logo src={LogoImage} />
      </LogoContainer>
      <TitleContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleContainer>
      <ImageContainer>
        <SuccessCheck style={imageStyle} />
      </ImageContainer>
      <ButtonsSection>
        <RaisedButton
          label={buttonLabel}
          onClick={onButtonClick}
          buttonStyle={{ width: '12rem' }}
          primary
        />
      </ButtonsSection>
    </Box>
  </Container>
);

SuccessBaseScreen.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

SuccessBaseScreen.defaultProps = {
  title: '',
  description: '',
  buttonLabel: '',
  onButtonClick: () => {},
};

const Box = styled(Paper)`
  width: 1100px;
  height: 700px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  padding: 30px 50px 50px 50px;
`;

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  text-align: center;
  font-family: DIN-Medium;
  margin-top: 40px;
`;

const Title = styled.div`
  font-size: 25px;
`;

const Description = styled.div`
  font-size: 20px;
  margin-top: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 80px 0;
`;

const ButtonsSection = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 230px;
  height: auto;
  margin-bottom: 0.375rem;
  align-self: center;
`;

export default SuccessBaseScreen;
