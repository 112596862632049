import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton, FlatButton } from 'material-ui';
import connect from './connect';
import SendDocumentModal from '../../components/modals/SendDocumentModal';

export class ShareDocument extends Component {
  state = {
    modalOpen: false,
  }

  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });


  handleSubmit = async (values) => {
    const { sendCopy, signatureRequest } = this.props;
    const response = await sendCopy({ ...values, signatureRequest });
    if (!response.e) this.closeModal();
  }

  render = () => {
    const {
      previewUrl, title, onSendAnother, submitSendArchived,
    } = this.props;

    const { modalOpen } = this.state;
    return (
      <Container>
        <DocumentPreview>
          <Preview src={previewUrl} />
          <DocumentName>{title}</DocumentName>
        </DocumentPreview>
        <RightSection>
          <Congratulations>Congratulations!</Congratulations>
          <Description>You’ve successfully sent [{title}]</Description>
          <div style={{ marginBottom: '20px' }}>
            <RaisedButton
              label="SEND A COPY"
              style={{ marginRight: '20px' }}
              onClick={this.openModal}
              primary
            />

            <FlatButton
              label="SEND ANOTHER DOCUMENT"
              onClick={onSendAnother}
              primary
            />
          </div>
        </RightSection>
        <SendDocumentModal
          isOpen={modalOpen}
          handleModal={submitSendArchived}
          handleCancel={this.closeModal}
          handleSubmit={this.handleSubmit}
        />
      </Container>
    );
  }
}

ShareDocument.propTypes = {
  previewUrl: PropTypes.string,
  title: PropTypes.string,
  submitSendArchived: PropTypes.func,
  onSendAnother: PropTypes.func,
  sendCopy: PropTypes.func,
  signatureRequest: PropTypes.object,
};

ShareDocument.defaultProps = {
  previewUrl: '',
  title: '',
  submitSendArchived: () => {},
  onSendAnother: () => {},
  sendCopy: () => {},
  signatureRequest: {},
};

const Container = styled.div`
  display: flex;
`;

const DocumentPreview = styled.div`
  width: 175px;
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid gray;
  height: 240px;
`;

const Preview = styled.img`
  height: 170px;
  width: 132px;
`;

const DocumentName = styled.div`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  width: 100%;
`;

const RightSection = styled.div`
  margin-left: 60px;
`;

const Congratulations = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  margin: 15px 0 40px;
`;

export default connect(ShareDocument);
