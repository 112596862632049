import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(evt) {
    // Put the value of the file input inside a hidden field as a base 64 encoded buffer.
    const file = evt.nativeEvent.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      this.props.onSaved(reader.result);
    };

    reader.readAsArrayBuffer(file);
  }

  render() {
    return (
      <div>
        <input type="file" onChange={(e) => { this.handleOnChange(e); }} disabled={this.props.isDisabled} />
        <Field type="hidden" component="input" name={this.props.fieldName} />
      </div>
    );
  }
}

Upload.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onSaved: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

Upload.defaultProps = {
  isDisabled: false,
};

export default Upload;
