import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import { colors } from '../../utils';
import ArrowDown from '../../assets/images/arrow_down.svg';
import SignOptions from '../../assets/images/SignOptions.png';

const SignToolBar = (props) => {
  const [logo, setLogo] = useState('');
  const [fieldsRequired, setFieldsRequired] = useState(0);
  const [title, setTitle] = useState('');
  const [actions, setActions] = useState([]);

  // Popover states
  const [openActions, setOpenActions] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  useEffect(() => {
    setLogo(props.logo);
    setFieldsRequired(props.fieldsRequired);
    setTitle(props.title);
    setActions(props.actions);
  });

  const handleOpen = (e) => {
    if (!props.disabledActions) {
      setOpenActions(true);
      setAnchorEl(e.currentTarget);
    }
  };

  return (
    <>
      <Container>
        <Section>
          <LogoContainer>
            <Logo src={logo} />
          </LogoContainer>
          <TitleContainer>{title}</TitleContainer>
          <LogoContainer />
        </Section>
        <Section>
          <ActionsContainer>
            <ActionsActivator className="activator" disabled={props.disabledActions} onClick={handleOpen}>
              Document Actions
              <ArrowDownIcon />
            </ActionsActivator>
            <Popover
              open={openActions}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              targetOrigin={{ horizontal: 'left', vertical: 'top' }}
              onRequestClose={() => setOpenActions(false)}
            >
              <Menu>
                {actions.map(action =>
                  (<MenuItem
                    key={action.label}
                    primaryText={action.label}
                    onClick={(...agrs) => {
                      setOpenActions(false);
                      action.action(...agrs);
                    }}
                  />),
                )}
              </Menu>
            </Popover>
          </ActionsContainer>
          <FieldsRequiredContainer>
            Fields Required <span>{fieldsRequired}</span>
          </FieldsRequiredContainer>
        </Section>
      </Container>
      <ContainerMobile>
        <SectionMobile>
          <LogoContainer>
            <Logo src={logo} />
          </LogoContainer>
          <FieldsRequiredContainer>
            <span>{fieldsRequired}</span>
          </FieldsRequiredContainer>
        </SectionMobile>
        <SectionMobile style={{ backgroundColor: '#f2f2f2' }}>
          <ActionsContainer>
            <ActionsActivator className="activator" disabled={props.disabledActions} onClick={handleOpen}>
              <SignOptionsIcon />
            </ActionsActivator>
            <Popover
              open={openActions}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              targetOrigin={{ horizontal: 'left', vertical: 'top' }}
              onRequestClose={() => setOpenActions(false)}
            >
              <Menu>
                {actions.map(action =>
                  (<MenuItem
                    key={action.label}
                    primaryText={action.label}
                    onClick={(...agrs) => {
                      setOpenActions(false);
                      action.action(...agrs);
                    }}
                  />),
                )}
              </Menu>
            </Popover>
          </ActionsContainer>
          <TitleContainer>{title}</TitleContainer>
        </SectionMobile>
      </ContainerMobile>
    </>
  );
};

SignToolBar.propTypes = {
  fieldsRequired: PropTypes.number,
  title: PropTypes.string,
  logo: PropTypes.any,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  })),
  disabledActions: PropTypes.bool.isRequired,
};

SignToolBar.defaultProps = {
  fieldsRequired: 0,
  title: '',
  logo: '',
  actions: [],
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 100;
  border-bottom: 1px solid #EEEEEE; 
  @media (max-width: 769px) {
    display: none;
  } 
`;

const ContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  z-index: 100;
  @media (min-width: 769px) {
    display: none;
  }  
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
`;

const SectionMobile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #ffffff;
  @media (max-width: 768px) {
    height: 3rem;
  } 
`;

const LogoContainer = styled.div`
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Logo = styled.div`
  width: 100%;
  height: 4rem;
  margin-left: 1.5rem;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 768px) {
    margin-left: 0rem;
    height: 3rem;
  } 
`;

const TitleContainer = styled.div`
  flex: 4;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 0.8rem;
  @media (max-width: 768px) {
    margin-left: 0rem;
    margin-top: 0rem;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  } 
`;

const ActionsContainer = styled.div`
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 3rem;
  @media (max-width: 768px) {
    margin-left: 0rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  } 
`;

const ActionsActivator = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  
`;

const ArrowDownIcon = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  margin-left: 1rem;
  background-image: url(${ArrowDown});
  background-repeat: no-repeat;
  background-size: contain;
`;

const SignOptionsIcon = styled.div`
  width: 1.3rem;
  height: 1.3rem;
  align-items: center;
  background-image: url(${SignOptions});
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  } 
`;

const FieldsRequiredContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  margin-right: 2rem;

  & > span {
    border: 1px solid ${colors.blue};
    padding: 0.1rem 1.5rem;
    margin-left: 0.5rem;
  }

  @media (max-width: 768px) {
    margin-left: 0rem;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  } 
`;
export default SignToolBar;
