import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton, FlatButton } from 'material-ui';
import { FileCloudUpload } from 'material-ui/svg-icons';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { colors } from '../../utils';

// DnD Spec
const dropTargetSpec = {
  drop(props, monitor, component) {
    const { files } = monitor.getItem();
    component.loadFiles(files);
  },
};

// Dnd Collector
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class FileUpload extends React.Component {
  static propTypes = {
    onFilesLoaded: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    text: PropTypes.string,
    fileName: PropTypes.string,
  }

  static defaultProps = {
    text: 'Drop file here',
    fileName: '',
  }

  state = {
    file: null,
    preview: null,
  }

  loadFile(file) {
    const reader = new FileReader();

    reader.onload = () => {
      this.props.onFilesLoaded(reader.result, file);
      this.setState({
        file,
        preview: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  clearFiles() {
    this.setState({ file: null, preview: null });
  }

  renderUploadUI() {
    if (this.state.file) return null;
    const acceptedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];

    const acceptedFileTypeString = () => {
      let string = '';
      acceptedFileTypes.forEach((acceptedType, index) => { string = string.concat(acceptedType, index !== acceptedFileTypes.length - 1 ? ',' : ''); });
      return string;
    };

    const { text } = this.props;
    return (
      <Fragment>
        <FileCloudUpload className="mb-1" style={{ width: 48, height: 48 }} color={colors.blue} />
        <DropzonePrimaryLabel>{text}</DropzonePrimaryLabel>
        <DropzoneSecondaryLabel className="mb-2">or</DropzoneSecondaryLabel>
        <RaisedButton primary label="Upload File" onClick={() => this.fileBtnRef.click()} />
        <input
          style={{ display: 'none' }}
          ref={(ref) => { this.fileBtnRef = ref; return true; }}
          type="file"
          accept={acceptedFileTypeString()}
          onChange={e => this.loadFile(e.target.files[0])}
        />
      </Fragment>
    );
  }

  renderConfirmation() {
    if (!this.state.file) return null;
    const { fileName } = this.props;
    return (
      <Fragment>
        {this.state.preview && (
          <SignaturePreviewWrapper>
            <DropzonePrimaryLabel>{`File ${fileName} selected`}</DropzonePrimaryLabel>
          </SignaturePreviewWrapper>
        )}
        <FlatButton
          primary
          label="Clear"
          onClick={() => this.clearFiles()}
        />
      </Fragment>
    );
  }

  render() {
    return this.props.connectDropTarget((
      <span>
        <UploadDropzone isOver={this.props.isOver} className="mb-4">
          {this.renderUploadUI()}
          {this.renderConfirmation()}
        </UploadDropzone>
      </span>
    ));
  }
}


const UploadDropzone = styled.div`
  width: 100%;
  height: 220px;
  border: dashed .25rem ${colors.blue};
  background-color: #ECEFF1;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
`;

const DropzonePrimaryLabel = styled.div`
  font-size: 1.125rem;
  color: black;
`;

const DropzoneSecondaryLabel = styled.div`
  font-size: 1rem;
`;

const SignaturePreviewWrapper = styled.div`
height: 2rem;
`;


export default DropTarget([NativeTypes.FILE], dropTargetSpec, collect)(FileUpload);
