import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox } from 'material-ui';

import { Modal } from '../..';

export const EditColumnsModal = ({
  allColumns, currentColumns, onSave, onCancel,
}) => {
  const [checkedColumns, setCheckedColumns] = useState(currentColumns);

  const handleSave = () => {
    onSave(checkedColumns);
    onCancel();
  };

  const handleCheckAll = () => (e, check) =>
    setCheckedColumns(check ? allColumns : allColumns.slice(0, 1));

  const handleCheck = column => (e, check) => setCheckedColumns(checkedColumns
    .filter(c => c.id !== column.id)
    .concat(check || checkedColumns.length === 1 ? [column] : []));

  const renderCheckbox = column => (
    <div key={column.id} style={{ marginBottom: 20 }}>
      <Checkbox
        label={column.label}
        checked={checkedColumns.some(c => c.id === column.id)}
        onCheck={handleCheck(column)}
      />
    </div>
  );

  return (
    <Modal
      open
      title=""
      primaryLabel="Save"
      onPrimaryClick={handleSave}
      onSecondaryClick={onCancel}
      autoScrollBodyContent
      content={(
        <div style={{ color: 'black' }}>
          <Title>Edit Columns</Title>
          <Subtitle>User Activity Report</Subtitle>
          <div style={{ marginBottom: 20, marginTop: -20 }}>
            <Checkbox
              label="Select All"
              checked={checkedColumns.length === allColumns.length}
              onCheck={handleCheckAll()}
            />
          </div>
          {allColumns.map(renderCheckbox)}
        </div>
      )}
    />
  );
};

const columnsType = {
  id: PropTypes.string,
  label: PropTypes.string,
  field: PropTypes.string,
};

EditColumnsModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  currentColumns: PropTypes.arrayOf(PropTypes.shape(columnsType)),
  allColumns: PropTypes.arrayOf(PropTypes.shape(columnsType)),
};

EditColumnsModal.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  currentColumns: [],
  allColumns: [],
};

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-family: DIN-Medium;
`;

const Subtitle = styled.div`
  margin-bottom: 40px;
  font-family: DIN-Medium;
`;

export default EditColumnsModal;
