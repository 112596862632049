/* Match the event type returned by the BE with past tense verb */

export const actions = {
  create: 'created',
  created: 'created',
  update: 'updated',
  updated: 'updated',
  delete: 'deleted',
  deleted: 'deleted',
  update_roles: 'updated_roles',
  roles_updated: 'updated_roles',
  resend: 'resent',
  resent: 'resent',
  cancel: 'canceled',
  canceled: 'canceled',
  request_signatures: 'signatures_requested',
  signatures_requested: 'signatures_requested',
  send_reminder: 'reminder_sent',
  reminder_sent: 'reminder_sent',
  send: 'sent',
  sent: 'sent',
  sign: 'signed',
  signed: 'signed',
  partially_signed: 'signed',
  set_as_default: 'set_as_default',
};

export const resources = {
  organization: 'organization',
  invite: 'invite',
  document: 'document',
  signature_request: 'signature_request',
  template: 'template',
  archived_document: 'document',
  signed_document: 'document',
  brand: 'brand',
};


export default {
  resources,
  actions,
};
