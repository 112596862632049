import React from 'react';
import { connect } from 'react-redux';
import { RaisedButton } from 'material-ui';
import PropTypes from 'prop-types';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../components/pages';
import TabBar from '../components/TabBar';
import BrandsSection from '../components/organizations/BrandsSection';
import MembersSection from '../components/organizations/MembersSection';
import ApiKeysSection from '../components/organizations/ApiKeysSection/ApiKeysSection';
import EditOrganizationModal from '../components/modals/EditOrganizationModal';
import AddNewOrganizationModal from '../components/modals/AddNewOrganizationModal';
import organizationActions from '../actions/organization';
import organizationScreenActions from '../actions/organizationsScreen';
import brandSectionActions from '../actions/brandsSection';
import subscriptionsActions from '../actions/subscriptions';
import userProfileScreenActions from '../actions/userProfileScreen';
import apiKeysActions from '../actions/apiAndKeys';
import { didOrganizationChanged } from '../utils/userOrganizations';
import organizationService from '../services/organization';
import { isRoleAdmin, isRoleOwner } from '../utils/role';
import Message from '../components/commons/Message';
import UpgradeIcon from '../assets/images/upgradeIcon.png';
import { colors } from '../utils';

class OrganizationsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'members',
      fetchingOrganization: false,
      fetchingBrand: false,
      tabs: [
        { label: 'Members', value: 'members' },
        { label: 'Brands', value: 'brands' },
        {
          label: 'API and Keys',
          value: 'api_and_keys',
          disabled: this.shouldDisableApiAndKeys(),
          onClickIcon: this.shouldDisableApiAndKeys() ? this.showApiModal : undefined,
          icon: this.shouldDisableApiAndKeys() ? UpgradeIcon : undefined,
        },
      ],
    };
  }

  componentDidMount = () => {
    this.props.enableOrganizationChange();
    const { currentOrganization } = this.props.organizations;
    if (currentOrganization && currentOrganization.uuid) {
      this.fetchOrganization(currentOrganization.uuid);
      this.fetchBrand();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { currentOrganization } = this.props.organizations;
    if (
      didOrganizationChanged(prevProps, this.props) &&
      currentOrganization &&
      currentOrganization.uuid
    ) {
      this.fetchOrganization(currentOrganization.uuid);
      this.fetchBrand();
    }
  };

  setFetchingOrganization = (fetchingOrganization) => {
    this.setState({ fetchingOrganization });
  };

  setCurrentOrganization = (currentOrganization) => {
    const { setCurrentOrganization } = this.props;
    setCurrentOrganization(currentOrganization);
  };

  fetchOrganization = async (uuid) => {
    try {
      this.setFetchingOrganization(true);
      const { data: currentOrganization } = await organizationService.get(uuid);
      this.setCurrentOrganization(currentOrganization);
      this.setFetchingOrganization(false);
    } catch (e) {
      this.setFetchingOrganization(false);
    }
  };

  fetchBrand = async () => {
    this.setState({ fetchingBrand: true });
    try {
      const { fetchBrands } = this.props;
      await fetchBrands();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    } finally {
      this.setState({ fetchingBrand: false });
    }
  };

  isCurrentUserAuthorized = () => {
    const {
      auth: { user },
      organizations: { currentOrganization },
    } = this.props;
    if (currentOrganization) {
      const userInOrganization = currentOrganization.users.find(u => u.email === user.email);
      if (!userInOrganization) {
        return false;
      }
      const { role } = userInOrganization;
      return userInOrganization && (isRoleAdmin(role) || isRoleOwner(role));
    }
    return true;
  };

  switchTab(activeTab) {
    this.setState({ activeTab });
  }

  showEditOrganizationModal = () => {
    this.props.toggleEditOrganization(true);
  };

  showAddOrganizationModal = () => {
    const {
      setUpgradeModal, auth: {
        user:
      { planUsage: { organizationsInUse, organizationsLimit } },
      },
    } = this.props;
    if (Number.isInteger(organizationsLimit) && organizationsInUse + 1 > organizationsLimit) {
      setUpgradeModal(true, true);
    } else {
      this.props.toggleUserProfileOrganizationModal(true);
    }
  };

  shouldDisableApiAndKeys = () => {
    const { auth } = this.props;
    if (auth.user.currentPlan.id === 3) {
      return true;
    }
    return false;
  }

  showApiModal = () => {
    const { setOpenUpdateModal } = this.props;
    setOpenUpdateModal(true, 'api');
  }

  renderTabContent = () => {
    const { activeTab, fetchingOrganization, fetchingBrand } = this.state;

    switch (activeTab) {
      case 'members':
        return (
          <MembersSection
            fetchOrganization={this.fetchOrganization}
            loading={fetchingOrganization}
          />
        );
      case 'brands':
        return <BrandsSection loading={fetchingBrand} />;
      case 'api_and_keys':
        return <ApiKeysSection />;
      default:
        return null;
    }
  };

  renderPageFooterByTab = () => {
    const {
      organizations: { currentOrganization }, deleteOrganization,
      auth: { user: { currentPlan } },
    } = this.props;
    if (!this.isCurrentUserAuthorized() || !currentOrganization) {
      return !currentOrganization && currentPlan.planType !== 'FREE' && (
        <PageFooter
          onSavePress={this.showAddOrganizationModal}
          saveButtonLabel="Add Organization"
        />
      );
    }
    switch (this.state.activeTab) {
      case 'members':
        return this.isCurrentUserAuthorized && (
          <PageFooter
            customActions={[
              () => (
                <RaisedButton
                  className="ml-3"
                  labelStyle={{ color: colors.white, display: 'block' }}
                  label="Edit Organization"
                  onClick={this.showEditOrganizationModal}
                  backgroundColor={colors.darkBlue}
                />
              ),
            ]}
            onDeletePress={!currentOrganization.ownership_transfer_request_exists ?
              () => deleteOrganization(currentOrganization.uuid) : null}
            deleteButtonLabel="Delete Organization"
          />
        );
      case 'brands':
        return (
          <PageFooter
            onSavePress={() => this.props.toggleBrandModal(true)}
            saveButtonLabel="Add Brand"
          />
        );
      case 'api_and_keys':
        return (
          <PageFooter
            onSavePress={() => this.props.toggleApiAndKeysModal(true)}
            saveButtonLabel="Add API Key"
          />
        );
      default:
        return null;
    }
  };

  renderContent = (currentOrganization) => {
    if (!currentOrganization) {
      return (
        <React.Fragment>
          <Message
            style={{ paddingBottom: '1rem' }}
            message="You don't belong to any organization or you haven't selected one. "
          />
          <Message
            style={{ paddingTop: 0 }}
            message="You can create one with the actions displayed below."
          />
        </React.Fragment>
      );
    }
    if (!this.isCurrentUserAuthorized()) {
      return (
        <Message
          message={`Sorry you don't have access to manage the ${currentOrganization.name} details / settings.
            If you believe you have received this message in error, please contact your administrator`}
        />
      );
    }
    return this.renderOrganizationContent();
  };

  renderOrganizationContent = () => (
    <PageContent>
      <TabBar
        defaultTab={this.state.activeTab}
        tabs={this.state.tabs}
        tabChanged={activeTab => this.switchTab(activeTab)}
      />
      {this.renderTabContent()}
    </PageContent>
  );

  render() {
    const { currentOrganization } = this.props.organizations;
    return (
      <PageContainer>
        <PageHeader title="Organization Admin" />
        {!this.isCurrentUserAuthorized() ? (
          <Message
            message={`Sorry you don't have access to manage the ${currentOrganization.name} details / settings.
            If you believe you have received this message in error, please contact your administrator`}
          />
        ) : (
          this.renderContent(currentOrganization)
        )}
        <EditOrganizationModal />
        <AddNewOrganizationModal />
        {this.renderPageFooterByTab()}
      </PageContainer>
    );
  }
}

OrganizationsScreen.propTypes = {
  toggleEditOrganization: PropTypes.func.isRequired,
  toggleBrandModal: PropTypes.func.isRequired,
  toggleUserProfileOrganizationModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  setCurrentOrganization: PropTypes.func.isRequired,
  toggleApiAndKeysModal: PropTypes.func.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  setOpenUpdateModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = () => {
  const {
    setCurrentOrganization,
    enableOrganizationChange,
    deleteOrganization,
  } = organizationActions;
  const { toggleEditOrganization } = organizationScreenActions;
  const { toggleBrandModal, fetchBrands } = brandSectionActions;
  const { toggleUserProfileOrganizationModal } = userProfileScreenActions;
  const { toggleApiAndKeysModal } = apiKeysActions;
  const { setUpgradeModal, setOpenUpdateModal } = subscriptionsActions;
  return {
    setCurrentOrganization,
    toggleEditOrganization,
    toggleBrandModal,
    toggleUserProfileOrganizationModal,
    fetchBrands,
    toggleApiAndKeysModal,
    enableOrganizationChange,
    setUpgradeModal,
    deleteOrganization,
    setOpenUpdateModal,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(OrganizationsScreen);
