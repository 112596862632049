import React from 'react';
import { isEmpty } from 'lodash';
import { connect as reduxConnect } from 'react-redux';
import editorActions from '../../../actions/editor';

const renderWrappedComponent = Component =>
  class extends React.Component {
    validateSpecialFields = () => {
      // eslint-disable-next-line react/prop-types
      const { pageFields } = this.props;
      // eslint-disable-next-line react/prop-types
      const validations = pageFields.map((field) => {
        // eslint-disable-next-line react/prop-types
        if (field.field_type === 'radio' && !field.radio_button_group_id) {
          return false;
        }
        return true;
      });
      return !validations.includes(false);
    }

    render() {
      return (<Component
        {...this.props}
        validateSpecialFields={this.validateSpecialFields}
        // eslint-disable-next-line react/prop-types
        showBack={isEmpty(this.props.pageFields) &&
          // eslint-disable-next-line react/prop-types
          !isEmpty(this.props.sendDocumentScreen.documents)}
      />);
    }
  };

function mapStateToProps({ editor, fields, sendDocumentScreen }) {
  return {
    currentRecipient: editor.currentRecipient,
    recipients: editor.recipients.map(recipient =>
      ({
        name: recipient.email || recipient.role,
        uuid: recipient.uuid,
        value: recipient.uuid,
        color: recipient.color,
      })),
    pageFields: editor.pageFields,
    currentEditorPage: editor.currentEditorPage,
    sendDocumentScreen,
    fields: fields.standardFields.concat(fields.customFields),
  };
}

function mapDispatchToProps() {
  const {
    setCurrentRecipient,
    resetEditorPageFields,
    addEditorPageField,
  } = editorActions;
  return {
    setCurrentRecipient,
    resetEditorPageFields,
    addEditorPageField,
  };
}

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));


export default connect;
