import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Actions from './../actions';
import Modal from './Modal';

const styles = {
  message: {
    fontFamily: 'DIN-Medium',
    fontSize: '24px',
    textAlign: 'center',
    color: 'rgba(0,0,0,0.87)',
    margin: '1rem',
  },
};

const ConfirmDialog = ({
  confirmOpen, buttonLabel, confirmDialogReturn, message,
}) => (
  <Modal
    title=""
    open={confirmOpen}
    content={<div style={styles.message}>{message}</div>}
    primaryLabel={buttonLabel}
    onPrimaryClick={() => confirmDialogReturn(true)}
    secondaryLabel="Cancel"
    onSecondaryClick={() => confirmDialogReturn(false)}
    contentStyle={{ width: '640px' }}
  />
);

ConfirmDialog.propTypes = {
  confirmDialogReturn: PropTypes.func.isRequired,
  message: PropTypes.any.isRequired,
  confirmOpen: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  buttonLabel: 'OK',
};

const mapStateToProps = ({ confirmDialog }) => ({ ...confirmDialog });

export default connect(mapStateToProps, Actions)(ConfirmDialog);
