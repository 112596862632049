import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { CircularProgress, Paper } from 'material-ui';
import graphqlClient from '../../config/graphql';
import organizationActions from '../../actions/organization';
import { FETCH_INVITE_INFO } from './queries';
import { animations } from '../../utils';

const connect = (props) => {
  // eslint-disable-next-line
  const { user: { email }, history, setOrganizationsValue, selectedInvite } = props;
  const [inviteToken, setInviteToken] = useState('');

  const fetchInviteInformation = async () => {
    try {
      // eslint-disable-next-line react/prop-types
      if (selectedInvite.uuid) {
        setOrganizationsValue('openOrganizationInviteModal', true);
      } else {
        const { data: { invite } } = await graphqlClient().query({
          query: FETCH_INVITE_INFO,
          fetchPolicy: 'network-only',
          variables: { token: inviteToken },
        });

        if (email === invite.email && invite.inviteType === 'MEMBER') {
          setOrganizationsValue('organizationInviteToken', inviteToken);
          setOrganizationsValue('openOrganizationInviteModal', true);
        }
      }

      // eslint-disable-next-line react/prop-types
      history.push('/dashboard');
    } catch (e) {
      toast.error(e.message);
    }
  };

  const loadInformation = async () => {
    await fetchInviteInformation();
  };

  useEffect(() => {
    if (!email) history.push(`/login${window.location.search}`);

    const { invite_token } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const token = invite_token || localStorage.getItem('inviteToken') || '';
    setInviteToken(token);
    localStorage.removeItem('inviteToken');
    if (!token) history.push('/dashboard');
  }, []);

  useEffect(() => {
    if (inviteToken || selectedInvite.uuid) loadInformation();
  }, [inviteToken]);

  const LoadingSpinner = () => (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minWidth: 590, minHeight: 240 }}
    >
      <CircularProgress size={60} thickness={7} />
    </div>
  );

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Box zDepth={3}>
        <Content className="d-flex flex-column align-items-center">
          <LoadingSpinner />
        </Content>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;

const Box = styled(Paper)`
  width: 999px;
  height: 650px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 113px 40px;
`;

const Content = styled.div`
  font-family: DIN-Medium;
`;

export default RConnect(({ auth, organizationInvites }) => ({
  user: auth.user,
  selectedInvite: organizationInvites.selectedInvite,
}), {
  fetchOrganizations: organizationActions.fetchOrganizations,
  setOrganizationsValue: organizationActions.setOrganizationsValue,
})(connect);
