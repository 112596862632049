import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from 'material-ui/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { store, persistor } from './config';
import './styles';
import Head from './components/Head';
import Router from './config/router';
import muiTheme from './config/muiTheme';

/* eslint-disable react/prefer-stateless-function */
class App extends Component {
  render = () => (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider muiTheme={muiTheme}>
          <Fragment>
            <ToastContainer
              newestOnTop
              closeOnClick
              pauseOnHover
              hideProgressBar={false}
              autoClose={5000}
            />
            <div id="root">
              <Head />
              <Router />
            </div>
          </Fragment>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default DragDropContext(HTML5Backend)(App);
