import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field, reduxForm } from 'redux-form';
import { TextField, Checkbox, SelectField } from 'redux-form-material-ui';
import { RaisedButton, MenuItem } from 'material-ui';
import { isEmpty } from 'lodash';
import { validateEmails } from '../../../utils';
import connect from './connect';

const validate = (values) => {
  const errors = {};

  if (!values.emails) {
    errors.emails = 'This field is required';
  } else if (!validateEmails(values.emails)) {
    errors.emails = 'You have entered an invalid email address, please check before sending';
  }

  return errors;
};

class ShareDocumentForm extends Component {
  handleSubmit = ({ emails, message }) => {
    this.props.onSubmit(emails, message);
  };

  render = () => {
    const { handleSubmit, brands, useBrand } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field
          component={TextField}
          name="emails"
          floatingLabelText="Email Addresses"
          fullWidth
        />
        <EmailLabel>Separate multiple addresses with a comma.</EmailLabel>
        <Field
          component={TextField}
          name="message"
          multiLine
          rows={4}
          rowsMax={8}
          floatingLabelText="Message"
          fullWidth
        />
        {!isEmpty(brands) && (
        <InlineWrapper>
          <Field style={{ width: 'auto', marginTop: '16px' }} component={Checkbox} name="useBrand" />
          <Field
            component={SelectField}
            name="brandUuid"
            hintText="Use brand"
            disabled={!useBrand}
          >
            {
              brands.map(brand =>
                <MenuItem value={brand.uuid} primaryText={brand.name} />)
            }
          </Field>
        </InlineWrapper>
        )}
        <ButtonContainer>
          <RaisedButton primary label="SHARE" type="submit" />
        </ButtonContainer>
      </Form>
    );
  }
}

ShareDocumentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired,
  useBrand: PropTypes.bool,
};

ShareDocumentForm.defaultProps = {
  useBrand: false,
};

const EmailLabel = styled.div`
  width: 100%;
  color: #c0c0c0;
  font-size: 0.8rem;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
`;


const InlineWrapper = styled.div`
  display: flex;
  margin: 20px 0 40px;
`;

export default connect(reduxForm({
  form: 'shareDocumentForm',
  validate,
})(ShareDocumentForm));
