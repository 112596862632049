import gql from 'graphql-tag';

export const DELETE_ORGANIZATION_MEMBER = gql`
mutation DeleteOrganizationMember(
  $userUuid: ID!,
  $transferToUserUuid: ID,
  ){
  deleteOrganizationMember(
    userUuid: $userUuid,
    transferToUserUuid: $transferToUserUuid,
  ){
    success
  }
}
`;

export default {
  DELETE_ORGANIZATION_MEMBER,
};
