import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

const selectStyle = {
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 0, 0.3)',
  paddingLeft: '10px',
  paddingRight: '10px',
};

const required = value => (value ? undefined : 'Required');

const Select = props => (
  <Field component="select" name={props.fieldName} style={selectStyle} disabled={props.isDisabled} validate={props.isRequired ? [required] : null} >
    <option value="1">Option 1</option>
    <option value="2">Option 2</option>
    <option value="3">Option 3</option>
  </Field>
);

Select.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Select.defaultProps = {
  isRequired: false,
  isDisabled: false,
};

export default Select;
