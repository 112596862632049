import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { isValid } from 'redux-form';
import uploadAndArchiveActions from '../../actions/uploadAndArchive';
import filesManagerActions from '../../actions/filesManager';
import organizationActions from '../../actions/organization';
import { setSignsMessageValue } from '../../actions/signs';
import subscriptionsActions from '../../actions/subscriptions';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render = () => (<Component {...this.props} />)
  };

const mapDispatchToProps = () => {
  const {
    saveArchiveDocument,
    submitSendArchived,
    setSendDocumentModal,
    resetUploadAndArchiveScreen,
    setArchiveDocument,
    sendArchivedDocument,
  } = uploadAndArchiveActions;
  const {
    setAddFilesDialog,
    clearDocuments,
  } = filesManagerActions;
  const {
    disableOrganizationChange,
    enableOrganizationChange,
  } = organizationActions;

  return {
    setAddFilesDialog,
    saveArchiveDocument,
    submitSendArchived,
    setSendDocumentModal,
    resetUploadAndArchiveScreen,
    clearDocuments,
    setArchiveDocument,
    sendArchivedDocument,
    disableOrganizationChange,
    enableOrganizationChange,
    setMessage: setSignsMessageValue,
    setUpgradeModal: subscriptionsActions.setUpgradeModal,
  };
};

const mapStateToProps = state => ({
  uploadAndArchiveScreen: state.uploadAndArchiveScreen,
  formValid: isValid('uploadAndArchive')(state),
});

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));


export default connect;
