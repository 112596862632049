import subscriptions from '../../constants/subscriptionsPlan';

export const parsePlan = userPlan => ({
  ...(subscriptions.find(s => s.planType === (userPlan.planType || 'FREE')) || {}),
  ...userPlan,
});

export const mergeTypeWithInterval = (obj, plan) => ({
  ...obj,
  [plan.planType]: {
    ...(obj[plan.planType] || {}),
    [plan.interval]: plan,
  },
});

export default {
  parsePlan,
  mergeTypeWithInterval,
};
