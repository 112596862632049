import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Paper } from 'material-ui';
import { RecoveryPasswordForm } from '../components/forms';
import { animations } from '../utils';
import Actions from '../actions';

const RecoveryPasswordScreen = ({ recoveryPassword }) => (
  <Container className="d-flex justify-content-center align-items-center">
    <LoginBox zDepth={3} className="p-4">
      <Title>Reset Password</Title>
      <Subtitle>Please enter a new password</Subtitle>
      <RecoveryPasswordForm handleSubmit={recoveryPassword} />
    </LoginBox>
  </Container>
);

RecoveryPasswordScreen.propTypes = {
  recoveryPassword: PropTypes.func.isRequired,
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;

const LoginBox = styled(Paper)`
  width: 640px;
  height: 384px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
`;

const Title = styled.div`
  margin-top: 2.5rem;
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: .8rem;
  margin: 0 !important;
`;

export default connect(null, Actions)(RecoveryPasswordScreen);

