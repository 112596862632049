import Types from '../constants/actionTypes';

const { SET_ACTIVITY_LOG, SET_LOADING } = Types.activityLog;

const INITIAL_STATE = {
  activityLog: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVITY_LOG: {
      const { activityLog } = action;
      return {
        ...state,
        activityLog,
      };
    }

    case SET_LOADING: {
      const { loading } = action;
      return {
        ...state,
        loading,
      };
    }

    default:
      return state;
  }
};

