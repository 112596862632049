import React from 'react';
import { connect as RConnect } from 'react-redux';
import subscriptionsActions from '../../../actions/subscriptions';
import ErrorUpdateModal from './ErrorUpdateCreditCardModal';

const connect = Component => (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    openErrorUpdateCreditCardModal,
    // eslint-disable-next-line react/prop-types
    setOpenErrorCreditCardModal,
  } = props;

  return (
    <Component
      {...props}
      open={openErrorUpdateCreditCardModal}
      onCancel={() => setOpenErrorCreditCardModal(false)}
    />
  );
};

export default RConnect(({ auth, subscriptions }) => ({
  user: auth.user,
  openErrorUpdateCreditCardModal: subscriptions.openErrorUpdateCreditCardModal,
}), {
  setOpenErrorCreditCardModal: subscriptionsActions.setOpenErrorCreditCardModal,
})(connect(ErrorUpdateModal));
