import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  list: [],
  currentOrganization: null,
  onUserProfile: false,
  canChange: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.organizations.GET_ORGANIZATIONS_LIST: {
      const { payload } = action;
      return {
        ...state,
        list: payload,
      };
    }

    case Types.organizations.SET_CURRENT_ORGANIZATION: {
      const { currentOrganization } = action;

      return {
        ...state,
        currentOrganization,
        onUserProfile: !currentOrganization,
      };
    }

    case Types.organization.UPDATE: {
      const { organization } = action;
      const list = state.list.map(v => (v.uuid === organization.uuid ? organization : v));

      return { ...state, list };
    }

    case Types.organization.DELETE: {
      const { organizationUuid } = action;
      const list = state.list.filter(organization => organization.uuid !== organizationUuid);

      return { ...state, list };
    }

    case Types.organization.ADD: {
      const { organization } = action;
      return {
        ...state,
        list: [...state.list, organization],
      };
    }

    case Types.auth.LOGOUT: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    case Types.organization.SET_CAN_CHANGE: {
      const { canChange } = action;
      return {
        ...state,
        canChange,
      };
    }

    case Types.organization.RESET: {
      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};
