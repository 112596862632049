import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, isEmpty, head } from 'lodash';
import { connect } from 'react-redux';
import { TextField, RaisedButton } from 'material-ui';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../components/pages';
import { DocumentThumbnail } from '../components';
import { setSignsMessageValue } from '../actions/signs';
import filesManagerActions from '../actions/filesManager';
import organizationActions from '../actions/organization';
import subscriptionsActions from '../actions/subscriptions';
import documentService from '../services/document';
import FullLoadingIndicator from '../components/commons/FullLoadingIndicator';
import normalizers from '../components/forms/normalizers';

const styles = {
  textField: {
    width: '70%',
  },
};

class FillAndSignScreen extends React.Component {
  state = {
    fileName: '',
    message: '',
    loading: false,
  };

  componentDidMount = () => {
    const {
      enableOrganizationChange,
      clearDocuments,
      history,
      setUpgradeModal,
      auth: {
        user: {
          currentPlan: { apiAccess },
          planUsage: { apiDocumentsInUse, noApiDocumentsInUse, documentsLimit },
        },
      },
    } = this.props;

    const documentsInUse = apiAccess ? apiDocumentsInUse : noApiDocumentsInUse;

    if (Number.isInteger(documentsLimit) && documentsInUse + 1 > documentsLimit) {
      setUpgradeModal(true);
      history.push('/dashboard');
    }
    clearDocuments();
    enableOrganizationChange();
  };

  componentWillReceiveProps = (nextProps) => {
    const {
      history,
      setUpgradeModal,
    } = this.props;


    const currentPlanUsage = this.props.auth.user.planUsage;
    const newPlanUsage = nextProps.auth.user.planUsage;
    const { documentsInUse, documentsLimit } = newPlanUsage;
    if (!isEqual(currentPlanUsage, newPlanUsage) && (documentsInUse + 1 > Number(documentsLimit))) {
      setUpgradeModal(true);
      history.push('/dashboard');
    }
  };

  componentDidUpdate = ({ filesManager }) => {
    if (!isEqual(this.props.filesManager, filesManager) && !isEmpty(filesManager)) {
      const { documents } = this.props.filesManager;
      const fileName = !isEmpty(head(documents)) ? head(documents).file_name : '';

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ fileName });
    }
  };

  componentWillUnmount= () => {
    const { enableOrganizationChange } = this.props;
    enableOrganizationChange();
  }


  setLoading = (loading) => {
    this.setState({
      loading,
    });
  }

  saveButton = async () => {
    // eslint-disable-next-line react/prop-types
    const { filesManager: { documents }, setMessage, history } = this.props;
    const { uuid } = head(documents);
    try {
      this.setLoading(true);
      await documentService.update(uuid, { file_name: this.state.fileName });
      setMessage(this.state.message);
      this.setLoading(false);
      history.push(`/fill-sign/${uuid}`);
    } catch (e) {
      this.setLoading(false);
    }
  };

  customActions = () => {
    const { filesManager: { documents } } = this.props;
    return [
      () => (
        <RaisedButton
          primary
          label="Next"
          title="Next"
          onClick={this.saveButton}
          disabled={!documents.length}
        />
      ),
    ];
  }

  renderDocuments = (documents) => {
    if (!documents.length) return null;

    return documents.map(document => (
      <DocumentThumbnail
        key={document.uuid}
        document={document}
        onRemovePress={this.props.clearDocuments}
      />
    ));
  };

  renderDocumentUploader(documents) {
    if (documents.length) return null;
    return (
      <DocumentThumbnail
        onUploadPress={() => this.props.setAddFilesDialog(true)}
      />
    );
  }

  render() {
    const { documents } = this.props.filesManager;

    return (
      <PageContainer>
        <PageHeader title="Fill & Sign" />
        <PageContent>
          <div className="no-gutters row">
            <div className="col-12 col-lg-8 d-flex flex-wrap">
              {this.renderDocuments(documents)}
              {this.renderDocumentUploader(documents)}
            </div>
          </div>
          <TextField
            required
            style={styles.textField}
            floatingLabelText="Name of Document *"
            value={this.state.fileName}
            onChange={(e) => { this.setState({ fileName: e.target.value }); }}
            onBlur={(e) => {
              this.setState({ fileName: normalizers.fileNormalizer(e.target.value, '.pdf') });
              e.preventDefault();
            }}
          />
          <TextField
            className="mt-2"
            style={styles.textField}
            floatingLabelText="Message"
            value={this.state.message}
            onChange={(e) => { this.setState({ message: e.target.value }); }}
          />
        </PageContent>
        <PageFooter customActions={this.customActions()} />
        <FullLoadingIndicator open={this.state.loading} />
      </PageContainer>
    );
  }
}

FillAndSignScreen.propTypes = {
  setAddFilesDialog: PropTypes.func.isRequired,
  filesManager: PropTypes.object.isRequired,
  clearDocuments: PropTypes.func.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ filesManager, auth, organizations }) =>
  ({ filesManager, auth, organizations });

const mapDispatchToProps = () => {
  const {
    setAddFilesDialog,
    clearDocuments,
  } = filesManagerActions;
  const {
    enableOrganizationChange,
    disableOrganizationChange,
  } = organizationActions;
  const { setUpgradeModal } = subscriptionsActions;

  return {
    setAddFilesDialog,
    clearDocuments,
    enableOrganizationChange,
    disableOrganizationChange,
    setMessage: setSignsMessageValue,
    setUpgradeModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(FillAndSignScreen);
