import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { startCase } from 'lodash';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Form, withFormik } from 'formik';
import { TextField, SelectField, MenuItem } from 'material-ui';
import * as Yup from 'yup';
import Actions from '../../../actions';

const inputTypesOptions = ['checkbox', 'date', 'email', 'number', 'radio', 'text', 'select'];

const CustomFieldFormSchema = Yup.object().shape({
  name: Yup.string().ensure()
    .required('Required'),
});

class CustomFieldForm extends Component {
  setValue = (field, value) => {
    const {
      setFieldValue, setFieldTouched, changeCustomFormFieldValue,
    } = this.props;
    setFieldTouched(field, true, false);
    setFieldValue(field, value);
    changeCustomFormFieldValue(field, value);
  }

  render = () => {
    const { values, errors } = this.props;
    return (
      <StyledForm>
        <Layout>
          <TextField
            fullWidth
            value={values.name}
            onChange={e => this.setValue('name', e.target.value)}
            floatingLabelText="Name*"
            errorText={errors.name}
          />
          <SelectField
            floatingLabelText="Input Type"
            value={values.type}
            onChange={(event, index, value) => this.setValue('type', value)}
          >
            {inputTypesOptions.map(inputType => (
              <MenuItem value={inputType} primaryText={startCase(inputType)} />
            ))}
          </SelectField>
        </Layout>
      </StyledForm>
    );
  }
}

CustomFieldForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  changeCustomFormFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func, //eslint-disable-line
};

CustomFieldForm.defaultProps = {
  onChange: () => {},
};

const StyledForm = styled(Form)`
  width: 100%;
`;

const Layout = styled.div`
  width: 600px;
`;

const selector = formValueSelector('customFieldForm');

const mapStateToProps = state => ({
  name: selector(state, 'name'),
  type: selector(state, 'type'),
  inputTypes: state.fields.inputTypes,
});

export default connect(mapStateToProps, Actions)(withFormik({
  validationSchema: CustomFieldFormSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ name, type }) => ({
    name,
    type,
  }),
})(CustomFieldForm));
