import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import queryString from 'query-string';
import Modal from '../../components/Modal';
import OverviewSection from '../../components/analytics/OverviewSection';
import ReportListSection from '../../components/analytics/ReportListSection';
import ScheduleReportForm, { FormSchema } from '../../components/forms/ScheduleReportForm';
import history from '../../config/history';
import { PageContainer, PageHeader, PageContent } from '../../components/pages';
import { TabBar } from '../../components';

const reports = [{
  id: 1,
  title: 'Envelope Detail Report',
  subtitle: 'Information about envelope and recipients',
  viewAction: () => history.push('/analytics/envelope-recipient-report'),
  type: 'ENVELOPES_DETAIL_REPORT',
}, {
  id: 2,
  title: 'User Activity Report',
  subtitle: 'Totals users and their envelope count',
  viewAction: () => history.push('/analytics/user-activity-report'),
  type: 'USER_ACTIVITY_REPORT',
}];

export const AnalyticsScreen = ({
  auth: { user },
  onSchedule,
  location,
}) => {
  const [currentTab, setCurrentTab] = useState('Overview');
  const [currentReportType, setCurrentReportType] = useState(null);

  useEffect(() => {
    const { tab } = queryString.parse(location.search);
    if (tab) {
      setCurrentTab(tab);
    }
  });

  const tabs = [{
    label: 'Overview',
    value: 'Overview',
    onClick: () => history.push('/analytics?tab=Overview'),
    render: () => <OverviewSection />,
  }, {
    label: 'Reports',
    value: 'Reports',
    onClick: () => history.push('/analytics?tab=Reports'),
    render: () => (
      <ReportListSection
        items={reports}
        onClickSchedule={s => setCurrentReportType(s.type)}
      />
    ),
  }];
  const tab = tabs.find(t => t.label === currentTab) || tabs[0];

  const renderFormContent = form => (
    <ScheduleReportForm
      {...form}
      email={user.email}
      onChange={(value, path) => {
        form.setFieldTouched(path, !!value);
        form.setFieldValue(path, value);
      }}
    />
  );

  const renderModal = form => (
    <StyledModal
      title="Schedule Report"
      primaryLabel="Schedule"
      open={!!currentReportType}
      content={renderFormContent(form)}
      primaryDisabled={!form.isValid}
      onPrimaryClick={() => {
        onSchedule({ ...form.values, reportType: currentReportType });
        setCurrentReportType(null);
      }}
      onSecondaryClick={() => setCurrentReportType(null)}
    />
  );

  return (
    <PageContainer>
      <PageHeader title="Analytics" />
      <PageContent>
        <TabBar
          tabs={tabs}
          defaultTab={currentTab}
          tabChanged={setCurrentTab}
        />
        {tab.render()}
        <Formik
          key={new Date().getTime()}
          validationSchema={FormSchema}
          render={renderModal}
        />
      </PageContent>
    </PageContainer>
  );
};

AnalyticsScreen.propTypes = {
  auth: PropTypes.object.isRequired,
  onSchedule: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const StyledModal = styled(Modal)`
  & > div > div {
    width: 640px !important;
    & > div {
      min-height: 500px;
      height: 625px;
      display: flex;
      flex-direction: column;
      & > h3 {
        margin-top: 30px !important;
      }
      & > :nth-child(2n) {
        flex: 1 1 0;
      }
    }
  }
`;

export default AnalyticsScreen;
