import React, { useState } from 'react';
import { connect as reduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import UpdateCreditCardForm from './ErrorUpdateCreditCardForm';
import graphqlClient from '../../../config/graphql';
import { UPDATE_CARD } from './queries';
import authActions from '../../../actions/auth';
import userProfileActions from '../../../actions/userProfileScreen';

export const connect = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const { setCurrentUser } = props;
  const [loading, setLoading] = useState(false);

  const updateCard = async ({ id: cardToken }) => {
    try {
      setLoading(true);
      await graphqlClient().mutate({
        mutation: UPDATE_CARD,
        variables: { cardToken },
      });
      const currentUser = await authActions.fetchCurrentUser();
      setCurrentUser(currentUser);
      toast.success('Credit card updated successfully');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
      throw (e);
    }
  };

  return <Component {...props} onUpdate={updateCard} loading={loading} />;
};

export default reduxConnect(
  ({ auth: { user } }) => ({ currentUser: user }),
  { setCurrentUser: userProfileActions.setCurrentUser },
)(connect(UpdateCreditCardForm));
