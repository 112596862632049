import { submit } from 'redux-form';
import Types from '../constants/actionTypes';

export const toggleScheduleModal = displayModal => ({
  type: Types.analyticsScreen.TOGGLE_SCHEDULE_MODAL,
  displayModal,
});

export const toggleSendReportModal = display => ({
  type: Types.analyticsScreen.TOGGLE_SEND_REPORT_MODAL,
  display,
});

export const submitReport = () => (dispatch) => {
  dispatch(submit('sendReportForm'));
};

export const sendReport = () => (dispatch) => {
  dispatch({
    type: Types.analyticsScreen.SENDING_REPORT,
    sending: true,
  });
};

export const setStopReportScheduledSuccessModal = open => (dispatch) => {
  dispatch({
    type: Types.analyticsScreen.SET_STOP_REPORT_SUCCESSFULLY_MODAL,
    open,
  });
};

export const setReportVariables = variables => (dispatch) => {
  dispatch({
    type: Types.analyticsScreen.SET_REPORT_VARIABLES,
    variables,
  });
};

export default ({
  setStopReportScheduledSuccessModal,
});
