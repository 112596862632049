import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, CardText, FlatButton } from 'material-ui';

const ReportListSection = ({ items, onClickSchedule }) => (
  <div className="mt-3">
    {items.map(item => (
      <Card key={item.id} className="mb-3">
        <CardContent>
          <ReportHeader>
            <Title>{item.title}</Title>
            <Subtitle>{item.subtitle}</Subtitle>
          </ReportHeader>
          <ReportActions>
            <FlatButton primary label="View" onClick={item.viewAction} />
            <FlatButton primary label="Schedule" onClick={() => onClickSchedule(item)} />
          </ReportActions>
        </CardContent>
      </Card>
    ))}
  </div>
);

ReportListSection.propTypes = {
  items: PropTypes.array.isRequired,
  onClickSchedule: PropTypes.func.isRequired,
};

const CardContent = styled(CardText)`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const ReportHeader = styled.div`
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h4`
  font-size: 1.1em;
  margin-bottom: 3px;
`;

const Subtitle = styled.div`
  font-size: 0.9em;
`;

const ReportActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export default ReportListSection;
