import React, { Component } from 'react';
import styled from 'styled-components';
import { RaisedButton } from 'material-ui';
import PropTypes from 'prop-types';
import connectGraphql from './connect';
import RateChart from '../../components/analytics/OverviewSection/RateChart';
import StatusChart from '../../components/analytics/OverviewSection/StatusChart';
import VelocityChart from '../../components/analytics/OverviewSection/VelocityChart';
import VolumeChart from '../../components/analytics/OverviewSection/VolumeChart';
import { PageContainer, PageHeader } from '../../components/pages';
import AnalyticsOverlayImage from '../../assets/images/AnalyticsOverlay.png';
import { LogModule, TableModule } from '../../components/dashboard';
import { Box } from '../../components';
import { activityLog as activityLogUtil, colors, breakpoints } from '../../utils';
import SignatureReminderSentModal from '../../components/modals/SignatureReminderSentModal';

const buttonStyle = {
  labelStyle: {
    padding: 0,
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
  },
  buttonStyle: {
    height: 40,
    backgroundColor: colors.blue,
  },
  buttonContainerStyle: {
    minWidth: 200,
    height: 'auto',
    marginBottom: '1rem',
  },
  overlayStyle: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const titleStyle = {
  fontFamily: 'DIN-Bold',
  fontWeight: '600',
};

const buttons = [
  { label: 'SEND DOCUMENT', action: '/send-document' },
  { label: 'CREATE TEMPLATE', action: '/documents-manager/templates/new' },
  { label: 'FILL & SIGN', action: '/fill-sign' },
  { label: 'UPLOAD & STORE', action: '/documents-manager/upload-and-archive' },
];

class DashboardScreen extends Component {
  state = {
    openSignatureReminderSentModal: false,
  }

  toggleSignatureReminderSentModal = () => {
    const { openSignatureReminderSentModal } = this.state;
    this.setState({ openSignatureReminderSentModal: !openSignatureReminderSentModal });
  }

  parseWaitingForMeRequests = signatureRequests =>
    signatureRequests.map(signatureRequest => ({
      ...signatureRequest,
      title: signatureRequest.subject ? signatureRequest.subject : 'N/A',
      date: signatureRequest.activityLogs && signatureRequest.activityLogs.nodes.length ?
        signatureRequest.activityLogs.nodes[0].eventDate : 'N/A',
    }));

  parseWaitingForSignatureRequests = signatureRequests =>
    signatureRequests.map(signatureRequest => ({
      ...signatureRequest,
      title: signatureRequest.subject ? signatureRequest.subject : 'N/A',
      date: signatureRequest.activityLogs && signatureRequest.activityLogs.nodes.length ?
        signatureRequest.activityLogs.nodes[0].eventDate : 'N/A',
    }));

  handleSendReminder = (signatureRequest) => {
    const { createReminder } = this.props;
    const recipientUuids = signatureRequest.recipients.nodes
      .filter(r => r.pending).map(r => r.uuid);
    createReminder(signatureRequest.uuid, recipientUuids, this.toggleSignatureReminderSentModal);
  };

  openDocumentManagerTab = (tab) => {
    const { history } = this.props;
    history.push({
      pathname: '/documents-manager',
      search: `?tab=${tab}`,
    });
  };

  renderActionButton = button => (
    <RaisedButton
      key={button.label}
      label={button.label}
      labelStyle={buttonStyle.labelStyle}
      buttonStyle={buttonStyle.buttonStyle}
      style={buttonStyle.buttonContainerStyle}
      overlayStyle={buttonStyle.overlayStyle}
      onClick={() => this.props.history.push(button.action)}
      primary
    />
  );

  render() {
    const {
      activityLogs,
      waitingForMe,
      waitingForSignature,
      fetchMoreWaitingForMe,
      fetchMoreWaitingForSignature,
      fetchMoreActivityLogs,
      loadingWaitingForSignature,
      loadingWaitingForMe,
      loadingActivityLogs,
      initialLoading,
      currentUser,
      currentOrganization,
      noAnalyticsData,
    } = this.props;

    const { openSignatureReminderSentModal } = this.state;
    const currentOrgUser = currentOrganization
      && currentOrganization.users.find(u => u.email === currentUser.email);
    const hideAnalytics = !currentOrgUser || currentOrgUser.role === 'member' || noAnalyticsData;

    return (
      <PageContainer ignoreClasses>
        <PageHeader
          title="Dashboard"
          containerStyle={{ marginBottom: '1.3125rem', marginTop: '2rem' }}
        />

        <ActionsContainer>
          {buttons.map(this.renderActionButton)}
        </ActionsContainer>

        <ModulesContainer>
          <ModuleContainer>
            <TableModule
              title="Waiting for Signature"
              onClickTitle={() => {
                this.openDocumentManagerTab('out_for_signature');
              }}
              elements={this.parseWaitingForSignatureRequests(waitingForSignature)}
              buttonLabel="SEND REMINDER"
              onClickButton={(signatureRequest) => {
                this.handleSendReminder(signatureRequest);
              }}
              emptyMessage="There are no documents waiting for signatures!"
              onScrollEnd={fetchMoreWaitingForSignature}
              loadingMore={loadingWaitingForSignature}
              loading={initialLoading}
            />
          </ModuleContainer>
          <ModuleContainer>
            <TableModule
              title="Waiting for Me"
              onClickTitle={() => {
                this.openDocumentManagerTab('waiting_for_me');
              }}
              elements={this.parseWaitingForMeRequests(waitingForMe)}
              buttonLabel="SIGN"
              onClickButton={(signatureRequest) => {
                this.props.history.push(`/signature?signature_request=${signatureRequest.uuid}`);
              }}
              onScrollEnd={fetchMoreWaitingForMe}
              loadingMore={loadingWaitingForMe}
              loading={initialLoading}
              emptyMessage="There are no documents waiting for you to sign!"
            />
          </ModuleContainer>
          <ModuleContainer>
            <LogModule
              title="Activity Log"
              logs={activityLogUtil.prepareResponse(activityLogs)}
              onScrollEnd={fetchMoreActivityLogs}
              loadingMore={loadingActivityLogs}
              loading={initialLoading}
              emptyMessage="You have no activity yet!"
            />
          </ModuleContainer>
        </ModulesContainer>
        {hideAnalytics ? (
          <Box title="Analytics Overview" boxHeaderStyle={titleStyle}>
            <AnalyticsOverview>
              <div className="background" />
              <div className="text">No Data Available</div>
            </AnalyticsOverview>
          </Box>
        ) :
        (
          <Box title="Analytics Overview" boxHeaderStyle={titleStyle}>
            <ChartRow className="row">
              <StatusChart withHeader={false} />
              <RateChart withHeader={false} />
            </ChartRow>
            <ChartRow className="row">
              <VelocityChart withHeader={false} />
              <VolumeChart withHeader={false} />
            </ChartRow>
          </Box>
        )}
        <SignatureReminderSentModal
          open={openSignatureReminderSentModal}
          onClose={this.toggleSignatureReminderSentModal}
        />
      </PageContainer>
    );
  }
}

const ChartRow = styled.div`
margin: 1rem;
  & .col-6 > div {
    box-shadow: unset !important;
  }
`;

const AnalyticsOverview = styled.div`
  width: 100%;
  & > .background {
    width: 100%;
    height: 35rem;
    background-image: url(${AnalyticsOverlayImage});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(5px);
  }
  & > .text {
    position: relative;
    width: 100%;
    font-size: 1.4rem !important;
    color: #676767;
    font-family: DIN-Medium !important;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 19.5rem;
  }
`;

const ActionsContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 1.625rem;
  display: flex;
  align-items: center;

  & > div {
    margin-right: 36px;
  }

  & > div > button {
    height: 3rem !important;
    background-color: #016d9f !important;
  }

  & > div > button > div > div {
    width: 13.5rem !important;
    height: 3rem !important;
    margin: 0 auto !important;
    & > span {
      line-height: 1rem;
      min-width: 10rem !important;
      font-size: 16px !important;
      font-family: DIN-Medium !important;
      font-weight: 500 !important;
    }
  }
`;

const ModulesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ModuleContainer = styled.div`
  margin-bottom: 2.25rem;
  width: 49%;
  @media screen and (min-width: ${breakpoints.xl.min}px) {
    width: 32%;
  }
`;

DashboardScreen.propTypes = {
  waitingForMe: PropTypes.array.isRequired,
  waitingForSignature: PropTypes.array.isRequired,
  activityLogs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  fetchMoreWaitingForMe: PropTypes.func.isRequired,
  fetchMoreWaitingForSignature: PropTypes.func.isRequired,
  fetchMoreActivityLogs: PropTypes.func.isRequired,
  loadingWaitingForSignature: PropTypes.bool.isRequired,
  loadingWaitingForMe: PropTypes.bool.isRequired,
  loadingActivityLogs: PropTypes.bool.isRequired,
  initialLoading: PropTypes.bool.isRequired,
  createReminder: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  noAnalyticsData: PropTypes.bool.isRequired,
};


export default connectGraphql(DashboardScreen);
