import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { RaisedButton } from 'material-ui';
import { startCase } from 'lodash';

import InviteNewUser from './../InviteNewUser';
import SearchInput from '../../SearchInput';
import SortableTable from '../../SortableTable';
import history from '../../../config/history';

const getRole = ({ token, role, invite_type: type }) => startCase(role || '') || (token
  ? `Pending ${type} ${type === 'ownership' ? 'transference' : 'invitation'}`
  : 'Invitation is lost');

const getFullName = (member) => {
  let name = '';
  if (member.recipient_name) {
    name = member.recipient_name;
  } else if (member.first_name && member.last_name) {
    name = `${member.first_name} ${member.last_name}`;
  } else if (member.full_name) {
    name = member.full_name;
  } else if (member.email) {
    name = member.email;
  }
  if (name === 'Signature only user Signature only user') {
    name = member.email || '';
  }
  return name;
};

const parseMember = member => ({
  id: member.id,
  uuid: member.uuid,
  fullName: getFullName(member),
  role: getRole(member),
  email: member.email,
  isInvitation: !member.role,
});

const matchSearch = term => ({
  first_name, last_name, full_name, email, role,
}) => [
  first_name, last_name, full_name, email, role,
].filter(v => !!v).some(v => v.toLowerCase().includes(term));

const columns = [{
  id: 'fullName', label: 'Name', field: 'fullName', type: 'text',
}, {
  id: 'role', label: 'Role', field: 'role', type: 'text',
}, {
  id: 'email', label: 'Email', field: 'email', type: 'text',
}];

export const MembersSection = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { currentOrganization } = props;

  const handleOpenInviteNewUser = () => {
    const {
      openInviteNewUser,
      setUpgradeModal,
      auth: {
        user: { planUsage: { organizationUsersInUse, organizationUsersLimit } },
      },
    } = props;
    if (Number.isInteger(organizationUsersLimit)
      && organizationUsersInUse + 1 > organizationUsersLimit
    ) {
      setUpgradeModal(true);
    } else {
      openInviteNewUser();
    }
  };

  const renderTable = () => {
    const {
      loading, currentOrganization: { users, invitees },
      resendInvite, cancelInvite,
    } = props;
    const classes = { tableRowHead: 'bg-white' };
    const potentialOwners = invitees ? invitees
      .filter(i => i.invite_type === 'ownership')
      .map(i => i.email) : [];
    const auxInvitees = invitees || [];
    const data = [...auxInvitees, ...users.filter(u => !potentialOwners.includes(u.email))]
      .filter(member => member.role !== 'owner')
      .filter(matchSearch(searchTerm))
      .map(parseMember);
    const options = [{
      label: 'Resend invitation',
      action: (_, { uuid }) => resendInvite(uuid),
      filter: u => u.isInvitation,
    }, {
      label: 'Cancel Invitation',
      action: (_, { uuid }) => cancelInvite(uuid),
      filter: u => u.isInvitation,
    }, {
      label: 'Edit Role',
      action: (_, { uuid }) => history.push(`/organization-member/${uuid}`),
      filter: u => !u.isInvitation,
    }];
    const optionColumn = (
      <div style={{ width: 170, marginLeft: 'auto' }}>
        <RaisedButton
          primary
          fullWidth
          label="Invite New User"
          onClick={handleOpenInviteNewUser}
        />
      </div>
    );
    const sortableTableProps = {
      loading, classes, data, columns, optionColumn, options,
    };

    return <SortableTable {...sortableTableProps} />;
  };


  return (
    <>
      <SearchInput
        style={{ margin: '30px 0px', width: '100%' }}
        searchTermChanged={term => setSearchTerm(term)}
      />
      <div className="mt-3 w-100">
        <Paper square>
          {currentOrganization && renderTable()}
        </Paper>
      </div>
      <InviteNewUser />
    </>
  );
};

MembersSection.propTypes = {
  openInviteNewUser: PropTypes.func.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  cancelInvite: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
  /* eslint-enable */
};

export default MembersSection;
