import React, { useEffect, useState } from 'react';
import { connect as RConnect } from 'react-redux';
import queryString from 'query-string';
import graphqlClient from '../../config/graphql';
import analyticsScreenActions from '../../actions/analyticsScreen';
import { STOP_SCHEDULED_REPORT } from './queries';

export const renderWrappedComponent = Component => (props) => {
  const {
    /* eslint-disable react/prop-types */
    user, history, setStopReportScheduledSuccessModal,
    match: { params: { reportScheduleUuid } }, history: { location },
    /* eslint-enable */
  } = props;
  const [error, setError] = useState(false);

  const stopScheduledReport = async () => {
    try {
      await graphqlClient().mutate({
        mutation: STOP_SCHEDULED_REPORT,
        variables: {
          reportScheduleUuid,
          recipientEmail: user.email,
        },
      });

      setStopReportScheduledSuccessModal(true);

      history.push('/dashboard');
    } catch (e) {
      setError(e.message);
    }
  };

  const loadInformation = async () => {
    await stopScheduledReport();
  };

  useEffect(() => {
    const { email } = queryString.parse(location.search);
    if (!user) history.push(`/login?reportScheduleUuid=${reportScheduleUuid}&reportScheduledEmail=${email}`);
    else if (user && user.email !== email) setError('different account');
    else if (reportScheduleUuid) loadInformation();
  }, [reportScheduleUuid, user]);


  return (<Component
    {...props}
    error={error}
  />);
};

const connect = WrappedComponent =>
  RConnect(({ auth }) => ({ user: auth.user }), {
    setStopReportScheduledSuccessModal: analyticsScreenActions.setStopReportScheduledSuccessModal,
  })(renderWrappedComponent(WrappedComponent));

export default connect;
