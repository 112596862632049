import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animations } from '../../utils';

const PageContainer = ({
  style, children, className, ignoreClasses,
}) => (
  <Container className={ignoreClasses ? '' : `d-flex flex-column ${className}`} style={style}>
    { children }
  </Container>
);

PageContainer.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  ignoreClasses: PropTypes.bool,
  style: PropTypes.object,
};

PageContainer.defaultProps = {
  className: '',
  ignoreClasses: false,
  style: {},
};

const Container = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  margin-top: 0.6rem;
  margin-left: 2rem;
  animation: ${animations.fadeIn} .3s ease-in;
`;

export default PageContainer;

