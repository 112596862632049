import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, withFormik } from 'formik';
import validator from 'validator';
import { TextField, FlatButton, RaisedButton } from 'material-ui';
import * as Yup from 'yup';

const DesignateAnotherUserSchema = Yup.object().shape({
  email: Yup.string().ensure()
    .required('Required'),
  message: Yup.string().ensure()
    .required('Required'),
});

class DesignateAnotherUserForm extends Component {
  setValue = (field, value) => {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  }

  validate = (email) => {
    if ((email && !validator.isEmail(email))) {
      return 'Please enter a valid email address';
    }

    return null;
  }

  render = () => {
    const {
      values, errors, touched, onSend, onCancel, isValid,
    } = this.props;

    return (
      <StyledForm>
        <Header>
          To send this document to another individual for signature,
          please enter their email and message below.
        </Header>
        <TextField
          value={values.email}
          onChange={e => this.setValue('email', e.target.value)}
          errorText={(touched.email && errors.email) || this.validate(values.email)}
          floatingLabelText="Email"
          hintText="Enter email address"
          type="text"
          fullWidth
        />
        <TextField
          value={values.message}
          onChange={e => this.setValue('message', e.target.value)}
          errorText={touched.message && errors.message}
          floatingLabelText="Message"
          type="text"
          fullWidth
        />
        <ActionsContainer>
          <FlatButton primary onClick={onCancel}>
            CANCEL
          </FlatButton>
          <RaisedButton
            primary
            buttonStyle={{ color: 'white' }}
            onClick={() => {
              onSend(values.email, values.message);
              onCancel();
            }}
            disabled={!isValid}
          >
            SEND
          </RaisedButton>
        </ActionsContainer>
      </StyledForm>
    );
  }
}

DesignateAnotherUserForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSend: PropTypes.func,
  onCancel: PropTypes.func,
};

DesignateAnotherUserForm.defaultProps = {
  onSend: () => {},
  onCancel: () => {},
};

const StyledForm = styled(Form)`
  width: 100%;

  & button > div {
    font-weight: 600 !important;
    font-size: 0.9rem !important;
  }
`;

const ActionsContainer = styled.div`
  width: 98%;
  margin-top: 2rem;
  text-align: right;
  & > div {
    margin-left: 1rem;
  }
`;

const Header = styled.div`
  width: 90%;
  color: black;
`;

export default withFormik({
  validationSchema: DesignateAnotherUserSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) props.onSubmit(values);
  },
})(DesignateAnotherUserForm);
