import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog } from 'material-ui';
import OrganizationForm from '../forms/OrganizationForm/OrganizationForm';
import Alert from '../Alert';
import organizationScreenActions from '../../actions/organizationsScreen';
import organizationActions from '../../actions/organization';

class EditOrganizationModal extends Component {
  state = {
    alert: false,
  };

  toggleAlert = () => {
    this.setState({ alert: !this.state.alert });
  };

  handleCancel = () => {
    this.props.toggleEditOrganization(false);
  };

  handleDelete = () => {
    const {
      deleteOrganization,
      setCurrentOrganization,
      organizations: { currentOrganization, list },
    } = this.props;

    deleteOrganization(currentOrganization.uuid);
    setCurrentOrganization(list[0]);
    this.toggleAlert();
  };

  render() {
    const {
      organizationsScreen: { showEditOrganization, savingEdit },
      organizations: { currentOrganization },
    } = this.props;

    return (
      <Fragment>
        <Dialog
          open={showEditOrganization}
          title="Edit Organization"
          autoScrollBodyContent
        >
          <OrganizationForm
            edit
            onCancel={this.handleCancel}
            onDeleteOrganization={this.toggleAlert}
            loading={savingEdit}
          />
        </Dialog>
        <Alert
          open={this.state.alert}
          primaryLabel="Delete"
          handleSubmit={this.handleDelete}
          handleClose={this.toggleAlert}
          content={`Are you sure you want to delete the organization "${
            currentOrganization ? currentOrganization.name : ''
          }"?`}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = () => {
  const {
    toggleEditOrganization,
  } = organizationScreenActions;
  const {
    deleteOrganization,
    setCurrentOrganization,
  } = organizationActions;
  return {
    toggleEditOrganization,
    deleteOrganization,
    setCurrentOrganization,
  };
};

EditOrganizationModal.propTypes = {
  organizations: PropTypes.object.isRequired,
  organizationsScreen: PropTypes.object.isRequired,
  toggleEditOrganization: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  setCurrentOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  organizationsScreen: state.organizationsScreen,
  organizations: state.organizations,
});

export default connect(mapStateToProps, mapDispatchToProps())(EditOrganizationModal);
