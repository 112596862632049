import gql from 'graphql-tag';

export const FETCH_USERS = gql`
  query fetchUsers(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    users(
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      nodes {
        id
        firstName
        lastName
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalPageCount
      totalCount
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers(
    $query: String!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sortColumn: UserSortColumnsEnum
    $sortOrder: SortOrderEnum
  ) {
    searchUsers(
      query: $query
      after: $after
      before: $before
      first: $first
      last: $last
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      nodes {
        uuid
        firstName
        lastName
        status
        lastSignInAt
        subscription {
          plan {
            name
            nickname
            planType
          }
        }
        organizations {
          organization { name }
          role
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalPageCount
      totalCount
    }
  }
`;

export default {
  FETCH_USERS,
  SEARCH_USERS,
};
