import { getStatus, getHistoryByEventName } from '../../../utils/documentParserGQL';

export const parseTemplates = templates =>
  templates.map((template) => {
    const {
      uuid, name, notes, activityLogs, recipients, description,
      previewImage, completeInAnyOrder, brand, defaultMessage, pageFields,
    } = template;

    const documents = [];
    const auxDocuments = [];

    // eslint-disable-next-line array-callback-return
    pageFields.nodes.map((pageField) => {
      const auxPageField = {
        id: pageField.id,
        metadata: pageField.metadata,
        recipient_uuid: pageField.recipient.uuid,
        page_id: pageField.page.id,
        field: {
          id: pageField.field.id,
          input_type: pageField.field.inputType,
        },
        page_field_detail: {
          ...pageField.pageFieldDetail,
          read_only: pageField.pageFieldDetail.readOnly,
          x_coord: pageField.pageFieldDetail.xCoord,
          y_coord: pageField.pageFieldDetail.yCoord,
        },
        page: {
          id: pageField.page.id,
          ...pageField.page.document,
        },
      };

      if (!auxDocuments.includes(pageField.page.document.uuid)) {
        auxDocuments.push(pageField.page.document.uuid);
        const auxDoc = {};
        auxDoc.uuid = pageField.page.document.uuid;
        auxDoc.document_preview_url = pageField.page.document.previewImage.url;
        auxDoc.file_name = pageField.page.document.fileName;
        auxDoc.page_fields = [auxPageField];
        auxDoc.page_ids = [auxPageField.page.id];
        documents.push(auxDoc);
      } else {
        const index = auxDocuments.indexOf(pageField.page.document.uuid);
        documents[index] = {
          ...documents[index],
          page_fields: [...documents[index].page_fields, auxPageField],
          page_ids: [...documents[index].page_ids, auxPageField.page.id],
        };
      }
    });

    const history = activityLogs.nodes.map(log => ({ [log.event]: log.eventDate }));

    return {
      complete_in_any_order: completeInAnyOrder,
      brand,
      default_message: defaultMessage,
      uuid,
      name,
      history,
      description,
      notes: notes || '',
      recipients: recipients.nodes,
      date: getHistoryByEventName(history, 'created') || getHistoryByEventName(history, 'create'),
      type: 'template',
      documents,
      previewUrl: previewImage && previewImage.url,
      status: getStatus(history),
    };
  });

export default parseTemplates;
