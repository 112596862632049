import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect as RConnect } from 'react-redux';
import { capitalize, debounce, get, isEmpty } from 'lodash';

import PlatformUsersList from './PlatformUsersList';
import graphqlClient from '../../config/graphql';
import { ROWS_PER_PAGE } from '../SortableTable/SortableTable';
import { SEARCH_USERS } from './queries';

const defaultPageInfo = { totalCount: 0, page: 0 };

const spanFormat = children => ({
  style: { opacity: '0.54', fontSize: 12 },
  children,
});

const parseNode = n => ({
  name: [
    `${n.firstName} ${n.lastName}`.trim(),
    ...[].concat(get(n, 'subscription.plan.nickname', [])).map(spanFormat),
  ],
  ...n.organizations.reduce((z, { organization: o, role }) => ({
    organization: (z.organization || []).concat(o.name),
    role: (z.role || []).concat(capitalize(role === 'OWNER' ? 'Organization Owner' : role)),
  }), {}),
  status: capitalize(n.status),
  lastLogin: n.lastSignInAt ? [
    moment(n.lastSignInAt).format('dddd, MMMM DD, YYYY'),
    spanFormat(moment(n.lastSignInAt).format('H:mm:ss a')),
  ] : '',
});

const connect = WrappedComponent => (props) => {
  const [users, setUsers] = useState([]);
  const [currentPageInfo, setCurrentPageInfo] = useState(defaultPageInfo);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const domain = get(props, 'currentOrganization.subdomain');

  const searchUsers = async (variables = {}, page) => {
    setLoading(true);
    try {
      const {
        data: { searchUsers: { nodes, pageInfo, totalCount } },
      } = await graphqlClient(domain).query({
        query: SEARCH_USERS,
        fetchPolicy: 'network-only',
        variables,
      });

      setUsers(nodes.map(parseNode));
      setCurrentPageInfo({ ...pageInfo, totalCount, page });
      setLoading(false);
    } catch (e) {
      setUsers([]);
      setCurrentPageInfo(defaultPageInfo);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(users)) return;
    searchUsers({ query, first: ROWS_PER_PAGE }, 0);
  }, []);

  useEffect(() => {
    searchUsers({ query, first: ROWS_PER_PAGE }, 0);
  }, [query]);

  const handleOnChangePage = async (_, nextPage) => {
    const { endCursor, startCursor, page } = currentPageInfo;
    const variables = nextPage > page
      ? { first: ROWS_PER_PAGE, after: endCursor }
      : { last: ROWS_PER_PAGE, before: startCursor };
    searchUsers({ ...variables, query }, nextPage);
  };

  return (
    <WrappedComponent
      {...props}
      {...currentPageInfo}
      data={users}
      loading={loading}
      onChangePage={handleOnChangePage}
      onSearch={debounce(setQuery, 350)}
    />
  );
};

const mapStateToProps = ({ organizations }) => ({
  currentOrganization: organizations.currentOrganization,
});

export default RConnect(mapStateToProps, null)(connect(PlatformUsersList));
