import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import {
  scaleValues,
  scaleValueWithConstant,
  decreasedScaleRatio,
} from '../../../utils/scaling';
import StampPickerModal from '../../modals/StampPickerModal';

const convertPixelToCm = pixels => parseFloat(pixels * 0.026458).toFixed(1);

const Stamp = ({
  field, scaleRatio, imageWidth, onClick, onResizeStart, onResizeStop, recipients,
  // eslint-disable-next-line no-unused-vars
  mode, width, height, onChange, onSaved,
}) => {
  const currentRecipient = recipients && recipients.find(r => r.uuid === field.recipientUuid);
  const renderSignStampComponent = () => {
    const [openModal, setOpenModal] = useState(false);
    return (
      <FieldContainer
        onClick={() => {
          setOpenModal(true);
        }}
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
      >
        {!field.value ? (
          <FieldText scaleRatio={scaleRatio} imageWidth={imageWidth}>
            <p className="name">
              {field.page_field_detail.required &&
                <RequiredMark scaleRatio={scaleRatio} imageWidth={imageWidth} >*</RequiredMark>}
              {field.value ? field.value : field.field.name}
            </p>
            <p className="size">{`W: ${convertPixelToCm(width)}cm`}</p>
            <p className="size">{`H: ${convertPixelToCm(height)}cm`}</p>
          </FieldText>) :
          (
            <Content image={field.value} />
        )}
        <StampPickerModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSaved={(val) => {
            onSaved(val);
            onChange(val);
          }}
          maxSize={5}
        />
      </FieldContainer>
    );
  };
  return mode === 'sign' ? renderSignStampComponent() : (
    <ResizableBox
      width={field.width}
      height={field.height}
      minConstraints={scaleValues([40, 20], scaleRatio)}
      maxConstraints={scaleValues([300, 300], scaleRatio)}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      onClick={onClick}
    >
      <FieldText scaleRatio={scaleRatio} imageWidth={imageWidth}>
        <p className="name">
          <IndicatorContainer recipientColor={currentRecipient && currentRecipient.color}>
            <div className="indicator" />
          </IndicatorContainer>
          {field.required &&
          <RequiredMark scaleRatio={scaleRatio} imageWidth={imageWidth} >*</RequiredMark>}
          {field.value ? field.value : field.name}
        </p>
        <p className="size">{`W: ${convertPixelToCm(field.width)}cm`}</p>
        <p className="size">{`H: ${convertPixelToCm(field.height)}cm`}</p>
      </FieldText>
    </ResizableBox>
  );
};

Stamp.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onResizeStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  recipients: PropTypes.array,
  mode: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onSaved: PropTypes.func,
  onChange: PropTypes.func,
};

Stamp.defaultProps = {
  onClick: () => {},
  onResizeStop: () => {},
  onResizeStart: () => {},
  recipients: [],
  mode: 'edit',
  width: 0,
  height: 0,
  onSaved: () => {},
  onChange: () => {},
};

const Content = styled.div`
  background-image: url(${props => props.image});
  background-position: ${props => (props.isDot ? 'left' : 'top')};
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: ${props => (props.isDot ? '20%' : '100%')};
`;


const FieldContainer = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  & > div > div, > div {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
  }

  & hr {
    display: none !important;
  }

   & div {
     width: 100% !important;
     opacity: 0.9 !important;
     background-color: #B3E1F5 !important;
     border: 1px solid #3190C0 !important;
     display: flex;
     font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px !important;
     padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
     align-items: flex-start;
     ::placeholder {  
       font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
       color: #236BA0;
       font-weight: 500;
    }
   }
`;


const RequiredMark = styled.span`
  && {
    margin: 0 ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(3, scaleRatio, imageWidth)}px;;
    color: red;
    font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(10, scaleRatio, imageWidth)}px;
    line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(14, scaleRatio, imageWidth)}px;
  }
`;

const FieldText = styled.div`
  line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(16, decreasedScaleRatio(scaleRatio, 0.1), imageWidth)}px;
  font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, decreasedScaleRatio(scaleRatio, 0.1), imageWidth)}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #236BA0;
  font-weight: 500;
  border: 1px solid #6BB5D5;
  justify-content: flex-start;
  padding-top: 5px;
  padding-left: 5px;
  height: 100%;
  & .name {
    display: flex;
    margin-bottom: 0.5rem !important;
  }
  & .size {
    margin: 0rem !important;
    white-space: nowrap;
  }
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
  height: 100%;

  & > .indicator {
    border-radius: 50%;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    background-color: ${props => props.recipientColor};
  }

`;

export default Stamp;
