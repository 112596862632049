import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  organizationInviteToken: '',
  organizationTransferToken: '',
  selectedInvite: {},
  openOrganizationInviteModal: false,
  openDeclineOrganizationInviteModal: false,
  openDeclineOrganizationInviteSuccessModal: false,
  openOrganizationInviteSuccessModal: false,
  openOrganizationTransferModal: false,
  openDeclineOrganizationTransferModal: false,
  openDeclineOrganizationTransferSuccessModal: false,
  openOrganizationTransferSuccessModal: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.organization.SET_FIELD: {
      const { value, name } = action;
      return {
        ...state,
        [name]: value,
      };
    }

    default:
      return state;
  }
};
