import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from 'material-ui';
import connect from './connect';

import SectionLoader from '../../components/SectionLoader';

export const PlatformSubscriptionPlans = ({ data, loading }) => {
  const renderRow = row => (
    <Row>
      <div>
        <TextField
          floatingLabelText="Plan Name"
          value={row.name}
          fullWidth
        />
      </div>
      <div>
        <TextField
          floatingLabelText="Plan Type"
          value={row.planType}
          fullWidth
        />
      </div>
      <div>
        <TextField
          floatingLabelText="UUID"
          value={row.uuid}
          fullWidth
        />
      </div>
      <div>
        <TextField
          floatingLabelText="Stripe Plan"
          value={row.stripePlan}
          fullWidth
        />
      </div>
      <div>
        <TextField
          floatingLabelText="Price"
          value={`$${Number(row.amount / 100).toFixed(0)}`}
          fullWidth
        />
      </div>
    </Row>
  );

  return (
    <div className="mt-3 w-100">
      <Paper square>
        {loading
          ? (
            <div stype={{ height: '300px' }}>
              <SectionLoader />
            </div>
          ) : (
            <div className="p-4">
              {data.map(renderRow)}
            </div>
          )}
      </Paper>
    </div>
  );
};

PlatformSubscriptionPlans.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    planType: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  })),
  loading: PropTypes.bool,
};

PlatformSubscriptionPlans.defaultProps = {
  data: [],
  loading: false,
};

const Row = styled.div`
  display: flex;
  & > div:not(:last-child) {
    margin-right: 37px;
    width: 20%;
  }
`;

export default connect(PlatformSubscriptionPlans);
