import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Circle = ({
  src, isDot, onRemove, editable, id,
}) => (
  <Container>
    <Content id={id} image={src} isDot={isDot} />
    {editable && <Close onClick={onRemove}>x</Close>}
  </Container>
);

const Content = styled.div`
  border: 2px solid black;
  border-radius: 50%;
  filter: brightness(0);
  width: 100%;
  padding-top: 3px;
  height: ${props => (props.isDot ? '20%' : '100%')};
`;

const Container = styled.div`
  display: flex;
  border: dotted 1px rgb(84, 188, 234);
  width: 100%;
  height: 100%;
`;

const Close = styled.div`
  text-align: right;
  position: absolute;
  width: 5px;
  height: 5px;
  cursor: pointer;
  position: relative;
  font-size: 0.7rem;
  top: -6px;
  right: 2px;
  font-size: 0.8rem;
`;


Circle.propTypes = {
  src: PropTypes.string.isRequired,
  isDot: PropTypes.bool,
  onRemove: PropTypes.func,
  editable: PropTypes.bool,
  id: PropTypes.string,
};

Circle.defaultProps = {
  isDot: false,
  onRemove: () => {},
  editable: false,
  id: '',
};

export default Circle;
