import React, { useEffect, useState } from 'react';
import { connect as RConnect } from 'react-redux';

import WaitingForMeTableBody from './WaitingForMeTableBody';
import graphqlClient from '../../../config/graphql';
import parseWaitingForMeDocuments from './parser';
import { FETCH_WAITING_FOR_ME } from './queries';
import { ROWS_PER_PAGE } from '../../SortableTable/SortableTable';
import * as DocumentManagerActions from '../../../actions/documentManagerScreen';

const defaultPageInfo = { totalCount: 0, page: 0 };

const connect = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const { searchTerm, currentOrganization } = props;
  const [currentPageInfo, setCurrentPageInfo] = useState(defaultPageInfo);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchDocuments = async (variables = {}, page) => {
    setLoading(true);
    try {
      const {
        data: { waitingForMe: { nodes, pageInfo, totalCount } },
      } = await graphqlClient((currentOrganization || {}).subdomain).query({
        query: FETCH_WAITING_FOR_ME,
        fetchPolicy: 'network-only',
        variables,
      });
      setDocuments(parseWaitingForMeDocuments(nodes));
      setCurrentPageInfo({ ...pageInfo, totalCount, page });
    } catch (e) {
      setDocuments([]);
      setCurrentPageInfo(defaultPageInfo);
      throw (e);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChangePage = async (_, nextPage) => {
    const { endCursor, startCursor, page } = currentPageInfo;
    const variables = nextPage > page
      ? { first: ROWS_PER_PAGE, after: endCursor }
      : { last: ROWS_PER_PAGE, before: startCursor };
    searchDocuments({ ...variables, query: searchTerm }, nextPage);
  };

  useEffect(() => {
    searchDocuments({ query: searchTerm, first: ROWS_PER_PAGE }, 0);
  }, [searchTerm]);

  const compProps = {
    ...props,
    documents,
    loading,
    handleOnChangePage,
    currentPageInfo,
  };

  return <Component {...compProps} />;
};

export default RConnect(({ organizations, documentManagerScreen }) => ({
  currentOrganization: organizations.currentOrganization,
  selectedDocument: documentManagerScreen.selectedDocument,
}), {
  setSelectedDocument: DocumentManagerActions.setSelectedDocument,
})(connect(WaitingForMeTableBody));
