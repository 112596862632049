import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SuccessImage from '../../assets/images/Tick.svg';

const SuccessCheck = ({ style }) => (
  <Container style={style} />
);

SuccessCheck.propTypes = {
  style: PropTypes.object,
};

SuccessCheck.defaultProps = {
  style: {},
};

const Container = styled.div`
  width: 20rem;
  height: 20rem;
  background-image: url(${SuccessImage});
  background-repeat: no-repeat;
  background-size: contain;
`;

export default SuccessCheck;
