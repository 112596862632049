import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress, Dialog } from 'material-ui';
import colors from '../../utils/colors';

const FullLoadingIndicator = ({ open, message }) => (
  <Dialog
    open={open}
    style={{ backgroundColor: 'transparent' }}
    contentStyle={{ backgroundColor: 'transparent' }}
    paperClassName="paperName"
    paperProps={{
          style: { background: 'transparent' },
          zDepth: 0,
        }}
  >
    <LoaderContainer>
      <CircularProgress className="mb-3" />
      <Message>
        {message}
      </Message>
    </LoaderContainer>
  </Dialog>
);


FullLoadingIndicator.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.any,
};

FullLoadingIndicator.defaultProps = {
  message: '',
};

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Message = styled.h3`
  color: ${colors.blue};
`;


export default FullLoadingIndicator;
