import React, { Component } from 'react';
import { connect as ReduxConnect } from 'react-redux';
import { isEqual } from 'lodash';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-toastify';
import { getTodayMinus } from '../../utils/date';
import graphqlClient from '../../config/graphql';
import { FETCH_WAITING_FOR_ME, FETCH_WAITING_FOR_SIGNATURE, FETCH_ACTIVITY_LOGS, CREATE_REMINDER, FETCH_EVELOPES_SUMMARY } from './queries';

const renderWrappedComponent = WrappedComponent =>
  class extends Component {
    state = {
      waitingForMe: [],
      waitingForSignature: [],
      activityLogs: [],
      activityLogsFetched: false,
      waitingForMeFirst: 10,
      waitingForSignatureFirst: 10,
      activityLogFirst: 10,
      loadingWaitingForMe: false,
      loadingWaitingForSignature: false,
      loadingActivityLogs: false,
      initialLoading: false,
      haveAnalyticsData: false,
    }

    componentDidMount = async () => {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ initialLoading: true });
      await this.fetchWaitingForMe();
      await this.fetchWaitingForSignature();
      await this.fetchActivityLogs();
      await this.fetchSummary();
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ initialLoading: false });
    }

    componentDidUpdate = async (nextProps) => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization } = this.props;
      // eslint-disable-next-line react/prop-types
      if (!isEqual(currentOrganization, nextProps.currentOrganization)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ initialLoading: true });
        await this.fetchWaitingForMe();
        await this.fetchWaitingForSignature();
        await this.fetchActivityLogs();
        await this.fetchSummary();
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ initialLoading: false });
      }
    }

    reminderParser = (uuid, recipientUuids) => {
      const parsedData = {
        signatureRequestUuid: uuid,
        message: 'Signature Request Reminder',
        frequency: 'NOW',
        options: {
          recipientUuids,
        },
      };
      return parsedData;
    };

    // eslint-disable-next-line
    createReminder = async (signatureRequestUuid, recipientsUuids, callback) => {
      try {
        // eslint-disable-next-line react/prop-types
        const { currentOrganization } = this.props;
        await graphqlClient(currentOrganization &&
          // eslint-disable-next-line react/prop-types
          currentOrganization.subdomain).mutate({
          mutation: CREATE_REMINDER,
          variables: this.reminderParser(signatureRequestUuid, recipientsUuids),
        });
        callback();
      } catch (e) {
        toast.error(e.message);
        throw (e);
      }
    };

    // eslint-disable-next-line
    fetchSummary = async () => {
      try {
        // eslint-disable-next-line react/prop-types
        const { currentOrganization } = this.props;
        const { data } = await graphqlClient(currentOrganization &&
          // eslint-disable-next-line react/prop-types
          currentOrganization.subdomain).mutate({
          mutation: FETCH_EVELOPES_SUMMARY,
          variables: { startDate: getTodayMinus(1, 'year'), endDate: new Date() },
        });
        this.setState({
          haveAnalyticsData: data.envelopesSummaryReport
          && data.envelopesSummaryReport.totalEnvelopes,
        });
        return data.envelopesSummaryReport;
      } catch (e) {
        this.setState({ haveAnalyticsData: false });
      }
    };

    fetchWaitingForMe = async () => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization } = this.props;
      const { waitingForMeFirst } = this.state;
      this.setState({ loadingWaitingForMe: true });
      try {
        const {
          data: {
            waitingForMe: {
              nodes,
            },
          },
        // eslint-disable-next-line react/prop-types
        } = await graphqlClient(currentOrganization && currentOrganization.subdomain).query({
          query: FETCH_WAITING_FOR_ME,
          fetchPolicy: 'network-only',
          variables: {
            first: waitingForMeFirst,
          },
        });
        this.setState({ waitingForMe: nodes });
        this.setState({ loadingWaitingForMe: false });
        return [];
      } catch (e) {
        this.setState({ loadingWaitingForMe: false });
        return [];
      }
    }

    fetchWaitingForSignature = async () => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization } = this.props;
      const { waitingForSignatureFirst } = this.state;
      this.setState({ loadingWaitingForSignature: true });
      try {
        const {
          data: {
            waitingForSignature: {
              nodes,
            },
          },
        // eslint-disable-next-line react/prop-types
        } = await graphqlClient(currentOrganization && currentOrganization.subdomain).query({
          query: FETCH_WAITING_FOR_SIGNATURE,
          fetchPolicy: 'network-only',
          variables: {
            first: waitingForSignatureFirst,
          },
        });
        this.setState({ waitingForSignature: nodes });
        this.setState({ loadingWaitingForSignature: false });
        return [];
      } catch (e) {
        this.setState({ loadingWaitingForSignature: false });
        return [];
      }
    }

    // eslint-disable-next-line consistent-return
    fetchActivityLogs = async () => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization } = this.props;
      const { activityLogFirst, activityLogsFetched, activityLogs } = this.state;
      if (!activityLogsFetched || activityLogs.length >= activityLogFirst - 5) {
        this.setState({ loadingActivityLogs: true });
        try {
          const {
            data: {
              activityLogs: {
                nodes,
              },
            },
          // eslint-disable-next-line react/prop-types
          } = await graphqlClient(currentOrganization && currentOrganization.subdomain).query({
            query: FETCH_ACTIVITY_LOGS,
            fetchPolicy: 'network-only',
            variables: {
              first: activityLogFirst,
            },
          });
          this.setState({ activityLogs: orderBy(nodes, 'eventDate', 'desc'), activityLogsFetched: true });
          this.setState({ loadingActivityLogs: false });
          return [];
        } catch (e) {
          this.setState({ loadingActivityLogs: false });
          return [];
        }
      }
    }

    fetchMoreWaitingForMe = async () => {
      await this.setState(prevState => ({ waitingForMeFirst: prevState.waitingForMeFirst + 5 }));
      this.fetchWaitingForMe();
    }

    fetchMoreWaitingForSignature = async () => {
      await this.setState(prevState =>
        ({ waitingForSignatureFirst: prevState.waitingForSignatureFirst + 5 }));
      this.fetchWaitingForSignature();
    }

    fetchMoreActivityLogs = async () => {
      await this.setState(prevState => ({ activityLogFirst: prevState.activityLogFirst + 5 }));
      this.fetchActivityLogs();
    }

    render() {
      return (<WrappedComponent
        {...this.props}
        {...this.state}
        fetchMoreWaitingForMe={this.fetchMoreWaitingForMe}
        fetchMoreWaitingForSignature={this.fetchMoreWaitingForSignature}
        fetchMoreActivityLogs={this.fetchMoreActivityLogs}
        createReminder={this.createReminder}
        noAnalyticsData={!this.state.haveAnalyticsData}
      />);
    }
  };


const mapStateToProps = ({ organizations, auth }) => ({
  currentOrganization: organizations.currentOrganization,
  currentUser: auth.user,
});

const connect = WrappedComponent =>
  ReduxConnect(mapStateToProps, null)(renderWrappedComponent(WrappedComponent));

export default connect;
