import gql from 'graphql-tag';

export const FETCH_USER = gql`
  query FetchUser(
    $uuid: ID!
  ){
    user(
      uuid: $uuid
    ){
      uuid
      firstName
      lastName
      email
      phone
      role
      state
      streetAddress
      zipCode
    }
  }
`;

export default {
  FETCH_USER,
};
