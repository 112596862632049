import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Drawer } from 'material-ui';
import { connect } from 'react-redux';

import * as SidenavActions from '../../actions/sidenav';
import CollapseIcon from '../../assets/images/collapse-icon.svg';
import LogoOpen from '../../assets/images/logo.svg';
import LogoClosed from '../../assets/logo-closed.png';
import SidenavSection from './SidenavSection';
import { history } from '../../config';
import { spacing } from '../../utils';

class Sidenav extends Component {
  state = {
    open: true,
  };

  componentWillMount = () => {
    const { SetActiveByLink } = this.props;
    const { pathname } = history.location;
    SetActiveByLink(pathname);
    history.listen((location) => {
      SetActiveByLink(location.pathname);
    });
  };

  componentDidMount() {
    const { open } = this.props;
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ open });
  }

  componentWillReceiveProps = (nextProps) => {
    const { open } = this.state;
    if (open !== nextProps.open) {
      this.setState({ open: nextProps.open });
    }
  };

  toggleCollapse = () => {
    const { open } = this.state;
    const { onCollapse } = this.props;
    onCollapse(open);
    this.setState({ open: !open });
  };

  render = () => {
    const { sections } = this.props;
    const { open } = this.state;

    return (
      <Drawer
        containerStyle={{
          width: open ? spacing.sidenavWidth : spacing.sidenavWidthCollapsed,
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: '#fafafa',
          boxShadow: 'none',
          zIndex: 1500,
          borderRight: '1px solid #BDBDBD',
        }}
      >
        <Logo to="/">
          <LogoImg
            src={open ? LogoOpen : LogoClosed}
            collapsed={!open}
            tabIndex={-1}
            alt="app.sign.identitymark.ai"
          />
        </Logo>
        {sections.map(section => (
          <SidenavSection key={section.id} section={section} collapsed={!open} />
        ))}
        <CollapseContainer onClick={this.toggleCollapse} collapsed={!open} />
      </Drawer>
    );
  };
}

Sidenav.propTypes = {
  SetActiveByLink: PropTypes.func.isRequired,
  sections: PropTypes.array,
  open: PropTypes.bool,
  onCollapse: PropTypes.func,
};

Sidenav.defaultProps = {
  sections: [],
  open: true,
  onCollapse: () => {},
};

const Logo = styled(Link)`
  width: 100%;
  display: block;
  text-align: center;
`;

const LogoImg = styled.img`
  padding: ${props => (props.collapsed ? '1.5rem 0rem 1rem' : '0')};
  width: 70%;
  margin: 5px auto 0;
`;

const CollapseContainer = styled.div`
  position: absolute;
  bottom: 2rem;
  background-image: url(${CollapseIcon});
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  left: ${props =>
    (!props.collapsed ? spacing.sidenavWidth - 50 : spacing.sidenavWidthCollapsed / 3.5)}px;
  transform: ${props => props.collapsed && 'rotate(180deg)'};
  cursor: pointer;
`;

const mapStateToProps = state => ({
  sections: state.sidenav.sections,
  user: state.auth.user,
  currentOrganization: state.organizations.currentOrganization,
});
const mapDispatchToProps = {
  SetActiveByLink: SidenavActions.SetActiveByLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
