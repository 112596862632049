import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlatButton, RaisedButton } from 'material-ui';
import { Modal } from '../..';

// eslint-disable-next-line react/prop-types
const content = ({ onClickNext, onClickCancel }) => (
  <>
    <Content>
      Once you transfer this organization, you will no longer be able to edit the contents,
      manage members, delete or modify the account in any way. These permissions
      will be given to the new organization owner unless they decline the invitation.
    </Content>
    <ActionsContainer>
      <FlatButton primary onClick={onClickCancel}>
        Cancel
      </FlatButton>
      <RaisedButton
        primary
        buttonStyle={{ color: 'white' }}
        onClick={onClickNext}
      >
        Next
      </RaisedButton>
    </ActionsContainer>
  </>
);

const InfoTransferOrganizationModal = props => (
  <StyledModal
    open={props.isOpen}
    title="Transfer Organization Ownership"
    content={content(props)}
    actions
  />
);

InfoTransferOrganizationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

const StyledModal = styled(Modal)`
  & > div > div > div {
    padding-top: 1.5rem;
    & > div {
      font-family: DIN-Medium;

    }
  }
  & h3 {
    font-family: DIN-Medium;
  }
  `;

const ActionsContainer = styled.div`
  width: 98%;
  margin-top: 3rem;
  text-align: right;
  & > div {
    margin-left: 1rem;
  }
`;

const Content = styled.div`
  width: 98%;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export default InfoTransferOrganizationModal;
