import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import AttachFileIcon from 'material-ui/svg-icons/editor/attach-file';
import {
  scaleValues,
  scaleValue,
  scaleValueWithConstant,
} from '../../../utils/scaling';
import FilePickerModal from '../../modals/FilePickerModal';

const AttachFile = ({
  field, scaleRatio, imageWidth, onClick, onResizeStart, onResizeStop, recipients,
  // eslint-disable-next-line no-unused-vars
  mode, width, height, onChange, onSaved,
}) => {
  const currentRecipient = recipients && recipients.find(r => r.uuid === field.recipientUuid);
  const renderSignAttachmentComponent = () => {
    const [openModal, setOpenModal] = useState(false);
    return (
      <FieldContainer
        onClick={() => {
          setOpenModal(true);
        }}
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
        width={width}
        height={height}
      >
        <AttachFileField withValue={field.value} scaleRatio={scaleRatio} imageWidth={imageWidth}>
          <StyledAttachFileIcon
            withValue={field.value}
            scaleRatio={scaleRatio}
            imageWidth={imageWidth}
          />
          {field.value && field.metadata.filename}
        </AttachFileField>
        <FilePickerModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSaved={(value, metadata) => {
            onSaved(value);
            onChange({ value, metadata });
          }}
          maxSize={5}
        />
      </FieldContainer>
    );
  };
  return mode === 'sign' ? renderSignAttachmentComponent() : (
    <ResizableBox
      width={field.width > scaleValue(50, scaleRatio) ? field.width : scaleValue(50, scaleRatio)}
      height={field.height > scaleValue(72, scaleRatio) ? field.height : scaleValue(72, scaleRatio)}
      minConstraints={scaleValues([50, 72], scaleRatio)}
      maxConstraints={scaleValues([300, 200], scaleRatio)}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      onClick={onClick}
    >
      <AttachFileField scaleRatio={scaleRatio} imageWidth={imageWidth} >
        <IndicatorContainer recipientColor={currentRecipient && currentRecipient.color}>
          <div className="indicator" />
        </IndicatorContainer>
        <StyledAttachFileIcon scaleRatio={scaleRatio} imageWidth={imageWidth} />
      </AttachFileField>

    </ResizableBox>
  );
};

AttachFile.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onResizeStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  recipients: PropTypes.array,
  mode: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onSaved: PropTypes.func,
  onChange: PropTypes.func,
};

AttachFile.defaultProps = {
  onClick: () => {},
  onResizeStop: () => {},
  onResizeStart: () => {},
  recipients: [],
  mode: 'edit',
  width: 0,
  height: 0,
  onSaved: () => {},
  onChange: () => {},
};

const FieldContainer = styled.div`
  cursor: pointer;
  background-color: #B3E1F5 !important;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  opacity: 0.9 !important;
  display: flex;
  font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(9, scaleRatio, imageWidth)}px !important;
  align-items: center;
  padding: 1px !important;
  & > div > div, > div {  
    display: flex;
    font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(9, scaleRatio, imageWidth)}px !important;
    align-items: center;
    padding: 1px !important;
  }
`;

const StyledAttachFileIcon = styled(AttachFileIcon)`
  height: ${({ scaleRatio, imageWidth, withValue }) => scaleValueWithConstant(withValue ? 18 : 26, scaleRatio, imageWidth)}px !important;
  width: ${({ scaleRatio, imageWidth, withValue }) => scaleValueWithConstant(withValue ? 18 : 26, scaleRatio, imageWidth)}px !important;
  fill: #236BA0 !important;
`;

const AttachFileField = styled.div`
  width: 94%;
  height: 94%;
  display: flex;
  margin: 3%;
  border-radius: 5px;
  background-color: ${props => (props.withValue ? 'white' : 'transparent')} !important;
  color: #236BA0;
  word-break: break-word;
  text-align: center;
  flex-direction: column;
  border: 1px solid ${props => (props.withValue ? '#6BB5D5' : 'transparent')};
  align-items: center;
  justify-content: center;
  padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%
  position: absolute;
  top: 8px;
  left: 8px;
  height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;

  & > .indicator {
    border-radius: 50%;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    background-color: ${props => props.recipientColor};
  }

`;

export default AttachFile;
