import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  updatingProfile: false,
  savingIdentity: false,
  loadingProfile: false,
  organizationModalVisible: false,
  savingOrganization: false,
  currentUser: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.userProfileScreen.UPDATING_PROFILE: {
      const { updatingProfile } = action;
      return { ...state, updatingProfile };
    }
    case Types.userProfileScreen.SAVING_IDENTITY: {
      const { saving } = action;
      return {
        ...state,
        savingIdentity: saving,
      };
    }
    case Types.userProfileScreen.LOADING_PROFILE: {
      const { loadingProfile } = action;
      return {
        ...state,
        loadingProfile,
      };
    }
    case Types.userProfileScreen.TOGGLE_ORGANIZATION_MODAL: {
      const organizationModalVisible = action.displayModal;
      return { ...state, organizationModalVisible };
    }
    case Types.organization.SAVING: {
      const { saving } = action;
      return { ...state, savingOrganization: saving };
    }
    case Types.userProfileScreen.SET_CURRENT_USER: {
      const { currentUser } = action;
      return { ...state, currentUser };
    }
    default:
      return state;
  }
};
