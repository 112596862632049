import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmptyListNotification from '../../EmptyListNotification';
import SortableTable from '../../SortableTable';
import { dateFormats } from '../../../utils';

class WaitingForMeTableBody extends Component {
  columns = [{
    id: 'name', label: 'Name', field: 'name', type: 'text',
  }, {
    id: 'subject', label: 'Subject', field: 'subject', type: 'text',
  }, {
    id: 'sender', label: 'Sender', field: 'sender', type: 'text',
  }, {
    id: 'date', label: 'Date Received', field: 'date', type: 'date',
  }];

  parseData = () => (this.props.documents || []).map(document => ({
    name: document.name,
    subject: document.subject,
    sender: document.from,
    date: document.date && dateFormats.fullMonthDate(document.date),
    document,
  }));

  render = () => {
    const {
      selectedDocument, setSelectedDocument, loading,
      handleOnChangePage, currentPageInfo,
    } = this.props;
    const data = this.parseData();

    if (!loading && data.length === 0) {
      return (
        <EmptyListNotification
          message="Nothing to see here! You do not have any documents awaiting your signature."
          link="/dashboard"
          buttonLabel="Dashboard"
        />
      );
    }

    return (
      <SortableTable
        {...currentPageInfo}
        onChangePage={handleOnChangePage}
        data={data}
        isSelectedRow={row => (row.document || {}).uuid === (selectedDocument || {}).uuid}
        onClickRow={row => setSelectedDocument(row.document, 'waitingForMe')}
        columns={this.columns}
        loading={loading}
      />
    );
  }
}

WaitingForMeTableBody.propTypes = {
  selectedDocument: PropTypes.object.isRequired,
  setSelectedDocument: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  handleOnChangePage: PropTypes.func.isRequired,
  currentPageInfo: PropTypes.object.isRequired,
};

WaitingForMeTableBody.defaultProps = {
  loading: false,
};

export default WaitingForMeTableBody;
