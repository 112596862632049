import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../utils';


const PageHeader = ({ title, subtitle }) => (
  <Container>
    <span>
      { title }
    </span>
    {subtitle &&
      <Subtitle>
        { subtitle }
      </Subtitle>
    }
  </Container>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

PageHeader.defaultProps = {
  subtitle: '',
};

const Container = styled.div`
  font-family: DIN-Medium;
  font-size: 34px;
  margin-bottom: 2rem;
`;

const Subtitle = styled.span`
  color: ${colors.defaultImageGray};
  display: block;
`;

export default PageHeader;
