import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DesignateAnotherUserForm from '../../forms/DesignateAnotherUserForm';
import { Modal } from '../..';

const DeclineModal = props => (
  <StyledModal
    open={props.isOpen}
    title="Designate another user"
    content={
      <DesignateAnotherUserForm
        onSend={props.onClickSend}
        onCancel={props.onClickCancel}
      />}
    actions
  />
);

DeclineModal.propTypes = {
  onClickSend: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

const StyledModal = styled(Modal)`
  & > div > div > div {
    padding: 3rem 0rem 0rem;
  }
`;

export default connect(mapStateToProps, null)(DeclineModal);
