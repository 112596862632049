import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SubscriptionCard = ({
  style, title, amount, features, hoverActivated, interval, readOnly,
  selected, actionLabel, onClickAction, headerText, activeFooter,
  onChangeInterval, planType, plansByInterval, hovered,
}) => {
  const [hover, setHover] = useState(hovered || false);
  const [selectedInterval, setSelectedInterval] = useState(interval);
  const active = (hoverActivated && hover) || selected;
  const intervals = Object.keys(plansByInterval || {}).sort();
  const renderHeader = () => (planType === 'FREE'
    ? <span className="price-free">FREE</span>
    : <span className="header">{headerText}</span>);

  const renderInterval = label => (
    <Tab
      key={label}
      selected={selectedInterval === label}
      onClick={() => {
        setSelectedInterval(label);
        onChangeInterval(label);
      }}
    >
      {label}LY
    </Tab>
  );

  const renderPrice = () => (
    <span className="price">
      <span className="money-symbol">$</span>
      {Number(amount / 100).toFixed(0)}
    </span>
  );

  return (
    <Container
      style={style}
      selected={active}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Header selected={active}>{title}</Header>

      <Body>
        <PriceContainer>
          {amount ? renderPrice() : renderHeader()}
        </PriceContainer>

        {!headerText && intervals.length > 1 && (
          readOnly
            ? <Tab selected readOnly={readOnly}>{interval}LY</Tab>
            : <TabContainer>{intervals.map(renderInterval)}</TabContainer>
        )}

        <FeaturesContainer>
          <ScrollableContent>
            {features.map(f => <span key={f}>{f}</span>)}
          </ScrollableContent>
        </FeaturesContainer>
      </Body>

      {onClickAction && (
        <Footer
          onClick={selected ? undefined : () => onClickAction(selectedInterval)}
          isActiveFooter={activeFooter}
          isHover={hoverActivated && hover}
          isSelected={selected}
        >
          {actionLabel}
        </Footer>
      )}
    </Container>
  );
};

SubscriptionCard.propTypes = {
  planType: PropTypes.string.isRequired,
  style: PropTypes.object,
  title: PropTypes.string,
  amount: PropTypes.number,
  headerText: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  interval: PropTypes.string,
  selected: PropTypes.bool,
  actionLabel: PropTypes.string,
  onClickAction: PropTypes.func,
  onChangeInterval: PropTypes.func,
  activeFooter: PropTypes.bool,
  hoverActivated: PropTypes.bool,
  hovered: PropTypes.bool,
  readOnly: PropTypes.bool,
  plansByInterval: PropTypes.object,
};

SubscriptionCard.defaultProps = {
  style: {},
  title: '',
  headerText: '',
  amount: 0,
  features: [],
  hovered: false,
  selected: false,
  interval: 'MONTH',
  actionLabel: 'Choose Plan',
  onClickAction: undefined,
  onChangeInterval: () => {},
  activeFooter: false,
  hoverActivated: false,
  readOnly: false,
  plansByInterval: undefined,
};

const TabContainer = styled.div`
    display: flex;
    border-radius: 2rem;
    width: 80%;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
`;

const Tab = styled.div`
  flex: 1;
  padding: 0rem 1rem;
  transition: all 0.3s ease-in-out;
  height: 1.5rem;
  color: ${props => (props.selected ? '#5198B9' : '#98C5D8')};
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 0.1rem;
  font-size: 0.7rem;
  cursor: ${p => (p.readOnly ? 'auto' : 'pointer;')}
  border: 1px solid ${props => (props.selected ? '#3BA9F4' : '#98C5D8')};
  background-color: ${props => (props.selected ? '#BBE4F6' : 'white')};
`;

const Container = styled.div`
  display:flex;
  flex-direction: column;
  width: 240px;
  transition: all 0.3s ease-in-out;
  border: 1px solid ${props => (props.selected ? '#016D9F' : '#54BCEA')};
  transform: scale(${props => (props.selected ? '1.1' : '1')});
  z-index: ${props => (props.selected ? 100 : 0)};
  position: ${props => props.selected && 'relative'};
  background-color: white;
  height: 25rem;
`;

const Header = styled.div`
  display: flex;
  background-color: rgba(84,188,234,0.46);
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 22px;
  font-family: DIN-Medium;
  padding: 1rem 0rem;
  font-weight: ${props => (props.selected ? '600' : '500')};
`;

const Body = styled.div`
  flex: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const PriceContainer = styled.div`
  font-family: DIN-Medium;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > .header {
    text-transform: uppercase;
    line-height: 1.7rem;
    display: flex;
    width: 80%;
    text-align: center;
    justify-content: center;
    font-size: 24px;
    color: #016D9F;
    font-family: DIN-Medium;
  }

  & > .price-free {
    font-size: 50px;
    color: #016D9F;
    font-family: DIN-Medium;
  }

  & > .price {
    font-size: 70px;
    color: #016D9F;
    font-family: DIN-Medium;

    .money-symbol {
      font-size: 1.5rem;
      position: relative;
      color: #016D9F;
      bottom: 0.9rem;
    }
  }
`;

const FeaturesContainer = styled.div`
  display: flex;
  height: 9rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  letter-spacing: 0.33px;
  color: #525252;
  font-family: DIN-Medium;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 0.5rem;
  overflow-x: none;
`;

const ScrollableContent = styled.div`
  text-align: center;
  overflow: auto;
  width: 100%;

  & > span {
    text-align: center;
    display: block;
  }
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0rem;
  background-color: ${p => (p.isHover || p.isSelected || p.isActiveFooter ? '#016D9F' : '#54BCEA')};
  align-items: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: white;
  font-family: DIN-Medium;
  font-weight: 500;
  cursor: ${p => (p.isSelected ? 'default' : 'pointer')};
`;


export default SubscriptionCard;
