import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Checkbox as CheckboxCore, Tooltip } from '@material-ui/core';
import {
  CircularProgress, Checkbox, FlatButton, RaisedButton,
  SelectField, MenuItem, DatePicker,
} from 'material-ui';
import { partition, sortBy } from 'lodash';

import alreadySignedIcon from '../../../assets/images/alreadySigned.png';
import colors from '../../../utils/colors';
import hintIcon from '../../../assets/images/hint-icon.svg';

const periods = [{
  value: 'DAILY',
  label: 'Day',
}, {
  value: 'WEEKLY',
  label: 'Week',
}, {
  value: 'MONTHLY',
  label: 'Month',
}, {
  value: 'BUSINESS_DAYS',
  label: 'Bussines days',
}];

const customStyles = {
  selectField: {
    width: 100,
    marginLeft: 10,
    marginRight: 10,
  },
  datePicker: {
    width: 100,
    marginLeft: 10,
    alignItems: 'center',
  },
  disabled: {
    style: { cursor: 'default' },
    inputStyle: { pointerEvents: 'none' },
    iconStyle: { opacity: 0.39 },
  },
};

const hasAllFieldsEqual = (field, [head, ...tail]) => tail
  .reduce((z, x) => z && x[field] === head[field], true);

const equalArrays = (a, b) => a.length === b.length
  && a.every(ax => b.includes(ax));

const LoadingSpinner = () => (
  <div
    style={{ minWidth: 250, minHeight: 300 }}
    className="d-flex align-items-center justify-content-center"
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

const SignedRecipients = () => (
  <div className="w-100 h-100 text-center">
    All the recipients have signed
  </div>
);

// eslint-disable-next-line react/prop-types
const RecipientEmail = ({ email, isDisabled, error }) => (
  <Tooltip title={error || email}>
    <span
      className="text-truncate"
      style={{
        ...(isDisabled ? { opacity: 0.39 } : {}),
        ...(error ? { borderBottom: '1px red dashed' } : {}),
        maxWidth: 204,
      }}
    >
      {email}
    </span>
  </Tooltip>
);

const ReminderSection = ({
  saveReminder, stopReminder,
  selectedDocument: {
    recipients: propRecipients,
    reminder: {
      uuid: reminderUuid,
      frequency: propFrequency = '',
      options: {
        recipientUuids: proprecipientUuids = [],
        date: propDate = null,
      } = {},
    } = {},
  },
}) => {
  const [frequency, setFrequency] = useState('');
  const [recipientUuids, setrecipientUuids] = useState([]);
  const [date, setDate] = useState(null);
  const hasSigningOrder = !hasAllFieldsEqual('signingOrder', propRecipients);
  const recipients = hasSigningOrder
    ? sortBy(propRecipients, 'signingOrder')
    : propRecipients;
  const [signedRecipients, unsignedRecipients] = partition(recipients, 'alreadySigned');
  const initialrecipientUuids = proprecipientUuids
    .filter(id => unsignedRecipients.some(r => r.uuid === id));

  useEffect(() => {
    if (!frequency || frequency === 'DATE') return;
    setDate(null);
  }, [frequency]);

  useEffect(() => {
    setFrequency(propFrequency);
    setrecipientUuids(initialrecipientUuids);
    setDate(propDate);
  }, [reminderUuid]);

  const nextRecipient = unsignedRecipients[0] || {};
  const includesNextRecipient = recipientUuids.includes(nextRecipient.uuid);
  const addNextRecipient = r => hasSigningOrder
    && !includesNextRecipient
    && nextRecipient.uuid !== r.uuid;
  const hasAllRequiredFields = recipientUuids.length !== 0
    && frequency
    && (frequency !== 'DATE' || date)
    && (!hasSigningOrder || includesNextRecipient);
  const hasBeenEdited = propFrequency !== frequency
    || !equalArrays(initialrecipientUuids, recipientUuids)
    || (moment(propDate).isValid() && !moment(propDate).isSame(date));
  const isValidReminder = hasAllRequiredFields && (!reminderUuid || hasBeenEdited);

  const renderRecipient = (r) => {
    const isDisabled = signedRecipients.some(s => s.uuid === r.uuid);
    const indicator = [{
      when: () => isDisabled,
      render: () => <Tooltip title="Signed"><SignedIcon /></Tooltip>,
    }, {
      when: () => hasSigningOrder,
      render: () => <SigningOrder>{r.signingOrder}</SigningOrder>,
    }].find(c => c.when()) || { render: () => null };
    const error = hasBeenEdited
      && r.uuid === nextRecipient.uuid
      && hasSigningOrder
      && !includesNextRecipient;
    const label = (
      <div className="d-flex">
        {indicator.render()}
        <RecipientEmail
          email={r.email}
          isDisabled={isDisabled}
          error={error
            ? 'This email is the next one in queue therefore it must be included in the reminder list.'
            : ''}
        />
      </div>
    );

    const compProps = {
      ...(isDisabled
        ? customStyles.disabled
        : { inputStyle: { width: '40px' } }),
      label,
      key: r.uuid,
      checked: isDisabled || (recipientUuids.includes(r.uuid) && r.pending),
      onCheck: (e, check) => setrecipientUuids(check
        ? recipientUuids
          .concat(addNextRecipient(r) ? nextRecipient.uuid : [])
          .concat(r.uuid)
        : recipientUuids.filter(id => r.uuid !== id),
      ),
    };

    return <Checkbox {...compProps} />;
  };

  return (
    <>
      <SubsectionTitle className="d-flex">
        Who do you want to send a reminder to?
        {hasSigningOrder && (
          <Tooltip title="The reminder will be sent only to the email in queue based on the signing order.">
            <HintIcon src={hintIcon} />
          </Tooltip>
        )}
      </SubsectionTitle>
      <SubsectionContainer
        className="d-flex flex-column"
        style={{
          minHeight: 90,
          maxHeight: 145,
          overflowY: 'auto',
          paddingBottom: 4,
        }}
      >
        <SelectAllCheck selectedNone={recipientUuids.length === 0}>
          <CheckboxCore
            className="svg-checkbox"
            indeterminate={recipientUuids.length > 0
              && recipientUuids.length !== unsignedRecipients.length}
            checked={recipientUuids.length === unsignedRecipients.length}
            onChange={(e, check) => setrecipientUuids(check && recipientUuids.length === 0
              ? unsignedRecipients.map(r => r.uuid)
              : [],
            )}
          />
          <span>Select All</span>
        </SelectAllCheck>
        {recipients.map(renderRecipient)}
      </SubsectionContainer>

      <SubsectionTitle>Send Reminder:</SubsectionTitle>
      <SubsectionContainer>
        <ReminderOption>
          <ReminderCheckbox
            checked={frequency === 'NOW'}
            onCheck={(e, check) => setFrequency(check ? 'NOW' : '')}
          />
          <span>Now</span>
        </ReminderOption>

        <ReminderOption>
          <ReminderCheckbox
            checked={periods.some(p => p.value === frequency)}
            onCheck={(e, check) => setFrequency(check ? 'DAILY' : '')}
          />
          <span>Every</span>
          <SelectField
            value={frequency}
            disabled={!periods.some(p => p.value === frequency)}
            onChange={(e, i, freq) => setFrequency(freq)}
            style={customStyles.selectField}
          >
            {periods.map(p => (
              <MenuItem key={p.value} value={p.value} primaryText={p.label} />
            ))}
          </SelectField>
          <span>until completed</span>
        </ReminderOption>

        <ReminderOption>
          <ReminderCheckbox
            style={{ width: 'initial' }}
            checked={frequency === 'DATE'}
            onCheck={(e, check) => setFrequency(check ? 'DATE' : '')}
          />
          <ReminderLabel>
            <span>Specify</span>
            <DatePicker
              container="inline"
              hintText="MM/DD/YYYY"
              value={date ? moment(date).toDate() : null}
              disabled={frequency !== 'DATE'}
              minDate={moment().add(1, 'day').toDate()}
              onChange={(e, d) => setDate(d)}
              textFieldStyle={customStyles.datePicker}
            />
          </ReminderLabel>
        </ReminderOption>
      </SubsectionContainer>

      <div className="d-flex justify-content-between">
        <FlatButton
          primary
          label="stop reminder"
          onClick={() => stopReminder(reminderUuid)}
          disabled={!reminderUuid}
        />
        <RaisedButton
          primary
          label={frequency === 'NOW' ? 'send' : 'save'}
          disabled={!isValidReminder}
          onClick={() => saveReminder({
            reminderUuid,
            frequency,
            options: { recipientUuids, ...(date ? { date } : {}) },
          })}
        />
      </div>
    </>
  );
};

ReminderSection.propTypes = {
  selectedDocument: PropTypes.object.isRequired,
  saveReminder: PropTypes.func.isRequired,
  stopReminder: PropTypes.func.isRequired,
};

export const DocumentRemindSection = (props) => {
  const { loading, selectedDocument: { recipients } } = props;
  const Component = [{
    when: () => loading,
    then: LoadingSpinner,
  }, {
    when: () => recipients.filter(r => r.pending).length === 0,
    then: SignedRecipients,
  }, {
    when: () => true,
    then: () => ReminderSection(props),
  }].find(r => r.when()).then();

  return (
    <div style={{ fontFamily: 'Roboto', margin: '1rem 0.5rem 0 0.5rem' }}>
      {Component}
    </div>
  );
};

DocumentRemindSection.propTypes = {
  loading: PropTypes.bool.isRequired,
  selectedDocument: PropTypes.object.isRequired,
};

const SubsectionContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  font-size: 0.9rem;
`;

const SubsectionTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const ReminderOption = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ReminderLabel = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ReminderCheckbox = styled(Checkbox)`
  width: initial !important;
`;

const SelectAllCheck = styled.div`
  margin-bottom: -3px;
  > span:first-child {
    margin-left: -12px;
    margin-right: 4px;
  }
  .svg-checkbox {
    svg {
      fill: ${p => (p.selectedNone ? 'rgba(0, 0, 0, 0.87)' : 'rgb(3, 169, 244)')};
    }
  }
`;

const SigningOrder = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.blue};
  border-radius: 9999px;

  margin-right: .5rem;
  padding-left: 7px;
  font-weight: 400;
  color: ${colors.blue};
`;

const SignedIcon = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.blue};
  border-radius: 9999px;

  margin-right: .5rem;
  background-color: #ebf9ff;
  background-image: url(${alreadySignedIcon});
  background-position: center;
  background-repeat: no-repeat;
`;

const HintIcon = styled.img`
  cursor: help;
  margin-left: 5px;
`;

export default DocumentRemindSection;
