import gql from 'graphql-tag';

export const CREATE_SIGNATURE_REQUEST = gql`
  mutation CreateSignatureRequestt(
    $subject: String,
    $message: String,
    $password: String,
    $templateUuid: ID,
    $brandUuid: ID,
    $completeInAnyOrder: Boolean,
    $documentUuids: [ID!],
    $recipientsAttributes: [RecipientAttributes!]!,
    $copiedRecipientsAttributes: [CopiedRecipientAttributes!],
    $remindersAttributes: [ReminderAttributes!],
    ){
      createSignatureRequest(
        subject: $subject,
        message: $message,
        password: $password,
        templateUuid: $templateUuid,
        brandUuid: $brandUuid,
        completeInAnyOrder: $completeInAnyOrder,
        documentUuids: $documentUuids,
        recipientsAttributes: $recipientsAttributes,
        copiedRecipientsAttributes: $copiedRecipientsAttributes,
        remindersAttributes: $remindersAttributes,
      ){
        signatureRequest{ 
          completeInAnyOrder
          documents {
            nodes {
              uuid
            }
          }
          previewImage{
            url
          }
          subject
          message
          organization {
            uuid
          }
          recipients {
            nodes {
              alreadySigned
              email
              fullName
              pending
              signAuthToken
              signingOrder
              uuid
            }
          }
          uuid
        }
      }
  }
`;

export default {
  CREATE_SIGNATURE_REQUEST,
};
