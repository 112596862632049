import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress, Paper } from 'material-ui';
import { Elements, StripeProvider } from 'react-stripe-elements';

import LogoImage from '../../assets/images/logo.svg';
import history from '../../config/history';
import stripeApiKey from '../../constants/stripeKey';
import { PaymentForm } from '../../components/forms';
import { animations } from '../../utils';

export const LoadingSpinner = (
  <div
    style={{ minHeight: 650 }}
    className="d-flex align-items-center justify-content-center"
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

export const PaymentScreen = ({
  selectedSubscription, onClickContactUs, subscribeUser, loading,
  secondaryActionLabel, primaryActionLabel,
}) => (
  <StripeProvider apiKey={stripeApiKey}>
    <Container>
      <Box zDepth={3}>
        {loading ? LoadingSpinner : (
          <>
            <Logo src={LogoImage} />
            <Elements>
              <PaymentForm
                history={history}
                selectedSubscription={selectedSubscription}
                onClickContactUs={onClickContactUs}
                onPay={({ id }) => subscribeUser(id, selectedSubscription.uuid)}
                primaryActionLabel={primaryActionLabel}
                secondaryActionLabel={secondaryActionLabel}
              />
            </Elements>
          </>
        )}
      </Box>
    </Container>
  </StripeProvider>
);

PaymentScreen.propTypes = {
  loading: PropTypes.bool,
  selectedSubscription: PropTypes.object,
  subscribeUser: PropTypes.func.isRequired,
  onClickContactUs: PropTypes.func.isRequired,
  primaryActionLabel: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
};

PaymentScreen.defaultProps = {
  loading: false,
  selectedSubscription: {},
  secondaryActionLabel: 'Back',
  primaryActionLabel: 'Pay',
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled(Paper)`
  width: 1000px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  padding: 30px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 40px;
  align-self: center;
`;

export default PaymentScreen;
