import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShareDocumentForm from '../forms/ShareDocumentForm/ShareDocumentForm';

const DocumentShareSection = props => (
  <SectionContainer>
    <ShareDocumentForm onSubmit={props.onShare} />
  </SectionContainer>
);
DocumentShareSection.propTypes = {
  onShare: PropTypes.func.isRequired,
};


const SectionContainer = styled.div`
  margin-top: 1rem;
`;

export default DocumentShareSection;
