import gql from 'graphql-tag';

export const FETCH_SIGNED_DOCUMENTS = gql`
  query signedDocuments(
    $query: String
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    signedDocuments(
      query: $query
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        uuid
        fileName
        notes {
          nodes {
            uuid
            text
            createdAt
            updatedAt
          }
        }
        subject
        user {
          email
        }
        signedPdf {
          url
        }
        recipients {
          nodes {
            uuid
            email
            fullName
            pending
          }
        }
        document {
          previewImage {
            url
          }
        }
        activityLogs {
          nodes {
            event
            eventDate
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalPageCount
      totalCount
    }
  }
`;

export default {
  FETCH_SIGNED_DOCUMENTS,
};
