import Types from '../constants/actionTypes';

const { GET_DOCUMENT, DOWNLOAD_DOCUMENT } = Types.documents;

const INITIAL_STATE = {
  document: '',
  file: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENT: {
      const { payload } = action;
      return {
        ...state,
        document: payload,
      };
    }

    case DOWNLOAD_DOCUMENT: {
      const { payload } = action;
      return {
        ...state,
        file: payload,
      };
    }

    default:
      return state;
  }
};

