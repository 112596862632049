import gql from 'graphql-tag';

export const STOP_SCHEDULED_REPORT = gql`
mutation StopReportSchedule(
  $reportScheduleUuid: ID!
  $recipientEmail: String!
  ) {
    stopReportSchedule(
      reportScheduleUuid: $reportScheduleUuid
      recipientEmail: $recipientEmail
    ) {
      success
    }
  }
`;

export default {
  STOP_SCHEDULED_REPORT,
};
