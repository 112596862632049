import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { RaisedButton, TextField } from 'material-ui';
import connect from './connect';
import CancelIcon from '../../../assets/images/cancel.svg';
import { getEntityType } from './utils';

class DocumentNotesSection extends React.Component {
  state = {
    newNote: '',
  }

  addNewNote = value => this.setState({ newNote: value })

  saveNote = async () => {
    const { newNote } = this.state;
    const { selectedDocument, createNote } = this.props;
    const { type } = selectedDocument;
    if (newNote) {
      await createNote(selectedDocument.uuid, getEntityType(type), newNote);
      toast.success('Your note was successfuly added');
      this.setState({ newNote: '' });
    }
  }

  renderNotes = () => {
    const { deleteNote, notes } = this.props;
    return notes.map(({ uuid, text }) => (
      <NoteWrapper>
        <NoteText>{text}</NoteText>
        <CloseButton
          onClick={() => deleteNote(uuid)}
        >
          <CloseImage src={CancelIcon} />
        </CloseButton>
      </NoteWrapper>
    ));
  }

  render = () => {
    const { newNote } = this.state;
    return (
      <Wrapper>
        <Disclaimer>Store any additional information about this document here</Disclaimer>
        {this.renderNotes()}
        <TextFieldWrapper>
          <TextField
            value={newNote}
            underlineShow={false}
            onChange={e => this.addNewNote(e.target.value)}
            multiLine
          />
        </TextFieldWrapper>
        <ButtonWrapper>
          <RaisedButton
            primary
            disabled={!newNote.length}
            onClick={this.saveNote}
            label="Save Note"
          />
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

DocumentNotesSection.propTypes = {
  createNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  selectedDocument: PropTypes.object.isRequired,
  notes: PropTypes.array,
};

DocumentNotesSection.defaultProps = {
  notes: [],
};

const Wrapper = styled.div``;

const Disclaimer = styled.div`
  font-size: 12px;
  padding-bottom: 20px;
`;

const TextFieldWrapper = styled.div`
  border-bottom: 1px solid #EEEEEE;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const CloseImage = styled.img``;

const NoteWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 10px 0; 
  border-bottom: 1px solid #EEEEEE;
`;

const NoteText = styled.div`
  max-width: 250px;
  word-wrap: break-word;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 0px;
  top: 3px;
  cursor: pointer;
  padding: 5px;
`;

export default connect(DocumentNotesSection);

