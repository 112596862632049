import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from '../..';

class VoidRequestModal extends Component {
  handleDeleteClick = () => {
    const { onDelete, onCancel } = this.props;
    onDelete();
    onCancel();
  }

  renderContent = () => (
    <Wrapper>
      <Title>Delete Brand</Title>
      <Description>
        Are you sure you want to delete this brand?
      </Description>
    </Wrapper>
  );

  render = () => {
    const { onCancel } = this.props;
    return (
      <Modal
        open
        title=""
        content={this.renderContent()}
        onPrimaryClick={this.handleDeleteClick}
        onSecondaryClick={onCancel}
        primaryLabel="Delete"
        contentStyle={{ maxWidth: '600px' }}
      />
    );
  }
}

VoidRequestModal.propTypes = {
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

VoidRequestModal.defaultProps = {
  onDelete: () => {},
  onCancel: () => {},
};

const Wrapper = styled.div`
  color: black;
`;

const Title = styled.div`
  font-size: 20px;
`;

const Description = styled.div`
  margin: 40px 0 70px;
`;

export default VoidRequestModal;
