import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { forgotPassword } from '../../actions/password';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render() {
      // eslint-disable-next-line react/prop-types
      const { history, handleForgotPassword } = this.props;
      const props = {
        forgotPassword: handleForgotPassword,
        // eslint-disable-next-line react/prop-types
        onCancel: () => history.push('/login'),
      };
      return (<Component {...props} />);
    }
  };

const mapDispatchToProps = () => ({
  handleForgotPassword: forgotPassword,
});

const connect = WrappedComponent =>
  reduxConnect(null, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));


export default connect;
