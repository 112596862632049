import React from 'react';
import PropTypes from 'prop-types';

import EmptyListNotification from '../../EmptyListNotification';
import SortableTable from '../../SortableTable';
import documentManagerParser from '../../../utils/documentManagerParser';
import { dateFormats } from '../../../utils';

const ArchivedDocumentsTable = ({
  selectedDocument, setSelectedDocument, loading,
  handleOnChangePage, currentPageInfo, documents,
}) => {
  if (!loading && documents.length === 0) {
    return (
      <EmptyListNotification
        message="Nothing to see here! You do not have any stored documents."
        link="/documents-manager/upload-and-archive"
        buttonLabel="Upload & Store"
      />
    );
  }

  const columns = [{
    id: 'name', label: 'Name', field: 'name', type: 'text',
  }, {
    id: 'creator', label: 'Creator', field: 'sender', type: 'text',
  }, {
    id: 'partyName', label: 'Party Name', field: 'partyName', type: 'text',
  }, {
    id: 'date', label: 'Date Stored', field: 'date', type: 'date',
  }];
  const getFileUrl = uuid => (documents.find(d => d.uuid === uuid) || {}).fileUrl;
  const options = [{
    label: 'View',
    action: uuid => window.open(getFileUrl(uuid), '_blank'),
  }];
  const data = (documents || []).map(document => ({
    uuid: document.uuid,
    name: document.name,
    subject: document.subject,
    sender: document.sender,
    partyName: document.partyName,
    recipients: documentManagerParser.getEmailsFromRecipients(document.recipients),
    date: document.date && dateFormats.fullMonthDate(document.date.split(' ')[0]),
    document,
  }));

  return (
    <SortableTable
      {...currentPageInfo}
      onChangePage={handleOnChangePage}
      data={data}
      isSelectedRow={row => (row.document || {}).uuid === (selectedDocument || {}).uuid}
      onClickRow={row => setSelectedDocument(row.document, 'archived')}
      columns={columns}
      options={options}
      loading={loading}
    />
  );
};

ArchivedDocumentsTable.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocument: PropTypes.object.isRequired,
  setSelectedDocument: PropTypes.func,
  loading: PropTypes.bool,
  handleOnChangePage: PropTypes.func.isRequired,
  currentPageInfo: PropTypes.object.isRequired,
};

ArchivedDocumentsTable.defaultProps = {
  documents: [],
  setSelectedDocument: () => {},
  loading: false,
};

export default ArchivedDocumentsTable;
