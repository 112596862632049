const generateRolePlaybook = (assignedRoles, persistedRoles) => {
  const playbook = [];
  Object.keys(assignedRoles).forEach((fieldId) => {
    if (persistedRoles[fieldId]) {
      // the role is a persisted one, we need to find what changes to make on the field
      if (persistedRoles[fieldId] !== assignedRoles[fieldId]) {
        // the field changed the owner so we need to move it
        playbook.push({
          action: 'destroy',
          target: persistedRoles[fieldId],
          id: fieldId,
        });
        playbook.push({
          action: 'add',
          target: assignedRoles[fieldId],
          id: fieldId,
          moved: true,
        });
      } else {
        // the field is the same, will only update its properties
        playbook.push({
          action: 'add',
          target: assignedRoles[fieldId],
          id: fieldId,
        });
      }
    } else {
      // the role is not persisted, we will just add it to the recipient
      playbook.push({
        action: 'add',
        target: assignedRoles[fieldId],
        id: fieldId,
      });
    }
  });
  return playbook;
};


export const templateParser = {
  parseField: field => ({
    id: field.isNew ? undefined : field.id,
    page_id: field.pageId,
    field_id: field.fieldType,
    // eslint-disable-next-line
    _destroy: field['_destroy'],
    page_field_detail_attributes: {
      width: Math.round(field.width),
      height: Math.round(field.height),
      x_coord: Math.round(field.x),
      y_coord: Math.round(field.y),
      required: field.required,
    },
  }),
  parseDataToTemplateWithFieldsRequest: (
    recipients,
    fields,
    assignedRoles,
    persistedRoles,
    scaleRatio,
  ) => {
    const playbook = generateRolePlaybook(assignedRoles, persistedRoles);

    const fieldIndexes = {};
    fields.forEach((field, index) => { fieldIndexes[field.id] = index; });

    return {
      recipients_attributes: recipients.map(recipient => ({
        uuid: recipient.uuid,
        template_page_fields_attributes: playbook
          .filter(pbAction => pbAction.target === recipient.role)
          .map((action) => {
            switch (action.action) {
              case 'add': {
                const field = templateParser
                  .parseField(fields[fieldIndexes[action.id]], scaleRatio);
                if (action.moved) {
                  field.id = null;
                }
                return field;
              }
              case 'destroy': {
                if (!fields[fieldIndexes[action.id]].isNew) {
                  const field = templateParser
                    .parseField(fields[fieldIndexes[action.id]], scaleRatio);
                  // eslint-disable-next-line
                field['_destroy'] = true;
                  return field;
                }
                return null;
              }
              default:
                return null;
            }
          }),
      })),
    };
  },
  parseDataToDocumentWithFieldsRequest: (
    signatureRequestId,
    recipients,
    fields,
    assignedRoles,
    persistedRoles,
  ) => {
    const playbook = generateRolePlaybook(assignedRoles, persistedRoles);
    const fieldIndexes = {};
    fields.forEach((field, index) => { fieldIndexes[field.id] = index; });
    return {
      fields: fields.map((field) => {
        // eslint-disable-next-line
        const playbookAction = playbook.find(pa => pa.id == field.id);
        const recipientUuid = recipients.find(r => r.email === playbookAction.target).id;
        if (playbookAction.action === 'add') {
          return {
            ...templateParser.parseField({ ...field, forceCreate: true }),
            recipient_uuid: recipientUuid,
            signature_request_uuid: signatureRequestId,
          };
        }
        return {
          ...templateParser.parseField({ ...field, forceCreate: true }),
          recipient_uuid: recipientUuid,
          signature_request_uuid: signatureRequestId,
          _destroy: true,
        };
      }),
    };
  },
  parseTemplateFieldFromResponse: templateField => ({
    width: templateField.page_field_detail.width,
    height: templateField.page_field_detail.height,
    x: templateField.page_field_detail.x_coord,
    y: templateField.page_field_detail.y_coord,
    required: templateField.page_field_detail.required,
    pageId: templateField.page_id,
    fieldType: templateField.field ? templateField.field.id : templateField.field_id,
    id: templateField.id,
  }),

  parseTemplateFieldsFromResponse: (recipients) => {
    let pageFields = [];
    recipients.forEach((recipient) => {
      pageFields = pageFields.concat(recipient.template_page_fields
        .map(templateField => templateParser.parseTemplateFieldFromResponse(templateField)));
    });
    return pageFields;
  },

  parseDocumentFieldsFromResponse: (documents) => {
    const pageFields = [];
    documents.forEach((document) => {
      document.page_fields.forEach((pageField) => {
        pageFields.push({
          ...templateParser.parseTemplateFieldFromResponse(pageField),
          recipient_email: pageField.recipient.email,
        });
      });
    });
    return pageFields;
  },

  /* Parser used to parse the current redux data to the request payload when creating or updating
  * a template without page fields
  * */
  parseDataToRequest: ({
    title,
    description,
    documents,
    roles,
    rolesToWipe,
    isSelectedBrandEnabled,
    selectedBrand,
    completeInOrder,
    currentOrganization,
  }) => ({
    organization_uuid: (currentOrganization || {}).uuid,
    name: title,
    description,
    complete_in_any_order: !completeInOrder,
    default_message: 'Default Message',
    brandUuid: (isSelectedBrandEnabled && selectedBrand) ? selectedBrand : undefined,
    document_uuids: documents.map(v => v.uuid),
    recipients_attributes: [
      ...roles.map((role, index) => ({
        role: role.role,
        signing_order: index + 1,
        uuid: role.uuid,
      })),
      ...rolesToWipe,
    ],
  }),
};

export default templateParser;
