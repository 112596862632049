import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress, Paper } from 'material-ui';
import { Checkbox } from '@material-ui/core';
import { mapValues } from 'lodash/fp';

import SearchInput from '../../SearchInput';
import SortableTable from '../../SortableTable';
import TabBar from '../../TabBar';
import { Modal } from '../..';

const LoadingSpinner = () => (
  <div
    style={{ minWidth: 590, minHeight: 240 }}
    className="d-flex align-items-center justify-content-center"
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

const upsert = (upserter, [key, ...path], obj) => ({
  ...obj,
  ...(key ? {
    [key]: path.length === 0
      ? upserter(obj[key])
      : upsert(upserter, path || [], obj[key]),
  } : {}),
});

const ModalContent = ({
  classes, entities, requiredEntities, tabs,
  currentTab, setCurrentTab,
  itemsByList, items, setItems,
  searchTerm, setSearchTerm,
  stopPageResetting, setStopPageResetting,
}) => {
  const { limit } = entities[currentTab] || { list: [], limit: 0, label: '' };
  const currentItems = itemsByList[currentTab] || [];
  const totalSelected = currentItems.filter(i => i.isSelected).length;

  const handleClick = ({ uuid }) => setItems(upsert(
    isSelected => !isSelected && totalSelected < limit,
    [currentTab, uuid, 'isSelected'],
    items,
  ));
  const handleSelectAllClick = (e, checked) => setItems(upsert(
    mapValues(d => ({ ...d, isSelected: checked && totalSelected === 0 })),
    [currentTab],
    items,
  ));
  const handleTabChange = async (tab) => {
    if (tab === currentTab) return;
    setStopPageResetting(false);
    setCurrentTab(tab);
  };

  const RenderTable = () => {
    const CheckTitle = (
      <Checkbox
        indeterminate={totalSelected > 0 && totalSelected < currentItems.length}
        checked={totalSelected > 0 && currentItems.length === totalSelected}
        onChange={handleSelectAllClick}
        disabled={limit !== 0 && currentItems.length === 0}
      />
    );

    const columns = [{
      id: 'check', field: 'check', component: CheckTitle,
    }, {
      id: 'name', label: 'Name', field: 'name', type: 'text',
    }, {
      id: 'organization', label: 'Organization', field: 'organization', type: 'text',
    }];

    const data = currentItems
      .filter(i => ['name', 'organization'] // column fields
        .some(c => (i[c] || '').toLowerCase().includes(searchTerm.toLowerCase())))
      .map(i => ({
        ...i,
        check: <Checkbox key={i.isSelected} checked={i.isSelected} />,
      }));

    return (
      <SortableTable
        classes={classes}
        data={data}
        columns={columns}
        onClickRow={handleClick}
        rowsPerPage={5}
        stopPageResetting={stopPageResetting}
      />
    );
  };

  return (
    <Wrapper>
      <Title>Downgrade File Selection</Title>
      <Description>
        In order to downgrade your plan, you need to select which documents, templates
        and organizations you wish to keep for your new plan.
      </Description>
      <div style={{ margin: '20px 0 10px' }}>
        <TabBar
          tabs={tabs}
          tabChanged={handleTabChange}
          defaultTab={currentTab}
          fullWidth
        />
      </div>
      <SearchInput
        style={{ margin: '30px 0px', width: '100%' }}
        searchTermChanged={setSearchTerm}
        inputStyle={{ backgroundColor: '#FAFAFA' }}
      />
      <Paper>
        <TableWrapper><RenderTable /></TableWrapper>
      </Paper>
      <LimitIndicator>
        <span style={{ color: totalSelected > limit ? 'red' : 'black' }}>
          {`${totalSelected}/${limit} `}
        </span>
        SELECTED
        {requiredEntities.includes(currentTab) ? ' *' : ''}
      </LimitIndicator>
    </Wrapper>
  );
};

const entityProp = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  limit: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  toRemove: PropTypes.number,
};

ModalContent.propTypes = {
  entities: PropTypes.shape({
    templates: PropTypes.shape(entityProp),
    orgUsers: PropTypes.shape(entityProp),
  }).isRequired,
  itemsByList: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  setItems: PropTypes.func.isRequired,
  requiredEntities: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  stopPageResetting: PropTypes.bool.isRequired,
  setStopPageResetting: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

ModalContent.defaultProps = {
  classes: {},
};

export const EntitiesSelectionModal = (props) => {
  const {
    loading, onCancel, onConfirm, entities,
  } = props;
  const entityKeys = Object.keys(entities).filter(e => entities[e].list.length > 0);
  const requiredEntities = entityKeys.filter(e => entities[e].min);
  const tabs = entityKeys.map(e => ({ value: e, label: entities[e].label }));
  const [items, setItems] = useState({});
  const [currentTab, setCurrentTab] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [stopPageResetting, setStopPageResetting] = useState(true);
  const [isInvalid, setIsInvalid] = useState(true);
  const itemsByList = mapValues(i => Object.values(i), items);
  const handleConfirmation = () => onConfirm(mapValues(list => list.reduce((z, x) => {
    const field = x.isSelected ? 'selected' : 'unSelected';
    return { ...z, [field]: [...(z[field] || []), x] };
  }, {}), itemsByList));

  useEffect(() => {
    setItems(mapValues(({ list }) => list
      .reduce((z, i) => ({ ...z, [i.uuid]: i }), {}), entities));

    setCurrentTab(entityKeys[0]);
  }, [entities]);

  useEffect(() => {
    const itemsSelected = mapValues(e => e.filter(i => i.isSelected), itemsByList);
    const isAboveLimit = e => (itemsSelected[e] || []).length > entities[e].limit;
    const isEmpty = e => (itemsSelected[e] || []).length === 0;
    setIsInvalid(entityKeys.every(isEmpty) || entityKeys.some(isAboveLimit));
  }, [items]);

  useEffect(() => {
    // stops the table from keeping the current pagination when switching tabs
    if (!stopPageResetting) setStopPageResetting(true);
  }, [currentTab]);

  const contentProps = {
    /* eslint-disable object-property-newline */
    ...props, requiredEntities, tabs,
    itemsByList, items, setItems,
    currentTab, setCurrentTab,
    searchTerm, setSearchTerm,
    stopPageResetting, setStopPageResetting,
    /* eslint-enable */
  };

  return (
    <Modal
      open
      title=""
      primaryLabel="CONFIRM"
      secondaryLabel="BACK"
      content={loading ? LoadingSpinner() : ModalContent(contentProps)}
      primaryDisabled={isInvalid}
      onPrimaryClick={handleConfirmation}
      onSecondaryClick={onCancel}
      contentStyle={{ width: 700 }}
    />
  );
};

EntitiesSelectionModal.propTypes = {
  entities: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

EntitiesSelectionModal.defaultProps = {
  loading: false,
};

const Wrapper = styled.div`
  color: black;
  font-family: DIN-Medium;
  padding-bottom: 40px;
`;

const Title = styled.div`
  font-size: 23px;
  margin-top: -20px;
`;

const Description = styled.div`
  font-size: 18px;
  margin-top: 10px;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 290px;
`;

const LimitIndicator = styled.div`
  color: black;
  font-size: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  margin-top: 50px;
`;

export default EntitiesSelectionModal;
