import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlatButton, Paper, CircularProgress } from 'material-ui';

import SubscriptionPlans from '../../components/SubscriptionPlans';
import LogoImage from '../../assets/images/logo.svg';
import { animations } from '../../utils';

export const SubscriptionsScreen = (props) => {
  const {
    onClickContactUs, onClickAction,
    onBack, plans, loading, user: { currentPlan },
  } = props;

  const onClickSubscription = async (s) => {
    if (s.contactUsAction) onClickContactUs();
    else onClickAction(s);
  };

  const renderContent = () => (
    <>
      <LogoContainer>
        <Logo src={LogoImage} />
      </LogoContainer>
      <div className="w-100">
        <SubscriptionPlans
          plans={plans}
          onClickSubscription={onClickSubscription}
          currentPlan={currentPlan}
        />
      </div>
      <ButtonsSection>
        <div className="left-content">
          <div className="contact">
            <span style={{ fontFamily: 'DIN-Medium' }}>Need help choosing a plan?</span>
            <FlatButton
              label="Contact us!"
              primary
              onClick={onClickContactUs}
              labelStyle={{ padding: 0, textTransform: 'none' }}
            />
          </div>
          <div className="back">
            <FlatButton
              label="BACK"
              primary
              fullWidth
              onClick={onBack}
              labelStyle={{ padding: 0, textTransform: 'none' }}
            />
          </div>
        </div>
      </ButtonsSection>
    </>
  );

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Box zDepth={3}>
        <Content>
          {loading
            ? <CircularProgress size={60} thickness={7} />
            : renderContent()}
        </Content>
      </Box>
    </Container>
  );
};

SubscriptionsScreen.propTypes = {
  user: PropTypes.object.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickContactUs: PropTypes.func,
  onClickAction: PropTypes.func,
  onBack: PropTypes.func,
  loading: PropTypes.bool,
};

SubscriptionsScreen.defaultProps = {
  onClickContactUs: () => { },
  onClickAction: () => { },
  onBack: () => { },
  loading: false,
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;

const Box = styled(Paper)`
  width: 1100px;
  min-height: 780px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  padding: 30px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
`;

const ButtonsSection = styled.div`
  margin-top: 3rem;
  width: 100%;
  flex: 1;
  display: flex;

  & > .left-content {
    display: flex;
    flex-direction: column;
    width: 70%;

    & > .contact {
      margin-top: 0.5rem;
      display: inline-block;
    }

    & > .back {
      width: 8rem;
      margin-top: 1rem;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      display: inline-block;
      border: 1px solid #13AAF4;
    }
  }

  & > .signUp {
    display: flex;
    margin-top: 2rem;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const Logo = styled.img`
  width: 220px;
  height: auto;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
  align-self: center;
`;

export default SubscriptionsScreen;
