import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import styled from 'styled-components';
import { TextField, FlatButton, RaisedButton, AutoComplete } from 'material-ui';
import { withStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import * as Yup from 'yup';
import connect from './connect';
import { colors } from '../../../utils';
import { PhoneMask, ZipMask } from './masks';
import CustomTextField from '../../commons/CustomTextField';
import googlePlaces from '../../../services/googlePlaces';

const OrganizationSchema = Yup.object().shape({
  name: Yup.string().ensure()
    .required('Required'),
  subdomain: Yup.string().matches(/^([a-z][a-z\d]*(-[a-z\d]+)*|xn--[-a-z\d]+)$/i)
    .min(3).max(63)
    .required('Required'),
});

const styles = {
  input: {
    color: 'rgba(0, 0, 0, 0.3)',
  },
  textField: {
    marginTop: '16px',
  },
  chip: {
    backgroundColor: '#DBF3FA',
    marginRight: '5px',
  },
};

class OrganizationForm extends Component {
  state = {
    addressSearchOptions: [],
  }

  setStateField = (field, value) => this.setState({ [field]: value });

  setValue = (field, value) => {
    const {
      setFieldValue, setFieldTouched, onChange, values,
    } = this.props;
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    onChange(values);
  }

  // Debounced address search
  searchAddress = debounce((value) => {
    googlePlaces.search(value).then((places) => {
      this.setState({
        addressSearchOptions: places || [],
      });
    });
  }, 1000)

  geocodeSelectedAddress = (selectedAddress) => {
    this.setState({ addressSearchOptions: [] });

    googlePlaces.getDetails(selectedAddress.description).then((details) => {
      this.setValue('street', details.address);
      this.setValue('zip', details.zip);
      this.setValue('city', details.city);
      this.setValue('state', details.state);
    });
  }

  render = () => {
    const {
      values, touched, errors, isValid, onCancel, onDeleteOrganization, edit, submitForm, loading,
      classes,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <TextField
          value={values.name}
          onChange={e => this.setValue('name', e.target.value)}
          errorText={touched.name && errors.name}
          floatingLabelText="Organization *"
          fullWidth
          maxLength={36}
        />
        <Stack style={{ marginBottom: '1.4rem' }}>
          <TextField
            value={values.subdomain}
            onChange={e => this.setValue('subdomain', e.target.value)}
            errorText={touched.subdomain && errors.subdomain}
            floatingLabelText="Subdomain *"
            maxLength={16}
          />
          <DomainLabel className="col-4">.app.sign.identitymark.ai</DomainLabel>
        </Stack>
        <CustomTextField
          value={values.phone}
          onChange={e => this.setValue('phone', e.target.value)}
          errorText={touched.phone && errors.phone}
          label="Organization Phone"
          fullWidth
          className={classes.textField}
          InputProps={{
            inputComponent: PhoneMask,
            classes: {
              underline: 'text-field-core',
            },
          }}
          maxLength={20}
        />
        <AutoComplete
          maxLength={90}
          fullWidth
          floatingLabelText="Street Address"
          hintText="Find your address"
          autoComplete={Math.random()}
          dataSource={this.state.addressSearchOptions.map(option =>
            ({ text: option.description, value: option }))}
          filter={(searchText, key) =>
            key.toLowerCase().includes(searchText.toLowerCase())}
          searchText={values.street}
          onUpdateInput={(value, dataSource) => {
            this.setValue('street', value);
            this.searchAddress(value);

            const selectedAddress = dataSource.find(item => (item.text === value));

            if (selectedAddress) {
              this.geocodeSelectedAddress(selectedAddress.value);
            }
          }}
          errorText={touched.street && errors.street}
        />
        <TextField
          value={values.city}
          onChange={e => this.setValue('city', e.target.value)}
          errorText={touched.city && errors.city}
          floatingLabelText="City/Town"
          fullWidth
          maxLength={40}
        />
        <Stack>
          <TextFieldWrapper>
            <TextField
              value={values.state}
              onChange={e => this.setValue('state', e.target.value)}
              errorText={touched.state && errors.state}
              floatingLabelText="State"
              fullWidth
              maxLength={30}
            />
          </TextFieldWrapper>
          <CustomTextField
            value={values.zip}
            onChange={e => this.setValue('zip', e.target.value)}
            errorText={touched.zip && errors.zip}
            label="Zip Code"
            fullWidth
            className={classes.textField}
            InputProps={{
              inputComponent: ZipMask,
              classes: {
                underline: 'text-field-core',
              },
            }}
          />
        </Stack>
        <ButtonsContainer>
          {edit && !values.ownership_transfer_request_exists && (
            <FlatButton
              label="Delete Organization"
              onClick={onDeleteOrganization}
              style={{ color: colors.danger }}
            />
          )}
          <RightButtons>
            <FlatButton
              label="Cancel"
              primary
              onClick={onCancel}
              style={{ marginRight: '24px' }}
            />
            <RaisedButton
              label={loading ? 'Saving' : 'Save'}
              primary
              disabled={!isValid}
              onClick={submitForm}
            />
          </RightButtons>
        </ButtonsContainer>
      </Form>
    );
  }
}

OrganizationForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onDeleteOrganization: PropTypes.func,
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

OrganizationForm.defaultProps = {
  onCancel: () => {},
  onDeleteOrganization: () => {},
  onChange: () => {},
  edit: false,
  loading: false,
};

const Stack = styled.div`
  display: flex;
`;

const DomainLabel = styled.span`
  font-weight: bold;
  margin-left: 15px;
  line-height: 40px;
  align-self: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 40px;
`;

const RightButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const TextFieldWrapper = styled.div`
  margin-right: 20px;
  width: 100%;
`;

export default connect(withFormik({
  validationSchema: OrganizationSchema,
  mapPropsToValues: ({ initialValues }) => ({
    ...initialValues,
  }),
  enableReinitialize: true,
  isInitialValid: ({ initialValues }) => {
    const { name, subdomain } = initialValues;
    return name && subdomain;
  },
  handleSubmit: (values, { props }) => {
    const {
      updateOrganization,
      createOrganization,
      edit,
    } = props;

    values.address = `${values.street || ''}/-/${values.city || ''}/-/${values.state || ''}/-/${values.zip || ''}`;

    if (edit) {
      updateOrganization(values.uuid, values);
    } else {
      createOrganization(values);
    }
  },
})(withStyles(styles)(OrganizationForm)));
