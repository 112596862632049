import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress } from 'material-ui';
import BottomScrollListener from 'react-bottom-scroll-listener';
import Box from '../Box';
import LogItem from './LogItem';

class LogModule extends Component {
  state={};

  renderContent = () => {
    const { loading, logs, emptyMessage } = this.props;
    const { onScrollEnd, loadingMore } = this.props;
    if (loading) {
      return (<Content style={{ textAlign: 'center' }}><CircularProgress size={60} thickness={2} /></Content>);
    }

    return (
      <BottomScrollListener onBottom={onScrollEnd}>
        {scrollRef => (
          <Content innerRef={scrollRef}>
            {
              logs && logs.length > 0 ?
              logs.map(log => <LogItem key={log.uuid} content={log.description} date={log.date} />)
                :
                (
                  <div style={{ textAlign: 'center' }}>
                    {emptyMessage}
                  </div>
                )
            }
            {
              loadingMore &&
              <LoadingContainer><CircularProgress size={25} thickness={2} /></LoadingContainer>
            }
          </Content>
        )}
      </BottomScrollListener>
    );
  };

  render() {
    const { title } = this.props;
    return (
      <Box title={title} boxHeaderStyle={{ fontFamily: 'DIN-Bold' }}>
        {this.renderContent()}
      </Box>
    );
  }
}

LogModule.propTypes = {
  title: PropTypes.string.isRequired,
  logs: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.string,
  loadingMore: PropTypes.bool,
  onScrollEnd: PropTypes.func,
};

LogModule.defaultProps = {
  emptyMessage: '',
  loadingMore: false,
  onScrollEnd: () => {},
};

export default LogModule;

const Content = styled.div`
  width: 100%;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  padding-top: 0.875rem;
  height: 225px;
  overflow-y: scroll;
  * {
    font-family: Roboto;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
`;
