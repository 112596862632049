import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const inputStyle = isDisabled => ({
  width: '100%',
  height: '100%',
  backgroundColor: isDisabled ? 'rgba(125,125,125,0.1)' : 'rgba(255, 255, 0, 0.3)',
  border: 'none',
  paddingLeft: '10px',
  paddingRight: '10px',
});

const required = value => (value ? undefined : 'Required');

const handleAdjustWidth = (onChange, value, scaleRatio) => {
  // CHAR WIDTH IS CALCULATED WITH THE INITIAL FIELD WIDTH
  // DIVIDED BY AN AVERAGE OF 22 CHARACTERS IN THAT SPACE
  const charWidth = (250 * scaleRatio) / 22;
  const dimensions = {
    width: (!value || value.length <= 20) ? (250 * scaleRatio) : (value.length * charWidth),
  };
  onChange({ target: { value } }, dimensions);
};

const Input = ({
  onChange, type, fieldName, style, isRequired, className,
  isDisabled, editable, onRemove, placeholder, value, scaleRatio,
}) => {
  useEffect(() => {
    handleAdjustWidth(onChange, value, scaleRatio);
  }, [value]);
  return (
    <Container>
      <input
        type={type === 'date_signed' ? 'text' : type}
        name={fieldName}
        disabled={isDisabled}
        onChange={e => onChange(e)}
        placeholder={placeholder}
        maxLength={50}
        className={className}
        style={isEmpty(style) ? inputStyle(isDisabled) : style}
        validate={isRequired ? [required] : null}
      />
      {editable && <Close onClick={onRemove}>x</Close>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 104%;
  height: 100%;
`;

const Close = styled.div`
  text-align: right;
  height: 5px;
  right: 0.8rem;
  cursor: pointer;
  position: relative;
  top: -4px;
  font-size: 0.8rem;
`;

Input.propTypes = {
  fieldName: PropTypes.string.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  onRemove: PropTypes.func,
  editable: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  isRequired: false,
  type: 'text',
  style: {},
  className: '',
  isDisabled: false,
  onRemove: () => {},
  onChange: () => {},
  editable: false,
  value: '',
  placeholder: '',
};

export default Input;
