import gql from 'graphql-tag';

export const SEND_REPORT = gql`
  mutation SendReport(
    $startDate: IsoTime!,
    $endDate: IsoTime!,
    $reportType: ReportTypeEnum!,
    $recipients: [String!]!,
    $sendToMe: Boolean!,
  ) {
    sendReport(
      startDate: $startDate,
      endDate: $endDate,
      reportType: $reportType,
      recipients: $recipients,
      sendToMe: $sendToMe,
    ) {
      success
    }
  }
`;

export default { SEND_REPORT };
