/**
  Usage example:
  <PageFooter
    onSavePress={() => console.log('save pressed')}
    onDeletePress={() => console.log('cancel pressed')}
    customActions={[() => <FlatButton primary label="CUSTOM" />]}
  />
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RaisedButton, Dialog, FlatButton } from 'material-ui';
import { spacing, colors } from '../../utils';

class PageFooter extends React.Component {
  state = {
    showDeleteConfirmation: false,
  };

  onDeleteConfirmed = () => {
    this.setState({ showDeleteConfirmation: false });
    this.props.onDeletePress();
  };

  renderSaveButton() {
    const {
      onSavePress, saveButtonLabel, saveDisabled, savePrimary,
    } = this.props;

    if (!onSavePress) return null;

    return (
      <RaisedButton
        className="ml-3"
        label={saveButtonLabel}
        onClick={() => onSavePress()}
        primary={savePrimary}
        disabled={saveDisabled}
        backgroundColor={colors.darkBlue}
        labelColor={colors.white}
        labelStyle={{ display: 'block' }}
      />
    );
  }

  renderDeleteButton() {
    const {
      onDeletePress, deleteButtonLabel,
      deleteDisabled, leftCustomAction,
    } = this.props;
    if (!onDeletePress && !leftCustomAction) return null;
    return leftCustomAction ? leftCustomAction() : (
      <RaisedButton
        className="ml-3"
        secondary
        label={deleteButtonLabel}
        onClick={() => this.setState({ showDeleteConfirmation: true })}
        disabled={deleteDisabled}
      />
    );
  }

  renderBackButton() {
    const { onBackPress } = this.props;
    if (!onBackPress) return null;
    return (
      <FlatButton
        className="ml-3"
        secondary
        label="Back"
        onClick={onBackPress}
        primary
      />
    );
  }

  renderCustomActions() {
    const { customActions } = this.props;
    return (
      <Fragment>
        {customActions.map(customAction => (
          <div className="ml-3" key={Math.random().toString()}>
            {customAction()}
          </div>
        ))}
      </Fragment>
    );
  }

  renderDeleteConfirmationDialog() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary
        onClick={() => this.setState({ showDeleteConfirmation: false })}
      />,
      <FlatButton label="Yes, Delete" primary keyboardFocused onClick={this.onDeleteConfirmed} />,
    ];

    return (
      <Dialog
        title={this.props.deleteConfirmTitle}
        open={this.state.showDeleteConfirmation}
        actions={actions}
        onRequestClose={this.handleClose}
      >
        {this.props.deleteConfirmMessage}
      </Dialog>
    );
  }

  render = () => {
    const { collapsedNav, onBackPress } = this.props;
    return (
      <Container collapsedNav={collapsedNav}>
        <ButtonsContent>
          <ButtonsContent className="ml-5">
            {onBackPress ? this.renderBackButton() : this.renderDeleteButton()}
          </ButtonsContent>
          <ButtonsContent justifyContent="flex-end">
            {this.renderSaveButton()}
            {this.renderCustomActions()}
          </ButtonsContent>
        </ButtonsContent>
        {this.renderDeleteConfirmationDialog()}
      </Container>
    );
  };
}

PageFooter.propTypes = {
  onSavePress: PropTypes.func,
  onDeletePress: PropTypes.func,
  onBackPress: PropTypes.func,
  saveButtonLabel: PropTypes.string,
  deleteButtonLabel: PropTypes.string,
  deleteConfirmTitle: PropTypes.string,
  deleteConfirmMessage: PropTypes.string,
  customActions: PropTypes.arrayOf(PropTypes.func),
  leftCustomAction: PropTypes.func,
  saveDisabled: PropTypes.bool,
  savePrimary: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
  collapsedNav: PropTypes.bool.isRequired,
};

PageFooter.defaultProps = {
  onSavePress: null,
  onDeletePress: null,
  onBackPress: null,
  saveButtonLabel: 'Save Changes',
  deleteButtonLabel: 'Delete',
  deleteConfirmTitle: 'Are you sure you want to delete?',
  deleteConfirmMessage: 'This actions is not reversible.',
  customActions: [],
  leftCustomAction: null,
  saveDisabled: false,
  deleteDisabled: false,
  savePrimary: false,
};

const Container = styled.div`
  min-height: ${spacing.appHeaderSize};
  height: ${spacing.appHeaderSize};
  position: fixed;
  bottom: 0px;
  left: ${props => (props.collapsedNav ? spacing.sidenavWidthCollapsed : spacing.sidenavWidth)}px;
  right: 0px;
  padding-right: 4rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  z-index: 1301;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 7px -2px 10px;

  & > div > button {
    min-width: 140px !important;
  }

  & > div > button:not([disabled]) {
    background-color: #016d9f;
  }
`;

const ButtonsContent = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || 'flex-start'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  flex: 1;
`;

const mapStateToProps = ({ sidenav }) => ({ collapsedNav: sidenav.collapsed });

export default connect(mapStateToProps)(PageFooter);
