import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import {
  scaleValues,
  scaleValueWithConstant,
  decreasedScaleRatio,
} from '../../../utils/scaling';
import Signature from '../../documentSign/Signature';

const DefaultField = ({
  field, scaleRatio, imageWidth, onClick, onResizeStart, onResizeStop,
  recipients, mode, fieldName, isRequired, width, height, onSaved, onChange,
  onSignatureClick,
}) => {
  const currentRecipient = recipients && recipients.find(r => r.uuid === field.recipientUuid);

  const required = value => (value ? undefined : 'Required');


  const renderSignInputComponent = () => {
    const [text, setText] = React.useState(field.value);
    return (
      <FieldContainer scaleRatio={scaleRatio} imageWidth={imageWidth} width={width} height={height}>
        <textarea
          placeholder={isRequired ? `*${field.field.name}` : field.field.name}
          value={text}
          onChange={(e) => {
          setText(e.target.value);
        }}
          onBlur={() => {
          onSaved(text); onChange(text);
}}
          type={field.field.input_type}
          name={fieldName}
          validate={isRequired ? [required] : null}
        />
      </FieldContainer>
    );
  };

  const renderSignSignatureField = () => (
    <Signature
      field={field}
      mode="sign"
      placeholder={isRequired ? `*${field.field.name}` : field.field.name}
      metadata={field.metadata}
      width={width}
      height={height}
      scaleRatio={scaleRatio}
      onSaved={(val) => {
        onSaved(val);
        onChange(val);
      }}
      onClick={onSignatureClick}
      fieldName={`field_${field.id}`}
      fieldValue={field.value}
      isRequired={field.page_field_detail.required}
      type={field.field.input_type}
    />
  );

  const renderSignField = () => (field.field.name.includes('Signature') || field.field.name.includes('Initial') ? renderSignSignatureField() :
    renderSignInputComponent());


  return mode === 'sign' ? renderSignField() : (
    <ResizableBox
      width={field.width}
      height={field.height}
      minConstraints={scaleValues(field.name.includes('Signature') ?
      [150, 40] : [40, 20], scaleRatio)}
      maxConstraints={scaleValues([600, 300], scaleRatio)}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      onClick={onClick}
    >
      <FieldText scaleRatio={scaleRatio} imageWidth={imageWidth}>
        <IndicatorContainer recipientColor={currentRecipient && currentRecipient.color}>
          <div className="indicator" />
        </IndicatorContainer>
        {field.required &&
          <RequiredMark scaleRatio={scaleRatio} imageWidth={imageWidth} >*</RequiredMark>}
        {field.value ? field.value : field.name}
      </FieldText>
    </ResizableBox>
  );
};

DefaultField.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onResizeStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  recipients: PropTypes.array,
  mode: PropTypes.string,
  fieldName: PropTypes.string,
  isRequired: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onSaved: PropTypes.func,
  onChange: PropTypes.func,
  onSignatureClick: PropTypes.func.isRequired,
};

DefaultField.defaultProps = {
  onClick: () => {},
  onResizeStop: () => {},
  onResizeStart: () => {},
  recipients: [],
  mode: 'edit',
  fieldName: '',
  isRequired: false,
  width: 0,
  height: 0,
  onSaved: () => {},
  onChange: () => {},
};

const FieldContainer = styled.div`
  width: 100%;
  height: 100%;
  & > div > div, > div {
    width: 100% !important;
    height: 100% !important;  
  }

  & hr {
    display: none !important;
  }

   & textarea, input {
     resize: none;
     overflow:hidden;
     width: 100% !important;
     height: 100% !important;
     opacity: 0.9 !important;
     background-color: #B3E1F5 !important;
     border: 1px solid #3190C0 !important;
     display: flex;
     justify-content: flex-start;
     font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px !important;
     padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
     align-items: flex-start;
     ::placeholder {  
       font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
       color: #236BA0;
       font-weight: 500;
    }
   }
`;

const RequiredMark = styled.span`
  && {
    margin: 0 ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(3, scaleRatio, imageWidth)}px;;
    color: red;
    font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(10, scaleRatio, imageWidth)}px;
    line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(14, scaleRatio, imageWidth)}px;
  }
`;

const FieldText = styled.div`
  line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(16, decreasedScaleRatio(scaleRatio, 0.3), imageWidth)}px;
  font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, decreasedScaleRatio(scaleRatio, 0.3), imageWidth)}px;
  display: flex;
  align-items: flex-start;
  color: #236BA0;
  font-weight: 500;
  border: 1px solid #6BB5D5;
  justify-content: flex-start;
  padding-top: 5px;
  padding-left: 5px;
  height: 100%;
  white-space: nowrap;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
  height: 100%;

  & > .indicator {
    border-radius: 50%;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    background-color: ${props => props.recipientColor};
  }

`;

export default DefaultField;
