import { isEmpty } from 'lodash';
import Types from '../constants/actionTypes';
import documentService from './../services/document';
import signatureRequestService from '../services/signatureRequest';
import sendDocumentParser from '../utils/parsers/sendDocumentParser';

export const openOptionsMenu = () => ({
  type: Types.documentManager.OPEN_SIDE_OPTIONS_MENU,
});

export const setSelectedDocument = (document, docType) => (dispatch, getState) => {
  try {
    const { selectedDocType } = getState().documentManagerScreen;
    dispatch({
      type: Types.documentManager.SET_SELECTED_DOCUMENT,
      document,
      docType: docType || selectedDocType,
    });

    if (document) {
      dispatch(openOptionsMenu());
    }
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const closeOptionsMenu = () => async (dispatch) => {
  dispatch({ type: Types.documentManager.CLOSE_SIDE_OPTIONS_MENU });
};

export const setSelectedTemplate = template => ({
  type: Types.documentManager.SET_SELECTED_TEMPLATE,
  template,
});


export const shareDocument = (uuid, type, emails, message, name) => async (dispatch, getState) => {
  const { user } = getState().auth;
  const { useBrand, brandUuid } = getState().form.shareDocumentForm.values;

  const payload = {
    recipients: emails.split(',').map(e => e.trim()),
    subject: `${user.firstName} ${user.lastName} has shared ${name} with you.`,
    message,
  };
  if (useBrand) payload.brand_uuid = brandUuid;
  try {
    switch (type) {
      case 'archived': {
        await documentService.sendArchivedDocument(uuid, payload);
        break;
      }
      case 'completed': {
        await documentService.sendSignedDocument(uuid, payload);
        break;
      }
      default:
        break;
    }
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendReminder = (uuid, recipients, reminders, reminderFrequency, specific, now) =>
  async () => {
    try {
      if (reminderFrequency) {
        await signatureRequestService.updateSignatureRequest(uuid, {
          reminders_attributes: [
            ...reminders,
            {
              event: sendDocumentParser.parseReminderToRequest(reminderFrequency),
            },
          ],
        });
      }
      if (specific) {
        await signatureRequestService.updateSignatureRequest(uuid, {
          reminders_attributes: [
            ...reminders,
            {
              event: sendDocumentParser.parseReminderToRequest('day', specific, 1),
            },
          ],
        });
      }
      if (now && !isEmpty(recipients)) {
        await signatureRequestService.sendReminderNow(uuid, recipients.map(r => r.email));
      }

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  };


export const toggleSendTemplateModal = display => ({
  type: Types.documentManager.TOGGLE_SEND_TEMPLATE_MODAL,
  display,
});

export default {
  closeOptionsMenu,
  openOptionsMenu,
  setSelectedDocument,
  setSelectedTemplate,
  shareDocument,
  sendReminder,
  toggleSendTemplateModal,
};
