import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Image = ({
  src, isDot, onRemove, editable,
}) => (
  <Container>
    {!isDot ? <Content image={src} isDot={isDot} /> : (
      <DotContainer>
        <Content image={src} isDot={isDot} />
      </DotContainer>
    )}
    {editable && <Close onClick={onRemove}>x</Close>}
  </Container>
);

const Content = styled.div`
  background-image: url(${props => props.image});
  background-position: ${props => (props.isDot ? 'left' : 'top')};
  background-size: contain;
  background-repeat: no-repeat;
  filter: brightness(0);
  width: 100%;
  padding-top: 3px;
  height: ${props => (props.isDot ? '20%' : '100%')};
`;

const Container = styled.div`
  display: flex;
  border: dotted 1px rgb(84, 188, 234);
  width: 100%;
  height: 100%;
`;

const DotContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const Close = styled.div`
  text-align: right;
  width: 5px;
  height: 5px;
  cursor: pointer;
  position: relative;
  top: -6px;
  right: 2px;
  font-size: 0.8rem;
`;


Image.propTypes = {
  src: PropTypes.string.isRequired,
  isDot: PropTypes.bool,
  onRemove: PropTypes.func,
  editable: PropTypes.bool,
};

Image.defaultProps = {
  isDot: false,
  onRemove: () => {},
  editable: false,
};

export default Image;
