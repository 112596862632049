import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SelectField, MenuItem, CircularProgress } from 'material-ui';
import { PropTypes } from 'prop-types';
import { getTodayMinus } from '../../../utils/date';
import connect from './connect';
import { RoundChart } from '../../charts';
import { Box } from '../..';

export const RateChartReport = ({
  fetchSummary, organizations, withHeader, loading,
}) => {
  const [rate, setRate] = useState(1);
  const [completionRateData, setCompletionRateData] = useState({});

  const startDatesBy = {
    1: () => getTodayMinus(1, 'year'),
    2: () => getTodayMinus(1, 'month'),
    3: () => getTodayMinus(1, 'week'),
  };
  const handleChange = async (newRate) => {
    const newCompletionRateData = await fetchSummary(startDatesBy[newRate](), new Date());
    setCompletionRateData(newCompletionRateData);
    setRate(newRate);
  };

  useEffect(() => {
    handleChange(rate);
  }, [(organizations.currentOrganization || {}).id]);

  const renderTitle = title => (
    <Title>
      <div>{title}</div>
      <SelectField
        autoWidth
        style={{ width: 250 }}
        value={rate}
        onChange={(e, i, v) => handleChange(v)}
      >
        <MenuItem value={1} primaryText="Last 12 Months" />
        <MenuItem value={2} primaryText="Past 30 Days" />
        <MenuItem value={3} primaryText="Past 7 Days" />
      </SelectField>
    </Title>
  );

  const total = [
    'envelopesCompleted', 'envelopesDeclined', 'envelopesPartiallySigned', 'envelopesSent', 'envelopesVoided',
  ].reduce((sum, field) => sum + completionRateData[field], 0);
  const completionRate = total
    ? ((completionRateData.envelopesCompleted / total) * 100).toFixed(2)
    : 0;

  return (
    <Box withHeader={withHeader} title={renderTitle('Completion Rate')} className="col-6">
      <Chart>
        {loading
          ? <CircularProgress size={60} thickness={7} />
          : (
            <RoundChart
              lineName="amt"
              data={{
                name: `${completionRate}%`,
                amt: completionRate,
                fill: '#4caf50',
              }}
            />
          )}
      </Chart>
    </Box>
  );
};

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Chart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 18rem;
  padding: 1rem;
`;

RateChartReport.propTypes = {
  fetchSummary: PropTypes.func.isRequired,
  organizations: PropTypes.object.isRequired,
  withHeader: PropTypes.bool,
  loading: PropTypes.bool,
};

RateChartReport.defaultProps = {
  withHeader: true,
  loading: false,
};

export default connect(RateChartReport);
