import gql from 'graphql-tag';

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate(
    $name: String!,
    $description: String,
    $defaultMessage: String,
    $completeInAnyOrder: Boolean!,
    $brandUuid: ID,
    $documentUuids: [ID!]!,
    $recipientsAttributes: [TemplateRecipientsAttributes!]!,
    ){
      createTemplate(
        name: $name,
        description: $description,
        defaultMessage: $defaultMessage,
        completeInAnyOrder: $completeInAnyOrder,
        brandUuid: $brandUuid,
        documentUuids: $documentUuids,
        recipientsAttributes: $recipientsAttributes,
      ){
        template {
          uuid
        }
      }
  }
`;

export default {
  CREATE_TEMPLATE,
};
