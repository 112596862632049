import * as Icons from 'material-ui/svg-icons';
import CustomFieldIcon from '../assets/images/custom-fields.svg';
import InitialsIcon from '../assets/images/fields_panel_icons/initials.png';
import CompanyIcon from '../assets/images/fields_panel_icons/company.svg';
import StampIcon from '../assets/images/fields_panel_icons/stamp.svg';
import ParticipantStampIcon from '../assets/images/fields_panel_icons/participantStamp.svg';
import NoteIcon from '../assets/images/fields_panel_icons/note.svg';
import NumberIcon from '../assets/images/fields_panel_icons/number.svg';

const editorFields = {
  tabs: {
    standardFields: {
      id: 'STANDARD_FIELDS',
      name: 'Standard Fields',
      icon: Icons.NavigationMenu,
      groups: [
        {
          fields: [
            {
              icon: Icons.EditorBorderColor,
              name: 'Signature',
            },
            {
              specialIcon: true,
              icon: InitialsIcon,
              name: 'Initial',
            },
            {
              icon: Icons.ActionEvent,
              name: 'Date Signed',
            },
          ],
        },
        {
          fields: [
            {
              icon: Icons.EditorTextFields,
              panelName: 'Text Input',
              name: 'Text',
            },
            {
              specialIcon: true,
              icon: NumberIcon,
              panelName: 'Number Input',
              name: 'Number',
            },
            {
              icon: Icons.ToggleCheckBox,
              name: 'Checkbox',
            },
            {
              icon: Icons.NavigationArrowDropDownCircle,
              name: 'Dropdown',
            },
            {
              icon: Icons.ToggleRadioButtonChecked,
              name: 'Radio Button',
            },
          ],
        },
        {
          fields: [
            {
              icon: Icons.FileAttachment,
              name: 'Attachment',
            },
            {
              specialIcon: true,
              icon: NoteIcon,
              name: 'Note',
            },
            {
              icon: Icons.ActionReceipt,
              name: 'Transaction Number',
            },
            {
              specialIcon: true,
              icon: StampIcon,
              name: 'Stamp',
            },
            {
              specialIcon: true,
              icon: ParticipantStampIcon,
              name: 'Participation Stamp',
            },
          ],
        },
      ],
    },
    signerFields: {
      id: 'SIGNER_INFO_FIELDS',
      name: 'Signer Info Fields',
      icon: Icons.SocialPerson,
      groups: [
        {
          fields: [
            {
              icon: Icons.SocialPerson,
              name: 'Name',
            },
            {
              icon: Icons.CommunicationEmail,
              name: 'Email',
            },
            {
              specialIcon: true,
              icon: CompanyIcon,
              name: 'Company',
            },
            {
              icon: Icons.ActionWork,
              name: 'Title',
            },
            {
              icon: Icons.ActionEvent,
              name: 'Date Signed',
            },
          ],
        },
      ],
    },
    customFields: {
      id: 'CUSTOM_FIELDS',
      name: 'Custom Fields',
      showAddIcon: true,
      specialIcon: CustomFieldIcon,
      groups: [
        {
          fields: [
            {
              icon: Icons.EditorTextFields,
              name: 'Custom Field 1',
            },
          ],
        },
      ],
    },
  },
};

// export const unsupportedFields = [9, 10, 11, 12, 15, 16, 17];
export const unsupportedFields = [];

export const requiredFields = [1];

export const notRequiredFields = [3];

export const availableFields = () => {
  const fields = [];
  let count = 1;
  // eslint-disable-next-line array-callback-return
  Object.keys(editorFields.tabs).map((tab) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(editorFields.tabs[tab].groups).map((group) => {
      // eslint-disable-next-line array-callback-return
      editorFields.tabs[tab].groups[group].fields.map((field) => {
        fields.push({ name: field.name, id: count });
        count += 1;
      });
    });
  });
  return fields.filter(field => !unsupportedFields.includes(field.id));
};

export default editorFields;

