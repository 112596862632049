import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Actions from '../../../actions';
import BrandForm from '../../forms/BrandForm/BrandForm';
import { Modal } from '../..';

const BrandModal = ({
  currentBrand,
  displayModal,
  toggleBrandModal,
  submitBrand,
  saving,
  open,
}) => (
  <StyledModal
    open={displayModal || open}
    content={<BrandForm initialValues={currentBrand} />}
    title={currentBrand ? 'Edit Brand' : 'Add Brand'}
    onSecondaryClick={() => toggleBrandModal(false)}
    onPrimaryClick={submitBrand}
    primaryLabel={saving ? 'Saving...' : 'Save'}
    primaryDisabled={saving}
    autoScrollBodyContent
  />
);

BrandModal.propTypes = {
  currentBrand: PropTypes.object,
  displayModal: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  toggleBrandModal: PropTypes.func.isRequired,
  submitBrand: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

BrandModal.defaultProps = {
  currentBrand: null,
  open: false,
};

const StyledModal = styled(Modal)`
  & > div > div > div > div {
    border-color: white !important;
  }
  & h3 {
    font-family: 'DIN-Medium';
  }
`;

const mapStateToProps = ({
  brandsSection: { currentBrand, displayModal, saving },
}) => ({ currentBrand, displayModal, saving });

export default connect(mapStateToProps, Actions)(BrandModal);
