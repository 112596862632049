import React from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { TextField, SelectField } from 'redux-form-material-ui';
import MenuItem from 'material-ui/MenuItem';
import styled from 'styled-components';
import Actions from '../../../actions';
import { colors } from '../../../utils';

const validate = (values) => {
  const errors = {};

  if (!values.description) {
    errors.description = 'This field is required';
  }
  return errors;
};

let ApiAndKeysForm = props => (
  <Form onSubmit={() => { }}>
    <div className="row">
      <div className="col">
        <Field
          name="description"
          required
          component={TextField}
          fullWidth
          maxLength={23}
          floatingLabelText="Name"
        />
      </div>
      <div className="col">
        <Field
          component={SelectField}
          name="brandUuid"
          required
          fullWidth
          disabled={!props.brands.length}
          floatingLabelText="Select Brand"
          dropDownMenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        >
          {props.brands.map(brand =>
            <MenuItem primaryText={brand.name} value={brand.uuid} />)}
        </Field>
      </div>
    </div>
    {props.initialValues.key && props.initialValues.secret &&
      <React.Fragment>
        <div className="row mt-3">
          <div className="col">
            <Label>Api Key</Label>
            <ReadonlyInput
              readonly="readonly"
              type="text"
              component="input"
              className="form-control"
              name="key"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <Label>Secret</Label>
            <ReadonlyInput
              readonly="readonly"
              type="text"
              component="input"
              className="form-control"
              name="secret"
            />
          </div>
        </div>
      </React.Fragment>
    }
  </Form>
);

ApiAndKeysForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
};

const Label = styled.span`
  color: ${colors.textLight};
  font-size: 0.8em;
`;

const ReadonlyInput = styled(Field)`
  && {
    background-color: #fff !important;
    border-radius: 0px;
    &:hover, &:focus, &:active{
      outline: none;
    }
  }
`;

function mapStateToProps({ apiAndKeysSection, brandsSection }) {
  const { currentApiKey } = apiAndKeysSection;
  return {
    initialValues: {
      ...currentApiKey,
      brandUuid: currentApiKey && currentApiKey.brand ? currentApiKey.brand.uuid : null,
    },
    brands: brandsSection.brands,
  };
}

ApiAndKeysForm = reduxForm({
  form: 'addApiKeyForm',
  enableReinitialize: true,
  validate,
})(ApiAndKeysForm);

export default connect(mapStateToProps, Actions)(ApiAndKeysForm);
