import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { SelectField, MenuItem, FlatButton, DatePicker, Card } from 'material-ui';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from 'material-ui/svg-icons/image/edit';

import SortableTable from '../SortableTable';
import EditColumnsModal from '../modals/EditColumnsModal';

const filterOptions = [
  { label: 'Month to Date', value: 'month' },
  { label: 'Year to Date', value: 'year' },
  { label: 'Quarter to Date', value: 'quarter' },
  { label: 'Date Range', value: 'dateRange' },
];

const styles = () => ({
  tableRow: {
    borderRight: '1px solid #E2E2E2',
    borderLeft: '1px solid #E2E2E2',
    verticalAlign: 'top',
  },
});

export const ReportTable = ({
  columns, data, classes, searchTerm, loading,
  options, onFilterChange, page, totalCount, onChangePage,
}) => {
  const [filterType, setFilterType] = useState('');
  const [openModal, setOpenModal] = useState('');
  const [currentColumns, setCurrentColumns] = useState(columns);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!startDate || !endDate) return;
    onFilterChange({ startDate, endDate });
  }, [startDate, endDate]);

  const fields = currentColumns.length === 0 && data.length > 0
    ? Object.keys(data[0])
    : currentColumns.map(c => c.field);

  const matchTerm = o => fields.some(field => String(o[field] || '')
    .toLowerCase()
    .includes(searchTerm.toLowerCase()));

  const filteredData = searchTerm ? data.filter(matchTerm) : data;

  const handleFilterChange = (value) => {
    setFilterType(value);

    if (value === 'dateRange') {
      setStartDate(null);
      setEndDate(null);
      return;
    }

    const getTodayMinus = (number, string) => moment()
      .subtract(number, string)
      .toISOString();
    const startDatesBy = {
      month: () => getTodayMinus(1, 'month'),
      year: () => getTodayMinus(1, 'year'),
      quarter: () => getTodayMinus(3, 'months'),
    };

    onFilterChange({
      startDate: startDatesBy[value](),
      endDate: moment().toISOString(),
    });
  };

  const renderDateOptions = (
    <div className="d-flex">
      <DatePicker
        value={startDate}
        hintText="Select start date"
        onChange={(e, date) => setStartDate(date)}
        container="inline"
        textFieldStyle={{ width: '200px' }}
        style={{ marginRight: '20px' }}
        maxDate={endDate}
        autoOk
      />
      <DatePicker
        value={endDate}
        hintText="Select end date"
        onChange={(e, date) => setEndDate(date)}
        container="inline"
        textFieldStyle={{ width: '200px' }}
        style={{ marginRight: '20px' }}
        minDate={startDate}
        autoOk
      />
    </div>
  );

  const renderFilterOptions = (
    <FiltersWrapper className="d-flex align-items-center">
      <SelectField
        value={filterType}
        onChange={(e, i, value) => handleFilterChange(value)}
        hintText="Choose filter"
        style={{ width: '170px', cursor: 'pointer' }}
        underlineStyle={{ display: 'none' }}
      >
        {filterOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value} primaryText={label} />
        ))}
      </SelectField>
      {filterType === 'dateRange' && renderDateOptions}
      <FlatButton
        label="Edit Columns"
        labelPosition="before"
        labelStyle={{ textTransform: 'none', fontSize: '16px', fontWeight: 'normal' }}
        icon={<EditIcon color="rgb(112, 112, 112)" />}
        onClick={() => setOpenModal(true)}
      />
      <TotalResults className="position-absolute">
        Total Results: {loading ? '...' : totalCount || filteredData.length}
      </TotalResults>
    </FiltersWrapper>
  );

  const renderTable = (
    <SortableTable
      data={filteredData}
      columns={currentColumns}
      loading={loading}
      classes={{ ...classes, tableRowHead: (classes.tableRowHead || '').concat('bg-white') }}
      options={options}
      page={page}
      totalCount={totalCount}
      onChangePage={onChangePage}
    />
  );

  const renderEditColumnsModal = (
    <EditColumnsModal
      allColumns={columns}
      currentColumns={currentColumns}
      onSave={setCurrentColumns}
      onCancel={() => setOpenModal(false)}
    />
  );

  return (
    <Card>
      {renderFilterOptions}
      {renderTable}
      {openModal && renderEditColumnsModal}
    </Card>
  );
};

ReportTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  classes: PropTypes.object,
  columns: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  })),
  searchTerm: PropTypes.string,
  onFilterChange: PropTypes.func,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  onChangePage: PropTypes.func,
};

ReportTable.defaultProps = {
  options: null,
  loading: false,
  data: [],
  classes: {},
  columns: [],
  searchTerm: '',
  onFilterChange: () => {},
  page: null,
  totalCount: null,
  onChangePage: null,
};

const FiltersWrapper = styled.div`
  margin-left: 30px;
  padding-top: 10px;
`;

const TotalResults = styled.div`
  right: 30px;
`;

export default withStyles(styles)(ReportTable);
