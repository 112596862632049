/**
 * Util to track info related to post messages when embedding the experience as iFrame
 * @type {{SIGNING_SUCCESS: string, SIGNING_ERROR: string}}
 */

export const EVENTS = {
  SIGNING_SUCCESS: 'SIGNING_SUCCESS',
  SIGNING_ERROR: 'SIGNING_SUCCESS',
};

export const APP = 'DIGISIGN';

export const getMessageString = (event, data = {}) => JSON.stringify({
  ...data,
  origin: APP,
  event,
});

export const sendMessageToParent = (event, data) => {
  try {
    window.parent.postMessage(getMessageString(event, data), '*');
  } catch (e) {
    // console.log(e);
  }
};

export default {
  EVENTS,
  APP,
  getMessageString,
  sendMessageToParent,
};
