import React from 'react';
import PropTypes from 'prop-types';
import { FlatButton } from 'material-ui';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../../components/pages';
import ShareDocument from '../../components/ShareDocument';
import { colors } from '../../utils';

const ShareScreen = ({ history }) => (
  <PageContainer>
    <PageHeader title="Send Document" />
    <PageContent>
      <ShareDocument />
    </PageContent>
    <PageFooter
      customActions={[
        () => (
          <FlatButton
            labelStyle={{ color: colors.blue }}
            label="Document Manager"
            onClick={() => history.push('/documents-manager')}
          />
        ),
      ]}
    />
  </PageContainer>
);

ShareScreen.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ShareScreen;
