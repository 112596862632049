import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { CircularProgress, FlatButton, RaisedButton } from 'material-ui';
import LogoOpen from '../../../assets/images/logo.svg';
import Modal from '../../Modal';
import SubscriptionCard from '../../SubscriptionCard';
import EnterpriseUpgradeModal from '../../modals/EnterpriseUpgradeModal';

const LoadingSpinner = () => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{ minWidth: 590, minHeight: 240 }}
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

// eslint-disable-next-line react/prop-types
const DetailsContent = ({ handleAccept, onDecline, values: v }) => (
  <>
    <LogoImg src={LogoOpen} tabIndex={-1} alt="app.sign.identitymark.ai" />
    <Title className="align-self-center mb-0">You have been invited to own this organization!</Title>
    <Title className="align-self-center">Would you like to accept the invitation?</Title>

    <Details className="d-flex flex-row align-items-center justify-content-between w-100">
      <LeftSide>
        <div>Name</div>
        <Detail>{v.name}</Detail>
        <div>Address</div>
        <Detail>{v.address}</Detail>
        <div>Members</div>
        <Detail>{v.members}</Detail>
        <div>Documents Completed</div>
        <Detail>{v.documentsCompleted}</Detail>
      </LeftSide>
      <div style={{ minWidth: '30%' }}>
        {v.invitePlan && (
          <SubscriptionCard
            readOnly
            {...v.invitePlan}
            style={{ width: '100%', height: 320 }}
            onClickAction={null}
          />
        )}
      </div>
    </Details>

    <Actions className="d-flex flex-row justify-content-between w-100">
      <FlatButton
        label="decline"
        style={{ borderWidth: '1px', borderStyle: 'solid' }}
        onClick={onDecline}
        primary
      />
      <RaisedButton
        label="accept"
        onClick={handleAccept}
        primary
      />
    </Actions>
  </>
);

export const OrganizationTransferModal = (props) => {
  const {
    onAccept, loading, open, user, setOpenUpdateModal, onClose, setSubscriptionFieldValue,
  } = props;
  const { email, planUsage: { organizationsInUse, organizationsLimit } } = user;
  const [openEnterpriseUpgradeModal, setOpenEnterpriseUpgradeModal] = useState(false);

  const handleAccept = () => {
    if (organizationsLimit !== null && organizationsInUse + 1 > organizationsLimit) {
      const action = organizationsInUse === 1 ? setOpenEnterpriseUpgradeModal :
        async (val) => {
          await setSubscriptionFieldValue('newOrganizationUpgradeRequired', true);
          await setOpenUpdateModal(val);
          onClose();
          toast.error('Your current plan does not support organizations. Please upgrade your plan to continue this action.');
        };
      action(true);
    } else {
      onAccept();
    }
  };
  const handleContactUs = () => {
    window.location.href = `${process.env.REACT_APP_FRONT_PAGE_URL}/contact-us.html?email=${email}`;
  };

  const renderModalContent = () => (
    <Content className="d-flex flex-column align-items-center">
      {loading
        ? <LoadingSpinner />
        : <DetailsContent {...props} handleAccept={handleAccept} />}
    </Content>
  );

  return (
    <>
      <Modal
        open={open || false}
        modal={false}
        content={renderModalContent()}
        primaryStyle={{ display: 'none' }}
        secondaryStyle={{ display: 'none' }}
        titleStyle={{ padding: '50px 0px 50px 24px', fontFamily: 'DIN-Medium' }}
      />
      <EnterpriseUpgradeModal
        open={openEnterpriseUpgradeModal}
        onCancel={() => setOpenEnterpriseUpgradeModal(false)}
        onConfirm={handleContactUs}
      />
    </>
  );
};

OrganizationTransferModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
  setOpenUpdateModal: PropTypes.func.isRequired,
  setSubscriptionFieldValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    members: PropTypes.number,
    documentsCompleted: PropTypes.number,
    plan: PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
      interval: PropTypes.oneOf(['YEAR', 'MONTH']),
      features: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  loading: PropTypes.bool,
};

OrganizationTransferModal.defaultProps = {
  loading: false,
};

const Content = styled.div`
  font-family: DIN-Medium;
`;

const LogoImg = styled.img`
  width: 250px;
`;

const Title = styled.div`
  color: black;
  margin-top: 10px;
  margin-bottom: 35px;
  font-size: 20px;
`;

const Details = styled.div`
  background-color: #f6f6f6;
  padding: 30px 20px 30px 35px;
`;

const LeftSide = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Detail = styled.div`
  font-weight: 600;
`;

const Actions = styled.div`
  padding-top: 44px;
`;

export default OrganizationTransferModal;
