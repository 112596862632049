import gql from 'graphql-tag';

export const CREATE_REMINDER = gql`
mutation CreateReminder(
  $signatureRequestUuid: ID!,
  $message: String!,
  $frequency: FrequencyEnum!,
  $options: ReminderOptions
) {
  createReminder(
    signatureRequestUuid: $signatureRequestUuid,
    message: $message,
    frequency: $frequency,
    options: $options,
  ) {
    reminder {
      uuid
    }
  }
}
`;

export const DELETE_REMINDER = gql`
mutation DeleteReminder($uuid: ID!) {
  deleteReminder(uuid: $uuid) {
    success
  }
}
`;

export const UPDATE_REMINDER = gql`
mutation UpdateReminder(
  $uuid: ID!
  $message: String
  $frequency: FrequencyEnum!
  $options: ReminderOptions
) {
  updateReminder(
    uuid: $uuid
    message: $message
    frequency: $frequency
    options: $options
  ) {
    errors
    reminder {
      uuid
    }
  }
}
`;

export const SEARCH_SIGNATURE_REQUESTS = gql`
  query searchSignatureRequests($query: String) {
    waitingForSignature(query: $query) {
      nodes {
        uuid
        recipients {
          nodes {
            uuid
            alreadySigned
            email
            pending
            signingOrder
          }
        }
        reminders {
          nodes {
            uuid
            message
            frequency
            options {
              date
              recipientUuids
            }
            createdAt
          }
        }
      }
    }
  }
`;

export default {
  CREATE_REMINDER,
  DELETE_REMINDER,
  UPDATE_REMINDER,
  SEARCH_SIGNATURE_REQUESTS,
};
