import { find, trim } from 'lodash';

function findPlaceField(addressComponents, componentType) {
  const field = find(addressComponents, addressComponent =>
    addressComponent.types.includes(componentType));

  if (!field) return '';

  if (componentType === 'administrative_area_level_1') {
    return field.short_name || field.long_name;
  }

  return field.long_name;
}

function parsePlace(place) {
  const streetNumber = findPlaceField(place.address_components, 'street_number');
  const address = findPlaceField(place.address_components, 'route');
  const city = findPlaceField(place.address_components, 'locality');
  const state = findPlaceField(place.address_components, 'administrative_area_level_1');
  const country = findPlaceField(place.address_components, 'country');
  const zip = findPlaceField(place.address_components, 'postal_code');
  const zipSuffix = findPlaceField(place.address_components, 'postal_code_suffix');

  return {
    address: trim(`${streetNumber} ${address}`),
    city,
    state,
    country,
    zip: zipSuffix ? `${zip}-${zipSuffix}` : zip,
  };
}

function search(input) {
  const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  const autocompleteService = new window.google.maps.places.AutocompleteService();
  return new Promise((resolve) => {
    autocompleteService.getPlacePredictions({ input, sessionToken }, resolve);
  });
}

// function getDetails(placeId) {
function getDetails(address) {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        resolve(parsePlace(results[0]));
      } else {
        const msg = `Geocode was not successful for the following reason: ${status}`;
        reject(msg);
      }
    });
  });
}

export default {
  search,
  getDetails,
};
