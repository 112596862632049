import React from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import RemoveMemberModal from './RemoveMemberModal';
import graphqlClient from '../../../config/graphql';
import { DELETE_ORGANIZATION_MEMBER } from './queries';

export const connect = Component => (props) => {
  const onRemove = async (variables, callback) => {
    try {
      await graphqlClient(get(props, 'currentOrganization.subdomain')).mutate({
        mutation: DELETE_ORGANIZATION_MEMBER,
        variables,
      });
      toast.success('Organization User deleted successfully');
      callback();
    } catch (e) {
      toast.error(e.message);
      throw (e);
    }
  };

  return <Component {...props} onRemove={onRemove} />;
};

export default RConnect(({ organizations }) => ({
  currentOrganization: organizations.currentOrganization,
}))(connect(RemoveMemberModal));
