import filename from 'file-name';
import { get } from 'lodash';

import Types from '../constants/actionTypes';
import editorActions, { removeRecipientPageFields, updateRecipientPageFields } from './editor';
import graphqlClient from '../config/graphql';
import signatureRequestService from '../services/signatureRequest';
import serviceParser from '../utils/servicesParser';
import { CREATE_SIGNATURE_REQUEST } from '../utils/mutations/signatureRequest';
import { FETCH_CURRENT_USER } from '../screens/UserProfileScreen/queries';
import { createReminder } from '../utils/sendDocumentGQL';
import { parsePlan } from '../utils/parsers/subscriptionParser';
import { setDocumentFromTemplate } from './filesManager';
import { showLoader, hideLoader } from './loader';

const { sendDocumentParser } = serviceParser;

const {
  SET_FIELD,
  ADD_RECIPIENT,
  SET_RECIPIENTS,
  REMOVE_RECIPIENT,
  ADD_DOCUMENTS,
  REMOVE_DOCUMENT,
  UPDATE_MESSAGE_FIELD,
  TOGGLE_USE_EXISTING_TEMPLATE_MODAL,
  TOGGLE_ADD_FIELDS_LOADER,
  SET_TEMPLATE,
  RESET,
  TOGGLE_BRAND_SELECT,
  SET_MODE,
} = Types.sendDocumentScreen;

const fetchCurrentUser = async (subdomain) => {
  try {
    const {
      data: { currentUser },
    } = await graphqlClient(subdomain).query({
      query: FETCH_CURRENT_USER,
      fetchPolicy: 'network-only',
    });
    return currentUser;
  } catch (e) {
    return null;
  }
};

const updateCurrentUser = () => async (dispatch, getState) => {
  try {
    const { currentOrganization } = getState().organizations;
    const orgUser = await fetchCurrentUser((currentOrganization || {}).subdomain);
    const currentUser = await fetchCurrentUser();
    const currentPlan = parsePlan(get(currentUser, 'subscription.plan') || {});
    dispatch({
      type: Types.auth.LOGIN,
      payload: {
        ...getState().auth.user,
        ...orgUser,
        currentPlan,
      },
    });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

const setSendDocumentField = (field, value) => ({
  type: SET_FIELD,
  field,
  value,
});

const addSendDocumentRecipient = () => (dispatch, getState) => {
  const { recipients } = getState().sendDocumentScreen;
  if (recipients.length < 15) {
    dispatch({
      type: ADD_RECIPIENT,
    });
  }
};

const removeSendDocumentRecipient = index => async (dispatch, getState) => {
  const { recipients } = getState().sendDocumentScreen;
  await dispatch(removeRecipientPageFields(recipients[index].email));
  dispatch({
    type: REMOVE_RECIPIENT,
    index,
  });
};

const setSendDocumentRecipients = recipients => ({
  type: SET_RECIPIENTS,
  recipients,
});

const dispatchSendDocumentField = (field, value) => (dispatch) => {
  dispatch(setSendDocumentField(field, value));
};

const addSendDocumentDocuments = documents => (dispatch, getState) => {
  const { subject, documents: previousDocuments } = getState().sendDocumentScreen;
  if (subject && !subject.touched && documents && documents[0]) {
    dispatch(setSendDocumentField('subject', { value: filename(documents[0].file_name), touched: false }));
  }

  dispatch({
    type: ADD_DOCUMENTS,
    documents: [...previousDocuments, ...documents],
  });
};

const removeSendDocumentDocument = document => (dispatch) => {
  dispatch({
    type: REMOVE_DOCUMENT,
    document,
  });
  return Promise.resolve();
};

const updateMessageDocument = value => ({
  type: UPDATE_MESSAGE_FIELD,
  payload: value,
});

const toggleUseExistingTemplateModal = display => ({
  type: TOGGLE_USE_EXISTING_TEMPLATE_MODAL,
  display,
});

const setTemplateForSigRequest = () => (dispatch, getState) => {
  try {
    const { template } = getState().filesManager;

    dispatch(setDocumentFromTemplate());

    dispatch({
      type: SET_TEMPLATE,
      templateId: template.uuid,
    });

    let pageFields = [];
    // eslint-disable-next-line array-callback-return
    template.documents.map((document, docIndex) => {
      const docPageFields = document.page_fields.map(pf => ({
        ...pf.metadata,
        docIndex,
        documentUUID: document.uuid,
        fieldType: pf.field.id,
        field_type: pf.field.input_type,
        name: pf.field.name,
        id: pf.page_field_detail.id,
        isNew: true,
        pageId: pf.page.id,
        pageIdIndex: document.page_ids.indexOf(pf.page.id),
        recipientUuid: pf.recipient_uuid,
        recipientIndex: template.recipients.map(r => r.uuid).indexOf(pf.recipient_uuid),
        required: pf.page_field_detail.required,
        height: pf.page_field_detail.height,
        width: pf.page_field_detail.width,
        x: pf.page_field_detail.x_coord,
        y: pf.page_field_detail.y_coord,
      }));
      pageFields = pageFields.concat(docPageFields);
    });

    dispatch(setSendDocumentRecipients(template.recipients));
    dispatch(setSendDocumentField('disableCompleteInOrder', template.complete_in_any_order !== true));
    dispatch(setSendDocumentField('completeInOrder', !template.complete_in_any_order));
    dispatch(setSendDocumentField('disableSorting', true));
    dispatch(editorActions.setEditorField('pageFields', pageFields));
    if (template.brand && template.brand.uuid) {
      dispatch(setSendDocumentField('brandUuid', template.brand.uuid));
      dispatch(setSendDocumentField('isSelectedBrandEnabled', true));
    }
    dispatch(toggleUseExistingTemplateModal(false));

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

const sendSignatureRequest = uuid => async () =>
  new Promise((resolve, reject) => {
    signatureRequestService
      .sendSignatureRequest(uuid)
      .then(() => { resolve(); })
      .catch(() => { reject(); });
  });

const createSignatureRequestFromTemplate = shouldSendInvite => async (dispatch, getState) => {
  try {
    const {
      templateId,
      subject,
      message,
      recipients,
      completeInOrder,
      cc,
      password,
      brandUuid,
    } = getState().sendDocumentScreen;
    const { currentOrganization } = getState().organizations;
    const { user } = getState().auth;
    const payload = sendDocumentParser.parseDataFromTemplateToRequest({
      templateId,
      subject,
      message,
      recipients,
      completeInOrder,
      cc,
      password,
      brandUuid,
      currentOrganization,
    });

    const {
      data: { createSignatureRequest: { signatureRequest } },
    } = await graphqlClient((currentOrganization || {}).subdomain).mutate({
      mutation: CREATE_SIGNATURE_REQUEST,
      variables: payload,
    });

    const currentUser = await fetchCurrentUser((currentOrganization || {}).subdomain);
    dispatch({
      type: Types.auth.LOGIN,
      payload: { ...user, currentUser },
    });

    if (shouldSendInvite) {
      await dispatch(sendSignatureRequest(signatureRequest.uuid));
    }
    return Promise.resolve(signatureRequest);
  } catch (e) {
    return Promise.reject(e);
  }
};

const createSignatureRequestFromDocuments = shouldSendInvite => async (dispatch, getState) => {
  try {
    const {
      documents,
      subject,
      message,
      recipients,
      completeInOrder,
      cc,
      password,
      brandUuid,
    } = getState().sendDocumentScreen;
    const { currentOrganization } = getState().organizations;

    const payload = sendDocumentParser.parseDataFromDocumentToRequest({
      documents,
      subject,
      message,
      recipients,
      completeInOrder,
      cc,
      password,
      brandUuid,
      currentOrganization,
    });

    const {
      data: { createSignatureRequest: { signatureRequest } },
    } = await graphqlClient(currentOrganization && currentOrganization.subdomain).mutate({
      mutation: CREATE_SIGNATURE_REQUEST,
      variables: payload,
    });

    if (shouldSendInvite) {
      await dispatch(sendSignatureRequest(signatureRequest.uuid));
    }
    return Promise.resolve(signatureRequest);
  } catch (e) {
    return Promise.reject(e);
  }
};

const createSignatureRequest = shouldSendInvite => async (dispatch, getState) => {
  try {
    const { sendDocumentScreen } = getState();
    const { documentMode } = getState().sendDocumentScreen;
    const { currentOrganization } = getState().organizations;
    let response;
    switch (documentMode) {
      case 'template': {
        showLoader();
        response = await dispatch(createSignatureRequestFromTemplate(shouldSendInvite));
        dispatch(setSendDocumentField('signatureRequest', response.uuid));
        const recipientsUuids = response.recipients.nodes.map(recipient => recipient.uuid);
        createReminder(currentOrganization, response.uuid, recipientsUuids, sendDocumentScreen);
        hideLoader();
        break;
      }
      case 'document': {
        showLoader();
        response = await dispatch(createSignatureRequestFromDocuments(shouldSendInvite));
        dispatch(setSendDocumentField('signatureRequest', response.uuid));
        const recipientsUuids = response.recipients.nodes.map(recipient => recipient.uuid);
        createReminder(currentOrganization, response.uuid, recipientsUuids, sendDocumentScreen);
        hideLoader();
        break;
      }
      default:
        break;
    }
    return Promise.resolve(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

const toggleAddFieldsLoader = loading => ({
  type: TOGGLE_ADD_FIELDS_LOADER,
  loading,
});

const addFields = (shouldSendInvite = false) => async (dispatch) => {
  try {
    dispatch(toggleAddFieldsLoader(true));
    const signatureRequest = await dispatch(createSignatureRequest(shouldSendInvite));
    dispatch(toggleAddFieldsLoader(false));
    return Promise.resolve(signatureRequest);
  } catch (e) {
    return Promise.reject(e);
  }
};

const setMode = documentMode => async (dispatch) => {
  dispatch({ type: SET_MODE, documentMode });
  return Promise.resolve();
};

const resetSendDocument = () => async (dispatch) => {
  dispatch({ type: RESET });
  dispatch(editorActions.resetEditorPageFields());
  return Promise.resolve();
};

const changeRecipientValue = (index, newValue) => async (dispatch, getState) => {
  const { recipients } = getState().sendDocumentScreen;
  await dispatch(updateRecipientPageFields(recipients[index].email, newValue));
  dispatch({
    type: Types.sendDocumentScreen.UPDATE_RECIPIENT,
    payload: {
      index,
      newValue,
    },
  });
};

const toggleBrandSelect = () => ({ type: TOGGLE_BRAND_SELECT });

export {
  addSendDocumentRecipient,
  addSendDocumentDocuments,
  removeSendDocumentRecipient,
  setSendDocumentRecipients,
  removeSendDocumentDocument,
  setSendDocumentField,
  updateMessageDocument,
  toggleUseExistingTemplateModal,
  addFields,
  setTemplateForSigRequest,
  createSignatureRequest,
  resetSendDocument,
  changeRecipientValue,
  sendSignatureRequest,
  toggleBrandSelect,
  dispatchSendDocumentField,
  setMode,
  updateCurrentUser,
};

export default {
  addSendDocumentRecipient,
  addSendDocumentDocuments,
  removeSendDocumentRecipient,
  setSendDocumentRecipients,
  removeSendDocumentDocument,
  setSendDocumentField,
  updateMessageDocument,
  toggleUseExistingTemplateModal,
  addFields,
  setTemplateForSigRequest,
  createSignatureRequest,
  resetSendDocument,
  changeRecipientValue,
  sendSignatureRequest,
  toggleBrandSelect,
  dispatchSendDocumentField,
  setMode,
  updateCurrentUser,
};
