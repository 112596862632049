import gql from 'graphql-tag';

export const FETCH_WAITING_FOR_ME = gql`
  query getWaitingForMe($first: Int) {
    waitingForMe(first: $first) {
      nodes {
        uuid
        message
        subject
        redirectUrl
        activityLogs{
          nodes{
            event
            eventDate
            id
          }
        }
      }
    }
  }
`;

export const FETCH_WAITING_FOR_SIGNATURE = gql`
  query getWaitingForSignature($first: Int) {
    waitingForSignature(first: $first) {
      nodes {
        uuid
        message
        subject
        redirectUrl
        recipients{
          nodes{
            id: uuid
            pending
          }
        }
        activityLogs{
          nodes{
            event
            eventDate
            id
          }
        }
      }
    }
  }
`;

export const FETCH_ACTIVITY_LOGS = gql`
  query getActivityLogs($first: Int){
    activityLogs(first: $first){
      nodes {
        id
        event
        eventDate
        entityType
        metadata
        organization {
          name
        }
        entity {
          ... on ArchivedDocument {
            uuid
            firstName
          }
            ... on SignatureRequest {
            uuid
            subject
          }
            ... on Brand {
            id: uuid
            name
          }
          ... on Document {
            uuid
            fileName
          }
          ... on Template {
            uuid
            name
          }
        }
        user {
          id: uuid
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const FETCH_EVELOPES_SUMMARY = gql`
query EnvelopesSummaryReport(
  $startDate: IsoTime!,
  $endDate: IsoTime!,
  ){
  envelopesSummaryReport(
    startDate: $startDate,
    endDate: $endDate,
  ){
    totalEnvelopes
  }
}
`;


export const CREATE_REMINDER = gql`
mutation CreateReminder(
  $signatureRequestUuid: ID!,
  $message: String!,
  $frequency: FrequencyEnum!,
  $options: ReminderOptions
  ){
  createReminder(
    signatureRequestUuid: $signatureRequestUuid,
    message: $message,
    frequency: $frequency,
    options: $options,
  ){
    reminder {
      uuid
    }
  }
}
`;

export default {
  FETCH_WAITING_FOR_ME,
  FETCH_WAITING_FOR_SIGNATURE,
  FETCH_ACTIVITY_LOGS,
  CREATE_REMINDER,
};
