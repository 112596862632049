import React, { useState } from 'react';
import { connect as RConnect } from 'react-redux';

import graphqlClient from '../../../config/graphql';
import { FETCH_EVELOPES_SUMMARY, FETCH_EVELOPES_DETAILS } from './queries';
import * as DocumentManagerActions from '../../../actions/documentManagerScreen';

const connect = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const { currentOrganization } = props;
  const [loading, setLoading] = useState(false);

  const fetchSummary = async (startDate, endDate) => {
    setLoading(true);
    try {
      const { data } = await graphqlClient(
        (currentOrganization || {}).subdomain,
      ).mutate({
        mutation: FETCH_EVELOPES_SUMMARY,
        variables: { startDate, endDate },
      });
      return data.envelopesSummaryReport;
    } catch (e) {
      throw (e);
    } finally {
      setLoading(false);
    }
  };

  const fetchDetails = async (startDate, endDate) => {
    setLoading(true);
    try {
      const { data } = await graphqlClient(
        (currentOrganization || {}).subdomain,
      ).mutate({
        mutation: FETCH_EVELOPES_DETAILS,
        variables: { startDate, endDate },
      });
      return data.envelopesDetailReport.nodes;
    } catch (e) {
      throw (e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Component
      {...props}
      loading={loading}
      fetchSummary={fetchSummary}
      fetchDetails={fetchDetails}
    />
  );
};

export default Component => RConnect(({ organizations }) => ({
  organizations,
  currentOrganization: organizations.currentOrganization,
}), {
  setSelectedDocument: DocumentManagerActions.setSelectedDocument,
})(connect(Component));
