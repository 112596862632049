import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf';

const PdfDocument = ({ url, width, containerMinHeight }) => (
  <Container width={width} containerMinHeight={containerMinHeight}>
    <Document
      file={url}
      className="document-pdf-preview-wrapper"
    >
      <Page
        pageNumber={1}
        className="document-pdf-preview"
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    </Document>
  </Container>
);

PdfDocument.propTypes = {
  url: PropTypes.string,
  width: PropTypes.string,
  containerMinHeight: PropTypes.string,
};

PdfDocument.defaultProps = {
  url: '',
  width: '100%',
  containerMinHeight: '20rem',
};

const Container = styled.div`
  width: 100%;
  min-height: ${props => props.containerMinHeight};

  & canvas{
    width: ${props => props.width} !important;
    height: auto !important;
  }
`;

export default PdfDocument;
