import { isInteger } from 'lodash';
import api from './api';
import { checkOrganization } from '../utils';

const getOrgParam = (uuid, orgUuid) => `/templates/${uuid}${checkOrganization(orgUuid)}`;

const templateService = {
  get: (uuid, orgUuid) => api.get(getOrgParam(uuid, orgUuid)),
  create: template => api.post('/templates', { template }),
  update: (uuid, template, orgUuid) => api.put(getOrgParam(uuid, orgUuid), { template }),
  delete: id => api.delete(`/templates/${id}`),
  list: (organizationUuid, readyToUse, page, per) => api.get('/templates', {
    params: {
      organization_uuid: isInteger(organizationUuid) ? organizationUuid : '',
      ready_to_use: readyToUse === true || undefined,
      page,
      per,
    },
  }),
};

export default templateService;
