import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton } from 'material-ui';
import moment from 'moment';

const buttonStyle = {
  labelStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: '0.5rem',
    fontFamily: 'Lato',
    fontWeight: '700',
  },
  buttonStyle: {

  },
  buttonContainerStyle: {
    height: '28px',
  },
  overlayStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};


class TableModuleItem extends Component {
  state = {};
  render() {
    const { element, buttonLabel, onClickButton } = this.props;
    return (
      <Container>
        <ButtonContainer>
          <RaisedButton
            fullWidth
            label={buttonLabel}
            onClick={() => { onClickButton(element); }}
            primary
            labelStyle={buttonStyle.labelStyle}
            buttonStyle={buttonStyle.buttonStyle}
            style={buttonStyle.buttonContainerStyle}
            overlayStyle={buttonStyle.overlayStyle}
          />
        </ButtonContainer>
        <Title title={element.title}>
          {element.title}
        </Title>
        <Date>
          {moment(element.date).format('MMMM DD, YYYY')}
        </Date>
      </Container>
    );
  }
}

TableModuleItem.propTypes = {
  element: PropTypes.object.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClickButton: PropTypes.func.isRequired,
};

export default TableModuleItem;

const Container = styled.div`
  width: 100%;
  display: flex;
  font-family: DIN-Medium;
  border-bottom: solid 1px #ECEFF1;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

`;
const Title = styled.div`
  display: block;
  width: 45%;
  font-size: 0.875rem;
  padding: 0.35rem;
  color: #737373;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 2rem;
  
`;
const Date = styled.div`
  display: flex;
  font-size: 0.625rem;
  font-family: 'Roboto';
  width: 25%;
  padding: 0.35rem;
  color: #737373;
`;
