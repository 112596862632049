import React, { useEffect, useState } from 'react';
import { connect as RConnect } from 'react-redux';

import SubscriptionsScreen from './SubscriptionsScreen';
import subscriptionActions from '../../actions/subscriptions';

const connect = Component => (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    history, user: { email, signInCount }, plans, fetchPlans, setSelectedSubscription,
  } = props;
  const [loading, setLoading] = useState(true);

  const bootstrap = async () => {
    if (signInCount > 1) {
      history.push('/dashboard');
      return;
    }
    if (plans.length === 0) {
      await fetchPlans();
    }
    setLoading(false);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  const onClickContactUs = () => {
    window.location.href = `${process.env.REACT_APP_FRONT_PAGE_URL}/contact-us.html?email=${email}`;
  };
  const onClickAction = async (s) => {
    await setSelectedSubscription(s);
    history.push('/subscription/payment');
  };

  const componentProps = {
    ...props,
    loading: props.loading || loading, // eslint-disable-line
    onClickContactUs,
    onClickAction,
    onBack: () => history.push('/dashboard'),
  };
  return <Component {...componentProps} />;
};

export default RConnect(({ auth, subscriptions: s }) => ({
  user: auth.user, ...s,
}), {
  fetchPlans: subscriptionActions.fetchPlans,
  setSelectedSubscription: subscriptionActions.setSelectedSubscription,
})(connect(SubscriptionsScreen));
