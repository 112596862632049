import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { RaisedButton } from 'material-ui';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';

import ApiAndKeysModal from '../../modals/ApiAndKeysModal/ApiAndKeysModal';
import Modal from '../../Modal';
import Messages from '../../../constants/toastMessages';
import OptionsPopover from '../../OptionsPopover';
import SectionLoader from './../../SectionLoader';
import connect from './connect';

const styles = () => ({
  tableRowHead: {
    backgroundColor: '#FAFAFA',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },
  tableHeadCell: {
    border: 'none',
  },
  tableCell: {
    border: 'none',
    backgroundColor: 'white',
    color: 'gray',
  },
  message: {
    fontFamily: 'DIN-Medium',
    fontSize: '24px',
    textAlign: 'center',
    color: 'rgba(0,0,0,0.87)',
    margin: '1rem',
  },
});

class ApiKeysSection extends React.Component {
  static propTypes = {
    toggleApiAndKeysModal: PropTypes.func.isRequired,
    formValues: PropTypes.func.isRequired,
    createApiKey: PropTypes.func.isRequired,
    apiAndKeysSection: PropTypes.object.isRequired,
    loadApiKeys: PropTypes.func.isRequired,
    showConfirmDialog: PropTypes.func.isRequired,
    deleteApiKey: PropTypes.func.isRequired,
    regenerateApiKey: PropTypes.func.isRequired,
    setCurrentApiKey: PropTypes.func.isRequired,
    editApiKey: PropTypes.func.isRequired,
  }

  state = {
    order: 'asc',
    orderBy: '',
    orderType: 'text',
    unmasked_secret: '',
  };

  componentDidMount = async () => {
    const { loadApiKeys } = this.props;
    try {
      await loadApiKeys();
    } catch (e) {
      toast.error(Messages.apiKeys.fetch.error);
    }
  }

  getSorting = (order, orderBy) => (order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy));

  getDateSorting = (order, orderBy) => (a, b) => {
    const dateB = moment(b[orderBy], 'MMMM Do, YYYY');
    const dateA = moment(a[orderBy], 'MMMM Do, YYYY');
    return order === 'desc' ? dateB - dateA : dateA - dateB;
  };

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  handleRequestSort = (property, type) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, orderType: type });
  };

  handleApiKeyModalCancel = () => {
    const { toggleApiAndKeysModal } = this.props;
    toggleApiAndKeysModal(false);
  };

  handleApiKeyModalSave = async () => {
    const {
      formValues, apiAndKeysSection, editApiKey, createApiKey, currentOrganization,
    } = this.props;
    const { currentApiKey } = apiAndKeysSection;
    const values = formValues(this.props);
    if (currentApiKey.id) {
      editApiKey(
        currentApiKey.id,
        values.description,
        values.brandUuid,
      );
    } else {
      const { unmasked_secret } = await createApiKey(
        values.description,
        currentOrganization.uuid,
        values.brandUuid,
      );
      this.setState({ unmasked_secret });
    }
  }

  copyToClipboard = () => {
    const err = () => toast.error('Error while copying the secret to your clipboard');
    const secret = document.getElementById('unmasked-secret').value;
    const aux = document.createElement('div');

    try {
      aux.setAttribute('contentEditable', true);
      aux.innerHTML = secret;
      document.body.appendChild(aux);
      window.getSelection().selectAllChildren(aux);
      const successful = document.execCommand('copy');
      document.body.removeChild(aux);
      if (successful) toast.success('Secret copied to clipboard successfuly');
      else err();
    } catch (e) {
      err();
    }
  }

  parseApiKeys = () => {
    const { apiAndKeysSection } = this.props;
    const { clientApps } = apiAndKeysSection;
    return clientApps.map(({
      id, description, key, created_at, brand, secret = '',
    }) => ({
      id,
      description,
      key,
      secret: secret.substring(secret.length - 14, secret.length),
      createdAt: moment(created_at).format('LL'),
      brand,
    }));
  }

  columns = [{
    id: 'description', label: 'Name', field: 'description', type: 'text',
  }, {
    id: 'key', label: 'API Key', field: 'key', type: 'text',
  }, {
    id: 'secret', label: 'Secret', field: 'secret', type: 'text',
  }, {
    id: 'createdAt', label: 'Creation Date', field: 'createdAt', type: 'date',
  }];

  options = () => {
    const {
      showConfirmDialog, deleteApiKey, regenerateApiKey, toggleApiAndKeysModal, setCurrentApiKey,
    } = this.props;

    return [{
      label: 'Edit Title',
      action: (apiKey) => {
        toggleApiAndKeysModal(true, 'Edit API Key');
        setCurrentApiKey(apiKey);
      },
    }, {
      label: 'Regenerate',
      action: (apiKey) => {
        showConfirmDialog(
          `Are you sure you want to regenerate "${apiKey.description}"?`,
          async (result) => {
            if (result) {
              const { unmasked_secret } = await regenerateApiKey(apiKey.id);
              this.setState({ unmasked_secret });
            }
          },
          'Regenerate',
        );
      },
    }, {
      label: 'Delete',
      action: (apiKey) => {
        showConfirmDialog(
          `Are you sure you want to remove "${apiKey.description}"? This action is not reversible.`,
          (result) => {
            if (result) {
              deleteApiKey(apiKey.id);
            }
          },
          'Delete',
        );
      },
    }];
  }

  renderHeaderRow = (row) => {
    const { classes } = this.props;
    const { order, orderBy } = this.state;

    return (
      <TableCell
        key={row.id}
        sortDirection={orderBy === row.id ? order : false}
        className={classes.tableHeadCell}
      >
        <Tooltip title="Sort" placement="bottom-end" enterDelay={300}>
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={() => this.handleRequestSort(row.id, row.type)}
          >
            <NoWrap>{row.label}</NoWrap>
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    );
  }

  renderRow = (n) => {
    const { classes } = this.props;

    return (
      <TableRow
        className={classes.tableRow}
        hover
        tabIndex={-1}
        key={n.id}
      >
        {this.columns.map((row, index) => (
          <TableCell className={classes.tableCell}>
            <WithBorderBottom withBorder={index > 0}>
              {n[this.columns[index].field]}
            </WithBorderBottom>
          </TableCell>
        ))}
        <TableCell className={classes.tableCell}>
          <OptionsPopover
            options={this
              .options()
              .map(({ label, action }) => ({
                label,
                action: () => action(n),
              }))}
          />
        </TableCell>
      </TableRow>
    );
  }

  renderTable = () => {
    const { order, orderBy, orderType } = this.state;
    const { classes } = this.props;

    return (
      <TableWrapper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow >
              {this.columns.map(this.renderHeaderRow)}
              <TableCell className={classes.tableHeadCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.parseApiKeys()
              .sort(orderType === 'date'
                ? this.getDateSorting(order, orderBy)
                : this.getSorting(order, orderBy))
              .map(this.renderRow)}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  };

  render = () => {
    const { apiAndKeysSection } = this.props;
    const { unmasked_secret } = this.state;
    const {
      displayModal, modalTitle, generating, loading,
    } = apiAndKeysSection;

    return (
      <Wrapper>
        <ApiAndKeysModal
          isOpen={displayModal}
          title={modalTitle}
          handleModal={this.handleApiKeyModalSave}
          handleCancel={this.handleApiKeyModalCancel}
          sending={generating}
        />
        {loading
          ? <LoaderContainer><SectionLoader /></LoaderContainer>
          : this.renderTable()}

        <Modal
          title=""
          open={!!unmasked_secret}
          content={
            <div style={styles.message}>
              <KeyContainer>
                This will be the only time you can see your secret key.
                Would you like to copy it the clipboard? TESTING
                <Key disabled id="unmasked-secret" rows={3} value={unmasked_secret} />
              </KeyContainer>
            </div>
          }
          primaryLabel="Ok"
          onPrimaryClick={() => {
            this.copyToClipboard();
            this.setState({ unmasked_secret: '' });
          }}
          secondaryLabel="Cancel"
          onSecondaryClick={() => this.setState({ unmasked_secret: '' })}
          leftButtons={
            <RaisedButton
              primary
              label="Copy"
              style={{ marginLeft: 16 }}
              onClick={this.copyToClipboard}
            />
          }
          contentStyle={{ width: '640px' }}
        />
      </Wrapper>
    );
  }
}

ApiKeysSection.propTypes = {
  toggleApiAndKeysModal: PropTypes.func.isRequired,
  formValues: PropTypes.func.isRequired,
  createApiKey: PropTypes.func.isRequired,
  apiAndKeysSection: PropTypes.object.isRequired,
  loadApiKeys: PropTypes.func.isRequired,
  showConfirmDialog: PropTypes.func.isRequired,
  deleteApiKey: PropTypes.func.isRequired,
  regenerateApiKey: PropTypes.func.isRequired,
  setCurrentApiKey: PropTypes.func.isRequired,
  editApiKey: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};


const KeyContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 24px;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center;
`;

const Key = styled.textarea`
  width: 100%;
  margin: 2rem 0rem;
  height: 100%;
  word-wrap: break-word;
  font-size: 1rem;
  resize: none;
  background-color: transparent;
  border: 1px solid black;
  padding: 0.5rem;
`;

const LoaderContainer = styled.div`
  padding-top: 15rem;
`;

const Wrapper = styled.div``;

const NoWrap = styled.div`
  white-space: nowrap;
`;

const TableWrapper = styled.div`
  margin-top: 10px;
`;

const WithBorderBottom = styled.div`
  ${props => props.withBorder && 'border-bottom: 2px solid #EEEEEE;'}
  padding-bottom: 3px;
  margin-top: 5px;
`;

export default connect(withStyles(styles)(ApiKeysSection));
