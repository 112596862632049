import React, { useEffect } from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { verifyAuthExpiration } from '../utils/auth';
import authActions from '../actions/auth';

import history from './history';
import LoginScreen from '../screens/LoginScreen';
import OrganizationLoginScreen from '../screens/OrganizationLoginScreen/OrganizationLoginScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen/ForgotPasswordScreen';
import withAppContent from '../components/hoc/withAuthContent';
import withDocumentContent from '../components/hoc/withAuthDocumentContent';
import LoaderContainer from '../components/LoaderContainer';
import SignatureScreen from '../screens/SignatureScreen/SignatureScreen';
import RecoveryPasswordScreen from '../screens/RecoveryPasswordScreen';
import SignedDocumentScreen from '../screens/SignedDocumentScreen';
import SignUpScreen from '../screens/SignUpScreen';
import DashboardScreen from '../screens/DashboardScreen/DashboardScreen';
import PaymentScreen from '../screens/PaymentScreen';
import SubscribeEnterpriseUserScreen from '../screens/SubscribeEnterpriseUserScreen';
import SubscriptionsScreen from '../screens/SubscriptionsScreen';
import SendDocumentScreen from '../screens/SendDocumentScreen/SendDocumentScreen';
import AnalyticsScreen from '../screens/AnalyticsScreen';
import UserActivityReportScreen from '../screens/UserActivityReportScreen';
import DocumentsManagerScreen from '../screens/DocumentsManagerScreen/DocumentsManagerScreen';
import PlatformManagerScreen from '../screens/PlatformManagerScreen';
import TemplateScreen from '../screens/TemplateScreen';
import TemplateEditorScreen from '../screens/TemplateEditorScreen/TemplateEditorScreen';
import DocumentPreviewScreen from '../screens/DocumentPreviewScreen';
import OrganizationsScreen from '../screens/OrganizationsScreen';
import UploadAndArchiveScreen from '../screens/UploadAndArchiveScreen/UploadAndArchiveScreen';
import FillAndSignScreen from '../screens/FillAndSignScreen';
import UserProfileScreen from '../screens/UserProfileScreen';
import DocumentEditorScreen from '../screens/DocumentEditorScreen/DocumentEditorScreen';
import FillSignScreen from '../screens/FillSignScreen';
import DocSignedScreen from '../screens/DocSignedScreen/DocSignedScreen';
import SuccessScreen from '../screens/SuccessScreen/index';
import ShareScreen from '../screens/ShareScreen';
import OrganizationMemberScreen from '../screens/OrganizationMemberScreen/OrganizationMemberScreen';
import EnvelopeDetailReportScreen from '../screens/EnvelopeDetailReportScreen';
import RenewEnterprisePlanScreen from '../screens/RenewEnterprisePlanScreen';
import OrganizationInviteScreen from '../screens/OrganizationInviteScreen';
import OrganizationTransferScreen from '../screens/OrganizationTransferScreen';
import StopScheduledReportScreen from '../screens/StopScheduledReportScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import DelegateSignatureScreen from '../screens/DelegateSignatureScreen';
import RequiredCardUpdateScreen from '../screens/RequiredCardUpdateScreen';

const authorizeComponent = Component => (props) => {
  // eslint-disable-next-line
  const { auth: { user, loading }, currentOrganization, location, logout, setTokenExpired, setCurrentUser } = props;

  const getCurrentUser = async () => {
    const { data } = await verifyAuthExpiration(currentOrganization, async () => {
      await logout(location.search);
      if (user && user.uuid) {
        setTokenExpired(true);
      }
    });
    if (!isEmpty(data) && !isEmpty(data.currentUser)) {
      setCurrentUser({
        ...user,
        ...data.currentUser,
      });
    }
  };

  // Validate if the user auth token expired
  useEffect(() => {
    getCurrentUser();
  }, []);


  return user && !loading
    ? <Component {...props} />
    : <Redirect to={`/login${location.search}`} />;
};

const withAuth = compose(connect(
  ({ auth, organizations }) => ({ auth, currentOrganization: organizations.currentOrganization }),
  ({
    logout: authActions.logout,
    setTokenExpired: authActions.setTokenExpired,
    setCurrentUser: authActions.setCurrentUser,
  }),
), authorizeComponent);

const withAuthContent = Component => withAuth(withAppContent(Component));
const withAuthDocumentContent = Component => withAuth(withDocumentContent(Component));

export default () => (
  <Router history={history}>
    <React.Fragment>
      <Route component={LoaderContainer} />
      <Switch>
        {/* CREDIT CARD ERROR */}
        <Route exact path="/require-card-update" component={withAuth(RequiredCardUpdateScreen)} />

        {/* TRANSFER ORGANIZATION OWNERSHIP */}
        <Route exact path="/transfer-ownership" component={withAuth(OrganizationTransferScreen)} />

        {/* ORGANIZATION INVITATION */}
        <Route exact path="/organization-invite" component={withAuth(OrganizationInviteScreen)} />

        {/* STOP SCHEDULED REPORTS */}
        <Route exact path="/stop-report/:reportScheduleUuid" component={StopScheduledReportScreen} />

        {/* ANALYTIC REPORTS */}
        <Route exact path="/analytics/envelope-recipient-report" component={withAuthContent(EnvelopeDetailReportScreen)} />
        <Route exact path="/analytics/user-activity-report" component={withAuthContent(UserActivityReportScreen)} />
        <Route exact path="/analytics" component={withAuthContent(AnalyticsScreen)} />

        {/* FILL AND SIGN */}
        <Route exact path="/fill-sign/:id/complete" component={withAuthContent(DocSignedScreen)} />
        <Route exact path="/fill-sign/:id" component={withAuthDocumentContent(FillSignScreen)} />
        <Route exact path="/fill-sign" component={withAuthContent(FillAndSignScreen)} />

        {/* DOCUMENT MANAGER */}
        <Route path="/documents-manager/upload-and-archive" component={withAuthContent(UploadAndArchiveScreen)} />
        <Route path="/documents-manager/signature-request/fields" component={withAuthDocumentContent(DocumentEditorScreen)} />
        <Route path="/documents-manager/templates/:id/:action" component={withAuthDocumentContent(TemplateEditorScreen)} />
        <Route path="/documents-manager/templates/:id" component={withAuthContent(TemplateScreen)} />
        <Route path="/documents-manager/:tab" component={withAuthContent(DocumentsManagerScreen)} />
        <Route path="/documents-manager" component={withAuthContent(DocumentsManagerScreen)} />

        {/* SUBSCRIPTION */}
        <Route exact path="/subscribe-user/:plan_uuid" component={SubscribeEnterpriseUserScreen} />
        <Route exact path="/subscription/payment" component={withAuth(PaymentScreen)} />
        <Route exact path="/subscription" component={withAuth(SubscriptionsScreen)} />

        <Route path="/send-document" component={withAuthContent(SendDocumentScreen)} />
        <Route path="/share-document" component={withAuthContent(ShareScreen)} />
        <Route path="/platform-manager" component={withAuthContent(PlatformManagerScreen)} />
        <Route path="/organizations" component={withAuthContent(OrganizationsScreen)} />
        <Route path="/profile" component={withAuthContent(UserProfileScreen)} />
        <Route path="/document-preview" component={withAuthContent(DocumentPreviewScreen)} />
        <Route path="/organization-member/:id" component={withAuthContent(OrganizationMemberScreen)} />
        <Route exact path="/renew-enterprise" component={withAuth(RenewEnterprisePlanScreen)} />
        <Route exact path="/organization-login" component={withAuth(OrganizationLoginScreen)} />
        <Route exact path="/notifications" component={withAuthContent(NotificationsScreen)} />
        <Route path="/dashboard" component={withAuthContent(DashboardScreen)} />
        <Route exact path="/" component={withAuthContent(DashboardScreen)} />

        {/* NO AUTH REQUIRED */}
        <Route path="/signed-document" component={SignedDocumentScreen} />
        <Route path="/signature" component={SignatureScreen} />
        <Route path="/signature-request/:uuid/delegate" component={DelegateSignatureScreen} />
        <Route exact path="/login" component={LoginScreen} />
        <Route exact path="/signup" component={SignUpScreen} />
        <Route exact path="/success" component={SuccessScreen} />
        <Route exact path="/forgot-password" component={ForgotPasswordScreen} />
        <Route exact path="/recovery-password" component={RecoveryPasswordScreen} />
      </Switch>
    </React.Fragment>
  </Router>
);
