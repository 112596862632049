import Types from '../constants/actionTypes';
import documentService from './../services/signDocument';
import graphqlClient from '../config/graphql';
import { FETCH_PLAN_USAGE } from '../screens/UserProfileScreen/queries';
import { logout } from './auth';
import { verifyAuthExpiration } from '../utils/auth';

const fetchUserPlanUsage = async (currentOrganization) => {
  try {
    const {
      data: { currentUser: { planUsage } },
    } = await graphqlClient(currentOrganization).query({
      query: FETCH_PLAN_USAGE,
      fetchPolicy: 'network-only',
    });
    return planUsage;
  } catch (e) {
    return null;
  }
};

export const saveDocumentSign = params => async (dispatch, getState) => {
  try {
    const { currentOrganization } = getState().organizations;
    const { fields } = getState().signDocumentScreen;
    const { user } = getState().auth;
    const payload = { fill: { fields: [] } };

    // Create an object that can be handled by the API
    fields.forEach((v) => {
      const metadataByType = {
        radio: { input_type: 'radio', checked: !!(v.value) },
        select: { input_type: 'select', selected_option: v.value },
        checkbox: { input_type: 'checkbox', checked: !!(v.value) },
      };

      if (v.field.name.includes('Attachment')) {
        payload.fill.fields.push({ id: v.id, value: v.value, metadata: v.metadata });
      } else {
        payload.fill.fields.push({
          id: v.id,
          value: v.value,
          metadata: metadataByType[v.field.input_type] || {},
        });
      }
    });

    const { data: response } = await documentService.fill(params, payload);

    const nextRoute = response.redirect_url || undefined;
    const signedDocument = response.signed_documents && response.signed_documents.length ?
      response.signed_documents[0] : undefined;

    const planUsage = await fetchUserPlanUsage((currentOrganization || {}).subdomain);
    dispatch({
      type: Types.auth.LOGIN,
      payload: { ...user, planUsage },
    });

    return Promise.resolve({
      nextRoute,
      action: () => {
        if (nextRoute) {
          window.location.href = signedDocument ? `${nextRoute}?signatureUuid=${signedDocument.uuid}&signedPdfUrl=${signedDocument.signed_pdf_url}` : nextRoute;
        } else {
          verifyAuthExpiration(currentOrganization, () => dispatch(logout()));
        }
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const getValue = (field) => {
  if (field.value) {
    return field.value;
  } else if (field.metadata && field.metadata.checked) {
    return field.metadata.checked;
  } else if (field.metadata && field.metadata.selected_option >= 0) {
    return field.metadata.options[field.metadata.selected_option];
  }
  return '';
};

export const setSignDocumentFields = fields => async (dispatch) => {
  dispatch({
    type: Types.signDocumentScreen.LOADING,
    loading: true,
  });
  dispatch({
    type: Types.signDocumentScreen.SET_FIELDS,
    fields: fields.map(field => ({ ...field, value: getValue(field) })),
  });
  dispatch({
    type: Types.signDocumentScreen.LOADING,
    loading: false,
  });
};

export const setSignDocumentField = field => async (dispatch, getState) => {
  const { fields } = getState().signDocumentScreen;
  dispatch({
    type: Types.signDocumentScreen.LOADING,
    loading: true,
  });
  dispatch({
    type: Types.signDocumentScreen.SET_FIELD,
    field,
  });

  // RADIO BUTTON LOGIC
  if (field.field.input_type === 'radio' && field.value) {
    const auxFields = fields.map(f => (f.id !== field.id && f.metadata &&
      f.field.id === field.field.id &&
      f.metadata.radio_button_group_id === field.metadata.radio_button_group_id ?
      ({ ...f, value: false }) : f));
    dispatch({
      type: Types.signDocumentScreen.SET_FIELDS,
      fields: auxFields,
    });
  }
  dispatch({
    type: Types.signDocumentScreen.LOADING,
    loading: false,
  });
};


export const setSignDocumentScaleRatio = (scaleRatio, imageWidth) => async (dispatch) => {
  dispatch({
    type: Types.signDocumentScreen.SETTING_SCALE_RATIO,
    settingScaleRatio: true,
  });
  dispatch({
    type: Types.signDocumentScreen.SET_SCALE_RATIO,
    scaleRatio,
    imageWidth,
  });
  dispatch({
    type: Types.signDocumentScreen.SETTING_SCALE_RATIO,
    settingScaleRatio: false,
  });
};

export const setUserSignature = userSignature => async (dispatch) => {
  dispatch({
    type: Types.signDocumentScreen.SET_USER_SIGNATURE,
    userSignature,
  });
};

export default {
  saveDocumentSign,
  setSignDocumentFields,
  setSignDocumentField,
  setSignDocumentScaleRatio,
  setUserSignature,
};
