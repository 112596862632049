import moment from 'moment';

const shortDate = date => moment(date).format('MM/DD/Y');

const longDate = date => moment(date).format('MM/DD/Y hh:mm:ss A');

const fullMonthDate = date => moment(date).format('MMMM Do, YYYY');

export const getTodayMinus = (num, string) => moment(new Date()).subtract(num, string).toDate();

export default {
  longDate,
  shortDate,
  fullMonthDate,
  getTodayMinus,
};
