/* eslint-disable camelcase */
import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Checkbox from 'material-ui/Checkbox';
import IconButton from 'material-ui/IconButton';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import styled from 'styled-components';
import validator from 'validator';
import { HardwareKeyboardArrowDown, HardwareKeyboardArrowRight } from 'material-ui/svg-icons';
import { toast } from 'react-toastify';
import { get, isEmpty, isEqual, isNil, head } from 'lodash';

import UseExistingTemplateModal from '../../components/modals/UseExistingTemplateModal/UseExistingTemplateModal';
import ReminderOptions from '../../components/ReminderOptions';
import Recipients from '../../components/sendDocument/Recipients';
import Messages from '../../constants/toastMessages';
import SelectBrandField from '../../components/SelectBrandField';
import connect from './connect';
import editorParser from '../../utils/parsers/editorParser';
import { Box, DocumentThumbnail } from '../../components';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../../components/pages';
import { colors } from '../../utils';
import { didOrganizationChanged } from '../../utils/userOrganizations';

const reminderFrequencyOptions = ['DAILY', 'WEEKLY', 'MONTHLY', 'DATE'];

class SendDocumentScreen extends React.Component {
  state = {
    ...this.defaultState,
    currentLocation: {},
  };

  componentDidMount() {
    const {
      fetchBrands,
      clearDocuments,
      organizations,
      enableOrganizationChange,
      history,
      setUpgradeModal,
      auth: {
        user: {
          currentPlan: { apiAccess },
          planUsage: { apiDocumentsInUse, noApiDocumentsInUse, documentsLimit },
        },
      },
      editor: { pageFields },
      sendDocumentScreen: { documentMode },
    } = this.props;

    const documentsInUse = apiAccess ? apiDocumentsInUse : noApiDocumentsInUse;

    if (!isNil(documentsLimit) && documentsInUse + 1 > documentsLimit) {
      setUpgradeModal(true);
      history.push('/dashboard');
    }
    enableOrganizationChange();
    clearDocuments();
    if (organizations.currentOrganization) {
      fetchBrands();
    }

    if (documentMode !== 'template' && !isEmpty(pageFields)) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ disableEdition: true });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      history,
      getTemplateList,
      clearDocuments,
      fetchBrands,
      resetSendDocument,
      setUpgradeModal,

      organizations: { currentOrganization },
    } = this.props;
    const currentPlanUsage = this.props.auth.user.planUsage;
    const newPlanUsage = nextProps.auth.user.planUsage;
    const { documentsInUse, documentsLimit } = newPlanUsage;
    // VALIDATE IF THE USER CLICKS ON THE SAME SCREEN NAV FOR RESET DATA
    if (!isEqual(this.state.currentLocation.key, history.location.key)
      && isEqual(this.state.currentLocation.pathname, history.location.pathname)) {
      resetSendDocument();
      clearDocuments();
      getTemplateList();
      this.setState(this.defaultState);
      if (currentOrganization) {
        fetchBrands();
      }
    }

    if (!isEqual(currentPlanUsage, newPlanUsage) && (documentsInUse + 1 > Number(documentsLimit))) {
      setUpgradeModal(true);
      history.push('/dashboard');
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      getTemplateList,
      fetchBrands,
      sendDocumentScreen,
      resetSendDocument,
      setEditorField,
      editor,
      history,
      organizations: { currentOrganization },
    } = this.props;
    if (!isEqual(history.location, this.state.currentLocation)) {
      this.setCurrentLocation(history.location);
    }
    const { changedRecipient } = this.state;
    if (didOrganizationChanged(prevProps, this.props)) {
      getTemplateList();
      if (currentOrganization) {
        fetchBrands();
      }
    } if (isEmpty(sendDocumentScreen.documents) && !isEmpty(!editor.pageFields)) {
      resetSendDocument();
    } else if (!isEqual(prevProps.sendDocumentScreen.documents, sendDocumentScreen.documents)) {
      const filteredPageFields = editor.pageFields.filter(pageField =>
        sendDocumentScreen.documents.find(d => d.page_ids.includes(pageField.pageId)));
      setEditorField('pageFields', filteredPageFields);
    }
    if (!changedRecipient && !isEmpty(prevProps.sendDocumentScreen.recipients)
      && !isEqual(sendDocumentScreen.recipients, prevProps.sendDocumentScreen.recipients)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ changedRecipient: true });
    }
  };

  componentWillUnmount() {
    const { enableOrganizationChange } = this.props;
    enableOrganizationChange();
  }

  onClickSend(shouldSendInvite) {
    // If using a template then check if it has fields associated.
    const {
      sendDocumentScreen: { documentMode },
    } = this.props;
    if (documentMode === 'template') {
      const { template } = this.props.filesManager;
      const fields = editorParser.fromSignatureRequestToEditor.parseFields(template);
      if (isEmpty(fields)) {
        this.props.showConfirmDialog(
          'You are sending a template without fields. Will you continue?',
          (result) => {
            if (result) {
              this.createSignatureRequest(shouldSendInvite);
            }
          },
          'OK',
        );
      } else {
        this.createSignatureRequest(shouldSendInvite);
      }
    } else {
      this.createSignatureRequest(shouldSendInvite);
    }
  }

  async onClickAddFields() {
    const {
      history, sendDocumentScreen:
      { documentMode, templateId },
    } = this.props;
    try {
      if (documentMode === 'template') {
        history.push(`/documents-manager/templates/${templateId}/edit?redirect=true`);
      } else {
        this.setState({ isActionInProgress: true });
        this.setState({ isActionInProgress: false, changedRecipient: false });
        history.push('/documents-manager/signature-request/fields');
      }
    } catch (e) {
      this.setState({ isActionInProgress: false });
      toast.error(Messages.signatureRequest.create.error);
    }
  }

  onRemoveDocument = async (document) => {
    const {
      removeSendDocumentDocument, enableOrganizationChange, sendDocumentScreen: { documentMode },
      setMode, resetSendDocument, getTemplateList,
    } = this.props;
    if (documentMode === 'template') {
      await setMode('document');
      await resetSendDocument();
      await getTemplateList();
    } else removeSendDocumentDocument(document);
    enableOrganizationChange();
  };

  setCurrentLocation = currentLocation => this.setState({ currentLocation });

  getActions = () => {
    const { isActionInProgress: loading, disableEdition } = this.state;
    const { sendDocumentScreen, editor } = this.props;
    const {
      documentMode, documents, recipients,
    } = sendDocumentScreen;
    const {
      pageFields,
    } = editor;
    const recipientWithoutSignatureField = recipients && pageFields &&
      recipients.find(r => !r.role) && recipients.find(
      r => !pageFields.find(pf => pf.recipientUuid === r.email && pf.name === 'Signature'));
    const hasRecipient = get(recipients, '[0].email');
    const buttons = {
      sendDocument: () => (
        <RaisedButton
          primary
          label="Send"
          title="Send document"
          disabled={loading || !hasRecipient}
          onClick={() => this.isValid() && this.onClickSend(true)}
        />
      ),
      edit: () => (
        <FlatButton
          primary
          label="Edit"
          title="Edit document"
          disabled={loading}
          onClick={() => this.setState({ disableEdition: !disableEdition })}
        />
      ),
      editFields: () => (
        <FlatButton
          primary
          label="Edit Fields"
          title="Edit fields"
          disabled={loading || !hasRecipient}
          onClick={() => this.isValid() && this.onClickAddFields()}
        />
      ),
      addFields: () => (
        <RaisedButton
          primary
          label="Add Fields"
          title="Add fields"
          disabled={loading || !hasRecipient}
          onClick={() => this.isValid() && this.onClickAddFields()}
        />
      ),
    };

    const unrequiredRecipients = sendDocumentScreen.recipients
      .filter(r => !editor.pageFields.some(p => p.recipientUuid === r.email));

    const missingRecipient = !isEmpty(unrequiredRecipients);

    const conditions = [{
      when: () => isEmpty(head(documents || [])),
      then: () => [],
    }, {
      when: () => documentMode === 'template',
      then: () => ((recipients || []).some(r => !r.role) && recipientWithoutSignatureField
        ? [buttons.addFields]
        : [buttons.sendDocument, buttons.editFields]),
    }, {
      when: () => !missingRecipient && !isEmpty(editor.pageFields),
      then: () => [buttons.edit, buttons.sendDocument],
    }, {
      when: () => documentMode === 'document',
      then: () => [buttons.addFields],
    }, {
      when: () => true,
      then: () => [],
    }];

    return conditions.find(c => c.when()).then();
  };

  getPasswordConfirmErrorMsg() {
    const { password, passwordConfirm } = this.props.sendDocumentScreen;
    if (!password || password.length === 0) {
      return '';
    }
    if (!passwordConfirm || !passwordConfirm.length) return 'Please enter password confirmation';
    else if (passwordConfirm.length && password !== passwordConfirm) return 'Passwords must match';
    return '';
  }

  defaultState = {
    ccToggle: false,
    isActionInProgress: false,
    changedRecipient: false,
    disableEdition: false,
  }

  async createSignatureRequest(shouldSendInvite) {
    try {
      this.setState({ isActionInProgress: true });
      const {
        createSignatureRequest,
        updateSignatureRequestFields,
        resetSendDocument,
        resetEditor,
        sendDocumentScreen: { documentMode },
        fetchDocumentEditorSignatureRequest,
        history,
      } = this.props;


      const signatureRequestData = await createSignatureRequest(shouldSendInvite);
      const data = await fetchDocumentEditorSignatureRequest(signatureRequestData.uuid);
      const response = documentMode === 'template' ? data : await updateSignatureRequestFields(data);

      if (isEmpty(response) || isEmpty(response.errors)) {
        await resetEditor();
        await resetSendDocument();
        toast.success(Messages.signatureRequest.send.success);
        history.push('/share-document');
      } else {
        toast.error(response.errors.message);
      }
      this.setState({ isActionInProgress: false });
    } catch (e) {
      this.setState({ isActionInProgress: false });
    }
  }

  areRecipientsValid = () => {
    const {
      sendDocumentScreen: { recipients },
    } = this.props;
    let isValid = true;
    if (!isEmpty(recipients)) {
      recipients.forEach((recipient) => {
        if (!recipient.email || recipient.email === '' || !validator.isEmail(recipient.email)) {
          isValid = false;
        }
      });
    }
    return isValid;
  };

  isSelectedDocumentValid = () => {
    const { documents, templateId } = this.props.sendDocumentScreen;
    return !(isEmpty(documents) && !templateId);
  };

  isBrandValid = () => {
    const {
      sendDocumentScreen: { isSelectedBrandEnabled, brandUuid },
    } = this.props;
    return !(isSelectedBrandEnabled && !brandUuid);
  };

  isPasswordValid = () => {
    const {
      sendDocumentScreen: { passwordProtect, password, passwordConfirm },
    } = this.props;
    return !(passwordProtect && (isEmpty(password) || password !== passwordConfirm));
  };

  isReminderValid = () => {
    const {
      sendDocumentScreen: { setReminder, reminderFrequency },
    } = this.props;
    return !(
      setReminder &&
      (isEmpty(reminderFrequency) || !reminderFrequencyOptions.includes(reminderFrequency))
    );
  };

  isNameValid = () => {
    const { sendDocumentScreen: { subject } } = this.props;

    return !isEmpty(subject.value);
  };

  isValid = () => {
    const {
      setSendDocumentField, sendDocumentScreen: { openReminderForm },
    } = this.props;

    setSendDocumentField('shouldValidate', true);

    if (openReminderForm) {
      toast.error('Please confirm your reminder');
      return false;
    }
    if (!this.areRecipientsValid()) {
      toast.error('Some recipients are empty or have an invalid email format');
      return false;
    }
    if (!this.isBrandValid()) {
      toast.error('Please select a brand');
      return false;
    }
    if (!this.isPasswordValid()) {
      toast.error("Passwords are empty or don't match");
      return false;
    }
    if (!this.isReminderValid()) {
      toast.error('Please select a reminder frequency');
      return false;
    }
    if (!this.isSelectedDocumentValid()) {
      toast.error('Please upload a document or select a template');
      return false;
    }
    if (!this.isNameValid()) {
      toast.error('Please enter a name for the document');
      return false;
    }

    return true;
  };

  renderDocument = (document) => {
    const { sendDocumentScreen: { templatesList } } = this.props;
    const { disableEdition } = this.state;

    return (
      <DocumentThumbnail
        key={document.uuid}
        document={document}
        enableOptions={!isEmpty(templatesList)}
        onOptionsPress={() => this.props.toggleUseExistingTemplateModal(true)}
        onRemovePress={() => this.onRemoveDocument(document)}
        disabled={disableEdition}
      />
    );
  }

  renderDocumentUploader = () => {
    const {
      sendDocumentScreen: { templatesList, documents },
      auth: { user: { currentPlan: { id: currentPlanId } } },
    } = this.props;
    const { disableEdition } = this.state;
    return (
      <DocumentThumbnail
        enableOptions={!isEmpty(templatesList) && !documents.length && currentPlanId !== 1}
        onOptionsPress={() => this.props.toggleUseExistingTemplateModal(true)}
        onUploadPress={() => this.props.setAddFilesDialog(
          true,
          docs => this.props.addSendDocumentDocuments(docs),
        )}
        disabled={disableEdition}
      />
    );
  }

  renderPasswordOptions(passwordProtect, password, passwordConfirm) {
    if (!passwordProtect) return null;

    const { setSendDocumentField } = this.props;
    const { disableEdition } = this.state;

    return (
      <PasswordOptions className="mb-4">
        <TextField
          floatingLabelText="Password"
          type="password"
          value={password}
          onChange={e => setSendDocumentField('password', e.target.value)}
          errorText={!password.length ? 'Password must not be empty' : ''}
          disabled={disableEdition}
          fullWidth
        />
        <TextField
          floatingLabelText="Confirm Password"
          type="password"
          value={passwordConfirm}
          onChange={e => setSendDocumentField('passwordConfirm', e.target.value)}
          errorText={this.getPasswordConfirmErrorMsg()}
          disabled={disableEdition}
          fullWidth
        />
      </PasswordOptions>
    );
  }

  render() {
    const {
      brandsSection: { brands }, sendDocumentScreen, organizations,
      addSendDocumentRecipient, removeSendDocumentRecipient, changeRecipientValue,
      setSendDocumentRecipients, setSendDocumentField, editor: { pageFields }, history,
    } = this.props;
    const {
      completeInOrder, cc, documents, passwordProtect, password, passwordConfirm,
      subject, message, recipients, isSelectedBrandEnabled, shouldValidate, brandUuid,
      disableCompleteInOrder, disableSorting, documentMode,
    } = sendDocumentScreen;

    const { disableEdition, currentLocation } = this.state;

    return (
      <PageContainer>
        <PageHeader title="Send Document" />
        <PageContent>
          <div className="row">
            <div className="col-12 col-lg-12 d-flex flex-wrap mb-3">
              {(documents || []).map(this.renderDocument)}
              {documentMode !== 'template' && isEmpty(pageFields) && this.renderDocumentUploader()}
            </div>
          </div>
          {isEqual(history.location, currentLocation) && <Recipients
            recipients={recipients}
            changeRecipientValue={changeRecipientValue}
            addSendDocumentRecipient={addSendDocumentRecipient}
            removeSendDocumentRecipient={removeSendDocumentRecipient}
            setSendDocumentRecipients={setSendDocumentRecipients}
            completeInOrder={completeInOrder}
            completeInOrderToggled={(e, value) => setSendDocumentField('completeInOrder', value)}
            disableCompleteInOrder={disableCompleteInOrder}
            disableSorting={disableSorting}
            shouldValidate={shouldValidate}
            disableActions={documentMode === 'template'}
            disable={disableEdition}
          />}

          <CCRecipients className="mb-5">
            <CCRecipientsToggle>
              <IconButton onClick={() => this.setState({ ccToggle: !this.state.ccToggle })}>
                {this.state.ccToggle
                  ? <HardwareKeyboardArrowDown color={colors.textLight} />
                  : <HardwareKeyboardArrowRight color={colors.textLight} />}
              </IconButton>
              CC
            </CCRecipientsToggle>
            {this.state.ccToggle && (
              <div className="d-flex pl-5">
                <CCRecipientsTextField
                  floatingLabelText="Enter CC recipients’ emails separated by commas here."
                  value={cc}
                  disabled={disableEdition}
                  onChange={e => setSendDocumentField('cc', e.target.value)}
                />
              </div>
            )}
          </CCRecipients>

          <div style={{ paddingBottom: 100 }} className="d-flex justify-content-between">
            <TextFieldWrapper className="flex-grow-1 mr-5">
              {!isEmpty(brands) && organizations.currentOrganization && (
                <div className="d-flex">
                  <Checkbox
                    style={{ width: '40px' }}
                    checked={isSelectedBrandEnabled}
                    disabled={disableEdition}
                    onCheck={this.props.toggleBrandSelect}
                  />
                  <SelectBrandField
                    enabled={!disableEdition && isSelectedBrandEnabled}
                    currentBrand={brandUuid}
                    showError={shouldValidate && isSelectedBrandEnabled && !brandUuid}
                    onSelectBrand={value => setSendDocumentField('brandUuid', value)}
                    brands={brands}
                  />
                </div>
              )}

              <TextField
                fullWidth
                maxLength={100}
                floatingLabelText="Email Subject*"
                floatingLabelStyle={{ color: colors.textLight }}
                value={subject.value}
                disabled={disableEdition}
                onChange={e => setSendDocumentField('subject', {
                  value: e.target.value,
                  touched: true,
                })}
                errorText={shouldValidate && !this.isNameValid() && 'Please enter a name for the document'}
              />
              <TextField
                fullWidth
                maxLength={300}
                disabled={disableEdition}
                floatingLabelText="Message"
                floatingLabelStyle={{ color: colors.textLight }}
                value={message}
                onChange={e => this.props.updateMessageDocument(e.target.value)}
                errorStyle={{ color: colors.textLight }}
                multiLine
              />
            </TextFieldWrapper>
            <Options>
              <Box title="Options" boxContentStyle={{ overflow: 'visible' }}>
                <CheckboxWrapper>
                  <Checkbox
                    className="mb-3"
                    disabled={disableEdition}
                    iconStyle={{ marginRight: '30px' }}
                    label="Password Protect"
                    checked={passwordProtect}
                    onCheck={(e, value) => setSendDocumentField('passwordProtect', value)}
                  />
                  {this.renderPasswordOptions(passwordProtect, password, passwordConfirm)}
                  <ReminderOptions
                    disabled={disableEdition}
                    sendDocumentScreenValues={sendDocumentScreen}
                    setSendDocumentField={setSendDocumentField}
                  />
                </CheckboxWrapper>
              </Box>
            </Options>
          </div>
        </PageContent>
        <PageFooter
          onBackPress={documentMode !== 'template' && !isEmpty(pageFields) ? () => this.isValid() && this.onClickAddFields() : ''}
          customActions={this.getActions()}
        />
        <UseExistingTemplateModal />
      </PageContainer>
    );
  }
}

SendDocumentScreen.propTypes = {
  sendDocumentScreen: PropTypes.object.isRequired,
  setAddFilesDialog: PropTypes.func.isRequired,
  setSendDocumentField: PropTypes.func.isRequired,
  addSendDocumentRecipient: PropTypes.func.isRequired,
  setSendDocumentRecipients: PropTypes.func.isRequired,
  removeSendDocumentRecipient: PropTypes.func.isRequired,
  addSendDocumentDocuments: PropTypes.func.isRequired,
  removeSendDocumentDocument: PropTypes.func.isRequired,
  updateMessageDocument: PropTypes.func.isRequired,
  getTemplateList: PropTypes.func.isRequired,
  toggleUseExistingTemplateModal: PropTypes.func.isRequired,
  clearDocuments: PropTypes.func.isRequired,
  resetSendDocument: PropTypes.func.isRequired,
  createSignatureRequest: PropTypes.func.isRequired,
  updateSignatureRequestFields: PropTypes.func.isRequired,
  changeRecipientValue: PropTypes.func.isRequired,
  filesManager: PropTypes.object.isRequired,
  showConfirmDialog: PropTypes.func.isRequired,
  brandsSection: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  toggleBrandSelect: PropTypes.func.isRequired,
  resetEditor: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
  setEditorField: PropTypes.func.isRequired,
  fetchDocumentEditorSignatureRequest: PropTypes.func.isRequired,
};

const CCRecipients = styled.div`
  padding-left: 3.5rem;
`;

const CCRecipientsToggle = styled.div`
  display: flex;
  align-items: center;
`;

const CCRecipientsTextField = styled(TextField)`
  flex: 1;
`;

const PasswordOptions = styled.div`
  margin-top: -1.5rem;
`;

const Options = styled.div`
  width: 320px;
`;

const TextFieldWrapper = styled.div`
  max-width: 640px;
`;

const CheckboxWrapper = styled.div`
  margin-top: 20px;
  padding: 1rem;
`;

export default connect(SendDocumentScreen);
