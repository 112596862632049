import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import SignatureUpload from '../documentSign/SignatureUpload';
import Modal from '../Modal';
import { getBase64ImageMimeType } from '../../utils/file';

class StampPickerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signatureMode: 'upload',
      printName: '',
    };

    this.saveImage = this.saveImage.bind(this);
  }

  canvas = null;
  signatureImage = null;

  doSave(fieldName, image) {
    const { printName } = this.state;
    const { onClose } = this.props;
    if (this.props.beforeSavePromise) {
      this.props.beforeSavePromise(fieldName, image)
        .then(() => {
          // success callback
          this.props.onSaved(image, printName);
          onClose();
        }, () => {
          // error callback
        });
    } else {
      this.props.onSaved(image, printName);
      onClose();
    }
  }

  saveImage() {
    switch (this.state.signatureMode) {
      case 'upload': {
        const mimeType = getBase64ImageMimeType(this.signatureImage) &&
          getBase64ImageMimeType(this.signatureImage).toLowerCase();
        if (!mimeType) {
          toast.error('No image selected. Please select an image before continue');
          break;
        }
        if (mimeType === 'png' || mimeType === 'jpeg' || mimeType === 'jpg') {
          this.doSave(this.props.fieldName, this.signatureImage);
        } else {
          toast.error('The file is not an image. Please try with a PNG, JPG or JPEG file');
        }
        break;
      }
      default:
        break;
    }
  }

  renderSignatureHandler(mode) {
    const { maxSize } = this.props;
    switch (mode) {
      case 'upload':
        return (
          <UploadWrapper>
            <SignatureUpload
              text="Drop file here"
              buttonLabel="Upload File"
              onFilesLoaded={(base64Signature) => {
                  this.signatureImage = base64Signature;
              }}
            />
            <p>{`Maximum upload image size: ${maxSize}MB`}</p>
            {maxSize && <p className="filetype">File types: JPG, PNG, TIFF</p>}
          </UploadWrapper>
        );
      default:
        return null;
    }
  }

  renderModalContent() {
    return (
      <React.Fragment>
        <div>
          {this.state.textSignature}
          {this.renderSignatureHandler(this.state.signatureMode)}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { open, onClose } = this.props;
    return (
      <Modal
        title=""
        open={open}
        modal={false}
        content={this.renderModalContent()}
        primaryLabel={this.props.modalSaving ? 'Saving...' : 'Apply'}
        secondaryLabel="Cancel"
        onPrimaryClick={this.saveImage}
        onSecondaryClick={onClose}
        contentStyle={{ width: '548px' }}
        titleStyle={{ display: 'none' }}
        primaryDisabled={this.props.modalSaving}
      />
    );
  }
}

StampPickerModal.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onSaved: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  open: PropTypes.bool,
  modalSaving: PropTypes.bool,
  beforeSavePromise: PropTypes.func,
};

StampPickerModal.defaultProps = {
  modalSaving: false,
  open: false,
  beforeSavePromise: null,
  maxSize: 0,
};

const UploadWrapper = styled.div`
  width: 500px;
  & > p {
    font-size: 0.7rem;
    color: #979797;
    position: relative;
    bottom: 0.8rem;
  }
  & > .filetype {
    bottom: 2rem;
  }
`;

export default StampPickerModal;
