import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import graphqlClient from '../../../config/graphql';
import organizationActions from '../../../actions/organization';
import { setCurrentUser } from '../../../actions/auth';
import { DECLINE_INVITE } from './queries';
import { FETCH_CURRENT_USER } from '../../../screens/UserProfileScreen/queries';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    componentDidMount= () => {
      // eslint-disable-next-line react/prop-types
      const { organizationInviteToken, setOrganizationsValue } = this.props;
      if (!organizationInviteToken) {
        setOrganizationsValue('openDeclineOrganizationInviteModal', false);
      }
    }

    declineInvite = async (declineReason) => {
      try {
        // eslint-disable-next-line
        const { setOrganizationsValue, organizationInviteToken, selectedInvite, setCurrentAuthUser, user } = this.props;
        const variables = organizationInviteToken ? { inviteToken: organizationInviteToken } :
          // eslint-disable-next-line react/prop-types
          { inviteUuid: selectedInvite.uuid };
        await graphqlClient().mutate({
          mutation: DECLINE_INVITE,
          variables: {
            ...variables,
            declineReason,
          },
        });
        toast.success('Your invite was successfuly declined.');

        const {
          data: { currentUser },
        } = await graphqlClient().query({
          query: FETCH_CURRENT_USER,
          fetchPolicy: 'network-only',
        });
        setCurrentAuthUser({ ...user, ...currentUser });

        setOrganizationsValue('openDeclineOrganizationInviteModal', false);
        setOrganizationsValue('openDeclineOrganizationInviteSuccessModal', true);
      } catch (e) {
        toast.error(e.message);
        throw (e);
      }
    };

    handleBack = () => {
      // eslint-disable-next-line react/prop-types
      const { setOrganizationsValue } = this.props;
      setOrganizationsValue('openDeclineOrganizationInviteModal', false);
      setOrganizationsValue('openOrganizationInviteModal', true);
    }

    render() {
      // eslint-disable-next-line
      const { organizationInviteToken, openDeclineOrganizationInviteModal, selectedInvite } = this.props;
      return (<Component
        // eslint-disable-next-line
        open={(organizationInviteToken || selectedInvite.uuid) && openDeclineOrganizationInviteModal}
        onBack={this.handleBack}
        onSend={this.declineInvite}
        {...this.props}
      />);
    }
  };


const mapStateToProps = ({ organizationInvites, auth }) => ({
  openDeclineOrganizationInviteModal: organizationInvites.openDeclineOrganizationInviteModal,
  organizationInviteToken: organizationInvites.organizationInviteToken,
  selectedInvite: organizationInvites.selectedInvite,
  user: auth.user,
});

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, {
    setOrganizationsValue: organizationActions.setOrganizationsValue,
    setCurrentAuthUser: setCurrentUser,
  })(renderWrappedComponent(WrappedComponent));

export default connect;
