import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircularProgress, FlatButton, RaisedButton } from 'material-ui';
import LogoOpen from '../../../assets/images/logo.svg';
import Modal from '../../Modal';

const LoadingSpinner = () => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{ minWidth: 590, minHeight: 240 }}
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

// eslint-disable-next-line react/prop-types
const DetailsContent = ({ handleAccept, onDecline, values: v }) => (
  <>
    <LogoImg src={LogoOpen} tabIndex={-1} alt="app.sign.identitymark.ai" />
    <Title className="align-self-center mb-0">You have been invited to join this organization!</Title>
    <Title className="align-self-center">Would you like to accept the invitation?</Title>

    <Details className="d-flex flex-row align-items-center justify-content-between w-100">
      <OrgContent>
        <div>Name</div>
        <Detail>{v.name}</Detail>
        <div>Address</div>
        <Detail>{v.address}</Detail>
      </OrgContent>
      <OrgContent>
        <div>Invited By</div>
        <Detail>{v.ownerFullName}</Detail>
      </OrgContent>
    </Details>

    <Actions className="d-flex flex-row justify-content-between w-100">
      <FlatButton
        label="decline"
        style={{ borderWidth: '1px', borderStyle: 'solid' }}
        onClick={onDecline}
        primary
      />
      <RaisedButton
        label="accept"
        onClick={handleAccept}
        primary
      />
    </Actions>
  </>
);

export const OrganizationInviteModal = (props) => {
  const {
    onAccept, loading, open,
  } = props;

  const renderModalContent = () => (
    <Content className="d-flex flex-column align-items-center">
      {loading
        ? <LoadingSpinner />
        : <DetailsContent {...props} handleAccept={onAccept} />}
    </Content>
  );

  return (
    <Modal
      open={open || false}
      modal={false}
      content={renderModalContent()}
      primaryStyle={{ display: 'none' }}
      secondaryStyle={{ display: 'none' }}
      titleStyle={{ padding: '50px 0px 50px 24px', fontFamily: 'DIN-Medium' }}
    />
  );
};

OrganizationInviteModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    members: PropTypes.number,
    documentsCompleted: PropTypes.number,
    plan: PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
      interval: PropTypes.oneOf(['YEAR', 'MONTH']),
      features: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  loading: PropTypes.bool,
};

OrganizationInviteModal.defaultProps = {
  loading: false,
};

const Content = styled.div`
  font-family: DIN-Medium;
`;

const LogoImg = styled.img`
  width: 250px;
`;

const Title = styled.div`
  color: black;
  margin-top: 10px;
  margin-bottom: 35px;
  font-size: 20px;
`;

const Details = styled.div`
  background-color: #f6f6f6;
  padding: 30px 20px 30px 35px;
`;

const OrgContent = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
  width: 50%;
  min-height: 10rem;
`;

const Detail = styled.div`
  font-weight: 600;
`;

const Actions = styled.div`
  padding-top: 44px;
`;

export default OrganizationInviteModal;
