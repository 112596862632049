import gql from 'graphql-tag';

export const UPDATE_TEMPLATE_FIELDS = gql`
  mutation UpdateTemplateFields(
    $templateUuid: ID!
    $pageFields: [UpsertPageField!]!
  ) {
    upsertTemplatePageFields(
      templateUuid: $templateUuid
      pageFields: $pageFields
    ) {
        template{
          uuid
        }
      }
    }
`;


export default {
  UPDATE_TEMPLATE_FIELDS,
};
