import FileDownload from 'js-file-download';
import axios from 'axios';

export const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const getBase64ImageMimeType = (dataBase64) => {
  if (!dataBase64 || typeof dataBase64 !== 'string') {
    return null;
  }
  return dataBase64.substring('data:image/'.length, dataBase64.indexOf(';base64'));
};

export const getFileAndDownload = async (url, fileName) => {
  const response = await axios.get(url);
  FileDownload(response.data, fileName);
};

export default {
  getBase64,
  getBase64ImageMimeType,
  getFileAndDownload,
};
