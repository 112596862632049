import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';

import LoginScreen from './LoginScreen';
import authActions from '../../actions/auth';
import graphqlClient from '../../config/graphql';
import { FETCH_INVITE } from '../../screens/SignUpScreen/queries';

const fetchInvite = async (variables = {}) => {
  try {
    const { data: { invite } } = await graphqlClient().query({
      query: FETCH_INVITE,
      fetchPolicy: 'network-only',
      variables,
    });
    return invite;
  } catch (e) {
    return {};
  }
};

const connect = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const { auth, history, location } = props;
  const [loading, setLoading] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const {
    account_confirmation_success, invite_token: inviteToken,
  } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { user, loading: authLoading } = auth;

  const setInviteInfo = async () => {
    setLoading(true);
    const invite = await fetchInvite({ inviteToken });
    localStorage.setItem('inviteType', invite.inviteType);
    localStorage.setItem('inviteToken', inviteToken);
    setInviteEmail(invite.email);
    setLoading(false);
  };

  useEffect(() => {
    if (!authLoading && (user || {}).uuid) {
      history.push('/dashboard');
      return;
    }

    if (inviteToken) setInviteInfo();

    if (account_confirmation_success && !inviteToken) {
      const showMessage = {
        true: () => toast.success('You have successfully confirmed your account.'),
        false: () => toast.error('Your account have been confirmed already, Please try signing in.'),
      };
      showMessage[account_confirmation_success]();
    }
  }, []);

  const componentProps = {
    ...props,
    email: inviteEmail,
    auth: { ...auth, loading: authLoading || loading },
    createAccount: () => history.push(`/signup${location.search}`),
    forgotPassword: () => history.push('/forgot-password'),
  };

  return <Component {...componentProps} />;
};

export default RConnect(
  ({ auth }) => ({ auth }),
  { login: authActions.login },
)(connect(LoginScreen));
