import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlatButton, RaisedButton, TextField } from 'material-ui';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { history } from '../../config';

const formStyle = {
  width: '100%',
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().ensure()
    .required('Required'),
  password: Yup.string().ensure()
    .required('Required'),
});

const LoginForm = ({
  handleSubmit, values, errors, touched,
  setFieldValue, setFieldTouched, email,
}) => {
  const setValue = (field, value) => {
    setFieldTouched(field, true, false);
    setFieldValue(field, value);
  };

  useEffect(() => {
    if (email) setValue('email', email);
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      style={formStyle}
    >
      <TextField
        name="email"
        fullWidth
        disabled={!!email}
        value={values.email}
        errorText={touched.email && errors.email}
        onChange={e => setValue('email', e.target.value)}
        floatingLabelText="Email address"
        autoComplete="new-password"
      />
      <TextField
        name="password"
        className="mb-5"
        fullWidth
        value={values.password}
        errorText={touched.password && errors.password}
        onChange={e => setValue('password', e.target.value)}
        floatingLabelText="Password"
        type="password"
        autoComplete="new-password"
      />
      <ButtonsSection className="d-flex justify-content-between">
        <FlatButton
          label="Forgot Password?"
          primary
          onClick={() => history.push('/forgot-password')}
          labelStyle={{ padding: 0, textTransform: 'none' }}
        />
        <RaisedButton
          type="submit"
          label="LOGIN"
          buttonStyle={{ width: '8rem' }}
          primary
        />
      </ButtonsSection>
    </form>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  setFieldValue: PropTypes.any.isRequired,
  setFieldTouched: PropTypes.any.isRequired,
  email: PropTypes.string,
};

LoginForm.defaultProps = {
  email: '',
};

const ButtonsSection = styled.div`
  padding-top: 1rem;
  width: 100%;
`;

export default withFormik({
  validationSchema: LoginSchema,
  enableReinitialize: true,
  mapPropsToValues: ({
    email,
    password,
  }) => ({
    email,
    password,
  }),
  handleSubmit: (values, { props }) => {
    props.login(values);
  },
})(LoginForm);
