import React from 'react';
import { connect as RConnect } from 'react-redux';

import actions from '../../actions';

const connect = Component => props => <Component {...props} />;

const stateToProps = ({ auth, organizations }) => ({ auth, organizations });

export default Component => RConnect(stateToProps, actions)(connect(Component));
