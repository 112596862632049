import React from 'react';
import { Tooltip as TooltipMUI } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  tooltip: {
    maxWidth: 236,
    backgroundColor: '#595959',
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.7,
  },
};

const Tooltip = withStyles(styles)(({
  title, children, classes,
}) => (
  <TooltipMUI title={title} classes={classes}>
    {children}
  </TooltipMUI>
));

export { Tooltip as default, Tooltip };
