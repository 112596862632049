import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { colors } from '../utils';

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: colors.blue,
    primary2Color: colors.darkBlue,
    primaryColor: colors.darkBlue,
    accent1Color: colors.darkGray,
  },
  datePicker: {
    color: colors.blue,
    selectColor: colors.blue,
    headerColor: colors.darkBlue,
  },
});

export default muiTheme;
