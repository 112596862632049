import Input from './Input';
import Select from './Select';
import Signature from './Signature';
import ChoiceInput from './ChoiceInput';
import Accept from './Accept';
import Decline from './Decline';
import Upload from './Upload';
import DatePicker from './DatePicker';
import RemoteDatepicker from './RemoteDatepicker';
import Image from './Image';
import Circle from './Circle';

export default {
  Input,
  Select,
  Signature,
  ChoiceInput,
  Accept,
  Decline,
  Upload,
  DatePicker,
  RemoteDatepicker,
  Image,
  Circle,
};
