import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Form, reduxForm, Field } from 'redux-form';
import { TextField, Checkbox } from 'redux-form-material-ui';
import UploadImageModal from '../../modals/UploadImageModal';
import Actions from '../../../actions';
import ColorPickerTrigger from '../../ColorPickerTrigger';
import FileUploadPreview from '../../FileUploadPreview';
import { colors } from '../../../utils';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'This field is required';
  }

  if (!values.company_name) {
    errors.company_name = 'This field is required';
  }

  return errors;
};

const readImage = (callback, imageFile) => {
  const reader = new FileReader();
  reader.onload = () => callback(reader.result);
  reader.readAsDataURL(imageFile);
};

const getImage = (value = {}) => value.url || value.image;

const BrandForm = ({
  initialValues, change, currentBrand, addBrand, editBrand, handleSubmit,
}) => {
  const {
    signing_logo: signingL, email_logo: emailL,
    header_background_color, button_color, header_text_color, button_text_color,
  } = initialValues;
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageFileName, setCurrentImageFileName] = useState('');
  const [signing_logo, setSigningLogo] = useState({});
  const [email_logo, setEmailLogo] = useState({});
  const logoSetters = { signing_logo: setSigningLogo, email_logo: setEmailLogo };
  const signingLogo = getImage(signing_logo);
  const emailLogo = getImage(email_logo);

  const setImageWith = (callback, data) => {
    if (data && typeof data === 'object') {
      readImage(image => callback({ data, image }), data);
    } else {
      callback(data && data !== '_destroy' ? { data, url: data } : {});
    }
  };

  useEffect(() => { setImageWith(setSigningLogo, signingL); }, [signingL]);
  useEffect(() => { setImageWith(setEmailLogo, emailL); }, [emailL]);

  const handleOpenModal = fieldName => () => {
    setCurrentImageFileName(fieldName);
    setOpenImageModal(true);
  };

  const handleImageRemove = imageField => (e) => {
    e.stopPropagation();
    change(imageField, undefined);
    logoSetters[imageField]({});
  };

  const handleImageChange = (data) => {
    if (data) {
      change(currentImageFileName, data);
      setImageWith(logoSetters[currentImageFileName], data);
    }
    setOpenImageModal(false);
  };

  return (
    <Form onSubmit={handleSubmit(currentBrand ? editBrand : addBrand)}>
      <Field component={TextField} fullWidth name="name" floatingLabelText="Brand Name*" maxLength={23} />
      <Field
        component={TextField}
        fullWidth
        maxLength={23}
        name="company_name"
        floatingLabelText="Company Name*"
      />
      <Field className="mt-5 mb-4" component={Checkbox} name="default" label="Set as default" />

      <FormSection>Customize what recipients see:</FormSection>

      <LogosContainer className="row mb-2 mt-3">
        <ImageContainer className="col" onClick={handleOpenModal('signing_logo')}>
          {!!signingLogo && <ImageTitle>Signing Logo</ImageTitle>}
          <FileUploadPreview
            disabled
            autoUpdate
            preload={signingLogo}
            fieldName="signing_logo"
            handleRemove={handleImageRemove('signing_logo')}
            placeholder={<span>Upload Signing Logo</span>}
          />
        </ImageContainer>

        <ImageContainer className="col" onClick={handleOpenModal('email_logo')}>
          {!!emailLogo && <ImageTitle>Email Logo</ImageTitle>}
          <FileUploadPreview
            disabled
            autoUpdate
            preload={emailLogo}
            fieldName="email_logo"
            handleRemove={handleImageRemove('email_logo')}
            placeholder={<span>Upload Email Logo</span>}
          />
        </ImageContainer>
      </LogosContainer>

      <ColorsContainer>
        <div className="row mb-2 mt-4">
          <div className="col">
            <ColorPickerTrigger
              fieldName="header_background_color"
              handleColorChange={change}
              label="Header Background"
              value={header_background_color || '#FFFFFF'}
            />
          </div>

          <div className="col">
            <ColorPickerTrigger
              fieldName="button_color"
              handleColorChange={change}
              label="Button Background"
              value={button_color || colors.blue}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <ColorPickerTrigger
              fieldName="header_text_color"
              handleColorChange={change}
              label="Header Text"
              value={header_text_color || '#000000'}
            />
          </div>

          <div className="col">
            <ColorPickerTrigger
              fieldName="button_text_color"
              handleColorChange={change}
              label="Button Text"
              value={button_text_color || '#FFFFFF'}
            />
          </div>
        </div>
      </ColorsContainer>

      <UploadImageModal
        title="Logo"
        isOpen={openImageModal}
        onClickCancel={() => setOpenImageModal(false)}
        onClickOk={handleImageChange}
        image={getImage(({ signing_logo, email_logo })[currentImageFileName])}
      />
    </Form>
  );
};

BrandForm.propTypes = {
  currentBrand: PropTypes.object,
  addBrand: PropTypes.func.isRequired,
  editBrand: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.any.isRequired,
  change: PropTypes.func.isRequired,
};

BrandForm.defaultProps = {
  currentBrand: {},
};

const FormSection = styled.div`
  width: 100%;
  font-size: 20px;
  font-family: 'DIN-Medium';
  margin-bottom: 1.5rem;
  color: #000;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const LogosContainer = styled.div`
  & svg {
    width: 24px !important;
    margin-bottom: 2rem;
  }

  & > div {
    font-size: 0.9rem;
    font-weight: 700;
    font-family: avenir;
    align-items: flex-start;
  }
  & > .col > div {
    height: 9rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0.8rem;
  }
`;

const ImageTitle = styled.div`
  font-family: 'DIN-Medium';
  font-size: 14px;
  text-transform: uppercase;
  color: black;
  height: 1rem !important;
  width: 256px;
  font-weight: 500 !important;
  text-align: center;
`;

const ColorsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
`;

const mapStateToProps = ({ brandsSection: { currentBrand } }, props) => ({
  currentBrand,
  initialValues: { ...props.initialValues },
});

export default connect(mapStateToProps, Actions)(reduxForm({
  form: 'brandForm',
  validate,
  enableReinitialize: true,
})(BrandForm));
