import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import organizationScreenActions from '../../../actions/organizationsScreen';
import organizationActions from '../../../actions/organization';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render = () => (<Component {...this.props} />)
  };

const mapStateToProps = ({ organizations }, ownProps) => {
  const { currentOrganization } = organizations;
  const values = {};
  if (ownProps.edit) {
    const address = currentOrganization && currentOrganization.address ? currentOrganization.address.split('/-/') : '';
    values.street = address.length > 0 ? address[0] : '';
    values.city = address.length > 1 ? address[1] : '';
    values.state = address.length > 2 ? address[2] : '';
    values.zip = address.length > 3 ? address[3] : '';
    values.phone = currentOrganization.phone_number;
  }

  return {
    initialValues: ownProps.edit && { ...currentOrganization, ...values },
  };
};

const mapDispatchToProps = () => {
  const { toggleOrganizationEditImage, setCurrentOrganizationImage } = organizationScreenActions;
  const { updateOrganization, createOrganization } = organizationActions;

  return {
    toggleOrganizationEditImage,
    setCurrentOrganizationImage,
    updateOrganization,
    createOrganization,
  };
};

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
