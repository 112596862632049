import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, FlatButton, RaisedButton } from 'material-ui';
import {
  UploadFiles,
  // SelectFiles,
} from '.';
import Actions from '../../actions';

class AddDocumentDialog extends React.Component {
  state = {};

  onCancelPress() {
    this.props.setAddFilesDialog(false);
  }

  onContinuePress() {
    const { addFilesCallback, documents } = this.props.filesManager;
    if (typeof addFilesCallback === 'function') addFilesCallback(documents);
    this.props.setAddFilesDialog(false);
  }

  render() {
    const { title, dropTitle, buttonLabel } = this.props.filesManager.propsDialog;
    return (
      <Dialog
        open={this.props.filesManager.showAddFilesDialog}
        title={title || 'Upload or Select Files'}
        actions={[
          <FlatButton label="Cancel" primary onClick={() => this.onCancelPress()} />,
          <RaisedButton
            label="Continue"
            primary
            keyboardFocused
            onClick={() => this.onContinuePress()}
            disabled={this.props.filesManager.uploadProgress < 100 || this.props.filesManager.wip}
          />,
        ]}
      >
        <UploadFiles
          onFilesLoaded={files => this.props.uploadFiles(files)}
          uploading={this.props.filesManager.uploading}
          uploadProgress={this.props.filesManager.uploadProgress}
          message={this.props.filesManager.wipMessage}
          defaultTitle={dropTitle || 'Drop files here'}
          buttonLabel={buttonLabel || 'Select a File'}
        />

        {/* This section is meant for reusing assets, but is disabled for now  */}
        {/* <SelectFiles
          title="Select from your document library:"
          uniqueKey="uuid"
          files={[{ uuid: 1 }, { uuid: 2 }, { uuid: 3 }, { uuid: 4 }, { uuid: 5 }]}
          selectedFiles={[{ uuid: 1 }]}
          onSelection={document => console.log(document)}
        /> */}
      </Dialog>
    );
  }
}

AddDocumentDialog.propTypes = {
  filesManager: PropTypes.object.isRequired,
  setAddFilesDialog: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

const mapStateToProps = state => state;

export default connect(mapStateToProps, Actions)(AddDocumentDialog);

