import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SelectField, MenuItem, CircularProgress } from 'material-ui';
import { PropTypes } from 'prop-types';
import { getTodayMinus } from '../../../utils/date';
import connect from './connect';
import { DocumentsStatusChart } from '../../charts';
import { Box } from '../..';

const StatusChartReport = ({
  fetchSummary, organizations, withHeader, loading,
}) => {
  const [status, setStatus] = useState(1);
  const [statusData, setStatusData] = useState({});

  const startDatesBy = {
    1: () => getTodayMinus(1, 'year'),
    2: () => getTodayMinus(1, 'month'),
    3: () => getTodayMinus(1, 'week'),
  };
  const handleChange = async (newStatus) => {
    const newStatusData = await fetchSummary(startDatesBy[newStatus](), new Date());
    setStatusData(newStatusData);
    setStatus(newStatus);
  };

  useEffect(() => {
    handleChange(status);
  }, [(organizations.currentOrganization || {}).id]);

  const renderTitle = title => (
    <Title>
      <div>{title}</div>
      <SelectField
        autoWidth
        style={{ width: 250 }}
        value={status}
        onChange={(e, i, v) => handleChange(v)}
      >
        <MenuItem value={1} primaryText="Last 12 Months" />
        <MenuItem value={2} primaryText="Past 30 Days" />
        <MenuItem value={3} primaryText="Past 7 Days" />
      </SelectField>
    </Title>
  );

  return (
    <Box withHeader={withHeader} title={renderTitle('Envelope Status')} className="col-6">
      <Chart>
        {loading
          ? <CircularProgress size={60} thickness={7} />
          : (
            <DocumentsStatusChart
              sent={statusData.envelopesSent}
              delivered={statusData.envelopesPartiallySigned}
              completed={statusData.envelopesCompleted}
              declined={statusData.envelopesDeclined}
              voided={statusData.envelopesVoided}
            />
          )}
      </Chart>
    </Box>
  );
};

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Chart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 18rem;
  padding: 1rem;
`;

StatusChartReport.propTypes = {
  fetchSummary: PropTypes.func.isRequired,
  organizations: PropTypes.object.isRequired,
  withHeader: PropTypes.bool,
  loading: PropTypes.bool,
};

StatusChartReport.defaultProps = {
  withHeader: true,
  loading: false,
};

export default connect(StatusChartReport);
