import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import fieldsService from '../services/fields';
import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import { fieldsParser } from '../utils/servicesParser';
import { unsupportedFields } from '../config/editorFields';

export const setFields = fields => ({
  type: Types.fields.SET_FIELDS,
  fields,
});

export const fetchFields = () => async (dispatch) => {
  try {
    const { data: fields } = await fieldsService.list();
    if (fields && !isEmpty(fields.standard)) {
      fields.standard = fields.standard.filter(field => !unsupportedFields.includes(field.id));
    }

    dispatch(setFields(fields));
    return Promise.resolve(fields);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createCustomField = field => async (dispatch, getState) => {
  try {
    const { currentOrganization } = getState().organizations;
    const organizationUuid = currentOrganization ? currentOrganization.uuid : '';
    const { data } = await fieldsService.create(
      fieldsParser.parseFieldToRequest(field),
      organizationUuid,
    );
    dispatch({ type: Types.fields.CREATE_FIELD, field: { ...data } });

    toast.success(Messages.fields.create.success);

    return Promise.resolve(data);
  } catch (error) {
    toast.error(Messages.fields.create.error);

    return Promise.reject(error);
  }
};

export const updateCustomField = field => async (dispatch, getState) => {
  try {
    const organizationUuid = getState().organizations.currentOrganization
      && getState().organizations.currentOrganization.uuid;

    const { data } = await fieldsService.update(
      field.id,
      fieldsParser.parseFieldToRequest(field),
      organizationUuid || undefined,
    );

    dispatch({ type: Types.fields.UPDATE_FIELD, field });

    toast.success(Messages.fields.update.success);

    return Promise.resolve(data);
  } catch (error) {
    toast.error(Messages.fields.create.error);
    return Promise.reject(error);
  }
};

export const deleteCustomField = fieldId => async (dispatch) => {
  try {
    await fieldsService.delete(fieldId);
    dispatch({ type: Types.fields.DELETE_FIELD, id: fieldId });
    toast.success(Messages.fields.delete.success);
    return Promise.resolve(true);
  } catch (error) {
    toast.error(Messages.fields.delete.error);
    return Promise.reject(error);
  }
};

export default {
  setFields,
  fetchFields,
  createCustomField,
  updateCustomField,
  deleteCustomField,
};
