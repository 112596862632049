import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton } from 'material-ui';
import limitReachedImage from '../../../assets/images/enterLimits.png';
import CancelIcon from '../../../assets/images/close.svg';
import { Modal } from '../..';

const ModalContent = ({
  onClickContactUs, onClickCancel,
}) => (
  <Container>
    <CloseButton
      onClick={onClickCancel}
    >
      <CloseImage src={CancelIcon} />
    </CloseButton>
    <Image src={limitReachedImage} />
    <Title>You have reached your plan limit!</Title>
    <Content>
      If you want to add more items to your plan,
      please contact us and let us know what you need.
    </Content>
    <ButtonsSection>
      <RaisedButton
        label="Contact Us"
        onClick={onClickContactUs}
        primary
      />
    </ButtonsSection>
  </Container>
);

ModalContent.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onClickContactUs: PropTypes.func.isRequired,
};

const EnterpriseLimitsReachedModal = props => (
  <StyledModal
    open={props.open}
    content={ModalContent(props)}
    actions={<div />}
  />
);

EnterpriseLimitsReachedModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

const Container = styled.div`
  flex-direction: column;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModal = styled(Modal)`
  & > div > div {
    max-width: 640px !important;
  }
  & > div > div > div {
    padding: 3rem 0rem 0rem;
  }
  & h3 {
    text-align: center;
  }
  & > div > div >div > div{
    max-height: none !important;
  }
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: DIN-Medium;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  width: 100%;
  text-align: center;
`;

const Image = styled.img`
  width: 82px;
  height: 92px;
  margin: 0rem 0rem 2rem;
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: DIN-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  width: 75%;
  margin-top: 2rem;
  text-align: center;
`;

const ButtonsSection = styled.div`
  margin-top: 3rem;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .contact {
    display: inline-block;
    width: 70%;
  }

  & > .signUp {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const CloseImage = styled.img`
  filter: opacity(0.4);
  width: 0.7rem;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 8px;
  top: 3px;
  cursor: pointer;
  padding: 5px;
`;

export { EnterpriseLimitsReachedModal as default, EnterpriseLimitsReachedModal };
