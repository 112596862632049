import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import { SelectField, MenuItem } from 'material-ui';
import DropdownIcon from '../../../assets/images/drop-down-arrow.svg';
import {
  scaleValues,
  scaleValueWithConstant,
  decreasedScaleRatio,
} from '../../../utils/scaling';

const Dropdown = ({
  field, scaleRatio, imageWidth, onClick, onResizeStart, onResizeStop, recipients,
  mode, isRequired, width, height, onSaved, onChange,
}) => {
  const currentRecipient = recipients && recipients.find(r => r.uuid === field.recipientUuid);

  const renderSignDropdownComponent = () => (
    <FieldContainer
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      width={width}
      height={height}
    >
      <StyledSelectField
        hintText={
          <Container>
            {`${isRequired ? '* ' : ''}${field.value}`}
          </Container>
          }
        fullWidth
        height={height}
        onChange={(e, i, value) => {
          onSaved(value);
          onChange(value);
        }}

        width={field.width}
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
        labelStyle={{ position: 'static', color: 'red' }}
        value={field.value ||
          (field.metadata && field.metadata.options[field.metadata.selected_option])}
      >
        {field.metadata && field.metadata.options.map(option => (
          <MenuItem value={option} primaryText={option} />
        ))}
      </StyledSelectField>
    </FieldContainer>
  );

  return mode === 'sign' ? renderSignDropdownComponent() : (
    <ResizableBox
      width={field.width}
      height={field.height}
      minConstraints={scaleValues([120, 20], scaleRatio)}
      maxConstraints={scaleValues([300, 50], scaleRatio)}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      onClick={onClick}
    >
      <StyledSelectFieldDiv
        height={field.height}
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
      >
        <Container>
          <IndicatorContainer recipientColor={currentRecipient && currentRecipient.color}>
            <div className="indicator" />
          </IndicatorContainer>
          <div className="text">
            {`${field.required ? '* ' : ''}${field.selected_option || field.name}`}
          </div>
          <IconContainer>
            <img alt="icon" style={{ width: 8 }} src={DropdownIcon} />
          </IconContainer>
        </Container>
      </StyledSelectFieldDiv>
    </ResizableBox>
  );
};

Dropdown.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onResizeStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  recipients: PropTypes.array,
  mode: PropTypes.string,
  isRequired: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onSaved: PropTypes.func,
  onChange: PropTypes.func,
};

Dropdown.defaultProps = {
  onClick: () => {},
  onResizeStop: () => {},
  onResizeStart: () => {},
  recipients: [],
  mode: 'edit',
  isRequired: false,
  width: 0,
  height: 0,
  onSaved: () => {},
  onChange: () => {},
};

const FieldContainer = styled.div`
   width: ${props => props.width}px;
   height: ${props => props.height}px;
   opacity: 0.9 !important;

   .text{
     width: 100%;
   }
`;

const StyledSelectField = styled(SelectField)`
  padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
  padding-bottom: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(2, scaleRatio, imageWidth)}px;
  background-color: white !important;
  border: 2px solid #B3E1F5;
  font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(16, scaleRatio, imageWidth)}px !important;
  height: 100% !important;
  line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(24, scaleRatio, imageWidth)}px !important;
  & * {
    cursor: pointer !important;
  }
  & svg{
    fill: black !important;
    position: relative;
    bottom: 0.4rem;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(28, scaleRatio, imageWidth)}px !important;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(28, scaleRatio, imageWidth)}px !important;
  }
  & hr{
    border-color: transparent !important;
  }
  & div{
    color: #236BA0 !important;
    font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px !important;
  
    line-height: ${({ height }) => height}px !important;
    max-width: 100% !important;
    font-family: DIN-Medium;
  }

  & button {
    padding: 0px !important;
    height: auto !important;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      * {
        bottom: 0px !important;
      }
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 95%;
  position: absolute;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

const StyledSelectFieldDiv = styled.div`
  padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
  padding-bottom: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(2, scaleRatio, imageWidth)}px;
  background-color: white !important;
  border: 2px solid #B3E1F5;
  font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(14, decreasedScaleRatio(scaleRatio, 0.3), imageWidth)}px !important;
  height: 100% !important;
  line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(18, decreasedScaleRatio(scaleRatio, 0.3), imageWidth)}px !important;
  & * {
    cursor: pointer !important;
  }
  & svg{
    fill: black !important;
    position: relative;
    bottom: 0.4rem;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(28, scaleRatio, imageWidth)}px !important;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(28, scaleRatio, imageWidth)}px !important;
  }
  & hr{
    border-color: transparent !important;
  }
  & div{
    color: #236BA0 !important;
    font-weight: 600;
    height: ${({ height }) => height}px !important;
    max-width: 100% !important;
  }
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 0.2rem;
  align-items: flex-start;
  width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
  height: 100%;

  & > .indicator {
    border-radius: 50%;
    position: relative;
    top: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(4, scaleRatio, imageWidth)}px;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px !important;
    background-color: ${props => props.recipientColor};
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

export default Dropdown;
