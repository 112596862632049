import React, { Component } from 'react';
import { toast } from 'react-toastify';

import { connect as ReduxConnect } from 'react-redux';
import Messages from '../../../constants/toastMessages';
import organizationsActions from '../../../actions/organization';
import graphqlClient from '../../../config/graphql';
import { SEND_OWNERSHIP_TRANSFER_INVITE } from './queries';

const renderWrappedComponent = WrappedComponent =>
  class extends Component {
    state = {
      loading: false,
    }

    sendOwnershipTransferInvite = async (orgSubdomain, variables) => {
      const {
        // eslint-disable-next-line react/prop-types
        onClickCancel, onSend, updateUserOrganization, organization,
      } = this.props;
      this.setState({ loading: true });
      try {
        await graphqlClient(orgSubdomain).mutate({
          mutation: SEND_OWNERSHIP_TRANSFER_INVITE,
          variables,
        });
        this.setState({ loading: false });
        await updateUserOrganization({ ...organization, ownership_transfer_request_exists: true });
        toast.success(Messages.organizations.invite.success);
        onSend();
        onClickCancel();
      } catch (e) {
        this.setState({ loading: false });
        toast.error(Messages.organizations.invite.error);
        throw e;
      }
    }

    render = () => (
      <WrappedComponent
        {...this.props}
        {...this.state}
        onClickSend={this.sendOwnershipTransferInvite}
      />
    );
  };

const mapDispatchToProps = () => {
  const {
    updateUserOrganization,
  } = organizationsActions;

  return {
    updateUserOrganization,
  };
};

const connect = WrappedComponent =>
  ReduxConnect(null, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
