import Apps from 'material-ui/svg-icons/navigation/apps';
import DataUsage from 'material-ui/svg-icons/device/data-usage';
import Group from 'material-ui/svg-icons/social/group';
import Send from 'material-ui/svg-icons/content/send';
import Forum from 'material-ui/svg-icons/communication/forum';
import Upload from 'material-ui/svg-icons/file/cloud-upload';
import Paste from 'material-ui/svg-icons/content/content-paste';
import Copy from 'material-ui/svg-icons/content/content-copy';
import List from 'material-ui/svg-icons/action/list';

import Types from '../constants/actionTypes';

const { SET_MENU, SET_SECTION_ITEM_VISIBLE, SET_COLLAPSE } = Types.sidenav;

/**
 * INITIAL_STATE includes all menu items that will be displayed on the side nav panel.
 *
 * @param {Object} INITIAL_STATE
 * @param {number} INITIAL_STATE.id
 * @param {Object[]} INITIAL_STATE.items
 * @param {number} INITIAL_STATE.items[].id
 * @param {string} INITIAL_STATE.items[].label
 * @param {string} INITIAL_STATE.items[].link
 * @param {boolean} INITIAL_STATE.items[].pro
 * @param {boolean} INITIAL_STATE.items[].isActive
 * @param {boolean} INITIAL_STATE.items[].oneCondition
 * @param {[string]} INITIAL_STATE.items[].showIf
 * @param {ReactNode} INITIAL_STATE.items[].icon
 * @param {Object[INITIAL_STATE.items]} INITIAL_STATE.items[].items[]
 *
 * To show/hide menu items you can use the `showIf` attribute.
 * Its value must be an array of strings or an array of arrays (of strings) recursively.
 * The string should be a valid predicate/getter otherways the condition will be
 * matched against `false` hence it won't be displayed.
 * 1. ['predicateFunction'] - the function should return true or false.
 * 2. ['getterFunction:value'] - the function's result will be compared against the value.
 * Also, notice all conditionals will be compared against `&&` unless you set the attribute
 * `oneCondition` to true, in that case, all conditionals will be compared against `||`.
 *
 * @see isVisible ../../components/Sidenav/SidenavSection.js
*/
const INITIAL_STATE = {
  sections: [{
    id: 0,
    items: [{
      id: 'dashboard',
      label: 'Dashboard',
      icon: Apps,
      link: '/dashboard',
    }, {
      id: 'document-manager',
      label: 'Document Manager',
      icon: Copy,
      link: '/documents-manager',
      items: [{
        id: 'send-document',
        label: 'Send Document',
        icon: Send,
        isActive: false,
        pro: true,
        link: '/send-document',
        indicatorType: 'sendDocument',
      }, {
        id: 'create-template',
        label: 'Create Template',
        icon: Paste,
        isActive: false,
        pro: true,
        link: '/documents-manager/templates/new',
        indicatorType: 'createTemplate',
      }, {
        id: 'fill-n-sign',
        label: 'Fill & Sign',
        icon: Forum,
        isActive: false,
        pro: true,
        link: '/fill-sign',
      }, {
        id: 'upload-n-store',
        label: 'Upload & Store',
        icon: Upload,
        isActive: false,
        pro: true,
        indicatorType: 'uploadAndStore',
        link: '/documents-manager/upload-and-archive',
      }],
    }, {
      id: 'analytics',
      label: 'Analytics',
      icon: DataUsage,
      link: '/analytics',
      oneCondition: true,
      showIf: ['organizationUserRole:owner', 'organizationUserRole:admin'],
    }, {
      id: 'organization',
      label: 'Organization',
      icon: Group,
      pro: true,
      link: '/organizations',
      oneCondition: true,
      showIf: ['haveAdminPermissions'],
      indicatorType: 'organizations',
    }, {
      id: 'platform-manager',
      label: 'Platform Manager',
      icon: List,
      link: '/platform-manager',
      showIf: ['userRole:super_admin'],
    }],
  }],
  collapsed: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MENU: {
      const { sections } = action;
      return {
        ...state,
        sections,
      };
    }
    case SET_SECTION_ITEM_VISIBLE: {
      const { sectionId, itemId, visible } = action;
      const { sections } = state;
      sections[sectionId.toString()].items[itemId].hidden = !visible;
      return {
        ...state,
        sections,
      };
    }
    case SET_COLLAPSE: {
      const { collapse } = action;
      return {
        ...state,
        collapsed: collapse,
      };
    }
    default:
      return state;
  }
};
