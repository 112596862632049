import api from './api';

const password = {
  generate: payload => api.post('/client_app/auth/registrations', payload),
  load: organizationUuid => api.get('/client_app/auth/registrations', {
    params: {
      organization_uuid: organizationUuid || undefined,
    },
  }),
  delete: id => api.delete(`/client_app/auth/registrations/${id}`),
  regenerate: lastKey => api.put(`/client_app/auth/registrations/${lastKey}/refresh_secret`),
  update: (id, payload) => api.put(`/client_app/auth/registrations/${id}`, { client_app: payload }),
};

export default password;

