import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';

import SuccessBaseScreen from '../SuccessBaseScreen';
import history from '../../config/history';
import connect from './connect';

const SuccessScreen = ({ onClose, auth }) => {
  const userUuid = get(auth, 'user.uuid');

  useEffect(() => {
    if (!userUuid) return;
    history.push('/');
  }, []);

  return (
    <SuccessBaseScreen
      title="Success!"
      description="Check your email to confirm your account"
      buttonLabel="Close"
      onButtonClick={onClose}
    />
  );
};

SuccessScreen.propTypes = {
  auth: PropTypes.object,
  onClose: PropTypes.func,
};

SuccessScreen.defaultProps = {
  auth: {},
  onClose: () => {},
};

export const Logo = styled.img`
  width: 230px;
  height: auto;
  margin-bottom: 0.375rem;
  align-self: center;
`;

export default connect(SuccessScreen);
