import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

import DeleteIcon from 'material-ui/svg-icons/action/delete';
import RequiredIcon from 'material-ui/svg-icons/action/lock';
import UnrequiredIcon from 'material-ui/svg-icons/action/lock-open';
import { Menu, MenuItem } from 'material-ui';

import { DragItemTypes } from '../../constants';
import colors from '../../utils/colors';
import {
  RadioButton, Checkbox, DefaultField, Dropdown,
  AttachFile, Button, Stamp, ParticipationStamp,
} from './SpecialFields';


// DnD Spec
const dragSourceSpec = {
  canDrag(props) {
    return props.canDrag;
  },
  beginDrag(props) {
    return { id: props.field.id, width: props.field.width, height: props.field.height };
  },
};

// DnD Collector
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const menuStyle = {
  position: 'fixed',
  background: colors.backgroundGray,
};

export class PageField extends React.Component {
  static renderDropdownField = (
    field, onResizeStart, onResizeStop,
    scaleRatio, imageWidth, onClick, recipientsList,
  ) => (
    <Dropdown
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      field={field}
      onClick={onClick}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      recipients={recipientsList}
    />
  );

  static renderCheckboxField = (field, scaleRatio, imageWidth, onClick, recipientsList) => (
    <Checkbox
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      field={field}
      onClick={onClick}
      recipients={recipientsList}
    />
  );

  static renderButtonField = (
    field, onResizeStart, onResizeStop,
    scaleRatio, imageWidth, onClick, recipientsList,
  ) => (
    <Button
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      field={field}
      onClick={onClick}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      recipients={recipientsList}
    />
  );

  static renderStampField = (
    field, onResizeStart, onResizeStop,
    scaleRatio, imageWidth, onClick, recipientsList,
  ) => (
    <Stamp
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      field={field}
      onClick={onClick}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      recipients={recipientsList}
    />
  );

  static renderParticipationStampField = (
    field, onResizeStart, onResizeStop,
    scaleRatio, imageWidth, onClick, recipientsList,
  ) => (
    <ParticipationStamp
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      field={field}
      onClick={onClick}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      recipients={recipientsList}
    />
  );

  static renderRadioButtonField = (field, scaleRatio, imageWidth, onClick, recipientsList) => (
    <RadioButton
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      field={field}
      onClick={onClick}
      recipients={recipientsList}
    />
  );

  static renderAttachFileField = (
    field, onResizeStart, onResizeStop,
    scaleRatio, imageWidth, onClick, recipientsList,
  ) => (
    <AttachFile
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      field={field}
      onClick={onClick}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      recipients={recipientsList}
    />
  )

  static renderDefaultField = (
    field, onResizeStart, onResizeStop,
    scaleRatio, imageWidth, onClick, recipientsList,
  ) => (
    <DefaultField
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      field={field}
      onClick={onClick}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      recipients={recipientsList}
    />
  );

  static renderField = (...propsWithResize) => {
    const [field, ...props] = propsWithResize;
    const propsWithoutResize = [field, ...props.slice(2)];

    if (field.field_type === 'select') {
      return PageField.renderDropdownField(...propsWithResize);
    } else if (field.field_type === 'checkbox') {
      return PageField.renderCheckboxField(...propsWithoutResize);
    } else if (field.field_type === 'radio') {
      return PageField.renderRadioButtonField(...propsWithoutResize);
    } else if (field.name.includes('Approve') || field.name.includes('Decline')) {
      return PageField.renderButtonField(...propsWithResize);
    } else if (field.name.includes('Attachment')) {
      return PageField.renderAttachFileField(...propsWithResize);
    } else if (field.name.includes('Participation Stamp')) {
      return PageField.renderParticipationStampField(...propsWithResize);
    } else if (field.name.includes('Stamp')) {
      return PageField.renderStampField(...propsWithResize);
    }
    return PageField.renderDefaultField(...propsWithResize);
  };


  state = {
    contextMenu: { open: false, left: 0, top: 0 },
  };

  componentDidMount() {
    window.addEventListener('scroll', () => this.closeContextMenu());
    window.addEventListener('click', () => this.closeContextMenu());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.closeContextMenu());
    window.removeEventListener('click', () => this.closeContextMenu());
  }

  onResize = (data) => {
    const { size: { width, height } } = data;
    const { onResize, onResizeHandlePress } = this.props;

    onResize({ ...this.props.field, width, height });
    onResizeHandlePress(true);
  };

  onClickMenuItem = (action) => {
    const { field, onFieldChange, onRemoveField } = this.props;
    switch (action) {
      case 'required': {
        const required = (field.required === undefined) ? true : !field.required;
        onFieldChange({ ...field, required });
        break;
      }
      case 'delete': {
        onRemoveField(field);
        break;
      }

      default: {
        break;
      }
    }
  }

  onResizeStart = () => { this.props.onResizeHandlePress(false); };

  onResizeStop = (e, data) => this.onResize(data)

  getContainerStyle = props => ({
    position: 'absolute',
    left: props.x,
    top: props.y,
  })

  openContextMenu = (e) => {
    this.setState({
      contextMenu: {
        left: e.clientX,
        top: e.clientY,
        open: true,
      },
    });
    e.preventDefault();
  };

  closeContextMenu = () => {
    this.setState({
      contextMenu: {
        left: 0,
        top: 0,
        open: false,
      },
    });
  };


  withSettings = field => field.name.includes('Dropdown');

  render() {
    const {
      connectDragSource,
      isDragging,
      canDrag,
      focused,
      field,
      scaleRatio = 1,
      onClick,
      documentDimensions,
      imageWidth,
      recipients,
    } = this.props;

    const { contextMenu: { open, left, top } } = this.state;
    return connectDragSource((
      <div
        style={this.getContainerStyle(this.props.field)}
        placeholder={{ isDragging, canDrag, focused }}
        onContextMenu={this.openContextMenu}
      >
        {PageField.renderField(
          field, this.onResizeStart, this.onResizeStop,
          scaleRatio, (imageWidth || documentDimensions.IMAGE_WIDTH), onClick, recipients,
        )}
        {
          open &&
          <Menu
            style={{ ...menuStyle, left, top }}
          >
            <MenuItem
              leftIcon={field.required ? <UnrequiredIcon /> : <RequiredIcon />}
              onClick={() => { this.onClickMenuItem('required'); }}
              primaryText={field.required ? 'Don\'t Require' : 'Require'}
            />
            <MenuItem leftIcon={<DeleteIcon />} onClick={() => { this.onClickMenuItem('delete'); }} primaryText="Remove" />
          </Menu>
        }

      </div>
    ));
  }
}

PageField.propTypes = {
  field: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  focused: PropTypes.bool,
  canDrag: PropTypes.bool.isRequired,
  onResizeHandlePress: PropTypes.func,
  onResize: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  recipients: PropTypes.any.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

PageField.defaultProps = {
  onResizeHandlePress: () => {},
  onResize: () => {},
  focused: false,
};

export default DragSource(
  DragItemTypes.PAGE_FIELD,
  dragSourceSpec,
  collect,
)(PageField);
