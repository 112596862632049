import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropTarget } from 'react-dnd';
import { getInitialFieldDimensions } from '../../constants/fsFieldsInitialsDimensions';
import { DragItemTypes } from '../../constants';
import TemplateField from './TemplateField';
import { unscalePageField } from '../../utils/scaling';

// DnD Spec
const dropTargetSpec = {
  drop(props, monitor, component) {
    const itemType = monitor.getItemType();
    const { pageNumber, scaleRatio, standardFields } = props;
    const item = monitor.getItem();
    const offset = monitor.getSourceClientOffset();
    const rect = component.ref.getBoundingClientRect();
    const x = (offset.x - rect.left);
    const y = (offset.y - rect.top);
    const { type } = item;

    const gefFieldIdByName = name => standardFields.find(f => f.name === name).id;

    const fieldIds = {
      input: gefFieldIdByName('Text'),
      signature: gefFieldIdByName('Signature'),
      initials: gefFieldIdByName('Initial'),
      date_picker: gefFieldIdByName('Date Signed'),
      cross: gefFieldIdByName('Cross'),
      check: gefFieldIdByName('Check'),
      dot: gefFieldIdByName('Dot'),
      circle: gefFieldIdByName('Circle'),
      line: gefFieldIdByName('Line'),
    };

    const field = getInitialFieldDimensions({
      type,
      field_id: fieldIds[type],
      pageNumber,
    });

    switch (itemType) {
      case DragItemTypes.FIELDS_PANEL_OPTION:
        props.addTemplateField(unscalePageField({
          ...field,
          width: field.width * scaleRatio,
          height: field.height * scaleRatio,
          type,
          x,
          y,
        }, scaleRatio));
        break;
      case DragItemTypes.PAGE_FIELD:
        props.updateTemplateField(unscalePageField({
          ...item,
          width: item.width * scaleRatio,
          height: item.height * scaleRatio,
          x,
          y,
          pageNumber,
        }, scaleRatio));
        break;

      default:
        return null;
    }

    return undefined;
  },
};

// Dnd Collector
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class TemplateFields extends React.Component {
  renderFields(scaleRatio) {
    const { fields, pageNumber, auth } = this.props;

    return fields
      .filter(i => i.pageNumber === pageNumber)
      .map(field => (
        <TemplateField
          auth={auth}
          key={field.id || Math.random().toString()}
          field={field}
          drag={this.props.drag}
          pageNumber={this.props.pageNumber}
          updateTemplateField={this.props.updateTemplateField}
          removeTemplateField={this.props.removeTemplateField}
          updateTemplatePress={drag => this.props.updateTemplatePress(drag)}
          scaleRatio={scaleRatio}
        />
      ));
  }

  render() {
    const { connectDropTarget, isOver, scaleRatio } = this.props;
    return connectDropTarget((
      <div>
        <Container isOver={isOver} innerRef={(ref) => { this.ref = ref; return true; }}>
          {this.renderFields(scaleRatio)}
        </Container>
      </div>
    ));
  }
}

TemplateFields.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  drag: PropTypes.bool.isRequired,
  updateTemplateField: PropTypes.func.isRequired,
  removeTemplateField: PropTypes.func.isRequired,
  updateTemplatePress: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  standardFields: PropTypes.array.isRequired,
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, ${props => (props.isOver ? '0.5' : '0')});
  transition: all 0.3s ease-in-out;
`;

export default DropTarget(
  [DragItemTypes.PAGE_FIELD, DragItemTypes.FIELDS_PANEL_OPTION],
  dropTargetSpec,
  collect,
)(TemplateFields);

