import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { has } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { FlatButton, RaisedButton } from 'material-ui';

import ReduxTextField from './ReduxTextField';
import { history } from '../../config';
import { parserQueryString } from '../../utils';
import { SmallButtonContainer } from './ForgotPasswordForm';

const validate = (values) => {
  const errors = {};

  if (values.password === undefined || values.password === '') {
    errors.password = 'The Password field is required.';
  }

  if (values.password_confirmation === undefined || values.password_confirmation === '') {
    errors.password_confirmation = 'The Confirm Password field is required.';
  }

  if (has(values, 'password') && has(values, 'password_confirmation')) {
    if (values.password !== values.password_confirmation) {
      errors.password_confirmation = 'The passwords need be equals';
    }
  }

  return errors;
};

const submitForm = (handleSubmit) => {
  const headers = parserQueryString(window.location.search);
  handleSubmit(headers);
};

const ForgotPasswordForm = ({ valid, handleSubmit }) => (
  <form>
    <Field
      name="password"
      type="password"
      component={ReduxTextField}
      className="mb-2"
      fullWidth
      floatingLabelText="Enter a new password"
      autoComplete="password"
    />
    <Field
      name="password_confirmation"
      type="password"
      component={ReduxTextField}
      className="mb-2"
      fullWidth
      floatingLabelText="Confirm password*"
      autoComplete="password"
      required
      errorStyle={{ fontSize: '10px', marginBottom: '10px' }}
    />
    <ButtonsSection className="d-flex justify-content-between">
      <SmallButtonContainer>
        <FlatButton
          label="CANCEL"
          fullWidth
          primary
          onClick={() => history.push('/login')}
          labelStyle={{ padding: 0 }}
        />
      </SmallButtonContainer>
      <RaisedButton
        label="SUBMIT"
        disabled={!valid}
        primary={valid}
        onClick={() => submitForm(handleSubmit)}
      />
    </ButtonsSection>
  </form>
);

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
};

ForgotPasswordForm.defaultProps = {
  valid: false,
};

const ButtonsSection = styled.div`
  padding-top: 2rem;
  width: 100%;
`;

const LoginReduxForm = reduxForm({
  form: 'recoveryPassword',
  validate,
})(ForgotPasswordForm);

export default LoginReduxForm;

