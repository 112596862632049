import React from 'react';
import PropTypes from 'prop-types';
import { List, Subheader } from 'material-ui';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';

import SidenavSectionItem from './SidenavSectionItem';
import { colors } from '../../utils';

const SidenavSection = (props) => {
  const {
    collapsed, section, currentOrganization: org, auth: { user },
  } = props;
  const { label } = section;

  const isVisible = ({ showIf = [], oneCondition = false }) => {
    const fns = {
      userRole: () => get(user, 'roles[0].name', ''),
      organizationUserRole: () => {
        const orgUser = get(org, 'users', []).find(u => u.uuid === user.uuid);
        return get(orgUser, 'role', '');
      },
      canHaveOrganizations: () => {
        const organizationsLimit = get(user, 'planUsage.organizationsLimit', 0);
        const organizationsInUse = get(user, 'planUsage.organizationsInUse', 0);

        // If the organizationsLimit is null means that the user can have unlimited organizations
        return organizationsLimit === null || organizationsInUse < organizationsLimit;
      },
      haveAdminPermissions: () => {
        const orgUser = get(org, 'users', []).find(u => u.uuid === user.uuid);
        const userRole = get(orgUser, 'role', '');
        return isEmpty(org) || (['admin', 'owner'].includes(userRole));
      },
    };

    const validate = (v) => {
      const [key, value = true] = v.split(':');
      const fn = fns[key] || (() => false);
      return fn() === value;
    };

    return oneCondition
      ? showIf.some(validate)
      : showIf.every(validate);
  };

  const getVisibleItems = (menuItems = []) => {
    const [head, ...tail] = menuItems;

    return menuItems.length === 0 ? [] : []
      .concat(isVisible(head) ? { ...head, items: getVisibleItems(head.items) } : [])
      .concat(getVisibleItems(tail));
  };

  return (
    <List>
      {label && (
        <Subheader style={{ color: colors.yellow }}>
          {label.toUpperCase()}
        </Subheader>
      )}
      {getVisibleItems(section.items).map(sectionItem => (
        <SidenavSectionItem
          {...props}
          key={sectionItem.id}
          collapsed={collapsed}
          sectionItem={sectionItem}
        />
      ))}
    </List>
  );
};

SidenavSection.propTypes = {
  currentOrganization: PropTypes.object,
  auth: PropTypes.object.isRequired,
  section: PropTypes.object,
  collapsed: PropTypes.bool,
};

SidenavSection.defaultProps = {
  currentOrganization: {},
  section: {},
  collapsed: false,
};

export default connect(state => ({
  ...state,
  currentOrganization: state.organizations.currentOrganization || {},
}), null)(SidenavSection);
