import React from 'react';
import { startCase, toLower } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { dateFormats } from '../../utils';
import HistoryStartImg from '../../assets/images/history-start.svg';
import HistoryTrackImg from '../../assets/images/history-track.svg';
import HistoryTrackLongImg from '../../assets/images/history-track-long.svg';

const DocumentHistorySection = (props) => {
  const {
    selectedDocument: { history },
  } = props;

  return (
    <Wrapper>
      <HistoryStart src={HistoryStartImg} />
      {history.map((entry, index) => {
        const keys = Object.keys(entry);
        return (
          <HistorySection>
            {index === 0 ? <HistoryTrack src={HistoryTrackImg} /> :
            <HistoryTrackLong src={HistoryTrackLongImg} />}
            <HistoryText>{`${startCase(toLower(keys[0]))} on ${dateFormats.shortDate(entry[keys[0]])}`}</HistoryText>
          </HistorySection>
        );
      })}
    </Wrapper>
  );
};

DocumentHistorySection.propTypes = {
  selectedDocument: PropTypes.object.isRequired,
};

const Wrapper = styled.div`
  margin-top: 40px;
  margin-left: 25px;
`;

const HistoryStart = styled.img`
  height: 22px;
  margin-left: 5px;
  margin-bottom: 3px;
`;

const HistoryTrack = styled.img`
  height: 35px;
  margin-left: 6px;
`;
const HistoryTrackLong = styled.img`
  height: 45px;
  margin-left: 6px;
  margin-top: -2px;
`;

const HistorySection = styled.div`
  position: relative;
  margin-left: -7px;
`;

const HistoryText = styled.div`
  position: absolute;
  bottom: -3px;
  left: 30px;
  font-size: 14px;
`;

export default DocumentHistorySection;
