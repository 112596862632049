import React from 'react';

import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { colors } from '../../utils';

const LineHorizontalChart = ({ data, XAxisName, lineName }) => (
  <ResponsiveContainer minWidth={300}>
    <LineChart
      width={400}
      height={200}
      data={data}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <XAxis dataKey={XAxisName} />
      <YAxis allowDecimals={false} />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />
      <Line type="monotone" strokeWidth={3} dataKey={lineName} stroke={colors.blue} dot={{ fill: colors.blue }} activeDot={{ r: 8 }} />
    </LineChart>
  </ResponsiveContainer>
);

LineHorizontalChart.propTypes = {
  data: PropTypes.array.isRequired,
  lineName: PropTypes.string.isRequired,
  XAxisName: PropTypes.string.isRequired,
};

export default LineHorizontalChart;
