import { isEmpty } from 'lodash';

export const getPages = (documents) => {
  if (isEmpty(documents)) return [];

  let pages = [];

  documents.forEach((document) => {
    if (document.pages) {
      pages = pages
        .concat(document.pages.map(page => ({
          ...page,
          documentUUID: document.uuid,
        })));
    } else {
      pages = pages
        .concat(document.page_ids.map(pageId => ({
          id: pageId,
          documentUUID: document.uuid,
        })));
    }
  });

  return pages;
};

export default {
  getPages,
};
