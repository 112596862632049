import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

const renderOption = recipient => (
  <OptionContainer>
    <div className="indicatior_container">
      <Indicator color={recipient.color} />
    </div>
    <div className="name">{recipient.name}</div>
  </OptionContainer>
);


const RecipientSelectField = ({
  enabled, value, onChange, recipients, fullWidth,
}) => (
  <SelectField
    fullWidth={fullWidth}
    disabled={!enabled}
    dropDownMenuProps={{
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    }}
    value={value}
    onChange={(event, index, val) => onChange(val)}
  >
    {
      recipients && recipients.length > 0 &&
        recipients.map(recipient => (
          <MenuItem
            key={recipient.id}
            value={recipient.value}
            primaryText={renderOption(recipient)}
          />
        ))
    }
  </SelectField>
);

RecipientSelectField.propTypes = {
  enabled: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  recipients: PropTypes.array,
  fullWidth: PropTypes.bool,
};

RecipientSelectField.defaultProps = {
  enabled: false,
  value: {},
  onChange: () => {},
  fullWidth: false,
  recipients: [],
};

const OptionContainer = styled.div`
  display: flex;
  width: 100%;

  & > .name {
    display: flex;
    flex: 4;
    justify-content: flex-start;
    align-items: flext-start;
  }

  & > .indicatior_container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #8E8E8E;
    font-size: 12px;
    font-family: Avenir;
  }
`;

const Indicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.color || 'gray'};
  border-radius: 50%;
`;
export default RecipientSelectField;
