import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const DocumentNameIndicator = ({
  documents, currentPage,
}) => {
  const [currentDocument, setCurrentDocument] = useState({ uuid: '' });
  const [show, setShow] = useState(false);
  useEffect(() => {
    if ((currentPage && !currentDocument) ||
    (currentDocument && currentPage.documentUUID !== currentDocument.uuid)) {
      // eslint-disable-next-line react/prop-types
      const doc = documents.find(d => d.uuid === currentPage.documentUUID);
      if (doc) {
        setCurrentDocument(doc);
        setShow(true);
        setTimeout(() => setShow(false), 1500);
      }
    }
  });

  return (
    <Container className={show ? 'appear' : 'disappear'}>
      {currentDocument.file_name}
    </Container>
  );
};

DocumentNameIndicator.propTypes = {
  currentPage: PropTypes.object,
  documents: PropTypes.array,
};

DocumentNameIndicator.defaultProps = {
  currentPage: {},
  documents: [],
};

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const disappear = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Container = styled.div`
  width: auto;
  background-color: #DADADA;
  color: #696969;
  border-radius: 2px;
  display: inline-block;
  font-weight: 600;
  font-size: 0.9rem;
  font-family: DIN-Medium;
  padding: 0.2rem 1rem;
  opacity: 0;

  &.appear{
    animation: ${appear} 1s linear;
    animation-fill-mode: forwards;         
  }

  &.disappear{
    animation: ${disappear} 1s linear;
    animation-fill-mode: forwards;    
  }
`;

export default DocumentNameIndicator;
