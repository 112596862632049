import gql from 'graphql-tag';

export const FETCH_ARCHIVED_DOCUMENTS = gql`
  query getArchivedDocuments(
    $query: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sortColumn: UserSortColumnsEnum
    $sortOrder: SortOrderEnum
  ) {
    archivedDocuments(
      query: $query
      after: $after
      before: $before
      first: $first
      last: $last
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      nodes {
        uuid
        company
        notes {
          nodes {
            uuid
            text
            createdAt
            updatedAt
          }
        }
        fileName
        firstName
        lastName
        user {
          email
          firstName
          lastName
        }
        previewImage {
          url
        }
        signedDate
        file {
          url
        }
        activityLogs {
          nodes {
            event
            eventDate
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalPageCount
      totalCount
    }
  }
`;

export default {
  FETCH_ARCHIVED_DOCUMENTS,
};
