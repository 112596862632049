import { isEmpty, startCase } from 'lodash';

const getEmailsFromRecipients = (recipients) => {
  if (recipients && !isEmpty(recipients)) {
    return recipients.map((recipient, index) =>
      `${recipient.email}${index !== recipients.length - 1 ? ',' : ''}`).join(' ');
  }
  return '';
};

const getHistoryByEventName = (history, eventName) => {
  let value = null;
  if (!history || isEmpty(history)) {
    return value;
  }
  history.forEach((event) => {
    const keys = Object.keys(event);
    keys.forEach((key) => {
      if (key === eventName) {
        value = event[eventName];
      }
    });
  });
  return value;
};

const getStatus = (history) => {
  if (!history || isEmpty(history)) {
    return null;
  }
  const keys = Object.keys(history[0]);
  return startCase(keys[0]);
};

const parseSignatureRequest = (signatureRequest) => {
  const {
    subject,
    recipients,
    history,
    notes,
    uuid,
    sender,
    preview_url: previewUrl,
    reminders,
  } = signatureRequest;
  return {
    history,
    name: subject,
    recipients,
    date: getHistoryByEventName(history, 'created') || getHistoryByEventName(history, 'create'),
    subject,
    notes: notes || '',
    uuid,
    from: sender,
    to: getEmailsFromRecipients(recipients),
    previewUrl,
    reminders,
    status: getStatus(history),
  };
};

const parseCompletedDocuments = (signedDocuments, currentOrganization, user) =>
  signedDocuments.map((signDocument) => {
    const {
      history,
      notes,
      uuid,
      file_name: fileName,
      created_at: date,
      document,
      signed_pdf_url: fileUrl,
      recipients,
    } = signDocument;
    return {
      ...parseSignatureRequest(signDocument, currentOrganization, user),
      history: history || [],
      notes: notes || '',
      uuid,
      name: fileName,
      date,
      previewUrl: document && document.document_preview_url,
      fileUrl,
      status: getStatus(history),
      type: 'completed',
      share: true,
      emails: recipients,
    };
  });

const parseArchivedDocuments = archivedDocuments => archivedDocuments.map((archivedDocument) => {
  const {
    file_name, file_url, history, notes, uuid, preview_url: previewUrl, signed_date,
  } = archivedDocument;
  return {
    history,
    notes: notes || '',
    uuid,
    type: 'archived',
    name: !isEmpty(file_name) ? file_name : undefined,
    previewUrl,
    // eslint-disable-next-line no-undef
    fileUrl: !isEmpty(file_url) ? file_url : undefined,
    share: true,
    status: getStatus(history),
    date: signed_date,
  };
});

const parseOutForSignatureDocuments = (outForSignatureRequests, currentOrganization, user) =>
  outForSignatureRequests.map(outForSignatureRequest => ({
    ...parseSignatureRequest(outForSignatureRequest, currentOrganization, user),
    sendReminder: true,
    type: 'outForSignature',
  }));

const parseWaitingForMeDocuments = (waitingForMeSignatureRequests, currentOrganization, user) =>
  waitingForMeSignatureRequests.map(outForSignatureRequest => ({
    ...parseSignatureRequest(outForSignatureRequest, currentOrganization, user),
    type: 'waitingForMe',
  }));

const parseTemplates = templates => templates.map((template) => {
  const {
    history,
    notes,
    uuid,
    name,
    created_at,
    recipients,
    description,
    documents,
  } = template;
  return {
    name,
    history,
    description,
    notes: notes || '',
    uuid,
    recipients,
    date: created_at,
    type: 'template',
    previewUrl: !isEmpty(documents) ? documents[0].document_preview_url : undefined,
    status: getStatus(history),
  };
});


export default {
  parseArchivedDocuments,
  parseCompletedDocuments,
  parseOutForSignatureDocuments,
  parseWaitingForMeDocuments,
  parseTemplates,
  getEmailsFromRecipients,
};
