import gql from 'graphql-tag';

export const SEND_SIGNATURE_REQUEST_COPY = gql`
mutation sendSignatureRequestCopy(
  $uuid: ID!,
  $brandUuid: ID,
  $recipients: [String!]!,
  $ccMe: Boolean!,
  $subject: String!,
  $message: String!
  ){
  sendSignatureRequestCopy(
    uuid: $uuid,
    brandUuid: $brandUuid,
    recipients: $recipients,
    ccMe: $ccMe,
    subject: $subject,
    message: $message,
  ){
    errors
    success
  }
}
`;

export default {
  SEND_SIGNATURE_REQUEST_COPY,
};
