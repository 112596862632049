import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { colors } from '../../utils';

const BarVerticalChart = ({ data, lineName, XAxisName }) => (
  <Container>
    <ResponsiveContainer minWidth={400}>
      <BarChart
        data={data}
        margin={{
          top: 20, right: 20, left: 20, bottom: 5,
        }}
        barSize={40}
      >
        <CartesianGrid />
        <XAxis dataKey={XAxisName} />
        <YAxis allowDecimals={false} yAxisId="left" orientation="left" stroke={colors.gray} />
        <Tooltip />
        <Legend />
        <Bar yAxisId="left" dataKey={lineName} fill="#3596F3" />
      </BarChart>
    </ResponsiveContainer>
    <div className="legend">Time to Complete</div>
  </Container>
);

const Container = styled.div`
width: 100%;
height: 100%;
margin-bottom: 2rem;
 & g > text{
   fill: rgba(0,0,0,0.54);
   font-size: 10px;
 }
 & > .legend {
   width: 100%;
   text-align: center;
   color: rgba(0,0,0,0.54);
   position: relative;
   bottom: 1rem;
   margin: 1rem;
 }

 & .recharts-default-legend{
   display: none;
 }
`;

BarVerticalChart.propTypes = {
  data: PropTypes.array.isRequired,
  lineName: PropTypes.string.isRequired,
  XAxisName: PropTypes.string.isRequired,
};

export default BarVerticalChart;
