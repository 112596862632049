import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import { UpdateCreditCardForm } from '../../forms';

const UpdateCreditCardModal = ({ onUpdate, onCancel }) => (
  <Dialog
    open
    modal
    contentStyle={{ width: '1250px' }}
    autoScrollBodyContent
  >
    <UpdateCreditCardForm
      onUpdate={onUpdate}
      onCancel={onCancel}
    />
  </Dialog>
);

UpdateCreditCardModal.propTypes = {
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
};

UpdateCreditCardModal.defaultProps = {
  onUpdate: () => {},
  onCancel: () => {},
};

export default UpdateCreditCardModal;
