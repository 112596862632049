import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import connect from './connect';
import DocumentsEditor from '../../components/editor/DocumentsEditor';
import { getErrorMessage } from '../../utils/error';

class DocumentEditorScreen extends Component {
  state = {
    saving: false,
  }

  async componentDidMount() {
    const {
      fetchFields,
      loadEditorDataFromSignatureRequest,
      disableOrganizationChange,
      sendDocumentScreen: { recipients, documents },
      setEditorField,
      editor: { pageFields },
    } = this.props;

    disableOrganizationChange();
    await fetchFields();

    await setEditorField('pageFields', pageFields.map(pf => ({
      ...pf,
      recipient: pf.recipient || recipients.filter(r => !isEmpty(r) && r.role)[pf.recipientIndex],
    })));
    loadEditorDataFromSignatureRequest({
      recipients: recipients.map(r => ({ ...r, uuid: r.email })),
      documents,
    });
  }

  componentWillUnmount() {
    const { enableOrganizationChange } = this.props;
    enableOrganizationChange();
  }


  isSignatureRequestAlreadySent = (signatureRequest) => {
    if (!signatureRequest || isEmpty(signatureRequest)) {
      return false;
    }
    const { history } = signatureRequest;
    let alreadySent = false;
    if (history && !isEmpty(history)) {
      history.forEach((event) => {
        const eventNames = Object.keys(event);
        if (eventNames.includes('sent')) {
          alreadySent = true;
        }
      });
    }
    return alreadySent;
  }

  saveDocument = async () => {
    const {
      // eslint-disable-next-line react/prop-types
      savePageFields,
    } = this.props;
    try {
      this.setState({ saving: true });
      await savePageFields();
    } catch (e) {
      this.setState({ saving: false });
      toast.error(getErrorMessage(e));
    }
  };

  render() {
    const {
      standardFields,
      customFields,
      documentEditorScreen: { loading },
      editor: {
        documents,
        loading: editorLoading,
        pageFields,
        scaleRatio,
        focusedPageField,
        canDragFields,
        imageWidth,
        recipients,
        currentEditorPage,
      },
      setEditorFocusedPageField,
      setCurrentEditorPage,
      addEditorPageField,
      updateEditorPageField,
      toggleEditorDragging,
      removePageFieldFromEditorPage,
      setEditorScaleRatio,
      changeCustomFormFieldValue,
      documentDimensions,
    } = this.props;
    const { saving } = this.state;
    return (
      <DocumentsEditor
        title="Send Document"
        onSave={this.saveDocument}
        currentEditorPage={currentEditorPage}
        setCurrentEditorPage={setCurrentEditorPage}
        saveButtonLabel="Send"
        documentDimensions={documentDimensions}
        saveDisabled={saving}
        documents={documents}
        standardFields={standardFields}
        customFields={customFields}
        loading={loading.fetchDocumentEditorSignatureRequest
        || editorLoading.loadEditorDataFromTemplate}
        pageFields={pageFields}
        recipients={recipients}
        openFieldPageOptionModal={() => {}}
        onResizeHandlePress={toggleEditorDragging}
        onRemovePageField={removePageFieldFromEditorPage}
        onClickPageField={async (fPageField) => {
          await setTimeout(() => {
            setEditorFocusedPageField(fPageField);
          }, 100);
        }}
        onChangePageField={updateEditorPageField}
        onAddPageField={addEditorPageField}
        focusedPageField={focusedPageField}
        canDragFields={canDragFields}
        imageWidth={imageWidth}
        scaleRatio={scaleRatio}
        setScaleRatio={setEditorScaleRatio}
        setEditorFocusedPageField={setEditorFocusedPageField}
        changeCustomFormFieldValue={changeCustomFormFieldValue}
        onClickFieldsLayer={() => { setEditorFocusedPageField(null); }}
      />
    );
  }
}

DocumentEditorScreen.propTypes = {
  standardFields: PropTypes.array.isRequired,
  customFields: PropTypes.array.isRequired,
  documentEditorScreen: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  sendDocumentScreen: PropTypes.object.isRequired,
  setEditorFocusedPageField: PropTypes.func.isRequired,
  addEditorPageField: PropTypes.func.isRequired,
  updateEditorPageField: PropTypes.func.isRequired,
  toggleEditorDragging: PropTypes.func.isRequired,
  removePageFieldFromEditorPage: PropTypes.func.isRequired,
  setEditorField: PropTypes.func.isRequired,
  setEditorScaleRatio: PropTypes.func.isRequired,
  changeCustomFormFieldValue: PropTypes.func.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
  disableOrganizationChange: PropTypes.func.isRequired,
  setCurrentEditorPage: PropTypes.func.isRequired,
  loadEditorDataFromSignatureRequest: PropTypes.func.isRequired,
  fetchFields: PropTypes.func.isRequired,
  savePageFields: PropTypes.func.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

export default connect(DocumentEditorScreen);
