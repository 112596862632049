export const getEntityType = (type) => {
  switch (type) {
    case 'outForSignature': {
      return 'SIGNATURE_REQUEST';
    }
    case 'archived': {
      return 'ARCHIVED_DOCUMENT';
    }
    case 'template': {
      return 'TEMPLATE';
    }
    case 'signed': {
      return 'SIGNED_DOCUMENT';
    }
    default:
      return '';
  }
};

export default getEntityType;
