import gql from 'graphql-tag';

export const ENVELOPES_DETAIL_REPORT = gql`
  query EnvelopesDetailReport(
    $startDate: IsoTime!,
    $endDate: IsoTime!,
  ){
      envelopesDetailReport(
        startDate: $startDate,
        endDate: $endDate,
      )
        {
      nodes {
        completedOn
        completionTime
        lastActivity
        recipients
        senderName
        sentOn
        state
        subject
      }
    }
  }
`;

export const DOWNLOAD_REPORT = gql`
  mutation DownloadReport(
    $startDate: IsoTime!,
    $endDate: IsoTime!,
    $reportType: ReportTypeEnum!,
  ){
    downloadReport(
        startDate: $startDate,
        endDate: $endDate,
        reportType: $reportType,
      )
        {
        downloadUrl
    }
  }
`;

export default {
  ENVELOPES_DETAIL_REPORT,
  DOWNLOAD_REPORT,
};
