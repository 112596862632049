import gql from 'graphql-tag';

export const RENEW_ENTERPRISE_PLAN = gql`
  mutation RenewEnterprisePlan{
    renewEnterprisePlan{
      subscription {
        billingCycleEnd
        billingCycleStart
        plan {
          active
          amount
          apiAccess
          currency
          interval
          name
          nickname
          planType
          uuid
        }
      }
    }
  }
`;

export default { RENEW_ENTERPRISE_PLAN };
