import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CircularProgress from 'material-ui/CircularProgress';

const LoaderContainer = ({ loader: { active } }) => (
  <React.Fragment>
    {active && <Container> <CircularProgress size={60} thickness={7} /> </Container>}
  </React.Fragment>
);

LoaderContainer.propTypes = { loader: PropTypes.object.isRequired };

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000000000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.4);
`;

const mapStateToProps = ({ loader }) => ({ loader });

export default connect(mapStateToProps)(LoaderContainer);

