import React, { Component } from 'react';
import { toast } from 'react-toastify';
import graphqlClient from '../../config/graphql';
import { UPDATE_USER_ROLE } from './mutations';
import Messages from '../../constants/toastMessages';
import history from '../../config/history';

const renderWrappedComponent = WrappedComponent =>
  class extends Component {
    updateUserRole = async (userUuid, role) => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization } = this.props;
      try {
        await graphqlClient(currentOrganization &&
          currentOrganization.subdomain).mutate({
          mutation: UPDATE_USER_ROLE,
          variables: { userUuid, role },
        });
        toast.success(Messages.users.updateRole.success);
        history.push('/organizations');
      } catch (e) {
        toast.error(Messages.users.updateRole.error);
        throw e;
      }
    }

    render = () => (<WrappedComponent
      {...this.props}
      updateUserRole={this.updateUserRole}
    />);
  };

const connect = WrappedComponent => renderWrappedComponent(WrappedComponent);

export default connect;
