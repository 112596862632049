import { isEmpty } from 'lodash';
import Types from '../constants/actionTypes';
import { getFieldName, getFieldToAdd } from '../utils/pageFields';
import { unscalePageField } from '../utils/scaling';
import editorParser from '../utils/parsers/editorParser';
import recipientsColors from '../constants/recipientsColors';
import documentService from '../services/document';

export const setEditorField = (name, value) => ({
  type: Types.editor.SET_FIELD,
  name,
  value,
});

export const resetEditor = () => ({
  type: Types.editor.RESET,
});

export const setEditorLoading = (action, loading) => ({
  type: Types.editor.SET_LOADING,
  loading,
  action,
});

export const setCurrentEditorPage = page => (dispatch) => {
  dispatch(setEditorField('currentEditorPage', page));
};

export const setEditorPageFields = pageFields => (dispatch, getState) => {
  const allFields = [...getState().fields.standardFields, ...getState().fields.customFields];
  const setFields = getState().editor.pageFields;
  const fieldsWithNames = setFields.concat(pageFields).map(field => ({
    ...field,
    name: getFieldName(field, allFields, pageFields),
    field_type: allFields.find(f => f.id === field.fieldType).input_type,
    selected_option: field.selected_option !== undefined &&
     field.options && field.options[field.selected_option],
  }));
  dispatch(setEditorField('pageFields', fieldsWithNames));
  dispatch(setEditorField('initialPageFields', fieldsWithNames));
};

export const resetEditorPageFields = () => (dispatch, getState) => {
  const values = getState().editor.initialPageFields;
  dispatch(setEditorField('pageFields', values));
  dispatch(setEditorField('focusedPageField', null));
};

export const setCurrentRecipient = recipientUuid => (dispatch, getState) => {
  const { recipients } = getState().editor;
  const currentRecipient = recipients.find(r => r.uuid === recipientUuid);
  dispatch(setEditorField('currentRecipient', currentRecipient));
};

export const setEditorRecipients = recipients => (dispatch) => {
  const recipientsList = recipients
    .reverse()
    .map((recipient, index) => ({
      ...recipient,
      color: recipientsColors[index],
    }));

  dispatch(setEditorField('recipients', recipientsList));
  dispatch(setEditorField('currentRecipient', recipientsList[0]));
};

export const setEditorDocuments = documents => (dispatch) => {
  dispatch(setEditorField('documents', documents));
};

export const loadEditorDataFromTemplate = template => async (dispatch) => {
  try {
    dispatch(setEditorLoading('loadEditorDataFromTemplate', true));
    dispatch(setEditorPageFields(editorParser.fromSignatureRequestToEditor.parseFields(template)));
    dispatch(setEditorRecipients(template.recipients));
    dispatch(setEditorDocuments(template.documents));
    dispatch(setEditorLoading('loadEditorDataFromTemplate', false));
    return Promise.resolve();
  } catch (error) {
    dispatch(setEditorLoading('loadEditorDataFromTemplate', false));
    return Promise.reject(error);
  }
};

export const initializePageFields = recipients => async (dispatch, getState) => {
  const { pageFields, documents } = getState().editor;
  const auxPageFields = pageFields.map((pageField) => {
    const currentRecipient = recipients.filter(r => !isEmpty(r)).find(r => r.email &&
        r.email === pageField.recipient.email);

    return ({
      ...pageField,
      recipientUuid: currentRecipient && currentRecipient.email,
      recipient: currentRecipient,
      documentUUID: Number.isInteger(pageField.docIndex) ?
        documents[pageField.docIndex].uuid : pageField.documentUUID,
      pageId: Number.isInteger(pageField.pageIdIndex) ?
        documents[pageField.docIndex].page_ids[pageField.pageIdIndex] : pageField.pageId,
    });
  });

  await dispatch(setEditorField('pageFields', auxPageFields));
  await dispatch(setEditorField('initialPageFields', auxPageFields));
};

export const loadEditorDataFromSignatureRequest = ({ recipients = [], documents = [] }) =>
  async (dispatch) => {
    try {
      dispatch(setEditorLoading('loadEditorFromSignatureRequest', true));
      await dispatch(setEditorRecipients(recipients));
      await dispatch(setEditorDocuments(documents));
      await dispatch(initializePageFields(recipients));

      dispatch(setEditorLoading('loadEditorFromSignatureRequest', false));
      return Promise.resolve();
    } catch (error) {
      dispatch(setEditorLoading('loadEditorFromSignatureRequest', false));
      return Promise.reject(error);
    }
  };

export const setEditorScaleRatio = (scaleRatio, imageWidth) => async (dispatch) => {
  dispatch({
    type: Types.editor.SETTING_SCALE_RATIO,
    settingScaleRatio: true,
  });
  dispatch({
    type: Types.editor.SET_SCALE_RATIO,
    scaleRatio,
    imageWidth,
  });
  dispatch({
    type: Types.editor.SETTING_SCALE_RATIO,
    settingScaleRatio: false,
  });
};

export const setEditorFocusedPageField = focusedPageField => async (dispatch) => {
  dispatch({
    type: Types.editor.SET_FOCUSED_PAGE_FIELD,
    focusedPageField,
  });
};

export const removeRecipientPageFields = recipientUuid => async (dispatch, getState) => {
  const { pageFields } = getState().editor;
  const newPageFields = pageFields.filter(pf => pf.recipientUuid !== recipientUuid);
  await dispatch(setEditorField('pageFields', newPageFields));
};

export const updateRecipientPageFields = (oldRecipientUuid, recipientUuid) =>
  async (dispatch, getState) => {
    const { pageFields } = getState().editor;
    const newPageFields = pageFields.map(f => ({
      ...f,
      recipientUuid: f.recipientUuid === oldRecipientUuid ? recipientUuid : f.recipientUuid,
      recipient: f.recipientUuid === oldRecipientUuid ?
        { ...f.recipient, email: recipientUuid, uuid: recipientUuid } : f.recipient,
    }));
    await dispatch(setEditorField('pageFields', newPageFields));
  };

export const addEditorPageField = (field, documentUUID, pageId, scaleRatio) => (
  dispatch,
  getState,
) => {
  const allFields = [...getState().fields.standardFields, ...getState().fields.customFields];
  const { pageFields, currentRecipient } = getState().editor;
  const auxField = {
    ...field,
    field_type: allFields.find(f => f.id === field.fieldType).input_type,
  };
  let fieldToAdd = getFieldToAdd(auxField, allFields, pageFields);
  fieldToAdd = unscalePageField(fieldToAdd, scaleRatio);

  const existSameField = pageFields.find(pField => pField.fieldType === fieldToAdd.fieldType &&
    pField.x === fieldToAdd.x && pField.y === fieldToAdd.y && pField.height === fieldToAdd.height &&
    pField.width === fieldToAdd.width && pField.pageId === pageId,
  );

  if (!existSameField) {
    dispatch({
      type: Types.editor.ADD_PAGE_FIELD,
      payload: {
        field: {
          ...fieldToAdd,
          required: fieldToAdd.name === 'Signature',
          field_type: auxField.field_type,
        },
        documentUUID,
        pageId,
        recipient: currentRecipient,
        recipientUuid: currentRecipient.uuid,
      },
    });
  }
};

export const updateEditorPageField = (field, pageId, scaleRatio, isChangeFieldType = false) => (
  dispatch, getState,
) => {
  const allFields = [...getState().fields.standardFields, ...getState().fields.customFields];
  const { pageFields, recipients } = getState().editor;
  dispatch({
    type: Types.editor.UPDATE_PAGE_FIELD,
    field: unscalePageField(
      isChangeFieldType ? getFieldToAdd(field, allFields, pageFields) : field, scaleRatio,
    ),
    recipient: recipients.find(r => field.recipientUuid === r.uuid),
    pageId,
  });
};

export const setFieldOnAllPages = field => (
  dispatch,
  getState,
) => {
  const { documents, scaleRatio } = getState().editor;
  documents.map(async (doc) => {
    // eslint-disable-next-line array-callback-return
    doc.page_ids.map((pageId) => {
      if (pageId !== field.pageId) {
        dispatch(
          addEditorPageField(
            { ...field, id: (new Date()).getTime(), isNew: true },
            doc.uuid,
            pageId,
            scaleRatio,
          ));
      }
    });
  });
};

export const copyField = field => (
  dispatch,
  getState,
) => {
  const { documents, scaleRatio } = getState().editor;
  const doc = documents.find(d => d.page_ids.includes(field.pageId));
  dispatch(
    addEditorPageField(
      {
        ...field,
        id: (new Date()).getTime(),
        isNew: true,
        y: field.y + 5,
        checked: field.field_type === 'radio' ? false : field.checked,
      },
      doc.uuid,
      field.pageId,
      scaleRatio,
    ));

  dispatch({
    type: Types.editor.SET_FOCUSED_PAGE_FIELD,
    focusedPageField: null,
  });
};

// ToDo Check if necessary
export const toggleEditorDragging = canDragFields => ({
  type: Types.editor.TOGGLE_DRAGGING,
  canDragFields,
});

export const removePageFieldFromEditorPage = field => ({
  type: Types.editor.REMOVE_PAGE_FIELD,
  field,
});

export const updateEditorDocumentPageFields = (documents, pageFields) => async (dispatch) => {
  try {
    if (isEmpty(documents) || isEmpty(pageFields)) {
      return Promise.resolve();
    }
    dispatch(setEditorLoading('updateEditorDocumentPageFields', true));
    const documentUpdatePromises = [];
    documents.forEach((document) => {
      const documentPageFields = pageFields.filter(pageField =>
        document.page_ids.includes(pageField.pageId));
      const documentUpdateRequest = editorParser.fromEditorToDocumentRequest.parseRequest(
        document,
        documentPageFields,
      );
      documentUpdatePromises
        .push(documentService.update(document.uuid, documentUpdateRequest.document));
    });
    dispatch(setEditorLoading('updateEditorDocumentPageFields', false));
    return Promise.all(documentUpdatePromises);
  } catch (e) {
    return Promise.reject(e);
  }
};

export default {
  setEditorField,
  resetEditor,
  setEditorLoading,
  setEditorPageFields,
  loadEditorDataFromTemplate,
  loadEditorDataFromSignatureRequest,
  setEditorScaleRatio,
  setEditorFocusedPageField,
  addEditorPageField,
  updateEditorPageField,
  toggleEditorDragging,
  removePageFieldFromEditorPage,
  updateEditorDocumentPageFields,
  resetEditorPageFields,
  setCurrentRecipient,
  setFieldOnAllPages,
  copyField,
  setCurrentEditorPage,
  removeRecipientPageFields,
  updateRecipientPageFields,
};
