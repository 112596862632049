import React, { Component } from 'react';
import CanvasDraw from 'react-canvas-draw';
import { DragDropContext, DragSource } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Close from 'material-ui/svg-icons/navigation/close';
import trimCanvas from 'trim-canvas';
import { toast } from 'react-toastify';
import TextSignature from '../../documentSign/TextSignature';
import BluePixel from './../../../assets/images/bluePixel.jpg';
import SignatureUpload from '../../documentSign/SignatureUpload';
import Modal from '../../Modal';
import draw from './../../../assets/images/signing_icons/draw.png';
import keyboard from './../../../assets/images/signing_icons/keyboard.png';
import upload from './../../../assets/images/signing_icons/upload.png';
import drawSelected from './../../../assets/images/signing_icons/drawSelected.png';
import keyboardSelected from './../../../assets/images/signing_icons/keyboardSelected.png';
import uploadSelected from './../../../assets/images/signing_icons/uploadSelected.png';
import canvasClear from './../../../assets/images/canvas-clear.svg';
import { getBase64ImageMimeType } from '../../../utils/file';
import { colors } from '../../../utils';
import { DragItemTypes } from '../../../constants';

const required = value => (value ? undefined : 'Required');

const dragSourceSpec = {
  canDrag(props) {
    return props.drag;
  },
  beginDrag(props) {
    return { ...props.field };
  },
};

// DnD Collector
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class SignatureModal extends Component {
  state = {
    signatureMode: 'draw',
    applyEnabled: false,
    plainSignature: {
      text: '',
      width: 0,
      height: 0,
    },
  }

  canvas = null;
  signatureImage = null;

  doSave(image) {
    const { beforeSavePromise, onSave } = this.props;
    if (beforeSavePromise) {
      beforeSavePromise(image)
        .then(() => {
          // success callback
          onSave(image);
        }, () => {
          // error callback
        });
    } else {
      onSave(image);
    }
  }

  checkCanvasDimension = () => this.canvas.canvas.width > 1 && this.canvas.canvas.height > 1;

  saveImage = () => {
    const { signatureMode, plainSignature } = this.state;
    const { onClose } = this.props;
    switch (signatureMode) {
      case 'text': {
        // handle image creation from text signature
        const canvas = document.createElement('canvas');
        canvas.width = plainSignature.width + 20;
        canvas.height = plainSignature.height;

        const context = canvas.getContext('2d');

        context.font = '45px Dancing Script';
        context.fillStyle = 'black';
        context.textBaseline = 'middle';

        context.fillText(plainSignature.text, 10, (canvas.height / 2) - 5);

        const signature = canvas.toDataURL();
        if (signature !== 'data:,') this.doSave(signature);
        break;
      }
      case 'draw': {
        // get the canvas reference
        // trim the whitespace
        // convert the image to base 64
        // send the result via a prop
        trimCanvas(this.canvas.canvas);
        if (this.checkCanvasDimension()) this.doSave(trimCanvas(this.canvas.canvas).toDataURL());
        else onClose();
        break;
      }
      case 'upload': {
        if (!this.signatureImage) break;
        const mimeType = getBase64ImageMimeType(this.signatureImage).toLowerCase();
        if (mimeType === 'png' || mimeType === 'jpeg' || mimeType === 'jpg') {
          this.doSave(this.signatureImage);
        } else {
          toast.error('The file is not an image. Please try with a PNG, JPG or JPEG file');
        }
        break;
      }
      default:
        break;
    }
  }

  clearCanvas = () => {
    this.canvas.clear();
    this.setState({ applyEnabled: false });
  }

  updateTextSignature = (signature) => {
    this.setState({
      plainSignature: signature,
    });
  }

  renderSignatureHandler = () => {
    const { signatureMode, plainSignature } = this.state;
    const { type } = this.props;
    switch (signatureMode) {
      case 'text':
        return (
          <CanvasWrapper>
            <ClearCanvasIcon
              color={colors.textLight}
              style={{ zIndex: 1 }}
            />
            <TextSignature
              style={{ position: 'relative', bottom: '2rem' }}
              onChange={this.updateTextSignature}
              value={plainSignature}
              type={type}
            />
            <ClearTextContainer>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => this.setState({
                  plainSignature: {
                    text: '',
                    width: 0,
                    height: 0,
                  },
                })}
                style={{ cursor: 'pointer' }}
              >Clear
              </span>
            </ClearTextContainer>
          </CanvasWrapper>
        );
      case 'draw':
        return (
          <CanvasWrapper>
            <ClearCanvasIcon
              src={canvasClear}
            />
            <DrawContainer
              onClick={() => { this.setState({ applyEnabled: true }); }}
              onTouchStart={() => { this.setState({ applyEnabled: true }); }}
            >
              <CanvasDraw
                canvasWidth="500"
                canvasHeight="150"
                brushSize="2"
                ref={(ref) => {
                this.canvas = ref;
              }}
                style={{
                background: `url(${BluePixel}) repeat-x center center`,
              }}
              />
            </DrawContainer>
            <ClearTextContainer>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span
                onClick={this.clearCanvas}
                style={{ cursor: 'pointer' }}
              >Clear
              </span>
            </ClearTextContainer>
          </CanvasWrapper>
        );
      case 'upload':
        return (
          <UploadWrapper>
            <SignatureUpload
              onFilesLoaded={(base64Signature) => {
                  this.signatureImage = base64Signature;
              }}
            />
          </UploadWrapper>
        );
      default:
        return null;
    }
  }

  renderModalContent = () => {
    const { signatureMode, textSignature } = this.state;
    return (
      <React.Fragment>
        <ModalHeader>
          <DrawChoice onClick={() => { this.setState({ signatureMode: 'text' }); }}>
            <TabSwitch src={signatureMode === 'text' ? keyboardSelected : keyboard} alt="Type" />
          </DrawChoice>
          <DrawChoice onClick={() => { this.setState({ signatureMode: 'draw', applyEnabled: false }); }}>
            <TabSwitch src={signatureMode === 'draw' ? drawSelected : draw} alt="Draw" />
          </DrawChoice>
          <DrawChoice onClick={() => { this.setState({ signatureMode: 'upload' }); }}>
            <TabSwitch src={signatureMode === 'upload' ? uploadSelected : upload} alt="Upload" />
          </DrawChoice>
          {/* <DrawChoice onClick={() => { this.setState({ signatureMode: 'upload' }); }}>
            <TabSwitch src={mobileDraw} alt="Mobile" />
          </DrawChoice> */}
        </ModalHeader>
        <ContentWrapper>
          {textSignature}
          {this.renderSignatureHandler()}
        </ContentWrapper>
      </React.Fragment>
    );
  }

  render = () => {
    const {
      modalSaving, isRequired, connectDragSource, onClose,
    } = this.props;
    const { signatureMode, applyEnabled } = this.state;
    return connectDragSource(
      <div>
        <SignatureWrapper>
          <Modal
            title=""
            open
            modal={false}
            content={this.renderModalContent()}
            primaryLabel={modalSaving ? 'Saving...' : 'Apply'}
            secondaryLabel="Cancel"
            onPrimaryClick={this.saveImage}
            onSecondaryClick={onClose}
            contentStyle={{ width: '548px' }}
            titleStyle={{ display: 'none' }}
            primaryDisabled={modalSaving || (!applyEnabled && signatureMode === 'draw')}
          />
          <input type="hidden" validate={isRequired ? [required] : null} />
        </SignatureWrapper>
      </div>);
  }
}

SignatureModal.propTypes = {
  isRequired: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  connectDragSource: PropTypes.func.isRequired,
  modalSaving: PropTypes.bool,
  beforeSavePromise: PropTypes.func,
  type: PropTypes.string.isRequired,
};

SignatureModal.defaultProps = {
  isRequired: false,
  modalSaving: false,
  beforeSavePromise: null,
  onSave: () => {},
  onClose: () => {},
};

const CanvasWrapper = styled.div`
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
`;

const TabSwitch = styled.img`
  cursor: pointer;
`;

const SignatureWrapper = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const DrawChoice = styled.div`
  width: 30px;
  height: 30px;
  margin: 0 3px;
`;

const ClearCanvasIcon = styled(Close)`
  position: absolute;
  top: 55px;
  left: 3px;
`;

const ClearTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  color: ${colors.blue};
  z-index: 100;
  position: relative;

  & span {
    position: relative;
    bottom: 4rem;
  }
`;


const UploadWrapper = styled.div`
  width: 500px;
`;

const ContentWrapper = styled.div`
  margin: 10px 0;
`;

const DrawContainer = styled.div``;

export default DragDropContext(HTML5Backend)(DragSource(DragItemTypes.PAGE_FIELD,
  dragSourceSpec, collect)(SignatureModal));
