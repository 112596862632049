/* eslint-disable jsx-a11y/tabindex-no-positive */

import React from 'react';
import { Form, reduxForm, Field, change } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DatePicker } from 'material-ui';
import Actions from './../../actions';
import normalizers from './normalizers';

const validate = (values) => {
  const errors = {};

  if (!values.document_name) {
    errors.document_name = 'This field is required';
  }

  if (!values.signed_date) {
    errors.signed_date = 'This field is required';
  }

  return errors;
};

let UploadAndArchiveForm = props => (
  <Form onSubmit={() => { }}>
    <div className="row">
      <div className="col-6">
        <Field
          component={TextField}
          name="document_name"
          fullWidth
          floatingLabelText="Name of Document *"
          required
          tabIndex={1}
          onBlur={(e) => {
            props.dispatch(change('uploadAndArchive', 'document_name', normalizers.fileNormalizer(e.target.value, '.pdf')));
            e.preventDefault();
          }}
        />
        <Field
          component={TextField}
          name="first_name"
          fullWidth
          floatingLabelText="Party First Name"
          tabIndex={3}
        />
        <Field
          component={TextField}
          name="company"
          fullWidth
          floatingLabelText="Company"
          tabIndex={6}
        />
      </div>
      <div className="col-6">
        <DatePicker
          value={new Date()}
          floatingLabelText="Uploaded Date"
          fullWidth
          tabIndex={2}
          disabled
        />
        <Field
          component={TextField}
          type="text"
          name="last_name"
          fullWidth
          floatingLabelText="Party Last Name"
          tabIndex={4}
        />
        <Field
          component={TextField}
          name="notes"
          fullWidth
          floatingLabelText="Notes"
          tabIndex={5}
        />
      </div>
    </div>
  </Form>
);

UploadAndArchiveForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { values, ...other }) => {
  const { document } = state.uploadAndArchiveScreen;
  return {
    ...other,
    initialValues: {
      ...values,
      document_name: (document && document.name) || '',
    },
  };
};

UploadAndArchiveForm = reduxForm({
  form: 'uploadAndArchive',
  enableReinitialize: true,
  validate,
})(UploadAndArchiveForm);

export default connect(mapStateToProps, Actions)(UploadAndArchiveForm);
