import { findIndex } from 'lodash';

import Types from '../constants/actionTypes';
import editorFields from '../config/editorFields';

const INITIAL_STATE = {
  fieldsPanelCurrentTab: editorFields.tabs.standardFields,
  pageFields: [],
  initialPageFields: [],
  documents: [],
  focusedPageField: null, // still have to check if it is necessary
  canDragFields: true,
  recipients: [],
  currentRecipient: '',
  scaleRatio: 1,
  imageWidth: null,
  settingScaleRatio: false,
  loading: {},
  currentEditorPage: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.editor.RESET: {
      return {
        ...INITIAL_STATE,
      };
    }

    case Types.editor.SET_FIELD: {
      const { value, name } = action;
      return {
        ...state,
        [name]: value,
      };
    }

    case Types.editor.SET_LOADING: {
      const { loading: isLoading, action: actionName } = action;
      if (!actionName) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        loading: {
          ...state.loading,
          [actionName]: !isLoading ? undefined : true,
        },
      };
    }

    case Types.editor.SETTING_SCALE_RATIO: {
      const { settingScaleRatio } = action;

      return {
        ...state,
        settingScaleRatio,
      };
    }

    case Types.editor.SET_SCALE_RATIO: {
      const { scaleRatio, settingScaleRatio, imageWidth } = action;
      if (!settingScaleRatio) {
        return {
          ...state,
          scaleRatio,
          imageWidth,
        };
      }
      return {
        ...state,
      };
    }

    case Types.editor.SET_FOCUSED_PAGE_FIELD: {
      return {
        ...state,
        focusedPageField: action.focusedPageField,
      };
    }

    case Types.editor.ADD_PAGE_FIELD: {
      const {
        field, documentUUID, pageId, recipientUuid, recipient,
      } = action.payload;
      const pageFields = [...state.pageFields, {
        ...field, documentUUID, pageId, recipientUuid, recipient,
      }];
      return {
        ...state,
        pageFields,
      };
    }

    case Types.editor.UPDATE_PAGE_FIELD: {
      let { pageId } = action;
      const { field, recipient } = action;
      const pageFields = [...state.pageFields];
      const index = findIndex(pageFields, { id: field.id });
      pageId = pageId || pageFields[index].pageId;
      pageFields[index] = {
        ...pageFields[index], ...field, pageId, recipient: recipient || pageFields[index].recipient,
      };
      return {
        ...state,
        pageFields,
      };
    }

    case Types.editor.TOGGLE_DRAGGING: {
      const { canDragFields } = action;
      return {
        ...state,
        canDragFields,
      };
    }

    case Types.editor.REMOVE_PAGE_FIELD: {
      const { field } = action;

      if (field.isNew) {
        return {
          ...state,
          pageFields: [...state.pageFields.filter(pField => pField.id !== field.id)],
        };
      }

      return {
        ...state,
        pageFields:
          [...state.pageFields.filter(pField => pField.id !== field.id),
            { ...field, _destroy: true }],
      };
    }

    default:
      return state;
  }
};

