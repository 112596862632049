import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import { scaleValue } from '../../../utils/scaling';
import connect from './connect';
import { RadioButton, Checkbox, DefaultField, Dropdown, Stamp, AttachFile, DateField } from '../../editor/SpecialFields';
import { imaging } from '../../../utils';
import SignIndicator from '../../../assets/images/signing_icons/signIndicator.png';

class SignaturePage extends Component {
  state = {
    initialized: false,
    isVisible: false,
  }

  async componentDidMount() {
    const { page } = this.props;
    this.updateScaleRatio(page.image_url);
    window.addEventListener('resize', () => this.updateScaleRatio(page.image_url));
  }

  componentDidUpdate = async (prevProps) => {
    /* the first time it loads and is visible */
    const { IMAGE_WIDTH } = this.props.documentDimensions;
    if (this.state.isVisible && !this.state.initialized) {
      const scaleRatio = this.imageRef.width / IMAGE_WIDTH;
      this.props.updateScaleRatio(scaleRatio, IMAGE_WIDTH);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ initialized: true });
    }

    if (prevProps.imageWidth !== this.props.imageWidth) {
      this.updateScaleRatio(this.props.page.image_url);
    }
  }

  getNextEmptyRequiredField = () => {
    const {
      fields, onChangePage, documents, currentRecipient,
    } = this.props;
    const page_ids = documents.reduce((z, d) => [...z, ...d.page_ids], []);
    const requiredFields = fields.filter(field => field.page_field_detail.required &&
       field.recipient_uuid === currentRecipient &&
       field.value === '' && !field.field.name.includes('Date Signed') && !field.field.name.includes('Participation Stamp'));

    if (!requiredFields || !requiredFields.length) {
      return null;
    }

    const auxField = requiredFields[0];

    onChangePage(page_ids.indexOf(auxField.page_id));

    return auxField;
  }


  async updateScaleRatio(imageURL) {
    if (!(isNull(imageURL)) && this.imageRef) {
      const imageSize = await imaging.getImageSizeAsync(imageURL);
      const scaleRatio = this.imageRef.width / imageSize.width;
      this.props.updateScaleRatio(scaleRatio, this.props.imageWidth);
    }
  }

  handleFieldChange(value, field, fieldDef) {
    const { setSignDocumentField, setUserSignature } = this.props;
    if (fieldDef.name.includes('Attachment')) {
      setSignDocumentField({ ...field, ...value });
    } else {
      setSignDocumentField({ ...field, value });
    }
    if (fieldDef.name === 'Signature') {
      setUserSignature(value);
    }
  }

  handleFieldClick(field, fieldDef) {
    const { userSignature } = this.props;
    if (!fieldDef) {
      return true;
    }
    if (fieldDef.name === 'Signature' && userSignature) {
      this.props.customFieldSaved(`field_${field.id}`, userSignature);
      this.handleFieldChange(userSignature, field, fieldDef);
      return false;
    }
    return true;
  }

  renderField(field, scaleRatio) {
    const fieldDef = field.field;
    let ComponentToRender = null;
    let disabled = false;

    switch (fieldDef.name) {
      case 'Dropdown':
        ComponentToRender = Dropdown;
        break;
      case 'Initial':
      case 'Signature':
        ComponentToRender = DefaultField;
        break;
      case 'Radio Button':
        ComponentToRender = RadioButton;
        break;
      case 'Checkbox':
        ComponentToRender = Checkbox;
        break;
      default:
        ComponentToRender = DefaultField;
        break;
    }

    if (fieldDef.input_type === 'select') {
      ComponentToRender = Dropdown;
    } else if (fieldDef.input_type === 'radio') {
      ComponentToRender = RadioButton;
    } else if (fieldDef.input_type === 'checkbox') {
      ComponentToRender = Checkbox;
    } else if (fieldDef.input_type === 'file') {
      switch (fieldDef.name) {
        case 'Stamp':
          ComponentToRender = Stamp;
          break;
        case 'Attachment':
          ComponentToRender = AttachFile;
          break;
        default:
          ComponentToRender = DefaultField;
          break;
      }
    }

    if (!fieldDef.name.includes('Date Signed') && (fieldDef.input_type === 'date_signed' || fieldDef.input_type === 'date')) {
      fieldDef.input_type = 'date';
      disabled = true;
      ComponentToRender = DateField;
    }

    if (fieldDef.name.includes('Date Signed')) {
      fieldDef.input_type = 'date_signed';
      disabled = true;
      ComponentToRender = DateField;
    }

    if (fieldDef.name.includes('Participation Stamp')) {
      disabled = true;
      return null;
    }


    return (
      <ComponentToRender
        field={field}
        mode="sign"
        metadata={field.metadata}
        width={scaleValue(field.page_field_detail.width, scaleRatio)}
        height={scaleValue(field.page_field_detail.height, scaleRatio)}
        scaleRatio={scaleRatio}
        fieldName={`field_${field.id}`}
        fieldValue={field.value}
        onSaved={(data) => {
          this.props.customFieldSaved(`field_${field.id}`, data);
        }}
        onSignatureClick={() => this.handleFieldClick(field, fieldDef)}
        onChange={value => this.handleFieldChange(value, field, fieldDef)}
        isRequired={field.page_field_detail.required}
        isDisabled={disabled ? true : undefined}
        type={fieldDef.input_type}
      />
    );
  }


  renderPageFields(page, scaleRatio) {
    const { fields } = this.props;
    const pageFields = page.page_fields;
    return pageFields.map(field => (
      <FieldWrapper
        key={field.id}
        style={{
          width: scaleValue(field.page_field_detail.width, scaleRatio),
          height: scaleValue(field.page_field_detail.height, scaleRatio),
          left: scaleValue(field.page_field_detail.x_coord, scaleRatio),
          top: scaleValue(field.page_field_detail.y_coord, scaleRatio),
        }}
      >
        {this.renderField(fields.find(f => f.id === field.id), scaleRatio)}
      </FieldWrapper>
    ));
  }


  render() {
    const { page, scaleRatio } = this.props;
    const nextField = this.getNextEmptyRequiredField();
    if (!page) {
      return null;
    }
    return (
      <VisibilitySensor resizeCheck scrollCheck partialVisibility="top">
        {
            ({ isVisible }) => {
              if (this.state.isVisible !== isVisible) this.setState({ isVisible });
              return (
                <PageWrapper key={page.id}>

                  {nextField && nextField.page_id === page.id && (
                    <IndicatorContainer
                      height={scaleValue(nextField.page_field_detail.height, scaleRatio)}
                      y={scaleValue(nextField.page_field_detail.y_coord, scaleRatio)}
                    >
                      <Indicator src={SignIndicator} />
                    </IndicatorContainer>
                  )}
                  <PageImage
                    innerRef={(ref) => {
                      this.imageRef = ref;
                    }}
                    alt={`Page${page.id}`}
                    src={page.image_url}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                  {this.renderPageFields(page, scaleRatio)}
                </PageWrapper>);
            }
          }
      </VisibilitySensor>

    );
  }
}

SignaturePage.propTypes = {
  page: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  setSignDocumentField: PropTypes.func.isRequired,
  updateScaleRatio: PropTypes.func,
  customFieldSaved: PropTypes.func,
  onChangePage: PropTypes.func,
  currentRecipient: PropTypes.string.isRequired,
  documentDimensions: PropTypes.object.isRequired,
  setUserSignature: PropTypes.func.isRequired,
  userSignature: PropTypes.string.isRequired,
};

SignaturePage.defaultProps = {
  updateScaleRatio: () => {},
  customFieldSaved: () => {},
  onChangePage: () => {},
};

const PageImage = styled.img`
  width: 100%;
`;

const IndicatorContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${props => props.height}px;
  top: ${props => props.y}px;
`;

const Indicator = styled.img`
  width: 3.9rem;
  height: 1.9rem;
  position: relative;
  right: 4rem;
`;


const PageWrapper = styled.div`
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 0.5rem;
  position: relative;
  background-color: #fff;
  @media (max-width: 768px) {
    margin-top: 0rem;
  }
`;

const FieldWrapper = styled.div`
  position: absolute;
`;

export default connect(SignaturePage);
