import { identity } from 'lodash';

import Types from '../constants/actionTypes';

const {
  SET_LOADING, LOGIN, LOGOUT, RESET, SET_USER_ORGANIZATIONS, SET_CURRENT_USER, SET_TOKEN_EXPIRED,
} = Types.auth;

const INITIAL_STATE = {
  loading: false, user: undefined, token: '', tokenExpired: false,
};

const states = {
  [SET_LOADING]: (s, { loading }) => ({ ...s, loading }),
  [SET_TOKEN_EXPIRED]: (s, { tokenExpired }) => ({ ...s, tokenExpired }),
  [LOGIN]: (s, { payload }) => ({ ...s, user: payload, tokenExpired: false }),
  [LOGOUT]: s => ({ ...s, ...INITIAL_STATE }),
  [RESET]: () => INITIAL_STATE,
  [SET_USER_ORGANIZATIONS]: (s, { organizations }) => ({
    ...s,
    user: { ...s.user, organizations },
  }),
  [SET_CURRENT_USER]: (s, { user }) => ({ ...s, user }),
};

export default (state = INITIAL_STATE, action) => (states[action.type] || identity)(state, action);
