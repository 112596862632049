import * as Icons from 'material-ui/svg-icons';
import { editorFields } from '../config';

// eslint-disable-next-line import/prefer-default-export
export const getTabs = (standardFields = [], customFields = []) => {
  const tabs = { ...editorFields.tabs };
  tabs.standardFields.groups = tabs.standardFields.groups.map((group) => {
    const fields = group.fields.map((field) => {
      const standardField = standardFields.find(sField => sField.name === field.name);
      return { ...field, ...standardField };
    });
    return { fields };
  });

  tabs.signerFields.groups = tabs.signerFields.groups.map((group) => {
    const fields = group.fields.map((field) => {
      const standardField = standardFields.find(sField => sField.name === field.name);
      return { ...field, ...standardField };
    });
    return { fields };
  });

  tabs.customFields.groups = [{}];
  tabs.customFields.groups[0].fields = customFields.map(customField => ({
    icon: Icons.EditorTextFields,
    ...customField,
    editable: true,
  }));

  return tabs;
};

