import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Color from 'color';
import { colors } from '../utils';

const Button = props => (
  <Container className="p-3" primary={props.primary}>
    {props.label}
  </Container>
);

Button.propTypes = {
  label: PropTypes.string,
  primary: PropTypes.bool,
};

Button.defaultProps = {
  label: 'Button title',
  primary: false,
};

const Container = styled.button`
  background-color: ${props => (props.primary ? colors.lightBlue : colors.darkBlue)};
  border: 0;
  cursor: pointer;
  color: white;
  transition: all .3s ease;
  border-radius: 0.25rem;

  &:hover {
    background-color: ${(props) => {
    const color = props.primary ? colors.lightBlue : colors.darkBlue;
    return Color(color).lighten(0.2).string();
  }};
  }
`;

export default Button;
