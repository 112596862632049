import gql from 'graphql-tag';

export const FETCH_RESOURCES_COUNT_TO_DELETE = gql`
  query fetchResourcesCountToDelete($uuid: ID!) {
    toBeDeletedResourcesCount(newPlanUuid: $uuid) {
      organizationUsersCountToRemove
      templatesCountToRemove
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation updateSubscriptionPlan(
    $uuid: ID!
    $templates: [ID!]!
    $orgUsers: [ID!]
  ) {
    updateSubscriptionPlan(
      newPlanUuid: $uuid
      templatesToRemoveUuids: $templates
      organizationUsersToRemoveUuids: $orgUsers
    ) {
      success
    }
  }
`;

export default {
  FETCH_RESOURCES_COUNT_TO_DELETE,
  UPDATE_SUBSCRIPTION_PLAN,
};
