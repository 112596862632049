import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import { colors } from '../utils';

const TabBarScrollButton = props => (
  <ScrollButton
    direction={props.direction}
    onClick={props.onClick}
    visible={props.visible}
  >
    {props.direction === 'left' && <ChevronLeft style={{ color: colors.blue }} />}
    {props.direction === 'right' && <ChevronRight style={{ color: colors.blue }} />}
  </ScrollButton>
);

TabBarScrollButton.propTypes = {
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const ScrollButton = styled.button`
  background-color: #fff;
  border: none;
  outline: none !important;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  z-index: 99;
  cursor: pointer;
  ${props => (props.visible ? 'display: block;' : 'display: none;')}
  ${props => (props.direction === 'left' ? 'left: 0' : 'right: 0')}
`;

export default TabBarScrollButton;
