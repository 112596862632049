import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isValid } from 'redux-form';
import SendTemplateForm from '../forms/SendTemplateForm';
import { Modal } from '../';
import Actions from '../../actions';

const SendTemplateModal = ({
  documentManagerScreen: { sendTemplateModal },
  templateEditorScreen: { sending },
  toggleSendTemplateModal,
  sendTemplate,
  formValid,
}) => (
  <Modal
    title="Send Template"
    content={<SendTemplateForm />}
    onPrimaryClick={sendTemplate}
    onSecondaryClick={() => toggleSendTemplateModal(false)}
    primaryLabel={sending ? 'Sending...' : 'Send'}
    primaryDisabled={sending || !formValid}
    open={sendTemplateModal}
    isScrollable
  />
);

SendTemplateModal.propTypes = {
  templateEditorScreen: PropTypes.object.isRequired,
  documentManagerScreen: PropTypes.object.isRequired,
  toggleSendTemplateModal: PropTypes.func.isRequired,
  /* ToDo: Remove or create, this actions no longer exist in the templateEditorScreen actions */
  sendTemplate: PropTypes.func,
  formValid: PropTypes.bool.isRequired,
};

SendTemplateModal.defaultProps = {
  sendTemplate: () => { },
};

const mapStateToProps = state => ({
  templateEditorScreen: state.templateEditorScreen,
  documentManagerScreen: state.documentManagerScreen,
  formValid: isValid('sendTemplateForm')(state),
});

export default connect(mapStateToProps, Actions)(SendTemplateModal);
