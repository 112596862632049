import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CardNumberElement, CardCvcElement, CardExpiryElement, injectStripe } from 'react-stripe-elements';
import { Form, withFormik } from 'formik';
import { isEmpty } from 'lodash';
import { TextField, RaisedButton, FlatButton } from 'material-ui';
import { Tooltip } from '@material-ui/core';
import * as Yup from 'yup';

import hintIcon from '../../../assets/images/hint-icon.svg';
import lockIcon from '../../../assets/images/lock-icon.svg';
import { colors } from '../../../utils';
import SubscriptionCard from '../../SubscriptionCard';

const SignUpSchema = Yup.object().shape({
  name: Yup.string().ensure().required('Required'),
});

export const RenewEnterprisePlanForm = (props) => {
  const {
    values, errors, touched, setFieldValue, setFieldTouched, user,
    formTitle, creditCardInfo, onBack, submit, stripe, selectedSubscription,
  } = props;
  const [focusedField, setFocusedField] = useState('');
  const [validCardNumber, setValidCardNumber] = useState(false);
  const [validExpiration, setValidExpiration] = useState(false);
  const [validCvc, setValidCvc] = useState(false);
  const [selectedOption, setSelectedOption] = useState(isEmpty(creditCardInfo) || user.paymentIssue ? 'new' : 'current');
  const setValue = (field, value) => {
    setFieldTouched(field, true, false);
    setFieldValue(field, value);
  };
  const isValid = selectedOption === 'current'
    || (values.name && validCardNumber && validCvc && validExpiration);

  const renderCurrentCCForm = () => (
    <div className="content">
      <div className="title">Current Credit Card</div>
      <FlexContainer style={{ border: `1px solid ${colors.lightGray}`, margin: '1rem 0rem' }}>
        <FlexContainer selected={selectedOption === 'current'} flexDirection="column">
          <div className="title">{`*${creditCardInfo.cardLastDigts}`}</div>
          <div className="subtitle">Card Number</div>
        </FlexContainer>
        <FlexContainer selected={selectedOption === 'current'} flexDirection="column">
          <div className="title">{creditCardInfo.cardBrand}</div>
          <div className="subtitle">Brand</div>
        </FlexContainer>
        <FlexContainer selected={selectedOption === 'current'} flexDirection="column">
          <div className="title">
            {`${creditCardInfo.cardExpirationMonth} / ${creditCardInfo.cardExpirationYear}`}
          </div>
          <div className="subtitle">Exp. Date</div>
        </FlexContainer>
      </FlexContainer>
    </div>
  );

  const renderNewCCForm = () => (
    <div className="content">
      <div className="title">New Credit Card</div>
      <StyledForm>
        <TextField
          fullWidth
          value={values.name}
          onChange={e => setValue('name', e.target.value)}
          errorText={touched.name && errors.name}
          floatingLabelText="Name on Card *"
          disabled={selectedOption !== 'new'}
        />
        <div className="d-flex">
          <InputContainer focused={focusedField === 'cardNumber'} style={{ marginRight: '0.5rem' }}>
            <div className="header">Card Number*</div>
            <CardNumberElement
              onChange={e => setValidCardNumber(e.complete)}
              onFocus={() => setFocusedField('cardNumber')}
              onBlur={() => setFocusedField('')}
              disabled={selectedOption === 'current'}
            />
          </InputContainer>
          <InputContainer focused={focusedField === 'cardExpiration'} style={{ marginRight: '0.5rem' }}>
            <div className="header">Expiration Date*</div>
            <CardExpiryElement
              onChange={e => setValidExpiration(e.complete)}
              onFocus={() => setFocusedField('cardExpiration')}
              onBlur={() => setFocusedField('')}
              disabled={selectedOption === 'current'}
            />
          </InputContainer>
          <InputContainer
            className="position-relative"
            focused={focusedField === 'cardCvc'}
            style={{ marginLeft: '0.5rem' }}
          >
            <div className="header">CVC/CVV*</div>
            <CardCvcElement
              onChange={e => setValidCvc(e.complete)}
              onFocus={() => setFocusedField('cardCvc')}
              onBlur={() => setFocusedField('')}
              disabled={selectedOption === 'current'}
            />
            <Tooltip
              title="The last 3 digits on the back of your credit card. Amex will be 4 digits."
            >
              <img
                alt=""
                className="position-absolute"
                style={{ cursor: 'pointer', bottom: 9, right: 0 }}
                src={hintIcon}
              />
            </Tooltip>
          </InputContainer>
        </div>
      </StyledForm>
    </div>
  );

  // eslint-disable-next-line react/prop-types
  const renderCCOption = ({ option, render }) => (
    <OptionContainer key={option} selected={selectedOption === option}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={() => setSelectedOption(option)} className="selectContainer">
        <div className="select-input"><div className="indicator" /></div>
      </div>
      {render()}
    </OptionContainer>
  );

  const creditCardOptions = [
    { option: 'current', render: renderCurrentCCForm },
    { option: 'new', render: renderNewCCForm },
  ];

  return (
    <div className="d-flex align-items-center justify-content-between" style={{ flexWrap: 'wrap' }}>
      <div style={{
        justifyContent: 'space-between', marginBottom: 45, flexDirection: 'column', width: '70%',
      }}
      >
        <FormTitle>{formTitle}</FormTitle>
        {isEmpty(creditCardInfo) || user.paymentIssue
          ? renderNewCCForm()
          : creditCardOptions.map(renderCCOption) }
      </div>
      <SubscriptionCard
        readOnly
        activeFooter
        style={{
          width: '25%',
          display: 'block',
          height: 'auto',
          marginBottom: '3rem',
          marginRight: '2%',
        }}
        {...selectedSubscription}
      />
      <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
        <SecurePaymentSection>
          Secure Payment
          <img alt="" style={{ marginLeft: 10 }} src={lockIcon} />
        </SecurePaymentSection>
        <div>
          <FlatButton
            primary
            label="Back"
            className="mr-3"
            style={{ width: 122 }}
            onClick={onBack}
          />
          <RaisedButton
            primary
            label="Confirm"
            style={{ width: 122 }}
            onClick={() => submit(selectedOption, stripe)}
            disabled={!isValid}
          />
        </div>
      </div>
    </div>
  );
};

RenewEnterprisePlanForm.propTypes = {
  formTitle: PropTypes.node.isRequired,
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  selectedSubscription: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  creditCardInfo: PropTypes.object,
};

RenewEnterprisePlanForm.defaultProps = {
  creditCardInfo: {},
};

const StyledForm = styled(Form)`
  width: 100%;
  & > input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > .selectContainer {
    width: 5%;
    height: 100%;
    align-self: start;

    & > .select-input {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid ${props => (props.selected ? colors.blue : colors.gray)};
      border-radius: 50%;
      cursor: pointer;
      padding: 4px;

      & > .indicator {
        width: 100%;
        height: 100%
        border-radius: 50%;
        background-color: ${props => (props.selected ? colors.blue : colors.white)};
      }
    }
  }
  & > .content{
    width: 90%;
    border: 2px solid ${props => (props.selected ? colors.blue : colors.gray)};
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;

    & > .title {
      font-family: DIN-Medium;
      color: ${props => (props.selected ? colors.textBlack : colors.defaultImageGray)};
      font-size: 1.2rem;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0rem 0.5rem;
  flex-direction: ${props => props.flexDirection || 'row'};

  & > .title {
    font-size: 1rem;
    margin-top: 0.5rem;
    font-family: DIN-Medium;
    color: ${props => (props.selected ? 'black' : colors.defaultImageGray)};
  }
  & > .subtitle {
    font-size: 0.7rem;
    font-family: DIN-Medium;
    color: ${colors.defaultImageGray};
    margin-bottom: 0.5rem;
  }
`;

const FormTitle = styled.div`
  font-family: DIN-Medium;
  font-size: 1.5rem;
  margin: 2rem 1rem;
`;

const SecurePaymentSection = styled.div`
  font-family: DIN-Medium;
  font-size: 14px;
  color: ${colors.textBlack};
  & img {
    opacity: 1;
  }
`;

const InputContainer = styled.div`
  & > .header {
    font-size: 0.8rem;
    color: ${props => (props.focused ? colors.blue : '#B6B6B6')};
    margin-bottom: 0.5rem;
  }
  margin-top: 2rem;
  width: 100%;
  padding-bottom: 0.4rem;
  border-bottom: ${props => (props.focused ? `2px solid ${colors.blue}` : '1px solid #E0E0E0')};
`;

export default injectStripe(withFormik({
  validationSchema: SignUpSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) props.onSubmit(values);
  },
})(RenewEnterprisePlanForm));
