import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress, Paper } from 'material-ui';
import InfoYellow from '../../assets/images/infoYellow.svg';
import LogoImage from '../../assets/images/logo.svg';
import { LoginForm } from '../../components/forms';
import { animations, colors } from '../../utils';

const LoadingSpinner = (
  <div
    style={{ minWidth: 590, minHeight: 240 }}
    className="d-flex align-items-center justify-content-center"
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

const renderInactivityIndicator = () => (
  <InactivityContainer>
    <img src={InfoYellow} alt="info-icon" />
    You have been logged out due to inactivity, please log back in!
  </InactivityContainer>
);

export const LoginScreen = ({
  auth: { loading, tokenExpired }, login, createAccount, forgotPassword, email,
}) => (
  <Container className="d-flex justify-content-center align-items-center">
    <LoginBox zDepth={3}>
      <FormContainer className="p-4">
        <LogoContainer>
          <Logo src={LogoImage} />
        </LogoContainer>
        {tokenExpired && renderInactivityIndicator()}
        {loading ? LoadingSpinner : (
          <LoginForm
            email={email}
            login={login}
            forgotPassword={forgotPassword}
          />
        )}
      </FormContainer>
      <CreateAccountSection onClick={createAccount}>
        {'DON\'T HAVE AN ACCOUNT?'}
      </CreateAccountSection>
    </LoginBox>
  </Container>
);

LoginScreen.propTypes = {
  auth: PropTypes.object.isRequired,
  login: PropTypes.func,
  createAccount: PropTypes.func,
  forgotPassword: PropTypes.func,
  email: PropTypes.string,
};

LoginScreen.defaultProps = {
  login: () => {},
  createAccount: () => {},
  forgotPassword: () => {},
  email: '',
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;

const InactivityContainer = styled.div`
  width: 100%;
  text-align: center;
  color: #ffa007;
  font-family: DIN-Medium;
  font-size: 20px;
  margin: 2rem 0rem;
  & img {
    margin-right: 1rem;
    position: relative;
    bottom: 0.2rem;
  }
`;

export const LoginBox = styled(Paper)`
  width: 640px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LogoContainer = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  height: 3rem;
  flex: 1;
  justify-content: center;
  align-content: center;
`;

export const Logo = styled.img`
  width: 220px;
  height: auto;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
  align-self: center;
`;

export const FormContainer = styled.div`
  width: 100%;
`;

const CreateAccountSection = styled.div`
  margin-top: 1rem;
  padding: 0.7rem 0rem;
  color: #FFFFFF;
  font-family: DIN-Medium;
  font-size: 14px;
  background-color: ${colors.blue};
  color: white;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

export default LoginScreen;
