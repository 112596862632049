import { remove } from 'lodash';
import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  standardFields: [],
  customFields: [],
  inputTypes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.fields.SET_FIELDS: {
      const { fields: { standard, custom } } = action;
      return {
        ...state,
        standardFields: standard,
        customFields: custom,
      };
    }

    case Types.fields.UPDATE_FIELD: {
      const { field } = action;
      const stateCopy = JSON.parse(JSON.stringify(state));
      const fieldInState = stateCopy.customFields.find(cField => cField.id === field.id);
      Object.keys(fieldInState).forEach((key) => {
        fieldInState[key] = field[key];
        fieldInState.input_type = field.type;
      });
      return {
        ...state,
        customFields: stateCopy.customFields,
      };
    }

    case Types.fields.CREATE_FIELD: {
      const { field } = action;
      const stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.customFields.push(field);
      return {
        ...state,
        customFields: stateCopy.customFields,
      };
    }

    case Types.fields.DELETE_FIELD: {
      const { id } = action;
      const stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.customFields = remove(stateCopy.customFields, field => field.id !== id);
      return {
        ...state,
        customFields: stateCopy.customFields,
      };
    }

    case Types.fields.SET_INPUT_TYPES: {
      const { inputTypes } = action;
      return {
        ...state,
        inputTypes,
      };
    }

    default:
      return state;
  }
};

