import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import SendDocumentForm from '../forms/SendDocumentForm/SendDocumentForm';

const SendDocumentModal = ({
  handleModal,
  handleCancel,
  handleSubmit,
  selectedDocument,
  sending,
  isOpen,
  validateAll,
}) => {
  const [formValid, setFormValid] = useState(true);

  return (
    <Modal
      title="Send Copy"
      open={isOpen}
      content={<SendDocumentForm
        selectedDocument={selectedDocument}
        onSubmit={handleSubmit}
        validateAll={validateAll}
        isFormValid={setFormValid}
      />}
      onPrimaryClick={handleModal}
      primaryLabel={sending ? 'Sending...' : 'Send'}
      primaryDisabled={sending || !formValid}
      onSecondaryClick={handleCancel}
    />
  );
};

SendDocumentModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedDocument: PropTypes.object,
  sending: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  validateAll: PropTypes.bool,
};

SendDocumentModal.defaultProps = {
  selectedDocument: {},
  validateAll: false,
};

export default SendDocumentModal;
