import { identity } from 'lodash';

import Types from '../constants/actionTypes';

const {
  SET_PLANS,
  SET_SELECTED_SUBSCRIPTION,
  SET_OPEN_ITEMS_SELECTION_MODAL,
  SET_OPEN_PAYMENT_MODAL,
  SET_OPEN_UPDATE_MODAL,
  SET_SUBSCRIPTION_FIELD,
  SET_UPGRADE_MODAL,
  SET_OPEN_ERROR_CREDIT_CARD_MODAL,
  SET_VISIBLE_PLANS,
  RESET,
} = Types.subscriptions;

const INITIAL_STATE = {
  plans: [],
  visiblePlans: 'all',
  selectedSubscription: {},
  openFileSelectionModal: false,
  openUpgradeModal: false,
  openUpdateModal: false,
  openPaymentModal: false,
  personalAccountAction: false,
  newOrganizationUpgradeRequired: false,
  openErrorUpdateCreditCardModal: false,
};

const states = {
  [SET_PLANS]: (s, { plans }) => ({ ...s, plans }),
  [SET_SELECTED_SUBSCRIPTION]: (s, { selectedSubscription }) => ({ ...s, selectedSubscription }),
  [SET_OPEN_ITEMS_SELECTION_MODAL]: (s, { openItemsSelectionModal }) =>
    ({ ...s, openItemsSelectionModal }),
  [SET_OPEN_PAYMENT_MODAL]: (s, { openPaymentModal }) => ({ ...s, openPaymentModal }),
  [SET_OPEN_ERROR_CREDIT_CARD_MODAL]: (s, { open }) =>
    ({ ...s, openErrorUpdateCreditCardModal: open }),
  [SET_OPEN_UPDATE_MODAL]: (s, { openUpdateModal }) => ({ ...s, openUpdateModal }),
  [SET_SUBSCRIPTION_FIELD]: (s, { field, value }) => ({ ...s, [field]: value }),
  [SET_UPGRADE_MODAL]: (s, { openUpgradeModal, personalAccountAction }) => ({
    ...s,
    openUpgradeModal,
    personalAccountAction,
  }),
  [SET_VISIBLE_PLANS]: (s, { apiUpdate }) => ({ ...s, visiblePlans: apiUpdate }),
  [RESET]: () => INITIAL_STATE,
};

export default (state = INITIAL_STATE, action) => (states[action.type] || identity)(state, action);
