import React, { Component } from 'react';
import { connect as ReduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import graphqlClient from '../../../config/graphql';
import { CREATE_NOTE, DELETE_NOTE } from './mutations';
import { FETCH_NOTES } from './queries';
import { getEntityType } from './utils';
import * as DocumentManagerActions from '../../../actions/documentManagerScreen';
import Messages from '../../../constants/toastMessages';

const renderWrappedComponent = WrappedComponent =>
  class extends Component {
    state = {
      notes: [],
    }

    componentDidMount = () => this.fetchNotes();

    fetchNotes = async () => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization, selectedDocument } = this.props;
      try {
        const {
          data: {
            entityNotes: {
              nodes,
            },
          },
        } = await graphqlClient(currentOrganization &&
          currentOrganization.subdomain).query({
          query: FETCH_NOTES,
          variables: {
            entityUuid: selectedDocument.uuid,
            entityType: getEntityType(selectedDocument.type),
          },
        });
        this.setState({ notes: nodes });
      } catch (e) {
        throw e;
      }
    }

    createNote = async (entityUuid, entityType, text) => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization } = this.props;
      this.setState({ loading: true });
      try {
        await graphqlClient(currentOrganization &&
          currentOrganization.subdomain).mutate({
          mutation: CREATE_NOTE,
          variables: { entityUuid, entityType, text },
        });
        this.fetchNotes();
        this.setState({ loading: false });
      } catch (e) {
        this.setState({ loading: false });
        toast.error(Messages.documentManager.notes.update.error);
        throw e;
      }
    }

    deleteNote = async (uuid) => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization } = this.props;
      try {
        await graphqlClient(currentOrganization &&
          currentOrganization.subdomain).mutate({
          mutation: DELETE_NOTE,
          variables: { uuid },
        });
        this.fetchNotes();
      } catch (e) {
        toast.error(Messages.documentManager.notes.delete.error);
        throw e;
      }
    }

    render = () => (<WrappedComponent
      {...this.props}
      {...this.state}
      saveNotes={this.saveNotes}
      createNote={this.createNote}
      deleteNote={this.deleteNote}
      fetchNotes={this.fetchNotes}
    />);
  };


const mapStateToProps = ({ documentManagerScreen }) => ({
  selectedDocument: documentManagerScreen.selectedDocument,
});

const mapDispatchToProps = () => {
  const {
    setSelectedDocument,
  } = DocumentManagerActions;
  return {
    setSelectedDocument,
  };
};


const connect = WrappedComponent =>
  ReduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
