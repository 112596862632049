import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ChromePicker } from 'react-color';

class ColorPickerTrigger extends React.Component {
  state = {
    showPicker: false,
    color: null,
  };

  cover = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  popoverStyle = {
    position: 'absolute',
    bottom: 25,
    zIndex: '2',
  };

  togglePicker = () => {
    this.setState({
      showPicker: !this.state.showPicker,
    });
  };

  handleClose = () => {
    this.setState({ showPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
    this.props.handleColorChange(this.props.fieldName, color.hex);
  };

  render() {
    return (
      <FieldWrapper>
        <PickerSample>
          <Color onClick={this.togglePicker} bgColor={this.state.color || this.props.value} />
          {this.state.showPicker && (
            <div style={this.popoverStyle}>
              <div style={this.cover} onClick={this.handleClose} role="presentation" />
              <ChromePicker
                color={this.state.color || this.props.value}
                onChangeComplete={this.handleChange}
              />
            </div>
          )}
          <Field component="input" type="hidden" name={this.props.fieldName} />
        </PickerSample>
        <Label>{this.props.label}</Label>
      </FieldWrapper>
    );
  }
}

ColorPickerTrigger.propTypes = {
  handleColorChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

ColorPickerTrigger.defaultProps = {
  value: '#000000',
};

const FieldWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 1em;
`;

const PickerSample = styled.div`
  width: 28px;
  height: 28px;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 1px;
  position: relative;
  display: inline-block;
`;

const Color = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.bgColor};
  cursor: pointer;
`;

const Label = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1em;
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
`;

export default ColorPickerTrigger;
