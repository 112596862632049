import gql from 'graphql-tag';

export const CREATE_REMINDER = gql`
mutation CreateReminder(
  $signatureRequestUuid: ID!,
  $message: String,
  $frequency: FrequencyEnum!,
  $options: ReminderOptions
  ){
  createReminder(
    signatureRequestUuid: $signatureRequestUuid,
    message: $message,
    frequency: $frequency,
    options: $options,
  ){
    reminder {
      uuid
    }
  }
}
`;

export default {
  CREATE_REMINDER,
};
