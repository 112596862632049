import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import graphqlClient from '../../config/graphql';
import { FETCH_PLANS } from './queries';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    state = {
      data: [],
      loading: false,
    }

    componentDidMount() {
      this.fetchSubscriptionPlans();
    }

    fetchSubscriptionPlans = async () => {
      // eslint-disable-next-line react/prop-types
      this.setState({ loading: true });
      try {
        const {
          data: {
            plans: {
              nodes,
            },
          },
        // eslint-disable-next-line react/prop-types
        } = await graphqlClient().query({
          query: FETCH_PLANS,
          fetchPolicy: 'network-only',
        });
        this.setState({ data: nodes, loading: false });
        return { data: nodes };
      } catch (e) {
        this.setState({ loading: false });
        return {
          data: [],
        };
      }
    }

    render = () => <Component {...this.state} />
  };

const mapStateToProps = ({ organizations }) => ({
  currentOrganization: organizations.currentOrganization,
});

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, null)(renderWrappedComponent(WrappedComponent));


export default connect;
