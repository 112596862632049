import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { Form, Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { isValidInvitation } from '../../actions/organizationsScreen';

const validate = (values, { dispatch, initialValues }) => {
  const emails = get(values, 'recipients', '').split(',')
    .map(r => r.toLowerCase().trim()).filter(r => !!r);
  const existingEmails = get(initialValues, 'currentOrganization.users', [])
    .map(u => u.email.toLowerCase().trim());
  const errors = [{
    when: () => isEmpty(emails),
    then: () => 'This field is required.',
  }, {
    when: () => !emails.every(e => validator.isEmail(e)),
    then: () => 'You have entered an invalid email address, please check before sending.',
  }, {
    when: () => emails.some(e => existingEmails.includes(e)),
    then: () => 'You have entered an already member email address, please remove it before sending.',
  }, {
    when: () => true,
    then: () => '',
  }];
  const recipients = errors.find(e => e.when()).then();

  dispatch(isValidInvitation(isEmpty(recipients)));

  return isEmpty(recipients) ? {} : { recipients };
};

const InviteNewUserForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Field
      hintText="Enter email addresses separated by comma"
      floatingLabelText="Recipients"
      fullWidth
      component={TextField}
      name="recipients"
      type="recipients"
      required
    />
  </Form>
);

InviteNewUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default connect(({ organizations }) => ({
  initialValues: { currentOrganization: organizations.currentOrganization },
}))(reduxForm({ form: 'inviteNewUserForm', validate })(InviteNewUserForm));
