import { FETCH_CURRENT_USER } from '../screens/UserProfileScreen/queries';
import graphqlClient from '../config/graphql';

export const storeHeaders = (response, storage = 'localStorage') => {
  switch (storage) {
    case 'sessionStorage': {
      sessionStorage.setItem('accessToken', response.headers['access-token']);
      sessionStorage.setItem('uid', response.headers.uid);
      sessionStorage.setItem('client', response.headers.client);
      break;
    }
    default: {
      localStorage.setItem('accessToken', response.headers['access-token']);
      localStorage.setItem('uid', response.headers.uid);
      localStorage.setItem('client', response.headers.client);
    }
  }
};

export const clearHeaders = () => {
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('uid');
  sessionStorage.removeItem('client');
  localStorage.removeItem('client');
  localStorage.removeItem('client');
  localStorage.removeItem('client');
};

export const getHeaders = () => ({
  accessToken: sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken'),
  uid: sessionStorage.getItem('uid') || localStorage.getItem('uid'),
  client: sessionStorage.getItem('client') || localStorage.getItem('client'),
});

export const verifyAuthExpiration = async (currentOrganization, logout) => {
  try {
    const response = await graphqlClient(currentOrganization
      && currentOrganization.subdomain).query({
      query: FETCH_CURRENT_USER,
      fetchPolicy: 'network-only',
    });
    return response;
  } catch (e) {
    logout();
    return {};
  }
};


export default {
  getHeaders,
  clearHeaders,
  storeHeaders,
  verifyAuthExpiration,
};
