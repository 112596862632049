import React, { Component } from 'react';
import { connect as ReduxConnect } from 'react-redux';
// import jsPDF from 'jspdf';
import history from '../../config/history';
import { toggleSendDocumentModal, submitSendDocument, sendDocument } from '../../actions/signs';
import organizationActions from '../../actions/organization';
import { setPreviousDocuments, restoreFromPrevious } from '../../actions/filesManager';

const renderWrappedComponent = WrappedComponent =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends Component {
    render() {
      // eslint-disable-next-line react/prop-types
      return (<WrappedComponent
        {...this.props}
        history={history}
      />);
    }
  };


const mapStateToProps = ({
  signs: {
    displayModal, sending, template, message,
  },
  filesManager,
  loader,
}) => ({
  message,
  displayModal,
  sending,
  template,
  filesManager,
  loader,
});

const mapDispatchToProps = () => ({
  submitSendDocument,
  sendDocument,
  toggleSendDocumentModal,
  setPreviousDocuments,
  restoreFromPrevious,
  enableOrganizationChange: organizationActions.enableOrganizationChange,
  disableOrganizationChange: organizationActions.disableOrganizationChange,
});

const connect = WrappedComponent =>
  ReduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
