import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RadialBar, RadialBarChart, Legend, ResponsiveContainer, PolarAngleAxis } from 'recharts';

const style = {
  lineHeight: '10px',
  fontSize: '20px',
  fontWeight: 'bold',
  bottom: '44%',
  left: '50%',
  color: 'rgba(0,0,0,0.87)',
  fontFamily: 'Lato',
  transform: 'translateX(-50%)',
};

const RoundChart = ({ data, lineName }) => (
  <Layout>
    <ResponsiveContainer>
      <RadialBarChart
        innerRadius="140%"
        outerRadius="165%"
        data={[data]}
        startAngle={180}
        endAngle={0}
        cy="80%"
      >
        <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
        <RadialBar
          background
          dataKey={lineName}
          angleAxisId={0}
          label={{
            fill: 'white', position: 'insideStart',
          }}
        />
        <Legend iconSize={0} height={10} layout="horizontal" verticalAlign="bottom" wrapperStyle={style} />
      </RadialBarChart>
    </ResponsiveContainer>
    <CompletedText>Completed</CompletedText>
  </Layout>

);

const Layout = styled.div`
  width: 100%;
  height: 100%;

  & g > text {
    opacity: 0;
  }
`;
const CompletedText = styled.div`
  position: absolute;
  transform: translateX(-53%);
  left: 50%;
  bottom: 30%;
  color: rgba(0,0,0,0.54);
  font-size: 12px;
  font-weight: bold;
  font-family: Lato;
`;

RoundChart.propTypes = {
  data: PropTypes.object.isRequired,
  lineName: PropTypes.string.isRequired,
};

export default RoundChart;
