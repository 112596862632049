import React, { Component } from 'react';
import { Form, reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Checkbox } from 'redux-form-material-ui';
import { validateEmails } from '../../utils';

import Actions from './../../actions';

const validate = (values) => {
  const errors = {};
  const recipients = Object.keys(values).filter(k => k.includes('recipient_'));

  recipients.forEach((recipient) => {
    if (!validateEmails(values[recipient])) {
      errors[recipient] = 'You have entered an invalid email address, please check before sending';
    }
  });

  if (!values.body) {
    errors.body = 'This field is required';
  }

  if (!values.subject) {
    errors.subject = 'This field is required';
  }

  return errors;
};

class SendTemplateForm extends Component {
  handleSubmit = (values) => {
    const keys = Object.keys(values);
    const roles = keys.filter(k => k.includes('recipient_'));

    const templateRecipients = roles
      .reduce(
        (list, role) => list
          .concat(values[role].split(',').map(email => ({
            role: role.replace('recipient_', ''),
            email: email.trim(),
            name: email.trim(),
          }))),
        [],
      );

    const requestData = {
      signature_request: {
        message: values.body,
        subject: values.subject,
        complete_in_any_order: true,
      },
      template_recipients: templateRecipients,
    };
    /* ToDo: remove or create, The action no longer exist in the template editor screen actions */
    this.props.requestTemplateSignatures(this.props.currentTemplate.uuid, requestData);
  };

  renderRolesRecipientsFields = roles => (
    roles.map(role => (
      <Field
        hintText="Enter email addresses separated by comma"
        fullWidth
        component={TextField}
        name={`recipient_${role.role}`}
        floatingLabelText={role.role}
        floatingLabelFixed
        key={`recipient_${role.role}`}
        validate={value => (value ? undefined : 'This field is required')}
      />
    ))
  );

  render() {
    const { currentTemplate, user, handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <h6 className="mt-3">Recipients</h6>
        {this.renderRolesRecipientsFields(currentTemplate.recipients)}
        <div className="row mt-3">
          <div className="col-7 ">
            <Field
              component={Checkbox}
              name="cc_self"
              label={`CC me (${user.email})`}
            />
          </div>
        </div>
        <Field
          fullWidth
          component={TextField}
          name="cc_recipients"
          floatingLabelText="CC Recipients"
          hintText="Enter email addresses separated by comma"
        />
        <h6 className="mt-3">Message</h6>
        <Field fullWidth component={TextField} name="subject" floatingLabelText="Subject" />
        <Field fullWidth component={TextField} name="body" floatingLabelText="Body" />
      </Form>
    );
  }
}

SendTemplateForm.propTypes = {
  user: PropTypes.object.isRequired,
  currentTemplate: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  requestTemplateSignatures: PropTypes.func.isRequired,
};

const mapStateToProps = ({ documentManagerScreen, auth: { user } }) => ({
  currentTemplate: documentManagerScreen.selectedTemplate,
  user,
});

export default connect(mapStateToProps, Actions)(reduxForm({
  form: 'sendTemplateForm',
  enableReinitialize: true,
  validate,
})(SendTemplateForm));
