import React, { Component } from 'react';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import DraggableItem from './DraggableItem';

class DraggableList extends Component {
  state = {
    possibleMovement: null,
  }

  setPossibleMovement = (possibleMovement) => {
    this.setState({ possibleMovement });
  }


  move = (fromIndex, toIndex, movement) => {
    const { list, updateList } = this.props;
    if (fromIndex === toIndex || toIndex < 0) {
      return null;
    }

    if (toIndex >= 0 && movement === 'top') {
      updateList(arrayMove(list, fromIndex, toIndex));
    }
    if (toIndex < list.length && movement === 'bottom') {
      updateList(arrayMove(list, fromIndex, toIndex + 1));
    }
    return null;
  };

  render() {
    const { componentList } = this.props;
    const { possibleMovement } = this.state;
    return (
      <React.Fragment>
        {
          componentList && componentList.map((element, index) => (
            <DraggableItem
              possibleMovement={possibleMovement}
              index={index}
              element={element}
              move={this.move}
              setPossibleMovement={this.setPossibleMovement}
            >
              {element}
            </DraggableItem>
          ))
        }
      </React.Fragment>
    );
  }
}

DraggableList.propTypes = {
  list: PropTypes.array.isRequired,
  componentList: PropTypes.array.isRequired,
  updateList: PropTypes.func.isRequired,
};

export default DraggableList;
