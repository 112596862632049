import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../utils';

const ChartSummaryWidget = props => (
  <Container {...props} className="d-flex flex-column align-items-center justify-content-center">
    <LargeText className="text-center mb-1">
      {props.largeText}
    </LargeText>
    <SmallText className="text-center">
      {props.smallText}
    </SmallText>
  </Container>
);

ChartSummaryWidget.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  largeText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  smallText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const Container = styled.div`
  position: absolute;
  top: 0;
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
  height: ${({ height }) => (typeof height === 'string' ? height : `${height}px`)};
`;

const LargeText = styled.div`
  width: 100%;
  height: 28px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

const SmallText = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: ${colors.textLight};
  font-weight: bold;
  font-family: Lato;
`;

export default ChartSummaryWidget;
