import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { colors } from '../utils';

const Alert = ({
  content,
  handleClose,
  handleSubmit,
  open,
  primaryLabel,
  primaryStyle,
  secondaryLabel,
  secondaryStyle,
  actionsDisabled,
}) => {
  const actions = [
    <FlatButton label={secondaryLabel} primary onClick={handleClose} style={secondaryStyle} />,
    <FlatButton label={primaryLabel} primary onClick={handleSubmit} style={primaryStyle} />,
  ];

  return (
    <Dialog
      actions={!actionsDisabled && actions}
      modal={false}
      open={open}
      onRequestClose={handleClose}
    >
      {content}
    </Dialog>
  );
};

Alert.propTypes = {
  content: PropTypes.any,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
  primaryLabel: PropTypes.string,
  primaryStyle: PropTypes.object,
  secondaryLabel: PropTypes.string,
  secondaryStyle: PropTypes.object,
  actionsDisabled: PropTypes.bool,
};

Alert.defaultProps = {
  content: '',
  handleClose: () => {},
  handleSubmit: () => {},
  open: false,
  primaryLabel: 'Submit',
  primaryStyle: { color: colors.danger },
  secondaryLabel: 'Cancel',
  secondaryStyle: null,
  actionsDisabled: false,
};

export default Alert;
