import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import {
  scaleValues,
  scaleValueWithConstant,
  decreasedScaleRatio,
} from '../../../utils/scaling';

const renderSignParticipationStampComponent = ({
  scaleRatio, imageWidth, field: { value, field, page_field_detail },
}) => (
  <FieldContainer scaleRatio={scaleRatio} imageWidth={imageWidth}>
    {!value ? (
      <FieldText scaleRatio={scaleRatio} imageWidth={imageWidth}>
        <p className="name">
          {page_field_detail.required && (
            <RequiredMark scaleRatio={scaleRatio} imageWidth={imageWidth}>
              *
            </RequiredMark>
          )}
          {field.name}
        </p>
        <p className="size">Name</p>
        <p className="size">Signing/Approval Time</p>
        <p className="size">Email</p>
      </FieldText>
    ) : (
      <Content image={value} />
    )}
  </FieldContainer>
);

renderSignParticipationStampComponent.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
};

const renderUnsignParticipationStampComponent = ({
  imageWidth, onClick, onResizeStart, onResizeStop,
  recipients, scaleRatio, field: {
    recipientUuid, width, height, required,
  },
}) => {
  const currentRecipient = (recipients || []).find(r => r.uuid === recipientUuid) || {};

  return (
    <ResizableBox
      width={width}
      height={height}
      minConstraints={scaleValues([180, 90], scaleRatio)}
      maxConstraints={scaleValues([180, 90], scaleRatio)}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      onClick={onClick}
    >
      <FieldText scaleRatio={scaleRatio} imageWidth={imageWidth}>
        <p className="name">
          <IndicatorContainer recipientColor={currentRecipient.color}>
            <div className="indicator" />
          </IndicatorContainer>
          {required && (
            <RequiredMark scaleRatio={scaleRatio} imageWidth={imageWidth}>
              *
            </RequiredMark>
          )}
          Participation Stamp
        </p>
        <p className="size">Name</p>
        <p className="size">Signing/Approval Time</p>
        <p className="size">Email</p>
      </FieldText>
    </ResizableBox>
  );
};

renderUnsignParticipationStampComponent.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onResizeStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  recipients: PropTypes.array,
};

renderUnsignParticipationStampComponent.defaultProps = {
  onClick: () => {},
  onResizeStop: () => {},
  onResizeStart: () => {},
  recipients: [],
};

const ParticipationStamp = ({ mode, ...props }) => (mode === 'sign'
  ? renderSignParticipationStampComponent(props)
  : renderUnsignParticipationStampComponent(props));

ParticipationStamp.propTypes = { mode: PropTypes.string };

ParticipationStamp.defaultProps = { mode: 'edit' };

const Content = styled.div`
  background-image: url(${props => props.image});
  background-position: ${props => (props.isDot ? 'left' : 'top')};
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: ${props => (props.isDot ? '20%' : '100%')};
`;

const FieldContainer = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  & > div > div, > div {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
  }

  & hr {
    display: none !important;
  }

   & div {
     width: 100% !important;
     opacity: 0.9 !important;
     background-color: #B3E1F5 !important;
     border: 1px solid #3190C0 !important;
     display: flex;
     font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px !important;
     padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
     align-items: flex-start;
     ::placeholder {
       font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
       color: #236BA0;
       font-weight: 500;
    }
   }
`;


const RequiredMark = styled.span`
  && {
    margin: 0 ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(3, scaleRatio, imageWidth)}px;;
    color: red;
    font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(10, scaleRatio, imageWidth)}px;
    line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(14, scaleRatio, imageWidth)}px;
  }
`;

const FieldText = styled.div`
  line-height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(16, decreasedScaleRatio(scaleRatio, 0.1), imageWidth)}px;
  font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, decreasedScaleRatio(scaleRatio, 0.1), imageWidth)}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #236BA0;
  font-weight: 500;
  border: 1px solid #6BB5D5;
  justify-content: flex-start;
  padding-top: 5px;
  padding-left: 5px;
  height: 100%;
  & .name {
    display: flex;
    margin-bottom: 0.5rem !important;
  }
  & .size {
    margin: 0rem !important;
    white-space: nowrap;
  }
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
  height: 100%;

  & > .indicator {
    border-radius: 50%;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    background-color: ${props => props.recipientColor};
  }
`;

export default ParticipationStamp;
