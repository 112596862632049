import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageLoader from 'react-loading-image';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import styled from 'styled-components';
import {
  Card,
  CardActions,
  CardMedia,
  IconButton,
  Popover,
  Menu,
  MenuItem,
  RefreshIndicator, CircularProgress,
} from 'material-ui';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Messages from '../../constants/toastMessages';
import BrandModal from '../modals/BrandModal/BrandModal';
import DeleteBrandModal from '../modals/DeleteBrandModal';
import brandSectionActions from '../../actions/brandsSection';
import FullLoadingIndicator from '../commons/FullLoadingIndicator';

const style = {
  container: {
    position: 'relative',
    height: '14vw',
  },
  refresh: {
    display: 'inline-block',
    position: 'relative',
  },
};

class BrandsSection extends Component {
  state = {
    openActions: false,
    deleteBrandModal: false,
    elId: null,
    actionInProgress: false,
  };

  handleClick = uuid => (event) => {
    event.preventDefault();
    this.setState({
      openActions: true,
      anchorEl: event.currentTarget,
      elId: uuid,
    });
  };

  handleEditClick = (brandUuid) => {
    this.setState({ openActions: false });
    this.props.brandEditModal(brandUuid);
  };

  handleDeleteBrand = async () => {
    const { deleteBrand, fetchBrands } = this.props;
    this.setState({ actionInProgress: true });
    await deleteBrand(this.state.elId);
    await fetchBrands();
    this.setState({
      deleteBrandModal: false,
      actionInProgress: false,
    });
  }

  handleDefaultChange = async (id) => {
    const { setDefaultBrand, fetchBrands } = this.props;
    this.setState({
      openActions: false,
      actionInProgress: true,
    });
    try {
      await setDefaultBrand(id);
      await fetchBrands();
      toast.success(Messages.brands.setDefault.success);
    } catch (e) {
      toast.error(Messages.brands.setDefault.error);
    } finally {
      this.setState({ actionInProgress: false });
    }
  };

  renderLoadingImage = () => (
    <div className="d-flex align-items-center justify-content-center" style={style.container}>
      <RefreshIndicator
        size={40}
        left={10}
        top={0}
        status="loading"
        style={style.refresh}
      />
    </div>
  )

  renderBrand = brand => (
    <div className="col-4 col-lg-3 mt-4" key={`brand_${brand.uuid}`}>
      <Card>
        <CardMedia>
          <ImageLoader
            style={{
              height: '100%',
              padding: '1rem',
              objectFit: 'cover',
              borderRadius: '50%',
            }}
            src={brand.signing_logo}
            loading={this.renderLoadingImage}
          />
        </CardMedia>

        <CardActions>
          <BrandTextContainer>
            <BrandName>{brand.name}</BrandName>
            <IconButton onClick={this.handleClick(brand.uuid)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={this.state.openActions}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              onRequestClose={() => this.setState({ openActions: false })}
            >
              <Menu>
                <MenuItem
                  primaryText="Edit"
                  onClick={() => this.handleEditClick(this.state.elId)}
                />
                <MenuItem
                  primaryText="Delete"
                  onClick={() => this.setState({
                    openActions: false,
                    deleteBrandModal: true,
                  })}
                />
                <MenuItem
                  primaryText="Set as Default"
                  onClick={() => this.handleDefaultChange(this.state.elId)}
                />
              </Menu>
            </Popover>
          </BrandTextContainer>
        </CardActions>
      </Card>
    </div>
  );

  render() {
    const { brandsSection: { brands }, loading } = this.props;
    const { actionInProgress, deleteBrandModal } = this.state;
    return (
      <BrandContainer className="row">
        <BrandModal />
        {deleteBrandModal && (
          <DeleteBrandModal
            onDelete={this.handleDeleteBrand}
            onCancel={() => this.setState({ deleteBrandModal: false })}
          />
        )}
        {loading
          ? <Loading><CircularProgress className="mb-3" /></Loading>
          : (brands || []).map(this.renderBrand)}
        <FullLoadingIndicator open={actionInProgress} />
      </BrandContainer>
    );
  }
}

BrandsSection.propTypes = {
  brandsSection: PropTypes.object.isRequired,
  brandEditModal: PropTypes.func.isRequired,
  setDefaultBrand: PropTypes.func.isRequired,
  deleteBrand: PropTypes.func.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const BrandName = styled.div`
  font-size: 1.1rem;
  margin-top: 0.5rem;
  font-weight: 500;
  padding-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BrandContainer = styled.div`
  margin-bottom: 2rem;
`;

const BrandTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Loading = styled.div`
  padding: 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const mapDispatchToProps = () => {
  const {
    setDefaultBrand,
    deleteBrand,
    brandEditModal,
    fetchBrands,
  } = brandSectionActions;
  return {
    setDefaultBrand,
    deleteBrand,
    brandEditModal,
    fetchBrands,
  };
};

const mapStateToProps = ({ brandsSection }) => ({ brandsSection });

export default connect(mapStateToProps, mapDispatchToProps())(BrandsSection);
