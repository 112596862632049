import { toast } from 'react-toastify';
import { submit, reset } from 'redux-form';
import documentService from '../services/document';
import { showLoader, hideLoader } from './loader';
import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import uploadAndArchiveParser from '../utils/parsers/uploadAndArchiveParser';
import graphqlClient from '../config/graphql';
import { FILL_SIGN } from '../utils/mutations/fillAndSign';
import { fetchFields } from './fields';
import { updateCurrentUser } from './sendDocumentScreen';

export const setTemplateScaleRatio = (scaleRatio, imageWidth) => async (dispatch) => {
  dispatch({
    type: Types.signs.SETTING_SCALE_RATIO,
    settingScaleRatio: true,
  });
  dispatch({
    type: Types.signs.SET_SCALE_RATIO,
    scaleRatio,
    imageWidth,
  });
  dispatch({
    type: Types.signs.SETTING_SCALE_RATIO,
    settingScaleRatio: false,
  });
};

export const setSignsMessageValue = message => async (dispatch) => {
  dispatch({
    type: Types.signs.SET_MESSAGE,
    message,
  });
};

export const createTemplateField = field => (dispatch, getState) => {
  const { user } = getState().auth;
  const { fields } = getState().signs;
  const type = `default_${field.type}`;
  const content = user[type] || field.content || '';
  if (!fields.some(f => f.type === field.type &&
    f.x === field.x && f.y === field.y)) {
    dispatch({
      type: Types.signs.CREATE_FIELD,
      payload: {
        ...field,
        content,
      },
    });
  }
};

export const clearTemplateFields = () => (dispatch) => {
  dispatch(reset('templateSignForm'));
  dispatch({
    type: Types.signs.CLEAR_FIELDS,
  });
  return Promise.resolve();
};

export const removeTemplateField = field => dispatch =>
  dispatch({
    type: Types.signs.REMOVE_FIELD,
    payload: field,
  });

export const setSignScaleRatio = (scaleRatio, imageWidth) => ({
  type: Types.signs.SET_SCALE_RATIO,
  scaleRatio,
  imageWidth,
});

export const setSignPageNumber = pageNumber => ({
  type: Types.signs.SET_PAGE_NUMBER,
  pageNumber,
});

export const updateTemplateField = field => (dispatch) => {
  dispatch({
    type: Types.signs.UPDATE_FIELD,
    payload: field,
  });
};

export const updateTemplatePress = drag => (dispatch) => {
  dispatch({
    type: Types.signs.DRAG_FIELDS,
    payload: drag,
  });
};

export const createTemplate = uuid => async (dispatch, getState) => {
  try {
    dispatch(showLoader());
    await dispatch(fetchFields());
    const { standardFields } = getState().fields;
    const docFields = getState().signs.fields;

    const data = docFields.map(({
      field_id, height, width, x, y, content, value, pageNumber, type,
    }) => {
      let auxWidth = width;
      let auxHeight = height;
      switch (type) {
        case 'dot':
          auxWidth = width - 10;
          auxHeight = height / 5;
          break;
        default:
          break;
      }
      return ({
        fieldUuid: standardFields.find(field => field.id === field_id).uuid,
        pagePosition: pageNumber || 1,
        pageFieldDetail: {
          xCoord: x,
          yCoord: y,
          height: auxHeight,
          width: auxWidth,
          required: false,
        },
        fieldValue: content || value,
      });
    });

    const variables = { abbrevFields: data, documentUuid: uuid };
    const { currentOrganization } = getState().organizations;

    const { data: { fillAndSign: { signedDocument } } } = await graphqlClient(currentOrganization &&
      currentOrganization.subdomain).mutate({
      variables,
      mutation: FILL_SIGN,
    });

    dispatch({
      type: Types.signs.TEMPLATE_PAYLOAD,
      payload: signedDocument,
    });

    dispatch(hideLoader());
    dispatch(updateCurrentUser());
    return Promise.resolve(signedDocument);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const toggleSendDocumentModal = display => ({
  type: Types.signs.SEND_DOCUMENT_MODAL,
  display,
});

export const toggleSending = payload => ({
  type: Types.signs.SENDING,
  payload,
});

export const sendDocument = (uuid, payload) => async (dispatch) => {
  try {
    dispatch(toggleSending(true));
    await documentService.sendSignedDocument(
      uuid,
      uploadAndArchiveParser.fromFormToSendDocumentRequest.parseForm(payload),
    );

    toast.success(Messages.documents.send.success);

    dispatch(toggleSendDocumentModal(false));
  } catch (error) {
    toast.error(Messages.documents.send.error);
  } finally {
    dispatch(toggleSending(false));
  }
};

export const submitSendDocument = () => (dispatch) => {
  dispatch(submit('sendDocumentForm'));
};

export const loadFillAndSignDocument = documentId => async (dispatch) => {
  dispatch({
    type: Types.signs.LOADING_TEMPLATE,
    loading: true,
  });

  const { data: response } = await documentService.get(documentId);

  dispatch({
    type: Types.signs.SET_DOCUMENT,
    documents: [response],
  });

  dispatch({
    type: Types.signs.LOADING_TEMPLATE,
    loading: false,
  });
};

export const resetFillAndSign = () => ({
  type: Types.signs.RESET,
});


export default {
  createTemplateField,
  setSignPageNumber,
};
