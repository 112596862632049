import Types from '../constants/actionTypes';
import templateService from './../services/template';
import documentService from '../services/document';
import { templateParser } from '../utils/servicesParser';
import graphqlClient from '../config/graphql';
import { CREATE_TEMPLATE } from '../utils/mutations/templates';
import { FETCH_PLAN_USAGE } from '../screens/UserProfileScreen/queries';

const fetchUserPlanUsage = async (currentOrganization) => {
  try {
    const {
      data: { currentUser: { planUsage } },
    } = await graphqlClient(currentOrganization).query({
      query: FETCH_PLAN_USAGE,
      fetchPolicy: 'network-only',
    });
    return planUsage;
  } catch (e) {
    return null;
  }
};

export const setTemplateScreenField = (field, value) => ({
  type: Types.templateScreen.SET_FIELD,
  field,
  value,
});

export const addTemplateScreenRecipient = recipient => ({
  type: Types.templateScreen.ADD_RECIPIENT,
  recipient,
});

export const addTemplateScreenDocuments = documents => ({
  type: Types.templateScreen.ADD_DOCUMENTS,
  documents,
});

export const resetTemplateScreen = () => ({
  type: Types.templateScreen.RESET_SCREEN,
});

export const removeTemplateScreenDocument = document => (dispatch, getState) => {
  const { currentOrganization } = getState().organizations;

  documentService.delete(
    document.uuid,
    currentOrganization && currentOrganization.uuid ? currentOrganization.uuid : undefined,
  );

  dispatch({
    type: Types.templateScreen.REMOVE_DOCUMENT,
    document,
  });
};

export const createRole = () => (dispatch) => {
  dispatch({
    type: Types.templateScreen.ADD_ROLE,
  });
};

export const setRoles = roles => ({
  type: Types.templateScreen.SET_ROLES,
  roles,
});

export const updateRole = (i, role) => (dispatch) => {
  dispatch({
    type: Types.templateScreen.UPDATE_ROLES_DOCUMENTS,
    payload: { i, role },
  });
};

export const deleteRole = index => (dispatch) => {
  dispatch({
    type: Types.templateScreen.DELETE_ROLES_DOCUMENTS,
    index,
  });
};

const updateTemplate = async (template, organization, templateUuid) =>
  (await templateService.update(templateUuid, template, (organization || {}).uuid)).data || {};

const insertTemplate = async (template, organization) =>
  (await graphqlClient((organization || {}).subdomain).mutate({
    variables: {
      name: template.name,
      description: template.description,
      defaultMessage: template.default_message,
      completeInAnyOrder: template.complete_in_any_order,
      brandUuid: template.brandUuid,
      documentUuids: template.document_uuids,
      recipientsAttributes: template.recipients_attributes.map(recipient => ({
        role: recipient.role,
        signingOrder: recipient.signing_order,
        namePlaceholder: '',
        emailPlaceholder: '',
      })),
    },
    mutation: CREATE_TEMPLATE,
  })).data.createTemplate.template || {};

const saveTemplate = () => async (dispatch, getState) => {
  dispatch({
    type: Types.templateScreen.SAVE_IN_PROGRESS,
    saving: true,
  });

  const {
    auth: { user },
    templateScreen: { uuid: templateUuid, ...templateState },
    organizations: { currentOrganization: organization },
  } = getState();
  const template = templateParser.parseDataToRequest(templateState);
  const [operation, path] = templateUuid ? [updateTemplate, 'edit'] : [insertTemplate, 'new'];

  try {
    const data = await operation(template, organization, templateUuid);
    const planUsage = await fetchUserPlanUsage((organization || {}).subdomain);

    dispatch({ type: Types.editor.RESET });
    dispatch({
      type: Types.auth.LOGIN,
      payload: { ...user, planUsage },
    });
    dispatch({
      type: Types.templateScreen.SAVE_IN_PROGRESS,
      saving: false,
    });
    return Promise.resolve({
      response: data,
      route: `/documents-manager/templates/${data.uuid}/${path}`,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const loadTemplate = uuid => async (dispatch, getState) => {
  dispatch({
    type: Types.templateScreen.RESET_SCREEN,
  });

  dispatch({
    type: Types.templateScreen.SET_LOADING,
    loading: true,
  });

  const organizationUuid = getState().organizations.currentOrganization
    ? getState().organizations.currentOrganization.uuid
    : null;

  try {
    const { data: response } = await templateService.get(uuid, organizationUuid);

    dispatch(setTemplateScreenField('title', response.name));
    dispatch(setTemplateScreenField('description', response.description));
    dispatch(setTemplateScreenField('uuid', uuid));
    if (response.brand) {
      dispatch(setTemplateScreenField('selectedBrand', response.brand.uuid));
      dispatch(setTemplateScreenField('isSelectedBrandEnabled', true));
    }

    dispatch({
      type: Types.templateScreen.SET_ROLES,
      roles: response.recipients,
    });

    if (response && response.documents.length) {
      const documents = await Promise.all(response.documents.map(async (doc) => {
        const { data: document } = await documentService.get(doc.uuid);
        return document;
      }));
      dispatch(addTemplateScreenDocuments(documents));
    }

    dispatch({
      type: Types.templateScreen.SET_LOADING,
      loading: false,
    });
  } catch (e) {
    dispatch({
      type: Types.templateScreen.SET_LOADING,
      loading: false,
    });
  }
};

export const deleteTemplate = uuid => async (dispatch, getState) => {
  try {
    const { organizations: { currentOrganization }, auth: { user } } = getState();
    await templateService.delete(uuid);
    const planUsage = await fetchUserPlanUsage((currentOrganization || {}).subdomain);

    await dispatch({ type: Types.templateScreen.RESET_SCREEN });
    await dispatch({
      type: Types.auth.LOGIN,
      payload: { ...user, planUsage },
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export default {
  setTemplateScreenField,
  addTemplateScreenRecipient,
  addTemplateScreenDocuments,
  resetTemplateScreen,
  removeTemplateScreenDocument,
  createRole,
  setRoles,
  updateRole,
  deleteRole,
  saveTemplate,
  loadTemplate,
  deleteTemplate,
};
