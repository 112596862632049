import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  signatureRequest: null,
  loading: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.documentEditorScreen.SET_LOADING: {
      const { loading: isLoading, action: actionName } = action;
      const { loading: loadingInState } = state;
      const loading = JSON.parse(JSON.stringify(loadingInState));
      if (!actionName) {
        return {
          ...state,
        };
      }
      if (!isLoading) {
        delete loading[actionName];
      } else {
        loading[actionName] = true;
      }
      return {
        ...state,
        loading,
      };
    }

    case Types.documentEditorScreen.SET_SIGNATURE_REQUEST: {
      const { signatureRequest } = action;
      return {
        ...state,
        signatureRequest: { ...signatureRequest },
      };
    }

    default:
      return state;
  }
};

