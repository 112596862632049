import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { get } from 'lodash';
import { setSignDocumentField, setSignDocumentFields, setUserSignature } from '../../../actions/signDocumentScreen';
import { fetchDocumentDimensions } from '../../../actions/documentDimensions';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    componentDidMount() {
      // eslint-disable-next-line react/prop-types
      this.props.fetchDocumentDimensions();
    }
    render() {
      return (<Component {...this.props} />);
    }
  };


const mapStateToProps = ({ signDocumentScreen, documentDimensions, auth }) =>
  ({
    fields: signDocumentScreen.fields,
    documentDimensions,
    userSignature: get(auth, 'user.defaultSignature') || signDocumentScreen.userSignature,
  });

const mapDispatchToProps = () => ({
  setSignDocumentField,
  setSignDocumentFields,
  fetchDocumentDimensions,
  setUserSignature,
});

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));


export default connect;
