import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { some } from 'lodash';
import { Checkbox } from 'material-ui';
import { DocumentThumbnail } from '..';
import thumbImg from '../../assets/images/sample-thumbnail.png';

class SelectFiles extends React.Component {
  state = {}

  isSelected(document) {
    const { uniqueKey, selectedFiles } = this.props;
    return some(selectedFiles, { [uniqueKey]: document[uniqueKey] });
  }

  renderOptions() {
    const { uniqueKey, files, onSelection } = this.props;

    return files.map(document => (
      <Option key={document[uniqueKey]}>
        <OptionCheckbox
          checked={this.isSelected(document)}
          onCheck={(e, selected) => onSelection({ document, selected })}
        />
        <DocumentThumbnail thumbnailImage={thumbImg} file="base64..." />
      </Option>
    ));
  }

  render() {
    const { title } = this.props;
    return (
      <Container className="d-flex flex-column">
        <Title>{title}</Title>
        <OptionsContainer className="p-3">
          {/* {this.renderOptions()} */}
          <div>
            Server not providing uploaded documents list yet.
          </div>
        </OptionsContainer>
      </Container>
    );
  }
}

SelectFiles.propTypes = {
  title: PropTypes.string.isRequired,
  uniqueKey: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  onSelection: PropTypes.func.isRequired,
};

const Container = styled.div`
  width: 100%;
  height: 300px;
`;

const OptionsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Title = styled.div`
  font-size: 1.125rem;
  color: black;
`;

const Option = styled.div`
  display: inline-block;
  position: relative;
`;

const OptionCheckbox = styled(Checkbox)`
  position: absolute!important;
  top: .5rem;
  left: .5rem;
`;

export default SelectFiles;

