import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty, mapValues, omit } from 'lodash';
import { toast } from 'react-toastify';

import ConfirmDialog from '../ConfirmDialog';
import DeclineOrganizationInviteModal from '../modals/DeclineOrganizationInviteModal';
import DeclineOrganizationInviteSuccessModal from '../modals/DeclineOrganizationInviteSuccessModal';
import EntitiesSelectionModal from '../../components/modals/EntitiesSelectionModal';
import LogoImage from '../../assets/images/logo.svg';
import Messages from '../../constants/toastMessages';
import MemberLimitsReachedModal from '../modals/MemberLimitsReachedModal';
import OrganizationInviteModal from '../modals/OrganizationInviteModal';
import OrganizationInviteSuccessModal from '../modals/OrganizationInviteSuccessModal';
import PaymentInformationModal from '../modals/PaymentInformationModal';
import Sidenav from '../Sidenav/Sidenav';
import StopScheduledReportSuccessModal from '../modals/StopScheduledReportSuccessModal';
import SubscriptionConfirmation from '../modals/SubscriptionConfirmation';
import UpdatePlanModal from '../modals/UpdatePlanModal';
import EnterpriseLimitsReachedModal from '../modals/EnterpriseLimitsReachedModal';
import graphqlClient from '../../config/graphql';
import { FETCH_TEMPLATES } from '../../components/documentManager/TemplatesTableBody/queries';
import {
  FETCH_RESOURCES_COUNT_TO_DELETE, UPDATE_SUBSCRIPTION_PLAN,
} from '../../components/modals/EntitiesSelectionModal/queries';
import { UPDATE_CARD } from '../../components/forms/UpdateCreditCardForm/queries';
import { AppHeader } from '..';
import { AddFilesDialog } from '../filesManager';
import { parsePlan } from '../../utils/parsers/subscriptionParser';
import { spacing } from '../../utils';
import { subscribeUser } from '../../screens/PaymentScreen/connect';
import * as AuthActions from '../../actions/auth';
import * as ProfileActions from '../../actions/userProfileScreen';
import * as SidenavActions from '../../actions/sidenav';
import * as SubscriptionsActions from '../../actions/subscriptions';
import DeclineOrganizationTransferModal from '../modals/DeclineOrganizationTransferModal';
import DeclineOrganizationTransferSuccessModal from '../modals/DeclineOrganizationTransferSuccessModal';
import OrganizationTransferModal from '../modals/OrganizationTransferModal';
import OrganizationTransferSuccessModal from '../modals/OrganizationTransferSuccessModal';
import ErrorUpdateCreditCardModal from '../modals/ErrorUpdateCreditCardModal';

const parseCurrentPlan = ({ id, interval }, plans) => {
  if (interval === 'YEAR') return [];

  const currentPlan = plans.find(p => Object.keys(p.plansByInterval).length > 0
    && p.id === id);
  const info = currentPlan ? currentPlan.plansByInterval.YEAR : {};
  return info.uuid ? [{
    ...currentPlan,
    plansByInterval: { YEAR: info },
    uuid: info.uuid,
    amount: info.amount,
    interval: 'YEAR',
  }] : [];
};

const getItemsToRemove = async (subscription, currentPlan) => {
  if (subscription.id >= currentPlan.id) return {};

  try {
    const { data } = await graphqlClient().query({
      query: FETCH_RESOURCES_COUNT_TO_DELETE,
      fetchPolicy: 'network-only',
      variables: { uuid: subscription.uuid },
    });
    return omit(data.toBeDeletedResourcesCount, '__typename');
  } catch (e) {
    return {};
  }
};

const fetchTemplates = async (subdomain) => {
  try {
    const {
      data: { templates: { nodes } },
    } = await graphqlClient(subdomain).query({
      query: FETCH_TEMPLATES,
      fetchPolicy: 'network-only',
    });
    return nodes;
  } catch (e) {
    return [];
  }
};

const getAllTemplates = async ([org, ...organizations], allTemplates) => {
  if (!org) return allTemplates;

  const templates = await fetchTemplates(org.subdomain);

  return getAllTemplates(organizations, [
    ...allTemplates,
    ...templates.map(t => ({
      uuid: t.uuid,
      name: t.name,
      organization: org.name,
    })),
  ]);
};

const withAuthContent = Component => (props) => {
  const {
    /* eslint-disable react/prop-types */
    collapsedNav, setCollapseSidenav, organizations,
    setOpenUpdateModal, setUpgradeModal, setOpenPaymentModal, setOpenItemsSelectionModal,
    currentOrganization, fetchPlans, setSelectedSubscription, subscriptions,
    setCurrentAuthUser, setCurrentProfileUser, user,
    /* eslint-enable */
  } = props;
  const {
    email, uuid, currentPlan, cardInfo, planUsage, organizations: userOrganizations,
  } = user;
  const {
    openUpgradeModal, openUpdateModal, openPaymentModal, visiblePlans,
    plans, selectedSubscription, openItemsSelectionModal, newOrganizationUpgradeRequired,
  } = subscriptions;
  const { organizationUsersInUse: orgUsersInUse, templatesInUse } = planUsage || {};
  const [lastModal, setlastModal] = useState('');
  const [entities, setEntities] = useState({});
  const [items, setItems] = useState({ templates: { selected: [], unSelected: [] } });
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [itemsSelectionLoading, setItemsSelectionLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const orgMemberInfo = ((currentOrganization || {}).users || []).find(u => u.uuid === uuid);
  const isMember = orgMemberInfo && orgMemberInfo.role !== 'owner';
  const sidenavWidth = collapsedNav ? spacing.sidenavWidthCollapsed : spacing.sidenavWidth;
  const isEnterprisePlan = currentPlan.planType.toLowerCase().includes('enterprise');
  const upgradePlans = newOrganizationUpgradeRequired ? [
    ...parseCurrentPlan(currentPlan, plans),
    ...plans.filter(p => (p.id > currentPlan.id && p.planType !== 'FREE'
    && (p.organizationsLimit > 0 || p.organizationsLimit === undefined))),
  ] : [
    ...parseCurrentPlan(currentPlan, plans),
    ...plans.filter(p => (p.id > currentPlan.id && p.planType !== 'FREE')),
  ];

  const onClickContactUs = () => {
    window.location.href = `${process.env.REACT_APP_FRONT_PAGE_URL}/contact-us.html?email=${email}`;
  };

  const closeItemsSelection = () => {
    setlastModal('');
    setEntities({});
    setOpenItemsSelectionModal(false);
    setItemsSelectionLoading(false);
  };

  const goBackModal = () => {
    if (lastModal === 'upgrade') setUpgradeModal(true);
    else setOpenUpdateModal(true);
    setlastModal('');
    setOpenPaymentModal(false);
  };

  const updateCreditCard = async (createToken) => {
    const { token } = await createToken();
    if (!(token || {}).id) {
      toast.error('There was an error trying to update your new credit card. Please try again later.');
      goBackModal();
      return;
    }

    try {
      const {
        data: { updateCard: { cardInfo: newCardInfo } },
      } = await graphqlClient().mutate({
        mutation: UPDATE_CARD,
        variables: { cardToken: token.id },
      });
      setCurrentProfileUser({ ...user, cardInfo: newCardInfo });
      toast.success('Credit card updated successfully');
    } catch (e) {
      toast.error(e.message);
      throw (e);
    }
  };

  const submitPayment = async (creditCardOption = '', sprite = {}) => {
    setPaymentLoading(true);
    const { createToken } = sprite;

    if (creditCardOption === 'new' && currentPlan.planType !== 'FREE') {
      await updateCreditCard(createToken);
    }

    try {
      if (currentPlan.planType === 'FREE') {
        const { token } = await createToken();
        await subscribeUser({
          cardToken: token.id,
          planUuid: selectedSubscription.uuid,
        });
      } else {
        await graphqlClient().mutate({
          mutation: UPDATE_SUBSCRIPTION_PLAN,
          variables: {
            ...mapValues(items, d => d.unSelected.map(i => i.uuid)),
            uuid: selectedSubscription.uuid || '',
          },
        });
      }

      const updatedUser = await AuthActions.fetchCurrentUser();
      const updatedPlan = parsePlan(user.currentPlan);
      toast.success(Messages.subscription.success);
      setCurrentProfileUser(updatedUser);
      setCurrentAuthUser({ ...user, ...updatedUser, currentPlan: updatedPlan });
      setSelectedSubscription({});
    } catch (e) {
      toast.error(Messages.subscription.error);
    } finally {
      setOpenPaymentModal(false);
      closeItemsSelection();
      setPaymentLoading(false);
    }
  };

  const openEntitiesSelectionModal = async ({
    organizationUsersCountToRemove,
    templatesCountToRemove,
  }) => {
    setItemsSelectionLoading(true);
    setOpenItemsSelectionModal(true);

    const hasTemplatesToRemove = templatesCountToRemove !== 0;
    const templates = hasTemplatesToRemove ? await getAllTemplates(
      [{ name: 'No Organization' }, ...organizations],
      [],
    ) : [];
    const hasUsersToRemove = organizationUsersCountToRemove !== 0;
    const orgUsers = hasUsersToRemove ? Object.values(organizations.reduce((z, o) => ({
      ...z,
      ...o.users.reduce((users, u) => ({
        ...users,
        [u.uuid]: { uuid: u.uuid, name: u.full_name, organization: o.name },
      }), {}),
    }), {})) : [];

    setEntities({
      ...(hasTemplatesToRemove ? {
        templates: {
          list: templates,
          toRemove: templatesCountToRemove,
          limit: Math.abs(templatesInUse - templatesCountToRemove),
          label: 'Templates',
          min: 1,
        },
      } : {}),
      ...(hasUsersToRemove ? {
        orgUsers: {
          list: orgUsers,
          toRemove: organizationUsersCountToRemove,
          limit: Math.abs(orgUsersInUse - organizationUsersCountToRemove),
          label: 'Organization Users',
        },
      } : {}),
    });

    setItemsSelectionLoading(false);
  };

  useEffect(() => {
    if (plans.length === 0) fetchPlans();
  }, []);

  return (
    <div key={uuid} style={{ minHeight: '100vh', overflow: 'hidden' }} className="d-flex">
      <Sidenav onCollapse={setCollapseSidenav} open={!collapsedNav} />
      <AppHeader key={(userOrganizations || {}).length} openSidenav={!collapsedNav} />
      <Content className="w-100 align-self-stretch" sidenavWidth={sidenavWidth}>
        <Component {...props} />
      </Content>

      <MemberLimitsReachedModal
        open={currentOrganization && isMember && openUpgradeModal}
        onClose={() => setUpgradeModal(false)}
      />

      {/* UPDATE PLAN */}
      <UpdatePlanModal
        title={<LogoContainer><Logo src={LogoImage} /></LogoContainer>}
        open={openUpdateModal}
        selectedSubscription={selectedSubscription}
        onClickCancel={() => { setOpenUpdateModal(false); setSelectedSubscription({}); }}
        onClickSubscription={async (s) => {
          await setSelectedSubscription(s);
          setlastModal('update');
          if (s.id < currentPlan.id) setOpenConfirmation(true);
          else setOpenPaymentModal(true);
          setOpenUpdateModal(false);
        }}
        onClickContactUs={onClickContactUs}
        plans={newOrganizationUpgradeRequired ? upgradePlans : plans}
        currentPlan={currentPlan}
        visiblePlans={visiblePlans}
      />

      {/* UPGRADE PLAN */}
      <UpdatePlanModal
        title="Upgrade your account to access this feature!"
        open={!isMember && !isEnterprisePlan && openUpgradeModal}
        onClickCancel={() => { setUpgradeModal(false); setSelectedSubscription({}); }}
        onClickSubscription={async (s) => {
          await setSelectedSubscription(s);
          setlastModal('upgrade');
          setOpenPaymentModal(true);
          setUpgradeModal(false);
        }}
        selectedSubscription={selectedSubscription}
        onClickContactUs={onClickContactUs}
        plans={upgradePlans}
      />

      {/* ENTERPRISE PLAN LIMITS REACHED */}
      <EnterpriseLimitsReachedModal
        open={!isMember && isEnterprisePlan && openUpgradeModal}
        onClickCancel={() => setUpgradeModal(false)}
        onClickContactUs={onClickContactUs}
      />

      {/* DOWNGRADE PLAN */}
      {openItemsSelectionModal && (
        <EntitiesSelectionModal
          loading={itemsSelectionLoading}
          onConfirm={(selectedItems) => {
            setItems(selectedItems);
            setOpenPaymentModal(true);
            closeItemsSelection();
          }}
          onCancel={() => {
            setOpenUpdateModal(true);
            closeItemsSelection();
          }}
          entities={entities}
        />
      )}

      {/* PAYMENT INFORMATION */}
      <PaymentInformationModal
        loading={paymentLoading}
        formTitle={`${isEmpty(cardInfo) ? 'Enter' : 'Confirm'} your payment information to upgrade your plan. `}
        open={openPaymentModal}
        selectedSubscription={selectedSubscription}
        onBack={goBackModal}
        user={user}
        submit={submitPayment}
        creditCardInfo={cardInfo || {}}
      />

      {/* SUBSCRIPTION CONFIRMATION */}
      <SubscriptionConfirmation
        isOpen={openConfirmation}
        onConfirm={async () => {
          if (selectedSubscription.planType === 'FREE') {
            submitPayment();
          } else {
            const itemsToRemove = await getItemsToRemove(selectedSubscription, currentPlan);
            const hasItemsToPick = Object.values(itemsToRemove).some(i => i !== 0);
            if (hasItemsToPick) openEntitiesSelectionModal(itemsToRemove);
            else setOpenPaymentModal(true);
          }
          setOpenConfirmation(false);
        }}
        onCancel={() => {
          goBackModal();
          setOpenConfirmation(false);
        }}
        selectedSubscription={selectedSubscription}
      />

      {/* HANDLE FILES */}
      <AddFilesDialog />
      <ConfirmDialog />

      {/* CREDIT CARD ERROR */}
      <ErrorUpdateCreditCardModal />

      {/* ORGANIZATION INVITES */}
      <DeclineOrganizationInviteModal />
      <DeclineOrganizationInviteSuccessModal />
      <OrganizationInviteModal />
      <OrganizationInviteSuccessModal />

      {/* ORGANIZATION TRANSFER INVITES */}
      <DeclineOrganizationTransferModal />
      <DeclineOrganizationTransferSuccessModal />
      <OrganizationTransferModal />
      <OrganizationTransferSuccessModal />

      {/* SCHEDULED REPORTS */}
      <StopScheduledReportSuccessModal />
    </div>
  );
};

withAuthContent.propTypes = {
  user: PropTypes.object.isRequired,
  fetchPlans: PropTypes.func.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
  setOpenUpdateModal: PropTypes.func.isRequired,
};

const Content = styled.div`
  padding: ${spacing.appHeaderSize} 2.75rem 0.5rem ${p => p.sidenavWidth + 24}px;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
`;

export const Logo = styled.img`
  width: 220px;
  height: auto;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
  align-self: center;
`;

const mapStateToProps = ({
  auth: { user, loading }, sidenav, subscriptions, organizations,
}) => ({
  user,
  loading,
  collapsedNav: sidenav.collapsed,
  subscriptions,
  organizations: organizations.list,
  currentOrganization: organizations.currentOrganization,
});

const mapDispatchToProps = {
  ...SubscriptionsActions,
  setCollapseSidenav: SidenavActions.setCollapseSidenav,
  setCurrentAuthUser: AuthActions.setCurrentUser,
  setCurrentProfileUser: ProfileActions.setCurrentUser,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthContent,
);
