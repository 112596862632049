import { isEmpty } from 'lodash';

const editorParser = {
  fromSignatureRequestToEditor: {
    parseField: (field) => {
      const { page_field_detail } = field;
      return {
        width: Number(page_field_detail.width),
        height: Number(page_field_detail.height),
        x: Number(page_field_detail.x_coord),
        y: Number(page_field_detail.y_coord),
        required: page_field_detail.required,
        pageId: field.page.id,
        fieldType: field.field.id,
        id: field.id,
        recipient: field.recipient,
        recipientUuid: field.recipient ? field.recipient.uuid : field.recipient_uuid,
        name: field.field.name,
        ...editorParser.fromSignatureRequestToEditor.parseFieldMetadata(field),
      };
    },
    /**
     *
     * @param field The field returned in the BE response
     * @returns {*} metadata attributes needed for the page field in the editor
     */
    parseFieldMetadata: (field) => {
      const metadata = {};
      if (!field.metadata || isEmpty(field.metadata)) {
        return metadata;
      }
      if (!isEmpty(field.metadata.options)) {
        metadata.options = field.metadata.options;
        metadata.selected_option = field.metadata.selected_option &&
          field.metadata.options[field.metadata.selected_option - 1];
      }
      metadata.checked = !!field.metadata.checked;
      metadata.radio_button_group_id = field.metadata.radio_button_group_id;
      return metadata;
    },
    parseFields: (signatureRequest) => {
      const { documents } = signatureRequest;
      const fields = [];
      documents.forEach((document) => {
        document.page_fields.forEach((pageField) => {
          fields.push(editorParser.fromSignatureRequestToEditor.parseField(pageField));
        });
      });
      return fields;
    },
  },
  fromEditorToDocumentRequest: {
    parseRequest: (document, fields) => ({
      document: {
        file_name: document.file_name,
        fields: editorParser.fromEditorToDocumentRequest.parseFields(fields),
      },
    }),
    parseFields: (fields) => {
      if (isEmpty(fields)) {
        return [];
      }
      return fields
        .filter(field => !field._destroy)
        .map(field => ({
        /* id: !field.isNew ? field.id : undefined, BE is deleting all page fields for the moment */
          page_id: field.pageId,
          _destroy: (field._destroy && !field.isNew) ? field._destroy : undefined,
          recipient_uuid: field.recipientUuid,
          page_field_detail_attributes: {
            x_coord: field.x,
            y_coord: field.y,
            height: field.height,
            width: field.width,
            required: field.required ? field.required : false,
          },
          field_id: field.fieldType,
          value: !isEmpty(field.value) ? field.value : undefined,
          metadata: editorParser.fromEditorToDocumentRequest.parseFieldMetadata(field),
        }));
    },
    parseFieldMetadata: (field) => {
      if (field.options && field.options.length) {
        const metaData = {
          options: field.options,
        };
        if (!isEmpty(field.selected_option)) {
          metaData.selected_option = field.options.indexOf(field.selected_option) + 1;
        }
        return metaData;
      }

      if (field.checked && field.radio_button_group_id) {
        return {
          checked: true,
          radio_button_group_id: parseInt(field.radio_button_group_id, 10),
        };
      }

      if (field.radio_button_group_id) {
        return {
          radio_button_group_id: parseInt(field.radio_button_group_id, 10),
        };
      }


      if (field.checked) {
        return {
          checked: true,
        };
      }
      return undefined;
    },
  },
};

export default editorParser;
