import React, { Component } from 'react';
import { connect as ReduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import history from '../../config/history';
import sendDocumentActions from '../../actions/sendDocumentScreen';
import uploadAndArchiveActions from '../../actions/uploadAndArchive';
import graphqlClient from '../../config/graphql';
import { SEND_SIGNATURE_REQUEST_COPY } from './mutations';
import { parseValues } from './parser';
import Messages from '../../constants/toastMessages';

const renderWrappedComponent = WrappedComponent =>
  class extends Component {
    componentDidMount = () => {
      // eslint-disable-next-line react/prop-types
      const { signatureRequest } = this.props;
      if (!signatureRequest) history.push('/send-document');
    }

    getPreviewUrl = () => {
      // eslint-disable-next-line react/prop-types
      const { signatureRequest } = this.props;
      const { previewImage, preview_url = '' } = signatureRequest || {};

      return previewImage ? previewImage.url : preview_url;
    }

    sendAnotherDocument = () => history.push('/send-document');

    sendCopy = async (values) => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization, signatureRequest } = this.props;
      try {
        const {
          data: {
            errors,
          },
        // eslint-disable-next-line react/prop-types
        } = await graphqlClient(currentOrganization &&
          currentOrganization.subdomain).mutate({
          mutation: SEND_SIGNATURE_REQUEST_COPY,
          variables: parseValues({ signatureRequest, ...values }),
        });
        toast.success(Messages.documents.send.success);
        return { errors };
      } catch (e) {
        toast.error(Messages.documents.send.error);
        return { e };
      }
    }

    render() {
      // eslint-disable-next-line react/prop-types
      const { signatureRequest } = this.props;
      return (<WrappedComponent
        {...this.props}
        title={signatureRequest ? signatureRequest.subject : ''}
        previewUrl={this.getPreviewUrl()}
        onSendAnother={this.sendAnotherDocument}
        sendCopy={this.sendCopy}
      />);
    }
  };

const mapStateToProps = ({ documentEditorScreen, organizations }) => ({
  currentOrganization: organizations.currentOrganization,
  signatureRequest: documentEditorScreen.signatureRequest,
});

const mapDispatchToProps = () => {
  const { dispatchSendDocumentField } = sendDocumentActions;
  const { submitSendArchived } = uploadAndArchiveActions;
  return {
    dispatchSendDocumentField,
    submitSendArchived,
  };
};

const connect = WrappedComponent =>
  ReduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
