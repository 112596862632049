import { findIndex } from 'lodash';

import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  displayModal: false,
  modalTitle: 'Add API Key',
  clientApps: [],
  loading: false,
  currentApiKey: {},
  generating: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.apiAndKeysSection.TOGGLE_MODAL: {
      const { displayModal, modalTitle } = action;
      const currentApiKey = displayModal ? state.currentApiKey : {};
      return {
        ...state,
        displayModal,
        currentApiKey,
        modalTitle: modalTitle || INITIAL_STATE.modalTitle,
      };
    }
    case Types.apiAndKeysSection.LOADING: {
      const { loading } = action;
      return {
        ...state,
        loading,
      };
    }
    case Types.apiAndKeysSection.APPS_LOADED: {
      const { clientApps } = action;
      return {
        ...state,
        clientApps,
      };
    }
    case Types.apiAndKeysSection.API_KEY_ADDED: {
      const { newApiKey } = action;
      const current = state.clientApps;
      current.push(newApiKey);
      return {
        ...state,
        clientApps: current,
      };
    }
    case Types.apiAndKeysSection.API_KEY_REMOVED: {
      const { id } = action;

      const index = findIndex(state.clientApps, v => v.id === id);

      const { clientApps } = state;

      clientApps.splice(index, 1);

      return {
        ...state,
        ...clientApps,
      };
    }
    case Types.apiAndKeysSection.API_KEY_REGENERATED: {
      const { response } = action;

      const newKeys = state.clientApps.map((v) => {
        if (v.id === response.id) {
          return response;
        }
        return v;
      });

      return {
        ...state,
        clientApps: newKeys,
      };
    }
    case Types.apiAndKeysSection.SET_CURRENT_API_KEY: {
      const { currentApiKey } = action;
      return {
        ...state,
        currentApiKey,
      };
    }
    case Types.apiAndKeysSection.GENERATING_API_KEY: {
      const { generating } = action;
      return {
        ...state,
        generating,
      };
    }

    case Types.apiAndKeysSection.UPDATE_API_KEY: {
      const { apiKey } = action;
      const { clientApps } = state;
      const clientAppsCopy = JSON.parse(JSON.stringify(clientApps));
      const index = findIndex(clientAppsCopy, v => v.id === apiKey.id);
      if (index >= 0) {
        clientAppsCopy[index] = { ...apiKey };
      }
      return {
        ...state,
        clientApps: [...clientAppsCopy],
      };
    }
    default:
      return state;
  }
};

