import React, { useEffect, useState } from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { get, isEmpty } from 'lodash';

import Messages from '../../constants/toastMessages';
import actionsAuth from '../../actions/auth';
import graphqlClient from '../../config/graphql';
import history from '../../config/history';
import subscriptionActions from '../../actions/subscriptions';
import { SUBSCRIBE_USER, GET_PLAN } from './queries';
import { PaymentScreen } from '../PaymentScreen';
import { parsePlan } from '../../utils/parsers/subscriptionParser';

const fetchPlan = async (uuid) => {
  try {
    const { data } = await graphqlClient().query({
      query: GET_PLAN,
      fetchPolicy: 'network-only',
      variables: { uuid },
    });
    return data.plan;
  } catch (e) {
    return {};
  }
};

const connect = Component => (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    user, currentOrganization, setCurrentUser, setSelectedSubscription, setUpgradeModal,
  } = props;
  const [loading, setLoading] = useState(true);
  const [enterprisePlan, setEnterprisePlan] = useState({});

  const loadSelectedPlan = async () => {
    // eslint-disable-next-line react/prop-types
    const { match: { params: { plan_uuid } } } = props;
    if (isEmpty(user)) {
      history.push(`/login?enterprisePlanUuid=${plan_uuid}`);
    } else {
      const plan = parsePlan(await fetchPlan(plan_uuid));
      setEnterprisePlan({ ...plan, headerText: undefined });
      setLoading(false);
    }
  };

  useEffect(() => {
    setUpgradeModal(false);
    loadSelectedPlan();
  }, []);

  const subscribeUser = async (cardToken, planUuid) => {
    try {
      await graphqlClient(get(currentOrganization, 'subdomain')).mutate({
        mutation: SUBSCRIBE_USER,
        variables: { cardToken, planUuid },
      });
      const updatedPlan = await parsePlan(enterprisePlan);
      const updatedUser = await actionsAuth.fetchCurrentUser();
      toast.success(Messages.subscription.success);
      setCurrentUser({ ...user, ...updatedUser, currentPlan: updatedPlan });
      setSelectedSubscription({});
      history.push('/');
    } catch (e) {
      toast.error(Messages.subscription.error);
      throw e;
    }
  };

  const componentProps = {
    ...props,
    loading,
    subscribeUser,
    selectedSubscription: { ...enterprisePlan, onClickAction: undefined },
    secondaryActionLabel: '',
  };

  return <Component {...componentProps} />;
};

const mapDispatchToProps = () => ({
  ...subscriptionActions,
  setCurrentUser: actionsAuth.setCurrentUser,
});

export default RConnect(({ subscriptions, auth }) => ({
  ...subscriptions, user: auth.user,
}), mapDispatchToProps())(connect(PaymentScreen));
