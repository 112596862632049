import React from 'react';
import PropTypes from 'prop-types';

import EmptyListNotification from '../../EmptyListNotification';
import SortableTable from '../../SortableTable';
import documentManagerParser from '../../../utils/documentManagerParser';
import { dateFormats } from '../../../utils';

export const CompletedDocumentsTable = (props) => {
  const {
    documents: propDocs, selectedDocument, setSelectedDocument, loading,
    handleOnChangePage, currentPageInfo,
  } = props;
  const columns = [{
    id: 'name', label: 'Name', field: 'name', type: 'text',
  }, {
    id: 'subject', label: 'Subject', field: 'subject', type: 'text',
  }, {
    id: 'sender', label: 'Sender', field: 'sender', type: 'text',
  }, {
    id: 'recipients', label: 'Recipients', field: 'recipients', type: 'text',
  }, {
    id: 'date', label: 'Date Received', field: 'date', type: 'date',
  }];

  if (!loading && (propDocs || []).length === 0) {
    return (
      <EmptyListNotification
        message="Nothing to see here! You do not have any completed documents."
        link="/send-document"
        buttonLabel="Send Signature Request"
      />
    );
  }

  const data = (propDocs || []).map(d => ({
    uuid: d.uuid,
    name: d.name,
    subject: d.subject,
    sender: d.sender,
    recipients: documentManagerParser.getEmailsFromRecipients(d.recipients.nodes),
    date: d.activityLogs.nodes
      && d.activityLogs.nodes[0]
      && d.activityLogs.nodes[0].eventDate
      && dateFormats.fullMonthDate(d.activityLogs.nodes[0].eventDate),
    document: d,
  }));

  return (
    <SortableTable
      {...currentPageInfo}
      onChangePage={handleOnChangePage}
      data={data}
      onClickRow={row => setSelectedDocument(row.document, 'completed')}
      isSelectedRow={row => row.uuid === (selectedDocument || {}).uuid}
      columns={columns}
      loading={loading}
    />
  );
};

CompletedDocumentsTable.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocument: PropTypes.object,
  setSelectedDocument: PropTypes.func,
  loading: PropTypes.bool,
  handleOnChangePage: PropTypes.func.isRequired,
  currentPageInfo: PropTypes.object.isRequired,
};

CompletedDocumentsTable.defaultProps = {
  documents: [],
  selectedDocument: {},
  setSelectedDocument: () => {},
  loading: false,
};

export default CompletedDocumentsTable;
