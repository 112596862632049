import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DragSource, DropTarget } from 'react-dnd';
import Types from './Types';
import DraggableItemDivider from './DraggableItemDivider';

const dropTargetSpec = {
  drop(props, monitor) {
    const { index: toIndex, move, possibleMovement } = props;
    const { index: fromIndex } = monitor.getItem();
    move(fromIndex, toIndex, possibleMovement);
  },

  hover(props, monitor, component) {
    if (!component) {
      return null;
    }
    const { setPossibleMovement } = props;
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return null;
    }

    // Determine rectangle on screen
    // eslint-disable-next-line
    const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (hoverClientY < hoverMiddleY) {
      setPossibleMovement('top');
    } else {
      setPossibleMovement('bottom');
    }
    return null;
  },
};

// Dnd Collector
function dropTargetCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

// DnD Spec
const dragSourceSpec = {
  beginDrag(props) {
    const { index } = props;
    return { index };
  },
};

// DnD Collector
function dragSourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class DraggableItem extends Component {
  render() {
    const {
      connectDropTarget, connectDragSource, isOver, possibleMovement, children,
    } = this.props;
    return connectDropTarget(
      <div>
        <Container>
          <DraggableItemDivider isHover={isOver && possibleMovement === 'top'} />
          {connectDragSource(<div>{children}</div>)}
          <DraggableItemDivider isHover={isOver && possibleMovement === 'bottom'} />
        </Container>
      </div>,
    );
  }
}

DraggableItem.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  possibleMovement: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

DraggableItem.defaultProps = {};
const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export default DropTarget([Types.DRAGGABLE_LIST_ITEM], dropTargetSpec, dropTargetCollect)(
  DragSource(Types.DRAGGABLE_LIST_ITEM, dragSourceSpec, dragSourceCollect)(DraggableItem),
);
