import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton, FlatButton } from 'material-ui';
import { withFormik } from 'formik';
import { isEmpty, orderBy } from 'lodash';
import * as Yup from 'yup';

import AddNewOrganizationModal from '../../components/modals/AddNewOrganizationModal';
import SignatureForm from '../../components/forms/SignatureForm/SignatureForm';
import SectionLoader from './../../components/SectionLoader';
import ProfileOrganizationForm from '../../components/forms/ProfileOrganizationForm';
import UserProfileForm from '../../components/forms/UserProfileForm/';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../../components/pages';
import { UserProfileSchema } from '../../components/forms/UserProfileForm/UserProfileForm';
import { isAdmin } from '../../utils/users';

const UserSchema = Yup.object().shape({
  profile: UserProfileSchema,
});

export const UserProfileScreen = (props) => {
  const {
    values, errors, touched, setFieldValue, loading,
    user, updateProfile, updateUserPassword, userProfileScreen, userRole,
    user: { planUsage: { organizationsInUse, organizationsLimit } },
    toggleUserProfileOrganizationModal, setUpgradeModal,
    leaveOrganization, deleteOrganization, currentOrganization, organizations: orgs,
    subscription, onChangePlan, documentDimensions,
  } = props;
  const [newOrganizationData, setNewOrganizationData] = useState({});

  const showOrganizationModal = () => {
    if (Number.isInteger(organizationsLimit) && organizationsInUse + 1 > organizationsLimit) {
      setUpgradeModal(true, true);
    } else {
      toggleUserProfileOrganizationModal(true);
    }
  };

  const renderSections = () => {
    const organizations = orderBy(orgs.map(o => ({
      ...o,
      isOwner: o.users.some(u => u.uuid === user.uuid && u.role === 'owner'),
    })), ['isOwner'], 'desc');

    return (
      <Container>
        <UserProfileForm
          values={values.profile}
          errors={errors.profile}
          touched={touched.profile}
          subscription={subscription}
          onChangePlan={onChangePlan}
          currentOrganization={currentOrganization}
          onClickResetPassword={updateUserPassword}
          setFieldValue={(f, v) => setFieldValue(`profile[${f}]`, v)}
        />

        <SignatureForm
          documentDimensions={documentDimensions}
          onChange={(f, v) => setFieldValue(`profile[${f}]`, v)}
          initialValues={values.profile}
        />

        {!isEmpty(organizations) && (
          <div className="mt-5">
            <OrganizationHeader className="w-full p-3">
              Organizations
            </OrganizationHeader>
            {organizations.map(org => (
              <div
                className="bg-white p-3 organization-section"
                style={{ border: '1px solid #EFEFEF' }}
              >
                <ProfileOrganizationForm
                  isOwner={org.isOwner}
                  initialValues={org}
                  onClickDelete={deleteOrganization}
                  leaveOrganization={leaveOrganization}
                  onChange={data => setNewOrganizationData({
                    ...newOrganizationData,
                    [data.uuid]: data,
                  })}
                  currentUser={user}
                  currentOrganization={org}
                />
              </div>
            ))}
          </div>
        )}
      </Container>
    );
  };

  const { loadingProfile, updatingProfile } = userProfileScreen;
  const customActions = [
    () => isEmpty(currentOrganization) && isAdmin([userRole]) && (
      <FlatButton
        primary
        label="Add Organization"
        title="Add organization"
        backgroundColor="white"
        onClick={showOrganizationModal}
      />
    ),
    () => (
      <RaisedButton
        primary
        label={updatingProfile ? 'Saving...' : 'Save'}
        title={updatingProfile ? 'Saving...' : 'Save'}
        disabled={updatingProfile}
        onClick={() => updateProfile(values, newOrganizationData)}
      />
    ),
  ];

  return (
    <PageContainer>
      <PageHeader title="Profile" />
      <AddNewOrganizationModal />
      <PageContent>
        {loading || loadingProfile || updatingProfile
          ? <SectionLoader />
          : renderSections()}
      </PageContent>
      <PageFooter customActions={customActions} />
    </PageContainer>
  );
};

UserProfileScreen.propTypes = {
  loading: PropTypes.bool,
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  userProfileScreen: PropTypes.object.isRequired,
  toggleUserProfileOrganizationModal: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  userRole: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  leaveOrganization: PropTypes.object.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
  onChangePlan: PropTypes.func.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

UserProfileScreen.defaultProps = {
  loading: false,
};

const Container = styled.div`
  width: 90%;
  margin-bottom: 4rem;

  .organization-section + .organization-section {
    margin-top: 1.5rem;
  }
`;

const OrganizationHeader = styled.div`
  background-color: #ECEFF1;
  font-size: 20px;
  font-family: DIN-Medium;
`;

export default withFormik({
  validationSchema: UserSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ currentUser }) => ({
    profile: currentUser,
  }),
})(UserProfileScreen);
