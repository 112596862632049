import React from 'react';
import styled from 'styled-components';

const DocumentProtectSection = () => (
  <SectionContainer>
    <SectionTitle>Downloaded Agreement:</SectionTitle>
    <SectionText>
      Anyone who recieves a copy of the signed PDF can open it and validate its
      authencity in Adobe Reader or Adobe Acrobat.
    </SectionText>
  </SectionContainer>
);

const SectionContainer = styled.div`
  margin: 1rem 0.5rem;
`;

const SectionTitle = styled.h5`
  font-size: 1.1rem;
`;

const SectionText = styled.p`
  font-size: 0.8rem;
`;

export default DocumentProtectSection;
