import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LogoUploader from '../../LogoUploader';

import { Modal } from '../..';

const UploadImageModal = ({
  isOpen, title, onClickOk, onClickCancel, image,
}) => (
  <StyledModal
    open={isOpen}
    title={title}
    content={<LogoUploader onClickOk={onClickOk} onClickCancel={onClickCancel} image={image} />}
    actions
  />
);

UploadImageModal.propTypes = {
  onClickOk: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  image: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

UploadImageModal.defaultProps = {
  title: '',
  image: '',
};

const StyledModal = styled(Modal)`
  & > div > div > div {
    padding: 2rem 0rem 0rem;
  }

  & > div > div {
    max-width: 640px !important;
  }
`;


export default UploadImageModal;
