import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SelectField, MenuItem } from 'material-ui';

import BrandTooltipIcon from '../../assets/images/brand-tooltip.svg';
import Tooltip from '../Tooltip';

const renderDefaultOption = name => (
  <OptionContainer>
    <div className="name">{name}</div>
    <div className="default">Default</div>
  </OptionContainer>
);

const SelectBrandField = ({
  enabled, currentBrand, showError, onSelectBrand, brands = [], style,
}) => (
  <Wrapper style={style}>
    <SelectField
      disabled={!enabled}
      style={{ marginTop: '-12px', marginBottom: '1rem' }}
      dropDownMenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      value={currentBrand}
      hintText="Use Brand"
      errorText={showError && 'This field is required'}
      onChange={(e, i, value) => onSelectBrand(value)}
    >
      {brands.map(({ uuid, default: isDefault, name }) => (
        <MenuItem
          key={uuid}
          value={uuid}
          primaryText={isDefault ? renderDefaultOption(name) : name}
        />
      ))}
    </SelectField>
    <BrandTooltipWrapper>
      <Tooltip title="Add a brand style to your document. These can be managed in your organization.">
        <BrandTooltip src={BrandTooltipIcon} />
      </Tooltip>
    </BrandTooltipWrapper>
  </Wrapper>
);

SelectBrandField.propTypes = {
  enabled: PropTypes.bool,
  currentBrand: PropTypes.string,
  showError: PropTypes.bool,
  onSelectBrand: PropTypes.func,
  brands: PropTypes.array,
  style: PropTypes.object,
};

SelectBrandField.defaultProps = {
  enabled: false,
  currentBrand: undefined,
  showError: false,
  onSelectBrand: () => {},
  brands: [],
  style: {},
};

const Wrapper = styled.div`
  display: flex;

  .tooltip-wrapper {
    font-size: 18px;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  width: 100%;

  & > .name {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flext-start;
  }

  & > .default {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flext-end;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto;
  }
`;

const BrandTooltipWrapper = styled.div`
  margin-left: 20px;
`;

const BrandTooltip = styled.img``;

export default SelectBrandField;
