import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { groupBy, isEmpty } from 'lodash';

import SubscriptionCard from '../../components/SubscriptionCard';

const plansAreEqual = (a, b) => b.uuid === a.uuid && b.planType === a.planType;
const updateWithPlan = ({ planType, interval }) => (to) => {
  const plan = (planType === to.planType && interval && to.plansByInterval[interval]) || {};
  const fields = plan.uuid ? { interval, uuid: plan.uuid, amount: plan.amount } : {};
  return { ...to, ...fields, hovered: to.planType === planType };
};

export const SubscriptionPlans = ({
  onClickSubscription, currentPlan, plans, selectedSubscription, visiblePlans = 'all',
}) => {
  const auxPlan = isEmpty(selectedSubscription) ? currentPlan : selectedSubscription;
  const plansByCat = groupBy(plans, 'category');
  const [currentTab, setCurrentTab] = useState(auxPlan.category || (plans[0] || {}).category);
  const parsedPlans = plansByCat[currentTab].map(p => (currentPlan.id === p.id
    ? { ...p, ...p.plansByInterval[currentPlan.interval] }
    : p));
  const [currentPlans, setCurrentPlans] = useState(parsedPlans);
  const tabs = visiblePlans === 'all' ? Object.keys(plansByCat) : [visiblePlans];

  const onChangeInterval = plan => interval =>
    setCurrentPlans(currentPlans.map(updateWithPlan({ ...plan, interval })));
  const onChangeTab = tabLabel => () => {
    setCurrentPlans(plansByCat[tabLabel].map(updateWithPlan(currentPlan)));
    setCurrentTab(tabLabel);
  };

  useEffect(() => {
    if (tabs.length === 1) {
      onChangeTab(tabs[0])();
    }
  }, []);

  const renderTab = tabLabel => (
    <Tab
      key={tabLabel}
      selected={currentTab === tabLabel}
      onClick={onChangeTab(tabLabel)}
    >
      {tabLabel.toUpperCase()}
    </Tab>
  );

  const renderSubscriptionPlan = sp => (
    <SubscriptionCard
      {...sp}
      hoverActivated
      key={sp.uuid || sp.planType || sp.id}
      style={{ margin: '0rem 0.8rem' }}
      onClickAction={() => onClickSubscription(sp)}
      selected={plansAreEqual(currentPlan, sp)}
      actionLabel={plansAreEqual(currentPlan, sp) ? 'Your Plan' : sp.actionLabel}
      onChangeInterval={onChangeInterval(sp)}
    />
  );

  return (
    <>
      {tabs.length >= 1 && (
        <TabHeaders>
          <TabSection className="m-auto">
            {tabs.map(renderTab)}
          </TabSection>
        </TabHeaders>
      )}
      <div className="w-full mt-5 justify-content-center align-content-center d-flex">
        {currentPlans.map(renderSubscriptionPlan)}
      </div>
    </>
  );
};

SubscriptionPlans.propTypes = {
  onClickSubscription: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selectedSubscription: PropTypes.object,
  currentPlan: PropTypes.object,
  visiblePlans: PropTypes.string.isRequired,
};

SubscriptionPlans.defaultProps = {
  currentPlan: {},
  selectedSubscription: {},
};

const Tab = styled.div`
  min-width: 110px;
  transition: all 0.3s ease-in-out;
  height: 2.5rem;
  color: #5198B9;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2rem;
  cursor: pointer;
  border: 1px solid ${props => (props.selected ? '#3BA9F4' : '#EEEEEE')};
  background-color: ${props => (props.selected ? '#D7E6ED' : '#EEEEEE')};
`;

const TabSection = styled.div`
  display: inline-flex;
  border-radius: 2rem;
  background-color: #EEEEEE;
`;
const TabHeaders = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export default SubscriptionPlans;
