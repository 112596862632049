import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import CancelIcon from '../assets/images/cancel.svg';
import DocumentShareSection from './documentManager/DocumentShareSection';
import DocumentInputsSection from './documentManager/DocumentInputsSection';
import DocumentProtectSection from './documentManager/DocumentProtectSection';
import DocumentRemindSection from './documentManager/DocumentRemindSection';
import DocumentViewSection from './documentManager/DocumentViewSection';
import DocumentHistorySection from './documentManager/DocumentHistorySection';
import DocumentNotesSection from './documentManager/DocumentNotesSection/DocumentNotesSection';
import TabBar from './TabBar';

class DocumentManagerOptionsMenu extends React.Component {
  state = {
    selectedTab: null,
  }

  componentDidUpdate = (prevProps) => {
    const { tab: prevTab, selectedDocument: prevSelectedDocument } = prevProps;
    const { tab, selectedDocument } = this.props;
    if (!selectedDocument) {
      return 0;
    }
    const availableTabsForDocument = this.getTabs(selectedDocument);

    /* Loads tab based on props when the selected document changed */
    if (this.isSelectedDocumentDifferent(prevSelectedDocument, selectedDocument)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedTab: !isEmpty(availableTabsForDocument) ? availableTabsForDocument[0].value : null,
      });
    }

    /* Change the tab if the tab props changes */
    if (tab && tab !== prevTab
      && availableTabsForDocument.map(t => t.value).includes(tab)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedTab: tab,
      });
      return 0;
    }
    return 0;
  }


  getTabs = (selectedDocument) => {
    const tabs = [];
    const noteTypes = ['outForSignature', 'archived', 'template', 'signed'];

    if (selectedDocument && selectedDocument.previewUrl && !isEmpty(selectedDocument.previewUrl)) {
      tabs.push({ label: 'View', value: 'view' });
    }

    if (selectedDocument && selectedDocument.share) {
      tabs.push({ label: 'Share', value: 'share' });
    }

    tabs.push({ label: 'Protect', value: 'protect' });

    if (selectedDocument && !isEmpty(selectedDocument.recipients)
      && selectedDocument.sendReminder) {
      tabs.push({ label: 'Remind', value: 'remind' });
    }

    if (selectedDocument && !isEmpty(selectedDocument.history)) {
      tabs.push({ label: 'History', value: 'history' });
    }

    if (selectedDocument && noteTypes.includes(selectedDocument.type)) {
      tabs.push({ label: 'Notes', value: 'notes' });
    }

    return tabs;
  }

  isSelectedDocumentDifferent = (prevSelectedDocument, selectedDocument) =>
    (!prevSelectedDocument && selectedDocument) ||
    (prevSelectedDocument && !selectedDocument) ||
    (prevSelectedDocument.uuid !== selectedDocument.uuid)

  handleTabChange = (selectedTab) => { this.setState({ selectedTab }); }

  renderTabContent = (selectedTab, tabs) => {
    const { selectedDocument, onShare, selectedDocType } = this.props;

    switch (selectedTab || tabs[0].value) {
      case 'history':
        return <DocumentHistorySection selectedDocument={selectedDocument} />;
      case 'view':
        return (
          <DocumentViewSection
            selectedDocument={selectedDocument}
            selectedDocType={selectedDocType}
          />);
      case 'share':
        return <DocumentShareSection selectedDocument={selectedDocument} onShare={onShare} />;
      case 'protect':
        return <DocumentProtectSection selectedDocument={selectedDocument} />;
      case 'remind':
        return <DocumentRemindSection />;
      case 'notes':
        return <DocumentNotesSection selectedDocument={selectedDocument} />;
      default:
        return null;
    }
  };

  renderCloseButton = () => (
    <ButtonsContainer className="d-flex">
      <CloseButton onClick={this.props.onClose}>
        <img src={CancelIcon} alt="close" />
      </CloseButton>
    </ButtonsContainer>
  );

  render = () => {
    const {
      selectedDocument,
    } = this.props;

    const { selectedTab } = this.state;
    const tabs = this.getTabs(selectedDocument);

    return (
      <Container
        id="document-side-option-menu"
        className="w-100 h-100 d-flex flex-column"
      >
        {this.renderCloseButton()}
        <DocumentInputsSection />
        <TabBar
          defaultTab={selectedTab || tabs[0].value}
          tabs={tabs}
          scrollable
          tabChanged={this.handleTabChange}
          withoutShadow
        />
        <div className="d-flex flex-column h-100" style={{ marginTop: '30px' }}>
          {this.renderTabContent(selectedTab, tabs)}
        </div>
      </Container>
    );
  }
}

DocumentManagerOptionsMenu.propTypes = {
  tab: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  selectedDocType: PropTypes.string,
  selectedDocument: PropTypes.shape({
    history: PropTypes.array.isRequired,
    notes: PropTypes.object,
    uuid: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    subject: PropTypes.string,
    name: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    recipients: PropTypes.array,
    date: PropTypes.string,
    description: PropTypes.string,
    sendReminder: PropTypes.bool,
    previewUrl: PropTypes.string,
    fileUrl: PropTypes.string,
    reminders: PropTypes.array,
    status: PropTypes.string,
  }).isRequired,
};

DocumentManagerOptionsMenu.defaultProps = {
  tab: null,
  selectedDocType: '',
};

const Container = styled.div`
  overflow-y: auto;
  background-color: white;
  padding: 1rem;
  box-shadow:
    0 0 2px 0 rgba(0,0,0,0.14),
    0 2px 2px 0 rgba(0,0,0,0.12),
    0 1px 3px 0 rgba(0,0,0,0.2);
`;

const ButtonsContainer = styled.div`
  padding-bottom: 10px;
  border-bottom: 2px solid whitesmoke;
`;

const CloseButton = styled.div`
  cursor: pointer;
  padding: 5px;
`;

export default DocumentManagerOptionsMenu;
