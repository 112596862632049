import gql from 'graphql-tag';

export const VOID_SIGNATURE_REQUEST = gql`
mutation VoidSignatureRequest(
  $uuid: ID!,
  $voidReason: String!
  ){
    voidSignatureRequest(
    uuid: $uuid,
    voidReason: $voidReason,
  ){
    signatureRequest{
      uuid
    }
  }
}
`;

export const CREATE_REMINDER = gql`
mutation CreateReminder(
  $signatureRequestUuid: ID!,
  $message: String!,
  $frequency: FrequencyEnum!,
  $options: ReminderOptions
  ){
  createReminder(
    signatureRequestUuid: $signatureRequestUuid,
    message: $message,
    frequency: $frequency,
    options: $options,
  ){
    reminder {
      uuid
    }
  }
}
`;

export default {
  VOID_SIGNATURE_REQUEST,
  CREATE_REMINDER,
};
