import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { SelectField, MenuItem, TextField } from 'material-ui';
import { colors } from '../../../utils';

const DropdownFields = ({ field, onChange }) => {
  const [options, setOptions] = useState('');
  const [optionsText, setOptionsText] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    if (!selectedOption || !selectedOption !== field.selected_option) {
      setSelectedOption(field && field.selected_option);
    }
    if (field.options && (!options || !isEqual(options, field.options))) {
      // eslint-disable-next-line no-use-before-define
      handleChangeOptions(field && field.options.join('\n'));
    }
  });

  const handleChangeOptions = (optionsValues) => {
    setOptionsText(optionsValues);
    const newOptions = optionsValues.split('\n').filter(o => o.length);
    setOptions(newOptions);
    onChange('options', newOptions);
  };

  return (
    <Container>
      <InputContent>
        <div>Default Value</div>
        <div>
          <SelectField
            value={(field.selected_option || selectedOption)}
            onChange={(e, ind, val) => onChange('selected_option', val)}
          >
            {field.options && field.options.map(option => (
              <MenuItem key={option} value={option} primaryText={option} />
            ))}
          </SelectField>
        </div>
      </InputContent>
      <div className="dropdown drop-header">Dropdown Options</div>
      <InputContent>
        <div className="text-multiple">
          <TextField
            value={(optionsText)}
            onChange={(e, val) => handleChangeOptions(val)}
            fullWidth
            multiLine
            rows={2}
            rowsMax={2}
          />
        </div>
      </InputContent>
      <div className="dropdown" style={{ fontSize: '0.8rem' }}>Enter each dropdown option on a separate line.</div>
    </Container>
  );
};


DropdownFields.propTypes = {
  field: PropTypes.any,
  onChange: PropTypes.func,
};

DropdownFields.defaultProps = {
  field: {},
  onChange: () => {},
};

const InputContent = styled.div`
  color: ${colors.defaultImageGray};
  display: flex;
  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Container = styled.div`
  width: 100%;

  & label {
    color: ${colors.defaultImageGray} !important;
  }

  & .dropdown {
    color: ${colors.defaultImageGray} !important;
  }

  & .drop-header {
    margin: 1rem 0rem;
  }

  & .text-multiple {
    border: 1px solid ${colors.gray};
    border-radius: 0.2rem;
    & textarea {
      margin: 0rem !important;
      height: 100% !important;
    }
    & > div {
      height: 82px !important;
    }
    & hr {
      display: none;
    }
  }
`;

export default DropdownFields;
