export const parseValues = ({
  signatureRequest, brand, recipients, cc_self, subject, message,
}) => ({
  uuid: signatureRequest.uuid,
  brandUuid: brand,
  recipients,
  ccMe: cc_self || false,
  subject: subject || ' ',
  message: message || ' ',
});

export default parseValues;
