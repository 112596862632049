import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  loading: false,
  actionsLoading: {},
  documents: [],
  templates: [],
  archivedDocuments: [],
  documentsOutForSignature: [],
  documentsWaitingForMe: [],
  sideOptionsOpen: false,
  selectedDocument: null,
  selectedDocType: null,
  documentShared: false,
  selectedTemplate: null,
  sendTemplateModal: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.documentManager.SET_LOADING: {
      const { loading, action: actionName } = action;

      return {
        ...state,
        loading,
        actionsLoading: {
          ...state.actionsLoading,
          [actionName]: loading,
        },
      };
    }

    case Types.documentManager.TEMPLATES_LOADED: {
      const { payload } = action;
      return {
        ...state,
        templates: payload,
      };
    }

    case Types.documentManager.DOCUMENTS_LOADED: {
      const { payload } = action;
      return {
        ...state,
        documents: payload,
      };
    }

    case Types.documentManager.SET_SELECTED_DOCUMENT: {
      const { document, docType } = action;
      return {
        ...state,
        selectedDocument: document,
        selectedDocType: docType,
      };
    }

    case Types.documentManager.SET_SELECTED_TEMPLATE: {
      const { template } = action;
      return { ...state, selectedTemplate: template };
    }

    case Types.documentManager.OPEN_SIDE_OPTIONS_MENU: {
      return { ...state, sideOptionsOpen: true };
    }

    case Types.documentManager.CLOSE_SIDE_OPTIONS_MENU: {
      return {
        ...state,
        sideOptionsOpen: false,
        selectedDocument: {},
      };
    }

    case Types.documentManager.SHARE_DOCUMENT: {
      return { ...state, documentShared: true };
    }

    case Types.documentManager.CLEAR_SHARE_DOCUMENT: {
      return { ...state, documentShared: false };
    }

    case Types.documentManager.TOGGLE_SEND_TEMPLATE_MODAL: {
      return { ...state, sendTemplateModal: action.display };
    }

    case Types.documentManager.SET_ARCHIVED_DOCUMENTS: {
      const { archivedDocuments } = action;
      return {
        ...state,
        archivedDocuments,
      };
    }

    case Types.documentManager.SET_DOCUMENTS_WAITING_FOR_ME: {
      const { documentsWaitingForMe } = action;
      return {
        ...state,
        documentsWaitingForMe,
      };
    }

    case Types.documentManager.UPDATE_NOTES: {
      const { notes, documentType, uuid } = action;
      const stateCopy = JSON.parse(JSON.stringify(state));
      let arrayToUpdate = '';
      switch (documentType) {
        case 'outForSignature': {
          arrayToUpdate = 'documentsOutForSignature';
          break;
        }
        case 'waitingForMe': {
          arrayToUpdate = 'documentsWaitingForMe';
          break;
        }
        case 'completed': {
          arrayToUpdate = 'documents';
          break;
        }
        case 'template': {
          arrayToUpdate = 'templates';
          break;
        }
        case 'archived': {
          arrayToUpdate = 'archivedDocuments';
          break;
        }
        default:
          break;
      }
      if (arrayToUpdate) {
        stateCopy[arrayToUpdate].forEach((document) => {
          if (document.uuid === uuid) {
            document.notes = notes;
          }
        });
      }
      return {
        ...stateCopy,
      };
    }

    default:
      return state;
  }
};
