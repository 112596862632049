import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import analyticsScreenActions from '../../../actions/analyticsScreen';

const renderWrappedComponent = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const { setStopReportScheduledSuccessModal, reportStopedSuccessfullyModal } = props;
  const handleClose = () => setStopReportScheduledSuccessModal(false);

  return (
    <Component
      {...props}
      open={reportStopedSuccessfullyModal}
      onClose={handleClose}
    />
  );
};


const mapStateToProps = ({ analyticsScreen }) => ({
  reportStopedSuccessfullyModal: analyticsScreen.reportStopedSuccessfullyModal,
});

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, {
    setStopReportScheduledSuccessModal: analyticsScreenActions.setStopReportScheduledSuccessModal,
  })(renderWrappedComponent(WrappedComponent));

export default connect;
