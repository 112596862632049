import gql from 'graphql-tag';

export const FETCH_USER_ACTIVITY_REPORT = gql`
  query fetchUserActivityReport(
    $startDate: IsoTime!
    $endDate: IsoTime!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    userActivityReport(
      startDate: $startDate
      endDate: $endDate
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      nodes {
        email
        senderName
        envelopesCompleted
        envelopesSent
        # averageCompletionTime
        # envelopesDeclined
        # envelopesPartiallySigned
        # envelopesVoided
        # totalEnvelopes
      }
    }
  }
`;

export default { FETCH_USER_ACTIVITY_REPORT };
