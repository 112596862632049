import { toast } from 'react-toastify';
import { snakeCase } from 'lodash';
import Types from '../constants/actionTypes';
import userService from './../services/user';
import { updateOrganization } from './organization';

export const updateProfile = (
  newUserData, organizationsData,
) => async (dispatch, getState) => {
  dispatch({
    type: Types.userProfileScreen.UPDATING_PROFILE,
    updatingProfile: true,
  });

  const userInfo = getState().auth.user;
  const data = {};
  Object
    .keys(newUserData.profile)
    .forEach((key) => {
      data[snakeCase(key)] = newUserData.profile[key];
    });

  await Promise.all(Object
    .keys(organizationsData)
    .map(async uuid => dispatch(updateOrganization(uuid, organizationsData[uuid]))));

  try {
    delete data.email;
    const { data: response } = await userService.update(userInfo.uuid, data);

    // update the auth
    dispatch({
      type: Types.auth.LOGIN,
      payload: { ...userInfo, ...response },
    });

    toast.success('Your profile was sucessfully updated');
  } catch (err) {
    toast.error('An error happened while trying to update the profile. Please try again', 'Error');
  }

  dispatch({
    type: Types.userProfileScreen.UPDATING_PROFILE,
    updatingProfile: false,
  });
};

export const updateUserPassword = values => async (dispatch) => {
  dispatch({
    type: Types.userProfileScreen.UPDATING_PROFILE,
    updatingProfile: true,
  });

  try {
    if (values.password && values.newPassword && values.confirmPassword) {
      await userService.resetPassword({
        current_password: values.password,
        password: values.newPassword,
        password_confirmation: values.confirmPassword,
      });
    }

    toast.success('Your Password was sucessfully updated');
  } catch (err) {
    toast.error('An error happened while trying to update the password. Please try again', 'Error');
  }

  dispatch({
    type: Types.userProfileScreen.UPDATING_PROFILE,
    updatingProfile: false,
  });
};

export const setCurrentUser = currentUser => async dispatch => dispatch({
  type: Types.userProfileScreen.SET_CURRENT_USER,
  currentUser,
});

export const toggleUserProfileOrganizationModal = displayModal => ({
  type: Types.userProfileScreen.TOGGLE_ORGANIZATION_MODAL,
  displayModal,
});

export default {
  updateProfile,
  updateUserPassword,
  toggleUserProfileOrganizationModal,
  setCurrentUser,
};
