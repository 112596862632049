import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { SendReportForm } from '../../forms';

const SendReportModal = ({ sendReportModal }) => (
  <Dialog
    title="Send Report"
    autoScrollBodyContent
    open={sendReportModal}
    contentStyle={{ width: '600px', padding: '100px 0' }}
    titleStyle={{ paddingTop: '40px' }}
  >
    <SendReportForm />
  </Dialog>
);

SendReportModal.propTypes = {
  sendReportModal: PropTypes.bool.isRequired,
};

export default SendReportModal;
