import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RaisedButton, FlatButton } from 'material-ui';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../../components/pages';
import OrganizationMemberForm from '../../components/forms/OrganizationMemberForm';
import { colors } from '../../utils';
import BackIconSrc from '../../assets/images/blue-back.svg';
import history from '../../config/history';
import connect from './connect';

export class OrganizationMemberScreen extends Component {
  state = {
    userId: '',
    values: {},
    touched: false,
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { id } = match.params;
    this.setState({ userId: id });
  }

  getActions = () => {
    const { touched } = this.state;
    return [
      () => (
        <RaisedButton
          primary
          label="Save"
          onClick={this.handleSave}
          buttonStyle={{ backgroundColor: colors.darkBlue, width: '150px' }}
          disabled={!touched}
        />
      ),
      () => (
        <FlatButton
          primary
          label="Cancel"
          onClick={() => history.push('/organizations')}
        />
      ),
    ];
  }

  handleSave = () => {
    const { values, touched } = this.state;
    const { updateUserRole } = this.props;
    if (touched) updateUserRole(values.uuid, values.role);
  }

  handleFormChange = values => this.setState({ values, touched: true })

  renderBackSection = () => (
    <BackSection
      onClick={() => history.push('/organizations')}
    >
      <BackIcon src={BackIconSrc} />
      <BackText>MEMBERS</BackText>
    </BackSection>
  );

  render = () => {
    const { userId } = this.state;
    return (
      <PageContainer>
        <PageHeader title="Organization" />
        <PageContent>
          {this.renderBackSection()}
          <FormWrapper>
            {userId && (
              <OrganizationMemberForm
                userId={userId}
                onChange={this.handleFormChange}
              />
            )}
          </FormWrapper>
        </PageContent>
        <PageFooter customActions={this.getActions()} />
      </PageContainer>
    );
  }
}

OrganizationMemberScreen.propTypes = {
  match: PropTypes.object.isRequired,
  updateUserRole: PropTypes.func.isRequired,
};

const BackSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: -15px;
  margin-bottom: 25px;
  margin-left: 5px;
`;

const BackIcon = styled.img`
  height: 12px;
`;
const BackText = styled.div`
  color: #03a9f4;
  font-size: 14px;
  font-family: DIN-Medium;

  margin-left: 15px;
  padding: 2px;
`;

const FormWrapper = styled.div`
  background-color: white;
  padding: 30px 50px;
`;

export default connect(OrganizationMemberScreen);
