import { toast } from 'react-toastify';
import { head, isEmpty } from 'lodash';
import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import documentService from '../services/document';
import templateService from '../services/template';
import { addSendDocumentDocuments, toggleUseExistingTemplateModal } from './sendDocumentScreen';
import { getOrganizationUuid } from '../utils/organization';
import { enableOrganizationChange, disableOrganizationChange } from './organization';

export const setAddFilesDialog = (showAddFilesDialog, addFilesCallback = null, handler = null,
  propsDialog = {}) => ({
  type: Types.filesManager.SET_ADD_FILES_DIALOG,
  showAddFilesDialog,
  addFilesCallback,
  handler,
  propsDialog,
});

const uploadDocuments = files => async (dispatch, getState) => {
  try {
    dispatch(disableOrganizationChange());
    dispatch({
      type: Types.filesManager.SET_UPLOADING,
      uploading: true,
    });

    const documents = [];

    const formData = new FormData();
    formData.append('document[file_name]', files[0].name);
    formData.append('document[file]', files[0]);

    const { currentOrganization } = getState().organizations;
    if (currentOrganization && currentOrganization.uuid) {
      formData.append('document[organization_id]', currentOrganization.uuid);
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch({
          type: Types.filesManager.SET_UPLOAD_PROGRESS,
          uploadProgress: percentCompleted,
        });
        if (percentCompleted === 100) {
          dispatch({
            type: Types.filesManager.SET_WORK_IN_PROGRESS,
            payload: {
              wip: true,
              wipMessage: 'Creating Document',
            },
          });
        }
      },
    };

    const response = await documentService.create(currentOrganization
      && currentOrganization.subdomain, formData, config);
    documents.push(response.data);
    dispatch({
      type: Types.filesManager.SET_DOCUMENTS,
      documents,
    });

    dispatch({
      type: Types.filesManager.SET_WORK_IN_PROGRESS,
      payload: {
        wip: false,
        wipMessage: '',
      },
    });
    return Promise.resolve();
  } catch (e) {
    if (e.request.status === 0) {
      toast.error('Document upload request timed out.');
      dispatch(setAddFilesDialog(false));
    } else if (e.response.data && e.response.data.details
      && e.response.data.details.document_size) {
      toast.error('Document size is not supported. Supported pdf sizes are A4 and letter');
    } else if (e.response.data && e.response.data.details &&
      e.response.data.details.file && e.response.data.details.file.length) {
      toast.error(`File ${e.response.data.details.file[0]}`);
    } else {
      toast.error(Messages.documents.upload.error);
    }
    dispatch({
      type: Types.filesManager.SET_UPLOADING,
      uploading: false,
    });
    dispatch(enableOrganizationChange());
    return Promise.reject(e);
  }
};

const archiveDocuments = files => (dispatch) => {
  dispatch({
    type: Types.filesManager.SET_UPLOADING,
    uploading: true,
  });
  dispatch({
    type: Types.filesManager.SET_UPLOAD_PROGRESS,
    uploadProgress: 1,
  });
  dispatch({
    type: Types.filesManager.SET_DOCUMENTS,
    documents: [files[0]],
  });
  dispatch({
    type: Types.filesManager.SET_UPLOAD_PROGRESS,
    uploadProgress: 100,
  });
  dispatch({
    type: Types.filesManager.SET_WORK_IN_PROGRESS,
    payload: {
      wip: false,
      wipMessage: '',
    },
  });
};

export const uploadFiles = files => (dispatch, getState) => {
  const handlers = {
    uploadDocuments,
    archiveDocuments,
  };

  if (typeof getState().filesManager.handler === 'function') {
    getState().filesManager.handler(files);
  } else {
    dispatch(handlers[getState().filesManager.handler](files));
  }

  return Promise.resolve(files);
};

export const fetchDocuments = () => { };

/**
 *
 * @param ignoreTemplateEmptyDocuments ignores the templates that doesn't have any document
 * @param readyToUse query only the template that are ready to use.
 * Example if it has one signature field per recipient
 *
 */
export const getTemplateList =
  (ignoreTemplateEmptyDocuments, readyToUse) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Types.filesManager.SET_LOADING,
        loading: true,
      });

      const organizationUuid = getOrganizationUuid(getState());
      let { data: templates } = await templateService.list(organizationUuid, readyToUse);

      if (ignoreTemplateEmptyDocuments) {
        templates = templates.filter((template) => {
          const { documents } = template;
          return !isEmpty(documents);
        });
      }
      const payload = templates.map(({ uuid, name }) => ({ name, uuid }));

      dispatch({
        type: Types.filesManager.GET_LIST_TEMPLATES,
        payload,
      });
    } catch (error) {
      toast.error(Messages.templates.get.error);
    } finally {
      dispatch({
        type: Types.filesManager.SET_LOADING,
        loading: false,
      });
    }
  };

export const getDocumentFromTemplate = ({ documents }) => async (dispatch) => {
  try {
    const document = !isEmpty(documents) ? head(documents) : null;

    const response = await documentService.get(document.uuid);

    dispatch({
      type: Types.filesManager.SET_DOCUMENTS_TMP,
      payload: response.data,
    });
    return Promise.resolve(response);
  } catch (error) {
    toast.error(Messages.documents.get.error);
    return Promise.reject(error);
  }
};

export const setDocumentTmp = document => async (dispatch) => {
  dispatch({
    type: Types.filesManager.SET_DOCUMENTS_TMP,
    payload: document,
  });
};

export const setTemplateFromList = uuid => async (dispatch, getState) => {
  try {
    const response = await templateService.get(uuid, getOrganizationUuid(getState()));

    dispatch({
      type: Types.filesManager.SET_TEMPLATE,
      payload: response.data,
    });

    dispatch(getDocumentFromTemplate(response.data));
    return Promise.resolve();
  } catch (error) {
    toast.error(Messages.templates.set.error);
    return Promise.reject(error);
  }
};

export const clearDocuments = () => ({
  type: Types.filesManager.CLEAR_DOCUMENTS,
  payload: {},
});

export const setDocumentFromTemplate = () => async (dispatch, getState) => {
  const { documentTmp } = getState().filesManager;

  dispatch({
    type: Types.filesManager.SET_DOCUMENTS,
    documents: documentTmp,
  });

  dispatch(addSendDocumentDocuments([documentTmp]));
  dispatch(toggleUseExistingTemplateModal(false));
};

export const setPreviousDocuments = () => (dispatch, getState) => {
  const { documents } = getState().filesManager;

  dispatch({
    type: Types.filesManager.SET_PREVIOUS_DOCUMENT,
    documents,
  });
};

export const restoreFromPrevious = () => (dispatch, getState) => {
  const { previousDocuments } = getState().filesManager;

  dispatch({
    type: Types.filesManager.SET_DOCUMENTS,
    documents: previousDocuments,
  });
};

export default {
  setAddFilesDialog,
  uploadFiles,
  fetchDocuments,
  getTemplateList,
  getDocumentFromTemplate,
  setDocumentTmp,
  setTemplateFromList,
  clearDocuments,
  setDocumentFromTemplate,
  setPreviousDocuments,
  restoreFromPrevious,
};
