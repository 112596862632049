import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import drawIcon from '../../assets/images/fill_sign_icons/sign-icon.svg';
import TextIcon from '../../assets/images/fill_sign_icons/text-icon.svg';
import signatureIcon from '../../assets/images/fields_panel_icons/initials.png';
import calendarIcon from '../../assets/images/fill_sign_icons/calendar-icon.svg';
import CrossIcon from '../../assets/images/fill_sign_icons/cross-icon.svg';
import CircleIcon from '../../assets/images/fill_sign_icons/circle-icon.svg';
import CheckIcon from '../../assets/images/fill_sign_icons/check-icon.svg';
import LineIcon from '../../assets/images/fill_sign_icons/line-icon.svg';
import DotIcon from '../../assets/images/fill_sign_icons/dot-icon.svg';
import PageFieldOption from './PageFieldOption';

const buttons = {
  text_label: {
    minHeight: '60px',
    margin: '0 0.6rem',
    cursor: 'grab',
    minWidth: '1.5rem',
  },
  icon_label: {
    minHeight: '60px',
    margin: '0 0.6rem',
    cursor: 'grab',
  },
  initial_label: {
    minHeight: '60px',
    minWidth: '6rem',
    margin: '0 0.6rem',
    cursor: 'grab',
  },
  signed_date_label: {
    minWidth: '6rem',
    margin: '0 0.6rem',
    minHeight: '60px',
    cursor: 'grab',
  },
  image_label: {
    minWidth: '3rem',
    margin: '0 0.6rem',
    minHeight: '60px',
    cursor: 'grab',
  },
  label: {
    color: 'gray',
    textTransform: 'capitalize',
    cursor: 'grab',
  },
};

const PageFieldsToolBar = ({ standardFields }) => (
  <HeaderContainer>
    <HeaderContent>
      <PageFieldOption
        icon={TextIcon}
        style={buttons.text_label}
        standardFields={standardFields}
        type="input"
      />
      <Separator />
      <PageFieldOption
        icon={drawIcon}
        style={buttons.icon_label}
        standardFields={standardFields}
        type="signature"
        label="Sign"
      />
      <Separator />
      <PageFieldOption
        icon={signatureIcon}
        style={buttons.initial_label}
        standardFields={standardFields}
        type="initials"
        label="Initials"
      />
      <Separator />
      <PageFieldOption
        icon={calendarIcon}
        style={buttons.signed_date_label}
        type="date_picker"
        label="Date"
      />
      <Separator />
      <PageFieldOption
        icon={CrossIcon}
        style={buttons.image_label}
        standardFields={standardFields}
        type="cross"
      />
      <Separator />
      <PageFieldOption
        icon={CheckIcon}
        style={buttons.image_label}
        standardFields={standardFields}
        type="check"
      />
      <Separator />
      <PageFieldOption
        icon={CircleIcon}
        style={buttons.image_label}
        standardFields={standardFields}
        type="circle"
      />
      <Separator />
      <PageFieldOption
        icon={LineIcon}
        style={buttons.image_label}
        standardFields={standardFields}
        type="line"
      />
      <Separator />
      <PageFieldOption
        icon={DotIcon}
        style={buttons.image_label}
        standardFields={standardFields}
        type="dot"
      />
    </HeaderContent>
  </HeaderContainer>
);

const HeaderContainer = styled.section`
  width: 100%;
  height: 80px;
  margin-top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderContent = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
   & button:hover {
     
    background-color: #D1EBF6 !important;
   }
`;

const Separator = styled.div`
  width: 2px;
  height: 28px;
  background: #DEDEDE;
`;

PageFieldsToolBar.propTypes = {
  standardFields: PropTypes.array.isRequired,
};

export default PageFieldsToolBar;
