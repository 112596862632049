import gql from 'graphql-tag';

export const SUBSCRIBE_USER = gql`
  mutation SubscribeUser(
    $cardToken: String!
    $planUuid: ID!
  ) {
    subscribeUser(
      cardToken: $cardToken
      planUuid: $planUuid
    ) {
      success
    }
  }
`;

export const GET_PLAN = gql`
  query Plan($uuid: ID!) {
    plan(uuid: $uuid) {
      active
      amount
      apiAccess
      currency
      interval
      name
      nickname
      organizationMembersLimit
      organizationsLimit
      planType
      templatesLimit
      uuid
    }
  }
`;

export default {
  SUBSCRIBE_USER,
  GET_PLAN,
};
