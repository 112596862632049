import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField as TextFieldCore } from '@material-ui/core';
import styled from 'styled-components';

const CustomTextField = (props) => {
  const [focused, setFocused] = useState(false);
  return (
    <TextField
      {...props}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      focused={focused}
    />
  );
};


CustomTextField.propTypes = {
  message: PropTypes.string.isRequired,
  style: PropTypes.object,
};

CustomTextField.defaultProps = {
  style: {},
};

const TextField = styled(TextFieldCore)`
& label {
  color: ${props => (props.focused ? 'rgb(3, 169, 244) !important' : '#B7B7B7 !important')};
}
  & input:focus{
    border-bottom: 2.5px solid rgb(3, 169, 244) !important;
  }
  & *::after{
      border: 0px !important;
  }
`;


export default CustomTextField;
