import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { CardNumberElement, CardCvcElement, CardExpiryElement, injectStripe } from 'react-stripe-elements';
import { Form, withFormik } from 'formik';
import { TextField, RaisedButton, FlatButton, CircularProgress } from 'material-ui';
import { Tooltip } from '@material-ui/core';
import * as Yup from 'yup';
import hintIcon from '../../../assets/images/hint-icon.svg';
import lockIcon from '../../../assets/images/lock-icon.svg';
import { colors } from '../../../utils';
import SubscriptionCard from '../../SubscriptionCard';

const CreditCardSchema = Yup.object().shape({
  name: Yup.string().ensure()
    .required('Required'),
  cardNumber: Yup.string().matches(/^(\d{4}[- ]){3}\d{4}|\d{16}$/, 'Must be a valid credit card number.').ensure().required('Required'),
  month: Yup.string().ensure()
    .required('Required'),
  year: Yup.string().ensure()
    .required('Required'),
  cvv: Yup.string().min(3, 'Must be more than 3 digits.').max(4, 'Must be less than 4 digits.').ensure()
    .required('Required'),
});

class ErrorUpdateCreditCardForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      focusedField: '',
      validCardNumber: false,
      validExpiration: false,
      validCvc: false,
    };
  }

  setValue = (field, value) => {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldTouched(field, true, false);
    setFieldValue(field, value);
  }

  async submit() {
    const { onUpdate, values, onCancel } = this.props;
    const { token } = await this.props.stripe.createToken({ name: values.name });
    await onUpdate(token);
    onCancel();
  }

  renderLoadingSpinner = () => (
    <div
      style={{ minHeight: 570 }}
      className="d-flex align-items-center justify-content-center"
    >
      <CircularProgress size={60} thickness={7} />
    </div>
  );

  renderForm = () => {
    const {
      values, errors, touched, currentUser: { cardInfo, downgradeAt },
    } = this.props;
    const dateDifferenceDays = moment(downgradeAt).diff(moment(), 'days') || 0;
    const { focusedField } = this.state;
    return (
      <StyledForm>
        <FormTitle>Update your credit card information!</FormTitle>
        <FormSubtitle>
          You have <strong>{dateDifferenceDays} days</strong> to
           update your credit card information to avoid loosing access to your plan!
        </FormSubtitle>
        <PreviousCardTitle>Current Credit Card</PreviousCardTitle>
        <PreviousCardSection>
          <PreviousCardItem>
            <PreviousCardInfo>{`*${cardInfo.cardLastDigts}`}</PreviousCardInfo>
            <PreviousCardLabel>Card Number</PreviousCardLabel>
          </PreviousCardItem>
          <PreviousCardItem>
            <PreviousCardInfo>{cardInfo.cardBrand}</PreviousCardInfo>
            <PreviousCardLabel>Brand</PreviousCardLabel>
          </PreviousCardItem>
          <PreviousCardItem>
            <PreviousCardInfo>{`${cardInfo.cardExpirationMonth} / ${cardInfo.cardExpirationYear}`}</PreviousCardInfo>
            <PreviousCardLabel>Exp. Date</PreviousCardLabel>
          </PreviousCardItem>
        </PreviousCardSection>
        <TextField
          fullWidth
          value={values.name}
          onChange={e => this.setValue('name', e.target.value)}
          errorText={touched.name && errors.name}
          floatingLabelText="Name on card *"
          maxLength={30}
        />
        <InputContainer focused={focusedField === 'cardNumber'}>
          <div className="header">Card Number*</div>
          <CardNumberElement
            onChange={e => this.setState({ validCardNumber: e.complete })}
            onFocus={() => this.setState({ focusedField: 'cardNumber' })}
            onBlur={() => this.setState({ focusedField: '' })}
          />
        </InputContainer>

        <Stack style={{ marginBottom: '40px', width: '560px' }}>
          <InputContainer focused={focusedField === 'cardExpiration'} style={{ marginRight: '0.5rem' }}>
            <div className="header">Expiration Date*</div>
            <CardExpiryElement
              onChange={e => this.setState({ validExpiration: e.complete })}
              onFocus={() => this.setState({ focusedField: 'cardExpiration' })}
              onBlur={() => this.setState({ focusedField: '' })}
            />
          </InputContainer>
          <InputContainer focused={focusedField === 'cardCvc'} style={{ marginLeft: '0.5rem' }}>
            <div className="header">CVC/CVV*</div>
            <CardCvcElement
              onChange={e => this.setState({ validCvc: e.complete })}
              onFocus={() => this.setState({ focusedField: 'cardCvc' })}
              onBlur={() => this.setState({ focusedField: '' })}
            />
            <Tooltip
              title="The last 3 digits on the back of your credit card. Amex will be 4 digits."
            >
              <HintIcon src={hintIcon} />
            </Tooltip>
          </InputContainer>
        </Stack>

      </StyledForm>
    );
  }

  render = () => {
    const {
      values, onCancel, loading, currentUser: { currentPlan },
    } = this.props;
    const { validCardNumber, validCvc, validExpiration } = this.state;

    return loading ? this.renderLoadingSpinner() : (
      <Layout>
        <div className="d-flex align-items-center justify-content-between" style={{ flexWrap: 'wrap' }}>
          {this.renderForm()}

          <SubscriptionCard
            readOnly
            activeFooter
            style={{
              width: '25%', display: 'block', marginRight: '2%', marginBottom: '3rem', height: 'auto', marginTop: 'auto',
            }}
            {...currentPlan}
          />
        </div>
        <BottomText style={{ marginBottom: '60px', display: 'flex', alignItems: 'center' }}>
          Secure Payment
          <LockIcon src={lockIcon} />
        </BottomText>
        <PayButtonWrapper>
          <FlatButton
            label="Skip"
            primary
            onClick={onCancel}
          />
          <RaisedButton
            label="Update"
            primary
            onClick={this.submit}
            disabled={!values.name || !validCardNumber || !validCvc || !validExpiration}
          />
        </PayButtonWrapper>
      </Layout>
    );
  }
}

ErrorUpdateCreditCardForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  currentUser: PropTypes.object,
  stripe: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

ErrorUpdateCreditCardForm.defaultProps = {
  onUpdate: () => {},
  onCancel: () => {},
  currentUser: {},
  loading: false,
};

const Layout = styled.div`
  position: relative;
`;

const StyledForm = styled(Form)`
  & > input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  width: 70%;
`;

const Stack = styled.div`
  display: flex;
  position: relative;
`;

const HintIcon = styled.img`
  position: absolute;
  right: 0;
  bottom: 8px;
  cursor: pointer;
`;

const FormTitle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: DIN-Medium;
  font-size: 24px;
  margin-bottom: 1rem;
`;

const FormSubtitle = styled.div`
color: rgba(0, 0, 0, 0.87);
font-family: DIN-Medium;
font-size: 20px;
margin-bottom: 1rem;
`;

const BottomText = styled.div`
  font-family: DIN-Medium !important;
  font-size: 16px;
  & > a {
    color: rgb(3, 169, 244);
  }
  margin-bottom: 80px;
`;

const PayButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InputContainer = styled.div`
  & > .header {
    font-size: 0.8rem;
    color: ${props => (props.focused ? colors.blue : '#B6B6B6')};
    margin-bottom: 0.5rem;
  }
  margin-top: 2rem;
  width: 100%;
  padding-bottom: 0.4rem;
  border-bottom: ${props => (props.focused ? `2px solid ${colors.blue}` : '1px solid #E0E0E0')};
`;

export const LockIcon = styled.img`
  margin-left: 10px;
`;

const PreviousCardTitle = styled.div`
  color: black;
  margin-top: 20px;
`;

const PreviousCardSection = styled.div`
  display: flex;
  margin-top: 15px;
  padding: 5px 20px;
  border: 1px solid #EDEDED;
`;

const PreviousCardItem = styled.div`
  width: 200px;
`;
const PreviousCardInfo = styled.div`
  font-size: 14px;
  color: #e02525;
`;
const PreviousCardLabel = styled.div`
  font-size: 12px;
`;

export default injectStripe(withFormik({
  validationSchema: CreditCardSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) props.onSubmit(values);
  },
})(ErrorUpdateCreditCardForm));
