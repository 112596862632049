import gql from 'graphql-tag';

export const FETCH_WAITING_FOR_ME = gql`
  query getWaitingForMe(
    $query: String
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    waitingForMe(
      query: $query
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        uuid
        message
        subject
        previewImage{
          url
        }
        notes {
          nodes {
            uuid
            text
            createdAt
            updatedAt
          }
        }
        redirectUrl
        user {
          email
        }
        activityLogs {
          nodes {
            event
            eventDate
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalPageCount
      totalCount
    }
  }
`;

export default {
  FETCH_WAITING_FOR_ME,
};
