/* eslint-disable camelcase */
import api from './api';

function configInstance(params) {
  const {
    t, auth, client, uid,
  } = params;

  // If token or auth are present
  // Disregard standard error logic
  if (t || auth) {
    api.interceptors.response.eject(0);

    api.interceptors.response.use(null, error => Promise.reject(error));
  }

  api.interceptors.request.eject(0);

  api.interceptors.request.use((config) => {
    if (client && uid) {
      config.headers = { ...params };
    } else {
      config.headers = {
        'access-token': localStorage.getItem('accessToken'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
      };
    }

    return config;
  });
}

const documentService = {
  fetchSignatureRequest: (params) => {
    const {
      signature_request, t, auth, client, uid,
    } = params;

    configInstance(params);

    if (client && uid) {
      return api.get(`/signature_requests/${t}`);
    }

    if (!t && auth) {
      return api.get(`/signature_requests/${signature_request}?t=${auth}`);
    }

    return api.get(`/signature_requests/${signature_request}`);
  },
  fill: (params, payload) => {
    const {
      signature_request, t, auth, client, uid,
    } = params;

    configInstance(params);

    if (client && uid) {
      return api.post(`/signature_requests/${t}/fill`, payload);
    }

    if (!t && auth) {
      return api.post(`/signature_requests/${signature_request}/fill?t=${auth}`, payload);
    }

    return api.post(`/signature_requests/${signature_request}/fill`, payload);
  },
};

export default documentService;
