import React, { Fragment } from 'react';
import { isEmpty, isArray, head } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Paper, FlatButton, RaisedButton } from 'material-ui';
import { ContentAdd } from 'material-ui/svg-icons';
import { Document, Page, setOptions } from 'react-pdf';
import { colors } from '../utils';
import PDFLogo from '../assets/images/pdf.svg';

const pdfjsVersion = '2.0.305';

setOptions({
  workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.js`,
});

class DocumentThumbnail extends React.Component {
  renderDocumentPreview = (document, documentPreview) => {
    const { isLocal } = this.props;
    const thumbnailImage = document.document_preview ||
      document.thumbnailImage || document.document_preview_url;
    if (isLocal && document && document.type === 'application/pdf') {
      return (
        <LocalDocumentWrapper>
          <Document
            file={document}
            className="document-pdf-preview-wrapper"
          >
            <Page
              pageNumber={1}
              className="document-pdf-preview"
              width={110}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        </LocalDocumentWrapper>
      );
    }

    if (thumbnailImage) {
      return (
        <DocumentPreview
          thumbnailImage={thumbnailImage}
        />
      );
    }
    if (documentPreview) {
      return documentPreview;
    }
    return (
      <EmptyDocumentPreview>
        <EmptyDocumentPreviewImage src={PDFLogo} alt="" />
      </EmptyDocumentPreview>
    );
  }

  renderDocument = (documents) => {
    const { onRemovePress, documentPreview, disabled } = this.props;
    const document = isArray(documents) ? head(documents) : documents;

    return (
      <Fragment>
        {this.renderDocumentPreview(documents, documentPreview)}
        <DocumentName>
          {document.file_name || document.document_name || document.name} - Document Thumbnail
        </DocumentName>
        {!disabled && <FlatButton
          primary
          label="REMOVE"
          onClick={onRemovePress}
        />}
      </Fragment>
    );
  }

  renderFileSelector() {
    const {
      onUploadPress, enableOptions, onOptionsPress, buttonLabel, disabled,
    } = this.props;
    return (
      <Fragment>
        <AddIconContainer className="d-flex align-items-center justify-content-center">
          <ContentAdd color={colors.textLight} />
        </AddIconContainer>
        {!disabled && (
          <Buttons>
            <RaisedButton
              primary
              label={buttonLabel}
              onClick={onUploadPress}
            />
            {enableOptions && (
              <RaisedButton
                primary
                label="USE TEMPLATE"
                onClick={onOptionsPress}
              />
            )}
          </Buttons>
          )}
      </Fragment>
    );
  }

  render() {
    const { document, isFile } = this.props;
    return (
      <Container className="d-flex flex-column align-items-center">
        {(!isEmpty(document) || (document && isFile))
          ? this.renderDocument(document)
          : this.renderFileSelector()
        }
      </Container>
    );
  }
}

DocumentThumbnail.propTypes = {
  onUploadPress: PropTypes.func,
  onRemovePress: PropTypes.func,
  onOptionsPress: PropTypes.func,
  enableOptions: PropTypes.bool,
  document: PropTypes.any,
  isFile: PropTypes.bool,
  isLocal: PropTypes.bool,
  disabled: PropTypes.bool,
  documentPreview: PropTypes.element,
  buttonLabel: PropTypes.string,
};

DocumentThumbnail.defaultProps = {
  enableOptions: false,
  onUploadPress: () => {},
  onRemovePress: () => {},
  onOptionsPress: () => {},
  document: {},
  isFile: false,
  isLocal: false,
  disabled: false,
  documentPreview: undefined,
  buttonLabel: 'UPLOAD FILE',
};

const Container = styled(Paper)`
  width: 172px;
  height: 242px;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const DocumentPreview = styled.div`
  width: 85%;
  height: 170px;
  margin-top: 0.5rem;
  background-color: ${colors.gray};
  background-image: url(${props => props.thumbnailImage.replace(/\r?\n|\r/g, '')});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const EmptyDocumentPreview = styled.div`
  width: 85%;
  height: 170px;
  margin-top: 0.5rem;
  background-color: ${colors.gray};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyDocumentPreviewImage = styled.img`
  width: 35%
  `;

const DocumentName = styled.div`
    width: 100%;
    font-size: 20px;
    font-family: Roboto;
    line-height: 1.6rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 0rem 1rem;
    display: inline-block;
    align-items: flex-end;
`;

const Buttons = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 5px;
`;

const AddIconContainer = styled.div`
  width: 100%;
  height: 180px;
`;

const LocalDocumentWrapper = styled.div`
  margin-top: 0.5rem;
`;

export default DocumentThumbnail;
