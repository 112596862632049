import { toast } from 'react-toastify';
import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import apiKeysService from './../services/apiKeys';

export const createApiKey = (description, organizationUuid, brandUuid) => async (dispatch) => {
  try {
    dispatch({
      type: Types.apiAndKeysSection.GENERATING_API_KEY,
      generating: true,
    });

    const { data: response } = await apiKeysService.generate({
      client_app: {
        description,
        organization_uuid: organizationUuid,
        brand_uuid: brandUuid,
      },
    });

    dispatch({
      type: Types.apiAndKeysSection.API_KEY_ADDED,
      newApiKey: response,
    });

    dispatch({
      type: Types.apiAndKeysSection.GENERATING_API_KEY,
      generating: false,
    });

    dispatch({
      type: Types.apiAndKeysSection.TOGGLE_MODAL,
      displayModal: false,
    });

    toast.success(Messages.apiKeys.create.success);
    return Promise.resolve(response);
  } catch (err) {
    toast.error(Messages.apiKeys.create.error);

    dispatch({
      type: Types.apiAndKeysSection.GENERATING_API_KEY,
      generating: false,
    });
    return Promise.reject(err);
  }
};

export const toggleApiAndKeysModal = (displayModal, modalTitle) => ({
  type: Types.apiAndKeysSection.TOGGLE_MODAL,
  displayModal,
  modalTitle,
});

export const loadApiKeys = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: Types.apiAndKeysSection.LOADING,
      loading: true,
    });

    const { currentOrganization } = getState().organizations;

    const { data: response } = await apiKeysService.load((currentOrganization &&
      currentOrganization.uuid) ? currentOrganization.uuid : undefined);

    dispatch({
      type: Types.apiAndKeysSection.APPS_LOADED,
      clientApps: response,
    });

    dispatch({
      type: Types.apiAndKeysSection.LOADING,
      loading: false,
    });
    return Promise.resolve();
  } catch (e) {
    dispatch({
      type: Types.apiAndKeysSection.LOADING,
      loading: false,
    });
    return Promise.reject(e);
  }
};

export const deleteApiKey = id => async (dispatch) => {
  try {
    await apiKeysService.delete(id);

    dispatch({
      type: Types.apiAndKeysSection.API_KEY_REMOVED,
      id,
    });

    toast.success(Messages.apiKeys.delete.success);
  } catch (err) {
    toast.error(Messages.apiKeys.delete.error);
  }
};

export const regenerateApiKey = id => async (dispatch) => {
  try {
    const { data: response } = await apiKeysService.regenerate(id);

    dispatch({
      type: Types.apiAndKeysSection.API_KEY_REGENERATED,
      response,
    });

    toast.success(Messages.apiKeys.regenerate.success);
    return Promise.resolve(response);
  } catch (error) {
    toast.error(Messages.apiKeys.regenerate.error);
    return Promise.reject(error);
  }
};

export const setCurrentApiKey = currentApiKey => ({
  type: Types.apiAndKeysSection.SET_CURRENT_API_KEY,
  currentApiKey,
});

export const editApiKey = (id, description, brandUuid) => async (dispatch) => {
  try {
    dispatch({
      type: Types.apiAndKeysSection.GENERATING_API_KEY,
      generating: true,
    });

    const { data: response } = await apiKeysService.update(
      id,
      {
        description,
        brand_uuid: brandUuid,
      },
    );


    dispatch({
      type: Types.apiAndKeysSection.UPDATE_API_KEY,
      apiKey: response,
    });

    dispatch({
      type: Types.apiAndKeysSection.GENERATING_API_KEY,
      generating: false,
    });

    dispatch({
      type: Types.apiAndKeysSection.TOGGLE_MODAL,
      displayModal: false,
    });
    toast.success(Messages.apiKeys.edit.success);
  } catch (err) {
    dispatch({
      type: Types.apiAndKeysSection.GENERATING_API_KEY,
      generating: false,
    });

    dispatch({
      type: Types.apiAndKeysSection.TOGGLE_MODAL,
      displayModal: false,
    });
    toast.error(Messages.apiKeys.edit.error);
  }
};


export default {
  createApiKey,
  toggleApiAndKeysModal,
  loadApiKeys,
  deleteApiKey,
  regenerateApiKey,
  setCurrentApiKey,
  editApiKey,
};
