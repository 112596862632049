import gql from 'graphql-tag';

export const CREATE_NOTE = gql`
  mutation CreateNote(
    $entityUuid: ID!,
    $entityType: NoteEntityEnum!,
    $text: String!,
    ){
      createNote(
        entityUuid: $entityUuid,
        entityType: $entityType,
        text: $text,
      ){
        errors
      }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote(
    $uuid: ID!,
    ){
      deleteNote(
        uuid: $uuid,
      ){
        success
      }
  }
`;

export default {
  CREATE_NOTE,
  DELETE_NOTE,
};
