import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';

import Messages from '../../../constants/toastMessages';
import OutForSignatureTableBody from './OutForSignatureTableBody';
import graphqlClient from '../../../config/graphql';
import parseOutForSignatureDocuments from './parser';
import { FETCH_OUT_FOR_SIGNATURE } from './queries';
import { ROWS_PER_PAGE } from '../../SortableTable/SortableTable';
import { VOID_SIGNATURE_REQUEST, CREATE_REMINDER } from './mutations';
import * as DocumentManagerActions from '../../../actions/documentManagerScreen';

const defaultPageInfo = { totalCount: 0, page: 0 };

export const connect = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const { searchTerm, currentOrganization } = props;
  const [currentPageInfo, setCurrentPageInfo] = useState(defaultPageInfo);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { subdomain } = currentOrganization || {};

  const searchDocuments = async (variables = {}, page) => {
    setLoading(true);
    try {
      const {
        data: { waitingForSignature: { nodes, pageInfo, totalCount } },
      } = await graphqlClient(subdomain).query({
        query: FETCH_OUT_FOR_SIGNATURE,
        fetchPolicy: 'network-only',
        variables,
      });
      setDocuments(nodes);
      setCurrentPageInfo({ ...pageInfo, totalCount, page });
    } catch (e) {
      setDocuments([]);
      setCurrentPageInfo(defaultPageInfo);
      throw (e);
    } finally {
      setLoading(false);
    }
  };

  const reminderParser = (uuid, recipientUuids) => {
    const parsedData = {
      signatureRequestUuid: uuid,
      message: 'Signature Request Reminder',
      frequency: 'DATE',
      options: {
        recipientUuids,
        date: moment(new Date()).add(2, 'm').toDate(),
      },
    };
    return parsedData;
  };

  const createReminder = async (signatureRequestUuid, recipientsUuids, callback) => {
    try {
      await graphqlClient(subdomain).mutate({
        mutation: CREATE_REMINDER,
        variables: reminderParser(signatureRequestUuid, recipientsUuids),
      });
      callback();
    } catch (e) {
      toast.error(e.message);
      throw (e);
    }
  };

  const voidSignatureRequest = async (uuid) => {
    try {
      await graphqlClient(subdomain).mutate({
        mutation: VOID_SIGNATURE_REQUEST,
        variables: { uuid, voidReason: 'Signature no longer required' },
      });
      toast.success(Messages.documentManager.void.success);
      searchDocuments({ query: searchTerm, first: ROWS_PER_PAGE }, 0);
    } catch (e) {
      toast.error(Messages.documentManager.void.error);
      throw e;
    }
  };

  const handleOnChangePage = async (_, nextPage) => {
    const { endCursor, startCursor, page } = currentPageInfo;
    const variables = nextPage > page
      ? { first: ROWS_PER_PAGE, after: endCursor }
      : { last: ROWS_PER_PAGE, before: startCursor };
    searchDocuments({ ...variables, query: searchTerm }, nextPage);
  };

  useEffect(() => {
    searchDocuments({ query: searchTerm, first: ROWS_PER_PAGE }, 0);
  }, [searchTerm]);

  const compProps = {
    documents: parseOutForSignatureDocuments(documents),
    ...props,
    loading,
    voidSignatureRequest,
    createReminder,
    handleOnChangePage,
    currentPageInfo,
  };

  return <Component {...compProps} />;
};

export default RConnect(({ organizations, documentManagerScreen }) => ({
  currentOrganization: organizations.currentOrganization,
  selectedDocument: documentManagerScreen.selectedDocument,
}), {
  setSelectedDocument: DocumentManagerActions.setSelectedDocument,
})(connect(OutForSignatureTableBody));
