import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem } from 'material-ui';
import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, isNil, isEmpty } from 'lodash';

import * as SubscriptionsActions from '../../actions/subscriptions';
import * as SidenavActions from '../../actions/sidenav';
import * as templateActions from '../../actions/templateScreen';
import * as uploadNArchivActions from '../../actions/uploadAndArchive';
import * as sendDocumentActions from '../../actions/sendDocumentScreen';
import UpgradeIcon from '../../assets/images/upgradeIcon.png';
import { colors, spacing } from '../../utils';

const styles = {
  active: {
    color: colors.darkBlue,
  },
  innerDiv: {
    marginLeft: '55px',
    width: 'auto',
  },
  collapsedStyle: {
    marginLeft: 0,
    height: '3rem',
  },
};

const isActive = ({ isActive: active, items = [] }) =>
  active && items.every(i => !i.isActive);

export const SidenavSectionItem = (props) => {
  const {
    sectionItem, SetActiveByLink, history, setUpgradeModal, auth,
    collapsed, currentOrganization: org, resetSendDocument,
    resetTemplateScreen, resetUploadAndArchiveScreen, setSubscriptionFieldValue,
  } = props;

  const onClick = ({ link, id }) => {
    const conditions = {
      'send-document': () => resetSendDocument(),
      'create-template': () => resetTemplateScreen(),
      'upload-n-store': () => resetUploadAndArchiveScreen(),
    };
    (conditions[id] || (() => { }))();
    SetActiveByLink(link);
    history.push(link);
  };

  const getShouldUpgradePlan = (id) => {
    const {
      templatesInUse: tInUse, templatesLimit: tLimit,
      apiDocumentsInUse: aInUse, apiDocumentsInUse: naInUse,
      documentsLimit: dLimit, archivedDocumentsLimit, archivedDocumentsInUse,
      organizationsInUse: oInUse, organizationsLimit: oLimit,
    } = get(auth, 'user.planUsage', {});
    const { apiAccess } = get(auth, 'user.currentPlan', {});
    const dInUse = apiAccess ? aInUse : naInUse;
    const conditions = {
      'send-document': () => !isNil(dLimit) && dInUse + 1 > dLimit,
      'fill-n-sign': () => !isNil(dLimit) && dInUse + 1 > dLimit,
      'upload-n-store': () => !isNil(archivedDocumentsLimit) && archivedDocumentsInUse + 1 > archivedDocumentsLimit,
      'create-template': () => !isNil(tLimit) && tInUse + 1 > tLimit,
      organization: () => !isNil(oLimit) && isEmpty(org) && oInUse + 1 > oLimit,
    };
    return (conditions[id] || (() => false))();
  };

  const renderIndicator = (item) => {
    const { indicatorType } = item;
    // eslint-disable-next-line react/prop-types
    const { planUsage, currentPlan } = props;
    const {
      // eslint-disable-next-line react/prop-types
      apiDocumentsInUse, noApiDocumentsInUse, documentsLimit, organizationsLimit,
      // eslint-disable-next-line react/prop-types
      templatesInUse, templatesLimit, organizationsInUse,
      // eslint-disable-next-line react/prop-types
      archivedDocumentsInUse, archivedDocumentsLimit,
    } = planUsage;


    const { apiAccess } = get(auth, 'user.currentPlan', {});
    const documentsInUse = apiAccess ? apiDocumentsInUse : noApiDocumentsInUse;

    const indicatorLimits = {
      sendDocument: documentsLimit,
      createTemplate: templatesLimit,
      organizations: organizationsLimit,
      uploadAndStore: archivedDocumentsLimit,
    };

    if (!indicatorLimits[indicatorType]) return null;
    if (indicatorType === 'sendDocument' && currentPlan && currentPlan.id !== 1) return null;

    const indicatorNumber = {
      sendDocument: documentsLimit - documentsInUse,
      createTemplate: templatesLimit - templatesInUse,
      organizations: organizationsLimit - organizationsInUse,
    };

    const title = {
      sendDocument: (
        <div>
          {documentsLimit - documentsInUse} {documentsLimit - documentsInUse === 1 ? 'Document' : 'Documents'} available to send <br />
          {documentsInUse} {documentsInUse === 1 ? 'Document' : 'Documents'} sent
        </div>),
      createTemplate: (
        <div>
          {templatesLimit - templatesInUse} {templatesLimit - templatesInUse === 1 ? 'Template' : 'Templates'} available <br />
          {templatesInUse} {templatesInUse === 1 ? 'Template' : 'Templates'}  created
        </div>),
      uploadAndStore: (
        <div>
          {archivedDocumentsLimit - archivedDocumentsInUse} Stored {archivedDocumentsLimit - archivedDocumentsInUse === 1 ? 'Document' : 'Documents'} available <br />
          {archivedDocumentsInUse} Stored {archivedDocumentsInUse === 1 ? 'Document' : 'Documents'} created
        </div>),
      organizations: 'Total amount of organizations available',
    };

    return (
      <Tooltip
        title={title[indicatorType]}
      >
        <IndicatorWrapper>
          <IndicatorNumber>
            {indicatorNumber[indicatorType]}
          </IndicatorNumber>
        </IndicatorWrapper>
      </Tooltip>
    );
  };

  const renderItem = (isSubmenu = false) => (item) => {
    const Icon = item.icon;
    const {
      id, link, pro, items = [], indicatorType,
    } = item;
    const color = isActive(item) ? colors.blue : '#747474';
    const shouldUpgradePlan = pro && getShouldUpgradePlan(id);
    const handleOnClick = shouldUpgradePlan
      ? async () => {
        if (link === '/organizations') {
          await setSubscriptionFieldValue('newOrganizationUpgradeRequired', true);
        }
        setUpgradeModal(true);
      }
      : () => onClick(item);
    const style = {
      ...(isActive(item) ? styles.active : {}),
      ...(collapsed ? styles.collapsedStyle : {}),
      ...(!collapsed && shouldUpgradePlan ? { color: '#ADAEAD' } : {}),
    };
    const submenuAttrs = isSubmenu ? {
      open: false,
      primaryTogglesNestedList: false,
      initiallyOpen: false,
      className: 'nested',
      primaryText: !collapsed && item.label,
      rightIcon: !collapsed && shouldUpgradePlan ? <ProContent image={UpgradeIcon} /> : null,
      innerDivStyle: collapsed ? styles.collapsedStyle : styles.innerDiv,
      rightIconButton: null,
    } : {};

    return (
      <ItemContainer
        key={id}
        showUpgradeIndicator={!collapsed && shouldUpgradePlan}
        style={{ height: pro ? '3rem' : 'auto' }}
        isActive={isActive(item)}
      >
        <ListItem
          open
          primaryTogglesNestedList
          style={style}
          leftIcon={Icon ? <Icon size="20px" color={color} /> : ''}
          onClick={link ? handleOnClick : null}
          primaryText={item.label}
          initiallyOpen={items && isActive(item)}
          rightIconButton={<div />}
          nestedItems={items.map(renderItem(true))}
          {...submenuAttrs}
        />
        {!isSubmenu && !collapsed && shouldUpgradePlan && (
          <ProContent onClick={link ? handleOnClick : null} image={UpgradeIcon} />
        )}
        {indicatorType && !collapsed && !shouldUpgradePlan && renderIndicator(item)}
      </ItemContainer>
    );
  };

  return renderItem()(sectionItem);
};

SidenavSectionItem.propTypes = {
  SetActiveByLink: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
  setSubscriptionFieldValue: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  planUsage: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  sectionItem: PropTypes.object,
  collapsed: PropTypes.bool,
};

SidenavSectionItem.defaultProps = {
  currentOrganization: {},
  sectionItem: {},
  collapsed: false,
};

const ItemContainer = styled.div`
  width: 100%;
  position: relative;
  & > div > span > div > div > svg {
    fill: ${props => (props.showUpgradeIndicator ? '#adaead' : props.isActive && colors.darkBlue)} !important;
  }

  &  .nested > div > div > div {
    position: relative;
    right: 0.8rem;
    width: 9rem;
  }

  & div {
    font-family: DIN-Medium;
    font-size: 13px;
  }
`;

const ProContent = styled.div`
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
  color: ${colors.blue};
  position: relative;
  bottom: 2.2rem;
  background-image: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0.8rem;
  left: ${spacing.sidenavWidth / 2}px;
  font-weight: 600;
`;

const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid ${colors.blue};
  border-radius: 15px;
  position: absolute;
  right: 3rem;
  top: 0.9rem;
`;

const IndicatorNumber = styled.div`
  font-size: 9px !important;
  color: ${colors.darkGray};
`;

const mapStateToProps = state => ({
  ...state,
  currentPlan: state.auth.user.currentPlan,
  planUsage: state.auth.user.planUsage,
  currentOrganization: state.organizations.currentOrganization || {},
});
const mapDispatchToProps = {
  SetActiveByLink: SidenavActions.SetActiveByLink,
  setUpgradeModal: SubscriptionsActions.setUpgradeModal,
  setSubscriptionFieldValue: SubscriptionsActions.setSubscriptionFieldValue,
  resetSendDocument: sendDocumentActions.resetSendDocument,
  resetTemplateScreen: templateActions.resetTemplateScreen,
  resetUploadAndArchiveScreen: uploadNArchivActions.resetUploadAndArchiveScreen,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidenavSectionItem));
