import React from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';

import Messages from '../../../constants/toastMessages';
import InviteNewUser from './InviteNewUser';
import graphqlClient from '../../../config/graphql';
import orgService from '../../../services/organization';
import { SEND_INVITES } from './queries';
import { FETCH_PLAN_USAGE } from '../../../screens/UserProfileScreen/queries';
import * as orgScreenActions from '../../../actions/organizationsScreen';
import * as orgActions from '../../../actions/organization';
import * as authActions from '../../../actions/auth';

const fetchUserPlanUsage = async (currentOrganization) => {
  try {
    const {
      data: { currentUser: { planUsage } },
    } = await graphqlClient(currentOrganization).query({
      query: FETCH_PLAN_USAGE,
      fetchPolicy: 'network-only',
    });
    return planUsage;
  } catch (e) {
    return {};
  }
};

export const connect = Component => (props) => {
  const {
    /* eslint-disable react/prop-types */
    currentOrganization: currentOrg, closeInviteNewUser,
    setCurrentOrganization, user, setCurrentUser,
    /* eslint-enable */
  } = props;
  const { subdomain, uuid } = currentOrg || {};

  const sendInvites = async (values) => {
    const recipients = (values.recipients || [])
      .split(',')
      .map(email => ({ email, inviteType: 'MEMBER' }));

    try {
      await graphqlClient(subdomain).mutate({
        mutation: SEND_INVITES,
        variables: { recipients },
      });
      closeInviteNewUser();
      toast.success(Messages.organizations.invite.success);

      const { data: currentOrganization } = await orgService.get(uuid);
      setCurrentOrganization(currentOrganization);

      const planUsage = await fetchUserPlanUsage((currentOrg || {}).subdomain);
      setCurrentUser({ ...user, planUsage });
    } catch (e) {
      const errorMsg = (e.message || '').toLowerCase().indexOf('unable to issue') > -1
        ? 'This email is already a user'
        : Messages.organizations.invite.error;
      toast.error(errorMsg);
      throw e;
    }
  };

  return <Component {...props} inviteNewUser={sendInvites} />;
};

export default RConnect(({ organizationsScreen, organizations, auth: { user } }) => ({
  organizationsScreen,
  currentOrganization: organizations.currentOrganization,
  user,
}), {
  submitInviteForm: orgScreenActions.submitInviteForm,
  closeInviteNewUser: orgScreenActions.closeInviteNewUser,
  setCurrentOrganization: orgActions.setCurrentOrganization,
  setCurrentUser: authActions.setCurrentUser,
})(connect(InviteNewUser));
