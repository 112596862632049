import objectToFormData from 'object-to-formdata';
import api from './api';

const brands = {
  list: organizationUuid => api.get(`organizations/${organizationUuid}/brands`),
  create: (organizationUuid, payload) => {
    const form_data = objectToFormData(payload);
    return api.post(`organizations/${organizationUuid}/brands`, form_data, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${form_data._boundary}`,
      },
    });
  },
  update: (organizationUuid, brandUuid, payload) => {
    const form_data = objectToFormData(payload);
    return api.put(`organizations/${organizationUuid}/brands/${brandUuid}`, form_data, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${form_data._boundary}`,
      },
    });
  },
  delete: (organizationUuid, brandUuid) =>
    api.delete(`organizations/${organizationUuid}/brands/${brandUuid}`),
  setDefault: (organizationUuid, brandUuid) =>
    api.put(`organizations/${organizationUuid}/brands/${brandUuid}/set_as_default`),
};

export default brands;
