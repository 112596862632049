import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { has } from 'lodash';
import { MenuItem, SelectField, CircularProgress } from 'material-ui';

import EmptyListNotification from '../../EmptyListNotification';
import connect from './connect';
import Modal from '../../Modal';

class TemplateDocumentModal extends React.Component {
  state = {
    show: false,
    primaryEnable: false,
    selectedTemplate: null,
  };

  componentDidUpdate = (prevProps) => {
    const { sendDocumentScreen: { displayModal } } = prevProps;
    const { sendDocumentScreen: { displayModal: displayModalNow }, setDocumentTmp } = this.props;
    if (displayModal === false && displayModalNow === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ primaryEnable: false });
      setDocumentTmp({});
    }
  };

  onClickSave = async () => {
    const {
      toggleUseExistingTemplateModal, setTemplateForSigRequest,
      disableOrganizationChange,
    } = this.props;
    try {
      await setTemplateForSigRequest();
      this.setState({ selectedTemplate: null });
      toggleUseExistingTemplateModal(false);
      disableOrganizationChange();
    } catch (e) {
      // do nothing
    }
  }

  handleChange = async (value) => {
    const { setTemplateFromList } = this.props;
    try {
      await setTemplateFromList(value);
      this.setState({
        primaryEnable: true,
        selectedTemplate: value,
      });
    } catch (e) {
      // do nothing
    }
  };

  renderTemplate = ({ name, uuid }) => (
    <MenuItem
      key={uuid}
      value={uuid}
      primaryText={name}
    />
  )

  renderModalContent = () => {
    const {
      documents, loading, templates,
    } = this.props;
    const { show, selectedTemplate } = this.state;

    if (loading) {
      return (
        <Container>
          <CircularProgress className="mb-3" />
        </Container>
      );
    }

    if ((templates || []).length === 0) {
      return (
        <EmptyListNotification
          message="Nothing to see here! You do not have any templates."
          link="/documents-manager/templates/new"
          buttonLabel="Create Template"
        />
      );
    }

    return (
      <Container>
        <div>
          <Title>Use Existing Template</Title>
          <SearchTemplate>
            <SelectField
              floatingLabelText="Search Templates"
              onChange={(e, i, v) => this.handleChange(v)}
              value={selectedTemplate}
              dropDownMenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
            >
              {templates.map(this.renderTemplate)}
            </SelectField>
          </SearchTemplate>
        </div>

        <PreviewTemplate>
          {has(documents, 'document_preview_url') ? (
            <DocumentPreview>
              <ImagePreview src={documents.document_preview_url} alt="template preview document" />
              {show && <Message>Document Preview Loaded!</Message>}
            </DocumentPreview>
          ) : (
            <ImagePlaceholder />
          )}
        </PreviewTemplate>
      </Container>
    );
  };

  render() {
    const {
      toggleUseExistingTemplateModal,
      sendDocumentScreen: { displayModal },
    } = this.props;
    const { primaryEnable } = this.state;

    return (
      <Modal
        title=""
        content={this.renderModalContent()}
        onPrimaryClick={this.onClickSave}
        primaryLabel="Use"
        primaryDisabled={!primaryEnable}
        onSecondaryClick={() => {
          toggleUseExistingTemplateModal(false);
          this.setState({ selectedTemplate: null });
        }}
        open={displayModal}
      />
    );
  }
}

TemplateDocumentModal.propTypes = {
  setTemplateFromList: PropTypes.func.isRequired,
  setTemplateForSigRequest: PropTypes.func.isRequired,
  toggleUseExistingTemplateModal: PropTypes.func.isRequired,
  disableOrganizationChange: PropTypes.func.isRequired,
  sendDocumentScreen: PropTypes.object.isRequired,
  templates: PropTypes.array.isRequired,
  documents: PropTypes.object,
  setDocumentTmp: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
};

TemplateDocumentModal.defaultProps = {
  documents: {},
};

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
`;

const Title = styled.div`
  font-size: 1.3rem;
  color: black;
  margin: 0.5rem 1rem;
`;

const SearchTemplate = styled.div`
  width: 45%;
  height: 300px;
  margin-left: 1.1rem;
`;

const PreviewTemplate = styled.div`
  width: 45%;
  height: 300px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const ImagePreview = styled.img`
  width: 195px;
  height: 255px;
  border: 1px solid #80808040;
  background: white;
  object-fit: fill;
`;

const ImagePlaceholder = styled.div`
  width: 195px;
  height: 255px;
  border: 1px solid #80808040;
  background: white;

  &::before {
    position: relative;
    top: 111px;
    left: 70px;
    content: 'Preview';
    font-size: 14px;
    text-transform: capitalize;
    display: flex;
    align-self: center;
  }
`;

const DocumentPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const Message = styled.span`
  font-size: 12px;
  color: gray;
  margin-top: 10px;
`;

export default connect(TemplateDocumentModal);
