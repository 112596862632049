import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';

import ConfirmDialog from '../ConfirmDialog';
import { AppHeader } from '..';
import { AddFilesDialog } from '../filesManager';
import { spacing } from '../../utils';
import * as SidenavActions from '../../actions/sidenav';

const withAuthDocumentContent = Component => props => (
  <div className="d-flex flex-row">
    <Content>
      <div>
        <InnerContent>
          {/* eslint-disable-next-line react/prop-types */}
          <AppHeader withLogo onClickLogo={() => props.history.push('/')} />
          <Component {...props} />
        </InnerContent>
      </div>
    </Content>
    <AddFilesDialog />
    <ConfirmDialog />
  </div>
);

withAuthDocumentContent.propTypes = {
  user: PropTypes.object.isRequired,
};

const Content = styled.div`
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 1.5rem;
  display: block;
  margin-top: 0.5rem;
`;

const InnerContent = styled.div`
  margin-top: ${spacing.appHeaderSize}px;
`;

const mapStateToProps = ({ auth: { user, loading }, sidenav }) =>
  ({ user, loading, collapsedNav: sidenav.collapsed });

const mapDispatchToProps = () => {
  const {
    setCollapseSidenav,
  } = SidenavActions;
  return {
    setCollapseSidenav,
  };
};

const composeAuthContent =
  compose(connect(mapStateToProps, mapDispatchToProps()), withAuthDocumentContent);

export default composeAuthContent;
