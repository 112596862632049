import React, { Fragment } from 'react';
import styled from 'styled-components';
import RateChart from './RateChart';
import StatusChart from './StatusChart';
import VelocityChart from './VelocityChart';
import VolumeChart from './VolumeChart';

const OverviewSection = () => (
  <Fragment>
    <Row className="row">
      <StatusChart />
      <RateChart />
    </Row>
    <Row className="row">
      <VelocityChart />
      <VolumeChart />
    </Row>
  </Fragment>
);
const Row = styled.div`
  margin-top: 2rem;
`;

export default OverviewSection;
