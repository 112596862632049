import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'material-ui/DatePicker';
import { TextField } from 'material-ui';
import styled, { injectGlobal } from 'styled-components';


class RemoteDatepicker extends React.Component {
  state = {
    visible: null,
  };

  componentWillReceiveProps() {
    if (this.props.showDp && !this.state.visible) {
      this.dpRef.openDialog();
      this.setState({
        visible: true,
      });
    }
  }

  onDatepickerChange = (e, value) => {
    this.setState({ visible: false });
    this.props.completed(value);
  }

  dpRef = null;

  render = () => (
    <Container scaleRatio={this.props.scaleRatio}>
      <div onClick={this.props.onClick} role="presentation" style={{ height: '100%', cursor: 'pointer !important' }}>
        <TextField
          type="text"
          name="date-picker"
          className="sign_date_picker"
          style={this.props.style}
          value={this.props.field.value}
          fieldName={`field_${this.props.field.id}`}
          disabled
        />
        {!this.props.field.value && <div className="placeholder">MM/DD/YYYY</div>}
        <DatePicker
          ref={(ref) => { this.dpRef = ref; }}
          onDismiss={() => this.setState({ visible: false })}
          onChange={this.onDatepickerChange}
          style={{ display: 'none' }}
          disabled={this.props.disabled}
        />
      </div>
      {this.props.editable && <Close onClick={this.props.onRemove}>x</Close>}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  & > div > .placeholder {
    position: absolute;
    top: ${props => props.scaleRatio}px;
    font-size: ${props => props.scaleRatio * 14}px;
  }
`;

const Close = styled.div`
  text-align: right;
  height: 5px;
  right: 0.8rem;
  cursor: pointer;
  position: relative;
  top: -4px;
  font-size: 0.8rem;
`;

RemoteDatepicker.propTypes = {
  field: PropTypes.object.isRequired,
  style: PropTypes.object,
  showDp: PropTypes.bool.isRequired,
  completed: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  editable: PropTypes.bool,
  scaleRatio: PropTypes.number,
};


RemoteDatepicker.defaultProps = {
  style: {},
  disabled: false,
  onClick: () => {},
  onRemove: () => {},
  editable: false,
  scaleRatio: 1,
};

export default RemoteDatepicker;

// eslint-disable-next-line
injectGlobal`
.sign_date_picker input {
  border: 0px;
  width: 100%;
  cursor: pointer !important;
}

.sign_date_picker {
  background-color: transparent !important;
  border: 0px;
  width: 100%;
  color: black !important;
  & * {
    color: black !important;
  }
}
`;
