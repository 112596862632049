import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { toast } from 'react-toastify';

import Messages from '../constants/toastMessages';
import RoleList from '../components/commons/RoleList';
import SectionLoader from './../components/SectionLoader';
import SelectBrandField from '../components/SelectBrandField';
import templateScreenActions from '../actions/templateScreen';
import sendDocumentActions from '../actions/sendDocumentScreen';
import filesManagerActions from '../actions/filesManager';
import brandsSectionActions from '../actions/brandsSection';
import organizationActions from '../actions/organization';
import subscriptionsActions from '../actions/subscriptions';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../components/pages';
import { DocumentThumbnail } from '../components';
import { didOrganizationChanged } from '../utils/userOrganizations';

class TemplateScreen extends React.Component {
  state = {
    isEditing: this.props.match.params.id !== 'new',
    isActionInProgress: false,
  };

  componentDidMount = () => {
    const {
      clearDocuments,
      loadTemplate,
      resetTemplateScreen,
      match,
      fetchBrands,
      organizations,
      enableOrganizationChange,
      resetSendDocument,
      history,
      setUpgradeModal,
      auth: {
        user: {
          planUsage: { templatesInUse, templatesLimit },
        },
      },
    } = this.props;
    const { isActionInProgress } = this.state;

    if (match.params.id === 'new'
      && Number.isInteger(templatesLimit)
      && templatesInUse + 1 > templatesLimit
      && !isActionInProgress
    ) {
      setUpgradeModal(true);
      history.push('/dashboard');
    }

    enableOrganizationChange();
    resetSendDocument();

    if (organizations.currentOrganization) {
      fetchBrands();
    }

    if (match.params.id !== 'new') {
      loadTemplate(match.params.id);
    } else {
      clearDocuments();
      resetTemplateScreen();
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const {
      match,
      fetchBrands,
      history,
      setUpgradeModal,
    } = this.props;
    const { isActionInProgress } = this.state;

    if (didOrganizationChanged(this.props, nextProps)
      && nextProps.organizations.currentOrganization
    ) {
      fetchBrands();
    }

    const currentPlanUsage = this.props.auth.user.planUsage;
    const newPlanUsage = nextProps.auth.user.planUsage;
    const { templatesInUse, templatesLimit } = newPlanUsage;
    if (match.params.id === 'new'
      && !isEqual(currentPlanUsage, newPlanUsage)
      && (templatesInUse + 1 > Number(templatesLimit))
      && !isActionInProgress
    ) {
      setUpgradeModal(true);
      history.push('/dashboard');
    }
  };

  componentWillUnmount = () => {
    const { enableOrganizationChange } = this.props;
    enableOrganizationChange();
  }

  deleteTemplate = async () => {
    const { deleteTemplate, templateScreen: { uuid }, history } = this.props;
    try {
      this.setState({ isActionInProgress: true });
      await deleteTemplate(uuid);
      this.setState({ isActionInProgress: false });
      toast.success(Messages.templates.delete.success);
      history.push('/documents-manager?tab=template_library');
    } catch (e) {
      this.setState({ isActionInProgress: false });
      toast.error(Messages.templates.delete.error);
    }
  };

  nextButtonIsDisabled = () => (
    this.props.templateScreen.documents.length === 0
  );

  isTemplateValid = () => {
    const {
      templateScreen: {
        title, description, roles, documents,
        selectedBrand, isSelectedBrandEnabled,
      },
      setTemplateScreenField,
    } = this.props;
    setTemplateScreenField('shouldValidate', true);
    if (!this.isTitleValid(title)) {
      toast.error('Please enter a tittle');
      return false;
    }
    if (!this.isDescriptionValid(description)) {
      toast.error('Please enter a description');
      return false;
    }
    if (!this.areDocumentsValid(documents)) {
      toast.error('Please upload at least 1 document');
      return false;
    }
    if (!this.isBrandValid(selectedBrand, isSelectedBrandEnabled)) {
      toast.error('Please select a brand');
      return false;
    }
    if (!this.areRolesValid(roles)) {
      toast.error('Some roles are empty or equal to another');
      return false;
    }
    return true;
  };

  areDocumentsValid = documents => !isEmpty(documents)

  isBrandValid = (selectedBrand, isSelectedBrandEnabled) => !(isSelectedBrandEnabled
    && !selectedBrand)

  isTitleValid = title => !isEmpty(title)

  isDescriptionValid = () => true

  areRolesValid = (roles) => {
    let isValid = true;
    roles.forEach((role) => {
      if (!this.isRoleValid(role, roles)) {
        isValid = false;
      }
    });
    return isValid;
  }
  isRoleValid = (role, roles) => {
    if (isEmpty(role.role)) {
      return false;
    }
    let isValid = true;
    roles.forEach((r) => {
      if (r.role === role) {
        isValid = false;
      }
    });
    return isValid;
  }

  saveTemplate = async () => {
    if (!this.isTemplateValid()) return;

    const { saveTemplate, history } = this.props;
    try {
      this.setState({ isActionInProgress: true });
      const { route } = await saveTemplate();
      this.setState({ isActionInProgress: false });
      history.push(route);
    } catch (e) {
      this.setState({ isActionInProgress: false });
      toast.error(Messages.templates.save.error);
    }
  };

  renderDocuments = (documents) => {
    if (!documents.length) return null;

    return documents.map(document => (
      <DocumentThumbnail
        document={document}
        onRemovePress={() => this.props.removeTemplateScreenDocument(document)}
        key={document.uuid}
      />
    ));
  };

  renderDocumentUploader = (documents, documentsLimit) => {
    const { setAddFilesDialog, addTemplateScreenDocuments } = this.props;
    if (documents.length >= documentsLimit) return null;
    return (
      <DocumentThumbnail
        onUploadConfirm={() => {}}
        onUploadPress={() => {
          setAddFilesDialog(true, docs => addTemplateScreenDocuments(docs));
        }}
      />
    );
  };

  render() {
    const {
      title,
      description,
      documents,
      documentsLimit,
      roles,
      loading,
      brands,
      selectedBrand,
      isSelectedBrandEnabled,
      completeInOrder,
      shouldValidate,
    } = this.props.templateScreen;
    const {
      organizations, createRole, deleteRole, updateRole, setRoles, setTemplateScreenField,
    } = this.props;
    const { isActionInProgress } = this.state;

    return (
      <PageContainer>
        <PageHeader title={this.state.isEditing ? 'Edit Template' : 'New Template'} />
        <PageContent>
          {loading ? (
            <SectionLoader />
          ) : (
            <div>
              <div className="row">
                <div className="col-12 d-flex flex-wrap mb-5">
                  {this.renderDocuments(documents)}
                  {this.renderDocumentUploader(documents, documentsLimit)}
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12 col-lg-4">
                  <TextField
                    fullWidth
                    floatingLabelText="Name*"
                    value={title}
                    onChange={e => setTemplateScreenField('title', e.target.value)}
                    errorText={shouldValidate && !this.isTitleValid(title) && 'This field is required'}
                  />
                </div>
                <div className="col-12 col-lg-8">
                  <TextField
                    fullWidth
                    floatingLabelText="Description"
                    value={description}
                    onChange={e => setTemplateScreenField('description', e.target.value)}
                    errorText={shouldValidate && !this.isDescriptionValid(description) && 'This field is required'}
                  />
                </div>
              </div>
              { !isEmpty(brands) && organizations.currentOrganization && (
                <div className="col-5 mb-3 row">
                  <InlineWrapper>
                    <Checkbox
                      style={{ width: '10%' }}
                      checked={isSelectedBrandEnabled}
                      onCheck={() => setTemplateScreenField('isSelectedBrandEnabled', !isSelectedBrandEnabled)}
                    />
                    <SelectBrandField
                      enabled={isSelectedBrandEnabled}
                      currentBrand={selectedBrand}
                      showError={shouldValidate
                        && !this.isBrandValid(selectedBrand, isSelectedBrandEnabled)}
                      onSelectBrand={value => setTemplateScreenField('selectedBrand', value)}
                      brands={brands}
                    />
                  </InlineWrapper>
                </div>
              )}

              <RoleList
                roles={roles}
                isCompleteInOrderEnabled={completeInOrder}
                onChangeCompleteInOrder={() => { setTemplateScreenField('completeInOrder', !completeInOrder); }}
                onRemoveRole={deleteRole}
                onChangeRole={updateRole}
                onRolesUpdate={setRoles}
                onAddRole={createRole}
                shouldValidate={shouldValidate}
              />
              <Separator />
            </div>
          )}
        </PageContent>
        <PageFooter
          onSavePress={this.saveTemplate}
          saveButtonLabel="Next"
          saveDisabled={this.nextButtonIsDisabled() || isActionInProgress}
          savePrimary
          onDeletePress={this.state.isEditing ? this.deleteTemplate : null}
          deleteDisabled={isActionInProgress}
        />
      </PageContainer>
    );
  }
}

TemplateScreen.propTypes = {
  templateScreen: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  setTemplateScreenField: PropTypes.func.isRequired,
  addTemplateScreenDocuments: PropTypes.func.isRequired,
  removeTemplateScreenDocument: PropTypes.func.isRequired,
  setAddFilesDialog: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  roles: PropTypes.array,
  createRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  loadTemplate: PropTypes.func.isRequired,
  match: PropTypes.any.isRequired,
  resetTemplateScreen: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  clearDocuments: PropTypes.func.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  setRoles: PropTypes.func.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
  resetSendDocument: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
};

TemplateScreen.defaultProps = {
  title: '',
  description: '',
  roles: [],
};

const Separator = styled.div`
  width: 100%;
  height: 50px;
`;

const InlineWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const mapStateToProps = ({ templateScreen, organizations, auth }) =>
  ({ templateScreen, organizations, auth });

const mapDispatchToProps = () => {
  const {
    setTemplateScreenField,
    addTemplateScreenDocuments,
    removeTemplateScreenDocument,
    createRole,
    deleteRole,
    updateRole,
    saveTemplate,
    loadTemplate,
    resetTemplateScreen,
    deleteTemplate,
    setRoles,
  } = templateScreenActions;
  const {
    setAddFilesDialog,
    clearDocuments,
  } = filesManagerActions;
  const {
    fetchBrands,
  } = brandsSectionActions;
  const {
    enableOrganizationChange,
  } = organizationActions;
  const { resetSendDocument } = sendDocumentActions;
  const { setUpgradeModal } = subscriptionsActions;
  return {
    setTemplateScreenField,
    addTemplateScreenDocuments,
    removeTemplateScreenDocument,
    setAddFilesDialog,
    createRole,
    deleteRole,
    updateRole,
    saveTemplate,
    loadTemplate,
    resetTemplateScreen,
    deleteTemplate,
    clearDocuments,
    fetchBrands,
    setRoles,
    enableOrganizationChange,
    resetSendDocument,
    setUpgradeModal,
  };
};


export default connect(mapStateToProps, mapDispatchToProps())(TemplateScreen);
