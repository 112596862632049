import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import UserProfileForm from './UserProfileForm';

export const connect = Component => props => <Component {...props} />;


export default reduxConnect(
  ({
    userProfileScreen: { currentUser },
    auth: { user },
  }) => ({ currentUser, user }),
  null,
)(connect(UserProfileForm));
