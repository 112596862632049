import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  loading: false,
  dashboard: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.platformManager.SET_LOADING: {
      const { loading } = action;

      return { ...state, loading };
    }

    case Types.platformManager.FETCH_DASHBOARD_DATA: {
      const dashboard = action.response;
      return { ...state, dashboard };
    }

    default:
      return state;
  }
};
