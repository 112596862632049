import gql from 'graphql-tag';

export const SUBSCRIBE_USER = gql`
  mutation SubscribeUser($cardToken: String!, $planUuid: ID!) {
    subscribeUser(cardToken: $cardToken, planUuid: $planUuid) {
      success
    }
  }
`;

export default {
  SUBSCRIBE_USER,
};
