import * as Yup from 'yup';
import React, { useEffect } from 'react';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Checkbox, DatePicker, SelectField, TextField } from 'material-ui';
import { debounce } from 'lodash';

import validateEmails from '../../utils/validateEmails';

export const FormSchema = Yup.object().shape({
  frequency: Yup.string().required(),
  message: Yup.string().required('This field is required.'),
  startDate: Yup.date().required(),
  endDate: Yup.mixed().when('frequency', {
    is: 'ONCE',
    then: Yup.mixed().notRequired(),
    otherwise: Yup.date().required(),
  }),
  sendToMe: Yup.boolean(),
  sendOthers: Yup.boolean(),
  sendTo: Yup
    .array()
    .required('You must pick at least one option to send an email to.')
    .min(1),
  recipients: Yup.mixed().when('sendOthers', {
    is: true,
    then: Yup.string().ensure().test('are-emails', 'Emails are not valid.', validateEmails),
    otherwise: Yup.string().notRequired(),
  }),
});

const ScheduleReportForm = ({
  email, onChange, errors, touched, values: {
    frequency, startDate, endDate, recipients, sendOthers, sendTo = [],
  },
}) => {
  useEffect(() => {
    if (!startDate) onChange(new Date(), 'startDate');
    onChange(false, 'sendToMe');
  }, []);

  const toggleSendTo = (field, check) => sendTo
    .filter(s => s !== field)
    .concat(check ? [field] : []);

  const handleSelectFrequency = (e, i, v) => {
    onChange(v, 'frequency');
    if (v === 'ONCE') {
      onChange(undefined, 'endDate');
      if (!touched.startDate) onChange(undefined, 'startDate');
    }
  };

  const handleCheckSendToMe = (e, check) => {
    const field = 'sendToMe';
    onChange(check, field);
    onChange(toggleSendTo(field, check), 'sendTo');
  };

  const handleCheckSendOthers = (e, check) => {
    const field = 'sendOthers';
    onChange(check, field);
    onChange(toggleSendTo(field, check), 'sendTo');
    if (!check && recipients) onChange(undefined, 'recipients');
  };

  return (
    <div className="h-100 overflow-auto">
      <div className="d-flex">
        <div style={{ paddingRight: 48 }}>
          <SelectField
            required
            hintText="Schedule Frequency"
            style={{ width: 224 }}
            value={frequency}
            errorText={touched.frequency && errors.frequency}
            onChange={handleSelectFrequency}
          >
            <MenuItem value="DAILY" primaryText="Daily" />
            <MenuItem value="WEEKLY" primaryText="Weekly" />
            <MenuItem value="MONTHLY" primaryText="Monthly" />
            <MenuItem value="ONCE" primaryText="Once" />
          </SelectField>
        </div>

        <div className="pb-4 d-flex flex-column flex-grow-1">
          <DatePicker
            autoOk
            fullWidth
            required
            hintText={frequency === 'ONCE' ? 'Select date' : 'Select start date'}
            minDate={new Date()}
            maxDate={endDate}
            value={startDate}
            formatDate={date => moment(date).format('MM/DD/YYYY')}
            onChange={(e, date) => onChange(date, 'startDate')}
          />
          {frequency !== 'ONCE' && (
            <DatePicker
              autoOk
              fullWidth
              required
              hintText="Select end date"
              minDate={startDate}
              value={endDate}
              formatDate={date => moment(date).format('MM/DD/YYYY')}
              onChange={(e, date) => onChange(date, 'endDate')}
            />
          )}
        </div>
      </div>

      <div className="pt-5 d-flex flex-column">
        <Checkbox
          label={`Send to me (${email})`}
          style={{ marginTop: '.75rem', marginBottom: '1.5rem' }}
          onCheck={handleCheckSendToMe}
        />
        <Checkbox
          label="Send to others"
          checked={sendOthers}
          onCheck={handleCheckSendOthers}
        />
        {sendOthers && (
          <TextField
            multiLine
            className="flex-grow-1 w-auto"
            floatingLabelText="Enter email addresses separated by comma"
            style={{ marginLeft: '2.5rem' }}
            errorText={touched.recipients && errors.recipients}
            onChange={debounce((e, value) => onChange(value, 'recipients'), 400)}
          />
        )}
        <TextField
          className="flex-grow-1 w-auto"
          floatingLabelText="Email subject"
          style={{ marginLeft: '2.5rem' }}
          errorText={touched.message && errors.message}
          onChange={debounce((e, value) => onChange(value, 'message'), 400)}
        />
      </div>
    </div>
  );
};

ScheduleReportForm.propTypes = {
  email: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    frequency: PropTypes.string,
    sendOthers: PropTypes.bool,
    sendTo: PropTypes.arrayOf(PropTypes.string),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

export default ScheduleReportForm;
