import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../../Modal';

class MemberLimitsReachedModal extends Component {
  renderModalContent = () => (
    <ContentWrapper>
      To continue with this action please contact the owner of the
      organization to upgrade the current plan.
    </ContentWrapper>
  );

  render = () => {
    const {
      onClose,
      open,
    } = this.props;
    return (
      <Modal
        title="Organization Plan Limits Reached"
        open={open || false}
        modal={false}
        content={this.renderModalContent()}
        primaryLabel="Close"
        onPrimaryClick={onClose}
        contentStyle={{ width: '548px' }}
        secondaryStyle={{ display: 'none' }}
        titleStyle={{ padding: '50px 0px 50px 24px', fontFamily: 'DIN-Medium' }}
      />
    );
  }
}

MemberLimitsReachedModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

MemberLimitsReachedModal.defaultProps = {
  onClose: () => {},
  open: false,
};

const ContentWrapper = styled.div`
  font-weight: 500;
  color: black;
  font-family: DIN-Medium;
  margin-bottom: 20px;
  text-align: center;
`;

export default MemberLimitsReachedModal;
