import React from 'react';
import { connect as ReduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleSendReportModal } from '../../actions/analyticsScreen';
import EnvelopeDetailReportScreen from './EnvelopeDetailReportScreen';
import graphqlClient from '../../config/graphql';
import { parseRequest } from '../AnalyticsScreen/connect';
import { CREATE_REPORT_SCHEDULE } from '../AnalyticsScreen/queries';

const connect = Component => (props) => {
  const { currentOrganization } = props; // eslint-disable-line

  const createReportSchedule = async (payload) => {
    try {
      const variables = parseRequest(payload);
      await graphqlClient(
        currentOrganization && currentOrganization.subdomain,
      ).mutate({ mutation: CREATE_REPORT_SCHEDULE, variables });
      toast.success('Your report was successfuly scheduled.');
    } catch (e) {
      toast.error(e.message);
      throw (e);
    }
  };

  return (
    <Component
      {...props}
      onSchedule={createReportSchedule}
    />
  );
};

const mapStateToProps = ({ organizations, auth, analyticsScreen }) => ({
  currentOrganization: organizations.currentOrganization,
  organizations,
  auth,
  reportVariables: analyticsScreen.reportVariables,
});

const mapDispatchToProps = () => ({
  setOpenReportModal: toggleSendReportModal,
});

export default ReduxConnect(
  mapStateToProps, mapDispatchToProps())(connect(EnvelopeDetailReportScreen));
