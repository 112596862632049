import qs from 'qs';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import authService from '../services/auth';
import graphqlClient from '../config/graphql';
import history from '../config/history';
import organizationService from '../services/organization';
import { fetchSubscriptionPlans } from '../actions/subscriptions';
import { parsePlan } from '../utils/parsers/subscriptionParser';
import { getUserOrganizations } from '../utils';
import { FETCH_CURRENT_USER } from '../screens/UserProfileScreen/queries';
import { FETCH_INVITE } from '../screens/SignUpScreen/queries';

const fetchInvite = async (variables = {}) => {
  try {
    const { data: { invite } } = await graphqlClient().query({
      query: FETCH_INVITE,
      fetchPolicy: 'network-only',
      variables,
    });
    return invite;
  } catch (e) {
    return {};
  }
};

export const fetchCurrentUser = async (org) => {
  try {
    const { data: { currentUser } } = await graphqlClient(org || '').query({
      query: FETCH_CURRENT_USER,
      fetchPolicy: 'network-only',
    });
    return currentUser;
  } catch (e) {
    return {};
  }
};

export const login = values => async (dispatch, getState) => {
  const querySearch = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { currentOrganization } = getState().organizations;
  const {
    enterprisePlanUuid, reportScheduleUuid, plan, invite_token, redirect, reportScheduledEmail,
    tab, uuid,
  } = querySearch;

  try {
    dispatch({ type: Types.auth.RESET });
    dispatch({ type: Types.auth.SET_LOADING, loading: true });
    dispatch({ type: Types.organization.RESET });
    dispatch({ type: Types.subscriptions.RESET });

    // FETCH CURRENT USER
    const { headers, data } = await authService.login(values);
    localStorage.setItem('accessToken', headers['access-token']);
    localStorage.setItem('uid', headers.uid);
    localStorage.setItem('client', headers.client);
    const currentUser = await fetchCurrentUser(currentOrganization &&
       currentOrganization.subdomain);
    const currentPlan = parsePlan(get(currentUser, 'subscription.plan') || {});
    const user = {
      ...data.data,
      ...currentUser,
      currentPlan,
    };

    // SET ORGANIZATIONS LIST
    const { data: organizations } = await organizationService.list();
    dispatch({
      type: Types.organizations.GET_ORGANIZATIONS_LIST,
      payload: organizations,
    });

    // GET SUBSCRIPTION PLAN FROM URL
    const plans = await fetchSubscriptionPlans();
    const planType = plan || localStorage.getItem('defaultPlan');
    const interval = querySearch.interval || localStorage.getItem('interval');
    const planExists = plans.some(s => s.planType === planType && s.interval === (interval || 'MONTH'));
    const getQuery = () => [
      [planType, `plan=${planType}`],
      [interval, `interval=${interval}`],
    ].reduce((z, [a, b]) => z.concat(a ? b : []), []).join('&');
    const paymentQuery = planExists ? `?${getQuery()}` : '';
    localStorage.removeItem('defaultPlan');
    localStorage.removeItem('interval');

    // INVITATION TOKEN
    const inviteToken = invite_token || localStorage.getItem('inviteToken');
    const inviteType = inviteToken ? (await fetchInvite({ inviteToken })).inviteType : '';

    // Redirect the user according to his status
    const goTo = [{
      id: 'redirect',
      when: () => redirect,
      path: () => history.goBack(),
    }, {
      id: 'transfer-ownership',
      when: () => inviteType === 'OWNERSHIP',
      path: () => history.push(`/transfer-ownership?invite_token=${inviteToken}`),
    }, {
      id: 'organization-invite',
      when: () => inviteType === 'MEMBER',
      path: () => history.push(`/organization-invite?invite_token=${inviteToken}`),
    }, {
      id: 'subscription/payment',
      when: () => paymentQuery,
      path: () => history.push(`/subscription/payment${paymentQuery}`),
    }, {
      id: 'subscribe-user',
      when: () => enterprisePlanUuid,
      path: () => history.push(`/subscribe-user/${enterprisePlanUuid}`),
    }, {
      id: 'stop-schedule-report',
      when: () => reportScheduleUuid,
      path: () => history.push(`/stop-report/${reportScheduleUuid}?email=${reportScheduledEmail}`),
    }, {
      id: 'doc-manager-out_for_signature',
      when: () => tab && tab === 'out_for_signature',
      path: () => history.push(`documents-manager?tab=out_for_signature&uuid=${uuid}`),
    }, {
      id: 'organization-login',
      when: () => getUserOrganizations(user, organizations).length > 0,
      path: () => history.push('/organization-login'),
    }, {
      id: 'subscription',
      when: () => user.signInCount === 1 && get(user, 'roles[0].name', '') !== 'super_admin',
      path: () => history.push('/subscription'),
    }].find(g => g.when()) || {
      id: 'dashboard',
      path: () => history.push('/dashboard'),
    };

    // SET CURRENT USER AT THE END PLEASE
    dispatch({ type: Types.auth.LOGIN, payload: user });
    dispatch({ type: Types.auth.SET_LOADING, loading: false });
    goTo.path();
  } catch (error) {
    dispatch({ type: Types.auth.ERROR, error });
    dispatch({ type: Types.auth.SET_LOADING, loading: false });

    if (error.response && error.response.data.errors[0]) {
      toast.error(error.response.data.errors[0]);
    } else {
      toast.error(Messages.auth.login.error);
    }
  }
};

export const logout = (queryParams = '') => (dispatch) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('uid');
  localStorage.removeItem('client');

  dispatch({ type: Types.organization.RESET });
  dispatch({ type: Types.subscriptions.RESET });
  dispatch({ type: Types.auth.RESET });
  dispatch({ type: Types.auth.LOGOUT });

  history.replace(`/login${queryParams}`);
};

export const setUserOrganizations = organizations => dispatch => dispatch({
  type: Types.auth.SET_USER_ORGANIZATIONS,
  organizations,
});

export const setCurrentUser = user => async dispatch => dispatch({
  type: Types.auth.SET_CURRENT_USER,
  user,
});

export const setTokenExpired = tokenExpired => async dispatch => dispatch({
  type: Types.auth.SET_TOKEN_EXPIRED,
  tokenExpired,
});

export default {
  login,
  logout,
  setTokenExpired,
  fetchCurrentUser,
  setUserOrganizations,
  setCurrentUser,
};
