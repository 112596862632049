import validator from 'validator';
import { isInteger } from 'lodash';

export const uploadAndArchiveParser = {
  fromFormToSendDocumentRequest: {
    parseRecipients: recipients => recipients.split(',')
      .map(element => element.trim())
      .filter(element => validator.isEmail(element)),
    parseForm: (formValues) => {
      const {
        recipients,
        subject,
        message,
        cc,
        brandUuid,
      } = formValues;
      return {
        recipients: uploadAndArchiveParser
          .fromFormToSendDocumentRequest.parseRecipients(recipients),
        subject,
        message,
        cc: [cc],
        brand_uuid: isInteger(brandUuid) ? brandUuid : undefined,
      };
    },
  },
};

export default uploadAndArchiveParser;
