import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import { Popover, Menu, MenuItem, IconButton } from 'material-ui';

export const OptionsPopover = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // eslint-disable-next-line
  const renderOption = ({ label, action }) => (
    <MenuItem
      key={label}
      style={{ padding: '0 .5rem' }}
      primaryText={label}
      onClick={() => {
        action();
        setAnchorEl(null);
      }}
    />
  );

  return (
    <>
      <div className="text-right">
        <IconButton
          onClick={(e) => {
            e.cancelBubble = true;
            if (e.stopPropagation) e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </div>

      <Popover
        style={{ minWidth: '10rem' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onRequestClose={() => setAnchorEl(null)}
      >
        <Menu>{options.map(renderOption)}</Menu>
      </Popover>
    </>
  );
};

OptionsPopover.propTypes = {
  options: PropTypes.array,
};

OptionsPopover.defaultProps = {
  options: [],
};

export default OptionsPopover;
