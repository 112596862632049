import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Form, withFormik } from 'formik';
import { RaisedButton, TextField, AutoComplete } from 'material-ui';
import { debounce } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import CustomTextField from '../../commons/CustomTextField';
import googlePlaces from '../../../services/googlePlaces';
import { PhoneMask, ZipMask } from '../OrganizationForm/masks';

export const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .ensure()
    .required('Required')
    .email('Must be a valid email.'),
  phone: Yup.string()
    .ensure()
    .required('Required'),
  password: Yup.string()
    .ensure()
    .required('Required'),
  confirmPassword: Yup.string()
    .ensure()
    .required('Required'),
  firstName: Yup.string()
    .ensure()
    .required('Required'),
  lastName: Yup.string()
    .ensure()
    .required('Required'),
  streetAddress: Yup.string()
    .ensure()
    .required('Required'),
  city: Yup.string()
    .ensure()
    .required('Required'),
  state: Yup.string()
    .ensure()
    .required('Required'),
  zipCode: Yup.string()
    .ensure()
    .required('Required'),
});

const styles = {
  textField: {
    marginTop: '16px',
  },
};


class SignUpForm extends Component {
  state = {
    addressSearchOptions: [],
  }

  setStateField = (field, value) => this.setState({ [field]: value });

  setValue = (field, value) => {
    const { setFieldValue, onChange, values } = this.props;

    setFieldValue(field, value);
    onChange(values);
  };

  // Debounced address search
  searchAddress = debounce((value) => {
    googlePlaces.search(value).then((places) => {
      this.setState({
        addressSearchOptions: places || [],
      });
    });
  }, 1000)

  geocodeSelectedAddress = (selectedAddress) => {
    this.setState({ addressSearchOptions: [] });

    googlePlaces.getDetails(selectedAddress.description).then((details) => {
      this.setValue('streetAddress', details.address);
      this.setValue('zipCode', details.zip);
      this.setValue('city', details.city);
      this.setValue('state', details.state);
    });
  }

  render = () => {
    const {
      values, errors, isValid, touched, classes, invitationEmail,
      setFieldValue, setFieldTouched,
    } = this.props;

    const setValue = field => (e) => {
      setFieldTouched(field, true, false);
      setFieldValue(field, e.target.value);
    };

    return (
      <StyledForm>
        <Layout>
          <CustomStack>
            <TextFieldWrapper>
              <TextField
                fullWidth
                value={values.firstName}
                onChange={setValue('firstName')}
                errorText={touched.firstName && errors.firstName}
                floatingLabelText="First Name *"
                maxLength={25}
              />
            </TextFieldWrapper>
            <TextField
              fullWidth
              value={values.lastName}
              onChange={setValue('lastName')}
              errorText={touched.lastName && errors.lastName}
              floatingLabelText="Last Name *"
              maxLength={25}
            />
          </CustomStack>
          <CustomStack>
            <TextFieldWrapper>
              <AutoComplete
                listStyle={{ width: '100%' }}
                floatingLabelText="Street Address *"
                hintText="Find your address *"
                autoComplete={Math.random()}
                dataSource={this.state.addressSearchOptions.map(option =>
                  ({ text: option.description, value: option }))}
                filter={(searchText, key) =>
                  key.toLowerCase().includes(searchText.toLowerCase())}
                searchText={values.streetAddress}
                onUpdateInput={(value, dataSource) => {
                  this.setValue('streetAddress', value);
                  this.searchAddress(value);

                  const selectedAddress = dataSource.find(item => (item.text === value));

                  if (selectedAddress) {
                    this.geocodeSelectedAddress(selectedAddress.value);
                  }
                }}
                errorText={touched.streetAddress && errors.streetAddress}
              />
            </TextFieldWrapper>
            <TextField
              fullWidth
              value={values.city}
              onChange={setValue('city')}
              errorText={touched.city && errors.city}
              floatingLabelText="City/Town *"
              maxLength={25}
            />
          </CustomStack>
          <CustomStack>
            <TextFieldWrapper>
              <TextField
                fullWidth
                floatingLabelText="State *"
                maxLength={40}
                value={values.state}
                onChange={setValue('state')}
              />
            </TextFieldWrapper>
            <CustomTextField
              value={values.zipCode}
              onChange={setValue('zipCode')}
              errorText={touched.zipCode && errors.zipCode}
              label="Zip Code *"
              fullWidth
              className={classes.textField}
              InputProps={{
                inputComponent: ZipMask,
                classes: {
                  underline: 'text-field-core',
                },
              }}
            />
          </CustomStack>
          <TextField
            fullWidth
            disabled={!!invitationEmail}
            value={values.email}
            onChange={setValue('email')}
            errorText={touched.email && errors.email}
            floatingLabelText="Email *"
            maxLength={30}
          />
          <CustomTextField
            value={values.phone}
            onChange={setValue('phone')}
            errorText={touched.phone && errors.phone}
            label="Phone *"
            fullWidth
            className={classes.textField}
            InputProps={{
              inputComponent: PhoneMask,
              classes: {
                underline: 'text-field-core',
              },
            }}
          />
          <TextField
            fullWidth
            value={values.password}
            onChange={setValue('password')}
            errorText={touched.password && errors.password}
            floatingLabelText="Create Password *"
            type="password"
            maxLength={20}
          />
          <TextField
            fullWidth
            value={values.confirmPassword}
            onChange={setValue('confirmPassword')}
            errorText={touched.confirmPassword && errors.confirmPassword}
            floatingLabelText="Confirm Password *"
            type="password"
            maxLength={20}
          />
        </Layout>
        <ButtonSection>
          <RaisedButton
            type="submit"
            label="Sign Up"
            primary
            buttonStyle={{ width: '7rem' }}
            disabled={!isValid}
          />
        </ButtonSection>
      </StyledForm>
    );
  };
}

SignUpForm.propTypes = {
  invitationEmail: PropTypes.string,
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
};

SignUpForm.defaultProps = {
  invitationEmail: '',
  onSubmit: () => {},
  onChange: () => {},
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Layout = styled.div`
  width: ${!isMobile && '640px'};
  max-height: ${!isMobile && '22rem'};
  padding-right: 1rem;
  padding-bottom: 2rem;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #EDEDED;
  }
  overflow-y: auto;
`;

const Stack = styled.div`
  display: flex;
`;
const Div = styled.div``;

const CustomStack = isMobile ? Div : Stack;

const TextFieldWrapper = styled.div`
  margin-right: 10px;
  padding-right: 10px;
  width: 100%;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
  max-width: 640px;
`;

export default withFormik({
  validationSchema: SignUpSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ invitationEmail }) => ({ email: invitationEmail }),
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) props.onSubmit(values);
  },
})(withStyles(styles)(SignUpForm));

