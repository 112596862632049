
export const fieldsParser = {
  parseFieldToRequest: (field, userId) => ({
    name: field.name,
    input_type: field.type,
    user_id: userId,
    standard: false,
    required: field.required,
  }),
};

export default fieldsParser;
