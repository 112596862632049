import gql from 'graphql-tag';

export const FETCH_INVITE = gql`
  query fetchInvite($inviteToken: String!) {
    invite(token: $inviteToken) {
      email
      inviteType
      recipientName
      uuid
    }
  }
`;

export default { FETCH_INVITE };
