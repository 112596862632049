import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, withFormik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { RaisedButton, TextField, FlatButton } from 'material-ui';
import { Input, InputLabel, FormControl, Select, Chip, MenuItem, FormHelperText } from '@material-ui/core';
import { startCase } from 'lodash';
import * as Yup from 'yup';

import RemoveMemberModal from '../../modals/RemoveMemberModal';
import SendPasswordResetModal from '../../modals/SendPasswordResetModal';
import connect from './connect';
import { colors } from '../../../utils';

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().ensure()
    .required('Required'),
  lastName: Yup.string().ensure()
    .required('Required'),
  email: Yup.string().ensure()
    .email('Must be a valid email.')
    .required('Required'),
  zipCode: Yup.string().ensure()
    .required('Required'),
  role: Yup.string().ensure()
    .required('Required'),
});

const roles = [{
  uuid: '0',
  label: 'Member',
  value: 'MEMBER',
}, {
  uuid: '1',
  label: 'Admin',
  value: 'ADMIN',
}];


const styles = {
  input: {
    color: 'rgba(0, 0, 0, 0.3)',
  },
  select: {
    borderBottom: '1px solid rgb(224, 224, 224)',
    color: 'red',
  },
  chip: {
    backgroundColor: '#DBF3FA',
    marginRight: '5px',
  },
};


class OrganizationMemberForm extends Component {
  state = {
    modalRemoveOpen: false,
    modalPasswordOpen: false,
  }

  setValue = async (field, value) => {
    const {
      setFieldValue, setFieldTouched, onChange,
    } = this.props;
    await setFieldTouched(field, true, false);
    await setFieldValue(field, value);
    const { values } = this.props;
    onChange(values);
  }

  openRemoveModal = () => this.setState({ modalRemoveOpen: true })
  closeRemoveModal = () => this.setState({ modalRemoveOpen: false })

  openPasswordResetModal = () => this.setState({ modalPasswordOpen: true })
  closePasswordResetModal = () => this.setState({ modalPasswordOpen: false })

  render = () => {
    const {
      values, errors, touched, classes,
      sendPasswordReset, userId,
    } = this.props;

    const { modalRemoveOpen, modalPasswordOpen } = this.state;

    return (
      <StyledForm className="w-100 d-flex">
        <div style={{ width: 600 }}>
          <TextField
            fullWidth
            value={values.firstName}
            onChange={e => this.setValue('firstName', e.target.value)}
            errorText={touched.firstName && errors.firstName}
            floatingLabelText="First Name *"
            disabled
          />
          <TextField
            fullWidth
            value={values.lastName}
            onChange={e => this.setValue('lastName', e.target.value)}
            errorText={touched.lastName && errors.lastName}
            floatingLabelText="Last Name *"
            disabled
          />
          <TextField
            fullWidth
            value={values.email}
            onChange={e => this.setValue('email', e.target.value)}
            errorText={touched.email && errors.email}
            floatingLabelText="Email*"
            disabled
          />
          <TextField
            fullWidth
            value={values.phone}
            onChange={e => this.setValue('phone', e.target.value)}
            floatingLabelText="Phone"
            disabled
          />
          <FieldWrapper>
            <FormControl fullWidth error={touched.role && errors.role}>
              <InputLabel className={classes.input}>
                Role*
              </InputLabel>
              <Select
                value={values.role || []}
                onChange={e => this.setValue('role', e.target.value)}
                input={<Input />}
                classes={{
                  select: classes.select,
                }}
                renderValue={value => (
                  <Chip
                    key={value}
                    label={startCase(value.toLowerCase())}
                    classes={{ root: classes.chip }}
                  />
                )}
              >
                {roles.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>

              {touched.role && errors.role && (
                <FormHelperText id="component-error-text">
                  {errors.role}
                </FormHelperText>
              )}
            </FormControl>
          </FieldWrapper>

          <div className="d-flex justify-content-between" style={{ marginTop: 30 }}>
            <RaisedButton
              label="Send Password Reset"
              onClick={this.openPasswordResetModal}
              buttonStyle={{ backgroundColor: colors.darkBlue, height: '45px' }}
              overlayStyle={{
                height: '45px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              labelStyle={{ color: 'white', fontWeight: 300, fontSize: '15px' }}
            />
          </div>
          <div className="d-flex justify-content-between" style={{ marginTop: 24 }}>
            <FlatButton
              onClick={this.openRemoveModal}
              label="Remove From Organization"
              labelStyle={{ color: '#E02525', fontWeight: 500, padding: 0 }}
            />
          </div>

          {modalRemoveOpen && (
            <RemoveMemberModal
              currentUser={values}
              onCancel={this.closeRemoveModal}
            />
          )}
          {modalPasswordOpen && (
            <SendPasswordResetModal
              onSend={() => sendPasswordReset(userId)}
              onCancel={this.closePasswordResetModal}
            />
          )}
        </div>
      </StyledForm>
    );
  }
}

OrganizationMemberForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  sendPasswordReset: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

OrganizationMemberForm.defaultProps = {
  onChange: () => {},
  userId: null,
};

const StyledForm = styled(Form)`
  .MuiInput-underline-27:before {
    border-bottom: 1px solid yellow;
  }
`;

const FieldWrapper = styled.div`
  margin: 15px 0;
`;

export default connect(withFormik({
  validationSchema: SignUpSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }) => ({
    ...initialValues,
  }),
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) props.onSubmit(values);
  },
})(withStyles(styles)(OrganizationMemberForm)));
