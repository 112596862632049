import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, CardActions, FlatButton, RaisedButton, Checkbox } from 'material-ui';
import { isEmpty } from 'lodash';
import CancelIcon from '../../assets/images/close.svg';
import RecipientSelectField from '../RecipientSelectField';
import connect from './connect';
import { colors } from '../../utils';
import { CheckboxFields, RadioButtonFields, DropdownFields } from './specialFields';
import { getFieldName } from '../../utils/pageFields';

const renderSpecialFields = (field, onChange) => {
  if (!field.field_type) return null;

  if (field.field_type === 'checkbox') {
    return (
      <CheckboxFields field={field} onChange={onChange} />
    );
  } else if (field.field_type === 'radio') {
    return (
      <RadioButtonFields field={field} onChange={onChange} />
    );
  } else if (field.field_type === 'select') {
    return (
      <DropdownFields field={field} onChange={onChange} />
    );
  }
  return null;
};

const getContainerHeight = (field) => {
  if (!field.field_type) return null;
  if (field.field_type === 'checkbox') {
    return 445;
  } else if (field.field_type === 'radio') {
    return 495;
  } else if (field.field_type === 'select') {
    return 605;
  }
  return 400;
};

const PageFieldDescription = ({
  field, onSave, onDelete, recipients, onCopyField, onClose,
  visible, scaleRatio, open, pageFields, fields, onRepeatOnAllPages,
}) => {
  const [currentField, setCurrentField] = useState({});
  const [repeatOnAllPages, setRepeatOnAllPages] = useState('');

  const availableFields = fields.standardFields.concat(fields.customFields);


  useEffect(() => {
    if (isEmpty(currentField) || !visible) {
      setCurrentField(field);
      setRepeatOnAllPages(false);
    } else if (currentField.id === field.id &&
      // Field position changed
      ((currentField.x !== field.x) || (currentField.y !== field.y) ||
      // Field dimensions changed
      (currentField.height !== field.height) || (currentField.width !== field.width))) {
      setCurrentField({
        ...currentField, x: field.x, y: field.y, width: field.width, height: field.height,
      });

      setRepeatOnAllPages(false);
    }
  });

  const handleChangeField = (fieldName, value) =>
    setCurrentField({ ...currentField, [fieldName]: value });

  const handleSave = async () => {
    let auxCurrentField = currentField;
    let changedFieldType = false;

    if (field.fieldType !== currentField.fieldType) {
      const auxField = availableFields.find(f => f.id === currentField.fieldType);

      changedFieldType = true;
      const name = await getFieldName(currentField, availableFields, pageFields);
      auxCurrentField = { ...currentField, name, field_type: auxField.input_type };
    }

    if (field.recipientUuid !== currentField.recipientUuid && currentField.field_type === 'radio') {
      const radioButtonOnGroup = pageFields.filter(f =>
        f.radio_button_group_id === currentField.radio_button_group_id && f.id !== currentField.id);
      radioButtonOnGroup.map(rField =>
        onSave({
          ...rField,
          recipientUuid: currentField.recipientUuid,
          checked: currentField.checked ? false : rField.checked,
        }));
    } else if (field.checked !== currentField.checked && currentField.field_type === 'radio' && currentField.checked) {
      const radioButtonOnGroup = pageFields.filter(f =>
        f.radio_button_group_id === currentField.radio_button_group_id && f.id !== currentField.id);
      radioButtonOnGroup.map(rField =>
        onSave({ ...rField, checked: false }));
    }

    onSave(auxCurrentField, currentField.pageId, scaleRatio, changedFieldType);
    if (repeatOnAllPages) {
      onRepeatOnAllPages(auxCurrentField);
    }
    onClose();
  };

  const handleRemove = () => {
    onDelete(currentField);
    onClose();
  };

  const onTop = currentField.y > 350;

  return (
    <Container
      height={getContainerHeight(currentField)}
      visible={visible || open}
      onTop={onTop}
      top={currentField.y}
      left={currentField.x}
      fieldWidth={currentField.width}
      fieldHeight={currentField.height}
    >
      <Card style={{ boxShadow: 'none' }}>
        <FlexContainer justifyContent="flex-end">
          <CloseIcon src={CancelIcon} onClick={onClose} />
        </FlexContainer>
        <TitleContainer>{currentField.name}</TitleContainer>
        <InputContent>
          <div>Assigned To </div>
          <div>
            <RecipientSelectField
              enabled
              recipients={recipients}
              value={currentField.recipientUuid}
              onChange={value => handleChangeField('recipientUuid', value)}
            />
          </div>
        </InputContent>

        {renderSpecialFields(currentField, handleChangeField)}

        <FlexContainer margin="1rem 0rem 0rem">
          <FlexContainer margin="0.5rem 0rem 0rem">
            <Checkbox primary label="Required" checked={currentField.required} onCheck={() => handleChangeField('required', !currentField.required)} />
          </FlexContainer>
          <FlexContainer justifyContent="flex-end">
            <FlatButton primary label="Copy field" onClick={() => onCopyField(currentField)} labelStyle={{ textTransform: 'unset' }} />
          </FlexContainer>
        </FlexContainer>

        <FlexContainer margin="1rem 0rem">
          <FlexContainer>
            <Checkbox primary label="Repeat on all pages" checked={repeatOnAllPages} onCheck={() => setRepeatOnAllPages(!repeatOnAllPages)} />
          </FlexContainer>
        </FlexContainer>

        <CustomCardAction>
          <FlatButton primary onClick={handleRemove} label="Delete" />
          <RaisedButton primary onClick={handleSave} label="Save" className="save-button" />
        </CustomCardAction>
      </Card>
    </Container>
  );
};

PageFieldDescription.propTypes = {
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  field: PropTypes.any,
  fields: PropTypes.any,
  onCopyField: PropTypes.func,
  visible: PropTypes.bool,
  open: PropTypes.bool,
  recipients: PropTypes.array,
  scaleRatio: PropTypes.number.isRequired,
  pageFields: PropTypes.array.isRequired,
  onRepeatOnAllPages: PropTypes.func.isRequired,
};

PageFieldDescription.defaultProps = {
  field: {},
  fields: {},
  onSave: () => {},
  onDelete: () => {},
  onClose: () => {},
  onCopyField: () => {},
  visible: false,
  open: false,
  recipients: [],
};

const Container = styled.div`
  width: 28rem;
  min-height: 5rem;
  z-index: 10;
  position: absolute;
  top: ${props => (props.onTop ? (props.top + 50) - (props.height) : (props.top + props.fieldHeight + 40))}px;
  left: ${props => props.left - (220 - (props.fieldWidth / 2))}px;
  border-radius: 2px;
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));

  & > div {
    padding: 1.3rem;
  }

  &:after {
    content: " ";
    position: absolute;
    bottom: ${props => (props.onTop ? '-15px' : 'auto')};
    top: ${props => (props.onTop ? 'auto' : '-15px')};
    left: 200px;
    width: 0;
    height: 0;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    border-top: ${props => (props.onTop ? '15px solid white' : 'none')};
    border-bottom: ${props => (props.onTop ? 'none' : '15px solid white')};
    z-index: 1;
  }

`;

const TitleContainer = styled.div`
  font-size: 1rem;
  padding-bottom: 0.6rem;
  color: black;
  font-weight: 600;
  font-family: DIN-bold;
`;

const InputContent = styled.div`
  color: ${colors.defaultImageGray};
  display: flex;
  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }
`;

const CustomCardAction = styled(CardActions)`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    & > .save-button > button{
      background-color: #246C9E !important;
      padding: 0rem 2rem;
      & > div > div{
        padding: 0rem 2rem;
        }
    }
`;

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  margin: ${props => props.margin};

  & label {
    color: ${colors.defaultImageGray} !important;
  }
  & svg {
    fill: #246C9E !important;
  }
`;

const CloseIcon = styled.img`
  cursor: pointer;
  filter: opacity(0.4);
  width: 1rem;
`;


export default connect(PageFieldDescription);
