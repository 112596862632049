import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from '../..';

class SendPasswordResetModal extends Component {
  handleVoidClick = () => {
    const { onSend, onCancel } = this.props;
    onSend();
    onCancel();
  }

  renderContent = () => (
    <Wrapper>
      <Title>Send Password Reset?</Title>
      <Description>
        Are you sure you want to send a password reset link to this user?
      </Description>
    </Wrapper>
  );

  render = () => {
    const { onCancel } = this.props;
    return (
      <Modal
        open
        title=""
        content={this.renderContent()}
        onPrimaryClick={this.handleVoidClick}
        onSecondaryClick={onCancel}
        primaryLabel="Send"
        contentStyle={{ width: '600px' }}
      />
    );
  }
}

SendPasswordResetModal.propTypes = {
  onSend: PropTypes.func,
  onCancel: PropTypes.func,
};

SendPasswordResetModal.defaultProps = {
  onSend: () => {},
  onCancel: () => {},
};

const Wrapper = styled.div`
  color: black;
  font-family: DIN-Medium;
`;

const Title = styled.div`
  font-size: 20px;
`;

const Description = styled.div`
  margin: 40px 0;
`;

export default SendPasswordResetModal;
