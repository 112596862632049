import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  displayModal: false,
  sendReportModal: false,
  reportStopedSuccessfullyModal: false,
  sending: false,
  reportVariables: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.analyticsScreen.TOGGLE_SCHEDULE_MODAL: {
      const { displayModal } = action;
      return {
        ...state,
        displayModal,
      };
    }
    case Types.analyticsScreen.TOGGLE_SEND_REPORT_MODAL: {
      const sendReportModal = action.display;
      return { ...state, sendReportModal };
    }

    case Types.analyticsScreen.SENDING_REPORT: {
      const { sending } = action;
      return { ...state, sending };
    }

    case Types.analyticsScreen.SET_REPORT_VARIABLES: {
      const { variables } = action;
      return { ...state, reportVariables: variables };
    }

    case Types.analyticsScreen.SET_STOP_REPORT_SUCCESSFULLY_MODAL: {
      const reportStopedSuccessfullyModal = action.open;
      return { ...state, reportStopedSuccessfullyModal };
    }

    default:
      return state;
  }
};
