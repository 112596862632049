import sanitizeFilename from 'sanitize-filename';

const fileNormalizer = (value, fileExtension) => {
  if (value && fileExtension) {
    const fileName = sanitizeFilename(value);
    return fileName.endsWith(fileExtension) ? fileName : fileName.concat(fileExtension);
  }
  return value;
};


export default {
  fileNormalizer,
};
