import { toast } from 'react-toastify';
import { submit } from 'redux-form';
import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import brandsService from './../services/brandsService';

export const fetchBrands = () => async (dispatch, getState) => {
  try {
    const { currentOrganization } = getState().organizations;
    if (!currentOrganization || !currentOrganization.uuid) {
      dispatch({
        type: Types.brandsSection.GET_BRANDS_LIST,
        brands: [],
      });
      return Promise.resolve();
    }

    const { data } = await brandsService.list(currentOrganization.uuid);
    dispatch({
      type: Types.brandsSection.GET_BRANDS_LIST,
      brands: data,
    });
    return Promise.resolve(data);
  } catch (error) {
    toast.error(Messages.brands.get.error);
    return Promise.reject(error);
  }
};

export const toggleBrandModal = displayModal => ({
  type: Types.brandsSection.TOGGLE_MODAL,
  displayModal,
});

export const brandEditModal = brandUuid => (dispatch) => {
  dispatch({
    type: Types.brandsSection.SET_CURRENT,
    brandUuid,
  });

  dispatch(toggleBrandModal(true));
};

export const submitBrand = () => (dispatch) => {
  dispatch(submit('brandForm'));
};

export const addBrand = values => async (dispatch, getState) => {
  dispatch({
    type: Types.brandsSection.SAVING,
    saving: true,
  });

  try {
    const {
      organizations: { currentOrganization: { uuid } },
    } = getState();

    const { data: response } = await brandsService.create(uuid, {
      brand: { ...values, organization_uuid: uuid },
    });
    dispatch({
      type: Types.brandsSection.ADD,
      brand: response,
    });

    const { data } = await brandsService.list(uuid);
    dispatch({
      type: Types.brandsSection.GET_BRANDS_LIST,
      brands: data,
    });

    dispatch(toggleBrandModal(false));

    toast.success(Messages.brands.create.success);

    dispatch(toggleBrandModal(false));
  } catch (error) {
    toast.error(Messages.brands.create.error);
  } finally {
    dispatch({
      type: Types.brandsSection.SAVING,
      saving: false,
    });
  }
};

export const editBrand = values => async (dispatch, getState) => {
  dispatch({
    type: Types.brandsSection.SAVING,
    saving: true,
  });

  try {
    const {
      organizations: { currentOrganization },
      brandsSection: { brands, currentBrand },
    } = getState();

    delete values.organization;
    if (!(values.email_logo === null) && (typeof values.email_logo === 'string')) {
      delete values.email_logo;
    }

    if (!(values.signing_logo === null) && (typeof values.signing_logo === 'string')) {
      delete values.signing_logo;
    }

    const { data: response } = await brandsService.update(
      currentOrganization.uuid,
      currentBrand.uuid,
      { brand: { ...values, organization_uuid: currentOrganization.uuid } },
    );

    dispatch({
      type: Types.brandsSection.UPDATE,
      brand: response,
    });

    dispatch({
      type: Types.brandsSection.GET_BRANDS_LIST,
      brands: brands.map(b => ({
        ...b,
        ...(values.uuid === b.uuid ? { ...values, default: response.default } : {}),
      })),
    });

    toast.success(Messages.brands.update.success);

    dispatch(toggleBrandModal(false));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error', error);
    toast.error(Messages.brands.update.error);
  } finally {
    dispatch({
      type: Types.brandsSection.SAVING,
      saving: false,
    });
  }
};

export const setDefaultBrand = id => async (dispatch, getState) => {
  try {
    const { currentOrganization } = getState().organizations;
    if ((currentOrganization || {}).uuid) {
      await brandsService.setDefault(currentOrganization.uuid, id);
      dispatch({ type: Types.brandsSection.SET_DEFAULT, id });
    }
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteBrand = id => async (dispatch, getState) => {
  try {
    const organizationUuid = getState().organizations.currentOrganization.uuid;
    await brandsService.delete(organizationUuid, id);

    dispatch({
      type: Types.brandsSection.DELETE,
      id,
    });

    toast.success(Messages.brands.delete.success);
  } catch (error) {
    toast.error(Messages.brands.delete.error);
  }
};

export default {
  fetchBrands,
  toggleBrandModal,
  brandEditModal,
  submitBrand,
  addBrand,
  editBrand,
  setDefaultBrand,
  deleteBrand,
};
