import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  fields: [],
  loading: false,
  scaleRatio: 1,
  imageWidth: null,
  settingScaleRatio: false,
  userSignature: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.signDocumentScreen.SET_LOADING: {
      const { loading } = action;

      return {
        ...state,
        loading,
      };
    }
    case Types.signDocumentScreen.SET_FIELDS: {
      const { fields } = action;

      return {
        ...state,
        fields,
      };
    }
    case Types.signDocumentScreen.SET_FIELD: {
      const { field } = action;
      const { fields } = state;
      const index = fields.map(f => f.id).indexOf(field.id);
      if (index !== -1) {
        fields[index] = field;
      }
      return {
        ...state,
        fields,
      };
    }

    case Types.signDocumentScreen.SETTING_SCALE_RATIO: {
      const { settingScaleRatio } = action;

      return {
        ...state,
        settingScaleRatio,
      };
    }

    case Types.signDocumentScreen.SET_SCALE_RATIO: {
      const { scaleRatio, settingScaleRatio, imageWidth } = action;
      if (!settingScaleRatio) {
        return {
          ...state,
          scaleRatio,
          imageWidth,
        };
      }
      return {
        ...state,
      };
    }

    case Types.signDocumentScreen.SET_USER_SIGNATURE: {
      const { userSignature } = action;

      return {
        ...state,
        userSignature,
      };
    }


    default:
      return state;
  }
};
