export default {
  backgroundGray: '#FAFAFA',
  blue: '#03a9f4',
  darkGray: '#707070',
  defaultImageGray: '#A8A8A8',
  gray: '#ECEFF1',
  green: '#4CAF50',
  lightGray: '#EDEDED',
  purple: '#3F51B5',
  red: '#D50000',
  textLight: 'rgba(0,0,0,0.54)',
  textBlack: 'rgba(0, 0, 0, 0.87)',
  danger: '#D60B0B',
  white: '#FFFFFF',
  yellow: '#F9A825',
  darkBlue: '#016D9F',
};
