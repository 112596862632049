import React, { useEffect, useState } from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';

import DocumentRemindSection from './DocumentRemindSection';
import Messages from '../../../constants/toastMessages';
import graphqlClient from '../../../config/graphql';
import q from './queries';
import * as DocumentManagerActions from '../../../actions/documentManagerScreen';

const { remind: reminderMsg } = Messages.documentManager;

const connect = WrappedComponent => (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    currentOrganization, selectedDocument, setSelectedDocument,
  } = props;
  const { uuid: signatureRequestUuid, name: query } = selectedDocument;
  const [loading, setLoading] = useState(false);
  const { subdomain } = currentOrganization || {};

  const updateSelectedDocument = async () => {
    try {
      const {
        data: { waitingForSignature: { nodes } },
      } = await graphqlClient(subdomain).query({
        query: q.SEARCH_SIGNATURE_REQUESTS,
        fetchPolicy: 'network-only',
        variables: { query },
      });
      const {
        recipients: { nodes: recipients },
        reminders: { nodes: reminders },
      } = nodes.find(n => n.uuid === signatureRequestUuid);
      setSelectedDocument({
        ...selectedDocument,
        ...(recipients ? { recipients } : {}),
        reminder: reminders[0] || {},
        reminders,
      });
    } catch (e) {
      toast.error(reminderMsg.fetch.error);
    }
  };

  const reloadSelectedDocument = async () => {
    setLoading(true);
    await updateSelectedDocument();
    setLoading(false);
  };

  const deleteReminder = async (uuid) => {
    setLoading(true);
    try {
      await graphqlClient(subdomain).mutate({
        mutation: q.DELETE_REMINDER,
        variables: { uuid },
      });
      await updateSelectedDocument();
      toast.success(reminderMsg.stop.success);
    } catch (e) {
      toast.error(reminderMsg.stop.error);
      throw (e);
    } finally {
      setLoading(false);
    }
  };

  const createOrUpdateReminder = async ({ reminderUuid, ...variables }) => {
    const msg = variables.frequency === 'NOW' ? reminderMsg.sent : reminderMsg.schedule;
    const message = 'Signature Reminder';

    setLoading(true);
    try {
      if (reminderUuid) {
        await graphqlClient(subdomain).mutate({
          mutation: q.DELETE_REMINDER,
          variables: { uuid: reminderUuid },
        });
      }
      await graphqlClient(subdomain).mutate({
        mutation: q.CREATE_REMINDER,
        variables: { ...variables, signatureRequestUuid, message },
      });
      await updateSelectedDocument();
      toast.success(msg.success);
    } catch (e) {
      toast.error(msg.error);
      throw (e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reloadSelectedDocument();
  }, []);

  const compProps = {
    ...props,
    saveReminder: createOrUpdateReminder,
    stopReminder: deleteReminder,
    loading: props.loading || loading, // eslint-disable-line
  };

  return <WrappedComponent {...compProps} />;
};

export default RConnect(({ organizations, documentManagerScreen }) => ({
  currentOrganization: organizations.currentOrganization,
  selectedDocument: documentManagerScreen.selectedDocument,
}), {
  setSelectedDocument: DocumentManagerActions.setSelectedDocument,
})(connect(DocumentRemindSection));
