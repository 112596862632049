import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Paper } from 'material-ui';

import history from '../../config/history';
import { RenewEnterprisePlanForm } from '../../components/forms';
import { animations } from '../../utils';

export const RenewEnterprisePlanScreen = (props) => {
  const {
    stripe: propStripe, loading: propLoading, selectedSubscription, subscribeUser,
    creditCardInfo, onUpdateCreditCard, renewPlan,
  } = props;
  const [loading, setLoading] = useState(false);

  const submit = async (selectedOption, stripe) => {
    setLoading(true);
    if (selectedOption === 'new') {
      const { token } = await stripe.createToken();
      await onUpdateCreditCard(token);
    }
    renewPlan();
    setLoading(false);
  };

  return (
    <Container>
      <Box zDepth={3}>
        <RenewEnterprisePlanForm
          formTitle="Confirm your payment information."
          stripe={propStripe}
          loading={propLoading || loading}
          selectedSubscription={selectedSubscription}
          submit={submit}
          onPay={({ id }) => subscribeUser(id, selectedSubscription.uuid)}
          onBack={() => history.goBack()}
          creditCardInfo={creditCardInfo}
        />
      </Box>
    </Container>
  );
};

RenewEnterprisePlanScreen.propTypes = {
  loading: PropTypes.bool,
  selectedSubscription: PropTypes.object,
  subscribeUser: PropTypes.func.isRequired,
  creditCardInfo: PropTypes.object,
  stripe: PropTypes.object.isRequired,
  onUpdateCreditCard: PropTypes.func.isRequired,
  renewPlan: PropTypes.func.isRequired,
};

RenewEnterprisePlanScreen.defaultProps = {
  loading: false,
  selectedSubscription: {},
  creditCardInfo: {},
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled(Paper)`
  width: 1000px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  padding: 30px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 40px;
  align-self: center;
`;

export default RenewEnterprisePlanScreen;
