import React, { useEffect, useState } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { connect as reduxConnect } from 'react-redux';
import { toast } from 'react-toastify';

import Messages from '../../constants/toastMessages';
import RenewEnterprisePlanScreen from './RenewEnterprisePlanScreen';
import graphqlClient from '../../config/graphql';
import stripeApiKey from '../../constants/stripeKey';
import userActions from '../../actions/userProfileScreen';
import { RENEW_ENTERPRISE_PLAN } from './queries';
import { UPDATE_CARD } from '../../components/forms/UpdateCreditCardForm/queries';
import { fetchCurrentUser } from '../../actions/auth';

export const connect = Component => (props) => {
  const {
    /* eslint-disable react/prop-types */
    history,
    subscriptions: { selectedSubscription },
    user: { currentPlan },
    setCurrentUser,
    /* eslint-enable */
  } = props;
  const [creditCardInfo, setCreditCardInfo] = useState({});

  const bootstrap = async () => {
    if (!currentPlan.planType.toLowerCase().includes('enterprise')) {
      history.push('/dashboard');
    }
    const user = await fetchCurrentUser();
    setCreditCardInfo(user.cardInfo || {});
  };

  useEffect(() => {
    bootstrap();
  }, []);

  const updateCard = async (variables) => {
    const { id } = variables;
    try {
      await graphqlClient().mutate({
        mutation: UPDATE_CARD,
        variables: { cardToken: id },
      });
      const currentUser = await fetchCurrentUser();
      setCurrentUser(currentUser);
      toast.success('Credit card updated successfully');
    } catch (e) {
      toast.error(e.message);
      throw (e);
    }
  };

  const renewPlan = async () => {
    try {
      await graphqlClient().mutate({ mutation: RENEW_ENTERPRISE_PLAN });
      toast.success(Messages.subscription.success);
      history.push('/');
    } catch (e) {
      toast.error(Messages.subscription.error);
      throw e;
    }
  };

  return (
    <StripeProvider apiKey={stripeApiKey}>
      <Component
        {...props}
        selectedSubscription={selectedSubscription}
        creditCardInfo={creditCardInfo}
        updateCard={updateCard}
        renewPlan={renewPlan}
      />
    </StripeProvider>
  );
};

export default reduxConnect(({
  subscriptions, auth,
}) => ({
  subscriptions, user: auth.user,
}), {
  setCurrentUser: userActions.setCurrentUser,
})(connect(RenewEnterprisePlanScreen));
