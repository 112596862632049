import gql from 'graphql-tag';

export const CREATE_REPORT_SCHEDULE = gql`
  mutation createReportSchedule(
    $startDate: IsoTime!
    $endDate: IsoTime!
    $reportType: ReportTypeEnum!
    $sendToMe: Boolean!
    $frequency: ScheduleFrequencyEnum!
    $message: String
    $options: ScheduleOptions
  ) {
    createReportSchedule(
      startDate: $startDate
      endDate: $endDate
      reportType: $reportType
      sendToMe: $sendToMe
      frequency: $frequency
      message: $message
      options: $options
    ) {
      errors
      reportSchedule {
        uuid
      }
    }
  }
`;

export default { CREATE_REPORT_SCHEDULE };
