import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { ResizableBox } from 'react-resizable';
import moment from 'moment';
import { isEmpty, capitalize } from 'lodash';
import htmlToImage from 'html-to-image';

import CrossIcon from '../../assets/images/fill_sign_icons/cross-icon.svg';
import CircleIcon from '../../assets/images/fill_sign_icons/circle-icon.svg';
import CheckIcon from '../../assets/images/fill_sign_icons/check-icon.svg';
import LineIcon from '../../assets/images/fill_sign_icons/line-icon.svg';
import DotIcon from '../../assets/images/fill_sign_icons/dot-icon.svg';
import DocumentSign from '../documentSign';
import { DragItemTypes } from '../../constants';
import { unscaleValue } from '../../utils/scaling';

const {
  Input, Signature, RemoteDatepicker, Image, Circle,
} = DocumentSign;
const FONT_SIZE = 16;
// DnD Spec
const dragSourceSpec = {
  canDrag(props) {
    return props.drag;
  },
  beginDrag(props) {
    return { ...props.field };
  },
};

// DnD Collector
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class TemplateField extends React.Component {
  state = {
    showDp: false,
  };

  onResize = (data, scaleRatio) => {
    const {
      size: { width, height },
    } = data;
    const {
      updateTemplateField, updateTemplatePress, field, pageNumber,
    } = this.props;

    if (field.type === 'circle') {
      document.documentElement.classList.add('hide-scrollbar');
      htmlToImage.toPng(document.getElementById(`circle-image-${field.id}`))
        .then((dataUrl) => {
          updateTemplateField({
            ...field,
            width: unscaleValue(width, scaleRatio),
            height: unscaleValue(height, scaleRatio),
            pageNumber,
            content: dataUrl,
            value: dataUrl,
          });
          document.documentElement.classList.remove('hide-scrollbar');
        })
        .catch(() => {
          document.documentElement.classList.remove('hide-scrollbar');
        });
    } else {
      updateTemplateField({
        ...field,
        width: unscaleValue(width, scaleRatio),
        height: unscaleValue(height, scaleRatio),
        pageNumber,
      });
    }
    updateTemplatePress(true);
  };

  onSavedImage = (content, printName) => {
    const { field, updateTemplateField } = this.props;
    updateTemplateField({
      ...field, content, printName, value: content,
    });
  };

  getContainerStyle = (field, scaleRatio) => ({
    position: 'absolute',
    left: field.x * scaleRatio,
    top: field.y * scaleRatio,
  });

  deleteField = () => this.props.removeTemplateField(this.props.field);

  styleField = ({ width, height }, scaleRatio) => ({
    width: width * scaleRatio,
    height: height * scaleRatio,
    border: '1px dashed',
    borderColor: '#54BCEA',
    borderRadius: '5px',
    fontFamily: 'Helvetica',
    fontSize: FONT_SIZE * scaleRatio,
  });

  triggerDatepicker = () => {
    this.setState({
      showDp: true,
    });
  };

  preloadSignature(type) {
    if (type === 'signature' && this.props.auth.user.default_signature) {
      return this.props.auth.user.default_signature;
    }
    if (type === 'initials' && this.props.auth.user.default_initials) {
      return this.props.auth.user.default_initials;
    }
    return null;
  }

  renderFieldByType = (field, scaleRatio) => {
    const style = this.styleField(field, scaleRatio);

    switch (field.type) {
      case 'input': {
        return (
          <Input
            value={field.value}
            scaleRatio={scaleRatio}
            type="text"
            onChange={(e, dimensions) => this.props.updateTemplateField({
              ...field,
              ...dimensions,
              value: e.target.value,
            })}
            fieldName={`field_${field.id}`}
            placeholder="Text"
            editable
            onRemove={() => this.props.removeTemplateField(field)}
            style={{
              ...style,
              display: 'block',
              overflow: 'hidden',
              padding: '0',
              lineHeight: 1.5,
            }}
          />
        );
      }
      case 'cross': {
        return (
          <Image src={CrossIcon} editable onRemove={() => this.props.removeTemplateField(field)} />
        );
      }
      case 'check': {
        return (
          <Image src={CheckIcon} editable onRemove={() => this.props.removeTemplateField(field)} />
        );
      }
      case 'circle': {
        return (
          <Circle
            id={`circle-image-${field.id}`}
            src={CircleIcon}
            editable
            onRemove={() => this.props.removeTemplateField(field)}
          />
        );
      }
      case 'line': {
        return (
          <Image src={LineIcon} editable onRemove={() => this.props.removeTemplateField(field)} />
        );
      }
      case 'dot': {
        return (
          <Image
            src={DotIcon}
            editable
            isDot
            onRemove={() => this.props.removeTemplateField(field)}
          />
        );
      }
      case 'date_picker':
        return (
          <RemoteDatepicker
            completed={(value) => {
              this.setState({
                showDp: false,
              });
              const formatted = moment(value).format('MM/DD/YYYY');
              this.props.updateTemplateField({ ...field, value: formatted });
            }}
            showDp={this.state.showDp}
            field={field}
            style={style}
            editable
            onRemove={() => this.props.removeTemplateField(field)}
            disabled
            scaleRatio={scaleRatio}
            onClick={this.triggerDatepicker}
          />
        );
      case 'initials':
      case 'signature':
        return (
          <Signature
            width={field.width * scaleRatio}
            height={field.height * scaleRatio}
            fieldName={`field_${field.id}`}
            fieldValue={!isEmpty(field.content) ? field.content : undefined}
            onSaved={(content, printName) => this.onSavedImage(content, printName)}
            style={{ border: '1px dashed', borderColor: '#54BCEA' }}
            drawType={field.type}
            bgcolor="transparent"
            preload={this.preloadSignature(field.type)}
            placeholder={capitalize(field.type)}
            editable
            onRemove={() => this.props.removeTemplateField(field)}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      connectDragSource, isDragging, drag, field, scaleRatio,
    } = this.props;

    const getMaxConstraints = (type) => {
      if (type === 'date_picker') {
        return [500 * scaleRatio, 40 * scaleRatio];
      } else if (['cross', 'check', 'line', 'dot', 'circle'].includes(type)) {
        return [250 * scaleRatio, 250 * scaleRatio];
      } else if (type === 'input') {
        return [600 * scaleRatio, 30 * scaleRatio];
      }
      return [500 * scaleRatio, 600 * scaleRatio];
    };

    return connectDragSource(
      <div
        style={this.getContainerStyle(field, scaleRatio)}
        placeholder={{ isDragging, drag }}
        onContextMenu={this.openContextMenu}
      >
        <ResizableBox
          className="react-resizable-white"
          width={field.width * scaleRatio}
          height={field.height * scaleRatio}
          minConstraints={[30 * scaleRatio, 30 * scaleRatio]}
          maxConstraints={getMaxConstraints(field.type)}
          onResizeStart={() => this.props.updateTemplatePress(false)}
          onResizeStop={(e, data) => this.onResize(data, scaleRatio)}
        >
          {this.renderFieldByType(field, scaleRatio)}
        </ResizableBox>
      </div>,
    );
  }
}

TemplateField.propTypes = {
  field: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  drag: PropTypes.bool.isRequired,
  pageNumber: PropTypes.number,
  updateTemplateField: PropTypes.func.isRequired,
  removeTemplateField: PropTypes.func.isRequired,
  updateTemplatePress: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number.isRequired,
};

TemplateField.defaultProps = {
  pageNumber: 1,
};

export default DragSource(DragItemTypes.PAGE_FIELD, dragSourceSpec, collect)(TemplateField);
