import gql from 'graphql-tag';

export const DELETE_INVITE = gql`
mutation DeleteInvite(
  $uuid: ID!,
  ){
    deleteInvite(
    uuid: $uuid,
  ){
    success
  }
}
`;

export default {
  DELETE_INVITE,
};
