import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal } from '../..';
import { RenewEnterprisePlanForm } from '../../forms';

const PaymentInformationModal = props => (
  <StyledModal
    {...props}
    isScrollable
    content={<RenewEnterprisePlanForm {...props} />}
    actions={<div />}
  />
);

PaymentInformationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

PaymentInformationModal.defaultProps = {
  loading: false,
};

const StyledModal = styled(Modal)`
  & > div > div {
    max-width: 980px !important;
  }
  & > div > div > div {
    padding: 3rem 0rem 0rem;
  }
  & h3 {
    text-align: center;
  }
`;

export { PaymentInformationModal as default, PaymentInformationModal };
