import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Paper, CircularProgress } from 'material-ui';

import LogoImage from '../../assets/images/logo.svg';
import { SignUpForm } from '../../components/forms';
import { animations, colors } from '../../utils';

export const SignUpScreen = ({
  onSubmit, onBack, onHaveAnAccount, invitationEmail, loading,
}) => (
  <Container className="d-flex justify-content-center align-items-center">
    <SignUpBox zDepth={3}>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ width: 1000, height: 606, padding: '20px 0' }}
      >
        {loading ? <CircularProgress size={120} thickness={7} /> : (
          <>
            <Logo src={LogoImage} />
            <Title>Sign Up</Title>
            <SignUpForm
              invitationEmail={invitationEmail}
              onBack={onBack}
              onSubmit={onSubmit}
            />
          </>
        )}
      </div>
      <HaveAnAccountSection onClick={onHaveAnAccount}>
        ALREADY HAVE AN ACCOUNT?
      </HaveAnAccountSection>
    </SignUpBox>
  </Container>
);

SignUpScreen.propTypes = {
  loading: PropTypes.bool,
  invitationEmail: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onHaveAnAccount: PropTypes.func.isRequired,
};

SignUpScreen.defaultProps = {
  loading: false,
  invitationEmail: '',
};

const Container = styled.div`
  min-width: 100vw;
  min-height: ${!isMobile && '100vh'};
  & > div {
    width: ${isMobile && '100% !important'};
    height: ${isMobile && '100% !important'};
    & > div {
      width: ${isMobile && '96% !important'};
      margin-left: ${isMobile && '2% !important'};
      height: ${isMobile && '100% !important'};
    }
  }
`;

const Title = styled.div`
  font-family: DIN-Medium;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 26px;
  width: ${!isMobile && '640px'};
  margin-bottom: 1rem;
`;

export const SignUpBox = styled(Paper)`
  width: ${!isMobile && '1000px'};
  animation: ${animations.fadeIn} 0.6s ease-in-out;
`;

export const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 40px;
  align-self: center;
`;

const HaveAnAccountSection = styled.div`
  margin-top: 1rem;
  padding: 0.7rem 0rem;
  color: #FFFFFF;
  font-family: DIN-Medium;
  font-size: 14px;
  background-color: ${colors.blue};
  color: white;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

export default SignUpScreen;
