import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RaisedButton } from 'material-ui';

const EmptyListNotification = ({ message, link, buttonLabel }) => (
  <div
    className="d-flex flex-column justify-content-center align-items-center"
    style={{ height: 300 }}
  >
    <h4 className="mb-4">{message}</h4>
    <Link to={link}>
      <RaisedButton label={buttonLabel} primary />
    </Link>
  </div>
);

EmptyListNotification.propTypes = {
  message: PropTypes.string,
  link: PropTypes.string,
  buttonLabel: PropTypes.string,
};

EmptyListNotification.defaultProps = {
  message: 'Nothing to see here! There are no items to show.',
  link: '/',
  buttonLabel: 'Dashboard',
};

export default EmptyListNotification;
