import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import connect from './connect';
import { ForgotPasswordForm } from '../../components/forms';
import { LoginBox } from '../LoginScreen/LoginScreen';

const ForgotPasswordScreen = ({ forgotPassword, onCancel }) => (
  <Container className="d-flex justify-content-center align-items-center">
    <LoginBox zDepth={3} className="p-4 pb-5">
      <Title className="mt-4">Forgot Password</Title>
      <Subtitle>Please enter your email address to receive a reset password link</Subtitle>
      <ForgotPasswordForm handleSubmit={forgotPassword} onCancel={onCancel} style={{ width: '100%' }} />
    </LoginBox>
  </Container>
);

ForgotPasswordScreen.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;

const Title = styled.div`
  font-family: DIN-Medium;
  font-size: 24px;
  width: 100%;
  padding-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-family: DIN-Medium;
  font-size: 14px;
  line-height: 20px;
  margin: 0 !important;
  width: 100%;
`;

export default connect(ForgotPasswordScreen);

