import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, CircularProgress } from 'material-ui';
import { colors } from '../../utils';

const UploadingDocumentModal = ({
  isOpen, message,
}) => (
  <Dialog
    open={isOpen}
  >
    <Container>
      <CircularProgress className="mb-3" />
      <span>{message}</span>
    </Container>
  </Dialog>
);

const Container = styled.div`
  width: 100%;
  height: 220px;
  background: ${props => (props.isOver ? 'white' : colors.gray)};
  border: dashed .25rem ${colors.blue};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
`;

UploadingDocumentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

UploadingDocumentModal.defaultProps = {
  message: 'Uploading Document',
};

export default UploadingDocumentModal;
