const colors = [
  '#27ae60',
  '#3498db',
  '#e67e22',
  '#f1c40f',
  '#e74c3c',
  '#9b59b6',
  '#e84393',
  '#81ecec',
  '#ffeaa7',
  '#7bed9f',
  '#ff9ff3',
  '#1e3799',
  '#5f27cd',
  '#2f3542',
  '#a4b0be',
];

export default colors;
