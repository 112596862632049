import gql from 'graphql-tag';


export const DECLINE_INVITE = gql`
  mutation DeclineInvite(
    $inviteToken: String,
    $inviteUuid: ID,
    $declineReason: String,
    ){
      declineInvite(
      inviteToken: $inviteToken,
      inviteUuid: $inviteUuid,
      declineReason: $declineReason,
    ){
      success
    }
  }
`;

export default {
  DECLINE_INVITE,
};
