import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  showAddFilesDialog: false,
  addFilesCallback: null,
  files: [],
  selectedFiles: [],
  loading: false,
  uploading: false,
  uploadProgress: 0,
  handler: 'uploadDocuments',
  templates: [],
  template: {},
  documents: {},
  documentTmp: {},
  previousDocuments: {},
  wip: false,
  wipMessage: '',
  propsDialog: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.filesManager.SET_ADD_FILES_DIALOG:
      return {
        ...state,
        showAddFilesDialog: action.showAddFilesDialog,
        addFilesCallback: action.addFilesCallback,
        uploading: action.showAddFilesDialog ? state.uploading : INITIAL_STATE.uploading,
        uploadProgress: action.showAddFilesDialog
          ? state.uploadProgress
          : INITIAL_STATE.uploadProgress,
        handler: action.handler ? action.handler : INITIAL_STATE.handler,
        propsDialog: action.propsDialog,
      };

    case Types.filesManager.SET_LOADING:
      return { ...state, loading: action.loading };

    case Types.filesManager.GET_LIST_TEMPLATES: {
      const { payload } = action;
      return {
        ...state,
        templates: payload,
      };
    }

    case Types.filesManager.SET_TEMPLATE: {
      const { payload } = action;
      return {
        ...state,
        template: {
          ...state.template,
          ...payload,
        },
      };
    }

    case Types.filesManager.SET_UPLOADING: {
      const { uploading } = action;
      return {
        ...state,
        uploading,
        uploadProgress: uploading ? state.uploadProgress : 0,
      };
    }

    case Types.filesManager.CLEAR_DOCUMENTS: {
      const { payload } = action;
      return { ...state, documents: payload };
    }

    case Types.filesManager.SET_UPLOAD_PROGRESS:
      return { ...state, uploadProgress: action.uploadProgress };

    case Types.filesManager.SET_DOCUMENTS:
      return { ...state, documents: action.documents };

    case Types.filesManager.SET_DOCUMENTS_TMP: {
      const { payload } = action;
      return { ...state, documentTmp: payload };
    }

    case Types.filesManager.SET_SELECTED_FILES:
      return { ...state, selectedDocuments: action.selectedDocuments };

    case Types.filesManager.SET_PREVIOUS_DOCUMENT: {
      const previousDocuments = action.documents;

      return { ...state, previousDocuments };
    }

    case Types.filesManager.SET_WORK_IN_PROGRESS: {
      const newState = action.payload;
      return {
        ...state,
        ...newState,
      };
    }

    case Types.filesManager.RESET: {
      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};
