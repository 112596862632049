import sendDocumentParserModule from './parsers/sendDocumentParser';
import templateParserModule from './parsers/templateParser';
import fieldsParserModule from './parsers/fieldsParser';

export const sendDocumentParser = sendDocumentParserModule;
export const templateParser = templateParserModule;
export const fieldsParser = fieldsParserModule;

const parserUtil = {
  fieldsParser,
  templateParser,
  sendDocumentParser,
};

export default parserUtil;
