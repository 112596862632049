import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import CustomDragLayer from './CustomDragLayer';

import documentService from '../../services/document';
import TemplateFields from './TemplateFields';
import Actions from '../../actions';
import { imaging } from '../../utils';

class TemplateSignLoader extends React.Component {
  state = {
    imageUrl: '',
    initialized: false,
    isVisible: false,
  }

  componentDidMount = async () => {
    const { documentUUID, id } = this.props.page;
    const pageData = await this.loadPageData(documentUUID, id);
    this.updateScaleRatio(pageData.image_url);
    this.props.setSignPageNumber(this.props.number);
    window.addEventListener('resize', () => this.updateScaleRatio(pageData.image_url));
  };

  componentDidUpdate = async (prevProps) => {
    const { IMAGE_WIDTH } = this.props.documentDimensions;
    if (prevProps.page.id !== this.props.page.id) {
      const { documentUUID, id } = this.props.page;
      await this.loadPageData(documentUUID, id);
    }
    /* the first time it loads and is visible */
    if (this.state.isVisible && !this.state.initialized) {
      const scaleRatio = this.pageImageRef.width / IMAGE_WIDTH;
      this.props.setSignScaleRatio(scaleRatio, IMAGE_WIDTH);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ initialized: true });
    }

    if (prevProps.imageWidth !== this.props.imageWidth) {
      const { documentUUID, id } = this.props.page;
      const pageData = await this.loadPageData(documentUUID, id);
      this.updateScaleRatio(pageData.image_url);
    }

    if (prevProps.number !== this.props.number) {
      this.props.setSignPageNumber(this.props.number);
    }
  }

  async updateScaleRatio(imageURL) {
    if (!(isNull(imageURL)) && this.pageImageRef && this.pageImageRef.width) {
      const imageSize = await imaging.getImageSizeAsync(imageURL);
      const scaleRatio = this.pageImageRef.width / imageSize.width;
      this.props.setSignScaleRatio(scaleRatio, this.props.imageWidth);
    }
  }

  loadPageData(documentUUID, id) {
    return new Promise(async (resolve) => {
      const { data: pageData } = await documentService.getPage(documentUUID, id);
      this.setState({ imageUrl: pageData.image_url });
      this.updateScaleRatio(pageData.image_url, this.props.imageWidth);
      resolve(pageData);
    });
  }

  pageImageRef = null;

  render() {
    const { imageUrl } = this.state;
    const {
      signs, number, scaleRatio, imageWidth, auth, standardFields,
    } = this.props;
    return (
      <PageContainer>
        { !isEmpty(imageUrl) &&
        <VisibilitySensor resizeCheck scrollCheck partialVisibility="top" onChange={this.onVisibilityChange}>
          {
                ({ isVisible }) => {
                  if (this.state.isVisible !== isVisible) this.setState({ isVisible });
                  return (
                    <PageImage
                      src={imageUrl}
                      alt="document image"
                      innerRef={(ref) => { this.pageImageRef = ref; }}
                    />);
                }
              }

        </VisibilitySensor>
        }
        <ContainerFields>
          <TemplateFields
            auth={auth}
            pageNumber={number}
            fields={signs}
            standardFields={standardFields}
            drag={this.props.drag}
            addTemplateField={this.props.createTemplateField}
            updateTemplateField={field => this.props.updateTemplateField(field)}
            removeTemplateField={field => this.props.removeTemplateField(field)}
            updateTemplatePress={drag => this.props.updateTemplatePress(drag)}
            scaleRatio={scaleRatio}
          />

          <CustomDragLayer
            pageFields={signs.fields}
            scaleRatio={scaleRatio}
            imageWidth={imageWidth}
            user={auth.user}
          />
        </ContainerFields>
      </PageContainer>
    );
  }
}

TemplateSignLoader.propTypes = {
  page: PropTypes.object.isRequired,
  signs: PropTypes.array.isRequired,
  drag: PropTypes.bool.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  updateTemplateField: PropTypes.func.isRequired,
  removeTemplateField: PropTypes.func.isRequired,
  updateTemplatePress: PropTypes.func.isRequired,
  createTemplateField: PropTypes.func.isRequired,
  setSignScaleRatio: PropTypes.func.isRequired,
  setSignPageNumber: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  auth: PropTypes.any.isRequired,
  standardFields: PropTypes.array,
  documentDimensions: PropTypes.object.isRequired,
};

TemplateSignLoader.defaultProps = {
  standardFields: [],
};

const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background: white;
  border: 1px solid gray;
`;

const PageImage = styled.img`
  width: 100%;
`;

const ContainerFields = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, ${props => (props.isOver ? '0.5' : '0')});
  transition: all 0.3s ease-in-out;
`;

const mapStateToProps = ({ signs: { fields, drag, scaleRatio }, auth }) =>
  ({
    signs: fields, drag, scaleRatio, auth,
  });

export default connect(mapStateToProps, Actions)(TemplateSignLoader);
