import React, { useEffect, useState } from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';

import OrganizationInviteModal from './OrganizationInviteModal';
import graphqlClient from '../../../config/graphql';
import organizationActions from '../../../actions/organization';
import authActions, { fetchCurrentUser } from '../../../actions/auth';
import { FETCH_INVITE_INFO, ACCEPT_INVITE } from './queries';

const connect = Component => (props) => {
  const {
    /* eslint-disable react/prop-types */
    user, setCurrentOrganization, currentOrganization,
    setOrganizationsValue, openOrganizationInviteModal,
    organizationInviteToken, setCurrentUser, selectedInvite,
    setUserOrganizations,
    /* eslint-enable */
  } = props;
  // eslint-disable-next-line react/prop-types
  const { email } = user;
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});

  const fetchInviteInformation = async () => {
    try {
      // eslint-disable-next-line react/prop-types
      if (selectedInvite.uuid) {
        // eslint-disable-next-line react/prop-types
        const { organization } = selectedInvite;

        // eslint-disable-next-line react/prop-types
        const owner = organization.users.nodes.find(u => u.role === 'OWNER') ||
          // eslint-disable-next-line react/prop-types
          organization.users.nodes[0];

        setValues({
          ...organization,
          // eslint-disable-next-line react/prop-types
          address: organization.address && organization.address.split('/-/').join(', '),
          ownerFullName: `${owner.firstName} ${owner.lastName}`,
        });

        // eslint-disable-next-line react/prop-types
        if (email !== selectedInvite.email) {
          setOrganizationsValue('openOrganizationInviteModal', false);
        }
      } else {
        const { data: { invite } } = await graphqlClient().query({
          query: FETCH_INVITE_INFO,
          fetchPolicy: 'network-only',
          variables: { token: organizationInviteToken },
        });
        const { organization } = invite;

        // eslint-disable-next-line react/prop-types
        const owner = organization.users.nodes.find(u => u.role === 'OWNER') || organization.users.nodes[0];

        setValues({
          ...organization,
          // eslint-disable-next-line react/prop-types
          address: organization.address.split('/-/').join(', '),
          ownerFullName: `${owner.firstName} ${owner.lastName}`,
        });

        if (email !== invite.email) {
          setOrganizationsValue('openOrganizationInviteModal', false);
        }
      }
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  };

  const getPendingInvites = (userWithInvites) => {
    try { return userWithInvites.pendingInvites.nodes; } catch (e) { return []; }
  };

  const acceptInvite = async () => {
    setLoading(true);
    try {
      const currentUserBeforeAccept = await fetchCurrentUser();
      const pendingInvitesBeforeAccept = getPendingInvites(currentUserBeforeAccept);
      // eslint-disable-next-line
      const variables = organizationInviteToken ? { inviteToken: organizationInviteToken } : { inviteUuid: selectedInvite.uuid };
      await graphqlClient().mutate({
        mutation: ACCEPT_INVITE,
        variables,
      });
      const currentUser = await fetchCurrentUser();
      const pendingInvitesAfterAccept = getPendingInvites(currentUser);
      const organizationWhoInvited = pendingInvitesBeforeAccept.filter(
        org => !pendingInvitesAfterAccept.includes(org));
      await setCurrentUser({ ...user, ...currentUser });
      if (selectedInvite.uuid) {
        const newOrg = selectedInvite.organization;
        await setUserOrganizations([...user.organizations,
          { history: [], ...newOrg, users: [...newOrg.users.nodes, { email: user.email }] }]);
      }
      if (user.pendingInvites.nodes.length > 0) {
        const newOrg = organizationWhoInvited[0].organization;
        await setUserOrganizations([...user.organizations,
          { history: [], ...newOrg, users: [...newOrg.users.nodes, { email: user.email }] }]);
      }
      await setCurrentOrganization(currentOrganization);
      // LOGIC TO REFETCH USER NOTIFICATIONS SHOULD BE ADDED HERE
      setOrganizationsValue('openOrganizationInviteModal', false);
      setOrganizationsValue('openOrganizationInviteSuccessModal', true);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };

  const loadInformation = async () => {
    setLoading(true);
    await fetchInviteInformation();
    setLoading(false);
  };

  const handleDecline = () => {
    setOrganizationsValue('openOrganizationInviteModal', false);
    setOrganizationsValue('openDeclineOrganizationInviteModal', true);
  };

  useEffect(() => {
    if (organizationInviteToken || selectedInvite.uuid) loadInformation();
  }, [organizationInviteToken]);

  return (
    <Component
      {...props}
      loading={loading}
      // eslint-disable-next-line react/prop-types
      open={(organizationInviteToken || selectedInvite.uuid) && openOrganizationInviteModal}
      values={values}
      onAccept={acceptInvite}
      onDecline={handleDecline}
    />
  );
};

export default RConnect(({ auth, organizationInvites, organizations }) => ({
  user: auth.user,
  openOrganizationInviteModal: organizationInvites.openOrganizationInviteModal,
  organizationInviteToken: organizationInvites.organizationInviteToken,
  currentOrganization: organizations.currentOrganization,
  organizationsList: organizations.list,
  selectedInvite: organizationInvites.selectedInvite,
}), {
  setCurrentOrganization: organizationActions.setCurrentOrganization,
  setOrganizationsValue: organizationActions.setOrganizationsValue,
  setCurrentUser: authActions.setCurrentUser,
  setUserOrganizations: authActions.setUserOrganizations,
})(connect(OrganizationInviteModal));
