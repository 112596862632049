const DelegateSignatureScreen = (props) => {
  try {
    const { match: { params: { uuid } } } = props;
    if (uuid) {
      props.history.push(`/signature?signature_request=${uuid}&delegate=true`);
    } else {
      props.history.push('/dashboard');
    }
  } catch (e) {
    props.history.push('/dashboard');
  }

  return null;
};

export default DelegateSignatureScreen;
