import gql from 'graphql-tag';

export const FETCH_OUT_FOR_SIGNATURE = gql`
  query getOutForSignature(
    $query: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sortColumn: UserSortColumnsEnum
    $sortOrder: SortOrderEnum
  ) {
    waitingForSignature(
      query: $query
      after: $after
      before: $before
      first: $first
      last: $last
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      nodes {
        uuid
        message
        subject
        previewImage {
          url
        }
        notes {
          nodes {
            uuid
            text
            createdAt
            updatedAt
          }
        }
        recipients {
          nodes {
            uuid
            email
            fullName
            pending
            signingOrder
          }
        }
        reminders {
          nodes {
            uuid
            message
            frequency
            options {
              date
              recipientUuids
            }
            createdAt
          }
        }
        redirectUrl
        user {
          email
        }
        activityLogs {
          nodes {
            event
            eventDate
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalPageCount
      totalCount
    }
  }
`;

export default {
  FETCH_OUT_FOR_SIGNATURE,
};
