import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';

import Messages from '../../constants/toastMessages';
import SignUpScreen from './SignUpScreen';
import authService from '../../services/auth';
import graphqlClient from '../../config/graphql';
import history from '../../config/history';
import { FETCH_INVITE } from './queries';

const fetchInvite = async (variables = {}) => {
  try {
    const { data: { invite } } = await graphqlClient().query({
      query: FETCH_INVITE,
      fetchPolicy: 'network-only',
      variables,
    });
    return invite;
  } catch (e) {
    return {};
  }
};

const connect = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const { location, auth: { user } } = props;
  const [loading, setLoading] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const { invite_token: inviteToken, plan, interval } = qs.parse(location.search);

  const handleSubmit = async (values) => {
    const {
      email, firstName, lastName, password, phone, streetAddress, state, city, zipCode,
    } = values;

    setLoading(true);
    try {
      await authService.signUp({
        email,
        first_name: firstName,
        last_name: lastName,
        password,
        phone,
        street_address: streetAddress,
        city,
        state,
        zip_code: zipCode,
        confirm_success_url: '',
        ...(inviteEmail ? { invite_token: inviteToken } : {}),
      });
      setLoading(false);
      toast.success(inviteToken ? Messages.auth.signup.successNoConfirm
        : Messages.auth.signup.success);
      history.push('/login');
    } catch (e) {
      setLoading(false);
      const message = e.response.data.errors.full_messages[0];
      if (message) toast.error(message);
      else toast.error(Messages.auth.signup.error);
    }
  };

  const setInviteInfo = async () => {
    setLoading(true);
    const invite = await fetchInvite({ inviteToken });
    localStorage.setItem('inviteType', invite.inviteType);
    localStorage.setItem('inviteToken', inviteToken);
    setInviteEmail(invite.email);
    setLoading(false);
  };

  useEffect(() => {
    if ((user || {}).uuid) {
      history.push('/dashboard');
      return;
    }

    localStorage.setItem('defaultPlan', plan || '');
    localStorage.setItem('defaultInterval', interval || '');

    if (inviteToken) setInviteInfo();
  }, []);

  return (
    <Component
      {...props}
      loading={loading}
      invitationEmail={inviteEmail}
      onSubmit={handleSubmit}
      onBack={() => history.goBack()}
      onHaveAnAccount={() => history.push('/login')}
    />
  );
};

export default RConnect(
  ({ auth }) => ({
    auth,
  }))(connect(SignUpScreen));
