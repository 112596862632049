import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlatButton } from 'material-ui';

import SignatureModal from '../../modals/SignatureModal';
import close from '../../../assets/images/cancel-icon-signature.svg';
import { Box } from '../../../components';

const renderSignature = ({
  // eslint-disable-next-line react/prop-types
  label, image, onEdit, onRemove,
}) => (
  <div className="d-flex flex-grow-1 flex-column m-3 align-items-center">
    {image && (
      <div
        className="d-flex flex-grow-1 align-items-center"
        style={{ margin: '0 0 10px 0' }}
      >
        <ImageWrapper><Img src={image} /></ImageWrapper>
        <Close src={close} onClick={onRemove} />
      </div>
    )}
    <FlatButton
      primary
      label={label}
      onClick={onEdit}
    />
  </div>
);

export const SignatureForm = ({
  initialValues: { defaultSignature, defaultInitials },
  onChange,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mode, setMode] = useState('');

  return (
    <StyledBox title="Signature">
      <div className="d-flex">
        {renderSignature({
          label: 'New Signature',
          image: defaultSignature,
          onRemove: () => onChange('defaultSignature', null),
          onEdit: () => {
            setMode('defaultSignature');
            setModalOpen(true);
          },
        })}
        {renderSignature({
          label: 'New Initials',
          image: defaultInitials,
          onRemove: () => onChange('defaultInitials', null),
          onEdit: () => {
            setMode('defaultInitials');
            setModalOpen(true);
          },
        })}
      </div>

      {modalOpen && (
        <SignatureModal
          onClose={() => setModalOpen(false)}
          onSave={(image) => {
            onChange(mode, image);
            setModalOpen(false);
          }}
          type={mode === 'defaultInitials' ? 'initials' : 'signature'}
        />
      )}
    </StyledBox>
  );
};

SignatureForm.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
};

SignatureForm.defaultProps = {
  onChange: () => {},
  initialValues: {},
};

const StyledBox = styled(Box)`
  margin-top: 2rem;
  border: 1px solid #EFEFEF;
  & > div {
    box-shadow: none !important;
    font-size: 20px;
    font-family: DIN-Medium !important;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 70px;
`;

const Img = styled.img`
  transform:scale(0.45);
`;

const Close = styled.img`
  cursor: pointer;
  padding: 5px;
`;

export default SignatureForm;
