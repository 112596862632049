import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import {
  Table, TableCell, TableBody, TableHead,
  TablePagination, TableRow, TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import { isNil, isArray, isDate, isEmpty, isPlainObject, isString } from 'lodash';

import OptionsPopover from '../OptionsPopover';
import SectionLoader from '../../components/SectionLoader';
import { colors } from '../../utils';

export const ROWS_PER_PAGE = 10;

const formatDate = date => moment(date, 'MMMM Do, YYYY');

const sortByAny = (sortDirection, a, b) => [
  { when: () => b < a, then: () => -1 },
  { when: () => b > a, then: () => 1 },
  { when: () => true, then: () => 0 },
].find(c => c.when()).then() * sortDirection;

const sortByDate = (sortDirection, a, b) => (formatDate(b) - formatDate(a)) * sortDirection;

const condsToPrint = [{
  isType: 'Date',
  when: d => isDate(d) || (isString(d) && moment(d, 'MMMM Do, YYYY').isValid()),
  render: d => d,
  getSortable: d => d,
  getText: d => d,
}, {
  isType: 'String',
  when: isString,
  render: s => s,
  getSortable: s => s,
  getText: s => s,
}, {
  isType: 'Array',
  when: isArray,
  render: array => array.reduce((z, a) => (
    <>{z}<div className="text-truncate">{condsToPrint.find(c => c.when(a)).render(a)}</div></>
  ), <></>),
  getSortable: ([head]) => condsToPrint.find(c => c.when(head)).getSortable(head || ''),
  getText: ([head, ...tail]) => {
    const getText = a => condsToPrint.find(c => c.when(a)).getText(a);
    return tail.reduce((z, a) => z.concat(', ', getText(a)), getText(head || ''));
  },
}, {
  isType: 'PlainObject',
  when: isPlainObject,
  render: (o) => {
    const { type, children, ...oProps } = o;
    const addTruncate = a => (a.className || '').concat(' text-truncate');
    if (isEmpty(type)) {
      const newProps = { ...oProps, className: addTruncate(oProps) };
      return <div {...newProps}>{condsToPrint.find(c => c.when(children)).render(children)}</div>;
    }
    const newO = { ...o, props: { ...o.props, className: addTruncate(o.props) } };
    return <>{newO}</>;
  },
  getSortable: (o) => {
    const value = isEmpty(o.type) ? o.children : o.props.children;
    return condsToPrint.find(c => c.when(value)).getSortable(value);
  },
  getText: (o) => {
    const value = isEmpty(o.type) ? o.children : o.props.children;
    return condsToPrint.find(c => c.when(value)).getText(value);
  },
}, {
  isType: 'Unknown',
  when: () => true,
  render: p => p,
  getSortable: p => (isNil(p) ? '' : String(p)),
  getText: () => '',
}];

const SortableTable = ({
  classes, columns, onClickRow, options, loading,
  withEmptyRows, optionColumn, stopPageResetting, isSelectedRow,
  rowsPerPage: propRowsPerPage, onChangePage: propOnChangePage,
  data: propData, page: propPage, totalCount: propTotal,
}) => {
  const [currentOrder, setCurrentOrder] = useState('asc');
  const [currentOrderBy, setCurrentOrderBy] = useState('');
  const [currentOrderType, setCurrentOrderType] = useState('text');
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = propRowsPerPage || ROWS_PER_PAGE;

  useEffect(() => {
    if (!stopPageResetting) setCurrentPage(0);
  }, [propData]);

  if (loading) {
    return (
      <SectionLoaderContainer>
        <SectionLoader />
      </SectionLoaderContainer>
    );
  }

  const handleOnChangePage = (e, nextPage) => setCurrentPage(nextPage);

  const handleRequestSort = (orderBy, orderType) => {
    setCurrentOrder(currentOrderBy === orderBy && currentOrder === 'desc' ? 'asc' : 'desc');
    setCurrentOrderBy(orderBy);
    setCurrentOrderType(orderType);
  };

  const renderColumn = ({
    // eslint-disable-next-line react/prop-types
    id, field, label, type, component,
  }) => {
    if (component) {
      return <TableCell key={id}>{component}</TableCell>;
    }
    return (
      <TableCell
        key={id}
        sortDirection={currentOrderBy === field && currentOrder}
      >
        <Tooltip
          title="Sort"
          placement="bottom-end"
          enterDelay={300}
        >
          <TableSortLabel
            active={currentOrderBy === field}
            direction={currentOrder}
            onClick={() => handleRequestSort(field, type)}
          >
            <div className="text-nowrap">{label}</div>
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    );
  };

  const renderDataCell = row => (_, index) => {
    const value = row[columns[index].field];

    return (
      <TableCell key={`${row.id}-${columns[index].id}`}>
        <Tooltip title={condsToPrint.find(c => c.when(value)).getText(value)}>
          <div className="text-truncate">
            {condsToPrint.find(c => c.when(value)).render(value)}
          </div>
        </Tooltip>
      </TableCell>
    );
  };

  const renderOptions = (row) => {
    const opts = options
      .filter(o => (o.filter ? o.filter(row, o) : true))
      .map(o => ({
        label: o.label,
        action: () => o.action(row.uuid, row, o),
      }));

    return (
      <TableCell key={`options-${row.id || row.uuid}`}>
        <OptionsPopover options={opts} />
      </TableCell>
    );
  };

  const renderDataRow = (row, index) => {
    const isSelected = isSelectedRow && isSelectedRow(row) ? 'TableSelectedRow' : '';
    const isClickable = onClickRow && !isSelected;

    return (
      <TableRow
        hover
        tabIndex={-1}
        key={`row-${row.id || row.uuid || index}`}
        className={`${classes.tableRow || ''} ${isSelected}`}
        onClick={() => (isClickable ? onClickRow(row) : null)}
        style={{
          fontFamily: 'Roboto',
          cursor: isClickable ? 'pointer' : 'auto',
        }}
      >
        {columns.map(renderDataCell(row))}
        {!isEmpty(options) && renderOptions(row)}
      </TableRow>
    );
  };

  const renderEmptyRows = data => [
    ...Array(Math.abs(rowsPerPage - data.length)),
  ].map(() => (
    <TableRow tabIndex={-1} className={classes.tableRow} />
  ));

  const Pagination = ({ page, totalCount, onChangePage }) => (
    <TablePagination
      id="TablePagination"
      component="div"
      backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      nextIconButtonProps={{ 'aria-label': 'Next Page' }}
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={() => { }}
    />
  );

  const sortingFn = currentOrderType === 'date' ? sortByDate : sortByAny;
  const sortDirection = currentOrder === 'desc' ? 1 : -1;
  const sortByField = (a, b) => {
    const propA = a[currentOrderBy];
    const propB = b[currentOrderBy];
    const valueA = condsToPrint.find(c => c.when(propA)).getSortable(propA) || '';
    const valueB = condsToPrint.find(c => c.when(propB)).getSortable(propB) || '';
    return sortingFn(sortDirection, valueA.toLowerCase(), valueB.toLowerCase());
  };
  const [data, page, totalCount, onChangePage] = isNil(propPage)
    ? [propData.slice(rowsPerPage * currentPage, rowsPerPage * (currentPage + 1)),
      currentPage, propData.length, handleOnChangePage]
    : [propData, propPage, propTotal, propOnChangePage];

  return (
    <TableWrapper>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableRowHead}>
            {columns.map(renderColumn)}
            {!isEmpty(options) && (optionColumn
              ? <TableCell>{optionColumn}</TableCell>
              : <TableCell />)}
          </TableRow>
        </TableHead>
        <TableBody className="bg-white">
          {data.sort(sortByField).map(renderDataRow)}
          {withEmptyRows && renderEmptyRows(data)}
        </TableBody>
      </Table>
      {totalCount > rowsPerPage && (
        <Pagination
          page={page}
          totalCount={totalCount}
          onChangePage={onChangePage}
        />
      )}
    </TableWrapper>
  );
};

SortableTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  classes: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
    component: PropTypes.node,
  })),
  optionColumn: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func,
  })),
  withEmptyRows: PropTypes.bool,
  stopPageResetting: PropTypes.bool,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  isSelectedRow: PropTypes.func,
  onClickRow: PropTypes.func,
};

SortableTable.defaultProps = {
  loading: false,
  data: [],
  classes: {},
  columns: [],
  optionColumn: null,
  options: [],
  withEmptyRows: false,
  stopPageResetting: false,
  page: null,
  totalCount: null,
  onChangePage: null,
  rowsPerPage: null,
  isSelectedRow: null,
  onClickRow: null,
};

const TableWrapper = styled.div`
  & thead th {
    padding: 4px 16px;
    max-width: 140px;
  }
  & tbody {
    & tr, tr:not(:last-child) td {
      border: none;
    }
    & td {
      padding: 4px 16px;
      max-width: 140px;
    }
  }
  & #TablePagination > div {
    height: 64px;
    span:nth-child(2), div:nth-child(3) {
      display: none;
    }
  }
  & .TableSelectedRow {
    background-color: rgb(3, 169, 244, .1) !important;
    &:hover {
      background-color: rgb(3, 169, 244, .14) !important;
    }
  }
  & span > svg {
    color: ${colors.blue} !important;
  }
`;

const SectionLoaderContainer = styled.div`
  height: 300px;
`;

export default SortableTable;
