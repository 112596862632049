import gql from 'graphql-tag';

export const FETCH_EVELOPES_SUMMARY = gql`
query EnvelopesSummaryReport(
  $startDate: IsoTime!,
  $endDate: IsoTime!,
  ){
  envelopesSummaryReport(
    startDate: $startDate,
    endDate: $endDate,
  ){
    averageCompletionTime
    envelopesCompleted
    envelopesDeclined
    envelopesPartiallySigned
    envelopesSent
    envelopesVoided
    totalEnvelopes
  }
}
`;

export const FETCH_EVELOPES_DETAILS = gql`
query EnvelopesDetailReport(
  $startDate: IsoTime!,
  $endDate: IsoTime!,
  ){
  envelopesDetailReport(
    startDate: $startDate,
    endDate: $endDate,
  ){
    nodes {
      sentOn
      completionTime
    }
  }
}
`;

export default {
  FETCH_EVELOPES_SUMMARY,
  FETCH_EVELOPES_DETAILS,
};
