import React, { Component } from 'react';
import EditIcon from 'material-ui/svg-icons/image/edit';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import validator from 'validator';
import { IconButton, TextField } from 'material-ui';
import { ActionDelete, ActionSwapVert } from 'material-ui/svg-icons';

import { colors } from '../../utils';

class Recipient extends Component {
  state = {
    editEmail: true,
    email: '',
  }

  validate = (recipient, recipients) => {
    const email = (recipient.email || '').toLowerCase();

    if (!validator.isEmail(email)) {
      return 'Please enter a valid email address';
    }

    if (recipients.filter(r => r.email.toLowerCase() === email).length > 1) {
      return 'You must not enter duplicated email addresses';
    }

    return null;
  }

  changeEditMode = () => this.setState(prevState => ({ editEmail: !prevState.editEmail }))

  renderRecipientActions(index) {
    const {
      removeSendDocumentRecipient,
      recipients, disable, disableActions,
    } = this.props;

    if (recipients[index] && removeSendDocumentRecipient && !disable && !disableActions) {
      return (
        <RecipientButton onClick={() => removeSendDocumentRecipient(index)}>
          <ActionDelete color={colors.darkGray} />
        </RecipientButton>
      );
    }
    return null;
  }

  render() {
    const {
      index: i, recipient, recipients,
      shouldValidate, disableSorting, disable, changeRecipientValue,
      completeInOrder,
    } = this.props;
    const { editEmail, email } = this.state;

    const changeRecipient = (e) => {
      const newValue = (e.target.value || '').trim();
      this.setState({ email: newValue });
      changeRecipientValue(i, newValue);
    };

    return (
      <RecipientContainer>
        <RecipientLine className="mb-1" completeInOrder={completeInOrder}>
          {completeInOrder && (
            <>
              <RecipientSwap className="swap">
                {!disableSorting && <ActionSwapVert color={colors.textLight} />}
              </RecipientSwap>
              <RecipientIndex>{i + 1}</RecipientIndex>
            </>
          )}
          {!disable && (
            <RecipientEditButton onClick={this.changeEditMode}>
              <EditIcon color={colors.darkGray} />
            </RecipientEditButton>
          )}
          {!disable && editEmail
            ? (
              <RecipientEmailField
                type="email"
                hintText="Enter recipient email"
                value={recipient.email || email}
                onChange={changeRecipient}
                disabled={disable || !editEmail}
                errorText={(recipient.email.length > 0 || shouldValidate)
                  && this.validate(recipient, recipients)}
              />
            ) : (
              <RecipientEmail>{recipient.email || 'Enter recipient email'}</RecipientEmail>
            )}
          {recipient.role && (
            <RecipientRoleField>
              Role: {recipient.role}
            </RecipientRoleField>
          )}
          {this.renderRecipientActions(i)}
        </RecipientLine>
      </RecipientContainer>
    );
  }
}

Recipient.propTypes = {
  recipients: PropTypes.array,
  recipient: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  changeRecipientValue: PropTypes.func,
  removeSendDocumentRecipient: PropTypes.func,
  shouldValidate: PropTypes.bool,
  disableSorting: PropTypes.bool,
  disableActions: PropTypes.bool,
  disable: PropTypes.bool,
  completeInOrder: PropTypes.bool,
};

Recipient.defaultProps = {
  recipients: [],
  changeRecipientValue: () => {},
  removeSendDocumentRecipient: () => {},
  shouldValidate: false,
  disableSorting: false,
  disableActions: false,
  disable: false,
  completeInOrder: false,
};

const RecipientLine = styled.div`
  height: 3.625rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: ${p => (p.completeInOrder ? 'grab' : 'auto')};
`;

const RecipientIndex = styled.div`
  width: 2.5rem;
  display: flex;
  align-items: center;
`;

const RecipientSwap = styled.div`
  width: 2.5rem;
  display: flex;
  align-items: center;
`;

const RecipientEmailField = styled(TextField)``;

const RecipientButton = styled(IconButton)`
  margin-left: auto!important;
`;

const RecipientContainer = styled.div`
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #F6F6F6;
`;

const RecipientRoleField = styled.div`
  margin-top: 5px;
  margin-left: 10px;
`;

const RecipientEditButton = styled(IconButton)``;

const RecipientEmail = styled.div`
  width: 256px;
`;

export default Recipient;
