import scaling from './scaling';

const getFieldsByType = (fieldType, pageFields) => {
  if (pageFields && pageFields.length > 0 && fieldType) {
    return pageFields.filter(field => field.fieldType === fieldType);
  }
  return [];
};

export const getFirstFieldName = (newPageField, allFields) => {
  const field = allFields.find(v => v.id === newPageField.fieldType);
  return field ? field.name : '';
};

export const getNFieldName = (newPageField, allFields, fieldsOfSameType) => `${getFirstFieldName(newPageField, allFields)} ${fieldsOfSameType + 1}`;


/**
 * @param {Object} newPageField The new page field we want to find the name for
 * @param {Array} allFields All the fields (Standard + Customs)
 * @param {Array} pageFields All the page fields in the current template
 * @return {String} name that should be displayed for the page field
 */
export const getFieldName = (pageField, allFields, pageFields, isNew) => {
  const currentPageFields = pageFields.filter(p => p.pageId === pageField.pageId);
  const fieldsOfSameType = getFieldsByType(
    pageField.fieldType,
    currentPageFields,
  );
  if (fieldsOfSameType.length === 0 ||
    fieldsOfSameType.findIndex(field => field.id === pageField.id) === 0) {
    return getFirstFieldName(pageField, allFields);
  }
  return getNFieldName(
    pageField, allFields,
    isNew ? fieldsOfSameType.length :
      fieldsOfSameType.findIndex(field => field.id === pageField.id),
  );
};

/**
 * @param {field} React dnd current field values
 * @param {Array} allFields All the fields (Standard + Customs)
 * @param {Array} pageFields All the page fields in the current template
 * @return {Object} Field to be added to the store with all the data structure required
 */
export const getFieldToAdd = (field, allFields, pageFields) => {
  const fieldToAdd = {
    ...field,
    name: getFieldName(field, allFields, pageFields, true),
    width: 200,
    height: 25,
  };

  if (fieldToAdd.field_type === 'checkbox') {
    fieldToAdd.height = 20;
    fieldToAdd.width = 30;
  }
  if (fieldToAdd.field_type === 'select') {
    fieldToAdd.height = 25;
  }
  if (fieldToAdd.field_type === 'radio') {
    fieldToAdd.height = 20;
    fieldToAdd.width = 30;
    fieldToAdd.radio_button_group_id = 1;
  }
  if (fieldToAdd.field_type === 'file') {
    fieldToAdd.height = 84;
    fieldToAdd.width = 72;
  }
  if (fieldToAdd.name.includes('Participation Stamp')) {
    fieldToAdd.height = 90;
    fieldToAdd.width = 180;
  }
  if (fieldToAdd.name.includes('Signature') || fieldToAdd.name.includes('Initial')) {
    fieldToAdd.height = 40;
    fieldToAdd.width = 150;
  }
  return fieldToAdd;
};

export const getScaledFields = (fields, scaleRatio) => {
  if (!fields || !scaleRatio || fields.length === 0) {
    return fields;
  }
  return fields.map(field => scaling.scalePageField(field, scaleRatio));
};
