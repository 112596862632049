import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import { DragItemTypes } from '../../constants';
import TemplatePreviewPageField from '../signs/TemplatePreviewPageField';
import { getInitialFieldDimensions } from '../../constants/fsFieldsInitialsDimensions';

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '2rem',
  height: '100%',
};

function getItemStyles(props) {
  const { currentOffset } = props;
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

class CustomDragLayer extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    isDragging: PropTypes.bool.isRequired,
    itemType: PropTypes.object.isRequired,
    scaleRatio: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
  }

  renderItem = (item, itemType, scaleRatio) => {
    const { user } = this.props;
    const type = `default_${item.type}`;
    const content = user[type] || '';

    const field = getInitialFieldDimensions({
      type: item.type,
      content,
    });

    switch (itemType) {
      case DragItemTypes.FIELDS_PANEL_OPTION: {
        return (
          TemplatePreviewPageField.renderField(
            field,
            scaleRatio,
          ));
      }
      default:
        return () => {};
    }
  }

  render() {
    const {
      item, isDragging, itemType, scaleRatio,
    } = this.props;
    if (!isDragging) { return false; }

    return (
      <div style={layerStyles}>
        <div style={getItemStyles(this.props)}>
          {this.renderItem(item, itemType, scaleRatio)}
        </div>
      </div>
    );
  }
}

export default (DragLayer(collect)(CustomDragLayer));
