import React from 'react';

import ReportTable from '../../ReportTable';

const columns = [{
  id: 'subject', label: 'Subject', field: 'subject',
}, {
  id: 'status', label: 'Status', field: 'state', style: 'startCase',
}, {
  id: 'senderName', label: 'Sender Name', field: 'senderName',
}, {
  id: 'recipients', label: 'Recipients', field: 'recipients',
}, {
  id: 'sentOn', label: 'Sent On', field: 'sentOn',
}, {
  id: 'lastActivity', label: 'Last Activity', field: 'lastActivity',
}, {
  id: 'completedOn', label: 'Completed On', field: 'completedOn',
}, {
  id: 'completionTime', label: 'Completion Time', field: 'completionTime',
}];

export const EnvelopeDetailTable = props => (
  <ReportTable {...props} columns={columns} />
);

export default EnvelopeDetailTable;
