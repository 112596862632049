import gql from 'graphql-tag';

export const FETCH_PLANS = gql`
  query Plans{
    plans{
      nodes {
        uuid
        amount
        interval
        currency
        nickname
        planType
        organizationsLimit
      }
    }
  }
`;

export default {
  FETCH_PLANS,
};
