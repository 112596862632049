import React from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { colors } from './../utils';
import TabBarScrollButtons from './TabBarScrollButtons';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    fontFamily: 'DIN-Medium',
  },
  tabsRoot: {
    borderBottom: 'none',
    backgroundColor: 'white',
  },
  tabsIndicator: {
    backgroundColor: colors.blue,
  },
  tabRoot: {
    textTransform: 'uppercase',
    color: colors.blue,
    minWidth: 80,
  },
  scrollButtons: {
    position: 'absolute',
  },
  tabSelected: {
    border: 'none',
    outline: 'none !important',
    '&:active': {
      outline: 'none !important',
    },
    '&:focus': {
      outline: 'none !important',
    },
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
});
class TabBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultTab,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.setState({ value });
    this.props.tabChanged(value);
  }

  renderTabs() {
    const { classes } = this.props;
    return this.props.tabs.map((v) => {
      if (v.icon) {
        return (
          <TabContent>
            <Tab
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              key={v.value}
              value={v.value}
              label={v.label}
              onClick={v.onClick}
              disabled={!!v.disabled}
              disableRipple
              disableTouchRipple
            />
            <ProContent onClick={v.onClickIcon} image={v.icon} />
          </TabContent>
        );
      }
      return (
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          key={v.value}
          value={v.value}
          label={v.label}
          onClick={v.onClick}
          disabled={!!v.disabled}
          disableRipple
          disableTouchRipple
        />
      );
    });
  }

  render() {
    const { classes, withoutShadow } = this.props;
    return (
      <Wrapper withoutShadow={withoutShadow}>
        <Tabs
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          value={this.props.defaultTab ? this.props.defaultTab : this.state.value}
          indicatorColor="primary"
          scrollable={this.props.scrollable}
          scrollButtons="auto"
          onChange={(e, v) => this.handleChange(v)}
          fullWidth={this.props.fullWidth}
          ScrollButtonComponent={TabBarScrollButtons}
        >
          {this.renderTabs()}
        </Tabs>
      </Wrapper>
    );
  }
}

TabBar.propTypes = {
  defaultTab: PropTypes.any.isRequired,
  tabs: PropTypes.array.isRequired,
  tabChanged: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  scrollable: PropTypes.bool,
  withoutShadow: PropTypes.bool,
};

TabBar.defaultProps = {
  fullWidth: false,
  scrollable: false,
  withoutShadow: false,
};

const ProContent = styled.div`
  width: 1.3rem;
  height: 100%;
  cursor: pointer;
  right: -7rem;
  color: ${colors.blue};
  position: relative;
  bottom: 3rem;
  background-image: url(${v => v.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0.8rem;
  font-weight: 600;
`;

const TabContent = styled.div`
  
`;

const Wrapper = styled.div`
  position: relative;
  box-shadow: ${props => !props.withoutShadow && 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;'}
`;

export default withStyles(styles)(TabBar);
