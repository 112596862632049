import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import { Checkbox } from 'material-ui';
import {
  scaleValueWithConstant,
} from '../../../utils/scaling';

const CheckboxField = ({
  field, scaleRatio, imageWidth, onClick, recipients,
  mode, width, height, onChange, onSaved,
}) => {
  const currentRecipient = recipients && recipients.find(r => r.uuid === field.recipientUuid);
  const renderSignCheckboxComponent = () => (
    <FieldContainer
      onClick={() => {
      onSaved(!field.value);
      onChange(!field.value);
    }}
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
    >
      <StyledCheckbox
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
        checked={field.value}
        width={width}
        height={height}
      />
    </FieldContainer>
  );

  return mode === 'sign' ? renderSignCheckboxComponent() : (
    <ResizableBox
      width={field.width}
      height={field.height}
      minConstraints={[field.width, field.height]}
      maxConstraints={[field.width, field.height]}
      className="react-resizable-handle-hidden"
      onClick={onClick}
    >
      <div style={{ display: 'flex' }}>
        <IndicatorContainer
          recipientColor={currentRecipient && currentRecipient.color}
          scaleRatio={scaleRatio}
          imageWidth={imageWidth}
          width={field.width}
          height={field.height}
        >
          <div className="indicator" />
        </IndicatorContainer>
        <StyledCheckbox
          scaleRatio={scaleRatio}
          imageWidth={imageWidth}
          checked={field.checked}
          width={field.width}
          height={field.height}
        />
      </div>
    </ResizableBox>
  );
};

CheckboxField.propTypes = {
  field: PropTypes.any.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  onSaved: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

CheckboxField.defaultProps = {
  mode: 'edit',
  width: 0,
  height: 0,
  onChange: () => {},
  onSaved: () => {},
};

const FieldContainer = styled.div`
   & input {
     background-color: #B3E1F5;
     opacity: 0.9 !important;
     border: 1px solid #3190C0;
     display: flex;
     padding-left: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(6, scaleRatio, imageWidth)}px;
     align-items: flex-start;
     ::placeholder {  
       font-size: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
       color: #236BA0;
       font-weight: 500;
    }
   }
`;

const StyledCheckbox = styled(Checkbox)`
  height: 100%;
  width: 100%;
  & div{
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  & svg {
    height: ${({ imageWidth, height }) => scaleValueWithConstant(height, 1, imageWidth)}px !important;
    width: auto !important;
    fill: #236BA0 !important;
    background-color: #B1E1F6;
  }

  input{
    display: none;
  }
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(12, scaleRatio, imageWidth)}px;
  height: 100%;
  right: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(14, scaleRatio, imageWidth)}px;
  background-color: #B1E1F6;

  & > .indicator {
    border-radius: 50%;
    width: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    height: ${({ scaleRatio, imageWidth }) => scaleValueWithConstant(8, scaleRatio, imageWidth)}px;
    background-color: ${props => props.recipientColor};
    position: relative;
    z-index: 2;
  }
`;

export default CheckboxField;
