import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { RaisedButton, FlatButton } from 'material-ui';
import { PageContainer, PageHeader, PageContent, PageFooter } from '../../components/pages';
import { DocumentThumbnail } from '../../components';
import UploadAndArchiveForm from '../../components/forms/UploadAndArchiveForm';
import SendDocumentModal from '../../components/modals/SendDocumentModal';
import Messages from '../../constants/toastMessages';
import connect from './connect';
import UploadingDocumentModal from '../../components/modals/UploadingDocumentModal';
import { colors } from '../../utils';

class UploadAndArchiveScreen extends React.Component {
  componentDidMount = () => {
    const {
      history,
      clearDocuments,
      setUpgradeModal,
      auth: {
        user: {
          planUsage: {
            archivedDocumentsInUse, archivedDocumentsLimit,
          },
        },
      },
    } = this.props;
    this.resetScreen();
    clearDocuments();

    if (Number.isInteger(archivedDocumentsLimit)
    && archivedDocumentsInUse + 1 > archivedDocumentsLimit) {
      setUpgradeModal(true);
      history.push('/dashboard');
    }
  };

  componentWillUnmount =() => {
    this.resetScreen();
  }

  getSaveDisabledConditions() {
    switch (true) {
      case this.props.uploadAndArchiveScreen.savingArchive:
        return true;
      case !(this.props.formValid):
        return true;
      case this.props.uploadAndArchiveScreen.document == null:
        return true;
      default:
        return false;
    }
  }

  handleSave = async () => {
    try {
      const { saveArchiveDocument, disableOrganizationChange, setMessage } = this.props;
      await saveArchiveDocument();
      disableOrganizationChange();
      setMessage('');
    } catch (e) {
      toast.error(Messages.documents.archive.error);
    }
  };

  handleModalCancel = () => {
    this.props.setSendDocumentModal(false);
  };

  handleSubmit = (values) => {
    const { sendArchivedDocument } = this.props;
    sendArchivedDocument(values);
  }

  resetScreen = () => {
    this.props.resetUploadAndArchiveScreen();
    this.props.enableOrganizationChange();
  };

  renderUploader() {
    const {
      uploadAndArchiveScreen: { document },
      setArchiveDocument,
      setAddFilesDialog,
    } = this.props;
    return (
      <DocumentThumbnail
        document={document}
        isFile
        onRemovePress={() => { setArchiveDocument(null); }}
        onUploadPress={() => {
          setAddFilesDialog(true, files => setArchiveDocument(files[0]), 'archiveDocuments',
          { title: 'Select File', dropTitle: 'Drop file here', buttonLabel: 'Select File' });
        }}
        isLocal
        buttonLabel="Select File"
      />
    );
  }

  renderForm = () => <UploadAndArchiveForm values={this.props.uploadAndArchiveScreen.document} />;

  renderSuccess = () => (
    <React.Fragment>
      <Wrapper style={{ display: 'flex' }}>
        <Wrapper>
          <DocumentPreview>
            <Preview src={this.props.uploadAndArchiveScreen.document.preview_url} />
            <DocumentName>{this.props.uploadAndArchiveScreen.document.file_name}</DocumentName>
          </DocumentPreview>
        </Wrapper>
        <MessageSection>
          <TitleSection>
            <Congratulations>Congratulations!</Congratulations>
            <p>The document has been added to your archive</p>
          </TitleSection>
          <div style={{ marginBottom: '20px' }}>
            <RaisedButton
              label="SEND A COPY"
              onClick={() => this.props.setSendDocumentModal(true)}
              style={{ marginRight: '20px' }}
              primary
            />
            <FlatButton
              label="STORE ANOTHER DOCUMENT"
              onClick={this.resetScreen}
              primary
            />
          </div>
        </MessageSection>
      </Wrapper>
    </React.Fragment>
  );

  renderContent = () => {
    if (this.props.uploadAndArchiveScreen.saved) {
      return this.renderSuccess();
    }
    return (
      <React.Fragment>
        {this.renderUploader()}
        {this.renderForm()}
      </React.Fragment>
    );
  };

  render() {
    const {
      submitSendArchived,
      history,
      uploadAndArchiveScreen: {
        modalOpen,
        sending,
        saved,
        savingArchive,
        document,
      },
    } = this.props;

    const pageFooterProps = saved ? {
      customActions: [
        () => (
          <FlatButton
            labelStyle={{ color: colors.blue }}
            label="Document Manager"
            onClick={() => history.push('/documents-manager')}
          />
        ),
      ],
    } : {
      onSavePress: this.handleSave,
      saveDisabled: this.getSaveDisabledConditions(),
      saveButtonLabel: savingArchive ? 'Storing...' : 'Upload & Store',
    };

    return (
      <PageContainer>
        <PageHeader title="Upload & Store" />
        <PageContent>
          {this.renderContent()}
        </PageContent>
        <PageFooter
          {...pageFooterProps}
        />
        <UploadingDocumentModal isOpen={savingArchive} />
        <SendDocumentModal
          isOpen={modalOpen}
          sending={sending}
          selectedDocument={document ? document.document : undefined}
          handleModal={submitSendArchived}
          handleCancel={this.handleModalCancel}
          handleSubmit={this.handleSubmit}
        />
      </PageContainer>
    );
  }
}

UploadAndArchiveScreen.propTypes = {
  setAddFilesDialog: PropTypes.func.isRequired,
  saveArchiveDocument: PropTypes.func.isRequired,
  uploadAndArchiveScreen: PropTypes.object.isRequired,
  resetUploadAndArchiveScreen: PropTypes.func.isRequired,
  submitSendArchived: PropTypes.func.isRequired,
  setSendDocumentModal: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  clearDocuments: PropTypes.func.isRequired,
  formValid: PropTypes.bool.isRequired,
  setArchiveDocument: PropTypes.func.isRequired,
  sendArchivedDocument: PropTypes.func.isRequired,
  disableOrganizationChange: PropTypes.func.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setUpgradeModal: PropTypes.func.isRequired,
};

const DocumentPreview = styled.div`
  width: 175px;
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid gray;
  height: 240px;
`;

const Preview = styled.img`
  height: 170px;
  width: 132px;
`;

const DocumentName = styled.div`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  width: 100%;
`;

const MessageSection = styled.div`
  margin-left: 60px;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 50px;
`;

const Wrapper = styled.div``;

const Congratulations = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export default connect(UploadAndArchiveScreen);
