import gql from 'graphql-tag';

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole(
    $userUuid: ID!,
    $role: ChangeableRoleEnum!,
    ){
      updateUserRole(
        userUuid: $userUuid,
        role: $role,
      ){
        success
      }
  }
`;

export default {
  UPDATE_USER_ROLE,
};
