import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect as ReduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleSendReportModal, setReportVariables } from '../../actions/analyticsScreen';
import UserActivityReportScreen from './UserActivityReportScreen';
import graphqlClient from '../../config/graphql';
import { FETCH_USER_ACTIVITY_REPORT } from './queries';
import { isRoleAdmin, isRoleOwner } from '../../utils/role';
import { parseRequest } from '../AnalyticsScreen/connect';
import { CREATE_REPORT_SCHEDULE } from '../AnalyticsScreen/queries';

const connect = Component => (props) => {
  const { currentOrganization } = props; // eslint-disable-line
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchUserActivities = async (variables) => {
    setLoading(true);
    try {
      // eslint-disable-next-line react/prop-types
      const { setCurrentReportVariables } = props;
      await setCurrentReportVariables({ ...variables, reportType: 'USER_ACTIVITY_REPORT' });
      const { data: { userActivityReport: { nodes } } } = await graphqlClient(
        // eslint-disable-next-line react/prop-types
        currentOrganization && currentOrganization.subdomain,
      ).query({
        query: FETCH_USER_ACTIVITY_REPORT,
        fetchPolicy: 'network-only',
        variables,
      });
      setData(nodes);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setData([]);
      setLoading(false);
      throw (e);
    }
  };

  useEffect(() => {
    const startDate = moment().subtract(1, 'months').toISOString();
    const endDate = moment().toISOString();
    fetchUserActivities({ startDate, endDate });
    // eslint-disable-next-line no-shadow
    const { currentOrganization, auth: { user }, history } = props;
    const userInfo = currentOrganization &&
        currentOrganization.users.find(o => o.uuid === user.uuid);
    if (!userInfo || (!isRoleAdmin(userInfo.role) && !isRoleOwner(userInfo.role))) {
      history.push('/dashboard');
    }
  }, [(currentOrganization || {}).id]);


  const createReportSchedule = async (payload) => {
    try {
      const variables = parseRequest(payload);
      await graphqlClient(
        currentOrganization && currentOrganization.subdomain,
      ).mutate({ mutation: CREATE_REPORT_SCHEDULE, variables });
      toast.success('Your report was successfuly scheduled.');
    } catch (e) {
      toast.error(e.message);
      throw (e);
    }
  };

  return (
    <Component
      {...props}
      data={data}
      loading={loading}
      onFilterChange={fetchUserActivities}
      onSchedule={createReportSchedule}
    />
  );
};

const mapStateToProps = ({ organizations, auth, analyticsScreen }) => ({
  currentOrganization: organizations.currentOrganization,
  organizations,
  auth,
  reportVariables: analyticsScreen.reportVariables,
});

const mapDispatchToProps = () => ({
  setOpenReportModal: toggleSendReportModal,
  setCurrentReportVariables: setReportVariables,
});

export default ReduxConnect(
  mapStateToProps, mapDispatchToProps())(connect(UserActivityReportScreen));
