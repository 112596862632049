import moment from 'moment';
import { CREATE_REMINDER } from './mutations/sendDocument';
import graphqlClient from '../config/graphql';

const reminderParser = (uuid, recipientUuids, data) => {
  const frequency = data.reminderFrequencyType === 'byDate' ? 'DATE' : data.reminderFrequency;
  const parsedData = {
    signatureRequestUuid: uuid,
    sendToMe: data.reminderSendToMe,
    message: data.reminderMessage,
    frequency,
    options: {
      recipientUuids,
    },
  };
  if (data.reminderFrequencyType === 'byDate' && parsedData.options) parsedData.options.date = moment(data.reminderDate).toISOString();
  return parsedData;
};

// eslint-disable-next-line
export const createReminder = async (currentOrganization, signatureRequestUuid, recipientsUuids, variables) => {
  try {
    await graphqlClient(currentOrganization &&
      currentOrganization.subdomain).mutate({
      mutation: CREATE_REMINDER,
      variables: reminderParser(signatureRequestUuid, recipientsUuids, variables),
    });
  } catch (e) {
    throw (e);
  }
};

export default {
  createReminder,
};
