import React from 'react';
import { connect as RConnect } from 'react-redux';
import { setOrganizationsValue } from '../../actions/organization';
import NotificationsScreen from './NotificationsScreen';

const connect = Component => (props) => {
  const { currentOrganization, auth: { user } } = props; // eslint-disable-line

  // eslint-disable-next-line react/prop-types
  return <Component {...props} invites={user.pendingInvites ? user.pendingInvites.nodes : []} />;
};

const mapStateToProps = state => ({
  auth: state.auth,
  currentOrganization: state.organizations.currentOrganization,
});

const mapDispatchToProps = {
  setOrganizationsValue,
};

export default RConnect(mapStateToProps, mapDispatchToProps)(connect(NotificationsScreen));
