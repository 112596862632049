import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Paper, RaisedButton, FlatButton } from 'material-ui';
import IconButton from 'material-ui/IconButton';
import NavigateNextIcon from 'material-ui/svg-icons/image/navigate-next';
import connect from './connect';
import { animations, getUserOrganizations, colors } from '../../utils';
import history from '../../config/history';

class OrganizationLoginScreen extends React.Component {
  componentDidMount() {
    const { auth: { user }, organizations: { list } } = this.props;
    if (getUserOrganizations(user, list).length) return;
    history.push(user.signInCount === 1 ? '/subscription' : '/dashboard');
  }

  handlePressOrganization = (organization) => {
    if ((organization || {}).uuid) {
      this.props.setCurrentOrganization(organization);
    }
    history.push('/dashboard');
  }

  renderOrganizationOption = (organization, isLastIndex) => (
    <OrganizationOptionContent
      key={organization.name[0]}
      withBottomBorder={isLastIndex}
      onClick={() => this.handlePressOrganization(organization)}
      className="col-12 row m-0 p-0"
    >
      <OrganizationImage className="col-2 text-center">
        <div><h2>{organization.name[0].toUpperCase()}</h2></div>
      </OrganizationImage>
      <OrganizationName className="col-7 text-left pl-0">
        {organization.name.length < 30 ? organization.name : `${organization.name.substring(0, 27)}...`}
      </OrganizationName>
      <OrganizationName className="col-3 text-right">
        <IconButton>
          <NavigateNextIcon />
        </IconButton>
      </OrganizationName>
    </OrganizationOptionContent>
  )

  render() {
    const { auth: { user }, organizations: { list } } = this.props;
    const userOrganizations = getUserOrganizations(user, list);

    return (
      <Container className="d-flex justify-content-center align-items-center">
        <LoginBox zDepth={3} className="p-4 text-center row">
          <div className="col-12 mt-4 mb-1 text-left">
            <h3 className="d-flex" style={{ marginBottom: '0px', fontWeight: 'normal' }}>
              Hi,&nbsp;<div className="text-truncate" style={{ maxWidth: 200 }}>{user.firstName}</div>!
            </h3>
          </div>
          <div className="col-12 mb-3 text-left">
            <h5 style={{ fontWeight: 'normal' }}>Start with an organization</h5>
          </div>
          <OrganizationsContainer className="col-12">
            {userOrganizations.map((organization, index) =>
              this.renderOrganizationOption(organization, index === userOrganizations.length - 1))}
          </OrganizationsContainer>
          <div className="col-12">
            <div className="row mb-3">
              <div className="col-6 text-left">
                <FlatButton
                  label="Not you?"
                  primary
                  onClick={() => this.props.logout()}
                  labelStyle={{ padding: 0, textTransform: 'none' }}
                />
              </div>
              <div className="col-6 text-right">
                <RaisedButton primary label="SKIP" onClick={() => this.handlePressOrganization()} />
              </div>
            </div>
          </div>
        </LoginBox>
      </Container>
    );
  }
}

OrganizationLoginScreen.propTypes = {
  organizations: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setCurrentOrganization: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;

const OrganizationOptionContent = styled.div`
  border-top: 2px solid ${colors.lightGray};
  border-bottom: ${props => props.withBottomBorder && `2px solid ${colors.lightGray}`};
  background-color: #F6F6F6;
  height: 3rem;
  cursor: pointer;
`;

const OrganizationsContainer = styled.div`
  max-height: 10rem;
  overflow-y: auto;
  margin-bottom: 2rem;
`;

const OrganizationImage = styled.div`
  height: 100%;
  & > div {
    border-radius: 50%;
    background-color: #D8D8D8;
    position: relative;
    top: 0.4rem;
    height: 2.1rem;
    width: 2.2rem;
    color: white;
    & > h2{
      position: relative;
      top: 0.5rem;
      font-size: 1rem;
      font-weight: 400;
    }
  }
`;


const OrganizationName = styled.div`
    position: relative;
    top: 0.7rem;
    font-size: 1rem;
    color: ${colors.darkGray};

    & svg {
      position: relative;
      bottom: 0.8rem;
      fill: ${colors.darkGray} !important;
    }
`;

export const LoginBox = styled(Paper)`
  width: 640px;
  height: auto;
  min-height: 384px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  & h3 {
    font-size: 1.4rem;
  }

  & h5 {
    margin-top: 0.8rem;
    font-size: 1rem;
  }

`;

export const Logo = styled.img`
    width: 200px;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 0.375rem;
    align-self: center;
`;

export default connect(OrganizationLoginScreen);
