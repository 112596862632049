import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'lodash';

import SearchInput from '../SearchInput';
import SortableTable from '../SortableTable';

const userColumns = [{
  id: 'user-name',
  label: 'Name',
  field: 'name',
  type: 'text',
}, {
  id: 'user-organization',
  label: 'Organization',
  field: 'organization',
  type: 'text',
}, {
  id: 'user-role',
  label: 'Role',
  field: 'role',
  type: 'text',
}, {
  id: 'user-status',
  label: 'Status',
  field: 'status',
  type: 'text',
}, {
  id: 'user-last-login',
  label: 'Last Login',
  field: 'lastLogin',
  type: 'date',
}];

export const PlatformUsersList = ({ onSearch, ...props }) => (
  <>
    <SearchInput placeholder="Search" searchTermChanged={debounce(onSearch, 350)} />
    <div className="mt-3 w-100">
      <Paper square>
        <TotalResults>
          Total Results: {props.loading ? '...' : props.totalCount}
        </TotalResults>
        <SortableTable
          {...props}
          classes={{ tableRowHead: 'bg-white' }}
          columns={userColumns}
        />
      </Paper>
    </div>
  </>
);

PlatformUsersList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.array,
    organization: PropTypes.array,
    role: PropTypes.array,
    lastLogin: PropTypes.array,
  })).isRequired,
  loading: PropTypes.bool,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  onChangePage: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
};

PlatformUsersList.defaultProps = {
  loading: false,
  page: null,
  totalCount: null,
  onChangePage: null,
};

const TotalResults = styled.div`
  text-align: right;
  padding: 20px 24px;
  font-family: Roboto;
  font-size: 15px;
  color: rgba(0,0,0,0.87);
`;

export default PlatformUsersList;
