import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CustomDragLayer from './CustomDragLayer';
import CustomFieldModal from '../modals/CustomFieldModal/CustomFieldModal';
import DocumentsPageLoader from './DocumentsPageLoader';
import editorFields from '../../config/editorFields';
import { FieldsPanel, DocumentPageLoader } from '.';
import { PageContainer, PageHeader, PageContent } from '../pages';
import { getTabs } from '../../utils/templateEditor';

const DocumentsEditor = ({
  changeCustomFormFieldValue, loading, documents, pageFields, focusedPageField,
  canDragFields, onAddPageField, onChangePageField, onClickPageField, scaleRatio,
  onClickFieldsLayer, onResizeHandlePress, onRemovePageField, setScaleRatio, onSave,
  imageWidth, openFieldPageOptionModal, setEditorFocusedPageField, recipients,
  currentEditorPage, setCurrentEditorPage, standardFields, customFields, title,
  documentDimensions,
}) => {
  const [currentTab, setCurrentTab] = useState(editorFields.tabs.standardFields);
  const [customFieldModalOpen, setCustomFieldModalOpen] = useState(false);
  const [currentCustomField, setCurrentCustomField] = useState({});

  const onClickEdit = (field = {}) => {
    setCurrentCustomField(field);
    setCustomFieldModalOpen(true);
  };

  useEffect(() => {
    const { id = '', name = '', input_type = '' } = currentCustomField;
    changeCustomFormFieldValue('id', id);
    changeCustomFormFieldValue('name', name);
    changeCustomFormFieldValue('type', input_type);
  }, [customFieldModalOpen]);

  const renderPageLoader = page => (
    <DocumentPageLoader
      page={page}
      documentDimensions={documentDimensions}
      currentEditorPage={currentEditorPage}
      setCurrentEditorPage={setCurrentEditorPage}
      setScaleRatio={setScaleRatio}
      scaleRatio={scaleRatio}
      recipients={recipients}
      imageWidth={imageWidth}
      canDragFields={canDragFields}
      focusedPageField={focusedPageField}
      setEditorFocusedPageField={setEditorFocusedPageField}
      onClickFieldsLayer={onClickFieldsLayer}
      onAddPageField={onAddPageField}
      onChangePageField={onChangePageField}
      onClickPageField={onClickPageField}
      onRemovePageField={onRemovePageField}
      onResizeHandlePress={onResizeHandlePress}
      openFieldPageOptionModal={openFieldPageOptionModal}
      pageFields={pageFields}
    />
  );

  const renderDocumentsPages = () => (
    <DocumentsPageLoader
      documentDimensions={documentDimensions}
      documents={documents}
      setScaleRatio={setScaleRatio}
      scaleRatio={scaleRatio}
      imageWidth={imageWidth}
      loading={loading}
      renderPageLoader={renderPageLoader}
    />
  );

  return (
    <>
      <div style={{ marginLeft: '2rem', width: '80%' }}>
        <PageHeader title={title} />
        <PageContainer style={{ margin: '0 auto', maxWidth: imageWidth }}>
          <PageContent>{renderDocumentsPages()}</PageContent>
        </PageContainer>
      </div>

      <FieldsPanel
        tabs={getTabs(standardFields, customFields)}
        currentTab={currentTab}
        onTabPress={tab => setCurrentTab(tab)}
        onClickEdit={onClickEdit}
        focusedPageField={focusedPageField}
        recipients={recipients}
        updateEditorPageField={onChangePageField}
        onSave={onSave}
        scaleRatio={scaleRatio}
      />
      <CustomDragLayer
        allFields={[...standardFields, ...customFields]}
        pageFields={pageFields}
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
        recipients={recipients}
      />
      <CustomFieldModal
        key={currentCustomField.id}
        open={customFieldModalOpen}
        changeCustomFormFieldValue={changeCustomFormFieldValue}
        dismiss={() => setCustomFieldModalOpen(false)}
      />
    </>
  );
};

DocumentsEditor.propTypes = {
  title: PropTypes.string.isRequired,
  standardFields: PropTypes.any.isRequired,
  customFields: PropTypes.any.isRequired,
  changeCustomFormFieldValue: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  recipients: PropTypes.array.isRequired,
  pageFields: PropTypes.array.isRequired,
  focusedPageField: PropTypes.object.isRequired,
  canDragFields: PropTypes.bool.isRequired,
  onAddPageField: PropTypes.func.isRequired,
  onChangePageField: PropTypes.func.isRequired,
  onClickPageField: PropTypes.func.isRequired,
  onClickFieldsLayer: PropTypes.func.isRequired,
  onResizeHandlePress: PropTypes.func.isRequired,
  onRemovePageField: PropTypes.func.isRequired,
  setScaleRatio: PropTypes.func.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  openFieldPageOptionModal: PropTypes.func.isRequired,
  setEditorFocusedPageField: PropTypes.func.isRequired,
  setCurrentEditorPage: PropTypes.func.isRequired,
  currentEditorPage: PropTypes.object.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

export default DocumentsEditor;
