import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import documentActions from '../../actions/documentActions';
import parser from '../../utils/parserQueryString';
import organizationActions from '../../actions/organization';
import signDocumentScreenActions from '../../actions/signDocumentScreen';
import { fetchDocumentDimensions } from '../../actions/documentDimensions';
import graphqlClient from '../../config/graphql';
import { DECLINE_SIGNATURE_REQUEST, DELEGATE_SIGNATURE_REQUEST } from './queries';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    componentDidMount() {
      // eslint-disable-next-line react/prop-types
      this.props.fetchDocumentDimensions();
    }
    declineSignatureRequest = async (signatureRequestUuid, declineReason) => {
      // eslint-disable-next-line react/prop-types
      const { location, history } = this.props;
      const params = parser(location.search);
      const { auth } = params;
      try {
        const {
          data: { declineSignatureRequest },
        // eslint-disable-next-line react/prop-types
        } = await graphqlClient(null, auth).mutate({
          variables: {
          // eslint-disable-next-line react/prop-types
            signatureRequestUuid,
            declineReason,
          },
          mutation: DECLINE_SIGNATURE_REQUEST,
        });

        toast.success('Signature Request declined successfully');
        // eslint-disable-next-line react/prop-types
        history.push('/');
        return declineSignatureRequest;
      } catch (e) {
        return null;
      }
    };


    delegateSignatureRequest = async (signatureRequestUuid, email, comment) => {
      // eslint-disable-next-line react/prop-types
      const { location, history } = this.props;
      const params = parser(location.search);
      const { auth } = params;
      try {
        const {
          data: { delegateSignatureRequest },
        // eslint-disable-next-line react/prop-types
        } = await graphqlClient(null, auth).mutate({
          variables: {
          // eslint-disable-next-line react/prop-types
            signatureRequestUuid,
            email,
            comment,
          },
          mutation: DELEGATE_SIGNATURE_REQUEST,
        });
        toast.success('Signature Request delegated successfully');
        // eslint-disable-next-line react/prop-types
        history.push('/');
        return delegateSignatureRequest;
      } catch (e) {
        toast.error(e.message);
        throw (e);
      }
    };

    render() {
      // eslint-disable-next-line react/prop-types
      const props = {
        ...this.props,
      };
      return (<Component
        {...props}
        declineSignatureRequest={this.declineSignatureRequest}
        delegateSignatureRequest={this.delegateSignatureRequest}
      />);
    }
  };

const mapStateToProps = ({
  auth, signDocumentScreen, organizations, documentDimensions,
}) => (
  {
    auth,
    signDocumentScreen,
    organizations,
    currentOrganization: organizations.currentOrganization,
    documentDimensions,
  }
);

const mapDispatchToProps = () => {
  const { saveDocumentSignForm } = documentActions;
  const { setCurrentOrganization } = organizationActions;
  const { saveDocumentSign, setSignDocumentFields } = signDocumentScreenActions;
  return {
    saveDocumentSignForm,
    saveDocumentSign,
    setSignDocumentFields,
    setCurrentOrganization,
    fetchDocumentDimensions,
  };
};

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));


export default connect;
