import gql from 'graphql-tag';

export const UPDATE_SIGNATURE_REQUEST_FIELDS = gql`
  mutation UpdateSignatureRequestFields(
    $signatureRequestUuid: ID!
    $pageFields: [UpsertPageField!]!
  ) {
    upsertSignatureRequestPageFields(
      signatureRequestUuid: $signatureRequestUuid
      pageFields: $pageFields
    ) {
        signatureRequest{
          completeInAnyOrder
          documents {
            nodes {
              uuid
            }
          }
          previewImage{
            url
          }
          subject
          message
          organization {
            uuid
          }
          recipients {
            nodes {
              alreadySigned
              email
              fullName
              pending
              signAuthToken
              signingOrder
              uuid
            }
          }
          uuid
        }
      }
    }
`;

export const FETCH_TEMPLATES = gql`
  query getTemplates {
    templates{
      nodes{
        uuid
      }
    }
  }
`;

export default {
  UPDATE_SIGNATURE_REQUEST_FIELDS,
  FETCH_TEMPLATES,
};
