/**
 * Use these messages when displaying a toast notification to a user
 */

const auth = {
  login: {
    error: 'Please check your credentials',
  },
  permissions: {
    permissionDeniedGeneric: 'You have no access to this area',
    permissionDeniedOrganization: 'You have to be under an organization to access this section',
  },
  signup: {
    success: 'Thank you for signing up! Please check your inbox to confirm your account.',
    successNoConfirm: 'Thank you for signing up! You can login to your account.',
    error: 'An error occurred while creating your account. Please contact the invitation sender.',
  },
};

const apiKeys = {
  create: {
    success: 'API key created successfully',
    error: 'An error occurred while creating the API key. Please try again.',
  },
  delete: {
    success: 'API key deleted successfully',
    error: 'An error occurred while deleting the API key. Please try again.',
  },
  regenerate: {
    success: 'API key regenerated successfully',
    error: 'An error occurred while regenerating the API key. Please try again.',
  },
  edit: {
    success: 'API key updated successfully.',
    error: 'An error occurred while updating the API key. Please try again.',
  },
  fetch: {
    error: 'An error occurred while getting the API keys. Please try again later.',
  },
};

const brands = {
  get: {
    error: 'An error occurred while fetching the brands. Please try again.',
  },
  create: {
    success: 'Brand created successfully',
    error: 'An error occurred while creating the brand. Please try again.',
  },
  update: {
    success: 'Brand updated successfully.',
    error: 'An error occurred while updating the brand. Please try again.',
  },
  delete: {
    success: 'Brand deleted successfully.',
    error: 'An error occurred while deleting the brand. Please try again.',
  },
  setDefault: {
    success: 'Default brand set successfully.',
    error: 'An error occurred while setting the default brand. Please try again.',
  },
};

const documents = {
  download: {
    success: 'Document downloaded successfully',
    error: 'An error occurred while downloading the document. Please try again.',
  },
  note: {
    success: 'Note updated successfully',
    error: 'An error occurred while saving the note. Please try again.',
  },
  share: {
    success: 'Document shared successfully',
    error: 'Unable to share your document',
  },
  get: {
    success: '',
    error: 'An error occurred while fetching the document preview. Please try again.',
  },
  upload: {
    success: 'Document uploaded successfully',
    error: 'An error occurred while uploading the document. Please try again.',
  },
  send: {
    success: 'Document sent successfully',
    error: 'An error occurred while sending the document. Please try again.',
  },
  archive: {
    success: 'Document archived successfully',
    error: 'An error occurred while archiving the document. Please try again.',
  },
  sign: {
    success: 'Document signed successfully!',
    error: 'An error occurred while signing the document. Please try again.',
  },
  create: {
    success: 'Document created successfully!',
    error: 'An error occurred while creating the document. Please try again.',
  },
};

const templates = {
  get: {
    success: '',
    error: 'An error occurred while fetching the templates. Please try again.',
  },
  set: {
    success: '',
    error: 'An error occurred while setting the template. Please try again.',
  },
  create: {
    success: 'Template created successfully',
    error: 'An error occurred while creating the template. Please try again.',
  },
  update: {
    success: 'Template updated successfully',
    error: 'An error occurred while updating the template. Please try again.',
  },
  send: {
    success: 'Template sent successfully',
    error: 'An error occurred while sending the template. Please try again.',
  },
  save: {
    success: 'Template saved successfully',
    error: 'An error occurred while saving the template. Please try again.',
  },
  delete: {
    success: 'Template deleted successfully',
    error: 'An error occurred while deleting the template. Please try again.',
  },
  documentUpdate: {
    success: 'The fields have been updated successfully',
    error: 'There was en error while updating the fields. Please try again',
  },
};

const fields = {
  create: {
    success: 'Field created successfully',
    error: 'An error occurred while creating the field. Please try again.',
  },
  update: {
    success: 'Field updated successfully',
    error: 'An error occurred while updating the field. Please try again.',
  },
  delete: {
    success: 'Field deleted successfully',
    error: 'An error occurred while downloading the field. Please try again.',
  },
};

const organizations = {
  create: {
    success: 'Organization created successfully',
    error: 'An error occurred while creating the organization. Please try again.',
  },
  update: {
    success: 'Organization updated successfully',
    error: 'An error occurred while updating the organization. Please try again.',
  },
  delete: {
    success: 'Organization deleted successfully',
    error: 'An error occurred while deleting the organization. Please try again.',
  },
  invite: {
    success: 'Invite sent successfully',
    error: 'An error occurred while sending the invite. Please try again.',
  },
  invitationToken: {
    error: 'The invitation token provided is not valid',
  },
  resendInvite: {
    success: 'Invite resent successfully',
    error: 'An error occurred while resending the invite. Please try again.',
  },
  cancelInvite: {
    success: 'Invite canceled successfully',
    error: 'An error occurred while canceling the invite. Please try again.',
  },
};

const password = {
  forgot: {
    success: '',
    error: 'An error occurred while recovering your password. Please try again.',
  },
  recovery: {
    success: 'Password updated successfully',
    error: 'An error occurred while updating your password. Please try again.',
  },
};

const users = {
  get: {
    error: 'An error occurred while fetching the users. Please try again.',
  },
  updateRole: {
    success: 'The user has been updated successfully',
    error: 'An error occurred while updating the user. Please try again.',
  },
  delete: {
    success: 'The user has been deleted successfully',
    error: 'An error occurred while deleting the user. Please try again.',
  },
};

const signatureRequest = {
  send: {
    success: 'Your signature request has been sent',
  },
  create: {
    error: 'An error occurred while creating the signature request. Please try again.',
  },
  fetch: {
    error: 'The information on this page is no longer available!',
  },
};

const documentManager = {
  notes: {
    update: {
      success: 'Notes updated successfully',
      error: 'An error occurred while updating the notes. Please try again.',
    },
    delete: {
      success: 'Notes deleted successfully',
      error: 'An error occurred while deleting notes. Please try again.',
    },
  },
  remind: {
    sent: {
      success: 'Reminder sent successfully!',
      error: 'An error occurred while sending the document\'s reminder. Please try again.',
    },
    schedule: {
      success: 'Your reminder has been scheduled!',
      error: 'An error occurred while scheduling the document\'s reminder. Please try again.',
    },
    stop: {
      success: 'Your reminder has been stopped successfully',
      error: 'An error occurred while stopping the document\'s reminder. Please try again.',
    },
    fetch: {
      error: 'An error occurred while fetching the document\'s reminder. Please try again.',
    },
  },
  void: {
    success: 'Signature request has been voided successfully',
    error: 'An error occurred while voiding the signature request. Please try again.',
  },
};

const files = {
  select: {
    unsupported: 'One or more files are not a PDF',
  },
};

const subscription = {
  success: "You've been subscribed successfully",
  error: 'An error occurred while subscribing. Please try again.',
};

const passwordReset = {
  success: 'Password reset email has been sent successfully',
  error: 'An error occurred while sending the email. Please try again.',
};

const analytics = {
  sendReport: {
    success: 'Report has been sent successfully',
    error: 'An error occurred while sending the report. Please try again.',
  },
};

export default {
  auth,
  apiKeys,
  brands,
  documents,
  templates,
  fields,
  organizations,
  password,
  users,
  signatureRequest,
  documentManager,
  files,
  subscription,
  passwordReset,
  analytics,
};
