import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import { DragItemTypes } from '../../constants';
import { PageField } from './PageField';
import { getFieldToAdd } from '../../utils/pageFields';

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '20%',
  height: '100%',
};

function getItemStyles(props) {
  const { currentOffset, clientOffset, itemType } = props;
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const offset = itemType === DragItemTypes.FIELDS_PANEL_OPTION ? currentOffset : clientOffset;

  const { x, y } = offset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

class CustomDragLayer extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    allFields: PropTypes.array,
    pageFields: PropTypes.array,
    recipients: PropTypes.array,
    isDragging: PropTypes.bool.isRequired,
    itemType: PropTypes.object.isRequired,
    scaleRatio: PropTypes.number.isRequired,
    imageWidth: PropTypes.number,
  }

  static defaultProps = {
    allFields: [],
    pageFields: [],
    recipients: [],
    imageWidth: null,
  }

  renderItem = (field, allFields, pageFields, itemType, scaleRatio, imageWidth, recipients) => {
    switch (itemType) {
      case DragItemTypes.FIELDS_PANEL_OPTION: {
        return (
          PageField.renderField(
            getFieldToAdd({
              ...field,
              fieldType: field.id,
              field_type: allFields.find(f => f.id === field.id).input_type,
            }, allFields, pageFields),
            () => {}, () => {}, scaleRatio, imageWidth, () => {}, recipients,
          )
        );
      }
      default:
        return null;
    }
  }

  render() {
    const {
      item: field, allFields, pageFields, isDragging, itemType,
      scaleRatio, imageWidth, recipients,
    } = this.props;
    if (!isDragging) { return false; }
    return (
      <div style={layerStyles}>
        <div style={getItemStyles({ ...this.props, itemType })}>
          {this.renderItem(field, allFields, pageFields, itemType,
           scaleRatio, imageWidth, recipients)}
        </div>
      </div>
    );
  }
}

export default (DragLayer(collect)(CustomDragLayer));
