import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TimeAgo from 'react-timeago';


class LogItem extends Component {
  state = {};

  render() {
    const { date, content } = this.props;
    return (
      <Container >
        <Content>
          {content}
        </Content>
        <Date>
          {date && <TimeAgo date={date} />}
        </Date>
      </Container>
    );
  }
}

LogItem.propTypes = {
  content: PropTypes.string.isRequired,
  date: PropTypes.any.isRequired,
};

export default LogItem;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.875rem;
`;

const Content = styled.div`
  width: 100%;
  font-size: 0.75rem;
  font-family: DIN-Medium;
  line-height: 1rem;
`;
const Date = styled.div`
  width: 100%;
  font-size: 0.625rem;
  line-height: 1rem;
`;
