import { getStatus, getHistoryByEventName } from '../../../utils/documentParserGQL';

export const parseTemplates = templates =>
  templates.map((template) => {
    const {
      uuid, name, notes, activityLogs, recipients, description,
      previewImage, user,
    } = template;

    const history = activityLogs.nodes.map(log => ({ [log.event]: log.eventDate }));

    const lastEdited = activityLogs.nodes.filter(activityLog => activityLog.event === 'updated')[0];
    const lastEditedDate = lastEdited && lastEdited.eventDate;
    return {
      uuid,
      name,
      history,
      description,
      notes: notes || '',
      recipients: recipients.nodes,
      sender: user.email,
      date: getHistoryByEventName(history, 'created') || getHistoryByEventName(history, 'create'),
      lastEdited: lastEditedDate,
      createdBy: `${user.firstName} ${user.lastName}`,
      type: 'template',
      previewUrl: previewImage && previewImage.url,
      status: getStatus(history),
    };
  });

export default parseTemplates;
