import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from 'material-ui/svg-icons/content/add';
import styled from 'styled-components';
import { RaisedButton, Toggle } from 'material-ui';
import { isEmail } from 'validator';

import Recipient from './Recipient';
import DraggableList from '../DraggableList/DraggableList';
import { Box } from '../../components';

const hasDuplicatesBy = (field, arr) => Object.values(arr.reduce((z, a) => ({
  ...z,
  [a[field]]: (z[a[field]] || 0) + 1,
}), {})).some(r => r > 1);

class Recipients extends React.Component {
  renderRecipient = (recipient, index) => {
    const {
      changeRecipientValue, removeSendDocumentRecipient, recipients,
      addSendDocumentRecipient, shouldValidate, disable, disableSorting,
      completeInOrder, disableActions,
    } = this.props;

    return (
      <Recipient
        key={index}
        recipients={recipients}
        recipient={recipient}
        index={index}
        changeRecipientValue={changeRecipientValue}
        removeSendDocumentRecipient={
          (recipient.role || recipients.length === 1) ?
            null : removeSendDocumentRecipient}
        addSendDocumentRecipient={addSendDocumentRecipient}
        shouldValidate={shouldValidate}
        disableSorting={disable || disableSorting}
        disableActions={disable || disableActions}
        disable={disable}
        completeInOrder={disable ? false : completeInOrder}
      />
    );
  }

  renderRecipients = () => {
    const {
      recipients, setSendDocumentRecipients, disableSorting, completeInOrder,
    } = this.props;
    const children = recipients.map(this.renderRecipient);

    return !completeInOrder || disableSorting
      ? <>{children}</>
      : (
        <DraggableList
          componentList={children}
          list={recipients}
          updateList={setSendDocumentRecipients}
        />
      );
  }

  render() {
    const {
      completeInOrder, completeInOrderToggled, disable, recipients,
      addSendDocumentRecipient, disableCompleteInOrder, disableActions,
    } = this.props;
    const canAddRecipients = recipients.length < 15
      && recipients.every(r => isEmail(r.email))
      && !hasDuplicatesBy('email', recipients);

    return (
      <Box className="mb-3" title="Recipients">
        <Toggle
          toggled={completeInOrder}
          className="ml-3 mt-3"
          label="Sign in order"
          disabled={disable}
          style={{ maxWidth: 200 }}
          onToggle={disableCompleteInOrder ? (() => {}) : completeInOrderToggled}
        />
        {this.renderRecipients()}
        <ButtonContainer>
          {!disable && !disableActions && (
            <RaisedButton
              disabled={!canAddRecipients}
              primary
              label="Add New"
              style={{ margin: '1rem' }}
              buttonStyle={{ width: '140px' }}
              icon={<AddIcon />}
              onClick={addSendDocumentRecipient}
            />
          )}
        </ButtonContainer>
      </Box>
    );
  }
}


Recipients.propTypes = {
  recipients: PropTypes.array,
  changeRecipientValue: PropTypes.func,
  addSendDocumentRecipient: PropTypes.func,
  removeSendDocumentRecipient: PropTypes.func,
  setSendDocumentRecipients: PropTypes.func,
  completeInOrderToggled: PropTypes.func,
  completeInOrder: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  disableCompleteInOrder: PropTypes.bool,
  disableActions: PropTypes.bool,
  disableSorting: PropTypes.bool,
  disable: PropTypes.bool,
};

Recipients.defaultProps = {
  recipients: [],
  changeRecipientValue: () => {},
  addSendDocumentRecipient: () => {},
  removeSendDocumentRecipient: () => {},
  setSendDocumentRecipients: () => {},
  completeInOrderToggled: () => {},
  completeInOrder: false,
  shouldValidate: false,
  disableActions: false,
  disableCompleteInOrder: false,
  disableSorting: false,
  disable: false,
};


const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1rem;
`;


export default Recipients;
