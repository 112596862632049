import gql from 'graphql-tag';

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $name: String!,
    $businessName: String,
    $subdomain: String!,
    $taxId: String,
    $address: String,
    $invitees: [InviteRecipient!],
    $phoneNumber: String,
    ){
      createOrganization(
        name: $name,
        businessName: $businessName,
        subdomain: $subdomain,
        taxId: $taxId,
        address: $address,
        invitees: $invitees,
        phoneNumber: $phoneNumber,
      ){
        organization {
          uuid
          name
          address
          subdomain
          businessName
          phoneNumber
          users {
            nodes {
              uuid
              firstName
              lastName
              email
              defaultSignature
              defaultInitials
              phone
              state
              city
              streetAddress
              zipCode
            }
          }
        }
      }
  }
`;

export default {
  CREATE_ORGANIZATION,
};
