import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'redux-form-material-ui';
import { injectGlobal } from 'styled-components';
import Input from './Input';

const DatePickerComponent = ({
  field, style,
}) => (
  <Input
    component={DatePicker}
    type="text"
    className="sign_date_picker"
    fieldName={`field_${field.id}`}
    style={style}
  />
);

DatePickerComponent.propTypes = {
  field: PropTypes.object.isRequired,
  style: PropTypes.object,
};

DatePickerComponent.defaultProps = {
  style: {},
};

export default DatePickerComponent;

// eslint-disable-next-line
injectGlobal`
.sign_date_picker input {
  background-color: transparent !important;
  border: 0px;
  width: 100%;
}

.sign_date_picker {
  background-color: transparent !important;
  border: 0px;
  width: 100%;
}

.sign_date_picker hr {
  width: 0px !important;
}
`;
