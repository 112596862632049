import { toast } from 'react-toastify';
import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';

export const downloadSignedDocument = url => (dispatch) => {
  dispatch({
    type: Types.signedDocumentScreen.DOWNLOADING,
    downloading: true,
  });

  try {
    window.open(url);

    toast.success(Messages.documents.download.success);
  } catch (error) {
    toast.error(Messages.documents.download.error);
  } finally {
    dispatch({
      type: Types.signedDocumentScreen.DOWNLOADING,
      downloading: false,
    });
  }
};

export default {
  downloadSignedDocument,
};
