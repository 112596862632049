import React from 'react';
import { DragSource } from 'react-dnd';
import styled from 'styled-components';
import { DragItemTypes } from '../../constants';
import CrossIcon from '../../assets/images/fill_sign_icons/cross-icon.svg';
import CheckIcon from '../../assets/images/fill_sign_icons/check-icon.svg';
import LineIcon from '../../assets/images/fill_sign_icons/line-icon.svg';
import DotIcon from '../../assets/images/fill_sign_icons/dot-icon.svg';
import DocumentSign from '../documentSign';

const {
  Image,
  Circle,
} = DocumentSign;

// DnD Spec
const dragSourceSpec = {
  canDrag(props) {
    return props.canDrag;
  },
  beginDrag(props) {
    return { id: props.field.id, width: props.field.width, height: props.field.height };
  },
};

// DnD Collector
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

export class TemplatePreviewPageField extends React.Component {
  static renderField = (field, scaleRatio) => {
    switch (field.type) {
      case 'date_picker':
      case 'input': {
        return (
          <div style={{ height: field.height * scaleRatio, width: field.width * scaleRatio }}>
            <PreviewInput />
          </div>
        );
      }
      case 'cross': {
        return (
          <div style={{ height: field.height * scaleRatio, width: field.width * scaleRatio }}>
            <Image src={CrossIcon} />
          </div>
        );
      }
      case 'check': {
        return (
          <div style={{ height: field.height * scaleRatio, width: field.width * scaleRatio }}>
            <Image src={CheckIcon} />
          </div>
        );
      }
      case 'circle': {
        return (
          <div style={{ height: field.height * scaleRatio, width: field.width * scaleRatio }}>
            <Circle />
          </div>
        );
      }
      case 'line': {
        return (
          <div style={{ height: field.height * scaleRatio, width: field.width * scaleRatio }}>
            <Image src={LineIcon} />
          </div>
        );
      }
      case 'dot': {
        return (
          <div style={{ height: field.height * scaleRatio, width: field.width * scaleRatio }}>
            <Image src={DotIcon} isDot />
          </div>
        );
      }
      case 'initials':
      case 'signature':
        return (
          <div style={{ height: field.height * scaleRatio, width: field.width * scaleRatio }}>
            <PreviewInput image={field.content} />
          </div>
        );
      default:
        return null;
    }
  }
}

const PreviewInput = styled.div`
  width: 100%;
  height: 100%;
  border 1px dotted rgb(84, 188, 234);
  background-image: url(${props => props.image});
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
`;

export default DragSource(
  DragItemTypes.FIELDS_PANEL_OPTION,
  dragSourceSpec,
  collect,
)(TemplatePreviewPageField);
