import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { SelectField, MenuItem, CircularProgress } from 'material-ui';
import { getTodayMinus } from '../../../utils/date';
import connect from './connect';
import { BarVerticalChart } from '../../charts';
import { Box } from '../..';

const VelocityChartReport = ({
  fetchDetails, organizations, withHeader, loading,
}) => {
  const [velocity, setVelocity] = useState(1);
  const [velocityData, setVelocityData] = useState({});

  const startDatesBy = {
    1: () => getTodayMinus(1, 'year'),
    2: () => getTodayMinus(1, 'month'),
    3: () => getTodayMinus(1, 'week'),
  };

  const parseData = (envelopes) => {
    const data = [
      {
        name: '< 1 hour', envelopes: 0, min: 0, max: 1,
      },
      {
        name: '1-6 hours', envelopes: 0, min: 1, max: 6,
      },
      {
        name: '6-12 hours', envelopes: 0, min: 6, max: 12,
      },
      {
        name: '12-24 hours', envelopes: 0, min: 12, max: 24,
      },
      {
        name: '1-2 days', envelopes: 0, min: 24, max: 48,
      },
      {
        name: '2-7 days', envelopes: 0, min: 48, max: 7 * 24,
      },
      {
        name: '7+ days', envelopes: 0, min: 7 * 24, max: null,
      },
    ];
    // eslint-disable-next-line array-callback-return
    envelopes.filter(e => e.completionTime).map((envelope) => {
      const hours = parseInt(envelope.completionTime.split(':')[0], 10);
      // eslint-disable-next-line array-callback-return
      data.map((range) => {
        if (range.max && hours >= range.min && hours <= range.max) {
          range.envelopes += 1;
        } else if (!range.max && hours >= range.min) {
          range.envelopes += 1;
        }
      });
    });
    return data;
  };

  const handleChange = async (newVelocity) => {
    const newVelocityData = await fetchDetails(startDatesBy[newVelocity](), new Date());
    setVelocityData(parseData(newVelocityData));
    setVelocity(newVelocity);
  };

  useEffect(() => {
    handleChange(velocity);
  }, [(organizations.currentOrganization || {}).id]);

  const renderTitle = title => (
    <Title>
      <div>{title}</div>
      <SelectField
        autoWidth
        style={{ width: 250 }}
        value={velocity}
        onChange={(e, i, v) => handleChange(v)}
      >
        <MenuItem value={1} primaryText="Last 12 Months" />
        <MenuItem value={2} primaryText="Past 30 Days" />
        <MenuItem value={3} primaryText="Past 7 Days" />
      </SelectField>
    </Title>
  );

  return (
    <Box withHeader={withHeader} title={renderTitle('Envelope Velocity')} className="col-6">
      <Chart>
        {loading
          ? <CircularProgress size={60} thickness={7} />
          : <BarVerticalChart data={velocityData} XAxisName="name" lineName="envelopes" />}
      </Chart>
    </Box>
  );
};

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Chart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 18rem;
  padding: 1rem;
`;

VelocityChartReport.propTypes = {
  fetchDetails: PropTypes.func.isRequired,
  organizations: PropTypes.object.isRequired,
  withHeader: PropTypes.bool,
  loading: PropTypes.bool,
};

VelocityChartReport.defaultProps = {
  withHeader: true,
  loading: false,
};

export default connect(VelocityChartReport);
