import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton, FlatButton, CircularProgress } from 'material-ui';
import { FileCloudUpload, ActionCheckCircle } from 'material-ui/svg-icons';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';

import { colors } from '../../utils';
import Messages from '../../constants/toastMessages';

// DnD Spec
const dropTargetSpec = {
  drop(props, monitor, component) {
    const { files } = monitor.getItem();
    component.loadFiles(files);
  },
};

// Dnd Collector
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

const acceptedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];

const acceptedFileTypeString = () => {
  let string = '';
  acceptedFileTypes.forEach((acceptedType, index) => { string = string.concat(acceptedType, index !== acceptedFileTypes.length - 1 ? ',' : ''); });
  return string;
};

class UploadFiles extends React.Component {
  static propTypes = {
    onFilesLoaded: PropTypes.func.isRequired,
    onUploadConfirm: PropTypes.func.isRequired,
    uploading: PropTypes.bool.isRequired,
    uploadProgress: PropTypes.number.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    defaultTitle: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    message: PropTypes.string,
  }

  static defaultProps = {
    message: '',
  }

  state = {
    files: [],
  }

  loadFiles(files) {
    let areFilesValid = true;
    Array.from(files).forEach((file) => {
      if (!acceptedFileTypes.includes(file.type)) {
        areFilesValid = false;
      }
    });
    if (areFilesValid) {
      this.setState({ files });
      try {
        this.props.onFilesLoaded(files);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      } finally {
        this.clearFiles();
      }
    } else {
      toast.error(Messages.files.select.unsupported);
    }
  }

  clearFiles() {
    this.setState({ files: [] });
  }

  confirm() {
    const { files } = this.state;
    this.props.onUploadConfirm(files);
    this.clearFiles();
  }

  renderUploadUI() {
    const { uploading, defaultTitle, buttonLabel } = this.props;
    if (uploading || this.state.files.length) return null;
    return (
      <Fragment>
        <FileCloudUpload className="mb-1" style={{ width: 64, height: 64 }} color={colors.blue} />
        <DropzonePrimaryLabel>{defaultTitle}</DropzonePrimaryLabel>
        <DropzoneSecondaryLabel className="mb-2">or</DropzoneSecondaryLabel>
        <RaisedButton primary label={buttonLabel} onClick={() => this.fileBtnRef.click()} />
        <input
          style={{ display: 'none' }}
          ref={(ref) => { this.fileBtnRef = ref; return true; }}
          type="file"
          accept={acceptedFileTypeString()}
          onChange={e => this.loadFiles(e.target.files)}
        />
      </Fragment>
    );
  }

  renderConfirmation() {
    if (this.props.uploading || !this.state.files.length) return null;
    return (
      <Fragment>
        <RaisedButton
          primary
          className="my-2"
          label={`Upload ${this.state.files.length} File(s)`}
          onClick={() => this.confirm()}
        />
        <FlatButton
          primary
          label="Clear"
          onClick={() => this.clearFiles()}
        />
      </Fragment>
    );
  }

  renderLoader() {
    const { uploading, uploadProgress, message } = this.props;
    if (!uploading) return null;
    return (uploadProgress < 100 || message) ? (
      <Fragment>
        <CircularProgress className="mb-3" />
        {uploadProgress < 100 && <span>Uploading... {uploadProgress}%</span>}
        {message && <span>{message}</span>}
      </Fragment>
    ) : (
      <Fragment>
        <ActionCheckCircle className="mb-1" style={{ width: 64, height: 64 }} color={colors.green} />
        <span>All done!</span>
      </Fragment>
    );
  }

  render() {
    return this.props.connectDropTarget((
      <span>
        <UploadDropzone isOver={this.props.isOver} className="mb-4">
          {this.renderUploadUI()}
          {this.renderConfirmation()}
          {this.renderLoader()}
        </UploadDropzone>
      </span>
    ));
  }
}

const UploadDropzone = styled.div`
  width: 100%;
  height: 220px;
  background: ${props => (props.isOver ? 'white' : colors.gray)};
  border: dashed .25rem ${colors.blue};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
`;

const DropzonePrimaryLabel = styled.div`
  font-size: 1.125rem;
  color: black;
`;

const DropzoneSecondaryLabel = styled.div`
  font-size: 1rem;
`;

export default DropTarget([NativeTypes.FILE], dropTargetSpec, collect)(UploadFiles);
