import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SelectField, MenuItem } from 'material-ui';
import { colors } from '../../../utils';

const CheckboxFields = ({ field, onChange }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!checked || checked !== field.checked) {
      setChecked((field && field.checked) || false);
    }
  });

  return (
    <Container>
      <InputContent>
        <div>Default Value</div>
        <div>
          <SelectField
            dropDownMenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
            value={checked}
            onChange={(event, index, val) => onChange('checked', val > 0)}
          >
            <MenuItem
              value={false}
              primaryText="Unchecked"
            />
            <MenuItem
              value
              primaryText="Checked"
            />
          </SelectField>
        </div>
      </InputContent>
    </Container>
  );
};


CheckboxFields.propTypes = {
  field: PropTypes.any,
  onChange: PropTypes.func,
};

CheckboxFields.defaultProps = {
  field: {},
  onChange: () => {},
};

const InputContent = styled.div`
  color: ${colors.defaultImageGray};
  display: flex;
  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }
`;


const Container = styled.div`
  width: 100%;

  & label {
    color: ${colors.defaultImageGray} !important;
  }
`;

export default CheckboxFields;
