import axios from 'axios';
import history from './../config/history';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiWithoutHeaders = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});


/* eslint no-param-reassign: "off" */
const authenticationHeader = (config) => {
  config.headers = {
    'access-token': localStorage.getItem('accessToken'),
    client: localStorage.getItem('client'),
    uid: localStorage.getItem('uid'),
  };

  return config;
};

api.interceptors.request.use(authenticationHeader);

api.interceptors.response.use(null, (error) => {
  if (error.request.status === 401 && error.config.method.toUpperCase() !== 'OPTIONS') {
    // If there's an authentication error, logout and redirect to login
    localStorage.removeItem('accessToken');
    localStorage.removeItem('uid');
    localStorage.removeItem('client');

    switch (history.location.pathname) {
      case '/signature':
        history.push('/login?redirect=true');
        break;
      default:
        history.replace('/login');
    }
  }

  return Promise.reject(error);
});

export default api;
