import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../utils';

const BulletLegend = props => (
  <Container className="d-flex mb-3">
    <Bullet color={props.color} />
    <Label>{props.label}</Label>
  </Container>
);

BulletLegend.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
};

BulletLegend.defaultProps = {
  label: 'No Legend',
  color: colors.textLight,
};

const Container = styled.div`
  height: 1rem;
`;

const Bullet = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background-color: ${props => props.color};
  margin-right: 1rem;
`;

const Label = styled.div`
  color: ${colors.textLight};
  line-height: 1rem;
`;

export default BulletLegend;
