import { isRoleAdmin } from './role';

// eslint-disable-next-line
export const isAdmin = roles => (
  roles.find(role => role && isRoleAdmin(role.name))
);

// eslint-disable-next-line
export const isOwner = role => (
  role === 'owner' || role === 'super_admin'
);
