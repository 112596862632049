import React, { Component } from 'react';
import { connect as ReduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import graphqlClient from '../../../config/graphql';
import { FETCH_USER } from './queries';
import { SEND_RESET_PASSWORD_EMAIL } from './mutations';
import Messages from '../../../constants/toastMessages';

const renderWrappedComponent = WrappedComponent =>
  class extends Component {
    state = {
      user: {},
    }

    componentDidMount = () => this.fetchUser();

    fetchUser = async () => {
      // eslint-disable-next-line react/prop-types
      const { userId, currentOrganization } = this.props;
      const { data: { user } } = await graphqlClient(currentOrganization &&
        currentOrganization.subdomain).query({
        query: FETCH_USER,
        variables: { uuid: userId },
        fetchPolicy: 'network-only',
      });
      this.setState({ user });
    }

    sendPasswordReset = async (uuid) => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization } = this.props;
      try {
        await graphqlClient(currentOrganization &&
          // eslint-disable-next-line react/prop-types
          currentOrganization.subdomain).mutate({
          mutation: SEND_RESET_PASSWORD_EMAIL,
          variables: { uuid },
        });
        toast.success(Messages.passwordReset.success);
      } catch (e) {
        toast.error(Messages.passwordReset.error);
        throw e;
      }
    }

    render = () => {
      const { user } = this.state;
      return (<WrappedComponent
        {...this.props}
        initialValues={user}
        sendPasswordReset={this.sendPasswordReset}
      />);
    }
  };

const mapStateToProps = ({ organizations }) => ({
  currentOrganization: organizations.currentOrganization,
});

const connect = WrappedComponent =>
  ReduxConnect(mapStateToProps, null)(renderWrappedComponent(WrappedComponent));

export default connect;
