import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RaisedButton, Dialog, FlatButton } from 'material-ui';
import { isEmpty } from 'lodash';
import PDFIcon from '../../assets/images/pdf.svg';
import PrintIcon from '../../assets/images/print.svg';
import EnlargeIcon from '../../assets/images/enlarge.svg';
import PdfDocument from '../PdfPreview';

class DocumentViewSection extends React.Component {
  state = {
    openEnlargeDialog: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  onPrint = (pdf) => {
    window.open(pdf, 'mywin', 'width=600,height=800');
  }

  getButtonAction = ({ uuid, type, fileUrl }) => {
    const action = {
      to: '',
      label: '',
      external: false,
    };
    switch (type) {
      case 'waitingForMe': {
        action.to = `/signature?signature_request=${uuid}`;
        action.label = 'Sign';
        break;
      }
      case 'archived':
      case 'completed': {
        action.to = fileUrl;
        action.label = 'Open';
        action.external = true;
        break;
      }
      case 'template': {
        action.to = `/documents-manager/templates/${uuid}`;
        action.label = 'Edit';
        break;
      }
      default:
        break;
    }
    return action;
  }

  toggleEnlargeModal = () => {
    const { openEnlargeDialog } = this.state;
    this.setState({ openEnlargeDialog: !openEnlargeDialog });
  }

  renderActions = () => {
    const { selectedDocument } = this.props;
    const havePdf = (selectedDocument.signedPdf && selectedDocument.signedPdf.url)
      || selectedDocument.fileUrl;
    const disabledClass = havePdf ? '' : 'disabled';
    return (
      <FlexContainer style={{ position: 'relative' }} flexDirection="row">
        <FlexContainer onClick={() => havePdf && this.onPrint(havePdf)} className={`action ${disabledClass}`}>
          <ActionImage src={PrintIcon} />
          Print
        </FlexContainer>
        <FlexContainer onClick={this.toggleEnlargeModal} className="action">
          <ActionImage src={EnlargeIcon} />
          Enlarge
        </FlexContainer>
        {/* eslint-disable-next-line chai-friendly/no-unused-expressions */}
        <FlexContainer onClick={() => { havePdf && window.open(havePdf, '_blank'); }} className={`action ${disabledClass}`}>
          <ActionImage src={PDFIcon} />
          PDF
        </FlexContainer>
      </FlexContainer>
    );
  }

  renderButton = (action) => {
    if (!action || isEmpty(action.label)) {
      return null;
    }

    if (!action.external) {
      return (
        <Link to={action.to}>
          <RaisedButton label={action.label} primary />
        </Link>
      );
    }

    return (
      <a href={action.to}>
        <RaisedButton label={action.label} primary />
      </a>
    );
  }

  render() {
    const { selectedDocument, selectedDocType } = this.props;
    const { openEnlargeDialog } = this.state;

    if (!selectedDocument) return null;

    const { previewUrl, signedPdf } = selectedDocument;
    const actions = [
      <FlatButton
        label="Close"
        primary
        onClick={this.toggleEnlargeModal}
      />,
    ];

    return (
      <>
        {previewUrl && (
          <PreviewContainer withMarginBottom={selectedDocType === 'completed'}>
            {selectedDocType === 'completed' ?
              <PdfDocument url={signedPdf.url} width="80%" />
            :
              <PDFPreview src={previewUrl} alt="document preview" />
            }
          </PreviewContainer>
        )}

        <div
          style={{ paddingTop: '1rem' }}
          className="d-flex align-items-end justify-content-end h-100 w-100"
        >
          {selectedDocument.type === 'template'
            ? this.renderButton(this.getButtonAction(selectedDocument))
            : this.renderActions()}
        </div>

        <Dialog
          actions={actions}
          modal={false}
          open={openEnlargeDialog}
          onRequestClose={this.toggleEnlargeModal}
          autoScrollBodyContent
        >
          <PreviewContainer withMarginBottom={selectedDocType === 'completed'}>
            {selectedDocType === 'completed' ?
              <PdfDocument url={signedPdf.url} width="80%" containerMinHeight="80vh" />
            :
              <PDFPreview src={previewUrl} alt="document preview" />
            }
          </PreviewContainer>
        </Dialog>
      </>
    );
  }
}

DocumentViewSection.propTypes = {
  selectedDocument: PropTypes.object.isRequired,
  selectedDocType: PropTypes.string.isRequired,
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  flex: 1;
  justify-content: center;
  align-items: center;
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 12px;

  & .action{
    cursor: pointer;
    margin: 0 0.5rem;
  }

  & .disabled{
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const ActionImage = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  margin-bottom: 1rem;
`;

const PreviewContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: ${({ withMarginBottom }) => withMarginBottom && 3}rem;
  max-width: 100%;
  text-align: center;
`;

const PDFPreview = styled.img`
  height: auto;
  width: 80%;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
`;

export default DocumentViewSection;
