import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MenuItem } from 'material-ui';
import {
  NavigationApps,
  SocialGroup,
  EditorShowChart,
  AvLibraryBooks,
  ActionList,
} from 'material-ui/svg-icons';

import { spacing } from '../utils';

const AppSidebar = () => (
  <Container>
    <SidebarLink to="/dashboard">
      <MenuItem primaryText="Dashboard" leftIcon={<NavigationApps />} />
    </SidebarLink>

    <SidebarLink to="/send-document">
      <MenuItem primaryText="Send Document" leftIcon={<SocialGroup />} />
    </SidebarLink>

    <SidebarLink to="/analytics">
      <MenuItem primaryText="Analytics" leftIcon={<EditorShowChart />} />
    </SidebarLink>

    <SidebarLink to="/documents-manager">
      <MenuItem primaryText="Document Manager" leftIcon={<AvLibraryBooks />} />
    </SidebarLink>

    <SidebarLink to="/organizations">
      <MenuItem primaryText="Organizations" leftIcon={<ActionList />} />
    </SidebarLink>
  </Container>
);

const Container = styled.div`
  width: ${spacing.sidenavWidth};
`;

const SidebarLink = styled(Link)`
  &:hover { 
    text-decoration: none;
  }
`;

export default AppSidebar;
