import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isNull } from 'lodash';
import FieldsLayer from './FieldsLayer';
import { imaging } from '../../utils';
import { getScaledFields } from '../../utils/pageFields';

class DocumentPage extends React.Component {
  state = {
    scaling: false,
    intialized: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateScaleRatioEventFunction);
    window.addEventListener('resize', this.updateScaleRatioEventFunction);
  }

  componentDidUpdate = async (prevProps) => {
    /* the first time it loads and is visible */
    const { IMAGE_WIDTH } = this.props.documentDimensions;
    if (!prevProps.isVisible && this.props.isVisible && !this.state.intialized) {
      const scaleRatio = this.pageImageRef.width / IMAGE_WIDTH;
      this.props.setScaleRatio(scaleRatio, IMAGE_WIDTH);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ intialized: true });
    }

    if (prevProps.imageWidth !== this.props.imageWidth) {
      const { imageURL, imageWidth } = this.props;
      const image = this.pageImageRef.width < imageWidth ? this.pageImageRef.width : imageWidth;
      const imageSize = await imaging.getImageSizeAsync(imageURL);
      const scaleRatio = image / imageSize.width;
      this.props.setScaleRatio(scaleRatio, this.props.imageWidth);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScaleRatioEventFunction);
  }

  updateScaleRatioEventFunction = () => {
    const { isVisible } = this.props;
    const { scaling } = this.state;
    if (isVisible && !scaling) {
      this.setState({ scaling: true });
      setTimeout(() => {
        const { imageURL } = this.props;
        this.updateScaleRatio(imageURL);
        this.setState({ scaling: false, intialized: true });
      }, 100);
    }
  }

  handleChangeField = (field) => {
    const { setEditorFocusedPageField, onFieldChange, focusedPageField } = this.props;
    if (focusedPageField && focusedPageField.id === field.id) {
      setEditorFocusedPageField(field);
    }
    onFieldChange(field);
  }

  async updateScaleRatio(imageURL) {
    if (!(isNull(imageURL)) && this.pageImageRef) {
      const image = this.pageImageRef.width < this.props.imageWidth ?
        this.pageImageRef.width : this.props.imageWidth;
      const imageSize = await imaging.getImageSizeAsync(imageURL);
      const scaleRatio = image / imageSize.width;
      this.props.setScaleRatio(scaleRatio, this.props.imageWidth);
    }
  }

  pageImageRef = null

  render() {
    const {
      imageURL, canDragFields, setFieldOptionModalOpen,
      focusedPageField, onFieldAdd, onRemoveField,
      onFieldClick, onResizeHandlePress, onFieldResize, pageFields,
      scaleRatio, onClickFieldsLayer, imageWidth, recipients, documentDimensions,
    } = this.props;
    return (
      <Container>
        <PageImage
          src={imageURL}
          imageWidth={imageWidth}
          innerRef={(ref) => { this.pageImageRef = ref; }}
        />
        <FieldsLayer
          documentDimensions={documentDimensions}
          scaleRatio={scaleRatio}
          imageWidth={imageWidth}
          recipients={recipients}
          fields={getScaledFields(pageFields, scaleRatio)}
          focusedPageField={focusedPageField}
          canDragFields={canDragFields}
          onFieldAdd={field => onFieldAdd(field)}
          onFieldChange={field => this.handleChangeField(field)}
          onRemoveField={field => onRemoveField(field)}
          onFieldClick={field => onFieldClick(field)}
          onClick={() => onClickFieldsLayer()}
          onResizeHandlePress={canDrag => onResizeHandlePress(canDrag)}
          onFieldResize={field => onFieldResize(field)}
          setFieldOptionModalOpen={setFieldOptionModalOpen}
        />
      </Container>
    );
  }
}

DocumentPage.propTypes = {
  imageURL: PropTypes.string.isRequired,
  pageFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  focusedPageField: PropTypes.object,
  canDragFields: PropTypes.bool.isRequired,
  onFieldAdd: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFieldClick: PropTypes.func.isRequired,
  onFieldResize: PropTypes.func.isRequired,
  onResizeHandlePress: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  setScaleRatio: PropTypes.func.isRequired,
  setEditorFocusedPageField: PropTypes.func.isRequired,
  setFieldOptionModalOpen: PropTypes.func.isRequired,
  onClickFieldsLayer: PropTypes.func.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  recipients: PropTypes.any.isRequired,
  imageWidth: PropTypes.number,
  isVisible: PropTypes.bool,
  documentDimensions: PropTypes.object.isRequired,
};

DocumentPage.defaultProps = {
  focusedPageField: {},
  isVisible: false,
  imageWidth: null,
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background: white;
  border: 1px solid gray;
`;

const PageImage = styled.img`
  width: ${({ imageWidth }) => imageWidth}px;
`;

export default DocumentPage;

