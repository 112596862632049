import React from 'react';
import MaskedInput from 'react-text-mask';

export function PhoneMask(props) {
  // eslint-disable-next-line
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export function ZipMask(props) {
  const {
    // eslint-disable-next-line
    inputRef, value, onChange, ...other
  } = props;
  const [showMask, setShowMask] = React.useState(false);
  React.useEffect(() => {
    if (value) {
      setShowMask(true);
      onChange({ target: { value } });
    }
  }, [value]);
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onChange={onChange}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      value={value}
      showMask={showMask}
    />
  );
}

export default {
  PhoneMask,
  ZipMask,
};
