import api from './api';

const fieldsService = {
  get: id => api.get(`/fields/${id}`),
  create: ({
    name,
    input_type,
  }, organizationUuid) => api.post('/fields', {
    field: organizationUuid ? {
      name,
      input_type,
      organization_uuid: organizationUuid,
    } : {
      name,
      input_type,
    },
  }),
  update: (id, {
    name,
    input_type,
  }, organizationUuid) => api.put(`/fields/${id}`, {
    field: {
      name,
      input_type,
      organization_uuid: organizationUuid,
    },
  }),
  delete: id => api.delete(`/fields/${id}`),
  list: () => api.get('/fields'),
  listInputTypes: () => api.get('/fields/input_types'),
};

export default fieldsService;
