import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RaisedButton, Dialog, FlatButton } from 'material-ui';
import { colors } from '../utils';

class Modal extends React.Component {
  getDefaultActions = () => {
    const {
      primaryLabel,
      onPrimaryClick,
      primaryDisabled,
      primaryStyle,
      secondaryLabel,
      onSecondaryClick,
      secondaryDisabled,
      secondaryStyle,
      tertiaryLabel,
      tertiaryDisabled,
      onTertiaryClick,
      tertiaryStyle,
      leftButtons,
    } = this.props;
    return (
      <ButtonsContainer>
        <LeftButtons>
          {!tertiaryDisabled && (
            <FlatButton
              label={tertiaryLabel}
              primary
              onClick={onTertiaryClick}
              labelStyle={tertiaryStyle}
            />
          )}
          {leftButtons}
        </LeftButtons>
        <RightButtons>
          <FlatButton
            label={secondaryLabel}
            primary
            disabled={secondaryDisabled}
            onClick={onSecondaryClick}
            style={secondaryStyle}
          />
          <RaisedButton
            label={primaryLabel}
            primary
            disabled={primaryDisabled}
            onClick={onPrimaryClick}
            style={primaryStyle}
          />
        </RightButtons>
      </ButtonsContainer>
    );
  };

  render() {
    const {
      title,
      content,
      actions,
      open,
      isScrollable,
      ...other
    } = this.props;

    return (
      <CustomDialog
        title={title}
        actions={actions || this.getDefaultActions()}
        modal
        open={open}
        autoScrollBodyContent={isScrollable}
        {...other}
      >
        {content}
      </CustomDialog>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.node,
  content: PropTypes.any,
  actions: PropTypes.node,
  open: PropTypes.bool,
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  primaryDisabled: PropTypes.bool,
  secondaryDisabled: PropTypes.bool,
  tertiaryLabel: PropTypes.string,
  tertiaryDisabled: PropTypes.bool,
  onTertiaryClick: PropTypes.func,
  primaryStyle: PropTypes.object,
  secondaryStyle: PropTypes.object,
  tertiaryStyle: PropTypes.object,
  leftButtons: PropTypes.node,
  isScrollable: PropTypes.bool,
};

Modal.defaultProps = {
  actions: undefined,
  content: () => {},
  isScrollable: true,
  onPrimaryClick: () => {},
  onSecondaryClick: () => {},
  onTertiaryClick: () => {},
  open: false,
  primaryDisabled: false,
  primaryLabel: 'Save',
  primaryStyle: {},
  secondaryDisabled: false,
  secondaryLabel: 'Cancel',
  secondaryStyle: {
    marginRight: '2rem',
  },
  tertiaryDisabled: true,
  tertiaryLabel: 'Delete',
  tertiaryStyle: {
    color: colors.danger,
  },
  leftButtons: undefined,
  title: undefined,
};
const CustomDialog = styled(Dialog)`
  @media (orientation: landscape) and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }
  
  & > div > div  {
    @media (orientation: landscape) and (max-width: 768px) {
      transtition: none;
      transform: none;
    }

    @media (max-width: 768px) {
      max-width: 100% !important;
    }
  }

  & > div > div > div  {
    height: 100%;
    @media (orientation: landscape) and (max-width: 768px) {
      height: 80vh;
      display: flex;
      flex-direction: column;  
      justify-content: center;
      align-items: center;
    }
  }

  & > div > div > div > div {
    border-top: 0px !important;
    border-bottom: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 0px;
  }
   
  & > div > div > div > h3 {
    @media (orientation: landscape) and (max-width: 768px) {
      height: 100%;
      display: none;
    }    
  } 
`;

const ButtonsContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  margin-bottom: 1rem;
  @media (orientation: landscape) {
    justify-content: center;
    margin-bottom: 0rem;
    align-items: center;
  } 
`;

const LeftButtons = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  @media (orientation: landscape) and (max-width: 768px) {
    justify-content: center;
    align-content: center;
    flex: 0;
  } 
`;

const RightButtons = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 1rem;
  @media (orientation: landscape) and (max-width: 768px) {
    justify-content: center;
    align-content: center;
    padding-right: 0rem;
  } 
`;

export default Modal;
