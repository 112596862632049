import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  showInviteNewUser: false,
  showEditOrganization: false,
  showEditImage: false,
  currentOrganizationImage: null,
  savingEdit: false,
  isValid: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.organizationsScreen.OPEN_INVITE_NEW_USER: {
      return { ...state, showInviteNewUser: true };
    }

    case Types.organizationsScreen.CLOSE_INVITE_NEW_USER: {
      return { ...state, showInviteNewUser: false };
    }

    case Types.organizationsScreen.TOGGLE_EDIT_ORGANIZATION: {
      const showEditOrganization = action.display;
      return { ...state, showEditOrganization };
    }

    case Types.organizationsScreen.TOGGLE_EDIT_IMAGE: {
      const showEditImage = action.display;
      return { ...state, showEditImage };
    }

    case Types.organizationsScreen.SET_CURRENT_ORGANIZATION_IMAGE: {
      const { currentOrganizationImage } = action;
      return { ...state, currentOrganizationImage };
    }

    case Types.organization.SAVING: {
      const { savingEdit } = action;
      return { ...state, savingEdit };
    }

    case Types.organizationsScreen.IS_VALID_INVITATION_EMAIL: {
      const { isValid } = action;
      return { ...state, isValid };
    }

    default: {
      return state;
    }
  }
};
