import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { formValueSelector } from 'redux-form';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render = () => (<Component {...this.props} />)
  };

const selector = formValueSelector('shareDocumentForm');

const mapStateToProps = (state) => {
  const { brandsSection: { brands } } = state;
  return {
    useBrand: selector(state, 'useBrand'),
    brands,
  };
};

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, null)(renderWrappedComponent(WrappedComponent));

export default connect;
