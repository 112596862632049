import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../../Modal';

class SignatureSentModal extends Component {
  renderModalContent = () => (
    <ContentWrapper>
      Your signature has been sent! Thank you for signing.
    </ContentWrapper>
  );

  render = () => {
    const {
      onClose,
      open,
    } = this.props;
    return (
      <Modal
        title="Congratulations!"
        open={open}
        modal={false}
        content={this.renderModalContent()}
        primaryLabel="Close"
        onPrimaryClick={onClose}
        contentStyle={{ width: '548px' }}
        secondaryStyle={{ display: 'none' }}
        titleStyle={{ padding: '50px 0px 50px 24px', fontFamily: 'DIN-Medium' }}
      />
    );
  }
}

SignatureSentModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

SignatureSentModal.defaultProps = {
  onClose: () => {},
  open: false,
};

const ContentWrapper = styled.div`
  font-weight: 500;
  color: black;
  font-family: DIN-Medium;
  margin-bottom: 50px;
`;

export default SignatureSentModal;
