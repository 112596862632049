import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton, TextField } from 'material-ui';
import { ActionDelete, ActionSwapVert } from 'material-ui/svg-icons';
import { isEmpty } from 'lodash';
import { colors } from '../../utils';


class Role extends Component {
  validate = (role, roles) => {
    const { role: roleName } = role;
    if ((isEmpty(roleName))) {
      return 'The role can\'t be empty';
    }

    if ((roles.filter(r => roleName
      && r && r.role.toLowerCase() === roleName.toLowerCase()).length > 1)) {
      return 'You must not enter duplicated roles';
    }

    return null;
  }

  renderRoleActions(index) {
    const {
      onRemoveRole,
      roles,
    } = this.props;
    if (roles[index] && onRemoveRole) {
      return (
        <Button
          onClick={() => onRemoveRole(index)}
        >
          <ActionDelete
            color={colors.textBlack}
          />
        </Button>
      );
    }
    return null;
  }


  render() {
    const {
      index, onChangeRole,
      role, role: { role: roleName }, roles,
      shouldValidate,
    } = this.props;
    return (
      <Container>
        <Line>
          <SwapIcon className="swap">
            <ActionSwapVert color={colors.textLight} />
          </SwapIcon>
          <Index>{index + 1}</Index>
          <TextField
            type="text"
            hintText="Enter the role name"
            value={roleName}
            fullWidth
            underlineShow={false}
            onChange={e => onChangeRole(index, e.target.value)}
            errorText={(roleName.length > 0 || shouldValidate) && this.validate(role, roles)}
            errorStyle={{ position: 'absolute' }}
          />
          {this.renderRoleActions(index)}
        </Line>
      </Container>
    );
  }
}

Role.propTypes = {
  roles: PropTypes.array,
  role: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChangeRole: PropTypes.func,
  onRemoveRole: PropTypes.func,
  shouldValidate: PropTypes.bool,
};

Role.defaultProps = {
  roles: [],
  onChangeRole: () => {},
  onRemoveRole: () => {},
  shouldValidate: false,
};

const Line = styled.div`
  height: 3.625rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Index = styled.div`
  width: 2.5rem;
  display: flex;
  align-items: center;
`;

const SwapIcon = styled.div`
  width: 2.5rem;
  display: flex;
  align-items: center;
`;

const Button = styled(IconButton)`
  margin-left: auto!important;
`;


const Container = styled.div`
  height: 100%;
  width: 100%;
  border-top: 1px solid rgb(240, 240, 240);;
  border-bottom: 1px solid rgb(240, 240, 240);;
`;

export default Role;

