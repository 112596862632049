import React, { useEffect } from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { get, omit, zipObjectDeep } from 'lodash';

import Actions from '../../actions';
import AnalyticsScreen from './AnalyticsScreen';
import graphqlClient from '../../config/graphql';
import history from '../../config/history';
import { CREATE_REPORT_SCHEDULE } from './queries';

export const parseRequest = o => ({
  ...omit(o, ['sendTo', 'sendOthers', 'recipients']),
  ...zipObjectDeep(
    [].concat(o.recipients ? 'options.recipients' : [])
      .concat(o.frequency === 'ONCE' ? 'options.date' : []),
    [].concat(o.recipients ? o.recipients.split(',').map(a => a.trim()) : [])
      .concat(o.frequency === 'ONCE' ? o.startDate : []),
  ),
  ...(o.frequency === 'ONCE' ? { endDate: o.startDate } : {}),
});

const connect = Component => (props) => {
  const { currentOrganization, auth: { user } } = props; // eslint-disable-line

  useEffect(() => {
    const currentUser = get(currentOrganization, 'users', []).find(u => u.uuid === user.uuid);
    const role = get(currentUser, 'role', 'member');
    if (!['admin', 'super_admin', 'owner'].includes(role)) {
      history.push('/dashboard');
    }
  }, [(currentOrganization || {}).id]);

  const createReportSchedule = async (payload) => {
    try {
      const variables = parseRequest(payload);
      await graphqlClient(
        currentOrganization && currentOrganization.subdomain,
      ).mutate({ mutation: CREATE_REPORT_SCHEDULE, variables });
      toast.success('Your report was successfuly scheduled.');
    } catch (e) {
      toast.error(e.message);
      throw (e);
    }
  };

  return <Component {...props} onSchedule={createReportSchedule} />;
};

const mapStateToProps = state => ({
  auth: state.auth,
  currentOrganization: state.organizations.currentOrganization,
});

export default RConnect(mapStateToProps, Actions)(connect(AnalyticsScreen));
