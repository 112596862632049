import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import { orderBy } from 'lodash';
import Card from 'material-ui/Card';
import moment from 'moment';
import { PageContainer, PageHeader, PageContent } from '../../components/pages';
import SortableTable from '../../components/SortableTable';
import EmptyListNotification from '../../components/EmptyListNotification';

const styles = {
  actionContainer: {
    width: '80%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableContainer: {
    backgroundColor: 'white',
  },
};

const columns = [{
  id: 'type', label: 'Type', field: 'type', type: 'text',
}, {
  id: 'date', label: 'Date', field: 'date', type: 'date',
}, {
  id: 'action', label: '', field: 'action', type: 'action',
}];

const getValuesByType = () => ({
  OWNERSHIP: {
    name: 'Organization Ownership Transfer Invite',
    action: '/transfer-ownership',
  },
  MEMBER: {
    name: 'Organization Membership Invite',
    action: '/organization-invite',
  },
});


const parseInvites = ({ history, invites, setOrganizationsValue }) => orderBy(invites, ['createdAt'], ['desc']).map((invite) => {
  const values = getValuesByType(invite.uuid)[invite.inviteType];
  const onClick = async () => {
    await setOrganizationsValue('selectedInvite', invite);
    history.push(values.action);
  };
  return ({
    type: values.name,
    date: moment(invite.createdAt).format('HH:mm:ss a  dddd, MMM DD, YYYY'),
    action: <div style={styles.actionContainer}><FlatButton label="View" onClick={onClick} primary /></div>,
  });
},
);


export const NotificationsScreen = ({ history, invites, setOrganizationsValue }) => (
  <PageContainer>
    <PageHeader title="Notifications" />
    <PageContent>
      <Card style={styles.tableContainer}>
        <SortableTable
          data={parseInvites({ history, invites, setOrganizationsValue })}
          columns={columns}
        />
      </Card>
      {!invites.length && <EmptyListNotification
        message="Nothing to see here! You do not have waiting notifications."
        link="/dashboard"
        buttonLabel="Dashboard"
      />}
    </PageContent>
  </PageContainer>
);

NotificationsScreen.propTypes = {
  history: PropTypes.object.isRequired,
  setOrganizationsValue: PropTypes.func.isRequired,
  invites: PropTypes.array,
};

NotificationsScreen.defaultProps = {
  invites: [],
};

export default NotificationsScreen;
