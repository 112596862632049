import { toast } from 'react-toastify';
import { submit } from 'redux-form';
import Types from '../constants/actionTypes';
import Messages from '../constants/toastMessages';
import graphqlClient from '../config/graphql';
import { DELETE_INVITE } from '../utils/mutations/invitations';
import organizationService from '../services/organization';
import organizationActions from './organization';

export const openInviteNewUser = () => (dispatch) => {
  dispatch({
    type: Types.organizationsScreen.OPEN_INVITE_NEW_USER,
  });
};

export const closeInviteNewUser = () => (dispatch) => {
  dispatch({
    type: Types.organizationsScreen.CLOSE_INVITE_NEW_USER,
  });
};

export const toggleEditOrganization = display => ({
  type: Types.organizationsScreen.TOGGLE_EDIT_ORGANIZATION,
  display,
});

export const submitInviteForm = () => dispatch => dispatch(submit('inviteNewUserForm'));

export const cancelInvite = uuid => async (dispatch, getState) => {
  try {
    const { currentOrganization } = getState().organizations;
    if (!currentOrganization.uuid) {
      return Promise.resolve();
    }
    await graphqlClient(currentOrganization && currentOrganization.subdomain).mutate({
      variables: { uuid },
      mutation: DELETE_INVITE,
    });
    const { data } = await organizationService.get(currentOrganization.uuid);

    dispatch({
      type: Types.organizations.SET_CURRENT_ORGANIZATION,
      currentOrganization: data,
    });

    await dispatch(organizationActions.updateUserOrganization(data));

    toast.success(Messages.organizations.cancelInvite.success);
    return Promise.resolve();
  } catch (error) {
    toast.error(Messages.organizations.cancelInvite.error);
    return Promise.reject(error);
  }
};

export const toggleOrganizationEditImage = display => ({
  type: Types.organizationsScreen.TOGGLE_EDIT_IMAGE,
  display,
});

export const setCurrentOrganizationImage = imageUrl => ({
  type: Types.organizationsScreen.SET_CURRENT_ORGANIZATION_IMAGE,
  currentOrganizationImage: imageUrl,
});

export const isValidInvitation = isValid => ({
  type: Types.organizationsScreen.IS_VALID_INVITATION_EMAIL,
  isValid,
});

export default {
  openInviteNewUser,
  closeInviteNewUser,
  toggleEditOrganization,
  submitInviteForm,
  cancelInvite,
  toggleOrganizationEditImage,
  setCurrentOrganizationImage,
};
