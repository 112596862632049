import api from './api';

const organization = {
  get: uuid => api.get(`/organizations/${uuid}`),
  list: () => api.get('/organizations'),
  update: (organizationUuid, payload) => api.put(`/organizations/${organizationUuid}`, payload),
  delete: organizationUuid => api.delete(`/organizations/${organizationUuid}`),
  search: query => api.get(`/organizations/search?query=${query}&page=1&per=100`),
  updateRoles: (uuid, roles) => api.put(`/organizations/${uuid}/update_roles`, roles),
};

export default organization;
