import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Form, reduxForm, getFormValues } from 'redux-form';
import { isEmpty } from 'lodash';
import RaisedButton from 'material-ui/RaisedButton';
import PropTypes from 'prop-types';
import DocumentsPageLoader from '../editor/DocumentsPageLoader';
import SignaturePage from '../signature/SignaturePage/SignaturePage';
import SignDocumentScreenActions from '../../actions/signDocumentScreen';
import { colors } from '../../utils';

const buttonStyle = {
  background: '#03A9F4',
  color: '#FFFFFF',
};

class SignatureForm extends React.Component {
  state= {
    currentPageIndex: 0,
  }

  imageRef = null;

  styles = () => {
    const { brand } = this.props;

    if (isEmpty(brand)) {
      return buttonStyle;
    }

    return {
      background: brand.button_color,
      color: brand.button_text_color,
    };
  };


  customFieldSaved = (fieldName, data) => {
    this.props.change(fieldName, data);
  }

  render() {
    const {
      documents,
      isSigning,
      invalid,
      pristine,
      handleSubmit,
      onSubmit,
      signDocumentScreen,
      currentRecipient,
      setSignDocumentScaleRatio,
      documentDimensions,
    } = this.props;

    const getRequiredFields = () => {
      let requiredFields = signDocumentScreen.fields
        .filter(f => f.recipient_uuid === currentRecipient)
        .filter(f => f.page_field_detail.required && f.value === '' &&
        !f.field.name.includes('Date Signed') && !f.field.name.includes('Participation Stamp'));
      const auxRadioButtonsGroups = [];
      requiredFields = requiredFields.filter((f) => {
        if (f.metadata && f.metadata.input_type === 'radio') {
          if (!auxRadioButtonsGroups.includes(f.metadata.radio_button_group_id)) {
            auxRadioButtonsGroups.push(f.metadata.radio_button_group_id);
            return true;
          }
          return false;
        }
        return true;
      });
      return requiredFields;
    };

    const requiredFields = getRequiredFields();

    const isDisabled = invalid || pristine || isSigning || requiredFields.length;
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DocumentsPageLoader
          documentDimensions={documentDimensions}
          currentPageIndex={this.state.currentPageIndex}
          documents={documents}
          setScaleRatio={setSignDocumentScaleRatio}
          imageWidth={signDocumentScreen.imageWidth}
          scaleRatio={signDocumentScreen.scaleRatio}
          renderPageLoader={page => (
            <SignaturePage
              page={page}
              documents={documents}
              currentRecipient={currentRecipient}
              imageWidth={signDocumentScreen.imageWidth}
              scaleRatio={signDocumentScreen.scaleRatio}
              updateScaleRatio={setSignDocumentScaleRatio}
              customFieldSaved={this.customFieldSaved}
              onChangePage={currentPageIndex => currentPageIndex !== this.state.currentPageIndex
               && this.setState({ currentPageIndex })}
            />)}
        />
        <FormFooter>
          {(invalid || pristine) && (
            <p>
              Apparently the form has incomplete fields or validation errors. Please check before
              submitting
            </p>
          )}
          <RaisedButton
            style={this.styles()}
            primary
            disabled={isDisabled}
            type="submit"
            label="Complete"
            buttonStyle={!isDisabled && { backgroundColor: colors.darkBlue }}
          />
        </FormFooter>
      </Form>
    );
  }
}

SignatureForm.propTypes = {
  brand: PropTypes.object,
  documents: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  isSigning: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  signDocumentScreen: PropTypes.object.isRequired,
  setSignDocumentScaleRatio: PropTypes.func.isRequired,
  currentRecipient: PropTypes.number.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

SignatureForm.defaultProps = {
  brand: {},
  isSigning: false,
};

const FormFooter = styled.div`
  margin-top: 1rem;
  padding: 1rem 0.5rem;
  text-align: right;
  @media (max-width: 960px) {
    width: 100%;
    text-align: center;
    padding: 0rem;
    margin-top: 0rem;
    padding-bottom: 60px;
  }
`;

const mapStateToProps = state => ({
  signatureFormValues: getFormValues('signatureForm')(state),
  signDocumentScreen: state.signDocumentScreen,
});

const mapDispatchToProps = () => ({
  ...SignDocumentScreenActions,
});

export default connect(mapStateToProps, mapDispatchToProps())(reduxForm({
  form: 'signatureForm',
  enableReinitialize: true,
  pure: true,
})(SignatureForm));
