import * as auth from './auth';
import * as templateScreen from './templateScreen';
import templateEditorScreen from './templateEditorScreen';
import * as sendDocumentScreen from './sendDocumentScreen';
import * as filesManager from './filesManager';
import * as documentActions from './documentActions';
import fields from './fields';
import * as documentManagerScreen from './documentManagerScreen';
import * as password from './password';
import * as uploadAndArchive from './uploadAndArchive';
import * as sidenav from './sidenav';
import * as signs from './signs';
import * as organizationsScreen from './organizationsScreen';
import * as userProfileScreen from './userProfileScreen';
import * as organization from './organization';
import * as apiAndKeysSection from './apiAndKeys';
import * as confirmDialog from './confirmDialog';
import * as loader from './loader';
import * as brandsSection from './brandsSection';
import * as analyticsScreen from './analyticsScreen';
import * as documentDimensions from './documentDimensions';

const actionCreators = {
  ...auth,
  ...templateScreen,
  ...templateEditorScreen,
  ...sendDocumentScreen,
  ...filesManager,
  ...documentActions,
  ...fields,
  ...documentManagerScreen,
  ...password,
  ...uploadAndArchive,
  ...sidenav,
  ...signs,
  ...organizationsScreen,
  ...userProfileScreen,
  ...organization,
  ...apiAndKeysSection,
  ...confirmDialog,
  ...loader,
  ...brandsSection,
  ...analyticsScreen,
  ...documentDimensions,
};

export default actionCreators;
