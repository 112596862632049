import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CardNumberElement, CardCvcElement, CardExpiryElement, injectStripe } from 'react-stripe-elements';
import { Form, withFormik } from 'formik';
import { TextField, RaisedButton, FlatButton } from 'material-ui';
import { Tooltip } from '@material-ui/core';
import * as Yup from 'yup';
import hintIcon from '../../../assets/images/hint-icon.svg';
import SubscriptionCard from '../../SubscriptionCard';
import lockIcon from '../../../assets/images/lock-icon.svg';
import { colors } from '../../../utils';

const SignUpSchema = Yup.object().shape({
  name: Yup.string().ensure()
    .required('Required'),
  cardNumber: Yup.string().matches(/^(\d{4}[- ]){3}\d{4}|\d{16}$/, 'Must be a valid credit card number.').ensure().required('Required'),
  month: Yup.string().ensure()
    .required('Required'),
  year: Yup.string().ensure()
    .required('Required'),
  cvv: Yup.string().min(3, 'Must be more than 3 digits.').max(4, 'Must be less than 4 digits.').ensure()
    .required('Required'),
});

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      focusedField: '',
      validCardNumber: false,
      validExpiration: false,
      validCvc: false,
    };
  }

  setValue = (field, value) => {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldTouched(field, true, false);
    setFieldValue(field, value);
  }

  async submit() {
    const { onPay } = this.props;
    const { token } = await this.props.stripe.createToken({ name: 'Name' });
    onPay(token);
  }

  renderForm = () => {
    const {
      values, errors, touched, onClickContactUs,
    } = this.props;
    const { focusedField } = this.state;

    return (
      <StyledForm>
        <FormTitle>Enter your payment information</FormTitle>
        <TextField
          fullWidth
          value={values.name}
          onChange={e => this.setValue('name', e.target.value)}
          errorText={touched.name && errors.name}
          floatingLabelText="Name on Card *"
        />
        <InputContainer focused={focusedField === 'cardNumber'}>
          <div className="header">Card Number*</div>
          <CardNumberElement
            onChange={e => this.setState({ validCardNumber: e.complete })}
            onFocus={() => this.setState({ focusedField: 'cardNumber' })}
            onBlur={() => this.setState({ focusedField: '' })}
          />
        </InputContainer>

        <Stack>
          <InputContainer focused={focusedField === 'cardExpiration'} style={{ marginRight: '0.5rem' }}>
            <div className="header">Expiration Date*</div>
            <CardExpiryElement
              onChange={e => this.setState({ validExpiration: e.complete })}
              onFocus={() => this.setState({ focusedField: 'cardExpiration' })}
              onBlur={() => this.setState({ focusedField: '' })}
            />
          </InputContainer>
          <InputContainer focused={focusedField === 'cardCvc'} style={{ marginLeft: '0.5rem' }}>
            <div className="header">CVC/CVV*</div>
            <CardCvcElement
              onChange={e => this.setState({ validCvc: e.complete })}
              onFocus={() => this.setState({ focusedField: 'cardCvc' })}
              onBlur={() => this.setState({ focusedField: '' })}
            />
            <Tooltip
              title="The last 3 digits on the back of your credit card. Amex will be 4 digits."
            >
              <HintIcon src={hintIcon} />
            </Tooltip>
          </InputContainer>
        </Stack>

        <ContactUsSections>
          <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
            Secure Payment
            <LockIcon src={lockIcon} />
          </div>
          <div>You will be billed montly for your subscription.</div>
          <div>
            Any Questions? &nbsp;
            {/* eslint-disable-next-line */}
            <a style={{ color: '#016D9F' }} href="#" onClick={onClickContactUs}>Contact us!</a>
          </div>
        </ContactUsSections>
      </StyledForm>
    );
  }

  render = () => {
    const {
      values, selectedSubscription, history,
      secondaryActionLabel, primaryActionLabel,
    } = this.props;
    const { validCardNumber, validCvc, validExpiration } = this.state;

    return (
      <>
        <Stack style={{ justifyContent: 'space-between', marginBottom: 45 }}>
          {this.renderForm()}
          <SubscriptionCard
            readOnly
            activeFooter
            actionLabel="Change Plan"
            style={{ width: 256 }}
            onClickAction={() => history.push('/subscription')}
            {...selectedSubscription}
          />
        </Stack>
        <div className="d-flex justify-content-between">
          {secondaryActionLabel ?
            <FlatButton
              primary
              label={secondaryActionLabel}
              style={{ border: '1px solid', borderRadius: 2, width: 122 }}
              onClick={() => history.push('/dashboard')}
            />
            : <div />
          }
          {primaryActionLabel &&
            <RaisedButton
              primary
              label={primaryActionLabel}
              style={{ width: 122 }}
              onClick={this.submit}
              disabled={!values.name || !validCardNumber || !validCvc || !validExpiration}
            />
          }
        </div>
      </>
    );
  }
}

PaymentForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onPay: PropTypes.func,
  onClickContactUs: PropTypes.func,
  selectedSubscription: PropTypes.object,
  stripe: PropTypes.object.isRequired,
  primaryActionLabel: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
};

PaymentForm.defaultProps = {
  selectedSubscription: {},
  onClickContactUs: () => {},
  onPay: () => {},
  secondaryActionLabel: 'Back',
  primaryActionLabel: 'Pay',
};

const StyledForm = styled(Form)`
  width: 560px;
  & > input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Stack = styled.div`
  display: flex;
  position: relative;
`;

const HintIcon = styled.img`
  position: absolute;
  right: 0;
  bottom: 8px;
  cursor: pointer;
`;

const FormTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
`;

const ContactUsSections = styled.div`
  margin-top: 45px;
  font-family: DIN-Medium !important;
  font-size: 14px;
  color: #484848;
`;

const InputContainer = styled.div`
  & > .header {
    font-size: 0.8rem;
    color: ${props => (props.focused ? colors.blue : '#B6B6B6')};
    margin-bottom: 0.5rem;
  }
  margin-top: 2rem;
  width: 100%;
  padding-bottom: 0.4rem;
  border-bottom: ${props => (props.focused ? `2px solid ${colors.blue}` : '1px solid #E0E0E0')};
`;

export const LockIcon = styled.img`
  margin-left: 10px;
`;

export default injectStripe(withFormik({
  validationSchema: SignUpSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) props.onSubmit(values);
  },
})(PaymentForm));
