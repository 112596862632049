import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../utils';

const InfoWidget = props => (
  <Container {...props}>
    <LargeText>
      {props.largeText}
    </LargeText>
    <SmallText>
      {props.smallText}
    </SmallText>
  </Container>
);

InfoWidget.propTypes = {
  largeText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  smallText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const Container = styled.div``;

const LargeText = styled.div`
  width: 100%;
  height: 2.5rem;
  font-size: 2rem;
`;

const SmallText = styled.div`
  width: 100%;
  font-size: 0.875rem;
  color: ${colors.textLight};
`;

export default InfoWidget;
