import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from 'material-ui/TextField';
import NextPageIcon from '../../assets/images/next-page-icon.svg';
import PreviousPageIcon from '../../assets/images/previous-page-icon.svg';
import ZoomInIcon from '../../assets/images/zoom-in-icon.svg';
import ZoomOutIcon from '../../assets/images/zoom-out-icon.svg';

const DocumentEditorToolBar = ({
  onClickNext, onChangePage, onClickPrev, currentPage,
  totalPages, onClickZoomIn, onClickZoomOut,
}) => {
  const [page, setPage] = useState('0');
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (!editing && page !== currentPage) {
      setPage(currentPage);
    }
  });

  const handleChange = () => {
    if (page > 0 && page <= totalPages) {
      setEditing(false);
      onChangePage(page);
    } else {
      setPage(currentPage);
    }
  };

  return (
    <Container>
      <PageControlContainer>
        <IconContainer
          onClick={() => onClickNext(() => setPage(page + 1))}
          src={NextPageIcon}
        />
        <IconContainer
          onClick={() => onClickPrev(() => setPage(page - 1))}
          src={PreviousPageIcon}
        />
      </PageControlContainer>
      <PageNumberContainer>
        <TextField
          onBlur={handleChange}
          onChange={(e, val) => {
            setEditing(true);
            setPage(val);
          }}
          value={page}
          style={{ width: '45%' }}
        />
        <span>{`/ ${totalPages}`}</span>
        <Divider />
      </PageNumberContainer>
      <ZoomContainer>
        <IconContainer onClick={onClickZoomIn} src={ZoomInIcon} />
        <IconContainer onClick={onClickZoomOut} src={ZoomOutIcon} />
      </ZoomContainer>
    </Container>
  );
};

DocumentEditorToolBar.propTypes = {
  onClickNext: PropTypes.func,
  onClickPrev: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onChangePage: PropTypes.func,
  onClickZoomIn: PropTypes.func,
  onClickZoomOut: PropTypes.func,
};

DocumentEditorToolBar.defaultProps = {
  onClickNext: () => {},
  onClickPrev: () => {},
  currentPage: 1,
  totalPages: 1,
  onChangePage: () => {},
  onClickZoomIn: () => {},
  onClickZoomOut: () => {},
};

const Container = styled.div`
  width: 18rem;
  height: 3rem;
  background-color: #434343;
  border-radius: 2px;
  display: flex;
  @media (orientation: portrait) and (max-width: 768px) {
    width: 12rem;
  }
`;

const IconContainer = styled.img`
  width: 1rem;
  height:auto;
  margin-left: 0.7rem;
  position: relative;
  top: 0.5rem;
  display: inline-block;
  cursor: pointer;
  @media (orientation: portrait) and (max-width: 768px) {
    width: 2rem;
  }
`;

const PageControlContainer = styled.div`
  width: 33%;
  display: inline-block;
  text-align: center;
  @media (orientation: portrait) and (max-width: 768px) {
    width: 50%;
  }
`;

const PageNumberContainer = styled.div`
  width: 34%;
  display: flex;
  color: white;
  @media (orientation: portrait) and (max-width: 768px) {
    width: 50%;
  }

  & > span {
    position: relative;
    padding-left: 0.4rem;
    top: 0.75rem;
    font-size: 0.9rem;
    width: 4rem;
    letter-spacing: 0.1rem;
  }

  & > div > input {
    background-color: #7F7F7F !important;
    height: 2rem !important;
    top: 0.5rem;
    border-radius: 3px;
    color: white !important;
    text-align: right;
    padding-right: 0.2rem !important;
  }

  & > div > div {
    display: none;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 2rem;
  margin-left: 1rem;
  position: relative;
  margin-top: 0.5rem;
  background-color: white;
  @media (orientation: portrait) and (max-width: 768px) {
    display: none;
  }
`;


const ZoomContainer = styled.div`
  width: 33%;
  display: inline-block;
  text-align: left;
  padding-left: 2%;
  @media (orientation: portrait) and (max-width: 768px) {
    display: none;
  }
`;

export default DocumentEditorToolBar;
