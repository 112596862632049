
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Menu, MenuItem, Popover } from 'material-ui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import NotificationsIcon from '../assets/images/notifications-icon.png';
import ArrowDownIcon from '../assets/images/arrow_down.svg';
import ArrowRightIcon from '../assets/images/arrow_right.svg';
import ErrorIcon from '../assets/images/Shape.png';
import Logo from '../assets/logo-closed.png';
import organizationActions from '../actions/organization';
import brandsActions from '../actions/brandsSection';
import authActions from '../actions/auth';
import subscriptionsActions from '../actions/subscriptions';
import { spacing, colors, getUserOrganizations } from '../utils';

class AppHeader extends React.Component {
  state = {
    profilePopoverOpen: false,
    profileAnchorEl: null,
    organizationPopoverOpen: false,
    organizationAnchorEl: null,
  };

  componentDidMount = async () => {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ organizationAnchorEl: this.organizationWrapperRef });
  }

  organizationWrapperRef = null;

  handleOrganizationChange = (organization) => {
    const { fetchBrands, setCurrentOrganization } = this.props;
    setCurrentOrganization(organization);
    fetchBrands();
    this.setState({
      organizationPopoverOpen: false,
    });
  }

  toggleProfilePopover = (e) => {
    this.setState({
      profilePopoverOpen: !this.state.profilePopoverOpen,
      profileAnchorEl: e.target,
    });
  };

  toggleOrganizationPopover = () => this.setState({
    organizationPopoverOpen: !this.state.organizationPopoverOpen,
  });

  renderOrganizationsMenuItems = organizations => (
    <>
      <MenuItem onClick={() => this.handleOrganizationChange(null)}>
        No Organization
      </MenuItem>
      {organizations.map(organization => (
        <MenuItem
          onClick={() => this.handleOrganizationChange(organization)}
          key={organization.uuid}
        >
          <CircleDiv size={28}>
            <div>{organization.name[0].toUpperCase()}</div>
          </CircleDiv>
          {organization.name}
        </MenuItem>
      ))}
    </>
  );

  renderPaymentMethodProblem = () => (
    <PaymentErrorContainer>
      <img alt="error-icon" style={{ marginRight: 8 }} src={ErrorIcon} width={16} height={16} />
      There Is A Problem With Your Payment Method.
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={() => this.props.setOpenErrorCreditCardModal(true)} style={{ margin: '0 8px', textDecoration: 'underline', cursor: 'pointer' }}> Fix it</div>
    </PaymentErrorContainer>
  )

  render() {
    const {
      organizations, withLogo, onClickLogo, history, logout,
      auth: { user }, openSidenav,
    } = this.props;
    const { currentOrganization, canChange } = organizations;
    const userOrganizations = getUserOrganizations(user, user.organizations);
    const paddingLeft = openSidenav
      ? spacing.sidenavWidth
      : spacing.sidenavWidthCollapsed;

    const haveNotifications = user.pendingInvites.nodes.length > 0;

    return (
      <Container
        className="d-flex align-items-center justify-content-between"
        zDepth={2}
        withLogo={withLogo}
        paddingLeft={paddingLeft}
        hasOrg={!isEmpty(userOrganizations)}
      >
        <div>
          <OrganizationWrapper>
            {withLogo && <LogoImage onClick={onClickLogo} />}
            <OrganizationLabel>Current Organization:</OrganizationLabel>
            <CurrentOrganizationName
              canChange={canChange}
              onClick={canChange && this.toggleOrganizationPopover}
            >
              <div style={{ paddingTop: '3px' }}>
                {(currentOrganization || {}).name || 'No Organization'}
              </div>
              {canChange && (
                <DropDownIcon>
                  {this.state.organizationPopoverOpen
                    ? <img src={ArrowDownIcon} style={{ width: '10px', margin: '8px 8px 6px 6px' }} alt="" />
                    : <img src={ArrowRightIcon} style={{ width: '24px' }} alt="" />
                  }
                </DropDownIcon>
              )}
              <div
                ref={(ref) => { this.organizationWrapperRef = ref; }}
                style={{ position: 'absolute', left: '0', bottom: '0' }}
              />
            </CurrentOrganizationName>
          </OrganizationWrapper>

          <Popover
            open={this.state.organizationPopoverOpen}
            anchorEl={this.state.organizationAnchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={() => this.setState({ organizationPopoverOpen: false })}
          >
            <Menu>
              {this.renderOrganizationsMenuItems(userOrganizations)}
            </Menu>
          </Popover>
        </div>

        {user.paymentIssue && user.downgradeAt && this.renderPaymentMethodProblem()}

        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div style={{ marginRight: '1.5rem', cursor: 'pointer' }} onClick={() => history.push('/notifications')}>
            {haveNotifications && <RedDot />}
            <NotificationImage haveNotifications={haveNotifications} src={NotificationsIcon} style={{ width: '24px' }} alt="" />
          </div>
          <ProfileLabel className="d-flex" onClick={this.toggleProfilePopover}>
            <div className="text-truncate" style={{ maxWidth: 200 }}>
              {`Hi, ${user.firstName}`}
            </div>
            {this.state.profilePopoverOpen
              ? <img src={ArrowDownIcon} style={{ width: '10px', margin: '8px 8px 6px 6px' }} alt="" />
              : <img src={ArrowRightIcon} style={{ width: '24px' }} alt="" />
            }
          </ProfileLabel>

          <Popover
            open={this.state.profilePopoverOpen}
            anchorEl={this.state.profileAnchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={() => this.setState({ profilePopoverOpen: false })}
          >
            <Menu>
              <MenuItem
                value={1}
                primaryText="My Profile"
                onClick={() => {
                  this.setState({ profilePopoverOpen: false });
                  history.push('/profile');
                }}
              />
              <MenuItem value={2} primaryText="Log Out" onClick={() => logout()} />
            </Menu>
          </Popover>
        </div>
      </Container>
    );
  }
}

AppHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  openSidenav: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  setCurrentOrganization: PropTypes.func.isRequired,
  setOpenErrorCreditCardModal: PropTypes.func.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  withLogo: PropTypes.bool,
  onClickLogo: PropTypes.func,
};

AppHeader.defaultProps = {
  withLogo: false,
  onClickLogo: null,
};

const Container = styled.div`
  background-color: ${colors.backgroundGray};
  z-index: 5;
  overflow: visible;
  justify-content: space-between;
  padding: 26px 2.5rem 18px ${p => (p.withLogo ? 24 : 56 + p.paddingLeft)}px;
  font-family: DIN-Medium;
  position: ${p => (!p.withLogo ? 'fixed' : 'relative')};
  top: 0;
  width: 100%;
`;

const RedDot = styled.div`
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
  background-color: red;
  position:relative;
  left: 1rem;
  margin-bottom: -0.65rem;
`;

const NotificationImage = styled.img`
  filter: ${({ haveNotifications }) => !haveNotifications && 'brightness(0)'};
  opacity: ${({ haveNotifications }) => !haveNotifications && '0.5'};
`;

const OrganizationLabel = styled.div`
  font-size: 13px;
  color: #333E48;
  opacity: 0.55;
`;

const ProfileLabel = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: #000000;
  background-color: #FFFFFF;
  height: 26px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  padding-top: 3px;
  padding-left: 15px;
  padding-right: 5px;
`;

const CircleDiv = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: ${colors.defaultImageGray};
  border-radius: 50%;
  color: white;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  & > div {
    margin-top: ${props => !props.withPaddingTop && -2}px;
    font-size: ${props => props.size / 2}px;
    line-height: ${props => props.size + Math.abs((props.withPaddingTop || -2) * 2)}px;
  }
`;

const OrganizationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CurrentOrganizationName = styled.div`
  background-color: ${colors.blue};
  color: #fff;
  border-radius: 2px;
  text-align: center;
  margin-left: 1rem;
  padding-left: 15px;
  padding-right: ${p => (p.canChange ? 5 : 15)}px;
  font-size: 14px;
  position: relative;
  height: 26px;
  cursor: pointer;
  & > div {
    position: relative;
    display: inline-block;
    & > img {
      filter: invert(1);
    }
  }
`;

const DropDownIcon = styled.div``;

const LogoImage = styled.div`
  cursor: ${props => props.onClick && 'pointer'};
  width: 4rem;
  height: 3rem;
  background-image: url(${Logo});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 1rem;
`;

const PaymentErrorContainer = styled.div`
  color: #e02525;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 48px;
  padding: 0px 12px;
  height: 36px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.16), 0 0 1px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;

`;

const mapStateToProps = ({ auth, organizations }) => ({
  auth,
  organizations,
});

const mapDispatchToProps = {
  logout: authActions.logout,
  setCurrentOrganization: organizationActions.setCurrentOrganization,
  fetchBrands: brandsActions.fetchBrands,
  setOpenErrorCreditCardModal: subscriptionsActions.setOpenErrorCreditCardModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader));
