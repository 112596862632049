import gql from 'graphql-tag';

export const FETCH_NOTES = gql`
  query EntityNotes(
    $entityUuid: ID!,
    $entityType: NoteEntityEnum!,
  ){
    entityNotes(
      entityUuid: $entityUuid,
      entityType: $entityType,
    ){
      nodes {
        uuid,
        text,
        entityType,
      }
    }
  }
`;

export default {
  FETCH_NOTES,
};
