import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty, head } from 'lodash';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import { toast } from 'react-toastify';
import { PageContainer, PageHeader, PageContent } from '../components/pages';
import TemplateSignLoader from '../components/signs/TemplateSignLoader';
import Actions from '../actions';
import organizationActions from '../actions/organization';
import fieldsActions from '../actions/fields';
import DocumentsPageLoader from '../components/editor/DocumentsPageLoader';
import Messages from '../constants/toastMessages';
import PageFieldsToolBar from '../components/PageFieldsToolBar';

class FillSignScreen extends React.Component {
  async componentDidMount() {
    this.props.disableOrganizationChange();
    this.props.fetchDocumentDimensions();
    await this.props.resetFillAndSign();
    await this.props.clearTemplateFields();
    await this.props.fetchFields();
    if (this.props.match.params.id && !this.props.signs.documents) {
      this.props.loadFillAndSignDocument(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.enableOrganizationChange();
  }

  saveButton = async () => {
    const { createTemplate, signs: { scaleRatio, documents }, history } = this.props;
    const { uuid } = head(documents);
    try {
      const signedDocument = await createTemplate(uuid, scaleRatio);
      toast.success(Messages.documents.create.success);
      history.push(`/fill-sign/${signedDocument.uuid}/complete`);
    } catch (e) {
      toast.error(e.message);
    }
  }

  renderActions = () => {
    const { signs: { fields } } = this.props;
    const existsEmptySignature = fields.some(f => f.type === 'signature' && isEmpty(f.content));
    return (
      <RaisedButton
        primary
        label="Complete"
        title="Complete"
        onClick={this.saveButton}
        disabled={isEmpty(fields) || existsEmptySignature}
      />
    );
  }


  render() {
    const {
      signs: { documents, imageWidth, scaleRatio },
      setTemplateScaleRatio, auth, standardFields,
      documentDimensions,
    } = this.props;

    return (
      <PageContainer>
        <PageHeader title="Fill & Sign" />

        <PageFieldsToolBar standardFields={standardFields} />
        <PageContainer
          style={{
            maxWidth: imageWidth || 990, width: '100%', margin: 'auto', maxHeight: '600px', overflowY: 'auto',
          }}
        >
          <PageContent>
            <DocumentsPageLoader
              documentDimensions={documentDimensions}
              documents={documents}
              loading={false}
              toolbarPosition="bottom"
              setScaleRatio={setTemplateScaleRatio}
              scaleRatio={scaleRatio}
              imageWidth={imageWidth}
              renderPageLoader={(page, index) => (
                <ContainerDocument>
                  <TemplateSignLoader
                    documentDimensions={documentDimensions}
                    auth={auth}
                    standardFields={standardFields}
                    number={index + 1}
                    page={page}
                    imageWidth={imageWidth}
                    setSignScaleRatio={setTemplateScaleRatio}
                  />
                </ContainerDocument>)}
            />
            <ActionContainer>
              {this.renderActions()}
            </ActionContainer>
          </PageContent>
        </PageContainer>
      </PageContainer>
    );
  }
}

FillSignScreen.propTypes = {
  createTemplate: PropTypes.func.isRequired,
  setTemplateScaleRatio: PropTypes.func.isRequired,
  fetchFields: PropTypes.func.isRequired,
  signs: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadFillAndSignDocument: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  standardFields: PropTypes.array.isRequired,
  clearTemplateFields: PropTypes.func.isRequired,
  resetFillAndSign: PropTypes.func.isRequired,
  disableOrganizationChange: PropTypes.func.isRequired,
  enableOrganizationChange: PropTypes.func.isRequired,
  documentDimensions: PropTypes.object.isRequired,
  fetchDocumentDimensions: PropTypes.func.isRequired,
};

const ContainerDocument = styled.section`
  width: 100%;
  margin-bottom: 4rem;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 4rem;
  right: 2rem;

  & button {
    width: 10rem !important;
    height: 2.5rem !important;
    &:not([disabled]){
      background-color: #246C9E !important;
    }
    button > div {
      width: 10rem !important;
      height: 2.5rem !important;
    }
  }

  @media screen and (min-width: 1600px) {
    right: 5rem;
    bottom: 3rem;

    & button {
      width: 15rem !important;
      height: 3rem !important;
      &:not([disabled]){
        background-color: #246C9E !important;
      }
      button > div {
        width: 15rem !important;
        height: 3rem !important;
      }
    }
  }

  
`;

const mapStateToProps = ({
  auth, form, signs, filesManager, fields: { standardFields }, documentDimensions,
}) => ({
  auth, form, signs, filesManager, standardFields, documentDimensions,
});

const mapDispatchToProps = () => ({
  ...Actions,
  enableOrganizationChange: organizationActions.enableOrganizationChange,
  disableOrganizationChange: organizationActions.disableOrganizationChange,
  fetchFields: fieldsActions.fetchFields,
});

export default connect(mapStateToProps, mapDispatchToProps())(FillSignScreen);

