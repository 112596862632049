import React from 'react';

const inputStyle = {
  width: '100%',
  height: '100%',
};

const Decline = () => (
  <button type="button" style={inputStyle}>Decline</button>
);

export default Decline;
