import ImagesBase64 from './fillSignBase64Images';

export const getInitialImageContent = type => ImagesBase64[type] || '';

export const getInitialFieldDimensions = (field) => {
  const auxfield = {
    ...field,
    width: 160,
    height: (field.type === 'date_picker' || field.type === 'input') ? 25.6 : 51.2,
  };

  if (field.type === 'cross' || field.type === 'check' || field.type === 'dot'
        || field.type === 'line' || field.type === 'circle') {
    auxfield.width = 26.88;
    auxfield.height = 23.04;
    auxfield.content = getInitialImageContent(field.type);
    auxfield.value = getInitialImageContent(field.type);
  }

  return auxfield;
};
