import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import PropTypes from 'prop-types';
import Add from 'material-ui/svg-icons/content/add';
import { colors } from './../utils';

class FileUploadPreview extends React.Component {
  state = { currentImage: null };

  inputRef = null;

  handleChange = ({
    target: { files: [file] },
  }) => {
    const reader = new FileReader();

    this.props.handleChange(this.props.fieldName, file);
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.setState({ currentImage: reader.result });
    };
  };

  renderImageContent = () => (
    <div>
      <ImageContainer
        background={this.state.currentImage || this.props.preload}
        onClick={() => !this.props.disabled && this.inputRef.click()}
      />
      <Icon onClick={this.props.handleRemove}><DeleteIcon /></Icon>
    </div>
  )

  render() {
    return (
      <FilePreviewWrapper>
        <input
          type="file"
          ref={(ref) => {
            this.inputRef = ref;
            return true;
          }}
          onChange={this.handleChange}
          style={{ display: 'none' }}
        />
        <Field component="input" type="hidden" name={this.props.fieldName} />
        {!this.state.currentImage && !this.props.preload && (
          <Placeholder>
            <Add className="mt-4" style={{ width: '36px', height: '36px' }} />
            <PlaceholderText className="mb-2">{this.props.placeholder}</PlaceholderText>
          </Placeholder>
        )}
        {(this.state.currentImage || this.props.preload) && this.renderImageContent()}
      </FilePreviewWrapper>
    );
  }
}

FileUploadPreview.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.any.isRequired,
  preload: PropTypes.any,
  disabled: PropTypes.bool,
};

FileUploadPreview.defaultProps = {
  preload: null,
  disabled: false,
};

const Placeholder = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const PlaceholderText = styled.div`
  text-transform: uppercase;
  font-family: 'DIN-Medium';
  font-size: 14px;
  font-weight: 400;
  color: ${colors.blue};
  text-align: center;
`;

const FilePreviewWrapper = styled.div`
  width: 256px;
  height: 128px !important;
  padding-bottom: 0rem !important;
  margin-bottom: 0rem !important;
  border: 1px solid ${colors.lightGray};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div`
  position: absolute;
  right: 30%;
  top: 3rem;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 127px;
  height: 127px !important;
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  ${props =>
    props.background &&
    `background: url(${props.background}) no-repeat center center; background-size: cover;`};
`;

export default FileUploadPreview;
