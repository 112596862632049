import React, { useState } from 'react';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import MembersSection from './MembersSection';
import Messages from '../../../constants/toastMessages';
import graphqlClient from '../../../config/graphql';
import { RESEND_INVITE } from './queries';
import { cancelInvite, openInviteNewUser } from '../../../actions/organizationsScreen';
import { setUpgradeModal } from '../../../actions/subscriptions';

export const connect = Component => (props) => {
  const [loading, setLoading] = useState(false);

  const resendInvite = async (inviteUuid) => {
    setLoading(true);
    try {
      await graphqlClient(get(props, 'currentOrganization.subdomain')).mutate({
        mutation: RESEND_INVITE,
        variables: { inviteUuid },
      });
      setLoading(false);
      toast.success(Messages.organizations.resendInvite.success);
    } catch (e) {
      setLoading(false);
      toast.error(Messages.organizations.resendInvite.error);
      throw e;
    }
  };

  return (
    <Component
      {...props}
      loading={loading}
      resendInvite={resendInvite}
    />
  );
};

export default RConnect(({
  auth, organizations: { currentOrganization },
}) => ({
  auth, currentOrganization,
}), {
  cancelInvite, openInviteNewUser, setUpgradeModal,
})(connect(MembersSection));
