import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, withFormik } from 'formik';
import { TextField, FlatButton, RaisedButton } from 'material-ui';
import * as Yup from 'yup';

const inputStyle = {
  width: '45%',
  marginBottom: '0.5rem',
};

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string().ensure()
    .required('Required'),
  newPassword: Yup.string().ensure()
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Required'),
});

class ChangePasswordForm extends Component {
  setValue = (field, value) => {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  }

  render = () => {
    const {
      values, errors, touched, onSave, onCancel, isValid,
    } = this.props;

    return (
      <StyledForm>
        <TextField
          value={values.password}
          onChange={e => this.setValue('password', e.target.value)}
          errorText={touched.password && errors.password}
          floatingLabelText="Current Password"
          type="password"
          style={inputStyle}
        />
        <Stack >
          <TextField
            value={values.newPassword}
            onChange={e => this.setValue('newPassword', e.target.value)}
            errorText={touched.newPassword && errors.newPassword}
            floatingLabelText="New Password"
            type="password"
            style={inputStyle}
          />
          <TextField
            value={values.confirmPassword}
            onChange={e => this.setValue('confirmPassword', e.target.value)}
            errorText={touched.confirmPassword && errors.confirmPassword}
            floatingLabelText="Confirm Password"
            type="password"
            style={{ marginLeft: '5%', ...inputStyle }}
          />
        </Stack>
        <ActionsContainer>
          <FlatButton primary onClick={onCancel}>
            Cancel
          </FlatButton>
          <RaisedButton
            primary
            buttonStyle={{ color: 'white' }}
            onClick={() => {
              onSave(values);
              onCancel();
            }}
            disabled={!isValid}
          >
            Save
          </RaisedButton>
        </ActionsContainer>
      </StyledForm>
    );
  }
}

ChangePasswordForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

ChangePasswordForm.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
};

const StyledForm = styled(Form)`
  width: 100%;
`;

const Stack = styled.div`
  display: flex;
  position: relative;
`;

const ActionsContainer = styled.div`
  width: 98%;
  margin-top: 2rem;
  text-align: right;
  & > div {
    margin-left: 1rem;
  }
`;

export default withFormik({
  validationSchema: ChangePasswordSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) props.onSubmit(values);
  },
})(ChangePasswordForm);
