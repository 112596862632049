import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import RenewEnterprisePlanForm from './RenewEnterprisePlanForm';
import stripeApiKey from '../../../constants/stripeKey';

export default props => (
  <StripeProvider apiKey={stripeApiKey}>
    <Elements>
      <RenewEnterprisePlanForm {...props} />
    </Elements>
  </StripeProvider>
);
