import gql from 'graphql-tag';

export const SEND_OWNERSHIP_TRANSFER_INVITE = gql`
  mutation SendOwnershipTransferInvite(
    $recipient: String!,
    $name: String!
    ){
        sendOwnershipTransferInvite(
            recipient: $recipient,
            name: $name
        ){
            invite {
                uuid
            }
         }
    }
`;

export default {
  SEND_OWNERSHIP_TRANSFER_INVITE,
};
