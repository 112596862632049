import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Checkbox, Card, RadioButton, RadioButtonGroup, SelectField,
  MenuItem, DatePicker, FlatButton, RaisedButton,
} from 'material-ui';
import { withFormik } from 'formik';
import { isEmail } from 'validator';
import { uniqBy } from 'lodash';

const reminderFields = {
  setReminder: 'setReminder',
  reminderFrequencyType: 'reminderFrequencyType',
  reminderFrequency: 'reminderFrequency',
  reminderDate: 'reminderDate',
  reminderSendToMe: 'reminderSendToMe',
  reminderMessage: 'reminderMessage',
  reminderRecipients: 'reminderRecipients',
};

const reminderValues = {
  byDate: 'byDate',
  byTimeFrame: 'byTimeFrame',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export class ReminderOptions extends Component {
  state = {
    cardOpen: false,
  }

  setValue = (field, value) => {
    const { setFieldValue, setSendDocumentField } = this.props;
    setFieldValue(field, value);
    setSendDocumentField(field, value);
  }

  handleOnCheck = (value) => {
    const { setSendDocumentField } = this.props;

    setSendDocumentField('openReminderForm', value);
    setSendDocumentField(reminderFields.setReminder, value);
    this.setState({ cardOpen: Boolean(value) });
  }

  handleEmail = (email) => {
    const {
      setSendDocumentField, sendDocumentScreenValues: { reminderRecipients },
    } = this.props;
    const recipients = (reminderRecipients || [])
      .filter(r => r !== email)
      .concat((reminderRecipients || []).includes(email) ? [] : email);

    setSendDocumentField(reminderFields.reminderRecipients, recipients);
  }

  handleSave = () => {
    const { setSendDocumentField } = this.props;

    setSendDocumentField('openReminderForm', undefined);
    this.setState(prevState => ({ cardOpen: !prevState.cardOpen }));
    this.handleReset();
  }

  handleReset = () => {
    const {
      setReminder, reminderFrequencyType, reminderFrequency, ...fields
    } = reminderFields;
    const { byTimeFrame, DAILY } = reminderValues;

    Object.values(fields).forEach(field => this.setValue(field, undefined));
    this.setValue(reminderFrequencyType, byTimeFrame);
    this.setValue(reminderFrequency, DAILY);
  }

  handleChangeFrequency = (e, v) => {
    const [query, value] = v === reminderValues.byTimeFrame
      ? ['#reminder-options-select-frequency > div', reminderValues.byDate]
      : ['#reminder-options-date-picker', reminderValues.byTimeFrame];

    document.querySelector(query).click();
    this.setValue(reminderFields.reminderFrequencyType, value);
  }

  handleOnChangeTextarea = e => this.setValue(reminderFields.reminderMessage, e.target.value)

  renderRecipientsCheckbox = () => {
    const { recipients, reminderRecipients } = this.props.sendDocumentScreenValues;

    return uniqBy(recipients, 'email')
      .filter(r => isEmail(r.email))
      .map(({ email }) => (
        <Checkbox
          key={email}
          label={email}
          checked={(reminderRecipients || []).includes(email)}
          onCheck={() => this.handleEmail(email)}
        />
      ));
  }

  renderCard = values => (
    <Card style={{ position: 'absolute', left: '-360px', top: '-350px' }}>
      <CardContainer>
        <CardTitle>Reminder Options</CardTitle>
        <CardSubtitle>Frequency</CardSubtitle>

        <div className="d-flex">
          <RadioButtonGroup
            name={reminderFields.reminderFrequencyType}
            onChange={this.handleChangeFrequency}
            valueSelected={values.reminderFrequencyType}
          >
            <RadioButton value={reminderValues.byTimeFrame} />
          </RadioButtonGroup>

          <div style={{ marginTop: -11 }}>
            <SelectField
              id="reminder-options-select-frequency"
              hintText="Choose a period"
              value={values.reminderFrequency}
              onChange={(e, i, v) => {
                this.setValue(reminderFields.reminderFrequency, v);
                this.setValue(reminderFields.reminderDate, undefined);
                this.setValue(reminderFields.reminderFrequencyType, reminderValues.byTimeFrame);
              }}
              floatingLabelStyle={{ opacity: 0.38 }}
            >
              <MenuItem value={reminderValues.DAILY} primaryText="Daily until completed" />
              <MenuItem value={reminderValues.WEEKLY} primaryText="Weekly until completed" />
              <MenuItem value={reminderValues.MONTHLY} primaryText="Monthly until completed" />
            </SelectField>
          </div>
        </div>

        <div className="d-flex">
          <RadioButtonGroup
            name={reminderFields.reminderFrequencyType}
            onChange={this.handleChangeFrequency}
            valueSelected={values.reminderFrequencyType}
          >
            <RadioButton value={reminderValues.byDate} />
          </RadioButtonGroup>

          <div style={{ marginTop: -11 }}>
            <DatePicker
              id="reminder-options-date-picker"
              hintText="Choose a date"
              container="inline"
              minDate={new Date()}
              value={values.reminderDate || null}
              onChange={(e, date) => {
                this.setValue(reminderFields.reminderDate, date);
                this.setValue(reminderFields.reminderFrequency, undefined);
                this.setValue(reminderFields.reminderFrequencyType, reminderValues.byDate);
              }}
            />
          </div>
        </div>

        <CardSubtitle>Who would you like to remind?</CardSubtitle>
        <Checkbox
          label="Myself"
          checked={values.reminderSendToMe || false}
          onCheck={() => this.setValue(reminderFields.reminderSendToMe, !values.reminderSendToMe)}
        />
        {this.renderRecipientsCheckbox()}
        <CardSubtitle>
          Would you like to add a message?
          <Cursive>(Optional)</Cursive>
        </CardSubtitle>
        <TextArea
          placeholder="This message will be included in your reminder email."
          value={values.reminderMessage || ''}
          onChange={e => this.setValue(reminderFields.reminderMessage, e.target.value)}
        />
        <ButtonSection>
          <FlatButton
            label="Reset"
            onClick={this.handleReset}
            primary
          />
          <ButtonWrapper>
            <RaisedButton
              label="OK"
              onClick={this.handleSave}
              primary
            />
          </ButtonWrapper>
        </ButtonSection>
      </CardContainer>
    </Card>
  );

  render = () => {
    const { cardOpen } = this.state;
    const { sendDocumentScreenValues: { setReminder }, values, disabled } = this.props;

    return (
      <div className="position-relative">
        <Checkbox
          label="Set Reminder"
          iconStyle={{ marginRight: '30px' }}
          disabled={disabled}
          style={{ width: '200px' }}
          checked={setReminder}
          onCheck={(e, value) => this.handleOnCheck(value)}
        />
        {cardOpen && this.renderCard(values)}
        {cardOpen && <ArrowRight />}
      </div>
    );
  }
}

ReminderOptions.propTypes = {
  sendDocumentScreenValues: PropTypes.object.isRequired,
  setSendDocumentField: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ReminderOptions.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  disabled: false,
};

const CardContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  width: 340px;
  padding: 20px;
`;

const CardTitle = styled.div`
  font-size: 16px;
`;

const CardSubtitle = styled.div`
  font-size: 12px;
  color: black;
  opacity: 0.38;
  margin: 20px 0 20px;
`;

const ArrowRight = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  left: -20px;
  bottom: -5px;
  z-index: 10;
  border-top: 25px solid transparent;
  border-left: 15px solid white;
  border-bottom: 25px solid transparent;
  filter: drop-shadow(1px 1px 1px rgba(0,0,0,.2));
`;

const Cursive = styled.span`
  font-style: italic;
`;

const TextArea = styled.textarea`
  width: 100%;
  font-size: 12px;
  color: gray;
  height: 60px;
`;

const ButtonSection = styled.div`
  position: relative;
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ sendDocumentScreenValues }) => ({ ...sendDocumentScreenValues }),
})(ReminderOptions);
