import React, { Component } from 'react';
import { connect as ReduxConnect } from 'react-redux';
import { toast } from 'react-toastify';
import graphqlClient from '../../../config/graphql';
import { FETCH_TEMPLATES } from './queries';
import sendDocumentScreenActions from '../../../actions/sendDocumentScreen';
import filesManagerActions from '../../../actions/filesManager';
import organizationActions from '../../../actions/organization';
import parseTemplates from './parser';

const renderWrappedComponent = WrappedComponent =>
  class extends Component {
    state = {
      templates: [],
      loading: false,
    }

    componentDidMount = () => {
      this.fetchTemplates();
    }

    fetchTemplates = async () => {
      // eslint-disable-next-line react/prop-types
      const { currentOrganization, setSendDocumentField } = this.props;
      try {
        const {
          data: {
            templates: {
              nodes,
            },
          },
        // eslint-disable-next-line react/prop-types
        } = await graphqlClient(currentOrganization && currentOrganization.subdomain).query({
          query: FETCH_TEMPLATES,
          fetchPolicy: 'network-only',
        }).catch((e) => {
          toast.error(e.message);
        });
        this.setState({ templates: nodes });
        setSendDocumentField('templatesList', nodes);
        return { templates: nodes };
      } catch (e) {
        return {
          templates: null,
        };
      }
    }

    render() {
      const { templates } = this.state;
      return (<WrappedComponent
        templates={parseTemplates(templates)}
        {...this.props}
      />);
    }
  };

const mapStateToProps = ({
  filesManager: {
    template, documentTmp, loading,
  },
  sendDocumentScreen,
  organizations,
}) => ({
  sendDocumentScreen,
  documents: documentTmp,
  template,
  loading,
  currentOrganization: organizations.currentOrganization,
});

const mapDispatchToProps = () => {
  const {
    setTemplateFromList,
    setDocumentTmp,
  } = filesManagerActions;
  const {
    setTemplateForSigRequest,
    toggleUseExistingTemplateModal,
    addSendDocumentDocuments,
    setSendDocumentField,
  } = sendDocumentScreenActions;
  const {
    disableOrganizationChange,
  } = organizationActions;

  return {
    setTemplateFromList,
    setTemplateForSigRequest,
    addSendDocumentDocuments,
    toggleUseExistingTemplateModal,
    setDocumentTmp,
    disableOrganizationChange,
    setSendDocumentField,
  };
};

const connect = WrappedComponent =>
  ReduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
