import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  template: null,
  loading: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.templateEditorScreen.SET_LOADING: {
      const { loading: isLoading, action: actionName } = action;
      const { loading: loadingInState } = state;
      const loading = JSON.parse(JSON.stringify(loadingInState));
      if (!actionName) {
        return {
          ...state,
        };
      }
      if (!isLoading) {
        delete loading[actionName];
      } else {
        loading[actionName] = true;
      }
      return {
        ...state,
        loading,
      };
    }

    case Types.templateEditorScreen.SET_TEMPLATE: {
      const { template } = action;
      return {
        ...state,
        template: { ...template },
      };
    }

    default:
      return state;
  }
};

