import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropTarget } from 'react-dnd';
import { DragItemTypes } from '../../constants';
import './PageField.css';
import { PageField } from '.';
import { requiredFields } from '../../config/editorFields';
import PageFieldDescription from '../PageFieldDescription';

// DnD Spec
const dropTargetSpec = {
  drop(props, monitor, component) {
    const itemType = monitor.getItemType();
    const item = monitor.getItem();
    const offset = monitor.getSourceClientOffset();
    const rect = component.ref.getBoundingClientRect();
    const x = (offset.x - rect.left);
    const y = (offset.y - rect.top);

    switch (itemType) {
      case DragItemTypes.FIELDS_PANEL_OPTION:
        props.onFieldAdd({
          id: (new Date()).getTime(),
          fieldType: item.id,
          x,
          y,
          width: 200,
          height: 20,
          isNew: item.isNew,
          required: requiredFields.includes(item.id),
        });
        break;

      case DragItemTypes.PAGE_FIELD:
        props.onFieldChange({
          ...item,
          x,
          y,
        });
        break;

      default:
        return null;
    }

    return undefined;
  },
};

// Dnd Collector
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class FieldsLayer extends React.Component {
  renderFields() {
    const {
      scaleRatio,
      fields,
      focusedPageField,
      canDragFields,
      recipients,
      imageWidth,
      documentDimensions,
    } = this.props;

    return fields.map(field => (
      <PageField
        documentDimensions={documentDimensions}
        key={field.id || Math.random().toString()}
        scaleRatio={scaleRatio}
        imageWidth={imageWidth}
        recipients={recipients}
        focused={focusedPageField && field.id === focusedPageField.id}
        canDrag={canDragFields}
        field={field}
        onClick={() => this.props.onFieldClick(field)}
        onResizeHandlePress={canDrag => this.props.onResizeHandlePress(canDrag)}
        onResize={resizedField => this.props.onFieldResize(resizedField)}
        onFieldChange={this.props.onFieldChange}
        onRemoveField={this.props.onRemoveField}
        setFieldOptionModalOpen={this.props.setFieldOptionModalOpen}
      />
    ));
  }

  render() {
    const { connectDropTarget, isOver } = this.props;
    return connectDropTarget((
      <span>
        <Container
          isOver={isOver}
          innerRef={(ref) => { this.ref = ref; return true; }}
          onClick={this.props.onClick}
        >
          {this.renderFields()}
        </Container>
        <PageFieldDescription />
      </span>
    ));
  }
}

FieldsLayer.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  scaleRatio: PropTypes.number.isRequired,
  imageWidth: PropTypes.number,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  focusedPageField: PropTypes.object,
  canDragFields: PropTypes.bool.isRequired,
  onFieldAdd: PropTypes.func.isRequired, // eslint-disable-line
  onFieldChange: PropTypes.func.isRequired, // eslint-disable-line
  onFieldClick: PropTypes.func.isRequired,
  onFieldResize: PropTypes.func.isRequired,
  onResizeHandlePress: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  setFieldOptionModalOpen: PropTypes.func.isRequired,
  recipients: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  documentDimensions: PropTypes.object.isRequired,
};

FieldsLayer.defaultProps = {
  focusedPageField: {},
  imageWidth: null,
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, ${props => (props.isOver ? '0.5' : '0')});
  transition: all 0.3s ease-in-out;
`;

export default DropTarget(
  [DragItemTypes.FIELDS_PANEL_OPTION, DragItemTypes.PAGE_FIELD],
  dropTargetSpec,
  collect,
)(FieldsLayer);
