import React from 'react';
import PropTypes from 'prop-types';
import { RaisedButton, Toggle } from 'material-ui';
import AddIcon from 'material-ui/svg-icons/content/add';
import styled from 'styled-components';
import Role from './Role';
import DraggableList from '../DraggableList/DraggableList';
import { Box } from '../../components';
import { colors } from '../../utils';


class RoleList extends React.Component {
  getListToRender = (roles) => {
    const {
      onChangeRole, onRemoveRole,
      shouldValidate,
    } = this.props;
    return roles.map((role, index) => (
      <Role
        roles={roles}
        role={role}
        index={index}
        onChangeRole={onChangeRole}
        onRemoveRole={
          (roles.length === 1) ?
            null : onRemoveRole}
        shouldValidate={shouldValidate}
      />

    ));
  }

  render() {
    const {
      roles, isCompleteInOrderEnabled, onChangeCompleteInOrder,
      onAddRole, onRolesUpdate,
    } = this.props;

    return (
      <Box className="mb-3" title="Roles">
        <Container>
          <Header>
            <span>Complete in order</span>
            <Toggle
              toggled={isCompleteInOrderEnabled}
              style={{ width: '50%' }}
              onToggle={onChangeCompleteInOrder}
            />
          </Header>
          <DraggableList
            componentList={this.getListToRender(roles)}
            list={roles}
            updateList={onRolesUpdate}
          />
          <ButtonContainer>
            <RaisedButton label="Add New" primary style={{ margin: '1rem' }} icon={<AddIcon />} onClick={onAddRole} />
          </ButtonContainer>
        </Container>
      </Box>
    );
  }
}


RoleList.propTypes = {
  roles: PropTypes.array,
  onChangeRole: PropTypes.func,
  onAddRole: PropTypes.func,
  onRemoveRole: PropTypes.func,
  onRolesUpdate: PropTypes.func,
  isCompleteInOrderEnabled: PropTypes.bool,
  onChangeCompleteInOrder: PropTypes.func,
  shouldValidate: PropTypes.bool,
};

RoleList.defaultProps = {
  roles: [],
  onChangeRole: () => {},
  onAddRole: () => {},
  onRemoveRole: () => {},
  onRolesUpdate: () => {},
  isCompleteInOrderEnabled: true,
  onChangeCompleteInOrder: () => {},
  shouldValidate: false,
};

const Header = styled.header`
  width: 100%;
  height: 50px;
  background: ${colors.backgroundGray};
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 0px 1rem;
`;

const Container = styled.section`
  width: 100%;
  height: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;


export default RoleList;
