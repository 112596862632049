import React, { useEffect } from 'react';
import { connect as reduxConnect } from 'react-redux';
import { setCurrentOrganization } from '../../actions/organization';

const renderWrappedComponent = Component => (props) => {
  // eslint-disable-next-line react/prop-types
  const { history, auth, setOrganization } = props;
  useEffect(() => {
    setOrganization(null);
  }, []);

  return (
    <Component
      {...this.props}
      onClose={() => history.push('/')}
      selectedSubscription={auth.selectedSubscription}
    />
  );
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = () => ({
  setOrganization: setCurrentOrganization,
});

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));

export default connect;
