import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, withFormik } from 'formik';
import { TextField, RaisedButton, AutoComplete } from 'material-ui';
import { withStyles } from '@material-ui/core/styles';
import { get, debounce } from 'lodash';
import * as Yup from 'yup';

import TransferOwnershipModal from '../../modals/TransferOrganizationModal';
import InfoTransferOwnershipModal from '../../modals/InfoTransferOrganizationModal';
import { Modal } from '../..';
import googlePlaces from '../../../services/googlePlaces';

const OrganizationProfileSchema = Yup.object().shape({
  name: Yup.string()
    .ensure()
    .required('This field is required'),
  owner: Yup.string()
    .ensure()
    .required('This field is required'),
  city: Yup.string().ensure(),
  zipcode: Yup.string().ensure(),
});

const styles = {
  textField: {
    marginTop: '16px',
  },
};

export const ProfileOrganizationForm = (props) => {
  const {
    values,
    onClickDelete, errors, touched, isOwner, currentUser,
    currentOrganization, leaveOrganization,
  } = props;
  const [openInfoTransferModal, setOpenInfoTransferModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openLeaveOrgModal, setOpenLeaveOrgModal] = useState(false);
  const [transferOrgInProgress, setTransferInProgress] =
    useState(values.ownership_transfer_request_exists);
  const [addressSearchOptions, setAddressSearchOptions] = useState([]);


  const setValue = (field, value) => {
    const {
      setFieldValue, setFieldTouched, onChange,
    } = props;
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
    onChange({ ...values, [field]: value });
  };

  // Debounced address search
  const searchAddress = debounce((value) => {
    googlePlaces.search(value).then((places) => {
      setAddressSearchOptions(places || []);
    });
  }, 1000);

  const geocodeSelectedAddress = (selectedAddress) => {
    setAddressSearchOptions([]);

    googlePlaces.getDetails(selectedAddress.description).then((details) => {
      setValue('street', details.address);
      setValue('zip', details.zip);
      setValue('city', details.city);
      setValue('state', details.state);
    });
  };
  return (
    <StyledForm>
      <FormInner>
        <FormFieldsWrapper>
          <FieldsRow>
            <FieldsRowElement>
              <TextField
                value={values.name}
                disabled={!isOwner}
                onChange={e => setValue('name', e.target.value)}
                floatingLabelText="Organization Name *"
                errorText={touched.name && errors.name}
                fullWidth
                maxLength={40}
              />
            </FieldsRowElement>
            <FieldsRowElement>
              <TextField
                value={values.owner}
                disabled
                onChange={e => setValue('owner', e.target.value)}
                floatingLabelText="Organization Owner *"
                errorText={touched.owner && errors.owner}
                fullWidth
                maxLength={25}
              />
            </FieldsRowElement>
          </FieldsRow>
          <FieldsRow>
            <FieldsRowElement>
              <AutoComplete
                maxLength={90}
                fullWidth
                floatingLabelText="Street Address"
                hintText="Find your address"
                autoComplete={Math.random()}
                dataSource={addressSearchOptions.map(option =>
            ({ text: option.description, value: option }))}
                filter={(searchText, key) =>
            key.toLowerCase().includes(searchText.toLowerCase())}
                searchText={values.street}
                onUpdateInput={(value, dataSource) => {
                  setValue('street', value);
                  searchAddress(value);

                  const selectedAddress = dataSource.find(item => (item.text === value));

                  if (selectedAddress) {
                    geocodeSelectedAddress(selectedAddress.value);
                  }
                }}
                errorText={touched.street && errors.street}
              />
              <TextField
                value={values.state || ''}
                disabled={!isOwner}
                floatingLabelText="Organization State"
                onChange={e => setValue('state', e.target.value)}
                fullWidth
                maxLength={25}
              />
            </FieldsRowElement>
            <FieldsRowElement>
              <TextField
                value={values.city || ''}
                disabled={!isOwner}
                floatingLabelText="Organization City/Town"
                onChange={e => setValue('city', e.target.value)}
                fullWidth
                maxLength={40}
              />
              <TextField
                fullWidth
                value={values.zip}
                onChange={e => setValue('zip', e.target.value)}
                floatingLabelText="Zip Code"
                maxLength={5}
              />
            </FieldsRowElement>
          </FieldsRow>
        </FormFieldsWrapper>
        <FormActionsWrapper>
          {isOwner && !transferOrgInProgress && (
            <RaisedButton
              onClick={() => setOpenInfoTransferModal(true)}
              label="Transfer ownership"
              primary
              buttonStyle={{ padding: '0' }}
            />
          )}
          {isOwner && !transferOrgInProgress && (
            <DeleteButton onClick={() => onClickDelete(values.uuid)}>
              Delete Organization
            </DeleteButton>
          )}
          {!isOwner && (
            <DeleteButton onClick={() => setOpenLeaveOrgModal(true)}>
              Leave Organization
            </DeleteButton>
          )}
        </FormActionsWrapper>
      </FormInner>
      <TransferOwnershipModal
        isOpen={openTransferModal}
        userEmail={currentUser.email}
        onSend={() => setTransferInProgress(true)}
        onClickCancel={() => setOpenTransferModal(false)}
        organization={(currentOrganization || {})}
        organizationSubdomain={(currentOrganization || {}).subdomain}
      />
      <InfoTransferOwnershipModal
        isOpen={openInfoTransferModal}
        onClickCancel={() => setOpenInfoTransferModal(false)}
        onClickNext={() => {
          setOpenInfoTransferModal(false);
          setOpenTransferModal(true);
        }}
      />
      <StyledModal
        open={openLeaveOrgModal}
        title="Leaving Organization"
        content={(
          <div>
            Are you sure you want to leave this organization?
            You will no longer be able to access the organization
            documents and content
          </div>
        )}
        primaryLabel="Leave"
        onPrimaryClick={() => leaveOrganization(values)}
        secondaryLabel="Cancel"
        onSecondaryClick={() => setOpenLeaveOrgModal(false)}
      />
    </StyledForm>
  );
};

ProfileOrganizationForm.propTypes = {
  currentOrganization: PropTypes.object,
  currentUser: PropTypes.object,
  values: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  setFieldTouched: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func,
  isOwner: PropTypes.bool,
  leaveOrganization: PropTypes.func.isRequired,
};

ProfileOrganizationForm.defaultProps = {
  currentOrganization: null,
  onClickDelete: () => {},
  onChange: () => {},
  isOwner: false,
  currentUser: {},
};

const StyledForm = styled(Form)`
  width: 100%;
  margin: 0 auto;
  & > div {
    margin-bottom: 1rem;
  }
`;
const FieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FieldsRowElement = styled('div')`
  width: calc(50% - 25px);
  flex: 0 0 auto;
  & {
    width: calc(50% - 17.5px);
  }
`;
const FormInner = styled('div')`
  display: flex;
`;
const FormFieldsWrapper = styled('div')`
  width: 70%;
`;
const FormActionsWrapper = styled('div')`
  margin-left: 5rem;
  & button > div > div span {
    font-size: 14px !important;
    display: block;
    width: 100%;

    @media (max-width: 1598px) {
      font-size: 12px !important;
    }

    @media (max-width: 1498px) {
      font-size: 10px !important;
    }
  }
`;

const DeleteButton = styled.div`
  color: #E02525;
  font-family: Roboto;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  font-weight: 500;
  margin-top: 1.5rem;
  text-align: center;
`;

const StyledModal = styled(Modal)`
  & > div > div > div {
    padding-top: 1.5rem;
    & > div {
      font-family: DIN-Medium;

    }
  }
  & h3 {
    font-family: DIN-Medium;
  }
`;

const mapPropsToValues = (props) => {
  const owner = get(props, 'initialValues.users', []).find(u => u.role === 'owner') || {};
  const [street, city, state, zip] = get(props, 'initialValues.address', '').split('/-/');

  return ({
    ...props.initialValues,
    owner: owner.full_name || '',
    street,
    city,
    state,
    zip,
  });
};

export default withFormik({
  validationSchema: OrganizationProfileSchema,
  enableReinitialize: true,
  mapPropsToValues,
})(withStyles(styles)(ProfileOrganizationForm));
