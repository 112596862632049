import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { debounce } from 'lodash';

import SearchInput from '../SearchInput';
import SortableTable from '../SortableTable';

const PlatformOrganizationsList = ({
  data, onSearch, loading,
}) => {
  useEffect(() => {
    if (data.length !== 0) return;
    onSearch();
  }, []);

  const orgColumns = [{
    id: 'org-name',
    label: 'Organization Name',
    field: 'name',
    type: 'text',
  }, {
    id: 'org-owner',
    label: 'Organization Owner',
    field: 'owner',
    type: 'text',
  }];
  const organizations = data.map(o => ({
    id: o.id,
    name: o.name,
    owner: (o.users.find(u => u.role === 'owner') || {}).full_name || '-',
  }));

  return (
    <>
      <SearchInput placeholder="Search" searchTermChanged={debounce(onSearch, 350)} />
      <div className="mt-3 w-100">
        <Paper square>
          <TotalResults>
            Total Results: {loading ? '...' : organizations.length}
          </TotalResults>
          <SortableTable
            classes={{ tableRowHead: 'bg-white' }}
            columns={orgColumns}
            data={organizations}
            loading={loading}
          />
        </Paper>
      </div>
    </>
  );
};

PlatformOrganizationsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({
      full_name: PropTypes.string,
    })).isRequired,
  })).isRequired,
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

PlatformOrganizationsList.defaultProps = {
  loading: false,
};

const TotalResults = styled.div`
  text-align: right;
  padding: 20px 24px;
  font-family: Roboto;
  font-size: 15px;
  color: rgba(0,0,0,0.87);
`;

export default PlatformOrganizationsList;
