import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { connect as RConnect } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import PaymentScreen from './PaymentScreen';
import Messages from '../../constants/toastMessages';
import authActions from '../../actions/auth';
import graphqlClient from '../../config/graphql';
import history from '../../config/history';
import subscriptionActions from '../../actions/subscriptions';
import { SUBSCRIBE_USER } from './mutations';
import { parsePlan } from '../../utils/parsers/subscriptionParser';

export const subscribeUser = async variables => graphqlClient().mutate({
  mutation: SUBSCRIBE_USER,
  variables,
});

const connect = Component => (props) => {
  const {
    /* eslint-disable react/prop-types */
    user, user: { email, currentPlan },
    selectedSubscription, setSelectedSubscription, setCurrentUser,
    setUpgradeModal, plans, location, fetchPlans,
    /* eslint-enable */
  } = props;
  const { plan, interval } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [loading, setLoading] = useState(true);

  const findSelectedSubscription = async () => {
    const allPlans = plans.length === 0 ? await fetchPlans() : plans;
    const foundPlan = allPlans.find(p => p.planType === plan);
    if (!foundPlan) return {};

    const info = get(foundPlan, `plansByInterval.${interval}`);
    const fields = info ? { uuid: info.uuid, amount: info.amount, interval } : {};
    const subscription = { ...foundPlan, ...fields };
    if (subscription.id) await setSelectedSubscription(subscription);
    return subscription;
  };

  const bootstrap = async () => {
    setUpgradeModal(false);
    const subscription = !selectedSubscription.id && plan
      ? findSelectedSubscription()
      : selectedSubscription;

    if (!subscription.id || get(subscription, 'planType') === 'FREE') {
      history.push('/subscription');
    }
    setLoading(false);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    if (selectedSubscription.planType === currentPlan.planType
        && selectedSubscription.interval === currentPlan.interval) {
      history.push('/subscription');
    }
  }, [selectedSubscription.id, currentPlan.id]);

  const handleSubscripton = async (cardToken, planUuid) => {
    try {
      await subscribeUser({ cardToken, planUuid });
      const updatedPlan = await parsePlan(selectedSubscription);
      const updatedUser = await authActions.fetchCurrentUser();
      toast.success(Messages.subscription.success);
      setCurrentUser({ ...user, ...updatedUser, currentPlan: updatedPlan });
      setSelectedSubscription({});
      localStorage.removeItem('defaultPlan');
      history.push('/');
    } catch (e) {
      toast.error(Messages.subscription.error);
    }
  };

  const onClickContactUs = () => {
    window.location.href = `${process.env.REACT_APP_FRONT_PAGE_URL}/contact-us.html?email=${email}`;
  };

  const componentProps = {
    ...props,
    loading,
    subscribeUser: handleSubscripton,
    onClickContactUs,
    selectedSubscription,
  };

  return <Component {...componentProps} />;
};

export default RConnect(({ subscriptions, auth }) => ({
  ...subscriptions, user: auth.user,
}), {
  ...subscriptionActions,
  setCurrentUser: authActions.setCurrentUser,
})(connect(PaymentScreen));
