import moment from 'moment-timezone';
import { isInteger } from 'lodash';
import validator from 'validator';
import serverTimeZone from '../serverTimeZone';

export const sendDocumentParser = {
  parseReminderToRequest: (reminderFrequency, date, times) => {
    const now = date ? moment(date) : moment();
    const nowInServer = moment.tz(now, serverTimeZone);
    nowInServer.hour(9);
    nowInServer.minute(0);
    nowInServer.second(0);
    const parsedReminder = {
      every: reminderFrequency,
      day: [nowInServer.day()],
      at: [[nowInServer.hour(), nowInServer.minute(), nowInServer.second()]],
      on: nowInServer.format('dddd').toLowerCase(),
      total: (times && isInteger(times)) ? times : undefined,
    };
    return JSON.stringify(parsedReminder);
  },
  parseCCToRequest: cc => cc.split(',')
    .map(element => element.trim())
    .filter(element => validator.isEmail(element))
    .map(email => ({
      name: email,
      email,
    })),
  parseDataFromDocumentToRequest: ({
    documents,
    subject,
    message,
    recipients,
    completeInOrder,
    cc,
    password,
    brandUuid,
  }) => ({
    subject: subject.value,
    message,
    brandUuid,
    copiedRecipientsAttributes: sendDocumentParser.parseCCToRequest(cc),
    completeInAnyOrder: !completeInOrder,
    documentUuids: documents.map(v => v.uuid),
    recipientsAttributes: recipients.map(({ email }, index) => ({
      role: email, email, signingOrder: completeInOrder ? index + 1 : 1,
    })),
    password,
  }),
  parseDataFromTemplateToRequest: ({
    templateId,
    subject,
    message,
    recipients,
    completeInOrder,
    cc,
    password,
    brandUuid,
  }) => ({
    templateUuid: templateId,
    subject: subject.value,
    message,
    brandUuid,
    copiedRecipientsAttributes: sendDocumentParser.parseCCToRequest(cc),
    completeInAnyOrder: !completeInOrder,
    recipientsAttributes: recipients.map(({ email, role }, index) => ({
      role: role || undefined,
      email,
      signingOrder: completeInOrder ? index + 1 : 1,
    })),
    password,
  }),
};

export default sendDocumentParser;
