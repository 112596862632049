// import { IMAGE_WIDTH } from '../constants/pageFields';

/**
 * Returns a scaled value.
 * Mainly used to scale coordinates and dimensions based on a scale ratio.
 * @param {number} value
 * @param {number} scaleRatio
 */
export function scaleValue(value, scaleRatio) {
  return (value * scaleRatio);
}

/**
 * Returns a unscaled value.
 * Mainly used to unscale coordinates and dimensions based on a scale ratio.
 * @param {number} value
 * @param {number} scaleRatio
 */
export function unscaleValue(value, scaleRatio) {
  return (value / scaleRatio);
}

/**
 * Scales the dimensions and position of a page field
 * @param {object} pageField
 * @param {number} scaleRatio
 */
export function scalePageField(pageField, scaleRatio) {
  const scaledPageField = { ...pageField };
  scaledPageField.x *= scaleRatio;
  scaledPageField.y *= scaleRatio;
  scaledPageField.width *= scaleRatio;
  scaledPageField.height *= scaleRatio;
  return scaledPageField;
}

/**
 * Unscales the dimensions and position of a page field
 * @param {object} pageField
 * @param {number} scaleRatio
 */
export function unscalePageField(pageField, scaleRatio = 1) {
  const scaledPageField = { ...pageField };
  scaledPageField.x /= scaleRatio;
  scaledPageField.y /= scaleRatio;
  scaledPageField.width /= scaleRatio;
  scaledPageField.height /= scaleRatio;
  return scaledPageField;
}

/**
 * Returns a scaled array.
 * Mainly used to scale coordinates and dimensions based on a scale ratio.
 * @param {Array} values
 * @param {number} scaleRatio
 */
export function scaleValues(values, scaleRatio) {
  if (!values || values.length === 0) {
    return [];
  }
  return values.map(value => scaleValue(value, scaleRatio));
}

/**
 * Returns a unscaled array.
 * Mainly used to unscale coordinates and dimensions based on a scale ratio.
 * @param {Array} values
 * @param {number} scaleRatio
 */
export function unscaleValues(values, scaleRatio) {
  if (!values || values.length === 0) {
    return [];
  }
  return values.map(value => unscaleValue(value, scaleRatio));
}


/**
 * Returns a scaled value used only for the Page Fields styles. Example font size, etc.
 * @param {number} value
 * @param {number} scaleRatio
 * @param {number} constantRelatedValue
 * @param {number} constantValue
 * @return {number} scaled value
 *
 */
export function scaleValueWithConstant(
  value, scaleRatio = 1,
) {
  return (value * scaleRatio);
}

/**
 * Returns a reduced scaleRatio to control visual scaling aspects like fontSizes etc.
 * @param {number} scaleRatio
 * @param {number} decreaseRatio
 * @return {number} reduced scaleRatio
 *
 */

export const decreasedScaleRatio = (scaleRatio = 1, decreaseRatio = 1) =>
  ((scaleRatio - 1) * decreaseRatio) + 1;

export default {
  scaleValue,
  unscaleValue,
  scalePageField,
  unscalePageField,
  scaleValues,
  unscaleValues,
  scaleValueWithConstant,
  decreasedScaleRatio,
};
