import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import CustomFieldForm from '../../forms/CustomFieldForm';
import Modal from '../../Modal';
import Actions from '../../../actions';

const CustomFieldModal = ({
  dismiss, open, changeCustomFormFieldValue,
  customFieldFormData, deleteCustomField,
  updateCustomField, createCustomField,
}) => {
  const onClickDeleteCustomField = () => {
    deleteCustomField(customFieldFormData.id);
    dismiss();
  };

  const onClickSaveCustomField = () => {
    if (customFieldFormData.id) updateCustomField(customFieldFormData);
    else createCustomField(customFieldFormData);
    dismiss();
  };

  return (
    <Modal
      title={customFieldFormData.id ? 'Edit Custom Field' : 'Add Custom Field'}
      content={<CustomFieldForm changeCustomFormFieldValue={changeCustomFormFieldValue} />}
      onPrimaryClick={onClickSaveCustomField}
      onSecondaryClick={() => dismiss()}
      onTertiaryClick={onClickDeleteCustomField}
      tertiaryDisabled={!customFieldFormData.id}
      open={open}
    />
  );
};

CustomFieldModal.propTypes = {
  customFieldFormData: PropTypes.object.isRequired,
  updateCustomField: PropTypes.func.isRequired,
  createCustomField: PropTypes.func.isRequired,
  deleteCustomField: PropTypes.func.isRequired,
  changeCustomFormFieldValue: PropTypes.func.isRequired,
  open: PropTypes.bool,
  dismiss: PropTypes.func.isRequired,
};

CustomFieldModal.defaultProps = {
  open: false,
};

const selector = formValueSelector('customFieldForm');

const mapStateToProps = state => ({
  customFieldFormData: selector(state, 'id', 'name', 'type'),
});

export default connect(mapStateToProps, Actions)(CustomFieldModal);
