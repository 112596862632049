import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress } from 'material-ui';
import BottomScrollListener from 'react-bottom-scroll-listener';
import Box from '../Box';
import TableModuleItem from './TableModuleItem';

class TableModule extends Component {
  state={};

  renderTitle = (pendingElements, title, onClickTitle, loading) => (
    <TitleContainer onClick={onClickTitle}>
      <Number>
        {!loading ? pendingElements : ''}
      </Number>
      <Title>
        {title}
      </Title>
    </TitleContainer>
  )

  renderContent = (elements, onClickButton, buttonLabel, loading, emptyMessage) => {
    const { onScrollEnd, loadingMore } = this.props;
    if (loading) {
      return <CenteredContent><CircularProgress size={60} thickness={2} /></CenteredContent>;
    }

    return (
      <BottomScrollListener onBottom={onScrollEnd}>
        {scrollRef => (
          <Content innerRef={scrollRef}>
            {
              elements && elements.length > 0 ?
                elements.map(element =>
                  (<TableModuleItem
                    key={element.uuid}
                    buttonLabel={buttonLabel}
                    element={element}
                    onClickButton={() => { onClickButton(element); }}
                  />))
                :
                (
                  <CenteredContent>
                    { emptyMessage }
                  </CenteredContent>
                )
            }
            {
              loadingMore &&
              <LoadingContainer><CircularProgress size={25} thickness={2} /></LoadingContainer>
            }
          </Content>
        )}
      </BottomScrollListener>
    );
  }

  render() {
    const {
      elements, title, buttonLabel, onClickButton,
      emptyMessage, loading, onClickTitle,
    } = this.props;
    return (
      <Box title={this.renderTitle(elements.length, title, onClickTitle, loading)}>
        {this.renderContent(elements, onClickButton, buttonLabel, loading, emptyMessage)}
      </Box>
    );
  }
}

TableModule.propTypes = {
  title: PropTypes.string.isRequired,
  elements: PropTypes.array.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClickButton: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onClickTitle: PropTypes.func,
  onScrollEnd: PropTypes.func,
};

TableModule.defaultProps = {
  emptyMessage: '',
  loading: false,
  loadingMore: false,
  onClickTitle: () => {},
  onScrollEnd: () => {},
};


export default TableModule;

const Content = styled.div`
  width: 100%;
  height: 225px;
  overflow-y: auto;
  * {
    font-family: Roboto;
  }
`;

const CenteredContent = styled(Content)`
  text-align: center;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  padding-top: 0.875rem;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: DIN-Bold;
  font-size: 20px;
  color: rgba(0,0,0,0.87);
`;

const Title = styled.div`
  flex: 10;
`;

const Number = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #333E48;
  padding-right: 1rem;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
`;
