import api from './api';

const signatureRequest = {
  sendSignatureRequest: uuid => api.post(`/signature_requests/${uuid}/invite`),
  getSignatureRequest: uuid => api.get(`/signature_requests/${uuid}`),
  updateSignatureRequest: (uuid, sigRequest) => api.put(`/signature_requests/${uuid}`, { signature_request: sigRequest }),
  share: (uuid, payload) => api.post(`/signature_requests/${uuid}/email`, payload),
  sendReminderNow: (uuid, emails, message) => api.post(`/signature_requests/${uuid}/reminders/send_now`, { emails, message }),
};

export default signatureRequest;
