import React from 'react';
import styled from 'styled-components';
import { CircularProgress, Paper, RaisedButton } from 'material-ui';
import PropTypes from 'prop-types';
import { animations } from '../../utils';
import connect from './connect';

const LoadingSpinner = () => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{ minWidth: 590, minHeight: 240 }}
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

const renderErrorContent = (history, error) => (
  <Wrapper>
    <TitleContainer>
      <Title>There was a Problem</Title>
      <Description>
        {error === 'different account' ?
          'This account is not related to this scheduled report. Please make sure you are in the correct account'
          :
          'An error occurred while stopping the scheduled report. Please try again later'}
      </Description>
    </TitleContainer>
    <ButtonsSection>
      <RaisedButton
        label="Dashboard"
        onClick={() => history.push('/')}
        buttonStyle={{ width: '12rem' }}
        primary
      />
    </ButtonsSection>
  </Wrapper>
);

const StopScheduledReportScreen = ({ history, error }) => (
  <Container className="d-flex justify-content-center align-items-center">
    <Box zDepth={3}>
      <Content className="d-flex flex-column align-items-center">
        {!error ? <LoadingSpinner /> : renderErrorContent(history, error)}
      </Content>
    </Box>
  </Container>
);

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;

const Box = styled(Paper)`
  width: 999px;
  height: 650px;
  animation: ${animations.fadeIn} 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 113px 40px;
`;

const Content = styled.div`
  font-family: DIN-Medium;
`;

const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
padding: 0 40px;
`;

const TitleContainer = styled.div`
  text-align: center;
  font-family: DIN-Medium;
`;

const Title = styled.div`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;

const Description = styled.div`
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 4rem;
`;

const ButtonsSection = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

StopScheduledReportScreen.propTypes = {
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
};

StopScheduledReportScreen.defaultProps = {
  error: '',
};

export default connect(StopScheduledReportScreen);
