import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Message = (props) => {
  const { message, style } = props;
  return (
    <Container>
      <Content style={style}>
        {message}
      </Content>
    </Container>
  );
};


Message.propTypes = {
  message: PropTypes.string.isRequired,
  style: PropTypes.object,
};

Message.defaultProps = {
  style: {},
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.h3`
  padding: 4rem 2rem;
  text-align: center;
`;


export default Message;
