import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import styled from 'styled-components';
import SaveButton from 'material-ui/svg-icons/content/archive';

import { parserQueryString, colors } from '../utils';

import Actions from '../actions';

class DocumentPreviewScreen extends React.Component {
  state = {
    window: null,
    document: null,
    file: null,
  }

  componentWillMount() {
    this.setState({ window });
  }

  componentDidMount() {
    const parser = this.parseURL();
    const { getDocument, downloadDocument } = this.props;

    getDocument(parser);
    downloadDocument(parser);
  }

  componentWillReceiveProps({ document, file }) {
    if (!(isEmpty(document)) && !(isEqual(this.props.document, document))) {
      this.setState({ document: document.document_preview });
    }

    if (!(isEmpty(file)) && !(isEqual(this.props.file, file))) {
      this.setState({ file });
    }
  }

  parseURL = () => {
    const { location } = this.state.window;
    const { search } = location;

    const parser = parserQueryString(search);

    return parser;
  }

  render() {
    const { document, file } = this.state;
    return (
      <Container>
        <Header>
          <Title>Preview Document PDF</Title>
          <Options>
            <RaisedButton
              label="Download File"
              labelPosition="after"
              primary
              icon={<SaveButton />}
              href={file}
              download
            />
          </Options>
        </Header>
        <img src={document} alt="document" style={{ width: '100%' }} />
      </Container>
    );
  }
}

DocumentPreviewScreen.propTypes = {
  getDocument: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  document: PropTypes.object,
  file: PropTypes.string,
};

DocumentPreviewScreen.defaultProps = {
  document: {},
  file: '',
};

const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Header = styled.header`
  width: 100%;
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${colors.gray};
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 30px;
  height: 100%;
`;

const Options = styled.div`
  width: 170px;
  height: 100%;
`;

const mapStateToProps = state => ({
  document: state.documents.document,
  file: state.documents.file,
});

export default connect(mapStateToProps, Actions)(DocumentPreviewScreen);

