import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import Messages from '../constants/toastMessages';
import Types from '../constants/actionTypes';
import graphqlClient from '../config/graphql';
import organizationService from '../services/organization';
import { CREATE_ORGANIZATION } from '../utils/mutations/organizations';
import { FETCH_CURRENT_USER } from '../screens/UserProfileScreen/queries';
import { parsePlan } from '../utils/parsers/subscriptionParser';

const fetchCurrentUser = async (currentOrganization) => {
  // eslint-disable-next-line react/prop-types
  try {
    const {
      data: { currentUser },
    } = await graphqlClient(currentOrganization).query({
      query: FETCH_CURRENT_USER,
      fetchPolicy: 'network-only',
    });
    return currentUser;
  } catch (e) {
    return null;
  }
};

export const createOrganization = values => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    dispatch({
      type: Types.organization.SAVING,
      saving: true,
    });

    const variables = {
      name: values.name,
      subdomain: values.subdomain,
      address: values.address,
      phoneNumber: values.phone,
      invitees: values.emails ? values.emails.split(',').map(email => (email ? email.trim() : '')) : undefined,
    };

    const {
      data: { createOrganization: { organization } },
    } = await graphqlClient().mutate({
      variables,
      mutation: CREATE_ORGANIZATION,
    });

    toast.success(Messages.organizations.create.success);

    const organizationData = {
      invitees: [],
      history: [],
      ...organization,
      users: [{
        uuid: user.uuid,
        id: user.id,
        email: user.email,
        full_name: `${user.firstName} ${user.lastName}`,
        role: 'owner',
      }],
    };

    await dispatch({
      type: Types.organization.ADD,
      organization: organizationData,
    });

    await dispatch({
      type: Types.auth.SET_USER_ORGANIZATIONS,
      organizations: [...user.organizations, organizationData],
    });

    // eslint-disable-next-line no-use-before-define
    dispatch(setCurrentOrganization(organizationData));

    dispatch({
      type: Types.organization.SAVING,
      saving: false,
    });
    dispatch({
      type: Types.userProfileScreen.TOGGLE_ORGANIZATION_MODAL,
      displayModal: false,
    });
  } catch (err) {
    toast.error(Messages.organizations.create.error);

    dispatch({
      type: Types.organization.SAVING,
      saving: false,
    });
  }
};

export const fetchOrganizations = () => async (dispatch) => {
  try {
    const { data } = await organizationService.list();
    dispatch({
      type: Types.organizations.GET_ORGANIZATIONS_LIST,
      payload: data,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const searchOrganizations = query => async (dispatch) => {
  try {
    const { data } = await organizationService.search(query);
    dispatch({
      type: Types.organizations.GET_ORGANIZATIONS_LIST,
      payload: data,
    });
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setCurrentOrganization = organization => async (dispatch, getState) => {
  try {
    await dispatch({ type: Types.documentManager.CLOSE_SIDE_OPTIONS_MENU });
    await dispatch({
      type: Types.organizations.SET_CURRENT_ORGANIZATION,
      currentOrganization: organization,
    });
    dispatch({
      type: Types.organizationsScreen.SET_CURRENT_ORGANIZATION_IMAGE,
      currentOrganizationImage: (organization || {}).image,
    });

    const orgUser = await fetchCurrentUser((organization || {}).subdomain);
    const currentUser = await fetchCurrentUser();
    const currentPlan = parsePlan(get(currentUser, 'subscription.plan') || {});

    dispatch({
      type: Types.auth.LOGIN,
      payload: { ...getState().auth.user, ...orgUser, currentPlan },
    });

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateOrganization = (organizationUuid, payload, roles) =>
  async (dispatch, getState) => {
    try {
      const userInfo = getState().auth.user;
      dispatch({
        type: Types.organization.SAVING,
        savingEdit: true,
      });

      if (!isEmpty(roles)) {
        const request = { users_params: roles };

        await organizationService.updateRoles(organizationUuid, request);
      }

      const address = `${payload.street || ''}/-/${payload.city || ''}/-/${payload.state || ''}/-/${payload.zip || ''}`;
      const { data: organization } = await organizationService.update(organizationUuid, {
        organization: { ...payload, address, phone_number: payload.phone },
        new_invitees: payload.emails ? payload.emails.split(',').map(email => (email ? email.trim() : '')) : undefined,
      });
      dispatch({
        type: Types.organization.UPDATE,
        organization,
      });
      dispatch({
        type: Types.auth.LOGIN,
        payload: {
          ...userInfo,
          organizations: userInfo.organizations.map(org =>
            (org.uuid === organizationUuid ? organization : org)),
        },
      });

      dispatch({
        type: Types.organizations.SET_CURRENT_ORGANIZATION,
        currentOrganization: organization,
      });


      dispatch({
        type: Types.organization.SAVING,
        savingEdit: false,
      });

      dispatch({
        type: Types.organizationsScreen.TOGGLE_EDIT_ORGANIZATION,
        display: false,
      });

      toast.success(Messages.organizations.update.success);
      return Promise.resolve();
    } catch (error) {
      toast.error(Messages.organizations.update.error);

      dispatch({
        type: Types.organization.SAVING,
        savingEdit: false,
      });

      dispatch({
        type: Types.organizationsScreen.TOGGLE_EDIT_ORGANIZATION,
        display: false,
      });
      return Promise.reject(error);
    }
  };

export const deleteOrganization = organizationUuid => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    const { currentOrganization } = getState().organizations;
    await organizationService.delete(organizationUuid);

    toast.success(Messages.organizations.delete.success);

    dispatch({
      type: Types.organization.DELETE,
      organizationUuid,
    });

    dispatch({
      type: Types.auth.SET_USER_ORGANIZATIONS,
      organizations: user.organizations.filter(u => u.uuid !== organizationUuid),
    });


    const organization = currentOrganization.uuid !== organizationUuid && currentOrganization;

    if (!organization) {
      await dispatch(setCurrentOrganization(null));
    }

    const orgUser = await fetchCurrentUser(organization && organization.subdomain);
    const currentUser = await fetchCurrentUser();
    const currentPlan = parsePlan(get(currentUser, 'subscription.plan') || {});

    dispatch({
      type: Types.auth.LOGIN,
      payload: { ...getState().auth.user, ...orgUser, currentPlan },
    });

    dispatch({
      type: Types.organizationsScreen.TOGGLE_EDIT_ORGANIZATION,
      display: false,
    });
  } catch (error) {
    if (error.response.data.errors[0]) {
      toast.error(error.response.data.errors[0]);
    } else {
      toast.error(Messages.organizations.delete.error);
    }

    dispatch({
      type: Types.organizationsScreen.TOGGLE_EDIT_ORGANIZATION,
      display: false,
    });
  }
};

/**
 * Enable the possibility to change the current organizations
 * @returns {{type: string, canChange: boolean}}
 */
export const enableOrganizationChange = () => ({
  type: Types.organization.SET_CAN_CHANGE,
  canChange: true,
});

/**
 * Disable the possibility to change the current organizations
 * @returns {{type: string, canChange: boolean}}
 */
export const disableOrganizationChange = () => ({
  type: Types.organization.SET_CAN_CHANGE,
  canChange: false,
});

export const setOrganizationsValue = (name, value) => async (dispatch) => {
  try {
    dispatch({
      type: Types.organization.SET_FIELD,
      name,
      value,
    });
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateUserOrganization = newOrganizationsValues => async (dispatch, getState) => {
  const { user } = getState().auth;
  dispatch({
    type: Types.auth.SET_USER_ORGANIZATIONS,
    organizations: user.organizations.map(u =>
      (u.uuid === newOrganizationsValues.uuid ? newOrganizationsValues : u)),
  });
};


export default {
  createOrganization,
  fetchOrganizations,
  searchOrganizations,
  setCurrentOrganization,
  updateOrganization,
  deleteOrganization,
  setOrganizationsValue,
  enableOrganizationChange,
  disableOrganizationChange,
  updateUserOrganization,
};
