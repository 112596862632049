import gql from 'graphql-tag';

export const FILL_SIGN = gql`
  mutation FillAndSign(
    $documentUuid: ID!,
    $abbrevFields: [AbbrevFieldsAttributes!]!,
    ){
      fillAndSign(
        documentUuid: $documentUuid,
        abbrevFields: $abbrevFields,
      ){
        signedDocument {
          uuid
          signedPdf {
            url
          }
        }
      }
  }
`;

export default {
  FILL_SIGN,
};
