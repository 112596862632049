import Types from '../constants/actionTypes';

const INITIAL_STATE = {
  document: null,
  saved: false,
  sending: false,
  modalOpen: false,
  savingArchive: false,
  savingPercentage: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.uploadAndArchiveScreen.SET_DOCUMENT: {
      const { document } = action;
      return {
        ...state,
        document,
      };
    }

    case Types.uploadAndArchiveScreen.RESET_SCREEN: {
      return {
        ...INITIAL_STATE,
      };
    }

    case Types.uploadAndArchiveScreen.SET_SAVED: {
      const saved = action.payload;
      return {
        ...state,
        saved,
      };
    }

    case Types.uploadAndArchiveScreen.SENDING_DOCUMENT: {
      const { sending } = action;
      return {
        ...state,
        sending,
      };
    }

    case Types.uploadAndArchiveScreen.SET_SEND_DOCUMENT_MODAL: {
      const { status } = action;
      return {
        ...state,
        modalOpen: status,
      };
    }

    case Types.uploadAndArchiveScreen.SAVING_ARCHIVE: {
      const { savingArchive } = action;
      return {
        ...state,
        savingArchive,
      };
    }

    case Types.uploadAndArchiveScreen.SET_SAVING_PERCENTAGE: {
      const { savingPercentage } = action;
      return {
        ...state,
        savingPercentage,
      };
    }

    default:
      return state;
  }
};
