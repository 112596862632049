import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Search from 'material-ui/svg-icons/action/search';

const SearchInput = props => (
  <ComponentWrapper style={props.style}>
    <SearchIcon />
    <SearchField
      name="search_term"
      component="input"
      type="text"
      onChange={e => props.searchTermChanged(e.target.value)}
      placeholder={props.placeholder || 'Type here to search'}
      style={props.inputStyle}
    />
  </ComponentWrapper>
);

SearchInput.propTypes = {
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  searchTermChanged: PropTypes.func,
  placeholder: PropTypes.string,
};

SearchInput.defaultProps = {
  style: {},
  inputStyle: {},
  searchTermChanged: () => {},
  placeholder: 'Type here to search',
};

const ComponentWrapper = styled.div`
  position: relative
`;

const SearchIcon = styled(Search)`
  position: absolute;
  left: 10px;
  top: 10px;
  opacity: 0.38;
`;

const SearchField = styled(Field)`
  width: 100%;
  border: none !important;
  background-color: #fff;
  padding: 10px 5px 10px 50px;
  outline: none !important;
  border-radius: 0.25em;
  font-family: Roboto;
`;

export default reduxForm({
  form: 'searchForm',
})(SearchInput);
