import gql from 'graphql-tag';

export const RESEND_INVITE = gql`
  mutation ResendInvite($inviteUuid: ID!) {
    resendInvite(inviteUuid: $inviteUuid) {
      success
    }
  }
`;

export default { RESEND_INVITE };
