import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import organizationActions from '../../../actions/organization';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    componentDidMount= () => {
      // eslint-disable-next-line react/prop-types
      const { organizationInviteToken, setOrganizationsValue, selectedInvite } = this.props;
      // eslint-disable-next-line react/prop-types
      if (!organizationInviteToken && !selectedInvite.uuid) {
        setOrganizationsValue('openOrganizationInviteSuccessModal', false);
      }
    }

    handleClose = () => {
      // eslint-disable-next-line react/prop-types
      const { setOrganizationsValue } = this.props;
      setOrganizationsValue('openOrganizationInviteSuccessModal', false);
      setOrganizationsValue('organizationInviteToken', '');
    }

    render() {
      // eslint-disable-next-line
      const { organizationInviteToken, openOrganizationInviteSuccessModal, selectedInvite } = this.props;
      return (<Component
        // eslint-disable-next-line
        open={(organizationInviteToken || selectedInvite.uuid) && openOrganizationInviteSuccessModal}
        onClose={this.handleClose}
        {...this.props}
      />);
    }
  };


const mapStateToProps = ({ organizationInvites }) => ({
  openOrganizationInviteSuccessModal:
    organizationInvites.openOrganizationInviteSuccessModal,
  organizationInviteToken: organizationInvites.organizationInviteToken,
  selectedInvite: organizationInvites.selectedInvite,
});

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, {
    setOrganizationsValue: organizationActions.setOrganizationsValue,
  })(renderWrappedComponent(WrappedComponent));

export default connect;
