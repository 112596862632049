import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import editorActions from '../../actions/editor';

const renderWrappedComponent = Component =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render() {
      // eslint-disable-next-line react/prop-types
      const { open } = this.props;
      return (<Component {...this.props} open={open} />);
    }
  };

function mapStateToProps({ editor, fields }) {
  return {
    field: editor.focusedPageField || {},
    visible: editor.focusedPageField,
    scaleRatio: editor.scaleRatio,
    pageFields: editor.pageFields,
    fields,
    recipients: editor.recipients.map(recipient =>
      ({
        name: recipient.email || recipient.role,
        id: recipient.uuid,
        value: recipient.uuid,
        color: recipient.color,
      })),
  };
}

function mapDispatchToProps() {
  const {
    setEditorFocusedPageField,
    updateEditorPageField,
    removePageFieldFromEditorPage,
    setFieldOnAllPages,
    copyField,
  } = editorActions;
  return {
    onClose: () => setEditorFocusedPageField(null),
    onSave: updateEditorPageField,
    onDelete: removePageFieldFromEditorPage,
    onRepeatOnAllPages: setFieldOnAllPages,
    onCopyField: copyField,
  };
}

const connect = WrappedComponent =>
  reduxConnect(mapStateToProps, mapDispatchToProps())(renderWrappedComponent(WrappedComponent));


export default connect;
