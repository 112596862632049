import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'material-ui';

const ReduxTextField = props => (
  <TextField
    {...props}
    value={props.input.value}
    onChange={props.input.onChange}
    onKeyPress={props.input.onKeyPress}
  />
);

ReduxTextField.propTypes = {
  input: PropTypes.any,
};

ReduxTextField.defaultProps = {
  input: {},
};

export default ReduxTextField;
