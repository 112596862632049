import { isEmpty } from 'lodash';
import { getStatus } from '../../../utils/documentParserGQL';

export const parseArchivedDocuments = documents => documents.map((d) => {
  const {
    uuid, notes, previewImage, signedDate, file, fileName, activityLogs, user,
    firstName, lastName,
  } = d;
  const history = activityLogs.nodes.map(log => ({ [log.event]: log.eventDate }));

  return {
    history,
    notes,
    uuid,
    type: 'archived',
    name: !isEmpty(file) ? fileName : undefined,
    previewUrl: !isEmpty(previewImage) ? previewImage.url : undefined,
    fileUrl: !isEmpty(file) ? file.url : undefined,
    creator: `${user.firstName} ${user.lastName}`,
    partyName: (firstName && lastName && `${firstName} ${lastName}`) || '-',
    share: true,
    status: getStatus(history),
    sender: user.email,
    date: signedDate,
  };
});

export default parseArchivedDocuments;
