import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RaisedButton, FlatButton } from 'material-ui';
import AvatarEditor from 'react-avatar-editor';
import LogoPlaceholder from '../../assets/images/LogoPlaceholder.png';

const styles = {
  button: {
    marginTop: '1rem',
    height: '2.2rem',
  },
  buttonLabel: {
    position: 'relative',
    top: '0.4rem',
    padding: '0rem 3rem',
  },
  actionLabel: {
    position: 'relative',
    top: '0.4rem',
    padding: '0rem 1rem',
  },
};

class LogoUploader extends React.Component {
  state={
    newImage: '',
    uploaded: false,
  }

  onSave = () => {
    const { onClickOk, onClickCancel } = this.props;
    if (this.editor) {
      try {
        const canvasScaled = this.editor.getImageScaledToCanvas();
        canvasScaled.toBlob((blob) => {
          const imageFile = new File([blob], 'logo');
          onClickOk(imageFile);
        });
      } catch {
        onClickCancel();
      }
    }
  }

  blobToFile = (blob) => {
    blob.lastModifiedDate = new Date();
    return blob;
  }

  handleChange = ({
    target: {
      files: [file],
    },
  }) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.setState({ newImage: reader.result, uploaded: true });
    };
  };

  render() {
    const { onClickCancel, image } = this.props;
    const { newImage, uploaded } = this.state;
    const actualImage = uploaded ? newImage : image;
    return (
      <Container>
        <ImagePlaceholder>
          <AvatarEditor
            // eslint-disable-next-line no-return-assign
            ref={editor => this.editor = editor}
            image={actualImage || LogoPlaceholder}
            width={250}
            height={250}
            borderRadius={150}
          />
          <input
            type="file"
            ref={(ref) => { this.inputRef = ref; return true; }}
            onChange={this.handleChange}
            style={{ display: 'none' }}
          />
        </ImagePlaceholder>
        <ImageFooterContainer>
          <Recommentations>
            <p>Suggested Size</p>
            <p>300px x 300px, 1MB</p>
            <p>.jpg, .png, .tiff</p>
          </Recommentations>
          <RaisedButton primary style={styles.button} onClick={() => this.inputRef.click()} labelStyle={styles.buttonLabel} label={image ? 'Change' : 'Upload'} />
        </ImageFooterContainer>
        <ActionsContainer>
          <FlatButton primary style={styles.button} onClick={onClickCancel} label="Cancel" />
          <RaisedButton
            disabled={!actualImage}
            primary
            style={styles.button}
            labelStyle={styles.actionLabel}
            onClick={this.onSave}
            label="Save"
          />
        </ActionsContainer>
      </Container>
    );
  }
}

LogoUploader.propTypes = {
  onClickOk: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  image: PropTypes.string,
};

LogoUploader.defaultProps = {
  image: '',
};

const Container = styled.div`
  width: 100%;
`;

const ImagePlaceholder = styled.div`
  width: 592px;
  display: flex;
  height: 313px;
  background-color: #7F7F7F;
  justify-content: center;
  align-items: center;
  & canvas {
    background-color: white;
  }
`;

const ImageFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Recommentations = styled.div`
  margin-top: 1rem;
  & > p {
    font-size: 0.8rem !important;
    margin-bottom: 0 !important;
    color: #A7AAAF;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;


export default LogoUploader;
