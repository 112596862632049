import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmptyListNotification from '../../EmptyListNotification';
import SortableTable from '../../SortableTable';
import VoidRequestModal from '../../modals/VoidRequestModal';
import SignatureReminderSentModal from '../../modals/SignatureReminderSentModal';
import { dateFormats } from '../../../utils';

export class OutForSignatureTableBody extends Component {
  state = {
    modalOpen: false,
    voidRequestUuid: null,
    openSignatureReminderSentModal: false,
  }

  toggleSignatureReminderSentModal = () => {
    const { openSignatureReminderSentModal } = this.state;
    this.setState({ openSignatureReminderSentModal: !openSignatureReminderSentModal });
  }

  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });

  columns = [{
    id: 'name', label: 'Name', field: 'name', type: 'text',
  }, {
    id: 'subject', label: 'Subject', field: 'subject', type: 'text',
  }, {
    id: 'recipients', label: 'Recipients', field: 'recipients', type: 'text',
  }, {
    id: 'date', label: 'Date', field: 'date', type: 'date',
  }];

  options = [
    { label: 'Send Reminder', action: uuid => this.handleSendReminder(uuid) },
    { label: 'Void Request', action: uuid => this.handleVoidModal(uuid) },
  ];

  parseData = () => (this.props.documents || []).map(document => ({
    uuid: document.uuid,
    name: document.name,
    subject: document.subject,
    recipients: (document.recipients || []).map(r => r.email).join(', '),
    date: document.date && dateFormats.fullMonthDate(document.date),
    document,
  }));

  handleSendReminder = (uuid) => {
    const { createReminder, documents } = this.props;
    const signatureRequest = documents.find(d => d.uuid === uuid);
    const recipientUuids = signatureRequest.recipients.filter(r => r.pending).map(r => r.uuid);
    createReminder(uuid, recipientUuids, this.toggleSignatureReminderSentModal);
  };

  handleVoidModal = (uuid) => {
    this.openModal();
    this.setState({ voidRequestUuid: uuid });
  }

  handleVoid = () => {
    const { voidSignatureRequest } = this.props;
    const { voidRequestUuid } = this.state;
    if (voidRequestUuid) voidSignatureRequest(voidRequestUuid);
  }

  render = () => {
    const {
      selectedDocument, setSelectedDocument, loading,
      handleOnChangePage, currentPageInfo,
    } = this.props;
    const { modalOpen, openSignatureReminderSentModal } = this.state;
    const data = this.parseData();

    if (!loading && data.length === 0) {
      return (
        <EmptyListNotification
          message="Nothing to see here! You do not have any documents out for signature."
          link="/send-document"
          buttonLabel="Send Signature Request"
        />
      );
    }

    return (
      <div>
        <SortableTable
          {...currentPageInfo}
          onChangePage={handleOnChangePage}
          data={data}
          isSelectedRow={row => row.uuid === (selectedDocument || {}).uuid}
          onClickRow={row => setSelectedDocument(row.document, 'outForSignature')}
          columns={this.columns}
          options={this.options}
          loading={loading}
        />
        {modalOpen && (
          <VoidRequestModal
            onVoidClick={this.handleVoid}
            onCancel={this.closeModal}
          />
        )}
        <SignatureReminderSentModal
          open={openSignatureReminderSentModal}
          onClose={this.toggleSignatureReminderSentModal}
        />
      </div>
    );
  }
}

OutForSignatureTableBody.propTypes = {
  setSelectedDocument: PropTypes.func.isRequired,
  voidSignatureRequest: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  createReminder: PropTypes.func.isRequired,
  selectedDocument: PropTypes.object,
  loading: PropTypes.bool,
  handleOnChangePage: PropTypes.func.isRequired,
  currentPageInfo: PropTypes.object.isRequired,
};

OutForSignatureTableBody.defaultProps = {
  selectedDocument: null,
  loading: false,
};

export default OutForSignatureTableBody;
