import { isEmpty, lowerCase, trim, startCase, isArray, upperFirst, snakeCase } from 'lodash';
import { actions } from './constants';

const startsWithVowel = (word) => {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  let starts = false;
  vowels.forEach((vowel) => {
    if (word && word.startsWith(vowel)) {
      starts = true;
    }
  });
  return starts;
};

export const getFullName = (firstName, lastName, email) => {
  if (firstName === 'Signature only user') {
    return email;
  }
  return startCase(trim(`${firstName} ${lastName}`));
};

export const getOrganizationName = organization =>
  (organization && organization.name ? organization.name : '');

export const getAction = action => lowerCase(actions[action]);

export const getEntity = (entity) => {
  if (isEmpty(entity)) {
    return '';
  }
  return entity[entity.type];
};

export const getPreposition = entityType => (startsWithVowel(entityType) ? 'an' : 'a');

export const getEntityName = (entity) => {
  if (isEmpty(entity)) {
    return '';
  }
  const name =
    entity.name ||
    entity.fileName ||
    entity.subject ||
    entity.firstName;
  return name ? `'${name}'` : '';
};

export const getRecipients = ({ metadata }) => {
  if (isEmpty(metadata) || isEmpty(metadata.recipients) || !isArray(metadata.recipients)) {
    return '';
  }
  return metadata.recipients.join(', ');
};

export const getEmails = ({ metadata }) => {
  if (isEmpty(metadata) || isEmpty(metadata.emails) || !isArray(metadata.emails)) {
    return '';
  }
  return metadata.emails.join(', ');
};

export const getToMessage = (activityLog) => {
  const message = getRecipients(activityLog) || getEmails(activityLog);
  return message ? `to ${message}` : '';
};

export const getDefaultMessage = (activityLog) => {
  const {
    entity,
    entityType,
    event,
    user: { firstName, lastName, email },
  } = activityLog;
  return `${getFullName(firstName, lastName, email)} ${getAction(event)} ${getPreposition(entityType)} ${lowerCase(entityType)} ${getEntityName(entity)}`;
};

export const getMessage = (activityLog) => {
  const {
    entity,
    entityType,
    event,
    user: { firstName, lastName },
    organization,
  } = activityLog;

  const organizationName = !isEmpty(organization) ? organization.name : '';

  let message = '';
  switch (event) {
    case 'create':
    case 'created':
    case 'updated':
    case 'update':
    case 'delete':
    case 'deleted':
    case 'sign':
    case 'signed':
    case 'partially_signed':
    case 'cancel':
    case 'canceled':
    case 'resent':
    case 'resend': {
      message = getDefaultMessage(activityLog);
      break;
    }
    case 'roles_updated':
    case 'update_roles': {
      message = `${getFullName(
        firstName,
        lastName,
      )} updated roles for the organization ${organizationName}`;
      break;
    }
    case 'signatures_requested':
    case 'request_signatures': {
      message = `${getFullName(
        firstName,
        lastName,
      )} requested a signature using the ${lowerCase(entityType)} ${getEntityName(entity)}`;
      break;
    }
    case 'send_reminder':
    case 'reminder_sent': {
      message = `${getFullName(firstName, lastName)} sent a reminder for ${getEntityName(entity)} ${getToMessage(entity)}`;
      break;
    }
    case 'sent':
    case 'send': {
      message = `${getFullName(firstName, lastName)} sent ${getPreposition(entityType)} ${lowerCase(entityType)} ${getEntityName(entity)} ${getToMessage(activityLog)}`;
      break;
    }
    case 'set_as_default': {
      message = `${getFullName(firstName, lastName)} ${getAction(event)} the ${lowerCase(entityType)} ${getEntityName(entity)}`;
      break;
    }
    default: {
      message = `${upperFirst(snakeCase(entityType).replace('_', ' '))} ${snakeCase(event).replace('_', ' ')} by ${getFullName(firstName, lastName)} ${getAction(event)}`;
      break;
    }
  }
  return message;
};
