import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SelectField, MenuItem, CircularProgress } from 'material-ui';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import connect from './connect';
import { LineHorizontalChart } from '../../charts';
import { Box } from '../..';
import { getTodayMinus } from '../../../utils/date';

const VolumeChartReport = ({
  fetchDetails, organizations, withHeader, loading,
}) => {
  const [volume, setVolume] = useState(1);
  const [volumeData, setVolumeData] = useState({});

  const startDatesBy = {
    1: () => getTodayMinus(1, 'year'),
    2: () => getTodayMinus(1, 'month'),
    3: () => getTodayMinus(1, 'week'),
  };

  const parseData = (envelopes, newVolume) => {
    const data = [];
    if (newVolume === 1) {
      // eslint-disable-next-line no-plusplus
      for (let index = 11; index >= 0; index--) {
        const auxDate = moment(new Date()).subtract(index, 'months');
        data.push({
          name: auxDate.format('MMM'), date: auxDate.format('MMM YYYY'), envelopes: 0, amt: 0,
        });
        const auxEnvelopes = envelopes.filter(c => c.sentOn).map(d => moment(d.sentOn).format('MMM YYYY'));
        // eslint-disable-next-line array-callback-return
        data.map((month) => {
          const count = auxEnvelopes.filter(e => month.date === e).length;
          month.envelopes = count;
          month.amt = count;
        });
      }
      return data;
    } else if (newVolume === 2) {
      // eslint-disable-next-line no-plusplus
      for (let index = 4; index >= 0; index--) {
        const auxDate = moment(new Date()).subtract(index, 'weeks');
        data.push({
          name: `week ${5 - index}`, date: auxDate.week(), envelopes: 0, amt: 0,
        });
        const auxEnvelopes = envelopes.filter(c => c.sentOn).map(d => moment(d.sentOn).week());
        // eslint-disable-next-line array-callback-return
        data.map((month) => {
          const count = auxEnvelopes.filter(e => month.date === e).length;
          month.envelopes = count;
          month.amt = count;
        });
      }
      return data;
    } else if (newVolume === 3) {
      // eslint-disable-next-line no-plusplus
      for (let index = 6; index >= 0; index--) {
        const auxDate = moment(new Date()).subtract(index, 'days');
        data.push({
          name: `day ${7 - index}`, date: auxDate.dayOfYear(), envelopes: 0, amt: 0,
        });
        const auxEnvelopes = envelopes.filter(c => c.sentOn).map(d => moment(d.sentOn).dayOfYear());
        // eslint-disable-next-line array-callback-return
        data.map((month) => {
          const count = auxEnvelopes.filter(e => month.date === e).length;
          month.envelopes = count;
          month.amt = count;
        });
      }
      return data;
    }
    return null;
  };

  const handleChange = async (newVolume) => {
    const newVolumeData = await fetchDetails(startDatesBy[newVolume](), new Date());
    await setVolume(newVolume);
    setVolumeData(parseData(newVolumeData, newVolume));
  };

  useEffect(() => {
    handleChange(volume);
  }, [(organizations.currentOrganization || {}).id]);

  const renderTitle = title => (
    <Title>
      <div>{title}</div>
      <SelectField
        autoWidth
        style={{ width: 250 }}
        value={volume}
        onChange={(e, i, v) => handleChange(v)}
      >
        <MenuItem value={1} primaryText="Last 12 Months" />
        <MenuItem value={2} primaryText="Past 30 Days" />
        <MenuItem value={3} primaryText="Past 7 Days" />
      </SelectField>
    </Title>
  );

  return (
    <Box withHeader={withHeader} title={renderTitle('Envelope Volume')} className="col-6">
      <Chart>
        {loading
          ? <CircularProgress size={60} thickness={7} />
          : <LineHorizontalChart data={volumeData} XAxisName="name" lineName="envelopes" />}
      </Chart>
    </Box>
  );
};

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Chart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 18rem;
  padding: 1rem;
`;

VolumeChartReport.propTypes = {
  fetchDetails: PropTypes.func.isRequired,
  organizations: PropTypes.object.isRequired,
  withHeader: PropTypes.bool,
  loading: PropTypes.bool,
};

VolumeChartReport.defaultProps = {
  withHeader: true,
  loading: false,
};


export default connect(VolumeChartReport);
