import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { Form } from 'formik';
import { RaisedButton, TextField, CircularProgress, FlatButton, AutoComplete } from 'material-ui';
import { withStyles } from '@material-ui/core/styles';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { get, debounce } from 'lodash';
import * as Yup from 'yup';

import googlePlaces from '../../../services/googlePlaces';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import UpdateCreditCardModal from '../../modals/UpdateCreditCardModal';
import SubscriptionCard from '../../SubscriptionCard';
import { PhoneMask, ZipMask } from '../OrganizationForm/masks';
import CustomTextField from '../../commons/CustomTextField';
import stripeApiKey from '../../../constants/stripeKey';
import colors from '../../../utils/colors';

export const UserProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .ensure()
    .required('Required'),
  lastName: Yup.string()
    .ensure()
    .required('Required'),
  email: Yup.string()
    .ensure()
    .required('Required'),
  phone: Yup.string()
    .ensure()
    .required('Required'),
  streetAddress: Yup.string()
    .ensure()
    .required('Required'),
  city: Yup.string()
    .ensure()
    .required('Required'),
  state: Yup.string()
    .ensure()
    .required('Required'),
  zipCode: Yup.string()
    .ensure()
    .required('Required'),
});

const styles = {
  textField: {
    marginTop: '16px',
  },
};

class UserProfileForm extends Component {
  state = {
    openResetPassword: false,
    openUpdateCreditCardModal: false,
    addressSearchOptions: [],
  }

  onClickSubscription = () => {
    const { user: { currentPlan }, onChangePlan, onContactUs } = this.props;
    const onClick = currentPlan.contactUsAction ? onContactUs : onChangePlan;
    onClick();
  }

  setStateField = (field, value) => this.setState({ [field]: value });

  setValue = (field, value) => {
    const { setFieldValue, onChange, values } = this.props;

    setFieldValue(field, value);
    onChange(values);
  };

  // Debounced address search
  searchAddress = debounce((value) => {
    googlePlaces.search(value).then((places) => {
      this.setState({
        addressSearchOptions: places || [],
      });
    });
  }, 1000)

  geocodeSelectedAddress = (selectedAddress) => {
    this.setState({ addressSearchOptions: [] });

    googlePlaces.getDetails(selectedAddress.description).then((details) => {
      this.setValue('streetAddress', details.address);
      this.setValue('zipCode', details.zip);
      this.setValue('city', details.city);
      this.setValue('state', details.state);
    });
  }

  render = () => {
    const {
      values, errors, onClickResetPassword, subscription, classes,
      currentUser, user: { currentPlan },
    } = this.props;
    const { openResetPassword, openUpdateCreditCardModal } = this.state;

    const dateDifferenceDays = moment(subscription.billingCycleEnd).diff(moment(), 'days');
    const differenceText = dateDifferenceDays < 7 ? 'Days' : 'Weeks';
    const finalDateNumber = dateDifferenceDays < 7 ?
      dateDifferenceDays : Math.ceil(dateDifferenceDays / 7);

    const subscriptionFormat = subscription.plan && subscription.plan.interval === 'YEAR' ? 'MMMM DD YYYY' : 'MMMM DD';
    // LOGIC TO SHOW THE DATE WHEN A NEW PLAN WILL START
    const showNewPlanDate = values.pendingPlanChangeRequestExists;
    return (
      <StripeProvider apiKey={stripeApiKey}>
        <StyledForm>
          {showNewPlanDate &&
          <NewPlanInfoContainer>
            <div><span>{`${finalDateNumber} ${differenceText}`}</span>until your new plan starts</div>
          </NewPlanInfoContainer>
          }
          <Stack style={{ justifyContent: 'space-between' }}>
            <Layout>
              <TextField
                fullWidth
                value={values.firstName}
                onChange={e => this.setValue('firstName', e.target.value)}
                errorText={errors.firstName}
                floatingLabelText="First Name *"
                maxLength={25}
              />
              <TextField
                fullWidth
                value={values.lastName}
                onChange={e => this.setValue('lastName', e.target.value)}
                errorText={errors.lastName}
                floatingLabelText="Last Name *"
                maxLength={25}
              />
              <TextField
                fullWidth
                disabled
                floatingLabelText="Email"
                value={values.email}
              />
              <CustomTextField
                value={values.phone}
                onChange={e => this.setValue('phone', e.target.value)}
                errorText={errors.phone}
                label="Phone *"
                fullWidth
                className={classes.textField}
                InputProps={{
                  inputComponent: PhoneMask,
                  classes: {
                    underline: 'text-field-core',
                  },
                }}
              />
              <Stack>
                <TextFieldWrapper>
                  <AutoComplete
                    maxLength={90}
                    listStyle={{ width: '100%' }}
                    floatingLabelText="Street Address *"
                    hintText="Find your address *"
                    autoComplete={Math.random()}
                    dataSource={this.state.addressSearchOptions.map(option =>
                      ({ text: option.description, value: option }))}
                    filter={(searchText, key) =>
                      key.toLowerCase().includes(searchText.toLowerCase())}
                    searchText={values.streetAddress}
                    onUpdateInput={(value, dataSource) => {
                      this.setValue('streetAddress', value);
                      this.searchAddress(value);

                      const selectedAddress = dataSource.find(item => (item.text === value));

                      if (selectedAddress) {
                        this.geocodeSelectedAddress(selectedAddress.value);
                      }
                    }}
                  />
                </TextFieldWrapper>
                <TextField
                  fullWidth
                  value={values.city}
                  onChange={e => this.setValue('city', e.target.value)}
                  floatingLabelText="City/Town *"
                  maxLength={40}
                />
              </Stack>
              <Stack>
                <TextFieldWrapper>
                  <TextField
                    fullWidth
                    floatingLabelText="State"
                    maxLength={40}
                    value={values.state}
                    onChange={e => this.setValue('state', e.target.value)}
                  />
                </TextFieldWrapper>
                <CustomTextField
                  value={values.zipCode}
                  onChange={e => this.setValue('zipCode', e.target.value)}
                  errorText={errors.zipCode}
                  label="Zip Code *"
                  fullWidth
                  className={classes.textField}
                  InputProps={{
                    inputComponent: ZipMask,
                    classes: {
                      underline: 'text-field-core',
                    },
                  }}
                />
              </Stack>
              <Stack style={{ alignItems: 'center', marginTop: '20px' }}>
                <RaisedButton
                  onClick={() => this.setStateField('openResetPassword', true)}
                  label="Reset Password"
                  primary
                  style={{ width: '12rem' }}
                  buttonStyle={{ padding: '0' }}
                />
                {currentPlan.planType !== 'FREE' && (
                  <FlatButton
                    primary
                    label="Update Credit Card"
                    style={{ border: `1px solid ${colors.blue}`, marginLeft: '30px' }}
                    onClick={() => this.setStateField('openUpdateCreditCardModal', true)}
                  />
                )}
              </Stack>
            </Layout>
            {!currentPlan.id
              ? (
                <ButtonSection>
                  <CircularProgress size={60} thickness={7} />
                </ButtonSection>
              ) : (
                <ButtonSection>
                  {currentPlan.uuid && !currentUser.paymentIssue && (
                    <CardNumber>
                      {`Credit Card: ****${get(currentUser, 'cardInfo.cardLastDigts', '')}`}
                    </CardNumber>
                  )}
                  <SubscriptionCard
                    {...currentPlan}
                    activeFooter
                    readOnly
                    actionLabel="Change Plan"
                    onClickAction={currentPlan.contactUsAction
                      ? undefined : this.onClickSubscription}
                    style={{ width: '100%' }}
                  />
                  {currentPlan.uuid && (
                    <p>
                      {`Subscription plan renews ${moment(subscription.billingCycleEnd).format(subscriptionFormat)}`}
                    </p>
                  )}
                </ButtonSection>
              )}
          </Stack>
          {openUpdateCreditCardModal && (
            <Elements>
              <UpdateCreditCardModal
                onCancel={() => this.setStateField('openUpdateCreditCardModal', false)}
              />
            </Elements>
          )}
          <ChangePasswordModal
            isOpen={openResetPassword}
            onClickSend={onClickResetPassword}
            onClickCancel={() => this.setStateField('openResetPassword', false)}
          />
        </StyledForm>
      </StripeProvider>
    );
  };
}

UserProfileForm.propTypes = {
  values: PropTypes.any.isRequired,
  errors: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onContactUs: PropTypes.func,
  onClickResetPassword: PropTypes.func,
  onChangePlan: PropTypes.func.isRequired,
  currentPlan: PropTypes.object.isRequired,
  subscription: PropTypes.object,
  currentUser: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

UserProfileForm.defaultProps = {
  errors: {},
  onChange: () => {},
  onContactUs: () => {},
  onClickResetPassword: () => {},
  subscription: {},
  currentUser: {},
  user: {},
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const NewPlanInfoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  bottom: 3.5rem;
  left: 8rem;
  margin-bottom: -3.5rem;
  & > div {
    border: 1px solid #e54848;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    & > span {
      margin-right: 0.2rem;
      text-transform: uppercase;
      color: #016d9f;
    }
  }
`;

const Stack = styled.div`
  display: flex;
  width: 100%;
`;

const Layout = styled.div`
  position: relative;
`;
const TextFieldWrapper = styled.div`
  margin-right: 10px;
  padding-right: 10px;
  width: 100%;
`;
const ButtonSection = styled.div`
  margin: 25px 0 0 30px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > p {
    margin-top: 0.8rem;
    font-size: 12px;
    font-family: Roboto;
    color: rgba(0,0,0,0.38);
    text-align: center;
  }
`;

const CardNumber = styled.div`
  opacity: 0.5;
  font-size: 12px;
  margin-bottom: 10px;
`;

export default withStyles(styles)(UserProfileForm);
