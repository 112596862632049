

const getErrorMessageFromDetail = (detail) => {
  if (!detail) {
    return '';
  }
  let message = '';
  const keys = Object.keys(detail);
  keys.forEach((key) => {
    message = message.concat(`${(`${key}`).toUpperCase()}: ${detail[key][0]}.\n`);
  });
  return message;
};
/**
 *
 * @param {Error} error JS Error
 * @returns {string} Returns the BE response parsed
 */
export const getErrorMessage = (error) => {
  let message = 'Something went wrong';
  if (!error) {
    return message;
  }
  if (error.response && error.response.data) {
    const { data } = error.response;
    message = getErrorMessageFromDetail(data.details) || data.message;
    if (message) {
      return message;
    }
  }
  if (error.message) {
    return error.message;
  }
  return message;
};

export default {
  getErrorMessage,
};
