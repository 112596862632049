import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import * as reducers from '../reducers';

const authPersistConfig = {
  key: 'auth',
  storage,
};

const organizationsPersistConfig = {
  key: 'organizations',
  storage,
};

const documentDimensionsPersistConfig = {
  key: 'documentDimensions',
  storage,
};

const rootReducer = combineReducers({
  ...reducers,
  ...{ form: formReducer },
  auth: persistReducer(authPersistConfig, reducers.auth),
  organizations: persistReducer(organizationsPersistConfig, reducers.organizations),
  documentDimensions: persistReducer(documentDimensionsPersistConfig, reducers.documentDimensions),
});


export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(
  thunk,
  logger,
)));

export const persistor = persistStore(store);
