import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RaisedButton, FlatButton, Dialog, TextField } from 'material-ui';

const PasswordProtectModal = ({ onClose, onEnter }) => {
  const [password, setPassword] = useState('');
  const renderContent = () => (
    <Wrapper>
      <Title>Password Protect</Title>
      <Description>
        This document is password protected by the sender.
        Please enter the password to access the document to sign.
      </Description>
      <TextField
        type="password"
        floatingLabelText="Password"
        fullWidth
        value={password}
        onChange={(e, val) => setPassword(val)}
        errorStyle={{ position: 'absolute' }}
        style={{ marginBottom: '4rem' }}
      />
      <ButtonWrapper>
        <FlatButton
          label="CANCEL"
          primary
          onClick={onClose}
          labelStyle={{ fontFamily: 'DIN-Medium' }}
        />
        <RaisedButton
          label="ENTER"
          primary
          disabled={!password}
          onClick={() => onEnter(password)}
          labelStyle={{ fontFamily: 'DIN-Medium' }}
        />
      </ButtonWrapper>
    </Wrapper>
  );

  return (
    <Dialog
      open
      modal
      contentStyle={{ width: '550px' }}
    >
      {renderContent()}
    </Dialog>
  );
};

PasswordProtectModal.propTypes = {
  onClose: PropTypes.func,
  onEnter: PropTypes.func,
};

PasswordProtectModal.defaultProps = {
  onClose: () => {},
  onEnter: () => {},
};

const Wrapper = styled.div`
  color: black;
  padding: 30px 0 60px 0;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: DIN-Medium;
`;

const Description = styled.div`
  margin: 40px 0 0px;
  font-family: DIN-Medium;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
`;

export default PasswordProtectModal;
