import React from 'react';
import { connect } from 'react-redux';
import { isValid } from 'redux-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ApiAndKeysForm from '../../forms/ApiAndKeyForm/ApiAndKeysForm';
import { Modal } from '../../';

const ApiAndKeysModal = props => (
  <StyledModal
    open={props.isOpen}
    title={props.title}
    content={<ApiAndKeysForm />}
    primaryLabel={props.sending ? 'Saving...' : 'Save'}
    secondaryLabel="Cancel"
    primaryDisabled={props.sending || !props.isValid(props)}
    onPrimaryClick={props.handleModal}
    onSecondaryClick={props.handleCancel}
  />
);

ApiAndKeysModal.propTypes = {
  sending: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isValid: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    ...state,
    isValid: isValid('addApiKeyForm'),
  };
}

const StyledModal = styled(Modal)`
  & > div > div > div {
    padding: 3rem 0rem;
  }
`;

export default connect(mapStateToProps, null)(ApiAndKeysModal);
